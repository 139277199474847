import { EventDispatcher } from "./MatrixRequirementsAPI";
import { ml } from "./../matrixlib";
import { app, globalMatrix, matrixApplicationUI, matrixSession, restConnection } from "../../globals";

export type { IPushMessage, IItemEditor, IItemWatched, IItemUpdated, IItemCreated, IItemDeleted, ITodoChanged };
export { PushMessages };

interface IPushMessage {
    subject: string;
    project: string;
    item: string;
    version: string;
    users: string;
    parent: string;
    title: string;
    error?: string;
}
// message about editor
interface IItemEditor {
    user: string;
    thisSocket: boolean;
}
// message send if someone looks at the watched item
interface IItemWatched {
    item: string;
    users: string[];
    editor: IItemEditor;
    version: number;
}
// message send if some modified the watched item
interface IItemUpdated {
    item: string;
    version: number;
    title: string;
    thisSocket: boolean;
}
interface IItemCreated {
    item: string;
    parent: string;
    title: string;
}
interface IItemDeleted {
    item: string;
}
// message if someone created or closed a todo for the user
interface ITodoChanged {}

class PushMessages {
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private webSocket: WebSocket;
    private itemWatched: EventDispatcher<IItemWatched> = new EventDispatcher<IItemWatched>();
    private itemUpdated: EventDispatcher<IItemUpdated> = new EventDispatcher<IItemUpdated>();
    private itemCreated: EventDispatcher<IItemCreated> = new EventDispatcher<IItemCreated>();
    private itemDeleted: EventDispatcher<IItemDeleted> = new EventDispatcher<IItemDeleted>();
    private todoChanged: EventDispatcher<ITodoChanged> = new EventDispatcher<ITodoChanged>();
    static socketId: number = new Date().getTime();
    constructor() {}

    newConnection(): JQueryDeferred<{}> {
        // make sure there's nothing left of an old connection

        if (this.webSocket && this.webSocket.readyState != WebSocket.CLOSED) {
            this.webSocket.onclose = null;
            this.webSocket.onerror = null;
            this.webSocket.onmessage = null;
            this.webSocket.close();
        }

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        this.webSocket = null;

        // create a new one
        return this.connect();
    }

    connect(): JQueryDeferred<number> {
        let that = this;
        let res = <JQueryDeferred<number>>$.Deferred();

        ml.Logger.log("info", "Connecting to websocket");

        if (this.webSocket) {
            // something is in the bush, it tries to (re)connect
            if (this.webSocket.readyState == WebSocket.CONNECTING) {
                ml.Logger.log("warning", "WebSocket is in connecting state.");
                res.resolve(WebSocket.CONNECTING);
                return res;
            } else if (this.webSocket.readyState == WebSocket.CLOSING) {
                ml.Logger.log("warning", "WebSocket is in closing state.");
                res.resolve(WebSocket.CLOSING);
                return res;
            } else if (this.webSocket.readyState == WebSocket.OPEN) {
                ml.Logger.log("warning", "WebSocket is already opened.");
                res.resolve(WebSocket.OPEN);
                return res;
            }
        }

        this.webSocket = new WebSocket(
            globalMatrix.matrixBaseUrl.replace("https:", "wss:").replace("http:", "ws:") + "/websocket?",
        );

        /**
         * Binds functions to the listeners for the websocket.
         */

        this.webSocket.onerror = function (event: any) {
            ml.Logger.log("info", "Error from websocket");
            ml.Logger.log("info", event.data);
            res.reject();
        };

        this.webSocket.onmessage = function (event) {
            if (event.data) {
                let message = <IPushMessage>JSON.parse(event.data);
                console.log(message);
                if (message && message.error == "Cannot set you as editor") {
                    // indicate at least that we are watching
                    let itemId = app.getCurrentItemId();
                    if (itemId && ml.Item.parseRef(itemId).number) {
                        if (that.preventConcurrentEdit()) {
                            app.someOneIsChangingTheItem();
                            that.watchItem(itemId);
                        }
                    }
                } else {
                    that.trigger(message);
                }
            }
        };

        this.webSocket.onclose = function (event) {
            ml.Logger.log("info", "Stop listening to news from server: " + event.code);
            // check if I need to reconnect
            that.reconnectAfterCloseMessage(10);
        };

        this.webSocket.onopen = function (event) {
            ml.Logger.log("info", "Listening to news from server");

            // say hello to the server
            that.send({ verb: "openedSocket" }, 0);

            // we got (re)connected, so the server won't know in which editing states we are
            that.sendCurrentEditingStatus();

            res.resolve(WebSocket.OPEN);
        };
        return res;
    }

    // verify if there's a category setting allowing concurrent of items of this type
    private preventConcurrentEdit() {
        let itemId = app.getCurrentItemId();
        if (!itemId) return true;

        let category = ml.Item.parseRef(itemId).type;
        if (!category) return true;

        let concurrentEditSetting = <any>(
            globalMatrix.ItemConfig.getCategorySetting(ml.Item.parseRef(itemId).type, "concurrentEdit")
        );
        if (concurrentEditSetting && concurrentEditSetting.disabled) {
            return false;
        }

        return true;
    }

    private reconnectAfterCloseMessage(waitForServerRestartSeconds: number) {
        let that = this;

        ml.Logger.log("Info", "Waiting for new connection for " + waitForServerRestartSeconds);
        // get something from server, if connection is not ok, do not try to reconnect
        //Matrix-5036: no need to get everything from /rest/1. Server version is enough.
        restConnection
            .getServer("?output=serverVersion", true)
            .done(function () {
                // connection is still ok -> re'init the web sockets and send information about current view/edit state of an item
                that.connect()
                    .done(function (result) {
                        if (result != WebSocket.OPEN) {
                            // not normal
                            window.setTimeout(function () {
                                that.reconnectAfterCloseMessage(waitForServerRestartSeconds);
                            }, 1000);
                        }
                    })
                    .fail(function () {
                        // that's not normal...
                        window.setTimeout(function () {
                            that.reconnectAfterCloseMessage(waitForServerRestartSeconds);
                        }, 1000);
                    });
            })
            .fail(function () {
                if (waitForServerRestartSeconds == 0) {
                    // apparently server got really disconnected
                    console.log("Server also disconnected");
                    matrixSession.tryReconnect().done(() => {
                        // if itemDetails is empty.. this is 10 seconds after a log out, so we need a full refresh
                        matrixSession.updateUI($("#itemDetails").html() != "");
                    });
                } else {
                    // maybe user logs on as other user
                    window.setTimeout(function () {
                        that.reconnectAfterCloseMessage(waitForServerRestartSeconds - 1);
                    }, 1000);
                }
            });
    }

    trigger(message: any) {
        if (message.subject != "todo" && message.project != matrixSession.getProject()) {
            ml.Logger.log("info", "Message for previous project");
            return;
        }

        switch (message.subject) {
            case "todo":
                this.todoChanged.dispatch(<ITodoChanged>{});
                break;

            case "itemChanged":
                if (this.preventConcurrentEdit()) {
                    // the user does not edit the item
                    this.itemUpdated.dispatch(<IItemUpdated>message);
                }
                break;
            case "itemMoved":
                matrixApplicationUI.lastMainItemForm.addMove(message.item, message.version);
                break;
            case "itemDeleted":
                this.itemDeleted.dispatch(<IItemDeleted>message);
                break;
            case "itemCreated":
                this.itemCreated.dispatch(<IItemCreated>message);
                break;
            case "folderCreated":
                this.itemCreated.dispatch(<IItemCreated>message);
                break;
            case "watching":
                if (this.preventConcurrentEdit()) {
                    this.itemWatched.dispatch(<IItemWatched>message);
                    if ((<IItemWatched>message).item == app.getCurrentItemId() && app.needsSave() && !message.editor) {
                        // workaround MATRIX-4428 workaround: user edits an item and has another tab open with same item, user closes other tab
                        this.editItem(app.getCurrentItemId());
                    }
                }
                break;
        }
    }

    // if the user is watching or editing something, let the server know
    private sendCurrentEditingStatus() {
        let itemId = app.getCurrentItemId();

        if (itemId && ml.Item.parseRef(itemId).number) {
            if (app.needsSave()) {
                this.editItem(itemId);
            } else {
                this.watchItem(itemId);
            }
        }
    }

    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private retryTimer: number;
    private send(message: any, retryCount: number) {
        let that = this;

        message["socketId"] = "" + PushMessages.socketId;
        // make sure no old messages will be send after this
        window.clearTimeout(this.retryTimer);

        // make sure there's a good connection
        if (!this.webSocket || this.webSocket.readyState != WebSocket.OPEN) {
            // no good connection
            if (retryCount < 10) {
                ml.Logger.log("info", "Websocket disconnected try to reconnect");
                this.connect()
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    .done(function (status: number) {
                        if (status == WebSocket.OPEN) {
                            // got a new socket! send
                            ml.Logger.log("info", "Send (after reconnect) to watcher: " + message.verb);
                            try {
                                that.webSocket.send(JSON.stringify(message));
                            } catch (ex) {
                                ml.Logger.log("error", "Failing to send");
                            }
                        } else {
                            that.retryTimer = window.setTimeout(function () {
                                that.send(message, retryCount + 1);
                            }, 1000);
                        }
                    })
                    .fail(function () {});
            } else {
                ml.Logger.log("info", "Websocket disconnected - giving up, trying to send");
            }
            return;
        }

        ml.Logger.log("info", "Send to watcher: " + message.verb);
        try {
            this.webSocket.send(JSON.stringify(message));
        } catch (ex) {
            ml.Logger.log("error", "Failing to send");
        }
    }

    // start watching for item viewers
    watchItem(itemId: string) {
        this.send(
            {
                verb: "watchItem",
                project: matrixSession.getProject(),
                item: itemId,
            },
            0,
        );
    }
    // start watching for item viewers
    unWatchItem() {
        this.send(
            {
                verb: "unwatchItem",
            },
            0,
        );
    }

    // start edit item (user can save)
    editItem(itemId: string) {
        this.send(
            {
                verb: "editItem",
                project: matrixSession.getProject(),
                item: itemId,
            },
            0,
        );
    }

    // stop edit item (user dose not need to save anymore)
    unEditItem() {
        this.send(
            {
                verb: "uneditItem",
            },
            0,
        );
    }

    // event listeners
    onTodoChanged(fn: (args: ITodoChanged) => void) {
        this.todoChanged.subscribe(this, fn);
    }

    onItemUpdated(fn: (args: IItemUpdated) => void) {
        this.itemUpdated.subscribe(this, fn);
    }
    onItemCreated(fn: (args: IItemCreated) => void) {
        this.itemCreated.subscribe(this, fn);
    }
    onItemDeleted(fn: (args: IItemDeleted) => void) {
        this.itemDeleted.subscribe(this, fn);
    }
    onItemWatched(fn: (args: IItemWatched) => void) {
        this.itemWatched.subscribe(this, fn);
    }
}
