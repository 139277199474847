import { SelectMode } from "../UI/Components/ProjectViewDefines";
import { IBaseControlOptions } from "../UI/Controls/BaseControl";
import { TasksControlImpl } from "../UI/Controls/tasksControl";
import { IDB } from "./DBCache";
import { MR1, IItemChangeEvent } from "./MatrixRequirementsAPI";
import { IPlugin, IProjectPageParam, IPluginPanelOptions, plugins, pluginHooks } from "./PluginManager";
import { IJcxhr } from "./RestConnector";
import { ml } from "./../matrixlib";
import { ItemSelectionTools, IItemSelectDialogOptions } from "../UI/Tools/ItemSelectionView";
import { XRGetProject_StartupInfo_ListProjectAndSettings, XRPluginSetting } from "../../RestResult";
import { mTM } from "./TestManager";
import {
    IStringMap,
    IItem,
    app,
    matrixSession,
    globalMatrix,
    IControlDefinition,
    ControlState,
    IReference,
    matrixApplicationUI,
} from "../../globals";
import { FieldDescriptions } from "./FieldDescriptions";

export type {
    ITasksConfiguration,
    FolderItem,
    Folder,
    IPFExternalField,
    ICatFieldMapping,
    ITaskConfiguration,
    ITaskTaskDescription,
    IOne2OneMapping,
    IOne2OneMappingStatus,
    ITaskRenderInfo,
    ITaskSearch,
    ITaksProjects,
    ITaskType,
    ISmartTask,
    ISmartUrls,
    IWltItemWithLinks,
    IWltMatrixItem,
    IExternalItem,
    IMoreInfo,
    ISearchResults,
    ITaskDetails,
    XTCTableRow,
};
export { Tasks, mTasks };
export { InitializeTasks };

/*** config
 *
 */
interface ITasksConfiguration {
    config: ITaskConfiguration[]; // one for each server plugin
}

type FolderItem = Folder | IWltItemWithLinks;
type Folder = { name: string; id: string; children: FolderItem[] };

interface IPFExternalField {
    /**  Jira field id something like custom_... or assignee or ...*/
    extFieldId: string;
    /** converter which specified how to convert matrix field into jira field */
    converter: string;
    /** mapping for drop down from Matrix to Jira drop downs  */
    ddMapping?: IStringMap;
    [key: string]: any;
}

interface ICatFieldMapping {
    /** map from category to the fields in the category. The matrixFieldName is the name of the field in Matrix  */
    [key: string]: { [matrixFieldName: string]: IPFExternalField };
}
interface IExternalMeta {
    issueType: string;
    status: string;
}
interface ITaskConfiguration {
    /** defaultSearches: can be used to define default search expressions, (e.g. shortcuts to search task changed in last x hours, server plugin must understand these...) */
    defaultSearches?: ITaskSearch[];
    /** one2OneMapping: #
     * requires one2one capability. defines how external items are shown
     * the first search in the list will be executed automatically when the dialog is opened
     * in order not to run an automatic search define first element in array with name=""
     */
    one2OneMapping?: IOne2OneMapping;
    /** allowEmptySearches: can be set to true if plugin can handle it */
    allowEmptySearches?: boolean;
    /** searchHelp: can be an url to any website to explain search options (e.g. jql https://..atlassian.. /jql) */
    searchHelp?: string;
    /** autoSearch: can be set to true to start default search (when opening dialog)*/
    autoSearch?: boolean;
    /** smartLinks: a set of rules to automatically show hyperlinks to items -> note these are available only in the client, in documents the same rules will not be applied! */
    smartLinks?: ISmartTask[];
    /** smartUrls: a set of rules to automatically detect dropped links*/
    smartUrls?: ISmartUrls[];
    /** projectsCreate: there must be at least one default project in which tasks can be created */
    projectsCreate: ITaksProjects[];
    /** projectFilter: filter for projects of which items are displayed in workflow control, if not set all tasks are shown */
    projectFilter?: string[];

    /** useAsDescription: defines if and what to use as default description -> default is empty (an empty description box) */
    useAsDescription?: ITaskTaskDescription;
    /** useEmptyTitle: by default the title of new task is the current item title, true leaves it empty */
    useEmptyTitle?: boolean;
    /** requireCommitTicket: set to true if saving should requires a task id (requires smartLinks to be configured)*/
    requireCommitTicket?: boolean;

    /** catFieldMapping: mapping from Matrix fields to jira fields per category  */
    catFieldMapping?: ICatFieldMapping;
    /** userMapping: mapping from Matrix users to jira users  */
    userMapping?: IStringMap;
    /** defaultComment: when updating the matrix item this comment is added to the linked jira tickets as prefix to the details of which item was changed */
    defaultComment?: string;
    /** if autoAddCommentOnSave is to true it adds a comment to all linked items*/
    autoAddCommentOnSave?: boolean;

    /** showStatus: if set to true it will request from server the meta info for each linked ticket. If there's a status property in the meta it's displayed a string*/
    showStatus?: boolean;

    // these can be used to overwrite the plugins's default (e.g. if the plugins says they are possible, but the UI should disable the feature)

    /** pluginName: as shown in UI e.g. JIRA, GitHub, ...*/
    pluginName?: string;
    /** pluginLongName: as shown in UI e.g. JIRA Server Plugin, GitHub Plugin, ... */
    pluginLongName?: string;

    /** hideCreateTask: overwrites canCreate capability*/
    hideCreateTask?: boolean; //
    /** hideSearchTasks: overwrites canFind capability */
    hideSearchTasks?: boolean;
    /** handleAsLink: should not be changed - if true links are treated like URLs*/
    handleAsLink?: boolean;

    /** hasMeta: should not be changed - if true external items have a description and a status*/
    hasMeta?: boolean; //

    // server computed settings they could also be overwritten - but normally that's not needed

    /** nativeCreateUrl: overwrites nativeCreateUrl*/
    nativeCreateUrl?: string;
    /** nativeCreateSearch:  overwrites nativeCreateSearch*/
    nativeCreateSearch?: string;
    /** pluginId: 'internal id provided by server'*/
    pluginId?: number;

    // obsolete / not implemented?
    //createBacklinks?:boolean // if set to false overwrites the option to canCreateBacklinks capability
}

type ITaskTaskDescription = "hide" | "empty" | "text"; // later we could support html editors - not now though |"html"

interface IOne2OneMapping {
    // configures 1-1 mapping
    projectId: string; // id communicated to server to indicate in which project to create tickets
    taskTypeId: string; // id communicated to server to indicate what type
    showId?: boolean; // shows external item's id in title
    statusOverwrites: IOne2OneMappingStatus[];
}
interface IOne2OneMappingStatus extends ITaskRenderInfo {
    externalStatusName: string; // name of external status
    text: string; // text to show
}

interface ITaskRenderInfo {
    text: string; // one to one mapping will show this text
    color?: string; // in this color
    background?: string; // with bg color
    strikethrough?: boolean; // optional strikethrough
}

interface ITaskSearch {
    name: string; // name of search to show in UI
    expression: string; // expression to be send to server
}
interface ITaksProjects {
    projectId: string; // id communicated to server (can be "" if there is no concept of a project in server plugin)
    projectName: string; // name communicated to user
    taskTypes: ITaskType[]; // there must be at least one default task type per project
}

interface ITaskType {
    taskTypeId: string; // id communicated to server (can be "" if there is no concept of a different task types in server plugin)
    taskTypeName: string; // name communicated to user
    iconUrl?: string; // can be an url of an icon to display
    iconClass?: string; // can be an class of an icon to display
}

interface ISmartTask {
    regex: string; // regex which needs to match, e.g. (github)(-)([0-9]*) would match "github-12" with three groups, "github", "-" and "12" as $0,$1 and $2
    issueProjectId: string; // a template which creates the project information for a matched issue (uses the same replacement as above), eg. $0 for github
    issueId: string; // a template which creates the issue information for a matched issue (uses the same replacement as above) e.g. $2 for 12
    title: string; // url template for a title (can be empty)
    url?: string; // url template which needs to be created for the hyperlink, e.g. "https://myissue.com/myticket/ticke-$2/details" would replace the $2 with the second  group (like "12" above)
}
interface ISmartUrls {
    regex: string; // regex which needs to match, e.g. (github-)([0-9]*) would match "github-12" with two groups, "github-" and 12
    issueProjectId: string; // a template which creates the project information for a matched issue (uses the same replacement as above)
    issueId: string; // a template which creates the issue information for a matched issue (uses the same replacement as above)
    title: string; // url template for a title (can be empty)
    priority?: number; // if there are several matches the highest priority will win
}

/*** wlt interface
 *
 */

interface IWltItemWithLinks {
    matrixItem: IWltMatrixItem;
    links: IExternalItem[];
}
interface IWltMatrixItem {
    itemId: number;
    projectId: number;
    title: string;
    matrixItem: string;
    project: string;
}

interface IExternalItem {
    externalItemId: string;
    externalItemTitle: string;
    externalItemUrl: string;
    externalDescription: string;
    externalLinkCreationDate?: string;
    externalDone: boolean;
    externalUser?: string; // if user is returned with external item it's displayed in UI
    externalProject?: string;
    externalType?: string; // for jira use...
    externalMeta?: string;
    plugin: number;
    more?: IMoreInfo[];
}

interface IMoreInfo {
    key: string;
    value: string;
}

/* internal helper interface
 *
 *
 */

interface ISearchResults {
    startAt: number; // first number of hits
    maxResults: number; // which have been possibly returned
    tasks: IExternalItem[];
}

interface ITaskDetails extends IExternalItem {
    matrixItemIds?: string[]; // server provides list of linked items
}

interface XTCTableRow {
    comment: string;
}

interface ITaskPart {
    icon: JQuery;
    id: JQuery;
    ticketTitle: JQuery;
    externalUser: JQuery;
    actions: JQuery;
}
class Tasks implements IPlugin {
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private item: IItem;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private jui: JQuery;
    static tasksConfiguration: ITaskConfiguration[];

    //
    // ****************************************
    // standard plugin interface
    // ****************************************

    public isDefault = true;
    constructor() {}
    initItem(_item: IItem, _jui: JQuery) {
        this.item = _item;
        this.jui = _jui;
    }

    reset() {
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        this.item = null;
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        this.jui = null;
    }

    initServerSettings(serverSettings: XRGetProject_StartupInfo_ListProjectAndSettings) {}

    updateMenu(ul: JQuery, hook: number) {
        let that = this;

        if (hook !== pluginHooks.shares) {
            return;
        }

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        if (!this.item || app.isFolder(this.item.id)) {
            return; // do not show tasks for folders
        }

        if (!matrixSession.isEditor() || !Tasks.tasksConfiguration || Tasks.tasksConfiguration.length == 0) {
            return;
        }

        let miDivider = $('<li class="divider"></li>');
        ul.append(miDivider);

        $.each(Tasks.tasksConfiguration, function (idx, config) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            if (config.catFieldMapping && config.catFieldMapping[that.item.type]) {
                $($(`<li><span class="toolmenu">Push to ${config.pluginName}</span></li>`))
                    .click(() => {
                        that.pushIssueDlg(config);
                    })
                    .appendTo(ul);
            }

            if (!config.hideCreateTask) {
                if (config.handleAsLink) {
                    $(`<li><span class="toolmenu">Add web link</span></li>`)
                        .appendTo(ul)
                        .data("config", config)
                        .click(function (event: JQueryMouseEventObject) {
                            Tasks.createAndLinkWebDlg(
                                $(event.delegateTarget).data("config"),
                                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                null,
                                function (linkTitle: string, linkUrl: string) {
                                    let extItem: IExternalItem = {
                                        externalItemId: "" + new Date().getTime(),
                                        externalItemTitle: linkTitle,
                                        externalItemUrl: linkUrl,
                                        externalDescription: "",
                                        externalDone: false,
                                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                        plugin: config.pluginId,
                                    };
                                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                    Tasks.postCreateLinks(that.item.id, [extItem]).done(function (allITems) {
                                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                        that.updateUI(allITems);
                                        that.jui.dialog("close");
                                    });
                                },
                            );
                        });
                } else {
                    $(`<li><span class="toolmenu">Create and link ${config.pluginName} task</span></li>`)
                        .appendTo(ul)
                        .data("config", config)
                        .click(function (event: JQueryMouseEventObject) {
                            that.createAndLinkIssueDlg($(event.delegateTarget).data("config"));
                        });
                }
            }

            if (config.nativeCreateUrl && config.nativeCreateSearch) {
                $(`<li><span class="toolmenu">Open ${config.pluginName} create page</span></li>`)
                    .appendTo(ul)
                    .data("config", config)
                    .click(function (event: JQueryMouseEventObject) {
                        that.createSearchAndLinkIssueDlg($(event.delegateTarget).data("config"));
                    });
            }

            if (!config.hideSearchTasks && !config.handleAsLink) {
                $(`<li><span class="toolmenu">Link to existing ${config.pluginName} task</span></li>`)
                    .appendTo(ul)
                    .data("config", config)
                    .click(function (event: JQueryMouseEventObject) {
                        that.searchAndLinkIssueDlg($(event.delegateTarget).data("config"));
                    });
            }
        });
        return;
    }

    supportsControl(fieldType: string): boolean {
        return fieldType === FieldDescriptions.Field_tasksControl;
    }

    createControl(ctrl: JQuery, options: IBaseControlOptions) {
        ctrl.tasksControl(options);
    }

    initProject() {
        Tasks.tasksConfiguration = [];
        let pluginSettings = globalMatrix.ItemConfig.getPluginSettings();
        // setup highlight rules
        $.each(pluginSettings, function (tidx, plugin) {
            if (plugin.pluginId > 200) {
                let a: XRPluginSetting;
                // version 2 plugin
                let clientConfig: ITaskConfiguration = <ITaskConfiguration>{};
                let enabled = false;
                // get client config setting and find out if enabled
                $.each(plugin.settings, function (idx, setting) {
                    if (setting.setting === "clientConfig" && setting.value) {
                        clientConfig = JSON.parse(setting.value);
                    } else if (setting.setting === "clientEnabled") {
                        enabled = ml.JSON.isTrue(setting.value);
                    }
                });
                if (enabled) {
                    clientConfig.pluginId = plugin.pluginId;
                    $.each(clientConfig.smartLinks, function (idx, smartLink) {
                        // in case there are some hypelinks configured
                        addHighlightRegex(smartLink.regex, smartLink.url);
                    });
                    // unless names are defined, use server's default
                    if (!clientConfig.pluginLongName) {
                        clientConfig.pluginLongName = plugin.pluginLongName;
                    }
                    if (!clientConfig.pluginName) {
                        clientConfig.pluginName = plugin.pluginShortName;
                    }

                    if (typeof clientConfig.handleAsLink == "undefined") {
                        clientConfig.handleAsLink = plugin.capabilities.handleAsLink;
                    }

                    if (typeof clientConfig.hideCreateTask == "undefined") {
                        clientConfig.hideCreateTask = !plugin.capabilities.canCreate && !clientConfig.handleAsLink;
                    }

                    if (typeof clientConfig.hideSearchTasks == "undefined") {
                        clientConfig.hideSearchTasks = !plugin.capabilities.canFind;
                    }

                    /*if ( typeof clientConfig.createBacklinks == 'undefined') {
                       clientConfig.createBacklinks = plugin.capabilities.canCreateBacklinks;
                    }
                    */
                    // need to run again though settings
                    $.each(plugin.settings, function (idx, setting) {
                        if (setting.setting === "projectFilter") {
                            clientConfig.projectFilter = setting.value ? setting.value.split(",") : [];
                        }
                    });

                    if (typeof clientConfig.hasMeta == "undefined") {
                        clientConfig.hasMeta = plugin.capabilities.hasMeta;
                    }
                    $.each(plugin.computedSettings, function (idx, setting) {
                        if (setting.setting === "nativeCreateUrl" && !clientConfig.nativeCreateUrl) {
                            // client does not overwrite capability, so take default from server
                            clientConfig.nativeCreateUrl = setting.value;
                        }
                        if (setting.setting === "nativeCreateSearch" && !clientConfig.nativeCreateSearch) {
                            // client does not overwrite capability, so take default from server
                            clientConfig.nativeCreateSearch = setting.value;
                        }
                    });
                    Tasks.tasksConfiguration.push(clientConfig);
                }
            }
        });
    }

    getProjectPagesAsync(): Promise<IProjectPageParam[]> {
        return new Promise((resolve, reject) => {
            let that = this;
            let pages: IProjectPageParam[] = [];

            if (globalMatrix.ItemConfig.getTimeWarp()) {
                // remove the pages - too complicated
                resolve(pages);
                return;
            }
            $.each(Tasks.tasksConfiguration, function (idx, conf) {
                pages.push({
                    id: "TASKS" + conf.pluginId,
                    title: conf.pluginName + " addon",
                    folder: "ADDONS",
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    order: 1000 + conf.pluginId,
                    icon: "fal fa-external-link-square",
                    usesFilters: false,
                    render: (options: IPluginPanelOptions) => that.renderProjectPage(options),
                });
            });

            resolve(pages);
            return;
        });
    }
    // this function goes through all tests steps and applies all smartlink rules to find hyperlinks to issues
    // TODO: fix return type
    async preSaveHookAsync(isItem: boolean, type: string, controls: IControlDefinition[]): Promise<{}> {
        let that = this;
        // TODO: refactor it
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            if (isItem && mTM.isXTC(type)) {
                let linksToBeCreated: string[] = []; // array of matches

                for (let idx = 0; idx < controls.length; idx++) {
                    if (controls[idx].fieldType === FieldDescriptions.Field_test_steps_result) {
                        let val = await (controls[idx].control as any).getController().getValueAsync();
                        let v: XTCTableRow[] = JSON.parse(val);
                        for (let step = 0; step < v.length; step++) {
                            const comment = v[step].comment;
                            if (comment && comment !== "") {
                                // find matches
                                $.each(Tasks.tasksConfiguration, function (tidx, config) {
                                    $.each(config.smartLinks, function (idx, smartLink) {
                                        let re = new RegExp(smartLink.regex, "g");
                                        let hits = comment.match(re);
                                        if (hits) {
                                            $.each(hits, function (hidx, hit) {
                                                if (linksToBeCreated.indexOf(hit) === -1) {
                                                    linksToBeCreated.push(hit);
                                                }
                                            });
                                        }
                                    });
                                });
                            }
                        }
                    }
                }

                if (linksToBeCreated.length > 0) {
                    that.createLinksAsync(linksToBeCreated)
                        .done(function () {
                            resolve({});
                        })
                        .fail(function () {
                            reject();
                        });
                } else {
                    resolve({});
                }
            } else {
                resolve({});
            }
        });
    }

    // ****************************************
    // Misc functions for jiraPlugin (running inside JIRA)
    // ****************************************

    isPluginEnabled(pluginId: number) {
        let enabled = false;
        $.each(Tasks.tasksConfiguration, function (idx, config) {
            if (config.pluginId == pluginId) {
                enabled = true;
            }
        });
        return enabled;
    }

    // ****************************************
    // Misc functions for matrixreq and others
    // ****************************************

    // verifies if a save comment has all required task id's if not
    evaluateTaskIds(comment: string): string[] {
        let issues: string[] = [];
        $.each(Tasks.tasksConfiguration, function (idx, config) {
            if (config.requireCommitTicket) {
                let hasId = false;
                $.each(config.smartLinks, function (sidx, smartLink) {
                    let re = new RegExp(smartLink.regex, "g");
                    let hits = comment.match(re);
                    if (hits) {
                        hasId = true;
                    }
                });

                if (!hasId) {
                    issues.push(`missing task id for ${config.pluginName}`);
                }
            }
        });
        return issues;
    }

    // test if a dropped URL is a link to an issue -> if so convert it to externalitem struct
    static externalItemFromUrl(url: string): IExternalItem {
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        let matches: IExternalItem = null;
        let priority = 0;

        $.each(Tasks.tasksConfiguration, function (idx, config) {
            $.each(config.smartUrls, function (sidx, smartUrl) {
                if (!matches || priority < (smartUrl.priority ? smartUrl.priority : 0)) {
                    let re = new RegExp(smartUrl.regex);
                    let ma = url.match(re);
                    if (ma) {
                        // it matches: replace all palceholders, $0, $1, $2, with matching groups
                        let issueId = smartUrl.issueId;
                        let issueProjectId = smartUrl.issueProjectId;
                        let title = smartUrl.title ? smartUrl.title : "";
                        for (let i = 0; i < Math.min(ma.length, 10); i++) {
                            let rep = new RegExp("\\$" + i, "g");
                            issueProjectId = issueProjectId.replace(rep, ma[i]);
                            issueId = issueId.replace(rep, ma[i]);
                            title = title.replace(rep, ma[i]);
                        }
                        priority = smartUrl.priority ? smartUrl.priority : 0;
                        matches = {
                            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                            plugin: config.pluginId,
                            externalProject: issueProjectId,
                            externalItemId: issueId,
                            externalItemUrl: url,
                            externalDescription: "",
                            externalItemTitle: title,
                            externalDone: false,
                            externalType: "",
                        };
                    }
                }
            });
        });
        return matches;
    }

    private async addCommentToAllLinkedIssues(
        config: ITaskConfiguration,
        comment?: string,
        whatChanged?: string,
        version?: number,
    ) {
        let that = this;
        let versionStr = version ? `to version v${version}` : "";
        if (comment == undefined) {
            comment = "";
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            if (Tasks.getConfig(config.pluginId).defaultComment != undefined) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                comment = Tasks.getConfig(config.pluginId).defaultComment;
            }
            comment += `*${app.getCurrentItemId()} -  ${(
                await app.getCurrentTitle()
            ).trim()}*  has been updated ${versionStr}\n *See :* ${
                globalMatrix.matrixBaseUrl
            }/${matrixSession.getProject()}/${app.getCurrentItemId()} \n----`;
        }
        if (whatChanged != undefined) comment += "\n" + whatChanged;

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        Tasks.getTasks(that.item.id, [config.pluginId]).then((externalItems) => {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            externalItems.forEach((externalItem) => {
                let job = {
                    pluginId: config.pluginId,
                    action: "AddComment",
                    matrixItem: {
                        project: matrixSession.getProject(),
                    },
                    externalItem: {
                        externalItemId: externalItem.externalItemId,
                    },
                    more: [{ key: "comment", value: comment }],
                };

                globalMatrix.wfgwConnection
                    .postServer("?" + jQuery.param({ payload: JSON.stringify(job) }), job, true)
                    .done(() => {
                        console.log("Comment added! ");
                    })
                    .fail(function (jqxhr, textStatus, error) {
                        Tasks.showError("AddComment failed", jqxhr, textStatus, error);
                    });
            });
        });
    }

    /** this creates a new item and jira and sets the define fields with values coming from Matrix */
    private pushIssueDlg(config: ITaskConfiguration) {
        let that = this;

        let options = "";
        // create project / type drop down menu
        $.each(config.projectsCreate, function (i, project) {
            $.each(project.taskTypes, function (j, taskType) {
                let oName: string[] = [];
                if (project.projectName) oName.push(project.projectName);
                if (taskType.taskTypeName) oName.push(taskType.taskTypeName);
                options +=
                    "<option value='" +
                    project.projectId +
                    "|" +
                    taskType.taskTypeId +
                    "' " +
                    (i + j == 0 ? "selected" : "") +
                    ">" +
                    oName.join(" / ") +
                    "</option>";
            });
        });

        let form = $(`<div class="container" style="width:100%;height:100%">
                        <div class="row optionsSelectRow" style="margin-top:4px;margin-bottom:6px">
                            <div class="col-md-2" style="margin-top: 6px;"><label style="white-space: nowrap" for="inputType"
                                    class="control-label">Issue Type:</label></div>
                            <div class="col-md-10"><select class="form-control inputType" style="width:100%;height:32px" id="inputType">
                                    ${options}
                                </select></div>
                        </div>
                    </div>`);

        this.jui.html("");
        this.jui.removeClass("dlg-v-scroll");
        this.jui.addClass("dlg-no-scroll");
        this.jui.append(form);

        this.jui.dialog({
            autoOpen: true,
            title: "Create linked task",
            height: 200,
            width: 600,
            modal: true,
            open: function () {},
            close: function () {},
            buttons: [
                {
                    text: "Ok",
                    class: "btnDoIt",
                    click: async function () {
                        let title = await app.getCurrentTitle();
                        let type = <string>$(".inputType", that.jui).val();
                        let where = type.split("|");

                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        Tasks.postPushIssue(config.pluginId, that.item.id, title, "", where[0], where[1]).then(
                            function (newTask) {
                                that.updateUI(newTask);
                            },
                        );

                        that.jui.dialog("close");
                    },
                },
                {
                    text: "Cancel",
                    class: "btnCancelIt",
                    click: function () {
                        that.jui.dialog("close");
                    },
                },
            ],
        });
    }

    static postPushIssue(
        pluginId: number,
        itemId: string,
        title: string,
        description: string,
        projectId: string,
        taskTypeId: string,
    ): Promise<IExternalItem[]> {
        // TODO: refactor it
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            let project = matrixSession.getProject();

            let job = {
                pluginId: pluginId,
                action: "CreateIssue",
                matrixItem: {
                    project: project,
                    matrixItem: itemId,
                },
                externalItem: { externalItemTitle: title, externalDescription: description },
                more: [
                    { key: "project", value: projectId },
                    { key: "issueType", value: taskTypeId },
                ],
            };

            let cat = ml.Item.parseRef(itemId).type;
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            if (Tasks.getConfig(pluginId).catFieldMapping) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                let fm = Tasks.getConfig(pluginId).catFieldMapping[cat];

                if (fm) {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    let uMap = Tasks.getConfig(pluginId).userMapping;
                    for (let key of Object.keys(fm)) {
                        let field = globalMatrix.ItemConfig.getFieldByName(cat, key);
                        let value: string | undefined = undefined;
                        if (field && field.id) {
                            value = await app.getFieldValueAsync(field.id);
                        }
                        // current User is gotten from session
                        if (!value && fm[key].converter == "currentUser") {
                            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                            if (!uMap[matrixSession.getUser()]) {
                                ml.UI.showError(
                                    `No user mapped for user '${matrixSession.getUser()}'`,
                                    "Please ask your administrator to check that your user ID is correclty mapped in Jira Cloud extension configuration.",
                                );
                                reject("current user is not mapped in Jira");
                                return;
                            }
                            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                            value = JSON.stringify({ accountId: uMap[matrixSession.getUser()] });
                        }

                        if (value) {
                            switch (fm[key].converter) {
                                case "plaintext":
                                    value = $("<div>").html(value).text();
                                    break;
                                case "dropdown":
                                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                    if (fm[key].ddMapping && fm[key].ddMapping[value])
                                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                        value = JSON.stringify({ id: fm[key].ddMapping[value] });
                                    else {
                                        console.info("Cannot convert " + value + " to dropdown value");
                                        value = undefined;
                                    }
                                    break;

                                case "tags":
                                    value = JSON.stringify(value.replace(" ", "_").split(","));
                                    break;

                                case "labels": {
                                    // converter something to jira tags
                                    let labelList: string[] = JSON.parse(value);
                                    if (labelList && labelList.length > 0) {
                                        // whatever it is jira tags cannot have spaces
                                        value = JSON.stringify(labelList.map((l) => l.replace(" ", "_")));
                                    }

                                    break;
                                }

                                case "multiselect": {
                                    let values: any[] = value.split(",");
                                    if (fm[key].ddMapping) {
                                        let valuesMapped = values.map((v) => {
                                            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                            return { id: fm[key].ddMapping[v] };
                                        });
                                        if (
                                            // @ts-ignore is it a typo and it should be "values"?
                                            // as far as I see value can't be an array there
                                            value.filter((v) => {
                                                return v.id == null || v.id == undefined;
                                            }).length == 0
                                        ) {
                                            value = JSON.stringify(valuesMapped);
                                        } else {
                                            // there's at least one missing mapping
                                            console.info(
                                                `Cannot convert '${value}' to dropdown value -> there is a missing mapping`,
                                            );
                                            value = undefined;
                                        }
                                    } else {
                                        console.info("Cannot convert " + value + " to dropdown value");
                                        value = undefined;
                                    }

                                    break;
                                }
                                case "currentUser":
                                    // handled before switch statement
                                    break;

                                case "user":
                                    if (uMap && uMap[value]) {
                                        value = JSON.stringify({ accountId: uMap[value] });
                                    } else {
                                        console.info("Cannot convert " + value + " to mapped user");
                                        value = undefined;
                                    }
                                    break;
                            }
                        }
                        if (value != undefined) {
                            job.more.push({ key: fm[key].extFieldId, value: value });
                        }
                    }
                }
            }

            globalMatrix.wfgwConnection
                .postServer("?" + jQuery.param({ payload: JSON.stringify(job) }), job, true)
                .done(function (task) {
                    resolve(task as IExternalItem[]);
                })
                .fail(function (jqxhr, textStatus, error) {
                    Tasks.showError("Creating issue failed", jqxhr, textStatus, error);
                    reject(error);
                });
        });
    }

    subscribe() {
        let that = this;
        MR1.onAfterSave().subscribe(<any>this, function (event: IItemChangeEvent) {
            try {
                that.afterSaveHookAddComment(event);
            } catch (ex) {
                console.log("failed to update the task:");
                console.log(ex);
            }
        });
    }
    afterSaveHookAddComment(event: IItemChangeEvent): void {
        let that = this;
        let message = "";

        // message += "-------------------------------------------------------------------------- \n";

        if (event.before.title != event.after.title) {
            message += `*Title has been updated*
                            _Old value for title_
                            {{${event.before.title}}}
                            _New value for title_
                            {{${event.before.title}}}\n----\n`;
        }
        // API is not symmetric so before and after are handled differently
        let labelsBefore =
            event.before.labels && event.before.labels.length > 0 ? event.before.labels.join(",") : "[no labels set]";
        let labelsAfter = event.after.labels && event.before.labels.length > 0 ? event.after.labels : "[no labels set]";

        if (labelsBefore != labelsAfter) {
            message += `*Labels has been updated*
                        _Old value for labels_
                        {{${labelsBefore}}}
                        _New value for labels_
                        {{${labelsAfter}}}\n----\n`;
        }

        for (let key of Object.keys(event.after)) {
            // in theory we need to check both before and after keys (to handle rollbacks...)
            if (Number.isSafeInteger(parseInt(key))) {
                // event after/before field values are strings or boolean (as far as we see...)
                let valueBefore =
                    event.before[key] != undefined && event.before[key].length > 0
                        ? event.before[key]
                        : "[value not set]";
                let valueAfter =
                    event.after[key] != undefined && event.after[key].length > 0 ? event.after[key] : "[value not set]";

                if (valueBefore.toString() != valueAfter.toString()) {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    let ref = ml.Item.parseRef(that.item.id);
                    if (!ref.isFolder) {
                        let field = globalMatrix.ItemConfig.getFieldById(ref.type, parseInt(key));
                        if (field != undefined) {
                            message += `*Field '${field.label}' has been updated*
                                    _Old value for ${field.label}_
                                    {{${$("<div>").html(valueBefore).text()}}}
                                    _New value for ${field.label}_
                                    {{${$("<div>").html(valueAfter).text()}}}\n----\n`;
                        }
                    }
                }
            }
        }

        $.each(Tasks.tasksConfiguration, function (idx, config) {
            if (config.autoAddCommentOnSave) {
                that.addCommentToAllLinkedIssues(config, undefined, message, event.after.maxVersion);
            }
        });
    }

    // convert url to linked external item
    static createTaskFromUrl(itemId: string, url: string) {
        let externalItem = Tasks.externalItemFromUrl(url);
        if (externalItem) {
            Tasks.postCreateLinks(itemId, [externalItem]).done(function (createdTasks) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                mTasks.updateUI(createdTasks);
            });
        }
    }

    // check if a string is an id of an external item
    static isTaskId(someId: string) {
        let isId = false;

        $.each(Tasks.tasksConfiguration, function (tidx, config) {
            $.each(config.smartLinks, function (idx, smartLink) {
                let re = new RegExp(smartLink.regex, "g");
                let hits = someId.match(re);
                if (hits && hits[0] == someId) {
                    isId = true;
                    return;
                }
            });
        });

        return isId;
    }

    static getOne2OneTask(externalItemId: string): JQueryDeferred<IExternalItem> {
        let res: JQueryDeferred<IExternalItem> = $.Deferred();
        let handled = false;
        $.each(Tasks.tasksConfiguration, function (idx, config) {
            if (config.one2OneMapping) {
                handled = true;
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                Tasks.getMeta(config.pluginId, externalItemId)
                    .done(function (externalItem) {
                        res.resolve(externalItem);
                    })
                    .fail(function () {
                        res.reject("failed to retrieve one to one mapping status for " + externalItemId);
                    });
                return;
            }
        });
        if (!handled) {
            res.reject("no one 2 one mapping configured");
        }
        return res;
    }
    static createOne2OneTask(itemId: string): JQueryDeferred<IExternalItem> {
        let res: JQueryDeferred<IExternalItem> = $.Deferred();
        let handled = false;
        $.each(Tasks.tasksConfiguration, function (idx, config) {
            if (config.one2OneMapping) {
                handled = true;
                let taskTitle = "workflow status for " + itemId;
                let taskDescription = "";

                Tasks.postPushIssue(
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    config.pluginId,
                    itemId,
                    taskTitle,
                    taskDescription,
                    config.one2OneMapping.projectId,
                    config.one2OneMapping.taskTypeId,
                )
                    .then(function (newTasks) {
                        res.resolve(newTasks[0]);
                    })
                    .catch(function () {
                        res.reject("failed to create task for one to one mapping");
                    });
                return;
            }
        });
        if (!handled) {
            res.reject("no one 2 one mapping configured");
        }
        return res;
    }
    static getOne2OneRenderInfo(task?: IExternalItem): ITaskRenderInfo {
        let renderInfo: ITaskRenderInfo = {
            text: "click to link",
            color: "grey",
            background: "transparent",
            strikethrough: false,
        };
        if (task) {
            renderInfo.text = task.externalItemId;
            renderInfo.color = "black";
            $.each(task.more, function (idx, val) {
                if (val.key === "status") {
                    $.each(Tasks.tasksConfiguration, function (idx2, config) {
                        if (config.one2OneMapping) {
                            $.each(config.one2OneMapping.statusOverwrites, function (idx3, statusOverwrite) {
                                if (statusOverwrite.externalStatusName == val.value) {
                                    renderInfo = ml.JSON.clone(statusOverwrite);
                                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                    if (config.one2OneMapping.showId) {
                                        renderInfo.text = task.externalItemId + ":" + renderInfo.text;
                                    }
                                }
                            });
                        }
                    });
                }
            });
        }
        return renderInfo;
    }
    // ****************************************
    // Misc functions called by task control
    // to create / refresh UI components
    // ****************************************

    static showTasks(itemId: string, control: JQuery, canEdit: boolean, pluginFilter?: number[]) {
        let that = this;
        if (!Tasks.tasksConfiguration || Tasks.tasksConfiguration.length == 0) {
            control.append('<div style="color: #bbb;font-size: 12px;">no valid configuration</div>');
            return;
        }
        Tasks.getTasks(itemId, pluginFilter)
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            .done(function (tasks: IExternalItem[]) {
                Tasks.renderTasks(itemId, tasks, control, canEdit, false);
            })
            .fail(function (message) {
                if (message) {
                    ml.Logger.log("error", "Could not retrieve tasks for " + itemId);
                    ml.Logger.log("error", "Error was:" + message);
                }
            });
    }

    // ****************************************
    // private functions
    // ****************************************

    /*** UI
     *
     */

    private async createAndLinkIssueDlg(config: ITaskConfiguration) {
        let that = this;

        let options = "";
        let ocount = 0;
        // create project / type drop down menu
        $.each(config.projectsCreate, function (i, project) {
            $.each(project.taskTypes, function (j, taskType) {
                ocount++;
                let oName: string[] = [];
                if (project.projectName) oName.push(project.projectName);
                if (taskType.taskTypeName) oName.push(taskType.taskTypeName);
                options +=
                    "<option value='" +
                    project.projectId +
                    "|" +
                    taskType.taskTypeId +
                    "' " +
                    (i + j == 0 ? "selected" : "") +
                    ">" +
                    oName.join(" / ") +
                    "</option>";
            });
        });

        // define default title
        let iTitle = config.useEmptyTitle ? "" : await app.getCurrentTitle();

        // define default content
        let iContent: string = "";
        if (config.useAsDescription == "text") {
            let descriptionFields = globalMatrix.ItemConfig.getFieldsOfType("richtext", this.item.type);
            if (descriptionFields.length > 0) {
                let html = await app.getFieldValueAsync(descriptionFields[0].field.id);
                let tempdiv = $("<div style='display:none'>").html(html).appendTo("body");
                iContent = tempdiv.text();
                tempdiv.remove();
            }
        }

        let form = $(
            '<div class="container" style="width:100%;height:100%">' +
                '<div class="row optionsSelectRow" style="margin-top:4px;margin-bottom:6px">' +
                '    <div class="col-md-2" style="margin-top: 6px;"><label style="white-space: nowrap" for="inputType" class="control-label">Issue Type:</label></div>' +
                '    <div class="col-md-10"><select  class="form-control inputType" style="width:100%;height:32px" id="inputType">' +
                options +
                "        </select></div>" +
                "  </div>" +
                '  <div class="row">' +
                '    <div class="col-md-2" style="margin-top: 6px;"><label style="white-space: nowrap" for="inputTitle" class="control-label">Title:</label></div>' +
                '    <div class="col-md-10"><input autocomplete="off" type="text" class="form-control inputTitle" id="inputTitle" placeholder="enter title"></div>' +
                "  </div>" +
                '<div class="row jira-description">' +
                '    <div class="col-md-12"  style="height:100%"><textarea class="jira-textarea inputComment" placeholder="enter description">' +
                iContent +
                "</textarea></div>" +
                "</div></div>",
        );

        this.jui.html("");
        this.jui.removeClass("dlg-v-scroll");
        this.jui.addClass("dlg-no-scroll");
        this.jui.append(form);
        $(".inputTitle", this.jui).val(iTitle);
        let height = 500;
        if (config.useAsDescription == "hide") {
            $(".jira-description", this.jui).hide();
            height -= 300;
        }
        if (ocount == 1) {
            $(".optionsSelectRow", this.jui).hide();
            height -= 30;
        }
        this.jui.dialog({
            autoOpen: true,
            title: "Create linked task",
            height: height,
            width: 600,
            modal: true,
            open: function () {},
            close: function () {},
            buttons: [
                {
                    text: "Ok",
                    class: "btnDoIt",
                    click: function () {
                        let title = $("#inputTitle", that.jui).val();
                        let type = <string>$(".inputType", that.jui).val();
                        let description = $(".inputComment", that.jui).val();

                        let where = type.split("|");

                        Tasks.postCreateIssue(
                            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                            config.pluginId,
                            that.item.id,
                            title,
                            description,
                            where[0],
                            where[1],
                        ).done(function (newTask) {
                            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                            that.updateUI(newTask);
                        });

                        that.jui.dialog("close");
                    },
                },
                {
                    text: "Cancel",
                    class: "btnCancelIt",
                    click: function () {
                        that.jui.dialog("close");
                    },
                },
            ],
        });
    }

    private static createAndLinkWebDlg(config: ITaskConfiguration, task: IExternalItem, changeFunction: Function) {
        let form = $(
            '<div class="container" style="width:100%;height:100%;position:relative">' +
                '  <div class="row"><br/>' +
                "  </div>" +
                '  <div class="row">' +
                '    <div style="padding:0" class="col-md-1"><label style="white-space: nowrap;padding-top:6px" for="inputTitle" class="control-label">Text:</label></div>' +
                '    <div class="col-md-11"><input autocomplete="off" type="text" class="form-control" id="inputTitle" placeholder="enter text to display"></div>' +
                "  </div>" +
                '  <div class="row"><br/>' +
                "  </div>" +
                '  <div class="row">' +
                '    <div style="padding:0" class="col-md-1"><label style="white-space: nowrap;padding-top:6px" for="inputUrl" class="control-label">Url:</label></div>' +
                '    <div class="col-md-11"><input autocomplete="off" type="text" class="form-control" id="inputUrl" placeholder="enter link address"></div>' +
                "  </div>" +
                "</div>",
        );

        app.dlgForm.html("");
        app.dlgForm.removeClass("dlg-v-scroll");
        app.dlgForm.addClass("dlg-no-scroll");
        app.dlgForm.append(form);

        let emptyTitle = !task || !task.externalItemTitle;

        $("#inputTitle").on("mouseup keyup mouseout", function () {
            emptyTitle = $("#inputTitle").val() === "";
        });

        $("#inputUrl").on("mouseup keyup mouseout", function () {
            if (emptyTitle) {
                $("#inputTitle").val($("#inputUrl").val());
            }
            updateCanOK();
        });

        $("#doSearch").prop("disabled", true);
        app.dlgForm.dialog({
            autoOpen: true,
            title: "Add web link",
            height: 250,
            width: 600,
            modal: true,
            resizeStop: function () {},
            buttons: [
                {
                    text: "Ok",
                    class: "btnDoIt",
                    click: function () {
                        let newUrl = $("#inputUrl").val();
                        let newTitle = $("#inputTitle").val() ? $("#inputTitle").val() : newUrl;

                        changeFunction(newTitle, newUrl);

                        $("button", app.dlgForm).prop("disabled", true);
                        window.setTimeout(function () {
                            app.dlgForm.dialog("close");
                        }, 300);
                    },
                },
                {
                    text: "Cancel",
                    class: "btnCancelIt",
                    click: function () {
                        app.dlgForm.dialog("close");
                    },
                },
            ],
            open: function () {
                if (task) {
                    if (task.externalItemUrl) {
                        $("#inputUrl").val(task ? task.externalItemUrl : "");
                    }
                    if (task.externalItemTitle) {
                        $("#inputTitle").val(task ? task.externalItemTitle : "");
                    }
                }
                updateCanOK();
                $("#inputUrl").focus();
            },
        });

        function updateCanOK() {
            let okButton = $(".btnDoIt", app.dlgForm.parent());
            ml.UI.setEnabled(okButton, $("#inputUrl").val() !== "");
        }
    }

    private createSearchAndLinkIssueDlg(config: ITaskConfiguration) {
        let that = this;

        // open new page
        let win = window.open(config.nativeCreateUrl);
        this.waitForNewTaskOrWindowCloseActive = true;

        // show wait dialog
        let form = ml.UI.getSpinningWait(
            "Opened native create in other browser tab. Waiting for task to be created or tab to be closed.",
        );
        this.jui.html("");
        this.jui.removeClass("dlg-v-scroll");
        this.jui.addClass("dlg-no-scroll");
        this.jui.append(form);

        this.jui.dialog({
            autoOpen: true,
            title: "Wait for new task from other tab",
            height: 300,
            width: 600,
            modal: true,
            buttons: [
                {
                    text: "Cancel",
                    class: "btnCancelIt",
                    click: function () {
                        that.waitForNewTaskOrWindowCloseActive = false;
                        window.clearTimeout(that.waitForNewTaskOrWindowCloseTimer);
                        that.jui.dialog("close");
                    },
                },
            ],
            open: function () {},
            close: function () {
                ml.UI.setEnabled($(".btnDoIt", app.dlgForm.parent()), true);
            },
        });

        // run the config.nativeCreateSearch to get a list of hits BEFORE user can create an issue
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        Tasks.getFindTasks(config.pluginId, config.nativeCreateSearch, 0).done(function (result: ISearchResults) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            that.waitForNewTaskOrWindowClose(config, win, result);
        });
    }

    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private waitForNewTaskOrWindowCloseActive: boolean;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private waitForNewTaskOrWindowCloseTimer: number;
    private waitForNewTaskOrWindowClose(config: ITaskConfiguration, win: Window, taskSearchBefore: ISearchResults) {
        let that = this;
        if (!this.waitForNewTaskOrWindowCloseActive) {
            // the wait has been handled, e.g. by closing the dialogs
            return;
        }
        if (win.closed !== false) {
            // !== is required for compatibility with Opera
            this.waitForNewTaskOrWindowCloseActive = false;
            window.clearTimeout(this.waitForNewTaskOrWindowCloseTimer);
            that.jui.dialog("close");
            this.searchAndLinkIssueDlg(config, config.nativeCreateSearch);
        } else {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            Tasks.getFindTasks(config.pluginId, config.nativeCreateSearch, 0).done(function (result: ISearchResults) {
                if (!that.waitForNewTaskOrWindowCloseActive) {
                    // the wait has been handled, e.g. by closing the dialogs
                    return;
                }
                if (
                    result.tasks.length > taskSearchBefore.tasks.length ||
                    (result.tasks.length > 0 &&
                        result.tasks[0].externalItemId !== taskSearchBefore.tasks[0].externalItemId)
                ) {
                    that.waitForNewTaskOrWindowCloseActive = false;
                    window.clearTimeout(that.waitForNewTaskOrWindowCloseTimer);
                    that.jui.dialog("close");
                    that.searchAndLinkIssueDlg(config, config.nativeCreateSearch);
                } else {
                    that.waitForNewTaskOrWindowCloseTimer = window.setTimeout(function () {
                        that.waitForNewTaskOrWindowClose(config, win, result);
                    }, 300);
                }
            });
        }
    }

    private searchAndLinkIssueDlg(config: ITaskConfiguration, showSearch?: string) {
        let that = this;
        // help jira: https://matrixreq.atlassian.net/wiki/x/AwD3
        let jh = config.searchHelp
            ? "<a style='margin-left:10px' href='" +
              config.searchHelp +
              "' target='_blank'><span class='fal fa-info-circle'></span></a>"
            : "";
        let defaultSearch =
            config.defaultSearches && config.defaultSearches.length > 0 ? config.defaultSearches[0] : "";
        if (showSearch) {
            defaultSearch = { name: showSearch, expression: showSearch };
        }
        let form = $('<div style="width:100%;height:100%;position:relative">').append(
            this.getSearchField(config, refreshSearch),
        );

        let results = $('<div class="row taskFit">').appendTo(form);

        let moreInfo = $(
            '<div class="moreTasks" style="width: 100%;text-align: center;"><br/>enter search term</div>',
        ).appendTo(results);

        this.jui.html("");
        this.jui.removeClass("dlg-v-scroll");
        this.jui.addClass("dlg-no-scroll");
        this.jui.append(form);

        let start = 0;
        let select: IExternalItem[] = [];

        this.jui.dialog({
            autoOpen: true,
            title: "Search tasks",
            height: 500,
            width: 600,
            modal: true,
            buttons: [
                {
                    text: "Ok",
                    class: "btnDoIt",
                    click: function () {
                        ml.UI.setEnabled($(".btnDoIt", app.dlgForm.parent()), false);

                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        Tasks.postCreateLinks(that.item.id, select).done(function () {
                            that.updateUI(select);
                            that.jui.dialog("close");
                        });

                        results.html("");
                        results.append(ml.UI.getSpinningWait("creating links..."));
                        ml.UI.setEnabled($(".ui-dialog-buttonset button", that.jui.parent()), false);
                        ml.UI.setEnabled($("#appPopup button", that.jui.parent()), false);
                    },
                },
                {
                    text: "Cancel",
                    class: "btnCancelIt",
                    click: function () {
                        that.jui.dialog("close");
                    },
                },
            ],
            open: function () {
                updateCanOK();
                if (showSearch) {
                    refreshSearch(showSearch, true);
                } else if (config.defaultSearches && config.defaultSearches.length > 0 && config.autoSearch) {
                    refreshSearch(config.defaultSearches[0].expression, true);
                } else {
                    $("#inputSearch").focus();
                }
            },
            close: function () {
                ml.UI.setEnabled($(".btnDoIt", app.dlgForm.parent()), true);
            },
        });

        function updateCanOK() {
            ml.UI.setEnabled($(".btnDoIt", app.dlgForm.parent()), select.length > 0);
        }
        function refreshSearch(searchExpression: string, reset: boolean) {
            select = [];
            updateCanOK();

            moreInfo.remove();

            if (reset) {
                // user launched a new search, need to get rid of previous results
                start = 0;
            }
            if (start == 0) {
                results.html("").append(moreInfo);
            }
            moreInfo.html("").append(ml.UI.getSpinningWait("searching...")).appendTo(results);

            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            Tasks.getFindTasks(config.pluginId, searchExpression, start)
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                .done(function (result: ISearchResults) {
                    moreInfo.remove();

                    if (result.tasks.length === 0) {
                        results.append(
                            '<br/><br/><div class="moreTasks">no (more) results have been found</div><br/><br/>',
                        );
                        results.scrollTop(results[0].scrollHeight);
                        return;
                    }
                    let more = result.tasks.length == result.maxResults;

                    $.each(result.tasks, function (ridx, task) {
                        let res = $("<div style='cursor:pointer'>").append(
                            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                            Tasks.renderTask(that.item.id, task, false, true, true),
                        );
                        results.append(res);
                        res.data("task", task);
                        res.dblclick(function (event: JQueryEventObject) {
                            $(".selectedIssue").removeClass("selectedIssue");
                            select = [$(event.delegateTarget).data("task")];
                            $(event.delegateTarget).addClass("selectedIssue");
                            updateCanOK();
                            $(".ui-dialog-buttonpane button:contains('Ok')", app.dlgForm.parent()).trigger("click");
                        }).click(function (event: JQueryEventObject) {
                            // MATRIX-1367: attaching existing jira issues: allow multi select
                            // $(".selectedIssue").removeClass("selectedIssue");
                            $(event.delegateTarget).toggleClass("selectedIssue");
                            select = [];
                            $.each($(".selectedIssue"), function (idx, si) {
                                select.push($(si).data("task"));
                            });
                            updateCanOK();
                        });
                    });
                    results.highlightReferences({ noExternals: true });
                    if (more) {
                        start += result.maxResults;
                        let next = "get more results...";
                        moreInfo = $("<div class='moreTasks' style='cursor:pointer'>").html(next);
                        moreInfo.appendTo(results).click(function (event) {
                            moreInfo.html("").append(ml.UI.getSpinningWait("searching..."));
                            refreshSearch(searchExpression, false);
                        });
                    }
                    // dont scroll down to "get more results..." button:
                    // results.scrollTop(results[0].scrollHeight);
                })
                .fail(function (result) {
                    results.html(result);
                });
        }
    }

    static getConfig(pluginId: number) {
        for (let idx = 0; idx < Tasks.tasksConfiguration.length; idx++) {
            if (Tasks.tasksConfiguration[idx].pluginId == pluginId) {
                return Tasks.tasksConfiguration[idx];
            }
        }
        return null;
    }
    private renderProjectPage(options: IPluginPanelOptions) {
        let that = this;

        if (options.controlState === ControlState.Print) {
            return;
        }

        let pluginId = Number(options.type.replace("_TASKS", ""));

        options.control.html("").append(ml.UI.getSpinningWait("retrieving tasks"));

        app.waitForMainTree(() => {
            // get all tasks for plugin
            Tasks.getAllTasksProject(pluginId)
                .done(function (alltasks) {
                    let selectedFolders: string[] = [];
                    let textFilter: string = "";
                    enum TaskStatus {
                        All = "All",
                        Open = "Open",
                        Closed = "Closed",
                    }
                    let activeStatusFilter: TaskStatus = TaskStatus.All;

                    options.control.html("");

                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    if (alltasks.length === 0) {
                        options.control.append("<b>There are no tasks for this project!</b>");
                        return;
                    }

                    const folderPlaceHolderText = "Select folders to include";

                    const removeLinksByStatus = (task: IWltItemWithLinks) => {
                        if (activeStatusFilter === TaskStatus.All) {
                            return task;
                        }
                        const copy: IWltItemWithLinks = JSON.parse(JSON.stringify(task));
                        copy.links = copy.links.filter((link) => {
                            switch (activeStatusFilter) {
                                case TaskStatus.All:
                                    return true;
                                case TaskStatus.Closed:
                                    return link.externalDone;
                                case TaskStatus.Open:
                                    return !link.externalDone;
                            }
                        });
                        return copy;
                    };
                    const removeItemsWithoutLinks = (task: IWltItemWithLinks) => task.links.length > 0;
                    const removeLinksByText = (task: IWltItemWithLinks) => {
                        const loweredFilter = textFilter.toLowerCase();
                        const matrixItemMatches =
                            (task.matrixItem.title &&
                                task.matrixItem.title.toLowerCase().indexOf(loweredFilter) !== -1) ||
                            (task.matrixItem.matrixItem &&
                                task.matrixItem.matrixItem.toLowerCase().indexOf(loweredFilter) !== -1);
                        if (matrixItemMatches) {
                            // The matrix item matches, return all links
                            return task;
                        } else {
                            const copy: IWltItemWithLinks = JSON.parse(JSON.stringify(task));
                            copy.links = copy.links.filter(
                                (link) =>
                                    (link.externalItemTitle &&
                                        link.externalItemTitle.toLowerCase().indexOf(loweredFilter) !== -1) ||
                                    (link.externalDescription &&
                                        link.externalDescription.toLowerCase().indexOf(loweredFilter) !== -1) ||
                                    (link.externalItemId &&
                                        link.externalItemId.toLocaleLowerCase().indexOf(loweredFilter) !== -1),
                            );
                            return copy;
                        }
                    };

                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    let tableItem: JQuery = null;
                    const renderTable = () => {
                        const buttonText =
                            selectedFolders.length > 0 ? selectedFolders.join(",") : folderPlaceHolderText;
                        folderSelectionButton.text(buttonText);
                        if (tableItem) tableItem.remove();
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        const filteredTasks = alltasks
                            .map(removeLinksByStatus)
                            .filter(removeItemsWithoutLinks)
                            .map(removeLinksByText)
                            .filter(removeItemsWithoutLinks);

                        // console.log("Filtered tasks", filteredTasks);

                        tableItem = Tasks.renderTasksInTable(filteredTasks, selectedFolders, (folderAdd: Folder) => {
                            if (selectedFolders.indexOf(folderAdd.id) === -1) {
                                selectedFolders.push(folderAdd.id);
                            }
                            renderTable();
                        });
                        options.control.append(tableItem);
                    };

                    const filterToolbar = $("<div id='taskFilterToolbar' style='padding: 5px;display: flex;'>");

                    const itemSelection = new ItemSelectionTools();
                    const categories = globalMatrix.ItemConfig.getCategories(true).map((cat) => ({ type: cat }));
                    const selectOptions: IItemSelectDialogOptions = {
                        selectMode: SelectMode.autoPrecise,
                        linkTypes: categories,
                        focusOn: globalMatrix.ItemConfig.getCategories(true)[0],
                        autoScroll: false,
                        getSelectedItems: async () => selectedFolders.map((folder) => ({ to: folder, title: folder })), // is called before dialog is opened to get currently selected items
                        selectionChange: (newSelection: IReference[]) => {
                            // console.log("Selected items", newSelection);
                            selectedFolders = newSelection.map((selection) => selection.to);
                            renderTable();
                        },
                    };

                    const treeFilterGroup = $("<div class='btn-group' style='max-width: 75%; display: flex;'>");
                    const folderSelectionButton = $(
                        "<button class='btn btn-default btn-xs' style='text-align: left; " +
                            "overflow:hidden; text-overflow: ellipsis; min-width: 20em;'>",
                    )
                        .text(folderPlaceHolderText)
                        .click(() => {
                            itemSelection.showDialog(selectOptions);
                        });
                    treeFilterGroup.append(folderSelectionButton);

                    const clearButton = $(
                        "<button class='btn btn-default btn-xs' style='flex-grow: 0; flex-shrink: 0;'>",
                    )
                        .text("Clear")
                        .click(() => {
                            selectedFolders = [];
                            renderTable();
                        });
                    treeFilterGroup.append(clearButton);
                    filterToolbar.append(treeFilterGroup);

                    const selectStatus = $(
                        "<select class='form-control input-sm' style='height: 22px; width: 12em; margin: 0; margin-right: 5px;'>",
                    ).change((ev) => {
                        activeStatusFilter = $(ev.target).val();
                        renderTable();
                    });
                    Object.keys(TaskStatus).forEach((value) =>
                        selectStatus.append(
                            `<option ${activeStatusFilter == value ? "selected" : ""}>${value}</option>`,
                        ),
                    );
                    filterToolbar.append(selectStatus);

                    // From https://davidwalsh.name/javascript-debounce-function
                    function debounce(func: (...args: any[]) => void, wait: number, immediate: boolean = false) {
                        let timeout: number;
                        return function (this: any) {
                            // TODO: fix it
                            // eslint-disable-next-line @typescript-eslint/no-this-alias
                            let context = this,
                                // eslint-disable-next-line prefer-rest-params
                                args = arguments;
                            let later = function () {
                                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                timeout = null;
                                if (!immediate) {
                                    func.apply(context, [...args]);
                                }
                            };
                            let callNow = immediate && !timeout;
                            clearTimeout(timeout);
                            timeout = window.setTimeout(later, wait);
                            if (callNow) {
                                func.apply(context, [...args]);
                            }
                        };
                    }
                    const filterTextInput = $(
                        "<input type='text' class='form-control input-sm' placeholder='Filter by Title' " +
                            "style='height: 22px;margin-top: 0px;'>",
                    )
                        .val(textFilter)
                        .keyup(
                            debounce(function (ev) {
                                textFilter = $(ev.target).val();
                                renderTable();
                            }, 250),
                        );
                    filterToolbar.append(filterTextInput);
                    $(
                        "<i class='fal fa-file-excel hideCopy' data-title='Export to excel' style='cursor: pointer;padding-left: 10px;font-size: 20px;'></i>",
                    )
                        .appendTo(filterToolbar)
                        .click(() => {
                            const exportToExcel = (text: string, fileName: string) => {
                                const hiddenElement = document.createElement("a");
                                const date = new Date();
                                hiddenElement.href = "data:xls/plain;charset=utf-8," + encodeURIComponent(text);
                                hiddenElement.download = `${fileName}-${date.toISOString()}.xls`;
                                hiddenElement.click();
                            };

                            let tableHtml = $(".result-table").clone();
                            //remove 6th column
                            tableHtml.find("th:nth-child(6)").remove();
                            tableHtml.find("td:nth-child(6)").remove();
                            tableHtml.find(".ticket-icon").remove();
                            for (let status of tableHtml.find(".taskStatus").toArray()) {
                                if ($(status).text() !== "") {
                                    $(status).text(` [${$(status).text()}]`);
                                }
                            }

                            exportToExcel(tableHtml[0].outerHTML, "Tasks");
                        });

                    options.control.append(filterToolbar);
                    renderTable();
                })
                .fail(function (errorMsg) {
                    options.control.html("");

                    options.control.append("<b>Failed to retrieve tasks!</b><br>Error was:" + errorMsg);
                });
        });
    }

    /* helper */
    private updateUI(tasks: IExternalItem[]) {
        let that = this;

        let ctrls = matrixApplicationUI.lastMainItemForm.getControls("tasksControl");
        for (let idx = 0; idx < ctrls.length; idx++) {
            let filter = (<TasksControlImpl>ctrls[idx].getController()).getPluginFilter();
            let ul = $(".ticket-list", ctrls[idx]);
            if (ul.length == 0) {
                // list still empty
                ul = $("<ul class='ticket-list baseControl'>");
                $("ul", ctrls[idx]).replaceWith(ul);
            }
            $.each(tasks, function (idx, task) {
                if (!filter || filter.length == 0 || filter.indexOf(task.plugin) !== -1) {
                    // Check if the task is already displayed
                    const matchingTasks = ul.children().filter((index, child: Element) => {
                        const segments = (child as HTMLElement).innerText.split("\n");
                        return segments.length > 0 && segments[0] == task.externalItemId;
                    });
                    if (matchingTasks.length == 0) {
                        let item = $("<li class='taskDisplayContainer'>").append(
                            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                            Tasks.renderTask(that.item.id, task, true, false),
                        );
                        ul.append(item);
                    }
                }
            });
        }
    }

    private static getTaskDefinition(task: IExternalItem): ITaskType {
        let res: ITaskType;
        $.each(Tasks.tasksConfiguration, function (tci, tc) {
            $.each(tc.projectsCreate, function (i, project) {
                if (project.projectId == task.externalProject) {
                    $.each(project.taskTypes, function (j, taskType) {
                        if (taskType.taskTypeId == task.externalType) {
                            res = taskType;
                        }
                    });
                }
            });
        });
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        return res;
    }

    // render a bunch of tasks in a list
    static renderTasks(
        itemId: string,
        linkedTasks: IExternalItem[],
        root: JQuery,
        canEdit: boolean,
        fullWidth: boolean,
    ) {
        if (linkedTasks.length > 0) {
            const items = $("<ul class='ticket-list'></ul>").addClass("baseControl");
            // remove any previous tasks that could be already displayed
            root.empty();
            root.append(items);

            for (let tidx = 0; tidx < linkedTasks.length; tidx++) {
                let item = $("<li class='taskDisplayContainer'>").append(
                    Tasks.renderTask(itemId, linkedTasks[tidx], canEdit, fullWidth),
                );
                items.append(item);
            }
        } else {
            const items = $("<ul style='margin-top:0px;margin-bottom:0px'></ul>");
            root.append(items);
            items.append($("<li>").append('<div style="color: #bbb;font-size: 12px;">no tasks linked</div>'));
        }
    }

    // From Tom Gruner @ http://stackoverflow.com/a/12034334/1660815
    private static escapeHtml(source: string): string {
        let entityMap: any = {
            "<": "&lt;",
            ">": "&gt;",
            '"': "&quot;",
            "'": "&#39;",
            "/": "&#x2F;",
        };
        return String(source).replace(/[<>"'/]/g, (s) => entityMap[s]);
    }

    static renderTaskParts(
        itemId: string,
        task: IExternalItem,
        unlink: boolean,
        fullWidth: boolean,
        tinyLink?: boolean,
    ): ITaskPart {
        let ret: ITaskPart = {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            icon: null,
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            id: null,
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            ticketTitle: null,
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            externalUser: null,
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            actions: null,
        };
        function showDescription(id: JQuery, task: IExternalItem) {
            let prefix = "";
            if (task.externalProject) {
                prefix = task.externalProject + "-";
            }
            ml.UI.showTaskAsTooltip(
                prefix + task.externalItemId,
                task.externalItemTitle,
                task.externalItemUrl,
                "<div>" + ml.UI.getSpinningWait("loading description...").html() + "</div>",
                id,
            );

            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            Tasks.getMeta(task.plugin, task.externalItemId).done(function (externalData: IExternalItem) {
                let status = "";
                if (task.externalMeta) {
                    // "{"issueType":"Task","status":"Backlog"}"
                    let metaJson: IExternalMeta = JSON.parse(task.externalMeta);
                    status = `<span class='taskStatus' >${metaJson.status}</span> `;
                }
                ml.UI.showTaskAsTooltip(
                    prefix + task.externalItemId,
                    task.externalItemTitle,
                    task.externalItemUrl,
                    `<div> ${externalData.externalDescription} </div><span style="position:absolute;top:6px;right:18px;" >${status}</span>`,
                    id,
                );
            });
            return "";
        }

        let taskTypeDef = Tasks.getTaskDefinition(task);
        let config = this.getConfig(task.plugin);

        if (!config) {
            return ret;
        }
        let hiddenStyle = "";
        // there is no external project known here when showing external links from server... so let's not do anything
        //if ( config.projectFilter && config.projectFilter.length > 0 && config.projectFilter.indexOf(task.externalProject) == -1) {
        //    hiddenStyle = "color:#ccc !important";
        //}
        let isWebLink = config && config.handleAsLink;

        let icon = '<i class="ticket-icon-icon fal fa-thumbtack">';
        if (isWebLink) {
            let fixIcon = "$(this).css('display','none').parent().addClass('fal fa-external-link')";
            icon = '<i class="ticket-icon-icon fal fa-external-link">';
            try {
                icon =
                    '<span><img class="" src="https://www.google.com/s2/favicons?domain_url=' +
                    encodeURIComponent(new URL(task.externalItemUrl).hostname) +
                    '" onerror="' +
                    fixIcon +
                    '" /></span>';
            } catch (e) {
                // probably a bad URL
            }
        }
        if (taskTypeDef && taskTypeDef.iconClass) {
            icon = '<i class="ticket-icon-icon ' + taskTypeDef.iconClass + '">';
        } else if (taskTypeDef && taskTypeDef.iconUrl) {
            icon = '<img class="ticket-icon-icon" src="' + taskTypeDef.iconUrl + '">';
        }

        ret.icon = $("<span class='ticket-icon'>" + icon + "<span class='ticket-key'>");
        if (isWebLink) {
            let id = $(
                `<span title='${config.pluginName}' class='ticket-id'><b style='${
                    task.externalDone ? "text-decoration:line-through" : ""
                }'>${task.externalItemTitle}</b></span>`,
            );
            id.tooltip({ placement: "bottom", container: "body" });
            ret.id = id;

            if (task.externalItemUrl) {
                id.data("url", task.externalItemUrl).click(function (event) {
                    window.open($(event.delegateTarget).data("url"), "_blank");
                });
            }
        } else {
            let ttip = "hold shift to see a preview";
            if (!config.hasMeta) {
                ttip = "click to follow link";
            }

            // tiny links are used to have a selectable row in jira addon for example (when clicking on ticket it should not follow the link but select the row)
            let id = tinyLink
                ? $(
                      `<span class='tinylinkc'><b style='${hiddenStyle};${
                          task.externalDone ? "text-decoration: line-through" : ""
                      }'>${
                          task.externalItemId
                      }</b><a class='ticket-id fas fa-arrow-to-right' data-original-title="${ttip}" title="${ttip}"></a></span>`,
                  )
                : $(
                      `<a class='ticket-id' data-original-title="${ttip}" title="${ttip}"><b style='${hiddenStyle};${
                          task.externalDone ? "text-decoration: line-through" : ""
                      }'>${task.externalItemId}</b></a>`,
                  );

            id.tooltip({ placement: "bottom", container: "body" });

            if (config.hasMeta) {
                id.hover(
                    () => {
                        if (globalMatrix.globalShiftDown) showDescription(id, task);
                    },
                    () => {
                        if (!globalMatrix.globalShiftDown) {
                            setTimeout(() => {
                                ml.UI.hideTooltip();
                            }, 1000);
                        }
                    },
                );
            }

            ret.id = id;

            if (task.externalItemUrl) {
                let clickHandler = tinyLink ? $("a", id) : id;

                clickHandler.data("url", task.externalItemUrl).click(function (event) {
                    window.open($(event.delegateTarget).data("url"), "_blank");

                    if (event.preventDefault) event.preventDefault();
                    if (event.stopPropagation) event.stopPropagation();
                });
            }

            ret.ticketTitle = $(
                "<span class='ticket-title " +
                    (fullWidth ? "ticket-title-full" : "") +
                    "'>" +
                    Tasks.escapeHtml(task.externalItemTitle) +
                    "</span>",
            );
            if (config.hasMeta && config.showStatus) {
                if (task.externalMeta) {
                    // "{"issueType":"Task","status":"Backlog"}"
                    let metaJson: IExternalMeta = JSON.parse(task.externalMeta);
                    ret.ticketTitle.append(`<span class='taskStatus' >${metaJson.status}</span> `);
                }
            }
        }
        // TODO : remove external user as it's not populated from the rest call.
        if (task.externalUser) {
            ret.externalUser = $("<span class='ticket-user'>(" + task.externalUser + ")</span>");
        }

        if (unlink) {
            let ulb = $("<span style='padding-left:20px' title='remove link'><i class='fal fa-unlink'></i></span>")
                .click(function (event: JQueryEventObject) {
                    let theButton = $(event.delegateTarget);
                    let theRow = theButton.closest(".taskDisplayContainer");
                    let theItem = theButton.data("itemId");
                    let theTask: IExternalItem = <IExternalItem>theButton.data("task");
                    ml.UI.showConfirm(
                        3,
                        {
                            title: "Remove link to '" + theTask.externalItemId + " " + theTask.externalItemTitle + "'?",
                            ok: "Remove",
                        },
                        function () {
                            theButton.replaceWith($("<span class='fal fa-sync-alt refresh-animate'>"));
                            Tasks.deleteLink(theItem, theTask)
                                .done(function () {
                                    theRow.css("display", "none");
                                })
                                .fail(function (message) {
                                    ml.UI.showError("Cannot remove link: ", message);
                                });
                        },
                        function () {
                            // nothing to do...
                        },
                    );
                })
                .tooltip()
                .data("task", task)
                .data("itemId", itemId);

            ret.actions = $("<span class='ticket-unlink'>").append(ulb);

            if (isWebLink) {
                let editb = $("<span style='padding-left:20px' title='edit link'><i class='fal fa-pencil'></i></span>")
                    .click(function (event: JQueryEventObject) {
                        let theButton = $(event.delegateTarget);
                        let theRow = theButton.closest(".taskDisplayContainer");
                        let theItem = theButton.data("itemId");
                        let theConfig = theButton.data("config");
                        let theTask: IExternalItem = <IExternalItem>theButton.data("task");
                        Tasks.createAndLinkWebDlg(theConfig, task, function (linkTitle: string, linkUrl: string) {
                            // create a copy of the old task (to delete it later)
                            let oldTask = ml.JSON.clone(theTask);
                            // update the task
                            theTask.externalItemId = "" + new Date().getTime();
                            theTask.externalItemTitle = linkTitle;
                            theTask.externalItemUrl = linkUrl;

                            Tasks.postCreateLinks(theItem, [theTask]).done(function (results) {
                                $.each(results, function (tidx, task) {
                                    // overwrite the task with the data fromt he server (e.g. the plugin might change the done status)
                                    if (task.externalItemId == theTask.externalItemId) {
                                        theTask = task;
                                    }
                                });
                                Tasks.deleteLink(theItem, oldTask).done(function () {
                                    $(".ticket-id", theRow)
                                        .data("url", theTask.externalItemUrl)
                                        .html(
                                            "<b style='" +
                                                (theTask.externalDone ? "text-decoration: line-through" : "") +
                                                "'>" +
                                                theTask.externalItemTitle +
                                                "</b></span>",
                                        );
                                    theButton.data("task", theTask);
                                });
                            });
                        });
                    })
                    .tooltip()
                    .data("config", config)
                    .data("task", task)
                    .data("itemId", itemId);

                ret.actions.append("<span class='ticket-unlink'>").append(editb);
            }
        }

        return ret;
    }

    // render a single task, maybe with option to unlink
    static renderTask(
        itemId: string,
        task: IExternalItem,
        unlink: boolean,
        fullWidth: boolean,
        tinyLink?: boolean,
    ): JQuery {
        let ret = $("<span class='taskDisplay'>").data("externalItemId", task.externalItemId);

        let parts = Tasks.renderTaskParts(itemId, task, unlink, fullWidth, tinyLink);
        if (parts.icon != null) {
            ret.append(parts.icon);
        }
        if (parts.id != null) {
            ret.append(parts.id);
        }
        if (parts.ticketTitle != null) {
            ret.append(parts.ticketTitle);
        }
        if (parts.externalUser != null) {
            ret.append(parts.externalUser);
        }
        if (parts.actions != null) {
            ret.append(parts.actions);
        }

        return ret;
    }

    private getSearchField(
        config: ITaskConfiguration,
        searchFunction: (searchExpression: string, reset: boolean) => any,
    ): JQuery {
        let that = this;

        let inputSpace = $('<div class="input-group rowFlex rowFlexNoWrapNoGap" >');
        // TODO: convert to const and make sure it's still works
        // eslint-disable-next-line no-var
        var textInput = $(
            '<input autocomplete="off" type="text" autofocus="autofocus" name="search" placeholder="Search..." class="form-control searchNoX" style="height:36px">',
        )
            .appendTo(inputSpace)
            .on("mouseup keyup mouseout", function () {
                mir_btn.prop("disabled", !config.allowEmptySearches && (<string>textInput.val()).length < 3);
            })
            .keypress(function (e) {
                if (e.which == 13 && (config.allowEmptySearches === true || (<string>textInput.val()).length >= 3)) {
                    mir_btn.trigger("click");
                }
            });

        // TODO: convert to const and make sure it's still works
        // eslint-disable-next-line no-var
        var mir_btn = $(
            '<button class="btn btn-item taskSearch" type="button" data-toggle="tooltip" data-placement="bottom" title="Search for tasks"><i style="position:relative;top:4px;color:#bbb" class="fal fa-file-alt"></i><i style="position:relative;margin-left:-7px" class="fal fa-search"></button>',
        )
            .appendTo(inputSpace)
            .click(function () {
                searchFunction(textInput.val(), true);
            })
            .prop("disabled", true);

        let dropDown = $(
            '<button class="btn btn-item taskDropdown" type="button" data-toggle="dropdown"><span class="caret"></span></button>',
        );
        let ul = $(' <ul class="dropdown-menu dropdown-menu-sub pull-right role="menu"> ');

        $.each(config.defaultSearches, function (idx, search) {
            if (search.name) {
                $(
                    '<li title="Search for ' +
                        search.expression +
                        '"><a href="javascript:void(0)">' +
                        search.name +
                        "</a></li>",
                )
                    .appendTo(ul)
                    .data("expression", search.expression)
                    .click(function (event: JQueryEventObject) {
                        textInput.val($(event.delegateTarget).data("expression"));
                        mir_btn.trigger("click");
                    });
            }
        });

        if (config.defaultSearches && config.defaultSearches.length > 0 && config.defaultSearches[0].name) {
            textInput.val(config.defaultSearches[0].expression);
        }

        if (config.searchHelp) {
            let help = $(
                '<li title="Search help" ><a class="documentationLink" href="javascript:void(0)">Search Help</a></li>',
            );
            ul.append(help);
            help.click(function () {
                window.open(config.searchHelp);
            });
        }

        if (ul.children().length > 0) {
            inputSpace.append($("<div class='taskSearchAddon taskDropDownGroup'>").append(dropDown).append(ul));
        }

        return inputSpace;
    }

    // this function receives a list of smart links to tasks as defined by the user in test results
    // it must take these tasks and create something the server can link to
    private createLinksAsync(linksToBeCreated: string[]): JQueryDeferred<{}> {
        let that = this;

        let linkJobs: IExternalItem[] = [];

        // build a list of link job tupels, which plugin and with which 'command'
        $.each(Tasks.tasksConfiguration, function (tidx, config) {
            $.each(config.smartLinks, function (sidx, smartLink) {
                $.each(linksToBeCreated, function (idx, ltc) {
                    let re = new RegExp(smartLink.regex);
                    let ma = ltc.match(re);
                    if (ma) {
                        // it matches: replace all placeholders, $0, $1, $2, with matching groups
                        let issueProjectId = smartLink.issueProjectId;
                        let issueId = smartLink.issueId;
                        let url = smartLink.url;
                        let title = smartLink.title;
                        for (let i = 0; i < Math.min(ma.length, 10); i++) {
                            let rep = new RegExp("\\$" + i, "g");
                            issueProjectId = issueProjectId.replace(rep, ma[i]);
                            issueId = issueId.replace(rep, ma[i]);
                            url = url.replace(rep, ma[i]);
                            title = title.replace(rep, ma[i]);
                        }
                        linkJobs.push({
                            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                            plugin: config.pluginId,
                            externalProject: issueProjectId,
                            externalItemId: issueId,
                            externalItemUrl: url,
                            externalDescription: "",
                            externalItemTitle: title,
                            externalDone: false,
                            externalType: "",
                        });
                    }
                });
            });
        });

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        return Tasks.postCreateLinks(this.item.id, linkJobs).done(function (createdTasks) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            that.updateUI(createdTasks);
        });
    }

    /** rest api */

    // this links one matrix item to multiple issues in multiple plugins
    // not private: used by jiraPlugin.js
    static postCreateLinks(itemId: string, tasksToLink: IExternalItem[]): JQueryDeferred<IExternalItem[]> {
        let res: JQueryDeferred<IExternalItem[]> = $.Deferred();

        let project = matrixSession.getProject();
        $.each(tasksToLink, function (tidx, task) {
            if (!task.externalItemId || task.externalItemId == " ") {
                task.externalItemId = "" + new Date().getTime();
            }
        });
        let job = {
            action: "CreateLinks",
            matrixItem: {
                project: project,
                matrixItem: itemId,
            },
            externalItems: tasksToLink,
        };

        globalMatrix.wfgwConnection
            .postServer("?" + jQuery.param({ payload: JSON.stringify(job) }, true))
            .done(function (results) {
                let nt: ITaskDetails[] = [];
                $.each(tasksToLink, function (idx: number, link: IExternalItem) {
                    (<ITaskDetails>link).matrixItemIds = [itemId];
                    nt.push(<ITaskDetails>link);
                });
                res.resolve(nt);
            })
            .fail(function (jqxhr: IJcxhr, textStatus: string, error: string) {
                Tasks.showError("Creating link(s) to issue(s) failed", jqxhr, textStatus, error);
                res.reject(jqxhr);
            });

        return res;
    }

    // this creates an issue in a specified plugin and links it to the item
    static postCreateIssue(
        pluginId: number,
        itemId: string,
        title: string,
        description: string,
        projectId: string,
        taskTypeId: string,
    ): JQueryDeferred<IExternalItem[]> {
        let res: JQueryDeferred<IExternalItem[]> = $.Deferred();
        let project = matrixSession.getProject();
        // Tasks.getConfig(pluginId).createBacklinks;

        let job = {
            pluginId: pluginId,
            action: "CreateIssue",
            matrixItem: {
                project: project,
                matrixItem: itemId,
            },
            externalItem: { externalItemTitle: title, externalDescription: description },
            more: [
                { key: "project", value: projectId },
                { key: "issueType", value: taskTypeId },
            ],
        };
        globalMatrix.wfgwConnection
            .postServer("?" + jQuery.param({ payload: JSON.stringify(job) }), job, true)
            .done(function (task) {
                res.resolve(task as IExternalItem[]);
            })
            .fail(function (jqxhr, textStatus, error) {
                Tasks.showError("Creating issue failed", jqxhr, textStatus, error);
            });

        return res;
    }

    // this gets linked tasks of a matrix item from multiple plugins. if no item is specified it gets all tasks...
    // not private: used by jiraPlugin.js
    static getTasks(itemId?: string, pluginFilter?: number[]): JQueryDeferred<IExternalItem[]> {
        let res: JQueryDeferred<IExternalItem[]> = $.Deferred();

        let project = matrixSession.getProject();

        let job = {
            action: "GetIssues",
            matrixItem: {
                project: project,
                matrixItem: itemId,
            },
        };

        let nt: IExternalItem[] = [];

        globalMatrix.wfgwConnection
            .getServer("?" + jQuery.param({ payload: JSON.stringify(job) }, true))
            .done(function (links) {
                $.each(links as IWltItemWithLinks[], function (idx, hit) {
                    $.each(hit.links, function (jdx, link) {
                        if (!pluginFilter || pluginFilter.length == 0 || pluginFilter.indexOf(link.plugin) != -1) {
                            nt.push(link);
                        }
                    });
                });
                res.resolve(nt);
            })
            .fail(function (jqxhr: IJcxhr, textStatus: string, error: string) {
                Tasks.showError("Getting issues failed", jqxhr, textStatus, error);
            });

        return res;
    }

    static getAllTasksProject(plugin: number): JQueryDeferred<IWltItemWithLinks[]> {
        let res: JQueryDeferred<IWltItemWithLinks[]> = $.Deferred();

        let project = matrixSession.getProject();

        let job = {
            pluginId: plugin,
            action: "GetIssues",
            matrixItem: {
                project: project,
            },
        };

        globalMatrix.wfgwConnection
            .getServer("?" + jQuery.param({ payload: JSON.stringify(job) }, true))
            .done(function (links) {
                res.resolve(links as IWltItemWithLinks[]);
            })
            .fail(function (jqxhr: IJcxhr, textStatus: string, error: string) {
                Tasks.showError("Getting all issues failed", jqxhr, textStatus, error);
            });

        return res;
    }

    // this finds tasks in one plugin

    private static getFindTasks(pluginId: number, search: string, startAt: number): JQueryDeferred<ISearchResults> {
        let res: JQueryDeferred<ISearchResults> = $.Deferred();

        let project = matrixSession.getProject();

        let job = {
            pluginId: pluginId,
            action: "FindIssues",
            matrixItem: {
                project: project,
            },
            startAt: startAt,
            searchTerm: search,
        };

        globalMatrix.wfgwConnection
            .getServer("?" + jQuery.param({ payload: JSON.stringify(job) }, true))
            .done(function (results) {
                res.resolve({ startAt: startAt, maxResults: 50, tasks: results as IExternalItem[] });
            })
            .fail(function (jqxhr: IJcxhr, textStatus: string, error: string) {
                Tasks.showError("Search failed", jqxhr, textStatus, error);
            });

        return res;
    }
    static showError(text: string, jqxhr: IJcxhr, textStatus: string, error: string) {
        let details = ml.UI.getDisplayError(jqxhr, textStatus, error);
        if (details) {
            ml.UI.showError(text, details);
        } else {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            ml.UI.showError(text, jqxhr ? jqxhr.statusText : "Server error");
        }
    }

    static deleteLink(itemId: string, task: IExternalItem): JQueryDeferred<{}> {
        let res = $.Deferred();

        let project = matrixSession.getProject();

        let job = {
            pluginId: task.plugin,
            action: "BreakLinks",
            matrixItem: {
                project: project,
                matrixItem: itemId,
            },
            externalItems: [task],
        };

        globalMatrix.wfgwConnection
            .deleteServerAsync("", job, true)
            .done(function () {
                res.resolve();
            })
            .fail(function (jqxhr: IJcxhr, textStatus: string, error: string) {
                Tasks.showError("Removing link failed", jqxhr, textStatus, error);
            });

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        return res;
    }

    static getMeta(pluginId: number, externalItemId: string): JQueryDeferred<IExternalItem> {
        let res: JQueryDeferred<IExternalItem> = $.Deferred();

        let project = matrixSession.getProject();

        let job = {
            pluginId: pluginId,
            action: "GetMeta",
            matrixItem: {
                project: project,
            },
            externalItem: {
                externalItemId: externalItemId,
            },
        };

        globalMatrix.wfgwConnection
            .getServer("?" + jQuery.param({ payload: JSON.stringify(job) }, true))
            .done(function (result) {
                res.resolve(result as IExternalItem);
            })
            .fail(function (jqxhr: IJcxhr, textStatus: string, error: string) {
                Tasks.showError("Getting meta info of issue failed", jqxhr, textStatus, error);
            });

        return res;
    }

    static fillTree(tree: IDB[], alltasks: IWltItemWithLinks[]): FolderItem[] {
        return tree.reduce<FolderItem[]>((accumulator: FolderItem[], item: IDB): FolderItem[] => {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            if (item.id.indexOf("_") === 0) {
                // Ingore these
            } else if (item.id && item.id.indexOf("F-") === 0) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                const children = Tasks.fillTree(item.children, alltasks);
                if (children.length > 0) {
                    accumulator.push({
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        name: item.title,
                        id: item.id,
                        children: children,
                    });
                }
            } else {
                const issues = alltasks.filter((taskItem) => taskItem.matrixItem.matrixItem === item.id);
                if (issues.length > 0) {
                    if (issues.length !== 1) {
                        console.error("Filter by ID returned more than 1 result. Check!!!", item.id);
                    }
                    const issueItem = issues[0];
                    accumulator.push(issueItem);
                }
            }
            return accumulator;
        }, []);
    }

    static isFolder(item: FolderItem): item is Folder {
        return item.hasOwnProperty("name");
    }

    static appendIssueItems(
        parentElement: JQuery,
        folderItems: FolderItem[],
        selectedFolders: string[],
        folderChangeCallback: (folder: Folder) => void,
        folders: Folder[] = [],
    ): void {
        folderItems.forEach((item: FolderItem) => {
            if (Tasks.isFolder(item)) {
                Tasks.appendIssueItems(
                    parentElement,
                    item.children,
                    selectedFolders,
                    folderChangeCallback,
                    folders.concat(item),
                );
            } else {
                $.each(item.links, function (midx, task) {
                    const tr = $("<tr class='taskDisplayContainer'>");
                    const folderColumn = $("<td>");
                    const previousFolders: Folder[] = [];
                    folders.forEach((folder) => {
                        if (previousFolders.length !== 0) {
                            folderColumn.append($("<span>/</span>"));
                        }
                        previousFolders.push(folder);
                        const folderLink = $(
                            "<span style='cursor: pointer; color: var(--BlueLink);font-weight: 700;font-size: 14px;'>",
                        )
                            .text(folder.name)
                            .click((ev) => {
                                // console.log("Click on folder", previousFolders.join("/"));
                                folderChangeCallback(folder);
                            });
                        folderColumn.append(folderLink);
                    });
                    const matrixId = $("<td>").append(ml.Item.renderLink(item.matrixItem.matrixItem, " "));
                    const matrixTitle = $("<td>").append(item.matrixItem.title);

                    let taskParts = Tasks.renderTaskParts(item.matrixItem.matrixItem, task, true, false);
                    const linkID = $("<td>").append(taskParts.icon).append(taskParts.id);

                    const linkActions = taskParts.actions;
                    const linkTitle = $("<td>").append(taskParts.ticketTitle);
                    const thisFolderPath = previousFolders.map((f) => f.id).join("/");

                    const creationDate = $("<td>");
                    if (task.externalLinkCreationDate !== undefined) {
                        creationDate.text(
                            ml.UI.DateTime.renderCustomerHumanDate(moment(task.externalLinkCreationDate).toDate()),
                        );
                    }

                    if (Tasks.thisFolderPathIsInSelection(thisFolderPath, selectedFolders)) {
                        tr.append(folderColumn)
                            .append(matrixId)
                            .append(matrixTitle)
                            .append(linkID)
                            .append(linkTitle)
                            .append(linkActions)
                            .append(creationDate);

                        parentElement.append(tr);
                    }
                });
            }
        });
    }

    private static renderTasksInTable(
        alltasks: IWltItemWithLinks[],
        selectedFolders: string[],
        folderChangeCallback: (folder: Folder) => void,
    ): JQuery {
        const tableContainer = $(
            "<div id='tablecontainer' style='height: 100%; overflow: hidden; padding-bottom: 15px;'>",
        );
        let table = $(
            "<table class='table result-table'><thead><tr><th>Folder</th><th>Matrix Id</th><th>Matrix Title</th><th>Task Id</th><th>Task title</th><th></th><th>Link date</th></tr></thead></table>",
        );
        let items = $("<tbody>");
        table.append(items);

        const expandedFolders = Tasks.expandFolders(app.getTree(), selectedFolders, []);
        // console.log("Expanded Folders", expandedFolders);

        let folderItems: FolderItem[] = Tasks.fillTree(app.getTree(), alltasks);
        // console.log("Item Tree:", folderItems);

        Tasks.appendIssueItems(items, folderItems, expandedFolders, folderChangeCallback);

        let scroller = $("<div class='panel-body-v-scroll' style='height:100%'>");
        tableContainer.append(scroller.append(table));
        table.tablesorter({
            sortList: [
                [0, 0],
                [1, 0],
                [2, 0],
            ],
        });
        return tableContainer;
    }

    private static expandFolders(tree: IDB[], selectedFolders: string[], prefix: string[]): string[] {
        const folders: string[] = [];
        tree.forEach((treeitem) => {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            if (treeitem.id.indexOf("F-") === 0) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                const newPrefix = prefix.concat(treeitem.id);
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                if (selectedFolders.indexOf(treeitem.id) !== -1) {
                    folders.push(newPrefix.join("/"));
                }
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                folders.push(...Tasks.expandFolders(treeitem.children, selectedFolders, newPrefix));
            }
        });
        return folders;
    }

    private static thisFolderPathIsInSelection(thisFolderPath: string, selectedFolders: string[]): boolean {
        if (selectedFolders.length === 0) {
            return true;
        }
        const matchingFolders = selectedFolders.filter((folder) => thisFolderPath.indexOf(folder) === 0);
        return matchingFolders.length > 0;
    }
}

let mTasks: Tasks;

function InitializeTasks() {
    mTasks = new Tasks();
    // @ts-ignore TODO: get rid of global
    globalThis.Tasks = mTasks;
    // register the engine as plugin
    plugins.register(mTasks);
    mTasks.subscribe();
}
