import React, { FC } from "react";
import { IDashboardLinkForAnalytics } from "../../../../../sdk/plugins/interfaces";
import { globalMatrix, matrixSession } from "../../../../globals";
type Props = { links: IDashboardLinkForAnalytics[] };
export let LinkListDisplay: FC<Props> = (props: Props) => {
    return (
        <div id={"pluginList"}>
            <ul>
                {props.links.map((link) => {
                    return (
                        <li key={link.pageId}>
                            <a href={`${globalMatrix.matrixBaseUrl}/${matrixSession.getProject()}/${link.pageId}`}>
                                {link.title}
                            </a>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};
