import { IDHFControlDefinitionValue, mDHF } from "../../businesslogic/index";
import { BaseControl } from "./BaseControl";
import { ISignature } from "./docReview";
import { ml } from "./../../matrixlib";
import { IItemGet, globalMatrix, matrixSession } from "../../../globals";
import { FieldDescriptions } from "../../businesslogic/FieldDescriptions";
import { IDhfTableOptions } from "../../businesslogic/FieldHandlers/Document/GenericTableDocHandler";
import { EmptyFieldHandler } from "../../businesslogic/FieldHandlers/EmptyFieldHandler";
import { GenericFieldHandler } from "../../businesslogic/FieldHandlers/GenericFieldHandler";

export type { ISignaturesInfo };
export { DocBaseImpl };

interface ISignaturesInfo {
    signatures: ISignature[];
    missing: string[];
    given: string[];
    signatureDate: { [key: string]: string };
    missingSignatures: number;
    givenSignatures: number;

    needSignature: boolean; // if the user looking at this item needs to sign
    hasSignature: boolean; // true if this is a document which has at least one signature

    isTemplate: boolean; // if the item contains a "templateapproval" field, it is a template. and this needs to be signed instead of filling the signature table
}

abstract class DocBaseImpl extends BaseControl<GenericFieldHandler> {
    constructor(control: JQuery, fieldHandler: GenericFieldHandler) {
        super(control, fieldHandler);
    }

    // this method returns all signature information from a item
    // the item can be a DOC, SIGN, RELEASE
    // depending on the type (e.g. DOC) it looks for different fields
    static readSignatureInfo(item: IItemGet): ISignaturesInfo {
        let that = this;
        let signatureStatus: ISignaturesInfo = {
            signatures: [],
            missing: [],
            given: [],
            signatureDate: {},
            missingSignatures: 0,
            givenSignatures: 0,
            needSignature: false,
            hasSignature: false,
            isTemplate: false,
        };

        if (!item) {
            return signatureStatus;
        }

        $.each(item, function (key, val) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            if (val && globalMatrix.ItemConfig.getFieldType(item.type, key) === FieldDescriptions.Field_dhf) {
                // special dhf control
                let fieldVal = <IDHFControlDefinitionValue>JSON.parse(val);
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                if (fieldVal.type.toLocaleLowerCase() === "templateapproval") {
                    signatureStatus.isTemplate = true;
                }
            }
        });

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        if (mDHF.isDocumentFormType(item.type)) {
            // DRAFT, DOC, ...
            $.each(item, function (key, val) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                if (val && globalMatrix.ItemConfig.getFieldType(item.type, key) === FieldDescriptions.Field_dhf) {
                    // special dhf control
                    let fieldVal = <IDHFControlDefinitionValue>JSON.parse(val);
                    if (
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        fieldVal.type.toLocaleLowerCase() ===
                        (signatureStatus.isTemplate ? "templateapproval" : "signaturebox")
                    ) {
                        // control is a signature table
                        $.each(fieldVal, function (tableIdx, value) {
                            // get calue of control
                            let tableStr = value.fieldValue;
                            if (tableStr) {
                                let table = JSON.parse(tableStr);
                                $.each(table, function (rowIdx, row) {
                                    $.each(
                                        (<IDhfTableOptions>value.ctrlConfig).columns,
                                        function (tableColIdx, tableCol) {
                                            let user = row[tableCol.field];
                                            if (
                                                tableCol.editor == "user" &&
                                                user &&
                                                signatureStatus.missing.indexOf(user) == -1
                                            ) {
                                                signatureStatus.missingSignatures++;
                                                signatureStatus.missing.push(user);
                                            }
                                        },
                                    );
                                });
                            }
                        });
                    }
                }
            });
        } else {
            // this is a signed item
            $.each(item, function (detail, val) {
                if (typeof val === "string" && val.indexOf("<signatures ") === 0) {
                    let signaturesXML = $(val);
                    $.each(signaturesXML.find("signature"), function (idx, signature) {
                        signatureStatus.hasSignature = true; // there is at least this signature field
                        let orgUser = $(signature).attr("originaluserlogin");
                        let currentUser = $(signature).attr("user").toLowerCase();
                        let sig = {
                            orgid: orgUser ? orgUser.toLowerCase() : currentUser,
                            userid: currentUser,
                            signDate: $(signature).attr("signDate"),
                            signaturefileid: $(signature).attr("signaturefileid"),
                            signDateCustomer: $(signature).attr("signDateCustomer"),
                        };
                        if (that.isMeTest(sig.userid)) {
                            signatureStatus.needSignature = !sig.signDate;
                        }
                        if (sig.signDate) {
                            signatureStatus.given.push($(signature).attr("user"));
                            signatureStatus.givenSignatures++;
                            if (!sig.signDateCustomer) {
                                // in some old projects signDateCustomer was not set...
                                sig.signDateCustomer = ml.UI.DateTime.renderHumanDate(new Date(sig.signDate));
                            }
                        } else {
                            signatureStatus.missingSignatures++;
                            signatureStatus.missing.push($(signature).attr("user"));
                        }
                        signatureStatus.signatureDate[$(signature).attr("user").toLowerCase()] = sig.signDateCustomer;
                        signatureStatus.signatures.push(sig);
                    });
                }
            });
        }
        return signatureStatus;
    }
    static isMeTest(user: string) {
        return user.toLowerCase() === matrixSession.getUser().toLowerCase();
    }
    protected isMe(user: string) {
        return DocBaseImpl.isMeTest(user);
    }
}
