import { IPlugin, IProjectPageParam, plugins } from "../../../common/businesslogic/index";
import { ml } from "../../../common/matrixlib";
import { SelectMode } from "../../../common/UI/Components/ProjectViewDefines";
import { ItemSelectionTools } from "../../../common/UI/Tools/ItemSelectionView";
import { IItem, IReference, globalMatrix, matrixSession, app, ControlState, IItemGet } from "../../../globals";

import { ILabelGroup, IDropdownOption } from "../../../ProjectSettings";

export type { ILabelSelectMap };
export { initialize };

interface ILabelSelectMap {
    [map: string]: JQuery;
}

class MultiSetLabel implements IPlugin {
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private _item: IItem;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private _jui: JQuery;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private _type: string;

    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private selectedItems: IReference[];
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private todos: string[];
    private useFilters: ILabelSelectMap = {};
    private useGroups: JQuery[] = [];
    public isDefault = true;

    initItem(item: IItem, jui: JQuery) {
        this._item = item;
        this._jui = jui;
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        this._type = ml.Item.parseRef(item.id).type;
    }

    initServerSettings() {}
    initProject() {}
    async getProjectPagesAsync(): Promise<IProjectPageParam[]> {
        return [];
    }
    supportsControl() {
        return false;
    }

    updateMenu(ul: JQuery) {
        let that = this;
        let extras = globalMatrix.ItemConfig.getExtrasConfig();
        if (
            extras &&
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            ml.Item.parseRef(this._item.id).isFolder &&
            (ml.JSON.isTrue(extras.setLabel) || (extras.setLabel === "admin" && matrixSession.isAdmin()))
        ) {
            if (this.getMyOrLabels().length) {
                let setLabelMenu = $('<li><a href="javascript:void(0)">Change Labels</a></li>').click(function () {
                    let st = new ItemSelectionTools();
                    // let user select the items
                    st.showDialog({
                        selectMode: SelectMode.auto,
                        linkTypes: [{ type: that._type }],
                        selectionChange: function (newSelection: IReference[]) {
                            that.selectedItems = newSelection;
                            window.setTimeout(() => that.setOrLabels(), 1);
                        },
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        getSelectedItems: async function () {
                            return [{ to: that._item.id, title: "" }];
                        },
                    });
                });

                ul.append(setLabelMenu);
            }

            if (this.getRelevantXorLabels().length) {
                let setGroupLabelMenu = $('<li><a href="javascript:void(0)">Change Group Labels</a></li>').click(
                    function () {
                        let st = new ItemSelectionTools();
                        // let user select the items
                        st.showDialog({
                            selectMode: SelectMode.auto,
                            linkTypes: [{ type: that._type }],
                            selectionChange: function (newSelection: IReference[]) {
                                that.selectedItems = newSelection;
                                window.setTimeout(() => that.chooseXorLabels(), 1);
                            },
                            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                            getSelectedItems: async function () {
                                return [{ to: that._item.id, title: "" }];
                            },
                        });
                    },
                );
                ul.append(setGroupLabelMenu);
            }
        }
    }

    private chooseXorLabels() {
        let that = this;

        this.todos = [];
        // show dialog to select labels
        app.dlgForm.html("");
        app.dlgForm.addClass("dlg-v-scroll").removeClass("dlg-no-scroll");
        let scrollPanel = $('<div class="layoutContainerScroll"></div>');
        app.dlgForm.append(scrollPanel);
        // selected items
        // filter options
        let notMyLocks = this.getNotMyLocks();

        let label_Groups = this.getRelevantXorLabels();

        scrollPanel.append($('<span class="baseControlHelp">Choose Option:</span>'));

        that.useGroups = [];

        $.each(label_Groups, function (idx, lg) {
            let all: string[] = [];

            let actualLabels = that.getMyXorLabels(idx, lg, all);

            let ctrl = $("<div>")
                .mxDropdown({
                    controlState: ControlState.DialogCreate,
                    canEdit: true,
                    dummyData: false,
                    help: "",
                    fieldValue: "",
                    valueChanged: function () {},
                    parameter: {
                        placeholder: "",
                        create: false,
                        options: actualLabels,
                        groups: [],
                        maxItems: 1,
                        sort: true,
                    },
                    noMarkup: true,
                })
                .data("group", all);
            scrollPanel.append(ctrl);
            that.useGroups.push(ctrl);
        });

        app.dlgForm.dialog({
            autoOpen: true,
            title: "Select new values",
            height: 400,
            width: 770,
            modal: true,
            close: function () {},
            resizeStop: function () {},
            open: function () {},
            buttons: [
                {
                    text: "SET",
                    class: "btnDoIt",
                    click: function () {
                        that.chooseLabels();
                        app.dlgForm.dialog("close");
                    },
                },
                {
                    text: "Cancel",
                    class: "btnCancelIt",
                    click: function () {
                        app.dlgForm.dialog("close");
                    },
                },
            ],
        });
    }

    private getRelevantXorLabels() {
        let that = this;

        let label_Groups = ml.LabelTools.getLabelGroups(this._type).filter(function (lg, idx) {
            let mine = that.getMyXorLabels(idx, lg, []);
            return mine.length > 1 && lg.selection != "design_review" && lg.selection != "or";
        });

        return label_Groups;
    }

    private getMyXorLabels(idx: number, lg: ILabelGroup, all: string[]) {
        let group = lg.groupType ? lg.groupType : "Group " + idx;
        let actualLabels: IDropdownOption[] = [{ id: "", label: group + ": leave unchanged" }];
        let notMyLocks = this.getNotMyLocks();
        let notMyReviews = this.getNotMyDesignReviews();

        let mySelfHavNoRight = false;

        if (lg.reviewers && lg.reviewers.length && !matrixSession.amIAllowedUser(lg.reviewers)) {
            // there are reviewers defined for the group, but not me
            return [];
        }
        let all_labels = ml.LabelTools.getLabelDefinitions([this._type]); // labels of UC, TC, ...

        $.each(all_labels, function (lidx, l) {
            if (lg.labels.indexOf(l.label) != -1) {
                actualLabels.push({ id: l.label, label: group + " set to: " + l.label + " - " + l.reportName });
                all.push(l.label);
                if (notMyLocks.indexOf(l.label) != -1 || notMyReviews.indexOf(l.label) != -1) {
                    mySelfHavNoRight = true;
                }
            }
        });

        if (mySelfHavNoRight) {
            // cannot do anything with at least one label in group, so I don't offer to do group operations on label
            return [];
        } else {
            return actualLabels;
        }
    }

    private setOrLabels() {
        let that = this;

        this.todos = [];
        // show dialog to select labels
        app.dlgForm.html("");
        app.dlgForm.addClass("dlg-v-scroll").removeClass("dlg-no-scroll");
        let scrollPanel = $('<div class="layoutContainerScroll"></div>');
        app.dlgForm.append(scrollPanel);
        // selected items
        let relevant_labels = this.getMyOrLabels();
        that.useFilters = {};
        scrollPanel.append($('<span class="baseControlHelp">(Un)set these labels:</span>'));

        $.each(relevant_labels, function (index, rl) {
            let useFilter = $("<div>");
            let name = rl.reportName;
            if (rl.style && rl.style.filter && rl.style.filter.on && rl.style.filter.on.displayName) {
                name = rl.style.filter.on.displayName;
            }

            useFilter.checkBox({
                controlState: ControlState.FormEdit,
                canEdit: true,
                fieldValue: "false",
                help: name,
                valueChanged: function () {},
            });

            // make list more compact, but skipping the last element
            if (index !== relevant_labels.length - 1) {
                useFilter.css("margin-bottom", "-8px");
            }

            that.useFilters[rl.label] = useFilter;
            scrollPanel.append(useFilter);
        });

        app.dlgForm.dialog({
            autoOpen: true,
            title: "Select Labels to (un)set",
            height: 400,
            width: 500,
            modal: true,
            close: function () {},
            resizeStop: function () {},
            open: function () {},
            buttons: [
                {
                    text: "UNSET",
                    class: "btnDoIt2",
                    click: function () {
                        that.applyLabels(false);
                        app.dlgForm.dialog("close");
                    },
                },
                {
                    text: "SET",
                    class: "btnDoIt",
                    click: function () {
                        that.applyLabels(true);
                        app.dlgForm.dialog("close");
                    },
                },
                {
                    text: "Cancel",
                    class: "btnCancelIt",
                    click: function () {
                        app.dlgForm.dialog("close");
                    },
                },
            ],
        });
    }

    private getMyOrLabels() {
        let all_labels = ml.LabelTools.getLabelDefinitions([this._type]); // labels of UC, TC, ...

        let notMyLocks = this.getNotMyLocks();
        let notMyReviews = this.getNotMyDesignReviews();

        let label_Groups = ml.LabelTools.getLabelGroups(this._type);
        // remove labels if they are not in the good group
        let relevant_labels = all_labels.filter(function (label) {
            if (notMyLocks.indexOf(label.label) != -1) {
                // that's a lock and I am not a lock keeper
                return false;
            }
            if (notMyReviews.indexOf(label.label) != -1) {
                // that's a review and I am not the reviewer
                return false;
            }
            let targetGroups = label_Groups.filter(function (group) {
                if (group.selection == "design_review" || group.selection == "or") {
                    // only one in group can be selected
                    if (group.labels.indexOf(label.label) != -1) {
                        return true; // this is a group with the label, and as wanted an or / xor kind of group
                    }
                }
                return false;
            });
            return targetGroups.length > 0;
        });
        return relevant_labels;
    }
    private getNotMyLocks() {
        let lockConfig = globalMatrix.ItemConfig.getLabelLockConfig();
        if (!lockConfig) return [];
        let notMyLocks = lockConfig.locks
            .filter(function (lock) {
                return !matrixSession.amIAllowedUser(lock.lockKeeper);
            }) //
            .map(function (lock) {
                return lock.label;
            });
        return notMyLocks;
    }
    private getNotMyDesignReviews() {
        // get a list of design review, which the logged in user cannot set
        let all_labels = ml.LabelTools.getLabelDefinitions([this._type]); // labels of UC, TC, ...

        let notMyReviews = all_labels
            .filter(function (label) {
                let dr = ml.LabelTools.getDesignReview(label.label);

                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                return dr && !matrixSession.amIAllowedUser(dr.reviewers);
            })
            .map(function (review) {
                return review.label;
            });
        return notMyReviews;
    }

    // or labels
    private applyLabels(set: boolean) {
        let selection: string[] = [];
        $.each(this.selectedItems, function (idx, sel) {
            selection.push(sel.to);
        });
        this.createTodoList(selection);
        this.applyLabel(0, set);
    }

    private createTodoList(itemIds: string[]) {
        let that = this;
        $.each(itemIds, function (idx, itemId) {
            if (ml.Item.parseRef(itemId).isFolder) {
                that.createTodoList(app.getChildrenIds(itemId));
            } else {
                that.todos.push(itemId);
            }
        });
    }

    private applyLabel(itemIdx: number, set: boolean) {
        let that = this;

        if (itemIdx >= this.todos.length) {
            ml.UI.showSuccess("done " + (set ? "" : "un") + "setting labels");
            return;
        }

        ml.UI.showSuccess("Processing " + (itemIdx + 1) + " / " + this.todos.length + " : " + this.todos[itemIdx], -1);

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        app.getItemAsync(this.todos[itemIdx]).done(async function (original: IItemGet) {
            let labelsChanged = false;
            for (let l of Object.keys(that.useFilters)) {
                let label = l;
                let ctrl = that.useFilters[label];
                if (set) {
                    // add labels if necessary
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    if ((await ctrl.getController().getValueAsync()) && original.labels.indexOf(label) === -1) {
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        original.labels.push(label);
                        labelsChanged = true;
                    }
                } else {
                    // remove labels
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    if ((await ctrl.getController().getValueAsync()) && original.labels.indexOf(label) !== -1) {
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        original.labels = original.labels.filter(function (l) {
                            return l != label;
                        });
                        labelsChanged = true;
                    }
                }
            }
            if (labelsChanged) {
                // create a copy of item to be saved
                let newItem = ml.Item.clone(original, false);

                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                ml.Logger.log("info", "updating labels of " + newItem.id + " to " + original.labels.join());
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                newItem.labels = original.labels.join(",");
                app.updateItemInDBAsync(newItem, "change label plugin")
                    .done(function () {
                        ml.Logger.log("info", "updated labels of " + newItem.id + " to " + newItem.labels);
                        that.applyLabel(itemIdx + 1, set);
                    })
                    .fail(() => {
                        // user cancelled
                        ml.UI.showSuccess("cancelled " + (set ? "" : "un") + "setting labels");
                        return;
                    });
            } else {
                that.applyLabel(itemIdx + 1, set);
            }
        });
    }

    // or labels
    private chooseLabels() {
        let selection: string[] = [];
        $.each(this.selectedItems, function (idx, sel) {
            selection.push(sel.to);
        });
        this.createTodoList(selection);
        this.chooseLabel(0);
    }

    private chooseLabel(itemIdx: number) {
        let that = this;

        if (itemIdx >= this.todos.length) {
            ml.UI.showSuccess("done changing labels");
            return;
        }

        ml.UI.showSuccess("Processing " + (itemIdx + 1) + " / " + this.todos.length + " : " + this.todos[itemIdx], -1);

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        app.getItemAsync(this.todos[itemIdx]).done(async function (original: IItemGet) {
            let labelsChanged = false;
            // TODO: why do we using Object.keys on JQuery[]?
            for (let l of Object.keys(that.useGroups)) {
                let label = l;
                // @ts-ignore TODO: weird stuff, check it
                let ctrl = that.useGroups[label];
                let set = await ctrl.getController().getValueAsync();

                if (set) {
                    // not the leave changed option
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    if (original.labels.indexOf(set) == -1) {
                        labelsChanged = true;
                    }

                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    let labelCount = original.labels.length;
                    // remove all labels in group
                    let allLabelsInGroup = ctrl.data("group");
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    original.labels = original.labels.filter(function (l) {
                        return allLabelsInGroup.indexOf(l) == -1;
                    });

                    // add label
                    original.labels.push(set);

                    if (labelCount != original.labels.length) {
                        // apparently there was a label wrongly set in some or group
                        labelsChanged = true;
                    }
                }
            }
            if (labelsChanged) {
                // create a copy of item to be saved
                let newItem = ml.Item.clone(original, false);

                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                ml.Logger.log("info", "updating labels of " + newItem.id + " to " + original.labels.join());
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                newItem.labels = original.labels.join(",");
                app.updateItemInDBAsync(newItem, "change label plugin").done(function () {
                    ml.Logger.log("info", "updated labels of " + newItem.id + " to " + newItem.labels);
                    that.chooseLabel(itemIdx + 1);
                });
            } else {
                that.chooseLabel(itemIdx + 1);
            }
        });
    }
}

function initialize() {
    plugins.register(new MultiSetLabel());
}
