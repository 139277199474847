import { IDHFSectionOptions, IDHFControlDefinition, DocumentSectionType, mDHF } from "../../../../common/businesslogic";
import { mTM } from "../../../../common/businesslogic";
import { ml } from "../../../../common/matrixlib";
import { IBaseControlOptions } from "../../../../common/UI/Controls/BaseControl";
import { IFromToSelection } from "../../../../common/UI/Controls/itemSelectionFromTo";
import { IGenericMap } from "../../../../globals";
import { IDoubleDefaultControllerConfig } from "../../../../SingleSelectBase";
import {
    ITestResultsOptions,
    TestResultsDocFieldHandler,
} from "../../../../common/businesslogic/FieldHandlers/Document/testResultsDocFieldHandler";
import { IDocFieldHandler } from "../../../../common/businesslogic/FieldHandlers/Document/IDocFieldHandler";
import { DoubleSelectBase } from "../../../../DoubleSelectBase";

export { TestResults };

class TestResults extends DoubleSelectBase<ITestResultsOptions> {
    renderControl(ctrl: IDHFControlDefinition, ctrlParameter: IBaseControlOptions) {
        let hideTypes = mDHF.getDocumentTypes();
        hideTypes.push(mTM.getXTCType());
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        ctrl.control.itemSelectionFromTo(
            <any>ml.JSON.mergeOptions(ctrlParameter, {
                parameter: {
                    prefixFrom: "Include test results for the following items:",
                    buttonNameFrom: "Select Items",
                    showNotFrom: hideTypes,
                    prefixTo: "Include test results from these test runs:",
                    buttonNameTo: "Select Test Runs",
                    showOnlyTo: [mTM.getXTCType()],
                },
            }),
        );
    }

    showSpecificSettings(ctrl: IDHFControlDefinition, ctrlParameter: IBaseControlOptions, custom: JQuery) {
        let controllerConfig = this.getConfig(ctrl);

        let pickResults = $(
            '<select class="whichTests docOptionSelect form-control">' +
                '<option value="all">Include all test results</option>' +
                '<option value="modified">Include last modified tests results (if there are multiple for same test)</option>' +
                '<option value="created">Include last created tests results (if there are multiple for same test)</option>' +
                "</select>",
        );
        custom.append($(pickResults));
        pickResults.val(controllerConfig.lastOnly ? "modified" : controllerConfig.lastCreatedOnly ? "created" : "all");

        let options: string;

        options =
            '<div class="checkbox" ><label><input type="checkbox" class="p2" ' +
            (controllerConfig.completeTree ? "checked" : "") +
            "> Show complete traceability tree</label></div>";
        custom.append($(options));
        options =
            '<div class="checkbox" ><label><input type="checkbox" class="p3" ' +
            (controllerConfig.failedOnly ? "checked" : "") +
            "> Show only items with failed tests</label></div>";
        custom.append($(options));
        options =
            '<div class="checkbox" ><label><input type="checkbox" class="includeInToc" ' +
            (controllerConfig.includeInToc ? "checked" : "") +
            "> Add a reference of tested items to table of content</label></div>";
        custom.append($(options));
        options =
            '<div class="checkbox" ><label><input type="checkbox" class="numericalOrder" ' +
            (controllerConfig.numericalOrder ? "checked" : "") +
            "> List elements in numerical order</label></div>";
        custom.append($(options));

        let itemInfo = $(
            '<select class="p4 docOptionSelect form-control">' +
                '<option value="">Ignore dates (do not show outdated traces)</option>' +
                '<option value="warning">Indicate outdated traces</option>' +
                '<option value="error">Treat outdated traces as traceability errors</option>' +
                "</select>",
        );
        custom.append(itemInfo);
        $(custom.find(".p4")[0]).val(controllerConfig.dateoptions);

        this.addSpecificSettings(controllerConfig, custom);
    }
    async saveSpecificSettingsAsync(ctrl: IDHFControlDefinition, ctrlParameter: IBaseControlOptions, custom: JQuery) {
        let controllerConfig = this.getConfig(ctrl);

        let changed = false;

        let newVal = $(custom.find(".whichTests")[0]).val();
        changed = changed || (newVal !== "modified" && !controllerConfig.lastOnly);
        changed = changed || (newVal !== "created" && !controllerConfig.lastCreatedOnly);
        changed = changed || (newVal !== "all" && (controllerConfig.lastCreatedOnly || controllerConfig.lastOnly));

        controllerConfig.lastOnly = newVal === "modified";
        controllerConfig.lastCreatedOnly = newVal === "created";

        newVal = $(custom.find(".p2")[0]).prop("checked");
        changed = changed || newVal !== controllerConfig.completeTree;
        controllerConfig.completeTree = newVal;

        newVal = $(custom.find(".p3")[0]).prop("checked");
        changed = changed || newVal !== controllerConfig.failedOnly;
        controllerConfig.failedOnly = newVal;

        newVal = $(custom.find(".includeInToc")[0]).prop("checked");
        changed = changed || newVal !== controllerConfig.includeInToc;
        controllerConfig.includeInToc = newVal;

        newVal = $(custom.find(".numericalOrder")[0]).prop("checked");
        changed = changed || newVal !== controllerConfig.numericalOrder;
        controllerConfig.numericalOrder = newVal;

        newVal = $(custom.find(".p4")[0]).val();
        changed = changed || newVal !== controllerConfig.dateoptions;
        controllerConfig.dateoptions = newVal;

        let specificChanged = this.setSpecificSettings(controllerConfig, custom);

        this.fieldHandler.setDHFConfig(controllerConfig);

        return changed || specificChanged;
    }
    async verifyContentAsync(ctrl: IDHFControlDefinition) {
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        let lastValue = await ctrl.control.getController().getValueAsync();
        let lastVal = lastValue ? JSON.parse(lastValue) : { from: [], to: [] };
        if (lastVal.to.length == 0) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            ctrl.control.closest(".ft_dhf").find(".baseControlHelp").addClass("contentNeeded");
        } else {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            ctrl.control.closest(".ft_dhf").find(".baseControlHelp").removeClass("contentNeeded");
        }
    }
}
