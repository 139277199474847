import { app, globalMatrix } from "../../../globals";
import { IJcxhr } from "../../businesslogic/index";
import { ItemControl } from "../Components/ItemForm";
import { IBaseControl } from "../Controls/BaseControl";

interface JQuery {
    resizeDlgContent?: (ctrls: JQuery[] | ItemControl[], fullscreen?: boolean) => JQuery;
    highlight?: (words: string, options?: {}) => JQuery;
    unhighlight?: (options?: {}) => JQuery;
    highlightReferences?: (options?: {}) => JQuery;
    unhighlightReferences?: (options?: {}) => JQuery;
    // base control
    getController?: () => IBaseControl;
    // from misc libs
    code?: (p?: string) => string;
    tablesorter?: any;
    annotator?: Function;
    resizeItem?: (width?: number, force?: boolean) => void;
}

interface JQueryStatic {
    eachAsync?: (
        collection: any[],
        fct: (indexInArray: number, valueOfElement: any) => JQueryDeferred<any>,
        idx?: number,
    ) => JQueryDeferred<any>;
}

$.fn.resizeDlgContent = function (ctrls: JQuery[] | ItemControl[], fullscreen?: boolean): void {
    if (fullscreen) {
        let maxw = app.itemForm.width();
        let maxh = app.itemForm.height();
        app.dlgForm.parent().width(maxw - 40);
        app.dlgForm.parent().height(maxh - 30);
        let left = app.itemForm.offset().left + 20;
        let top = app.itemForm.offset().top + 15;
        app.dlgForm.parent().css({ top: top, left: left });
    }
    // TODO get rid of the magic constants ;-)
    let pw = app.dlgForm.parent().width();
    let ph = app.dlgForm.parent().height();
    app.dlgForm.width(pw - 31);
    app.dlgForm.height(ph - 115);
    for (let idx = 0; idx < ctrls.length; idx++) {
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        if ((<JQuery>ctrls[idx]).getController) (<JQuery>ctrls[idx]).getController().resizeItem();
        else if ((<ItemControl>ctrls[idx]).resizeItem) (<ItemControl>ctrls[idx]).resizeItem();
    }
};

$.fn.eachAsync = function (
    collection: any[],
    fct: (indexInArray: number, valueOfElement: any) => JQueryDeferred<any>,
    idx?: number,
): JQueryDeferred<any> {
    let res = $.Deferred();

    let nextIdx = typeof idx === "undefined" ? 0 : idx;
    if (!collection || collection.length <= nextIdx) {
        res.resolve();
        return res;
    }

    fct(nextIdx, collection[nextIdx])
        .done(function () {
            nextIdx++;
            $.fn
                .eachAsync(collection, fct, nextIdx)
                .done(function () {
                    res.resolve();
                })
                .fail(function (jqxhr: IJcxhr, textStatus: string, error: string) {
                    res.reject(jqxhr, textStatus, error);
                });
        })
        .fail(function (jqxhr: IJcxhr, textStatus: string, error: string) {
            res.reject(jqxhr, textStatus, error);
        });
    return res;
};
