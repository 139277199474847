// bar left side of screen

import { ISearchCountsTab } from "./ProjectTree";
import { ml } from "./../../matrixlib";
import { matrixSession, app, globalMatrix, IStringNumberMap } from "../../../globals";

export type { INavigationBar, INavigationBarTab, INavigationBarTabRuntime, IStringTabMap };
export { TabMode, NavigationBar };
export { NavBar };
export { initialize };

interface INavigationBar {
    tabs: INavigationBarTab[];
    // favorites:INavigationFavorite[] // above help could be some favorite links
}

interface INavigationBarRuntime extends INavigationBar {
    tabs: INavigationBarTabRuntime[];
    // favorites:INavigationFavorite[] // above help could be some favorite links
}

// tabs in bar
interface INavigationBarTab {
    name: string; // display name
    icon: string; // icon to be shown
    mode: TabMode; // by default show / hide
    other: string[]; // categories to show / hide
}

interface INavigationBarTabRuntime extends INavigationBarTab {
    // these are programmatically filled
    node?: JQuery; // the UI component for the tab
    trees?: JQuery[]; // the UI components <li> in the tree of that tab
    isActive?: boolean;
}

interface IStringTabMap {
    [key: string]: INavigationBarTab;
}

enum TabMode {
    ShowAsDefault = 1,
    HideAsDefault,
}

class NavigationBar {
    static navbarWidth: number = 0;
    protected rootTabMap: IStringTabMap = {};
    protected workFolders: string[] = [];
    protected enabled: boolean = false;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    protected bar: INavigationBarRuntime;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    protected helpButton: JQuery;
    private navBarOpened = true;
    private analyticsTab: JQuery | undefined;

    private static getDefaultBar(project: string): INavigationBar {
        return {
            tabs: [
                {
                    name: matrixSession.isQMSProject(project) ? "Quality Manual" : "Design",
                    icon: "fal fa-paint-brush",
                    mode: TabMode.ShowAsDefault,
                    other: ["F-DOC-1", "F-SIGN-1", "_", "F-REPORT-1", "F-PUB-1"], // categories to hide
                },
                {
                    name: "Documents",
                    icon: "fal fa-book",
                    mode: TabMode.HideAsDefault,
                    other: ["F-DOC-1", "F-SIGN-1"], // categories to show
                },
                {
                    name: "Tools",
                    icon: "fal fa-tools",
                    mode: TabMode.HideAsDefault,
                    other: ["_", "F-REPORT-1"], // categories to show
                },
                {
                    name: "Publish",
                    icon: "fal fa-upload",
                    mode: TabMode.HideAsDefault,
                    other: ["F-PUB-1"], // categories to show
                },
            ],
        };
    }

    constructor() {
        let that = this;
        $(window).resize(function () {
            that.resizeBarItems();
        });
    }

    public static getConfig(): INavigationBar {
        let setting = globalMatrix.ItemConfig.getNavigationBarConfig();
        if (!setting || JSON.stringify(setting) == "{}" || !setting.tabs) {
            const project = matrixSession.getProject();
            return NavigationBar.getDefaultBar(project);
        }
        return setting;
    }

    // navigation bar left
    init() {
        let that = this;

        this.addHelpButton();

        if (!app.mainApp) return;

        $("#navLeft").remove();

        this.navBarOpened = localStorage.getItem("navBarOpened") === "true";

        // unless there's a project / server specific bar
        let projectSetting = NavigationBar.getConfig();
        this.enabled = true;

        if (!this.enabled) {
            NavigationBar.navbarWidth = 0;
            app.resizeItem(true);
            return;
        }

        this.bar = ml.JSON.clone(projectSetting);

        $.each(this.bar.tabs, function (tabIdx, tab) {
            let tools = tab.other.indexOf("_");
            if (tools != -1) {
                // replace this by tools
                tab.other.splice(tools, 1, ...that.workFolders);
            }
        });
        this.createTabs();
        this.drawNavigationBar();
        //Delay the resize
        setTimeout(() => {
            that.resizeBarItems();
        }, 100);
        this.activateTab(this.bar.tabs[0]);
        this.updateNotificationCounters();
        this.resizeWitdh();
    }

    isEnabled() {
        return true;
    }
    // check if a certain item of the tree is in the current tab
    isInCurrentTab(itemId: string) {
        if (!this.enabled) return true; // there's only 1 tab, kind off
        if (!app.mainApp) return true;
        let tab = this.getTab(itemId);
        return tab ? tab.isActive : true;
    }
    getCurrentTab() {
        for (let tab of this.bar.tabs) {
            if (tab.isActive) return tab.name;
        }
    }
    countPerTab(itemIds: string[]): ISearchCountsTab[] {
        if (!this.enabled) return []; // no tabs
        if (!app.mainApp) return []; // no tabs
        let result: ISearchCountsTab[] = [];
        let resultMap: IStringNumberMap = {};
        for (let item of itemIds) {
            let tab = this.getTab(item);
            if (!resultMap[tab.name]) {
                resultMap[tab.name] = 0;
            }
            resultMap[tab.name]++;
        }
        for (let key in resultMap) {
            result.push({ tabName: key, count: resultMap[key] });
        }
        return result;
    }

    // activates a tab with a specific item
    activateItemsTab(itemId: string) {
        if (!this.enabled) return;
        if (!app.mainApp) return;

        let tab = this.getTab(itemId);

        if (!tab) {
            return; // some non existing item
        }

        if (tab.isActive) {
            // nothing to do
            return;
        }

        this.activateTab(tab);
    }

    // show badges for the tab
    updateNotificationCounters() {
        if (!this.enabled) return;
        $(".navBarNotification").remove();
        $.each(this.bar.tabs, function (tabIdx, tab) {
            let count = 0;
            $.each(tab.trees, function (liIdx, li) {
                count += li.data("notifications") ? li.data("notifications") : 0;
            });
            if (count) {
                $('<span class="notificationCounter navBarNotification">')
                    .html("" + count)
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    .appendTo(tab.node);
            }
        });
    }

    // create the bar on the left side
    protected drawNavigationBar() {
        let that = this;

        let navLeft = $("<div id='navLeft' class='hidden-print'>").prependTo("#highlightContext");

        // tabs
        $.each(this.bar.tabs, function (tabIdx, tab) {
            let node = $(`<div class="navLeftTab">
                            <div class="navLeftIcon" title="${tab.name}">
                                <i class="${tab.icon}"></i>
                            </div>
                            <div class="navLeftText">${tab.name}</div>
                        </div>`)
                .appendTo(navLeft)
                .click(function () {
                    $(".navLeftTabActive").removeClass("navLeftTabActive");
                    that.activateTab(tab);
                });
            tab.node = node;
        });
        if (matrixSession.getUISettings({ analyticsEnabled: false }).analyticsEnabled) {
            // Adding the analytics tab
            this.analyticsTab = $(
                `<div class='navLeftTab'>
                        <div class='navLeftIcon' title='Analytics'>
                        <i class='fal fa-chart-pie'></i>
                    </div>
                    <div class='navLeftText'>Analytics</div>
                    </div>`,
            )
                .appendTo(navLeft)
                .click(() => {
                    this.openAnalytics();
                });
        }
        navLeft.append($("<div class='flexGrowSpacer'/>"));
        // Add a toggle button to resize the bar
        let toggleButton = $(
            "<div class='navLeftTab navLeftToggle'><i class='navLeftIcon fal fa-angle-double-left'></i></div>",
        ).appendTo(navLeft);
        toggleButton.click(() => {
            this.toggleBar();
        });
    }

    // that's all the pages from third party plugins
    setWorkFolders(folders: string[]) {
        this.workFolders = folders;
    }

    // resize
    protected resizeBarItems() {
        if (!this.enabled || this.bar.tabs.length == 0 || !this.navBarOpened) return;
        $(".navLeftText").show();
        let lastTab = this.bar.tabs[this.bar.tabs.length - 1].node;
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        let lastPos = lastTab.position().top + lastTab.height() + 20; // some margin 20
    }
    // sort all items from the tree into the tabs, remove the tabs which are empty
    protected createTabs() {
        let that = this;

        let leftOvers: JQuery[] = [];

        $.each(that.bar.tabs, function (tabIdx, tab) {
            tab.trees = [];
        });

        that.bar.tabs.push({
            name: "Other",
            icon: "far fa-window-alt",
            mode: TabMode.ShowAsDefault,
            other: [], // categories to hide
            trees: [], // the content
        });

        $.each($("#projectTree .treeContent > ul").children(), function (idx, li) {
            $.each(that.bar.tabs, function (tabIdx, tab) {
                let show = true;
                if (tab.mode == TabMode.HideAsDefault) {
                    show = false;
                    $.each(tab.other, function (otherIdx, other) {
                        if ($(".key-" + other, $(li)).length) {
                            show = true;
                        }
                    });
                } else if (tab.mode == TabMode.ShowAsDefault) {
                    show = true;
                    $.each(tab.other, function (otherIdx, other) {
                        if ($(".key-" + other, $(li)).length) {
                            show = false;
                        }
                    });
                }

                if (show) {
                    // add the node to the tab
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    tab.trees.push($(li));
                    // remember that the node for that key is in the tab

                    let keys = $(".refTitle", $(li))[0]
                        .className.split(/\s+/)
                        .filter(function (cls) {
                            return cls.indexOf("key-") == 0;
                        });
                    if (keys.length == 1) {
                        that.rootTabMap[keys[0].replace("key-", "")] = tab;
                    }
                    return false;
                } else {
                    // continue to search
                    return true;
                }
            });
        });

        // now remove all the empty ones
        this.bar.tabs = this.bar.tabs.filter(function (tab) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            return tab.trees.length > 0;
        });
    }

    // switch tab (from search)

    switchTab(tabName: string) {
        if (!this.enabled || !this.bar.tabs.length) return false; // nothing to do
        let tab = this.bar.tabs.filter((tab) => tab.name == tabName);
        if (tab.length == 0) return; // should not happen

        $(".navLeftIcon").css("background-color", "");
        $(".navLeftIcon", tab[0].node).css("background-color", "var(--red-500");
        $(".navLeftTabActive").removeClass("navLeftTabActive");
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        tab[0].node.addClass("navLeftTabActive");

        // hide all
        $.each($("#projectTree .treeContent > ul").children(), function (idx, li) {
            $(li).hide();
        });

        // deactivate others
        $.each(this.bar.tabs, function (tabIdx, t) {
            t.isActive = false;
        });

        // activate the one
        tab[0].isActive = true;
        $.each(tab[0].trees, function (liIdx, li) {
            li.show();
        });
    }
    // user clicks on tab
    protected activateTab(tab: INavigationBarTabRuntime) {
        app.closeAnalytics();
        $(".treeName").text(tab.name);
        $(".navLeftTabActive").removeClass("navLeftTabActive");
        tab.node?.addClass("navLeftTabActive");

        if (tab.isActive) return;

        // make sure there's no filter
        ml.Search.cancelSearch();

        // hide all
        $.each($("#projectTree .treeContent > ul").children(), function (idx, li) {
            $(li).hide();
        });

        // deactivate others
        $.each(this.bar.tabs, function (tabIdx, t) {
            t.isActive = false;
        });

        // activate the one
        tab.isActive = true;
        $.each(tab.trees, function (liIdx, li) {
            li.show();
        });
    }

    private getTab(itemId: string): INavigationBarTabRuntime {
        // check if it is a 'normal database item' if so take it's category
        let category = ml.Item.parseRef(itemId).type;
        // skip the line when getting the breadcrumb

        let bread = category ? app.getCategoryBreadcrumbs(category) : app.getBreadcrumbs(itemId);
        let butter = bread[bread.length - 1];

        return this.rootTabMap[butter];
    }

    private toggleBar() {
        if (this.navBarOpened) {
            this.navBarOpened = false;
        } else {
            this.navBarOpened = true;
        }

        localStorage.setItem("navBarOpened", this.navBarOpened ? "true" : "false");
        this.resizeWitdh();
    }

    private resizeWitdh() {
        if (this.navBarOpened) {
            $(".navLeftToggle i").removeClass("fal fa-arrow-to-right").addClass("fal fa-arrow-to-left");
            $("#navLeft").removeClass("navLeftSmall");
            $(".navLeftText").show();
            $(".navLeftTab").css("width", "unset");
            NavigationBar.navbarWidth = 287;
        } else {
            $(".navLeftToggle i").removeClass("fal fa-arrow-to-left").addClass("fal fa-arrow-to-right");
            NavigationBar.navbarWidth = 72;
            $("#navLeft").addClass("navLeftSmall");
            $(".navLeftText").hide();
        }
        app.resizeItem(true);
    }

    private addHelpButton() {
        if (this.helpButton) this.helpButton.remove();
        this.helpButton = $(
            "<div class='navLeftHelp dropup'><i class='fal fa-question-circle dropdown-toggle' data-toggle='dropdown'></i>" +
                matrixSession.getHelpButton() +
                "</div>",
        ).prependTo(".navbar-left .navbar-brand");
        //Forward the click to the first link in the dropdown
        this.helpButton.find("li").click((e) => {
            if ($(e.target).find("a").length > 0) {
                $(e.target).find("a")[0].click();
            }
        });
    }

    openAnalytics() {
        // The content might depend on the tree being loaded so we wait for it.

        $(".navLeftTabActive").removeClass("navLeftTabActive");
        this.analyticsTab?.addClass("navLeftTabActive");
        app.openAnalytics();
    }
}

let NavBar: NavigationBar;

function initialize() {
    NavBar = new NavigationBar();
}
