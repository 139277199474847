//Import
/// <reference types="matrixrequirements-type-declarations" />

import { DateFieldHandler, IDB, MatrixSession } from "../../common/businesslogic/index";
import { IPlugin, IProjectPageParam, IPluginPanelOptions, plugins } from "../../common/businesslogic/index";
import { ml } from "../../common/matrixlib";
import { DateSelectImpl } from "../../common/UI/Controls/dateselect";
import { NavigationPanel } from "../../common/UI/MainTree/MainTree";
import { IItem, IStringNumberMap, ControlState, app, matrixSession, restConnection, globalMatrix } from "../../globals";
import {
    INotificationConfig,
    IContextPageConfigTab,
    notificationSetting,
    defaultNotificationConfig,
} from "../../ProjectSettings";
import { XRGetTodosAck, XRTodo, XRGetProject_Todos_GetTodosAck, XRTodoCount } from "../../RestResult";
import { IContextInformation, UIToolsConstants } from "../../common/matrixlib/MatrixLibInterfaces";
import { NavBar } from "../../common/UI/Components";
import { NotificationsBL } from "../../common/businesslogic/NotificationsBL";
import { INotificationsChanges } from "../../common/businesslogic/NotificationsCache";

export type { INotificationTableOptions };
export { Notifications, NotificationList };
export { initialize };

interface INotificationTableOptions {
    allowDelete: boolean;
    selectable: boolean;
    forColumn: boolean;
    itemColumn: boolean;
    doneColumn: boolean;
    canCloseMine: boolean;
    canCloseAll: boolean;
    showAddButton: boolean;
    none: string;
    tableClass?: string;
    moveDoneTo?: string;
}

// TODO: This is not a plugin since there are places in the code which refer to the class statically.
// Integrate it into businesslogic.
class Notifications implements IPlugin {
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private notificationConfig: INotificationConfig;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private lastCount: number;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private newNotification: boolean;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private _item: IItem;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private lastMenu: JQuery;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private projectCount: IStringNumberMap = null;
    public isDefault = true;
    previousNotificationsIds: number[] = [];

    constructor() {
        this.addFancyTreeNotificationCounterPlugin();
        this.watchActivity();
    }
    onUpdate(ui: JQuery, config: IContextPageConfigTab, context: IContextInformation) {}

    init() {
        let that = this;
        $().ready(() => {
            if (matrixSession && matrixSession.serverConfig) {
                let all = restConnection.getServer("all/todo").done((result) => {
                    that.setPreviousNotificationsIds(
                        (<XRGetTodosAck>result).todos.map((o) => {
                            return o.todoId;
                        }),
                    );
                });
            }
        });
    }
    setPreviousNotificationsIds(notifIds: number[]) {
        localStorage.setItem("previousNotificationsIds", JSON.stringify(notifIds));
    }
    getPreviousNotificationsIds(): number[] {
        let notif: number[] = [];
        let notifAsString = localStorage.getItem("previousNotificationsIds");

        if (notifAsString != undefined) {
            let parseNotif = JSON.parse(notifAsString);
            if (parseNotif != undefined) {
                return parseNotif;
            }
        }
        return notif;
    }
    initItem(item: IItem, jui: JQuery) {
        if (!this.isEnabled()) {
            return;
        }
        this._item = item;
        if (!this.projectCount) {
            this.projectCount = {};
            this.countRec(app.getTree());
        }
    }

    initServerSettings() {}

    initProject() {
        this.notificationConfig = <INotificationConfig>(
            matrixSession.getCustomerSettingJSON(notificationSetting, defaultNotificationConfig)
        );
        NotificationsBL.NoticationCache.setEnabled(this.isEnabled());

        if (!this.isEnabled()) {
            return;
        }
        this.updateProjectMenu();
        this.init();
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        this.projectCount = null;
    }

    isEnabled() {
        return this.notificationConfig && this.notificationConfig.enabled;
    }

    getProjectPagesAsync(): Promise<IProjectPageParam[]> {
        return new Promise((resolve, reject) => {
            let pages: IProjectPageParam[] = [];
            let that = this;

            if (this.isEnabled() && !globalMatrix.ItemConfig.getTimeWarp()) {
                pages.push({
                    id: "NOTIFICATION",
                    title: "My Notifications",
                    folder: "MYWORK",
                    order: 2000,
                    icon: "fal fa-bell",
                    usesFilters: false,
                    render: async (options: IPluginPanelOptions) => that.renderNotificationProjectPage(options),
                });
            }
            window.setTimeout(function () {
                if (!that.projectCount) {
                    that.projectCount = {};
                    that.countRec(app.getTree());
                }
            }, 1);
            resolve(pages);
        });
    }

    updateMenu(ul: JQuery) {
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        if (!this.isEnabled() || !ul || !this._item || ml.Item.parseRef(this._item.id).isFolder) {
            return;
        }

        let that = this;

        this.lastMenu = ul;

        $(".notificationMenu").remove();
        $(".notificationBtn").remove();

        let myNotification = NotificationsBL.NoticationCache.getProjectNotifications(
            matrixSession.getProject(),
            app.getCurrentItemId(),
        );

        let myNotificationMarker = myNotification.length
            ? "<i class='fal fa-bell '></i><span class='fancytree-notificationCounter notificationCounter'>" +
              myNotification.length +
              "</span>"
            : "<i class='fal fa-bell '></i>";
        $(
            '<div class="btn-group" style=""><button class="btn btn-item btn-sm notificationBtn" tabindex="-1" title="Show and create notifications">' +
                myNotificationMarker +
                "</button></div>",
        )
            .click(function (event: JQueryMouseEventObject) {
                that.showAllNotificationsDialog();
            })
            .insertBefore($("#shareButton").closest("div"));
    }

    private userCanAcknowledgeNotification(notification: XRTodo) {
        return !notification.auto;
        // that should be  notification.action.todoType == "user" ||  notification.action.todoType == "signaturesDone" ||  notification.action.todoType == "reviewsDone";
    }
    supportsControl() {
        return false;
    }

    private watchActivity() {
        let that = this;

        $(window).on("keyup click", function (event) {
            if (that.newNotification) {
                app.updateFavicon(matrixSession.getProject(), false);
                that.newNotification = false;
            }
        });
    }

    private updateActivity(newCount: number) {
        if (newCount > this.lastCount) {
            app.updateFavicon(matrixSession.getProject(), true);
            this.newNotification = true;
        }
        this.lastCount = newCount;
    }

    // this gets all the updates about the user's notifications after being signalled that something changed for the user
    async updateUI(notifChanged: INotificationsChanges) {
        let that = this;
        // update UI
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        that.projectCount = null; // removing the cache, so it will be reloaded next time updateCounters is called

        let total = notifChanged.total;
        let allNotifications = notifChanged.allNotifications;

        that.updateCounters();
        that.updateActivity(total);
        that.updateMenu(that.lastMenu);

        // Send browser notification. The random delay will avoid duplicates
        if (
            that.notificationConfig.browserNotificationDisabled == undefined ||
            that.notificationConfig.browserNotificationDisabled == false
        ) {
            let timeOut =
                that.notificationConfig.browserNotificationAutoCloseAfter != undefined
                    ? that.notificationConfig.browserNotificationAutoCloseAfter
                    : 9000;

            allNotifications.todos.forEach((t) => {
                if (that.getPreviousNotificationsIds().indexOf(t.todoId) == -1 && t.login == matrixSession.getUser()) {
                    if (window.Notification != undefined) {
                        if (window.Notification.permission !== "granted") window.Notification.requestPermission();
                        else {
                            // TODO: convert to const and make sure it's still works
                            // eslint-disable-next-line no-var
                            var n = new Notification(t.projectShort + "/" + t.itemRef, {
                                icon: globalMatrix.matrixBaseUrl + "/favicon_medical.ico",
                                body: t.originatorLogin + ": " + NotificationsBL.getMessage(t),
                                requireInteraction: true,
                            });

                            n.onclick = () => {
                                window.open(globalMatrix.matrixBaseUrl + "/" + t.projectShort + "/" + t.itemRef);
                            };
                        }
                        setTimeout(() => {
                            if (n != undefined) {
                                n.close();
                            }
                        }, timeOut);
                    }
                }
            });
        }
        that.setPreviousNotificationsIds(
            allNotifications.todos.map((o) => {
                return o.todoId;
            }),
        );
    }

    private async renderNotificationProjectPage(options: IPluginPanelOptions) {
        let that = this;

        if (options.controlState === ControlState.Print) {
            return;
        }

        document.title = "Notifications - " + matrixSession.getProject();

        options.control.html("");

        let h2 = ml.UI.getPageTitle("Notifications overview").appendTo(options.control);
        // paint the tabs
        let tabpanel = $(
            '<div role="tabpanel" class="tabpanel-container contextFrameContainer" style="top:60px;padding: 5px;">',
        );
        let tabpanelul = $('<ul class="nav nav-tabs contextFrameTabs" role="tablist">');

        let tabpanels = $('<div class="tab-content">');
        // enable copy
        ml.UI.copyBuffer(h2, "copy to clipboard", tabpanels, options.control, (copied: JQuery) => {
            $(".tabpaneltab.tab-pane", copied).not(".active").remove();
            $(".tab-content", copied).css("height", "");
        });

        options.control.append(tabpanel);
        tabpanel.append(tabpanelul);
        tabpanel.append(tabpanels);

        tabpanelul.append(
            '<li role="presentation" class="active"><a href="#MYNOTIFICATIONS"  role="tab" data-toggle="tab">Notifications for me</a></li>',
        );
        let myNotifications = $(
            '<div role="tabpanel"  style="height:100%" class="tabpaneltab tab-pane active" id="MYNOTIFICATIONS" >',
        );
        tabpanels.append(myNotifications);

        tabpanelul.append(
            '<li role="presentation"><a href="#MYDONE"  role="tab" data-toggle="tab">My previous notifications</a></li>',
        );
        let myDone = $('<div role="tabpanel"  style="height:100%" class="tabpaneltab tab-pane" id="MYDONE" >');
        tabpanels.append(myDone);

        tabpanelul.append(
            '<li role="presentation"><a href="#MYOWNED"  role="tab" data-toggle="tab">Notifications I created</a></li>',
        );
        let myOwned = $('<div role="tabpanel"  style="height:100%" class="tabpaneltab tab-pane" id="MYOWNED" >');
        tabpanels.append(myOwned);

        tabpanelul.append(
            '<li role="presentation"><a href="#MYOWNEDDONE"  role="tab" data-toggle="tab">Previous notifications I created</a></li>',
        );
        let myOwnedDone = $(
            '<div role="tabpanel"  style="height:100%" class="tabpaneltab tab-pane" id="MYOWNEDDONE" >',
        );
        tabpanels.append(myOwnedDone);

        // adjust heights of panels
        let height = $("#main").height() - tabpanels.offset().top + $("#main").offset().top;
        tabpanels.height(height);

        let notifs = await NotificationsBL.getGetNotificationsNowAndFuture(matrixSession.getProject());
        that.renderNotificationTable(
            myNotifications,
            {
                allowDelete: globalMatrix.globalShiftDown,
                selectable: globalMatrix.globalShiftDown,
                forColumn: false,
                itemColumn: true,
                doneColumn: false,
                canCloseMine: true,
                canCloseAll: false,
                showAddButton: false,
                none: "you have no todos",
                moveDoneTo: "MYDONE",
            },
            notifs.todosForNow.filter(function (notification) {
                return notification.login == matrixSession.getUser() && !notification.closedAt;
            }),
        );

        that.renderNotificationTable(
            myNotifications,
            {
                allowDelete: globalMatrix.globalShiftDown,
                selectable: globalMatrix.globalShiftDown,
                forColumn: false,
                itemColumn: true,
                doneColumn: false,
                canCloseMine: true,
                canCloseAll: true,
                showAddButton: false,
                none: "",
                moveDoneTo: "MYDONE",
            },
            notifs.todosForLater.filter(function (notification) {
                return notification.login == matrixSession.getUser() && !notification.closedAt;
            }),
        );
        that.renderNotificationTable(
            myDone,
            {
                allowDelete: globalMatrix.globalShiftDown,
                selectable: false,
                forColumn: false,
                itemColumn: true,
                doneColumn: true,
                canCloseMine: false,
                canCloseAll: true,
                showAddButton: false,
                none: "you did not finish any todo",
            },
            notifs.todosForNow.filter(function (notification) {
                return notification.login == matrixSession.getUser() && notification.closedAt;
            }),
        );
        that.renderNotificationTable(
            myOwned,
            {
                allowDelete: globalMatrix.globalShiftDown,
                selectable: globalMatrix.globalShiftDown,
                forColumn: true,
                itemColumn: true,
                doneColumn: false,
                canCloseMine: false,
                canCloseAll: true,
                showAddButton: false,
                none: "you did not create any todos",
                moveDoneTo: "MYOWNEDDONE",
            },
            notifs.todosForNow.filter(function (notification) {
                return notification.originatorLogin == matrixSession.getUser() && !notification.closedAt;
            }),
        );
        that.renderNotificationTable(
            myOwned,
            {
                allowDelete: globalMatrix.globalShiftDown,
                selectable: globalMatrix.globalShiftDown,
                forColumn: true,
                itemColumn: true,
                doneColumn: false,
                canCloseMine: false,
                canCloseAll: true,
                showAddButton: false,
                none: "",
            },
            notifs.todosForLater.filter(function (notification) {
                return notification.originatorLogin == matrixSession.getUser() && !notification.closedAt;
            }),
        );
        that.renderNotificationTable(
            myOwnedDone,
            {
                allowDelete: globalMatrix.globalShiftDown,
                selectable: false,
                forColumn: true,
                itemColumn: true,
                doneColumn: true,
                canCloseMine: false,
                canCloseAll: false,
                showAddButton: false,
                none: "none of your todos were done",
            },
            notifs.todosForNow.filter(function (notification) {
                return notification.originatorLogin == matrixSession.getUser() && notification.closedAt;
            }),
        );

        app.waitForMainTree(() => {
            options.control.highlightReferences();
        });
    }
    // renders a row with a notifications and +- actions on it
    protected renderNotificationRow(tr: JQuery, notification: XRTodo, tableOptions: INotificationTableOptions) {
        let that = this;

        let isAutomatic = !that.userCanAcknowledgeNotification(notification);
        let isForMe = notification.login == matrixSession.getUser();
        let isFromMe = notification.originatorLogin == matrixSession.getUser();

        if (notification.future) {
            tr.addClass("future");
        }
        if (notification.closedAt) {
            tr.addClass("past");
        }
        if (!isForMe) {
            tr.addClass("others");
        }

        if (tableOptions.selectable || tableOptions.allowDelete) {
            let input = $('<input class="notificationSelector" type="checkbox">').data("todoid", notification.todoId);
            $("<td>").appendTo(tr).append(input);
        }
        if (tableOptions.itemColumn) $("<td>" + notification.itemRef + "!</td>").appendTo(tr);
        if (tableOptions.forColumn)
            $("<td>" + (notification.login == matrixSession.getUser() ? "You" : notification.login) + "</td>").appendTo(
                tr,
            );
        $('<td style="white-space: pre-wrap;"">' + NotificationsBL.getMessage(notification) + "</td>").appendTo(tr);
        $("<td>" + notification.originatorLogin + "</td>").appendTo(tr);
        $("<td>" + notification.createdAtUserFormat + "</td>").appendTo(tr);
        if (tableOptions.doneColumn) $("<td>" + notification.closedAtUserFormat + "</td>").appendTo(tr);
        if (tableOptions.canCloseMine || tableOptions.canCloseAll) {
            if (tableOptions.canCloseAll || isForMe || isFromMe) {
                let td = $("<td>").appendTo(tr);
                if (isAutomatic && !that.notificationConfig.closeAuto) {
                    return;
                }
                if (notification.closedAt) {
                    if (!tableOptions.doneColumn)
                        $('<span class="closedAt">Closed: ' + notification.closedAtUserFormat + "</span>").appendTo(td);
                    return;
                }
                let closeStyle = "btn-link";
                let closeText = "acknowledge";
                let closeTitle = "remove notification";
                if (isAutomatic) {
                    closeStyle = "btn-link";
                    closeText = "force acknowledge";
                    closeTitle = "notification will also go once you do the job!";
                }
                if (notification.future) {
                    closeStyle = "btn-link";
                    closeText = "acknowledge already";
                    closeTitle = "remove notification already now";
                }
                if (!isForMe) {
                    closeStyle = "btn-link";
                    closeText = "mark done";
                    closeTitle = "mark notification as done for someone else";
                }
                if (tableOptions.moveDoneTo) {
                    tr.addClass("canBeClosed");
                    tr.data("todoId", notification.todoId);
                    tr.data("moveTo", tableOptions.moveDoneTo);
                }
                $('<button class="' + closeStyle + '" title="' + closeTitle + '">' + closeText + "</button>")
                    .appendTo(td)
                    .click(function (event: JQueryMouseEventObject) {
                        let button = $(event.delegateTarget);
                        NotificationsBL.deleteNotificationId(
                            matrixSession.getProject(),
                            button.data("todoid"),
                            false,
                        ).then(function () {
                            if (tableOptions.moveDoneTo) {
                                let todoId = button.closest("tr").data("todoId");
                                let toBeMoved = $(".canBeClosed").filter(function (trIdx, tr) {
                                    return $(tr).data("todoId") == todoId;
                                });
                                $.each(toBeMoved, function (idx, tbm) {
                                    let moveTo = $(tbm).data("moveTo");
                                    let tbodys = $("tbody", $("#" + moveTo));
                                    if (tbodys.length) {
                                        $(".btn-link", $(tbm)).replaceWith("<span>just now</span>");
                                        $(tbodys[0]).append($(tbm));
                                        $(tbodys[0]).parent().show();
                                        $(".noneMessage", $("#" + moveTo)).remove();
                                    } else {
                                        button.closest("tr").remove();
                                    }
                                });
                            } else {
                                button.closest("tr").remove();
                            }
                        });
                    })
                    .data("todoid", notification.todoId);
            } else {
                $("<td>").appendTo(tr);
            }
        }
    }
    // renders a table with notifications and +- actions on them
    protected renderNotificationTable(
        container: JQuery,
        tableOptions: INotificationTableOptions,
        notifications: XRTodo[],
    ) {
        let that = this;

        //Always add the table but hide it if there are no notifications
        let table = $(
            "<table class='table table-lined " + (tableOptions.tableClass ? tableOptions.tableClass : "") + "'>",
        );

        // create table with headings and body
        let thead = $(
            "<thead><tr>" +
                (tableOptions.selectable || tableOptions.allowDelete
                    ? "<th data-sorter='false' style='white-space: nowrap;'><input class='selectAllNotification' type='checkbox'> all</th>"
                    : "") +
                (tableOptions.itemColumn ? "<th>Item</th>" : "") +
                (tableOptions.forColumn ? "<th>Notification For</th>" : "") +
                "<th>Notification</th><th>Originator</th><th>Due date</th>" +
                (tableOptions.doneColumn ? "<th>Done at</th>" : "") +
                (tableOptions.canCloseMine || tableOptions.canCloseAll ? "<th></th>)" : "") +
                "</tr></thead>",
        );

        let tbody = $("<tbody>");
        table.append(thead).append(tbody).appendTo(container).tablesorter();
        table.hide();

        let newButtonContainer = $("<div>");
        newButtonContainer.appendTo(container);

        if (notifications.length == 0 && !tableOptions.showAddButton) {
            if (tableOptions.none) {
                $('<p class="noneMessage">').html(tableOptions.none).appendTo(container);
            }
            return;
        }

        if (!tableOptions.none) {
            $("<p>").html("Upcoming notifications").appendTo(container);
        }

        // Always show the table in case there is a button to add notifications
        let showTable = tableOptions.showAddButton;

        // add notifications
        $.each(notifications, function (idx, notification) {
            let tr = $("<tr>").appendTo(tbody);
            that.renderNotificationRow(tr, notification, tableOptions);
            showTable = true; // Show the table if there are notifications
        });

        if (showTable) {
            table.trigger("update"); // Let the tablesorter know about new rows.
            table.show();
        }

        if (tableOptions.showAddButton && this.notificationConfig.manualCreate) {
            $(
                "<button style='margin-top: 12px;' class='btn btn-success '><i class='fal fa-bell' style='margin-right:12px'></i>Create New</button>",
            )
                .appendTo(newButtonContainer)
                .click(function () {
                    // @ts-ignore TODO: something is seriously wrong here. as far as I can see we're returning XRTodo[]
                    // from the showCreateNotificationDialog method, not Number[]
                    that.showCreateNotificationDialog().done(async function (todoIds: number[]) {
                        let allNotification = await NotificationsBL.getAllNotificationForItem(
                            matrixSession.getProject(),
                            app.getCurrentItemId(),
                        );
                        // create is always for current item
                        let todos = allNotification.todos.filter(function (todo) {
                            return todoIds.indexOf(todo.todoId) != -1;
                        });
                        $.each(todos, function (idx, todo) {
                            let newNotificationRow = $("<tr>").appendTo(tbody);
                            that.renderNotificationRow(newNotificationRow, todo, tableOptions);
                        });
                        table.trigger("update");
                    });
                });
        }

        // assemble table
        let rowFlex = $("<div class='rowFlex'>").appendTo(container);

        if (tableOptions.selectable || tableOptions.allowDelete) {
            $(".selectAllNotification", table).click(function () {
                $(".notificationSelector", table).prop("checked", $(".selectAllNotification", table).prop("checked"));
            });
            if (tableOptions.selectable) {
                $("<button title class='btn btn-xs btn-default hideCopy'>Mark as done</button>")
                    .appendTo(rowFlex)
                    .click(function () {
                        that.closeNotifications($(".notificationSelector:checked", table), false);
                    });
            }
            if (tableOptions.allowDelete) {
                $("<button title class='btn btn-xs btn-default hideCopy'>Permanently delete</button>")
                    .appendTo(rowFlex)
                    .click(function () {
                        that.closeNotifications($(".notificationSelector:checked", table), true);
                    });
            }

            $("<p><br></p>").appendTo(container);
        }
    }

    protected indicateNotificationChange() {
        $(".notificationBtn").removeClass("bounce").addClass("bounce");
    }
    protected closeNotifications(notifications: JQuery, deleteThem: boolean) {
        ml.UI.BlockingProgress.Init([{ name: "Acknowledging notifications" }]);
        this.deleteNotificationIdRec(matrixSession.getProject(), notifications, deleteThem, 0);
    }
    // show all notifications for current user
    protected updateProjectMenu() {
        let that = this;

        let menu = $("#idNotificationList");
        let menuResponsive = $("#idNotificationListResponsive");
        // add a navigation icon left next button to project  menu
        $(".notificationBtnMenu").remove();
        let projectButton = $(
            '<button class="btn btn-item  notificationBtnMenu dropdown-toggle" data-toggle="dropdown" title="" data-original-title="Show all notifications"><i class="fal fa-bell notificationAction"></i></button>',
        ).insertBefore(menu);
        let projectButtonResponsive = $(
            '<buttton class="btn btn-item  notificationBtnMenu dropdown-toggle" data-toggle="dropdown" title="" data-original-title="Show all notifications"><i class="fal fa-bell notificationAction"></i></buttton>',
        ).insertBefore(menuResponsive);
        // remove old items
        menu.html("");
        menuResponsive.html("");

        $.each(matrixSession.getProjectList(false), function (idx, project) {
            // We skip projects that we don't have access
            if (project.accessType === "none") return;

            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            let notificationCount: XRTodoCount = NotificationsBL.NoticationCache.getTotalNotificationsProject(
                project.shortLabel,
            );
            if (notificationCount) {
                // there are some notifications for project
                let mss: string[] = [];
                // take up to first two messages from recent message list
                if (notificationCount.firstTodos.length > 0)
                    mss.push(NotificationsBL.getMessage(notificationCount.firstTodos[0]));
                if (notificationCount.firstTodos.length > 1)
                    mss.push(NotificationsBL.getMessage(notificationCount.firstTodos[1]));

                // format messages for ui: 3 lines
                let last = "";
                if (mss.length > 0) {
                    if (mss[0] != undefined) {
                        last = mss[0].length < 30 ? mss[0] : mss[0].substring(0, 30 - 3) + "...";
                    }
                }
                let before = "";
                if (mss.length > 1) {
                    if (mss[1] != undefined) {
                        before = mss[1].length < 30 ? mss[1] : mss[1].substring(0, 30 - 3) + "...";
                    }
                }
                let more =
                    notificationCount.nbTodos > mss.length
                        ? notificationCount.nbTodos - mss.length + " more messages"
                        : "";

                // build ui

                let img = matrixSession.getImgFromProject(project.shortLabel);

                let msg = ` ${img} <span class='mainmenu'>${project.shortLabel}
                                        <div class="notificationMenuSummary">
                                        <span class="last">${last}</span>
                                        <span class="before">${before}</span>
                                        <span class="more">${more}</span>
                                        </div>
                                    </span>`;

                $('<li class="notification-item">' + msg + "</li>")
                    .appendTo(menu)
                    .click(function (event: JQueryEventObject) {
                        app.canNavigateAwayAsync().done(function () {
                            // If we have QMSViewer only access, we open the published QMS in a new tab.
                            if (project.accessType === "qmsviewer") {
                                window.open(
                                    `${globalMatrix.matrixBaseUrl}/pub/${$(event.delegateTarget).data(
                                        "project",
                                    )}?USER=training`,
                                );
                            } else {
                                matrixSession.loadProject(
                                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                    null,
                                    $(event.delegateTarget).data("project") + "/MYWORK",
                                    true,
                                );
                            }
                        });
                    })
                    .data("project", project.shortLabel);
                $('<li style="position: relative;">' + msg + "</li>")
                    .appendTo(menuResponsive)
                    .click(function (event: JQueryEventObject) {
                        app.canNavigateAwayAsync().done(function () {
                            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                            matrixSession.loadProject(null, $(event.delegateTarget).data("project") + "/MYWORK", true);
                        });
                    })
                    .data("project", project.shortLabel);
            }
        });
        // fake show the list, to get the sizes
        let height = Math.max($("#main").height(), 200);
        // end fake show the list, to get the sizes
        $("#idNotificationList")
            .css("max-height", height + "px")
            .css("overflow-y", "auto")
            .css("overflow-x", "none");
        $("nav").css("visibility", "").css("display", "");

        // do main button
        this.lastCount = NotificationsBL.NoticationCache.getTotalNotifications();
        if (this.lastCount) {
            projectButton.append($("<span class='notificationIcon'>").html("" + this.lastCount));
            projectButtonResponsive.append($("<span class='notificationIcon'>").html("" + this.lastCount));
        }
    }

    // show a dialog asking for details of a notification to create
    protected showCreateNotificationDialog() {
        let that = this;
        let res: JQueryDeferred<XRTodo[]> = $.Deferred();

        let sendTo: string[] = [];

        async function updateSend() {
            let sendButton = $(".btnDoIt", ui.parent().parent());
            let enabled = sendTo.length && subj && (await subj.getController().getValueAsync()) !== "";
            ml.UI.setEnabled(sendButton, enabled);
        }

        let usel = $("<div>");

        // take users from current project
        ml.UI.SelectUserOrGroup.showMultiUserSelect(
            usel,
            "For",
            [],
            "Create Notification For",
            "",
            "",
            true,
            true,
            (selection: string[]) => {
                sendTo = selection;
                updateSend();
            },
        );

        let subj = $("<div>").plainText({
            controlState: ControlState.FormEdit,
            canEdit: true,
            help: "Notification message",
            fieldValue: "",
            valueChanged: function () {
                updateSend();
            }, // callback to call if value changes
            parameter: {
                allowResize: false,
            },
        });

        let dateSel = $("<div>").dateselect({
            controlState: ControlState.FormEdit,
            canEdit: true,
            help: "Date to activate (optional)",
            fieldValue: "",
            valueChanged: function () {
                updateSend();
            },
            parameter: {
                allowClear: true,
                minDate: new Date(),
            },
        });

        let dlg = $("<div>").appendTo($("body"));
        let ui = $("<div style='width:100%;height:100%'>");

        ui.append(dateSel);
        ui.append(usel);
        ui.append(subj);

        ui.addClass("dlg-no-scroll");
        ui.removeClass("dlg-v-scroll");

        ml.UI.showDialog(
            dlg,
            "Create Notification",
            ui,
            -730,
            -500,
            [
                {
                    text: "Create",
                    class: "btnDoIt",
                    click: async function () {
                        let text = DOMPurify.sanitize(await subj.getController().getValueAsync()) + "";
                        let dateStr = await dateSel.getController().getValueAsync();
                        let date = DateFieldHandler.getDateFromString(dateStr);
                        if (
                            date &&
                            ml.UI.DateTime.renderDashFormat(date) == ml.UI.DateTime.renderDashFormat(new Date())
                        ) {
                            // date is for today
                            date = null;
                        }
                        let created = await NotificationsBL.createNotification(
                            sendTo,
                            matrixSession.getProject(),
                            app.getCurrentItemId(),
                            text,
                            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                            null,
                            date,
                        );
                        res.resolve(created);
                        dlg.dialog("close");
                    },
                },
                {
                    text: "Cancel",
                    class: "btnCancelIt",
                    click: function () {
                        dlg.dialog("close");
                    },
                },
            ],
            UIToolsConstants.Scroll.None,
            false,
            false,
            () => {
                dlg.remove();
            },
            () => {
                updateSend();
                $("textarea", subj).focus();
            },
            () => {},
        );

        return res;
    }

    // show a dialog with all notifications
    protected showAllNotificationsDialog(): void {
        let that = this;

        let dlg = $("<div>").appendTo($("body"));
        let ui = $("<div style='width:100%'>");

        ml.UI.showDialog(
            dlg,
            "Notifications",
            ui,
            900,
            600,
            [
                {
                    text: "Ok",
                    class: "btnDoIt",
                    click: function () {
                        dlg.dialog("close");
                    },
                },
            ],
            UIToolsConstants.Scroll.Vertical,
            true,
            true,
            () => {
                dlg.remove();
            },
            () => {
                that.showNotifications(ui);
            },
            () => {},
        );
    }

    private showNotifications(ui: JQuery) {
        let that = this;
        ui.html("").append(ml.UI.getSpinningWait("getting notifications..."));

        restConnection
            .getProject("todo?includeDone=1&includeFuture=1&itemRef=" + app.getCurrentItemId())
            .done(function (result) {
                const allNotification = result as XRGetTodosAck;

                ui.html("");

                let cbs = {
                    others: localStorage.getItem("ShowNotificationOthers") == "0" ? false : true,
                    future: localStorage.getItem("ShowNotificationFuture") == "0" ? false : true,
                    past: localStorage.getItem("ShowNotificationPast") == "0" ? false : true,
                };

                let table = $("<table style='width:100%'>").appendTo(ui);
                let tr = $("<tr>").appendTo(table);
                let td1 = $("<td>").appendTo(tr);
                let td2 = $("<td>").appendTo(tr);
                let td3 = $("<td>").appendTo(tr);

                ml.UI.addCheckbox(td1, "Show notifications for others", cbs, "others", function () {
                    that.filterNotifications(ui, cbs);
                });
                ml.UI.addCheckbox(td2, "Show notifications for future", cbs, "future", function () {
                    that.filterNotifications(ui, cbs);
                });
                ml.UI.addCheckbox(td3, "Show notifications from the past", cbs, "past", function () {
                    that.filterNotifications(ui, cbs);
                });

                $('<div style="margin-bottom: 40px;">').appendTo(ui); // just a bit of space

                that.renderNotificationTable(
                    ui,
                    {
                        allowDelete: false,
                        selectable: false,
                        forColumn: true,
                        itemColumn: false,
                        doneColumn: false,
                        canCloseMine: true,
                        canCloseAll: false,
                        showAddButton: true,
                        none: "no (more) to dos",
                        tableClass: "itemNotifications",
                    },
                    allNotification.todos,
                );

                that.filterNotifications(ui, cbs);
            });
    }
    // hide / show notifications depending on checkboxes, remember last value
    protected filterNotifications(ui: JQuery, cbs: any) {
        localStorage.setItem("ShowNotificationOthers", cbs.others ? "1" : "0");
        localStorage.setItem("ShowNotificationFuture", cbs.future ? "1" : "0");
        localStorage.setItem("ShowNotificationPast", cbs.past ? "1" : "0");

        $("tr", ui).show();
        if (!cbs.others) $("tr.others", ui).hide();
        if (!cbs.future) $("tr.future", ui).hide();
        if (!cbs.past) $("tr.past", ui).hide();
    }

    // mark a notification as resolved (mark as done)
    deleteNotificationDlg(notification: XRTodo) {
        let that = this;
        let res = $.Deferred();
        ml.UI.showConfirm(
            -1,
            {
                title: "Remove notification '" + NotificationsBL.getMessage(notification) + "'",
                ok: "Ok",
                nok: "Cancel",
            },
            () => {
                try {
                    NotificationsBL.deleteNotification(notification);
                    res.resolve();
                } catch (err) {
                    res.reject();
                }
            },
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            null,
        );
        return res;
    }

    protected deleteNotificationIdRec(project: string, notifications: JQuery, deleteThem: boolean, idx: number) {
        let that = this;
        let res = $.Deferred();

        if (notifications.length <= idx) {
            res.resolve();
            return res;
        }
        NotificationsBL.deleteNotificationId(project, $(notifications[idx]).data("todoid"), deleteThem)
            .then(function () {
                ml.UI.BlockingProgress.SetProgress(0, ((idx + 1) * 100) / notifications.length);
                $(notifications[idx]).closest("tr").css("color", "lightgrey").css("text-decoration", "line-through");

                that.deleteNotificationIdRec(project, notifications, deleteThem, idx + 1)
                    .done(function () {
                        res.resolve();
                    })
                    .fail(function () {
                        res.reject();
                    });
            })
            .catch(function () {
                ml.UI.BlockingProgress.SetProgressError(0, "failed deleting todo");
            });

        return res;
    }

    // create a new notification object

    // count the notifications of a folder by adding up notifications of it's items
    private countRec(idb: IDB[]) {
        let that = this;
        let sum = 0;
        for (const child of idb) {
            let count = 0;

            if (child.children) {
                // this is a folder
                count = that.countRec(child.children);
            } else {
                count = NotificationsBL.NoticationCache.getProjectNotifications(
                    matrixSession.getProject(),
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    child.id,
                ).length;
            }
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            that.projectCount[child.id] = count;
            sum += count;
        }

        return sum;
    }

    // update the counters in the tree: build a new cache and repaint them
    protected updateCounters() {
        let that = this;

        // update counters
        window.setTimeout(function () {
            if (!that.projectCount) {
                that.projectCount = {};
                that.countRec(app.getTree());
                that.updateProjectMenu();

                // change icons in tree
                NavigationPanel.updateNotificationCounters();
                NavBar.updateNotificationCounters();
            }
        }, 1);
    }

    // returned the cached count of notifications for a specific item
    // if it is not yet cached, recreate it
    protected getNotificationCount(itemId: string) {
        let that = this;

        if (!this.projectCount) {
            this.projectCount = {};
            this.countRec(app.getTree());
        }
        return this.projectCount[itemId];
    }
    private addFancyTreeNotificationCounterPlugin() {
        (<any>(<any>$.ui).fancytree)._FancytreeClass.prototype.updateNotificationCounters = function () {
            this.visit(function (node: any) {
                node.updateNotificationCounters(false);
            });
        };

        (<any>(<any>$.ui).fancytree)._FancytreeNodeClass.prototype.updateNotificationCounters = function (
            doParents: boolean,
        ) {
            if (this.span) {
                let $badge = $("span.fancytree-notificationCounter", this.span),
                    extOpts = this.tree.options.notificationCounter,
                    count = NotificationList.getNotificationCount(this.key);
                if (count && this.span && (!this.isExpanded() || !extOpts.hideExpanded)) {
                    if (!$badge.length) {
                        if (this.folder) {
                            let expander = $("span.fancytree-expander", this.span);
                            $badge = $(
                                "<span class='fancytree-notificationCounter notificationCounter'/>",
                            ).insertBefore(expander);
                        } else {
                            $badge = $(
                                "<span class='fancytree-notificationCounter notificationCounter'/>",
                            ).insertBefore($("span.fancytree-icon,span.fancytree-custom-icon", this.span));
                        }
                    }
                    $badge.text(count);
                    $badge.closest("li").data("notifications", count);
                } else {
                    $badge.closest("li").data("notifications", 0);
                    $badge.remove();
                }
                if (doParents && extOpts.deep && !this.isTopLevel() && !this.isRoot()) {
                    this.parent.updateCounters();
                }
            }
        };

        // TODO(modules): improve all this casting.
        const tmp: Fancytree.FancytreeStatic = (<any>$.ui).fancytree;
        tmp.registerExtension({
            // Every extension must be registered by a unique name.
            name: "notificationCounter",
            // Version information should be compliant with [semver](http://semver.org)
            version: "1.0",

            // Extension specific options and their defaults.
            // This options will be available as `tree.options.notificationCounter.hideExpanded`

            options: {
                deep: true,
                hideZeros: true,
                hideExpanded: false,
                // dnd: {
                //     axis: "y"
                // }
            },

            // Local functions are prefixed with an underscore '_'.
            // Callable as `this._local._appendCounter()`.

            /*_appendCounter: function(bar) {
                var tree = this;
            },*/

            // **Override virtual methods for this extension.**

            // `treeInit` is triggered when a tree is initialized. We can set up classes or
            // bind event handlers here...
            treeInit: function (...args: unknown[]) {
                (<any>this)._super.apply(this, args);

                // Add a class to the tree container
                (<any>this).$container.addClass("fancytree-ext-notificationCounter");
            },

            // Destroy this tree instance (we only call the default implementation, so
            // this method could as well be omitted).

            treeDestroy: function (...args: unknown[]) {
                //(<any>this)._superApply(arguments);
                (<any>this)._super.apply(this, args);
            },

            // Overload the `renderTitle` hook, to append a counter badge
            nodeRenderTitle: function (ctx: any, title: any) {
                let node = ctx.node,
                    extOpts = ctx.options.notificationCounter,
                    count = NotificationList.getNotificationCount(node.key);
                // Let the base implementation render the title
                // We use `_super()` instead of `_superApply()` here, since it is a little bit
                // more performant when called often
                (<any>this)._super(ctx, title);
                // Append a counter badge
                if (count && (!node.isExpanded() || !extOpts.hideExpanded)) {
                    if (node.folder) {
                        $("span.fancytree-expander", node.span)
                            .before($("<span class='fancytree-notificationCounter notificationCounter'/>").text(count))
                            .closest("li")
                            .data("notifications", count);
                    } else {
                        $("span.fancytree-icon,span.fancytree-custom-icon", node.span).before(
                            $("<span class='fancytree-notificationCounter notificationCounter'/>").text(count),
                        );
                    }
                }
            },
        });
    }

    static anchorTimer: number;
    static anchorNotifications() {
        window.clearTimeout(Notifications.anchorTimer);
        let itemId = app.getCurrentItemId();
        Notifications.anchorTimer = window.setTimeout(() => {
            if (itemId == app.getCurrentItemId()) {
                let project = matrixSession.getProject();

                for (let notification of NotificationsBL.NoticationCache.getNotifications()) {
                    if (notification.projectShort == project && itemId == notification.itemRef) {
                        let anchor = NotificationsBL.getField(notification);
                        $(anchor).append(`<i class="fal fa-bell" style="color:${notification.userId}"></i>`);
                    }
                }
            }
        }, 1000);
    }
}

// TODO: convert to const and make sure it's still works
// eslint-disable-next-line no-var
var NotificationList: Notifications;

function initialize() {
    NotificationList = new Notifications();
    plugins.register(NotificationList);
}
