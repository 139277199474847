import { FieldDescriptions } from "../FieldDescriptions";
import { XRFieldTypeAnnotatedParamJson } from "../ItemConfiguration";
import { TestManagerConfiguration } from "../TestManagerConfiguration";
import { ITableControlBaseParams } from "./BaseTableFieldHandler";
import { BaseValidatedTableFieldHandler } from "./BaseValidatedTableFieldHandler";
import { ITestConfigTablesColumns } from "../../../ProjectSettings";

export { TestStepsFieldHandler };

class TestStepsFieldHandler extends BaseValidatedTableFieldHandler {
    public static UpdateFieldConfig(
        options: XRFieldTypeAnnotatedParamJson,
        itemType: string,
        testConfig: TestManagerConfiguration,
    ) {
        // This field handler gets it's configuration options from the test manager.
        // Ignore input options completely.
        const rconfig = testConfig.getTestStepsConfig(itemType);
        for (let name in rconfig) {
            options[name] = rconfig[name as keyof ITestConfigTablesColumns];
        }
    }

    constructor(options: ITableControlBaseParams) {
        super(options);
    }

    getFieldType(): string {
        return FieldDescriptions.Field_test_steps;
    }
}
