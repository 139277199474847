import {
    ControlState,
    IItemGet,
    IControlDefinition,
    IItemPut,
    IGenericMap,
    IReference,
    globalMatrix,
    matrixSession,
    app,
    matrixApplicationUI,
} from "../../../globals";
import { FieldHandlerFactory, XRFieldTypeAnnotated } from "../../businesslogic/index";
import { IPlugin, plugins } from "../../businesslogic/index";
import { ItemControl } from "../Components/ItemForm";
import { SelectMode } from "../Components/ProjectViewDefines";
import { IBaseControlOptions, BaseControl } from "./BaseControl";
import { IHtmlFormOptions } from "./htmlform";
import { ILinkCategories } from "./linkCollection";
import { ml } from "./../../matrixlib";
import { refLinkStyle, refLinkTooltip } from "../Parts/RefLinkDefines";
import { ItemCreationTools } from "../Tools/ItemCreationView";
import { ItemSelectionTools } from "../Tools/ItemSelectionView";
import { FieldDescriptions } from "../../businesslogic/FieldDescriptions";
import { EmptyFieldHandler } from "../../businesslogic/FieldHandlers/EmptyFieldHandler";

export interface ISyncStatusOptions extends IBaseControlOptions {
    controlState?: ControlState;
    canEdit?: boolean;
    help?: string;
    fieldValue?: string;
    valueChanged?: Function;
    parameter?: {
        readonly?: boolean;
    };
}

export interface ISyncCatgoryInfo {
    categories: string; // comma seperated list of categories, e.g. REQ,SPEC
    sourceName: string; // description of category source (e.g. confluence)
    new?: ISyncMapping[]; // mappings for automatic syncing of new external items
    resync?: ISyncMapping[]; // mappings for automatic syncing of new external items
}
export interface ISyncMapping {
    from: string; // name of field from external item
    to: string; // name of corresponding field of sync target
    fromId?: number; // added by matchmaking algorithm (if it field really exists), the  id of the field
    toId?: number; // added by matchmaking algorithm
}

export interface ISyncStatusValue {
    targetItemId?: string; // matrix item with which this one was / is synced
    targetSyncedVersion?: number; // version of target item with which this was in sync
    thisSyncedVersion?: number; // last version of this which was in sync
}

export interface IMergeResult {
    targetFieldId: string;
    targetFieldValue: string;
}

$.fn.syncStatus = function (this: JQuery, options: ISyncStatusOptions) {
    this.getController = () => {
        return baseControl;
    };
    if (!options.fieldHandler) {
        options.fieldHandler = FieldHandlerFactory.CreateHandler(
            globalMatrix.ItemConfig,
            FieldDescriptions.Field_syncStatus,
            options,
        );
        options.fieldHandler.initData(JSON.stringify(options.fieldValue));
    }
    let baseControl = new SyncStatusImpl(this, options.fieldHandler as EmptyFieldHandler);
    baseControl.init(options);
    return this;
};

export class SyncStatusImpl extends BaseControl<EmptyFieldHandler> {
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private settings: ISyncStatusOptions;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private syncStatus: ISyncStatusValue;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private syncCatgoryInfo: ISyncCatgoryInfo;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private labelStatus: string;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private target: ItemControl;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private source: ItemControl;
    private newCreated = false;
    static syncBlackList = ["jira", "workflow", "tasksControl", "labels"];
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private mergeResults: IMergeResult[];

    constructor(control: JQuery, fieldHandler: EmptyFieldHandler) {
        super(control, fieldHandler);
    }

    init(options: IHtmlFormOptions) {
        let that = this;
        this.mergeResults = [];

        if (!matrixSession.hasAgileSync()) {
            this._root.append("agile links not licensed");
            return;
        }

        // initialize options
        let defaultOptions = {
            controlState: ControlState.FormView, // read only rendering
            dummyData: false, // fill control with a dumy text (for form design...)
            canEdit: false, // whether data can be edited
            valueChanged: function () {}, // callback to call if value changes
            parameter: {
                readonly: false, // can be set to overwrite the default readonly status
            },
        };

        this.settings = ml.JSON.mergeOptions(defaultOptions, options);

        // this category setting has details about the sync source, possible target categories and fields to be synced
        this.syncCatgoryInfo = <ISyncCatgoryInfo>(
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            globalMatrix.ItemConfig.getCategorySetting(this.settings.type, "syncInfo")
        );
        // in case fields to be copied are not configured, use default fields
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        SyncStatusImpl.updateSyncDetails(this.settings.type, this.syncCatgoryInfo);

        // hide sync field from history, thumbnails, etc...
        if (
            this.settings.controlState !== ControlState.FormEdit &&
            this.settings.controlState !== ControlState.FormView
        ) {
            this._root.hide();
            return;
        }

        let ctrlContainer = $("<div>").addClass("baseControl").css("margin-bottom", "5px");
        this._root.append(ctrlContainer);

        // prepare sync info bar
        this.labelStatus = this.getSyncStatus();
        if (!this.settings.fieldValue) {
            this.syncStatus = {};
            // item has never been synced
            this._root.addClass("syncStatus syncStatusNotLinked");
            ctrlContainer.append("<span class=''>Not linked</span>");
            this.labelStatus = "NEW"; // 'fake'
        } else {
            this.syncStatus = <ISyncStatusValue>ml.JSON.fromString(this.settings.fieldValue).value;
            if (this.labelStatus === "IGNORE") {
                this._root.addClass("syncStatus syncStatusNoSync");
                ctrlContainer.append("<span class=''>Not linked</span>");
            } else if (this.labelStatus === "SYNCED") {
                this._root.addClass("syncStatus syncStatusInSync");
                this.showSyncInfo(ctrlContainer);
            } else if (this.labelStatus === "UNSYNC") {
                this.showLinkInfo(ctrlContainer);
                this._root.addClass("syncStatus syncStatusOutOfSync");
            }
        }

        // add sync actions
        if (this.settings.controlState === ControlState.FormEdit) {
            if (this.labelStatus === "NEW") {
                // item has never be synced: allow user to browse for item or create a new one
                this.offerLinking(ctrlContainer, true);
            } else if (this.labelStatus === "IGNORE") {
                // item is not  synced allow user to browse for item or create a new one
                this.offerLinking(ctrlContainer, false);
            } else if (this.labelStatus === "UNSYNC") {
                this.offerBreakLink(ctrlContainer);
                this.offerAutoSync(ctrlContainer, true);
                this.offerManualSync(true);
            } else if (this.labelStatus === "SYNCED") {
                that.offerBreakLink(ctrlContainer);
                this.offerAutoSync(ctrlContainer, false);
                this.offerManualSync(false);
            }
        }

        // add hook to catch save...
        mSyncStatusHook.initSyncControl(this);
        this._root.data("original", JSON.stringify(this.syncStatus));
    }

    async hasChangedAsync() {
        return this.mergeResults.length > 0 || this._root.data("original") !== JSON.stringify(this.syncStatus);
    }

    async getValueAsync() {
        return JSON.stringify(this.syncStatus);
    }

    destroy() {
        mSyncStatusHook.destroySyncControl();
    }

    resizeItem() {}

    preSaveHook(isItem: boolean, type: string, controls: IControlDefinition[]): Promise<{}> {
        let that = this;
        // TODO: refactor it
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            // update sync status labels (of this=external item)
            for (let control of controls) {
                if (control.fieldType == FieldDescriptions.Field_labels) {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    let labelsStr = await control.control.getController().getValueAsync();
                    let labels = labelsStr ? JSON.parse(labelsStr) : [];

                    if (!that.syncStatus.targetItemId) {
                        // user clicked on break link / or ignore external item
                        that.setSyncStatus(labels, "IGNORE");
                    } else if (that.mergeResults.length > 0 || that.newCreated) {
                        // at least one field was synced manually / or whole item was synced
                        that.setSyncStatus(labels, "SYNCED");
                    } else {
                        // item was linked but is not synced
                        that.setSyncStatus(labels, "UNSYNC");
                    }

                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    control.control.getController().setValue(JSON.stringify(labels));
                }
            }
            // prepare changes of target item
            let changes: IItemPut = { id: this.syncStatus.targetItemId, onlyThoseFields: 1, onlyThoseLabels: 1 };

            if (this.mergeResults.length > 0) {
                // copy syncable fields to target
                $.each(this.mergeResults, function (idx, manualMerge) {
                    (<IGenericMap>changes)[manualMerge.targetFieldId] = manualMerge.targetFieldValue;
                });
                // save the other (target item) if saving works, this (external) item will be saved with new settings
                app.updateItemInDBAsync(changes, "sync")
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    .done(function (newItem: IItemGet) {
                        // update this (external) syn info  version
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        that.syncStatus.targetSyncedVersion = newItem.history.length;
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        that.syncStatus.thisSyncedVersion = that.settings.item.history.length + 1;
                        // continue save
                        resolve({});
                    })
                    .fail(function () {
                        reject();
                    });
            } else {
                // other item did not change, continue saving
                resolve({});
            }
        });
    }

    // static interface for sync panel functions
    static createNew(externalCategory: string, items: string[], targetCategory: string): JQueryDeferred<{}> {
        let res = $.Deferred();
        // this category setting has details about the sync source, possible target categories and fields to be synced
        let syncCatgoryInfo = <ISyncCatgoryInfo>(
            globalMatrix.ItemConfig.getCategorySetting(externalCategory, "syncInfo")
        );
        // in case fields to be copied are not configured, use default fields
        SyncStatusImpl.updateSyncDetails(externalCategory, syncCatgoryInfo);
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        let actualMapping = SyncStatusImpl.getFieldIdMapping(externalCategory, targetCategory, syncCatgoryInfo.new);
        ml.UI.Progress.Init("creating synced items... ");
        SyncStatusImpl.createNewRec(items, 0, externalCategory, targetCategory, actualMapping)
            .done(function () {
                ml.UI.Progress.SuccessHide("items created", 2000);
            })
            .fail(function () {
                ml.UI.Progress.ErrorHide("failed to create items", 2000);
            });

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        return res;
    }

    static reSyncItems(externalCategory: string, items: string[]): JQueryDeferred<{}> {
        let res = $.Deferred();
        // this category setting has details about the sync source, possible target categories and fields to be synced
        let syncCatgoryInfo = <ISyncCatgoryInfo>(
            globalMatrix.ItemConfig.getCategorySetting(externalCategory, "syncInfo")
        );
        // in case fields to be copied are not configured, use default fields
        SyncStatusImpl.updateSyncDetails(externalCategory, syncCatgoryInfo);
        ml.UI.Progress.Init("creating synced items... ");
        SyncStatusImpl.reSyncItemsRec(items, 0, externalCategory, syncCatgoryInfo)
            .done(function () {
                ml.UI.Progress.SuccessHide("items created", 2000);
            })
            .fail(function () {
                ml.UI.Progress.ErrorHide("failed to create items", 2000);
            });

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        return res;
    }

    static breakLinks(externalCategory: string, items: string[]): JQueryDeferred<{}> {
        let res = $.Deferred();
        ml.UI.Progress.Init("breaking sync links ... ");
        SyncStatusImpl.breakLinksRec(items, 0, externalCategory)
            .done(function () {
                ml.UI.Progress.SuccessHide("removed sync links", 2000);
            })
            .fail(function () {
                ml.UI.Progress.ErrorHide("failed to remove sync links", 2000);
            });

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        return res;
    }

    private static breakLinksRec(items: string[], index: number, externalCategory: string): JQueryDeferred<{}> {
        let that = this;
        let res = $.Deferred();

        if (index >= items.length) {
            res.resolve();
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            return res;
        }

        ml.UI.Progress.Update((100 * index) / items.length);

        let itemId = items[index];
        index++;

        // need to get item, in case there are some downlinks to remove
        app.getItemAsync(itemId)
            .done(function (externalItem) {
                let changes: IItemPut = { id: itemId, onlyThoseFields: 1, onlyThoseLabels: 1 };

                // set status to IGNORE
                changes.labels = "IGNORE,-SYNCED,-NEW,-UNSYNC";
                // whipe out sync info just in case
                let fields = globalMatrix.ItemConfig.getFieldsOfType("syncStatus", externalCategory);
                if (fields.length == 1) {
                    let syncStatusFieldId = fields[0].field.id;
                    (<IGenericMap>changes)[syncStatusFieldId] = JSON.stringify({});
                }
                app.updateItemInDBAsync(changes, "batch break sync")
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    .done(function (newItem: IItemGet) {
                        // quietly remove downlinks, don't even wait for it...
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        $.each(externalItem.downLinks, function (dlidx, dl) {
                            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                            app.removeDownLinkAsync(externalItem.id, dl.to).done(function () {});
                        });

                        SyncStatusImpl.breakLinksRec(items, index, externalCategory)
                            .done(function () {
                                res.resolve();
                            })
                            .fail(function () {
                                res.reject();
                            });
                    })
                    .fail(function () {
                        res.reject();
                    });
            })
            .fail(function () {
                res.reject();
            });

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        return res;
    }

    private static createNewRec(
        items: string[],
        index: number,
        externalCategory: string,
        targetCategory: string,
        syncMapping: ISyncMapping[],
    ): JQueryDeferred<{}> {
        let that = this;
        let res = $.Deferred();

        if (index >= items.length) {
            res.resolve();
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            return res;
        }

        ml.UI.Progress.Update((100 * index) / items.length);

        let itemId = items[index];
        index++;

        let targetFolder = "F-" + targetCategory + "-1";
        app.getItemAsync(itemId)
            .done(function (externalItem) {
                let targetItem: IItemPut = {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    title: externalItem.title,
                };

                $.each(syncMapping, function (idx, mapping) {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    (<IGenericMap>targetItem)[mapping.toId] = (<IGenericMap>externalItem)[mapping.fromId];
                });

                // create matrix target item
                app.createItemOfTypeAsync(targetCategory, targetItem, "batch sync", targetFolder)
                    .done(function (result) {
                        // update external item with sync info
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        let changes: IItemPut = { id: externalItem.id, onlyThoseFields: 1, onlyThoseLabels: 1 };
                        let fields = globalMatrix.ItemConfig.getFieldsOfType("syncStatus", externalCategory);
                        if (fields.length == 1) {
                            let syncStatusFieldId = fields[0].field.id;
                            (<IGenericMap>changes)[syncStatusFieldId] = JSON.stringify({
                                targetSyncedVersion: 1,
                                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                thisSyncedVersion: externalItem.history.length + 1,
                                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                targetItemId: result.item.id,
                            });
                        }
                        // set status to sycned
                        changes.labels = "SYNCED,-NEW,-IGNORE";

                        // save the other (target item) if saving works, this (external) item will be saved with new settings
                        app.updateItemInDBAsync(changes, "batch sync")
                            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                            .done(function (newItem: IItemGet) {
                                // if there is a traceability rule from external item to target add link
                                if (
                                    globalMatrix.ItemConfig.getLinkTypes(externalCategory, true, false).indexOf(
                                        targetCategory,
                                    ) !== -1
                                ) {
                                    let exists = false;
                                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                    $.each(externalItem.downLinks, function (dlidx, dl) {
                                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                        if (dl.to === result.item.id) {
                                            exists = true;
                                        }
                                    });
                                    if (!exists) {
                                        // add link
                                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                        app.addDownLinkAsync(externalItem.id, result.item.id).always(function () {
                                            SyncStatusImpl.createNewRec(
                                                items,
                                                index,
                                                externalCategory,
                                                targetCategory,
                                                syncMapping,
                                            )
                                                .done(function () {
                                                    res.resolve();
                                                })
                                                .fail(function () {
                                                    res.reject();
                                                });
                                        });
                                    } else {
                                        SyncStatusImpl.createNewRec(
                                            items,
                                            index,
                                            externalCategory,
                                            targetCategory,
                                            syncMapping,
                                        )
                                            .done(function () {
                                                res.resolve();
                                            })
                                            .fail(function () {
                                                res.reject();
                                            });
                                    }
                                } else {
                                    SyncStatusImpl.createNewRec(
                                        items,
                                        index,
                                        externalCategory,
                                        targetCategory,
                                        syncMapping,
                                    )
                                        .done(function () {
                                            res.resolve();
                                        })
                                        .fail(function () {
                                            res.reject();
                                        });
                                }
                            })
                            .fail(function () {
                                res.reject();
                            });
                    })
                    .fail(function () {
                        res.reject();
                    });
            })
            .fail(function () {
                res.reject();
            });

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        return res;
    }

    private static reSyncItemsRec(
        items: string[],
        index: number,
        externalCategory: string,
        syncCatgoryInfo: ISyncCatgoryInfo,
    ): JQueryDeferred<{}> {
        let that = this;
        let res = $.Deferred();

        if (index >= items.length) {
            res.resolve();
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            return res;
        }

        ml.UI.Progress.Update((100 * index) / items.length);

        let itemId = items[index];
        index++;

        app.getItemAsync(itemId)
            .done(function (externalItem) {
                // get info and prepare update of external item

                let fields = globalMatrix.ItemConfig.getFieldsOfType("syncStatus", externalCategory);

                let syncStatusFieldId = fields[0].field.id;
                let syncInfo = <ISyncStatusValue>JSON.parse((<IGenericMap>externalItem)[syncStatusFieldId]);
                let targetItemId = syncInfo.targetItemId;
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                app.getItemAsync(targetItemId)
                    .done(function (targetItem) {
                        // prepare changing both items
                        let externalItemChanges: IItemPut = {
                            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                            id: externalItem.id,
                            onlyThoseFields: 1,
                            onlyThoseLabels: 1,
                        };
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        let targetItemChanges: IItemPut = { id: targetItem.id, onlyThoseFields: 1, onlyThoseLabels: 1 };
                        // update sync info from external item
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        syncInfo.thisSyncedVersion = externalItem.history.length + 1;
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        syncInfo.targetSyncedVersion = targetItem.history.length + 1;
                        (<IGenericMap>externalItemChanges)[syncStatusFieldId] = JSON.stringify(syncInfo);
                        externalItemChanges.labels = "SYNCED,-NEW,-IGNORE,-UNSYNC";
                        app.updateItemInDBAsync(externalItemChanges, "batch sync")
                            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                            .done(function (newItem: IItemGet) {
                                // update fields of targt item
                                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                let targetCategory = ml.Item.parseRef(targetItemId).type;
                                let syncMapping = SyncStatusImpl.getFieldIdMapping(
                                    externalCategory,
                                    targetCategory,
                                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                    syncCatgoryInfo.resync,
                                );
                                $.each(syncMapping, function (idx, mapping) {
                                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                    (<IGenericMap>targetItemChanges)[mapping.toId] = (<IGenericMap>externalItem)[
                                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                        mapping.fromId
                                    ];
                                });

                                app.updateItemInDBAsync(targetItemChanges, "batch sync")
                                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                    .done(function (newItem: IItemGet) {
                                        SyncStatusImpl.reSyncItemsRec(items, index, externalCategory, syncCatgoryInfo)
                                            .done(function () {
                                                res.resolve();
                                            })
                                            .fail(function () {
                                                res.reject();
                                            });
                                    })
                                    .fail(function () {
                                        res.reject();
                                    });
                            })
                            .fail(function () {
                                res.reject();
                            });
                    })
                    .fail(function () {
                        res.reject();
                    });
            })
            .fail(function () {
                res.reject();
            });

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        return res;
    }

    // automatically create field mappings between 1 and 1+ other categories based on fields names
    private static updateSyncDetails(externalCat: string, syncConfig: ISyncCatgoryInfo) {
        let that = this;
        if (!syncConfig) {
            return;
        }
        // if nothing is specified for new matchs, do it based on names
        if (!syncConfig.new) {
            syncConfig.new = [];
            $.each(syncConfig.categories.split(","), function (cidx, cat) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                that.matchSyncDetails(syncConfig.new, externalCat, cat);
            });
        }
        // if nothing is specified for resyncing matchs, do it based on names
        if (!syncConfig.resync) {
            syncConfig.resync = [];
            $.each(syncConfig.categories.split(","), function (cidx, cat) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                that.matchSyncDetails(syncConfig.resync, externalCat, cat);
            });
        }
    }

    private static getFieldIdMapping(externalCat: string, targetCat: string, syncMapping: ISyncMapping[]) {
        let actualMapping: ISyncMapping[] = [];
        $.each(syncMapping, function (idx, syncMapping) {
            let fromId = globalMatrix.ItemConfig.getFieldId(externalCat, syncMapping.from);
            let toId = globalMatrix.ItemConfig.getFieldId(targetCat, syncMapping.to);
            if (fromId && toId) {
                actualMapping.push({ from: syncMapping.from, to: syncMapping.to, fromId: fromId, toId: toId });
            }
        });
        return actualMapping;
    }

    // match fields between this external item and target item based on field names
    private static matchSyncDetails(matches: ISyncMapping[], externalCat: string, syncCat: string) {
        let that = this;
        $.each(globalMatrix.ItemConfig.getFields(externalCat), function (efidx, ef) {
            let efl = ef.label.toLowerCase();
            if (that.syncBlackList.indexOf(ef.fieldType) === -1) {
                $.each(globalMatrix.ItemConfig.getFields(syncCat), function (sfidx, sf) {
                    let sfl = sf.label.toLowerCase();
                    if (efl == sfl) {
                        let exists = false;
                        $.each(matches, function (midx, match) {
                            if (match.from == efl && match.to == sfl) {
                                exists = true;
                                return;
                            }
                        });
                        if (!exists) {
                            matches.push({ from: efl, to: sfl });
                        }
                    }
                });
            }
        });
    }

    // return sync status based on label
    private getSyncStatus(): string {
        const labels = this.settings.item?.labels || [];

        if (labels.indexOf("IGNORE") !== -1) return "IGNORE";
        if (labels.indexOf("SYNCED") !== -1) return "SYNCED";
        if (labels.indexOf("UNSYNC") !== -1) return "UNSYNC";

        return this.settings.fieldValue ? "UNSYNC" : "NEW";
    }

    // udpate sync status label
    private setSyncStatus(labels: string[], newStatus: string): void {
        if (labels.indexOf("IGNORE") !== -1) labels.splice(labels.indexOf("IGNORE"), 1);
        if (labels.indexOf("SYNCED") !== -1) labels.splice(labels.indexOf("SYNCED"), 1);
        if (labels.indexOf("UNSYNC") !== -1) labels.splice(labels.indexOf("UNSYNC"), 1);
        if (labels.indexOf("NEW") !== -1) labels.splice(labels.indexOf("NEW"), 1);

        labels.push(newStatus);
    }

    /** function called if item is not yet synced or ignored
     *
     */
    private offerLinking(ctrlContainer: JQuery, allowIgnore: boolean) {
        let that = this;

        let currentSelection = $("<span>");
        let selButton = $("<span>");

        let linkTypes: ILinkCategories[] = [];
        $.each(this.syncCatgoryInfo.categories.split(","), function (lti, lt) {
            linkTypes.push({ type: lt, name: globalMatrix.ItemConfig.getCategoryLabel(lt) });
        });

        let selectTools = new ItemSelectionTools();

        selectTools.renderButtons({
            selectMode: SelectMode.singleItem,
            control: selButton,
            linkTypes: linkTypes,
            smallbutton: false,
            crossProject: false,
            selectionChange: async function (newSelection: IReference[]) {
                currentSelection.html("sync with ");
                if (newSelection.length === 1) {
                    currentSelection.append(that.createItemLink(newSelection[0].to));
                }
                that.syncStatus.targetItemId = newSelection.length === 1 ? newSelection[0].to : "";
                if (that.settings.valueChanged) {
                    that.settings.valueChanged();
                }

                await app.saveAsync(false);
            },
            buttonName: "Select item to sync with",
            getSelectedItems: async function () {
                if (that.syncStatus.targetItemId) return [{ to: that.syncStatus.targetItemId, title: "" }];
                else return [];
            },
        });

        let createTools = new ItemCreationTools();
        createTools.renderButtons({
            control: selButton,
            linkTypes: linkTypes,
            singleCreate: true,
            dontOpenNewItem: false,
            created: async function (newRef: IReference) {
                currentSelection.html("sync with ").append(that.createItemLink(newRef.to));
                that.syncStatus.targetItemId = newRef.to;
                that.syncStatus.targetSyncedVersion = 1;
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                that.syncStatus.thisSyncedVersion = that.settings.item.history.length + 1;
                that.newCreated = true;

                if (that.settings.valueChanged) {
                    that.settings.valueChanged();
                }

                await that.saveAndLink(newRef);
            },
            open: function (view: ItemControl) {
                that.fillCreateDialog(view);
            },
        });

        if (allowIgnore) {
            $(
                '<button name="dontSync" class="btn btn-default sel_DontLink" style="color:white">Ignore - do not link</button>',
            )
                .appendTo(ctrlContainer)
                .click(function () {
                    that.syncStatus.targetItemId = "";
                    that.syncStatus.targetSyncedVersion = 0;
                    that.syncStatus.thisSyncedVersion = 0;

                    if (that.settings.valueChanged) that.settings.valueChanged();

                    app.saveAsync(false).done(function () {});
                });
        }
        ctrlContainer.append(currentSelection);
        ctrlContainer.append(selButton);

        $(".btn-default", ctrlContainer).removeClass("btn-default").addClass("btn-link sync-command");
        $(".btn-success", ctrlContainer).removeClass("btn-success").addClass("btn-link sync-command");
    }

    private showSyncInfo(ctrlContainer: JQuery) {
        let that = this;
        let syncInfo = $("<span>").appendTo(ctrlContainer);

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        app.getItemAsync(this.syncStatus.targetItemId).done(function (syncItem) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            if (that.syncStatus.targetSyncedVersion == syncItem.history.length) {
                syncInfo.html("Synced with current version of ");
            } else {
                syncInfo.html("Synced with previous version of ");
            }
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            syncInfo.append(that.createItemLink(that.syncStatus.targetItemId));
            $(".refId", syncInfo).css("color", "blue");
        });
    }

    private showLinkInfo(ctrlContainer: JQuery) {
        $("<span>Linked but not synced with </span>").appendTo(ctrlContainer);
        $("<span>'<span>").appendTo(ctrlContainer);
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        ctrlContainer.append(this.createItemLink(this.syncStatus.targetItemId));
        $(".refId", ctrlContainer).css("color", "blue");
        $("<span>'<span>").appendTo(ctrlContainer);
    }

    // this method is called when dialog to link to a new item is opened
    private fillCreateDialog(view: ItemControl) {
        let that = this;

        let decodeEntities = function (str: string) {
            // this prevents any overhead from creating the object each time
            let element = document.createElement("div");
            element.innerHTML = str;
            return element.textContent;
        };

        // TODO: fix properly
        window.setTimeout(() => {
            // delay this - otherwise is removed when input is focused...
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            view.getControlByName("Title").getController().setValue(decodeEntities(that.settings.item.title));

            if (this.syncCatgoryInfo && this.syncCatgoryInfo.new) {
                $.each(this.syncCatgoryInfo.new, function (idx, mapping) {
                    let controller = view.getControlByName(mapping.to)
                        ? view.getControlByName(mapping.to).getController()
                        : null;
                    if (controller && mapping.from) {
                        // find mapped field from imported item
                        $.each(
                            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                            globalMatrix.ItemConfig.getItemConfiguration(that.settings.type).fieldList,
                            function (fidx: number, flm: XRFieldTypeAnnotated) {
                                if (
                                    flm.label.toLowerCase() === mapping.from.toLowerCase() &&
                                    (<{ [fieldID: number]: string }>that.settings.item)[flm.id]
                                ) {
                                    controller.setValue((<{ [fieldID: number]: string }>that.settings.item)[flm.id]);
                                }
                            },
                        );
                    }
                });
            }
        }, 500);
    }

    private createItemLink(targetId: string) {
        return $("<span>").refLink({
            id: targetId,
            folder: false,
            title: app.getItemTitle(targetId),
            style: refLinkStyle.selectTree,
            tooltip: refLinkTooltip.html,
        });
    }

    private offerBreakLink(ctrlContainer: JQuery) {
        let that = this;
        $("<span class=''> (").appendTo(ctrlContainer);
        $("<span class='resetSync sync-command'>break link</span>")
            .appendTo(ctrlContainer)
            .click(function () {
                that.syncStatus.targetItemId = "";
                that.syncStatus.targetSyncedVersion = 0;
                that.syncStatus.thisSyncedVersion = 0;

                if (that.settings.valueChanged) that.settings.valueChanged();

                app.saveAsync(false).done(function () {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    $.each(that.settings.item.downLinks, function (dlidx, dl) {
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        app.removeDownLinkAsync(that.settings.item.id, dl.to).done(function () {
                            app.renderItem();
                        });
                    });
                });
            });
        $("<span class=''>). ").appendTo(ctrlContainer);
    }

    /** function called if item is linked - it can or cannot be in sync

    */
    private offerAutoSync(ctrlContainer: JQuery, initialSync: boolean) {
        let that = this;

        let buttonName = (initialSync ? "copy data to " : "re-copy data to ") + this.syncStatus.targetItemId;
        $('<button name="" class="btn btn-default sync_copyAllFields">' + buttonName + "</button>")
            .appendTo(ctrlContainer)
            .click(function () {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                app.getItemAsync(that.syncStatus.targetItemId)
                    .done(function (syncItem) {
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        that.autoSync(syncItem, false);
                    })
                    .fail(function () {
                        ml.UI.showError(
                            "Cannot Synchronize",
                            "Cannot retrieve linked item " + that.syncStatus.targetItemId,
                        );
                    });
            });
        $(".btn-default", ctrlContainer).removeClass("btn-default").addClass("btn-link sync-command");
    }

    private saveMergeInfo(targetFieldId: string, targetFieldValue: string) {
        // remove previous result
        this.mergeResults = this.mergeResults.filter(function (value, index) {
            return value.targetFieldId != targetFieldId;
        });

        // save new results
        this.mergeResults.push({
            targetFieldId: targetFieldId,
            targetFieldValue: targetFieldValue,
        });

        // update save button
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        this.settings.valueChanged();
    }

    // copy all matched fields from this item into target, change status and save
    private autoSync(syncItem: IItemGet, initialSync: boolean) {
        let that = this;

        let syncMapping = initialSync ? this.syncCatgoryInfo.new : this.syncCatgoryInfo.resync;

        // prepare sync data
        this.mergeResults = [];
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        for (let idx = 0; idx < syncMapping.length; idx++) {
            // gield id from field name
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            let fromId = globalMatrix.ItemConfig.getFieldId(this.settings.type, syncMapping[idx].from);
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            let toId = globalMatrix.ItemConfig.getFieldId(syncItem.type, syncMapping[idx].to);
            // make sure fields exists and put data into todo list
            if (fromId && toId) {
                this.mergeResults.push({
                    targetFieldId: "" + toId,
                    targetFieldValue: (<IGenericMap>this.settings.item)[fromId],
                });
            }
        }

        this.saveAndLink(syncItem);
    }

    private async saveAndLink(syncItem: IItemGet) {
        let that = this;

        try {
            await app.saveAsync(false);

            // if there is a traceability rule from external item to target add link
            if (
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                globalMatrix.ItemConfig.getLinkTypes(that.settings.item.type, true, false).indexOf(syncItem.type) !== -1
            ) {
                let exists = false;
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                $.each(that.settings.item.downLinks, function (dlidx, dl) {
                    if (dl.to === syncItem.id) {
                        exists = true;
                    }
                });
                if (!exists) {
                    // add link
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    app.addDownLinkAsync(that.settings.item.id, syncItem.id).always(function () {
                        // render again
                        app.renderItem();
                    });
                }
            }
        } catch (e) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            ml.UI.showError("Cannot Synchronize", "Cannot save the item " + that.settings.item.id);
        }
    }

    // manual merging

    // add sync buttons behind each syncable field
    private offerManualSync(initalSync: boolean) {
        let that = this;

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        app.getItemAsync(this.syncStatus.targetItemId).done(function (syncItem) {
            let syncMapping = that.syncCatgoryInfo.resync;
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            let targetCat = ml.Item.parseRef(that.syncStatus.targetItemId).type;

            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            let fieldMapping = SyncStatusImpl.getFieldIdMapping(that.settings.item.type, targetCat, syncMapping);
            // copy syncable fields to target
            for (let idx = 0; idx < fieldMapping.length; idx++) {
                let sc = matrixApplicationUI.lastMainItemForm.getControlByName(fieldMapping[idx].from);
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                let fromId = globalMatrix.ItemConfig.getFieldId(that.settings.type, fieldMapping[idx].from);
                let fromValue = (<IGenericMap>that.settings.item)[fromId];
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                let toId = globalMatrix.ItemConfig.getFieldId(syncItem.type, fieldMapping[idx].to);

                if (sc) {
                    let iconDone = $('<i class="fal fa-check iconDone">').hide();

                    let btnApply = $(
                        '<button name="" class="btn btn-sm sync_copyField btn-sync-field">copy field to ' +
                            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                            that.syncStatus.targetItemId.replace("-", "&dash;") +
                            "</button>",
                    )
                        .data("targetFieldId", toId)
                        .data("sourceValue", fromValue)
                        .click(function (event: JQueryMouseEventObject) {
                            iconDone.show();
                            let btn = $(event.delegateTarget);
                            that.saveMergeInfo(btn.data("targetFieldId"), btn.data("sourceValue"));
                        });

                    let btnMerge = $(
                        '<button name="" class="btn btn-sm sync_copyManual btn-sync-field">compare / merge manually</button>',
                    )
                        .data("targetField", fieldMapping[idx].from)
                        .data("sourceField", fieldMapping[idx].to)
                        .click(function (event: JQueryMouseEventObject) {
                            let btn = $(event.delegateTarget);
                            that.showSyncDialog(
                                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                syncItem,
                                iconDone,
                                btn.data("targetField"),
                                btn.data("sourceField"),
                                false,
                            );
                        });
                    let btnAdvancedMerge = $("<span>");
                    if (!initalSync) {
                        btnAdvancedMerge = $(
                            '<button name="" class="btn btn-sm sync_copyManualAdvanced btn-sync-field">advanced merge</button>',
                        )
                            .data("targetField", fieldMapping[idx].from)
                            .data("sourceField", fieldMapping[idx].to)
                            .click(function (event: JQueryMouseEventObject) {
                                let btn = $(event.delegateTarget);
                                that.showSyncDialog(
                                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                    syncItem,
                                    iconDone,
                                    btn.data("targetField"),
                                    btn.data("sourceField"),
                                    true,
                                );
                            });
                    }
                    $(".baseControlHelp", sc).append(iconDone);
                    $(".baseControlHelp", sc).append(btnApply);
                    $(".baseControlHelp", sc).append(btnMerge);
                    $(".baseControlHelp", sc).append(btnAdvancedMerge);
                }
            }
        });
    }
    /** render dialog to sync items */
    private async showSyncDialog(
        syncItem: IItemGet,
        syncedIcon: JQuery,
        targetField: string,
        sourceField: string,
        showPrevious: boolean,
    ) {
        let that = this;

        // prepare the up to 4 items/fields to be displayed
        let prevExternal = $("<div>");
        let prevTarget = $("<div>");
        if (showPrevious) {
            prevExternal
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                .data("itemId", that.settings.item.id)
                .data("version", that.syncStatus.thisSyncedVersion)
                .data("isTarget", false);
            prevTarget
                .data("itemId", that.syncStatus.targetItemId)
                .data("version", that.syncStatus.targetSyncedVersion)
                .data("isTarget", true);
            if (that.syncStatus.targetSyncedVersion) {
                prevTarget
                    .data(
                        "date",
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        syncItem.history[syncItem.history.length - that.syncStatus.targetSyncedVersion].dateUserFormat,
                    )
                    .data(
                        "author",
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        syncItem.history[syncItem.history.length - that.syncStatus.targetSyncedVersion].user,
                    );
            }
        }
        let curExternal = $("<div>").data("item", that.settings.item);
        let curTarget = $("<div>").data("item", syncItem);

        // prepare copy button
        let copyButton = $(
            '<button name="" class="btn btn-sm btn-sync-field">copy field to ' +
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                that.syncStatus.targetItemId.replace("-", "&dash;") +
                "</button>",
        );

        // build sync dialog
        let syncDialog = $('<table class="table mergeTable">');
        let tbody = $("<tbody>");

        syncDialog.append(tbody);
        tbody.append(
            $("<tr>")
                .append($('<td class="syncComparison _previous _external">').append(prevExternal))
                .append($('<td class="syncComparison _current _external">').append(curExternal)),
        );
        tbody.append($("<tr>").append($("<td>")).append($("<td>").append(copyButton)));
        tbody.append(
            $("<tr>")
                .append($('<td class="syncComparison _previous _target">').append(prevTarget))
                .append($('<td class="syncComparison _current _target">').append(curTarget)),
        );

        app.dlgForm.html("");
        app.dlgForm.removeClass("dlg-no-scroll");
        app.dlgForm.addClass("dlg-v-scroll");
        app.dlgForm.append(syncDialog);
        if (showPrevious) {
            // load data (after html nodes are attached to DOM) otherwise it looks bad since the padding is calculated
            that.lazyLoad(prevExternal, sourceField, "previously synced external");
            that.lazyLoad(
                prevTarget,
                targetField,
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                "previous synced " + that.syncStatus.targetItemId.replace("-", "&dash;"),
            );
        }
        // overwrite syncItems field with previously sync commands (e.g. if dialog is opened twice)
        $.each(this.mergeResults, function (idx, mergeResult) {
            (<IGenericMap>syncItem)[mergeResult.targetFieldId] = mergeResult.targetFieldValue;
        });

        this.target = new ItemControl(<any>{
            control: curTarget,
            controlState: ControlState.DialogEdit,
            item: syncItem,
            type: syncItem.type,
            isItem: true,
            parameter: { limitToA4: true },
            changed: function () {},
        });
        await this.target.load();

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        let targetversion = showPrevious ? " (version " + syncItem.history.length + ")" : "";
        let targetFieldId = this.hideNonSyncFields(
            this.target,
            targetField,
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            that.syncStatus.targetItemId.replace("-", "&dash;") + targetversion,
        );

        this.source = new ItemControl(<any>{
            control: curExternal,
            controlState: ControlState.HistoryView,
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            isHistory: that.settings.item.history.length,
            item: that.settings.item,
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            type: that.settings.item.type,
            isItem: true,
            parameter: { limitToA4: true },
            changed: function () {},
        });
        await this.source.load();
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        let thisVersion = showPrevious ? " (version " + that.settings.item.history.length + ")" : "";
        let externalFieldId = this.hideNonSyncFields(this.source, sourceField, "external item" + thisVersion);

        copyButton.click(async function (event: JQueryMouseEventObject) {
            that.target.setFieldValue(targetFieldId, await that.source.getFieldValue(externalFieldId));
        });

        // show dialog
        let maxWidth = $(document).width() * 0.9;
        app.dlgForm
            .dialog({
                autoOpen: true,
                title: "Sync Field",
                height: app.itemForm.height() * 0.9,
                width: showPrevious ? maxWidth : 800,
                modal: true,
                close: function () {},
                open: function () {},
                resizeStop: function (event, ui) {
                    app.dlgForm.resizeDlgContent([]);
                },
                buttons: [
                    {
                        text: "Ok",
                        class: "btnDoIt",
                        click: async function () {
                            syncedIcon.show();
                            that.saveMergeInfo("" + targetFieldId, await that.target.getFieldValue(targetFieldId));
                            app.dlgForm.dialog("close");
                        },
                    },
                    {
                        text: "Cancel",
                        class: "btnCancelIt",
                        click: function () {
                            app.dlgForm.dialog("close");
                        },
                    },
                ],
            })
            .resizeDlgContent([], false);
    }
    /** render info that the item behind exists and can be loaded (or doesn't exist...) */
    private lazyLoad(loader: JQuery, fieldName: string, fieldCaption: string) {
        let that = this;
        let prevVersion = loader.data("version");
        let prevItem = loader.data("itemId");

        app.getItemAsync(prevItem, prevVersion).done(async function (prev) {
            loader.html("");
            let ctrl = new ItemControl(<any>{
                control: loader,
                controlState: ControlState.HistoryView,
                isHistory: prevVersion,
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                type: prev.type,
                item: prev,
                isItem: true,
                parameter: { limitToA4: true },
                changed: function () {},
            });
            await ctrl.load();
            that.hideNonSyncFields(ctrl, fieldName, fieldCaption + " (version " + prevVersion + ")");
        });
    }

    /** hide input fields which cannot be synced (no setValue method) */
    private hideNonSyncFields(itemCtrl: ItemControl, fieldName: string, fieldCaption: string): number {
        let fieldId = 0;
        $.each(itemCtrl.controls, function (ic, c) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            if (c.name.toLowerCase() != fieldName.toLowerCase()) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                c.control.hide();
            } else {
                $(".baseControlHelp", c.control).html(fieldCaption);
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                fieldId = c.fieldId;
            }
        });
        return fieldId;
    }
}

// implement a plugin to catch save event before the save to be able to update the target item first
class syncStatusSaveHook implements IPlugin {
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private syncControl: SyncStatusImpl;
    public isDefault = true;

    constructor() {}

    // **********************
    // plugin interface
    // **********************
    preSaveHookAsync(isItem: boolean, type: string, controls: IControlDefinition[]): Promise<{}> {
        if (!this.syncControl) {
            return Promise.resolve({});
        } else {
            return this.syncControl.preSaveHook(isItem, type, controls);
        }
    }
    // **********************
    // custom interface
    // **********************
    initSyncControl(syncControl: SyncStatusImpl) {
        this.syncControl = syncControl;
    }
    destroySyncControl() {
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        this.syncControl = null;
    }
}

let mSyncStatusHook = new syncStatusSaveHook();

export function initialize() {
    plugins.register(mSyncStatusHook);
}
