import { XRGetTodosAck, XRTodo, XRTodoCount } from "../../RestResult";
import { NotificationsBL } from "./NotificationsBL";

export interface INotificationsChanges {
    total: number;
    allNotifications: XRGetTodosAck;
}
export class NotificationsCache {
    private myTodoCount: XRTodoCount[] = [];
    private myTodos: XRTodo[] = [];
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private isEnabled: boolean;

    setNotificationCounts(todos: XRTodoCount[]) {
        this.myTodoCount = todos;
    }

    getNotificationCounts() {
        return this.myTodoCount;
    }

    getNotifications(): XRTodo[] {
        return this.myTodos;
    }

    setNotifications(todos: XRTodo[]) {
        this.myTodos = todos;
    }

    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private notificationUpdateTimer: number;

    /**
     * Update the notifications cache  for the provided list of projects
     * @param projects
     */
    async update(projects: string[]): Promise<INotificationsChanges> {
        return new Promise((resolve, reject) => {
            if (!this.isEnabled) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                resolve({ total: 0, allNotifications: undefined });
            }
            clearTimeout(this.notificationUpdateTimer);

            this.notificationUpdateTimer = window.setTimeout(
                () => {
                    this.getAllNotificationsAndUpdateCache(projects).then((changes) => {
                        // in case there's a couple of notifications created in a short time
                        resolve(changes);
                    });
                },
                300 + Math.random() * 700,
            ); // wait up to 1 second before updating UI -> in case user has many tabs open
        });
    }

    setEnabled(isEnabled: boolean) {
        this.isEnabled = isEnabled;
    }

    /**
     * Return the total number of notifications for a specific project from the cache
     * @param project
     */
    getTotalNotificationsProject(project: string) {
        let list = this.getNotificationCounts().filter(function (notificationCount) {
            return notificationCount.projectShort == project;
        });
        return list.length ? list[0] : null;
    }
    /**
     * Return the total number of notifications from the cache
     */
    getTotalNotifications() {
        let sum = 0;
        $.each(this.getNotificationCounts(), function (cidx, notificationCount) {
            sum += notificationCount.nbTodos;
        });
        return sum;
    }

    /**
     * Return the notifications for a specific project and item from the cache
     * @param project
     * @param item
     */
    getProjectNotifications(project: string, item: string) {
        return this.getNotifications().filter(function (notification) {
            return (!project || notification.projectShort == project) && (!item || item == notification.itemRef);
        });
    }

    private async getAllNotificationsAndUpdateCache(projects: string[]): Promise<INotificationsChanges> {
        let that = this;

        let allNotifications = await NotificationsBL.getAllNotifications();
        let notificationCounts: XRTodoCount[] = [];

        // create object with counts

        notificationCounts = allNotifications.todoCounts.filter(function (notificationCount) {
            return projects.indexOf(notificationCount.projectShort) != -1;
        });

        let total = 0;
        for (let tc of notificationCounts) {
            total += tc.nbTodos;
        }

        // show a icon if the number increased

        // store them in session
        NotificationsBL.NoticationCache.setNotificationCounts(notificationCounts);
        NotificationsBL.NoticationCache.setNotifications(allNotifications.todos);
        return { total: total, allNotifications: allNotifications };
    }
}
