// main panel for project and category groups

import { app, ControlState, globalMatrix, matrixSession } from "../../../globals";
import { IEmbeddedReport } from "../../../ProjectSettings";
import { IDB } from "../../businesslogic/index";
import { ml } from "../../matrixlib";
import { IPanel } from "../Application";
import { refLinkStyle, refLinkTooltip } from "../Parts/RefLinkDefines";

export { GroupPanel };

class GroupPanel implements IPanel {
    title = "Category Group";
    constructor(categoryGroup: string, title: string) {
        document.title = title ? title : this.title + " - " + matrixSession.getProject();
        app.itemForm.prepend(ml.UI.getPageTitle(title ? title : this.title));
        // enable project filters by overlay
        ml.UI.toggleFilters(true);
        // get a specific help page (url) if set

        let gt = categoryGroup.substr(1);
        let helpPage: string;
        let groups = globalMatrix.ItemConfig.getCategoryGroupConfig();
        if (groups) {
            $.each(groups.groups, function (gidx, group) {
                if (group.text === gt && group.helpPage) {
                    helpPage = group.helpPage;
                }
            });
        }
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        this.createProjectControl(app.itemForm, helpPage);
    }

    destroy() {}

    private createProjectControl(control: JQuery, helpPage: string) {
        if (helpPage) {
            const iframe = $(
                '<iframe style="width: 100%;height: 95%;border: none; overflow: hidden;" src="' + helpPage + '">',
            );
            iframe.mouseenter(function (e) {
                // MATRIX-1202 The UI separator between the tree and the right side is weird on F-PROJECT
                $(document).off("mousemove");
            });

            control.append(iframe);
            return;
        }
        let report = globalMatrix.ItemConfig.getSetting("dashboard_report");
        if (report && report !== "") {
            let rjs = ml.JSON.fromString(report);
            if (rjs.status === "ok") {
                report = (<IEmbeddedReport>rjs.value).reportId;
            }
            let scroll_content = $("<div class='panel-body-v-scroll'></div>");
            control.append(scroll_content);
            globalMatrix.EmbeddedReport({
                control: scroll_content,
                fieldValue: report,
                controlState: ControlState.Report,
            });
        } else {
            let helpPage = globalMatrix.ItemConfig.getSetting("help_page");
            if (!helpPage) {
                helpPage = "https://urlshort.matrixreq.com/d24/manual/main";
            }
            let variables = {
                project: matrixSession.getProject(),
                user: matrixSession.getUser(),
                server: globalMatrix.matrixBaseUrl,
                version: app.getVersion(),
                product: globalMatrix.matrixProduct,
            };
            let param = jQuery.param(variables, true);
            helpPage = helpPage + "?" + param;
            const iframe = $(
                '<iframe style="width: 100%;height: 95%;border: none; overflow: hidden;" src="' + helpPage + '">',
            );
            iframe.mouseenter(function (e) {
                // MATRIX-1202 The UI separator between the tree and the right side is weird on F-PROJECT
                $(document).off("mousemove");
            });
            control.append(iframe);
        }
    }

    private addChildren(children: IDB | IDB[], docList: JQuery) {
        let that = this;
        let count = 0;
        $.each(children, function (cidx: number, child: IDB) {
            if (child.isUnselected == 0) {
                if (typeof child.children !== "undefined") {
                    count += that.addChildren(child.children, docList);
                } else {
                    count++;
                    let docRef = $("<div>").refLink({
                        id: child.id,
                        folder: false,
                        title: child.title,
                        style: refLinkStyle.selectTree,
                        tooltip: refLinkTooltip.html,
                    });
                    docList.append($("<li>").append(docRef));
                }
            }
        });
        return count;
    }
}
