import {
    IDHFSectionOptions,
    IDHFSection,
    IDHFControlDefinition,
    DocumentSectionType,
    mDHF,
} from "../../../common/businesslogic/index";
import { ml } from "../../../common/matrixlib";
import { IBaseControlOptions } from "../../../common/UI/Controls/BaseControl";
import {
    DateDocHandler,
    IDHFDueDateConfig,
    IDueDateOptions,
} from "../../../common/businesslogic/FieldHandlers/Document/DateDocHandler";
import { IDocFieldHandler } from "../../../common/businesslogic/FieldHandlers/Document/IDocFieldHandler";
import { BaseDHFSection } from "./BaseDHFSection";
export { DueDate };

class DueDate extends BaseDHFSection<IDueDateOptions> implements IDHFSection {
    renderControl(ctrl: IDHFControlDefinition, ctrlParameter: IBaseControlOptions) {
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        ctrl.control.dateselect(ml.JSON.mergeOptions(ctrlParameter, { parameter: { allowClear: true } }));
    }

    showSpecificSettings(ctrl: IDHFControlDefinition, ctrlParameter: IBaseControlOptions, custom: JQuery) {}
    async saveSpecificSettingsAsync(ctrl: IDHFControlDefinition, ctrlParameter: IBaseControlOptions, custom: JQuery) {
        return false;
    }
    async verifyContentAsync(ctrl: IDHFControlDefinition) {
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        let lastValue = await ctrl.control.getController().getValueAsync();
        if (!lastValue) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            ctrl.control.closest(".ft_dhf").find(".baseControlHelp").addClass("contentNeeded");
        } else {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            ctrl.control.closest(".ft_dhf").find(".baseControlHelp").removeClass("contentNeeded");
        }
    }
}
