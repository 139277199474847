import { IDHFControlDefinition } from "../../../common/businesslogic";
import { IBaseControlOptions } from "../../../common/UI/Controls/BaseControl";
import { IDocFieldHandler } from "../../../common/businesslogic/FieldHandlers/Document/IDocFieldHandler";

export class BaseDHFSection<T> {
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    fieldHandler: IDocFieldHandler;

    getFieldHandler(): IDocFieldHandler {
        return this.fieldHandler;
    }

    renderControl(ctrl: IDHFControlDefinition, ctrlParameter: IBaseControlOptions) {
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        ctrl.control.checkBox(ctrlParameter);
    }

    setFieldHandler(fieldHandler: IDocFieldHandler): void {
        this.fieldHandler = fieldHandler;
    }

    getConfig(ctrl: IDHFControlDefinition): T {
        let controllerConfig = this.fieldHandler.dhfFieldConfig;
        if (!controllerConfig) {
            controllerConfig = this.fieldHandler.getDefaultConfig();
        }
        return <T>controllerConfig;
    }
}
