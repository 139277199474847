import { FieldDescriptions } from "../../common/businesslogic/FieldDescriptions";
import { IDB, IDBParent, IDHFPasteBuffer, IDHFPasteBufferValue } from "../../common/businesslogic/index";
import { IPlugin, IProjectPageParam, IPluginPanelOptions, plugins } from "../../common/businesslogic/index";
import { mDHF } from "../../common/businesslogic/index";
import { RestDB } from "../../common/businesslogic/index";
import { ml } from "../../common/matrixlib";
import { HTMLCleaner } from "../../common/matrixlib/index";
import { UIToolsConstants } from "../../common/matrixlib/MatrixLibInterfaces";
import { ItemControl } from "../../common/UI/Components";
import { HistoryTools } from "../../common/UI/Tools/ItemHistoryView";
import {
    IItem,
    app,
    globalMatrix,
    restConnection,
    IBooleanMap,
    ControlState,
    matrixSession,
    IGenericMap,
    IItemPut,
} from "../../globals";

import { XCPostCompareHtml } from "../../RestCalls";
import {
    XRPostProject_LaunchReport_CreateReportJobAck,
    XRPostProject_CompareHtml_HtmlCompareResult,
    XRItemSimpleType,
    XRGetProject_ItemList_GetItemListAck,
} from "../../RestResult";

export type { ISection, ISectionPair };
export { Redlining };
export { initialize };

interface ISection {
    html: JQuery;
    title: string;
    type: string;
}

interface ISectionPair {
    left: ISection | null;
    right: ISection | null;
    dynamic: boolean; // whether it renders items
}

interface IDiffDocumentConfig {
    doc: string;
    diffSection: string;
    addedSection: string;
    removedSection: string;
    sameSection: string;
    targetFolder: string;
}
interface IDiffDocumentContent {
    diffText: string;
    addedText: string;
    removedText: string;
    sameText: string;
    diffSection: IDHFPasteBufferValue;
    addedSection: IDHFPasteBufferValue;
    removedSection: IDHFPasteBufferValue;
    sameSection: IDHFPasteBufferValue;
}
class Redlining implements IPlugin {
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private _item: IItem;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private _jui: JQuery;
    public isDefault = true;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private panel: JQuery;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private selectedOnly: string[] = null;
    private documentCompareCanceled = false;

    initItem(item: IItem, jui: JQuery) {
        this._item = item;
        this._jui = jui;
    }

    initServerSettings() {}
    initProject() {}
    supportsControl() {
        return false;
    }

    updateMenu(ul: JQuery) {
        let that = this;
    }

    getProjectPagesAsync(): Promise<IProjectPageParam[]> {
        return new Promise((resolve, reject) => {
            let that = this;
            let pages: IProjectPageParam[] = [];
            pages.push({
                id: "REDLINE",
                title: "Redlining",
                folder: "AUDIT",
                order: 3000,
                icon: "fal fa-stream",
                usesFilters: false,
                render: (options: IPluginPanelOptions) => that.renderProjectPage(options),
            });
            resolve(pages);
        });
    }

    compareDocumentsWord(source: string, target: string): Promise<XRPostProject_LaunchReport_CreateReportJobAck> {
        return app.startCompareDocumentsWord(source, target);
    }

    compareDocuments(report: JQuery, leftId: string, rightId: string) {
        let that = this;

        this.documentCompareCanceled = false;

        app.getItemAsync(leftId).done((leftItem) => {
            if (that.documentCompareCanceled) return;
            app.getItemAsync(rightId).done((rightItem) => {
                if (that.documentCompareCanceled) return;
                let leftDate =
                    ml.Item.parseRef(leftId).type == "DOC"
                        ? new Date().toISOString()
                        : // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                          // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                          // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                          // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                          leftItem.history[leftItem.history.length - 1].date;
                let rightDate =
                    ml.Item.parseRef(rightId).type == "DOC"
                        ? new Date().toISOString()
                        : // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                          // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                          // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                          // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                          rightItem.history[rightItem.history.length - 1].date;
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                let leftFilter = that.getFilterOfDoc(leftId, leftItem);
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                let rightFilter = that.getFilterOfDoc(rightId, rightItem);

                that.compareDocumentsDetail(report, leftId, rightId, leftDate, rightDate, leftFilter, rightFilter);
            });
        });
    }

    cancelCompare() {
        this.documentCompareCanceled = true;
    }

    compareDocumentsDetail(
        report: JQuery,
        leftId: string,
        rightId: string,
        leftCreationDate: string,
        rightCreationDate: string,
        leftFilter: string,
        rightFilter: string,
    ) {
        let that = this;

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        this.selectedOnly = null;

        report.html("");

        let containerDetailsSections = $("<div>").appendTo(report);
        let containerDetailsItems = $("<div>").appendTo(report);

        let step1 = ml.UI.getSpinningWait("Retrieving documents - please wait");
        containerDetailsSections.append(step1);

        let leftParams: { format: string; filter?: string } = { format: "html" };
        if (leftFilter) leftParams["filter"] = leftFilter;
        let rightParams = { format: "html" };
        if (rightFilter) leftParams["filter"] = rightFilter;

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        app.startCreateDocumentAsync(leftId, leftParams).done(function (
            result: XRPostProject_LaunchReport_CreateReportJobAck,
        ) {
            if (that.documentCompareCanceled) return;
            mDHF.loadDocument(result.jobId, function (leftHTML: any) {
                if (that.documentCompareCanceled) return;

                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                app.startCreateDocumentAsync(rightId, rightParams).done(function (
                    result: XRPostProject_LaunchReport_CreateReportJobAck,
                ) {
                    if (that.documentCompareCanceled) return;

                    mDHF.loadDocument(result.jobId, function (rightHTML: any) {
                        if (that.documentCompareCanceled) return;

                        if (new Date(leftCreationDate) < new Date(rightCreationDate)) {
                            that.showDetailedSectionChanges(
                                containerDetailsSections,
                                leftId,
                                rightId,
                                leftHTML,
                                rightHTML,
                            );
                            that.showDetailedItemChanges(
                                containerDetailsItems,
                                leftId,
                                rightId,
                                leftCreationDate,
                                rightCreationDate,
                                leftHTML,
                                rightHTML,
                            );
                        } else {
                            that.showDetailedSectionChanges(
                                containerDetailsSections,
                                rightId,
                                leftId,
                                rightHTML,
                                leftHTML,
                            );
                            that.showDetailedItemChanges(
                                containerDetailsItems,
                                rightId,
                                leftId,
                                rightCreationDate,
                                leftCreationDate,
                                rightHTML,
                                leftHTML,
                            );
                        }
                    });
                });
            });
        });
    }

    destroy() {}

    private getFilterOfDoc(id: string, item: IItem) {
        if (ml.Item.parseRef(id).type != "DOC") {
            return ""; // only interested in filter of DOC items
        }

        let filterFieldIds = globalMatrix.ItemConfig.getFieldsOfType("docFilter", "DOC");
        if (filterFieldIds.length == 0) {
            return ""; // no filter field
        }

        // get filter id
        let filterFieldId = filterFieldIds[0].field.id;

        // get filter from item
        let filter = item[filterFieldId];
        if (!filter) {
            return "";
        }
        return JSON.parse(filter).join(",");
    }
    // shows pure text changes for each section
    private showDetailedSectionChanges(
        container: JQuery,
        leftId: string,
        rightId: string,
        leftHTML: any,
        rightHTML: any,
    ) {
        let that = this;

        container.html("<h1>Document sections</h1>");

        let dynamicSections = mDHF.getSections(true);
        let staticSections = mDHF.getSections(false);
        let allSections = dynamicSections.concat(staticSections);

        // get all the right sections as 'baseline'
        let left23: boolean = false;
        let right23: boolean = false;

        let pairs: ISectionPair[] = [];
        $(".subchapter,.subsubchapter,.checksection", $(rightHTML)).each((idx, section) => {
            let type = that.getTypeFromClass($(section).attr("class"), allSections);
            if (type) right23 = true;
            pairs.push({
                right: {
                    html: that.getHtmlFromSection($(section)),
                    title: that.getTitleFromSection($(section)),
                    type: type,
                },
                left: null,
                dynamic: dynamicSections.indexOf(type) != -1,
            });
        });
        // add the left sections to the matching right or at end
        // get all the left sections
        $(".subchapter,.subsubchapter,.checksection", $(leftHTML)).each((idx, section) => {
            let type = that.getTypeFromClass($(section).attr("class"), allSections);
            if (type) left23 = true;
        });

        let only23 = left23 && right23;

        $(".subchapter,.subsubchapter,.checksection", $(leftHTML)).each((idx, section) => {
            let type = that.getTypeFromClass($(section).attr("class"), allSections);
            if (type) left23 = true;
            let left: ISection = {
                html: that.getHtmlFromSection($(section)),
                title: that.getTitleFromSection($(section)),
                type: type,
            };
            let added = false;
            for (let pair of pairs) {
                // if both are 2.3 generated documents, go by section type and title - if not only title
                if (
                    !added &&
                    !pair.left &&
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    pair.right.title == left.title &&
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    (!only23 || pair.right.type == left.type)
                ) {
                    pair.left = left;
                    added = true;
                }
            }
            if (!added) {
                // no match
                pairs.push({ left: left, right: null, dynamic: dynamicSections.indexOf(type) != -1 });
            }
        });
        // now the pairs is a list of matching section pairs

        let table = $(`<table class="table table-bordered">
            <thead><tr><th style='width:50%'>${leftId}!</th><th style='width:50%'>${rightId}!</th></tr></thead>
            <tbody></tbody>
        </table>`)
            .appendTo(container)
            .highlightReferences();

        for (let pair of pairs) {
            let sectionItems = `${pair.dynamic ? " - see item differences below" : ""}`;

            if (pair.left && pair.right) {
                if (pair.left.html.html() == pair.right.html.html()) {
                    $(
                        `<tr><td colspan=2><b>${pair.left.title}</b> did not change${sectionItems} <span class="viewHtml">(view rendered text)</span>.</td></tr>`,
                    )
                        .appendTo($("tbody", table))
                        .data("single", pair.left);
                } else {
                    $(
                        `<tr><td colspan=2><b>${pair.left.title}</b> has changed${sectionItems} <span class="compareHtml">(compare rendered text)</span>.</td></tr>`,
                    )
                        .appendTo($("tbody", table))
                        .data("pair", pair);
                }
            } else if (pair.left) {
                $(
                    `<tr><td><b>${pair.left.title}</b> does not exist in other${sectionItems} <span class="viewHtml">(view rendered text)</span>.</td><td>n/a</td></tr>`,
                )
                    .appendTo($("tbody", table))
                    .data("single", pair.left);
            } else {
                $(
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    `<tr><td>n/a</td><td><b>${pair.right.title}</b> does not exist in other${sectionItems} <span class="viewHtml">(view rendered text)</span>.</td></tr>`,
                )
                    .appendTo($("tbody", table))
                    .data("single", pair.right);
            }
        }

        $(".compareHtml").click((event) => {
            that.compareHtmlSection(event, leftId, rightId);
        });

        $(".viewHtml").click((event) => {
            that.viewHtmlSection(event);
        });
    }

    // goes from ".subchapter,.subsubchapter" and takes all until end or next  ".subchapter,.subsubchapter",
    private getHtmlFromSection(section: JQuery) {
        if (section.hasClass("checksection")) {
            return section.clone();
        }
        let div = $("<div>");
        let next = section.next();
        while (next && next.length) {
            if (next.hasClass("subchapter") || next.hasClass("subsubchapter") || next.hasClass("checksection")) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                next = null;
            } else {
                let after = next.next();
                if (next[0].nodeName != "HR") {
                    // there are some HRs in the html rendering between sections to make it pretty
                    div.append(next.clone());
                }
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                next = after.length ? after : null;
            }
        }
        return div;
    }
    private getTitleFromSection(section: JQuery) {
        if (section.hasClass("checksection")) {
            return $("b", section).text();
        } else {
            return section.text();
        }
    }
    // view a rendered html section
    private compareHtmlSection(event: JQueryEventObject, leftId: string, rightId: string) {
        let btn = $(event.delegateTarget);
        let pair = <ISectionPair>btn.closest("tr").data("pair");

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        let left = new HTMLCleaner(pair.left.html.html(), false).getClean(HTMLCleaner.CleanLevel.Strict, true);
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        let right = new HTMLCleaner(pair.right.html.html(), false).getClean(HTMLCleaner.CleanLevel.Strict, true);
        let param: XCPostCompareHtml = {
            arg: JSON.stringify({ versions: [left, right] }),
        };

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        app.compareHTML(param).done(function (result: XRPostProject_CompareHtml_HtmlCompareResult) {
            let dlg = $("<div>").appendTo($("body"));

            let content = $(`<table class="table table-bordered">
                <thead><tr></tr></thead>
                <tbody></tbody>
            </table>`);

            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            $("thead tr", content).append($("<th style='width:50%'>").append(ml.Item.renderLink(leftId, null, true)));
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            $("thead tr", content).append($("<th style='width:50%'>").append(ml.Item.renderLink(rightId, null, true)));

            $(`<tr><td>${result.html[0]}</td><td>${result.html[1]}</td></tr>`).appendTo($("tbody", content));

            ml.UI.showDialog(
                dlg,
                "Section Compare",
                content,
                $(document).width() * 0.85,
                app.itemForm.height() * 0.85,
                [
                    {
                        text: "Ok",
                        class: "btnDoIt",
                        click: function () {
                            dlg.dialog("close");
                        },
                    },
                ],
                UIToolsConstants.Scroll.Vertical,
                true,
                true,
                () => {
                    dlg.remove();
                },
                () => {},
                () => {},
            );
        });
    }

    // view a rendered html section
    private viewHtmlSection(event: JQueryEventObject) {
        let btn = $(event.delegateTarget);
        let single = <ISection>btn.closest("tr").data("single");
        let dlg = $("<div>").appendTo($("body"));
        let content = $("<div>").append($("<div class='redlineView'>").append(single.html));
        ml.UI.showDialog(
            dlg,
            "Section Content '" + single.title + "'",
            content,
            800,
            app.itemForm.height() * 0.9,
            [
                {
                    text: "Ok",
                    class: "btnDoIt",
                    click: function () {
                        dlg.dialog("close");
                    },
                },
            ],
            UIToolsConstants.Scroll.Vertical,
            true,
            true,
            () => {
                dlg.remove();
            },
            () => {},
            () => {},
        );
    }
    // returns a section type of a document section
    private getTypeFromClass(css: string, allSections: string[]) {
        // here I could remove other classes...
        if (!css) return "";
        let classes = css.split(" ");
        for (let cls of classes) {
            if (allSections.indexOf(cls) != -1) {
                return cls;
            }
        }
        // no known section
        return "";
    }

    // show a list of items side by side
    private showDetailedItemChanges(
        container: JQuery,
        leftId: string,
        rightId: string,
        leftCreationDate: string,
        rightCreationDate: string,
        leftHTML: any,
        rightHTML: any,
    ) {
        let that = this;

        let step3 = ml.UI.getSpinningWait("Retrieving all changed items");
        container.append(step3);
        restConnection
            .getProject("tree?fancy&atDate=" + new Date(leftCreationDate).toISOString())
            .done(function (leftTree) {
                if (that.documentCompareCanceled) return;

                restConnection
                    .getProject("tree?fancy&atDate=" + new Date(rightCreationDate).toISOString())
                    .done(function (rightTree) {
                        if (that.documentCompareCanceled) return;

                        // this are all the link ids in any of the documents + some which might be false positives
                        let leftLinks = that.extractLinks(leftHTML);
                        let rightLinks = that.extractLinks(rightHTML);

                        // let step3 = ml.UI.getSpinningWait( "1/4 Getting details " + rightId);
                        // report.append( step2 );
                        let leftVersions: XRItemSimpleType[] = [];
                        let rightVersions: XRItemSimpleType[] = [];

                        that.createItemsFromTree(leftTree as IDB[], leftVersions);
                        that.createItemsFromTree(rightTree as IDB[], rightVersions);

                        // filter the items from a certain time by those in the actual documents
                        leftVersions = leftVersions.filter((version) => leftLinks[version.ref]);
                        rightVersions = rightVersions.filter((version) => rightLinks[version.ref]);

                        let leftDisplay =
                            leftId + "! (" + ml.UI.DateTime.renderHumanDate(new Date(leftCreationDate), false) + ")";
                        let rightDisplay =
                            rightId + "! (" + ml.UI.DateTime.renderHumanDate(new Date(rightCreationDate), false) + ")";

                        container.html("<h1>Referenced items</h1>");

                        let warning = $("<div>").appendTo(container);
                        if (leftVersions.length == 0 && rightVersions.length == 0) {
                            warning.html("Neither document contains any items.");
                            return;
                        }

                        that.addFilters(container, leftId, rightId);

                        let diffTable = $("<div>").appendTo(container);

                        that.showDifferences(diffTable, leftVersions, rightVersions, leftDisplay, rightDisplay);

                        that.hideShow();
                    });
            });
    }

    // gets all the potential hyperlinks in a <a>XXX-YY</a>
    private extractLinks(html: string): IBooleanMap {
        let links: IBooleanMap = {};
        $.each($("a,.smartreplace", $(html)), (idx, item) => {
            let linkText = $(item).text();

            if (linkText) {
                let beforeSpace = linkText.split(" ")[0];
                if (!beforeSpace.match(/[^A-Z0-9-]/)) {
                    // potential link only letters, digits and -
                    links[beforeSpace] = true;
                }
            }
        });
        return links;
    }

    // retrieve a list of included items
    getIncludedItems(leftId: string, rightId: string) {
        let that = this;
        let res = $.Deferred();

        restConnection.getProject("itemlist/" + leftId).done(function (leftItems) {
            if (that.documentCompareCanceled) return;
            restConnection.getProject("itemlist/" + rightId).done(function (rightItems) {
                if (that.documentCompareCanceled) return;

                // the list might have some duplicates...
                that.selectedOnly = (rightItems as XRGetProject_ItemList_GetItemListAck).items
                    .map((item) => item.ref)
                    .concat((leftItems as XRGetProject_ItemList_GetItemListAck).items.map((item) => item.ref));
                res.resolve();
            });
        });
        return res;
    }

    // project pages show in the top in Projects, Reports and Documents
    private renderProjectPage(options: IPluginPanelOptions) {
        let that = this;

        if (options.controlState === ControlState.Print) {
            return;
        }

        document.title = "Redline - " + matrixSession.getProject();

        options.control.html("");

        options.control.prepend(ml.UI.getPageTitle("Change between two dates"));
        if (ml.UI.DateTime.requiresTimeZoneWarning()) {
            $(".toolbarButtons .buttonCTA").remove();
            $(".toolbarButtons", app.itemForm).append(ml.UI.DateTime.getTimeZoneCTA());
        }

        this.panel = $('<div class="panel-body-v-scroll fillHeight" style="padding: 0 12px;">').appendTo(
            options.control,
        );

        if (globalMatrix.ItemConfig.getTimeWarp()) {
            $(
                "<h2>Browse tree to find changes done after: " +
                    ml.UI.DateTime.renderCustomerHumanDate(new Date(globalMatrix.ItemConfig.getTimeWarp()), false) +
                    "</h2>",
            ).appendTo(this.panel);
            $("<div>The tree shows items in red if they changed after the above date</div>").appendTo(this.panel);
            $(
                "<div>History of items shows the last change before the date in red, versions after in grey.</div>",
            ).appendTo(this.panel);

            return;
        }
        let report = $("<div>");

        this.showDateSelection(report);
        this.panel.append(report);
    }

    private addFilters(report: JQuery, leftId?: string, rightId?: string) {
        let that = this;

        let filters = $("<div class='hideCopy'>").appendTo(report);
        $(
            '<div class="alignHorizontal" ><div class="checkbox" ><label><input type="checkbox" class="checkboxFilter" data-hide="unchanged" >show identical</label></div></div>',
        ).appendTo(filters);
        $(
            '<div class="alignHorizontal" ><div class="checkbox" ><label><input type="checkbox" class="checkboxFilter" data-hide="column1_not_included" checked >show added</label></div></div>',
        ).appendTo(filters);
        $(
            '<div class="alignHorizontal" ><div class="checkbox" ><label><input type="checkbox" class="checkboxFilter" data-hide="column2_not_included" checked >show removed</label></div></div>',
        ).appendTo(filters);
        $(
            '<div class="alignHorizontal" ><div class="checkbox" ><label><input type="checkbox" class="checkboxFilter" data-hide="changed" checked >show changed</label></div></div>',
        ).appendTo(filters);
        if (leftId && rightId) {
            $(
                '<div class="alignHorizontal" ><div class="checkbox" ><label><input disabled type="checkbox" class="selectedOnly">selected items only</label></div></div>',
            ).appendTo(filters);
            this.getIncludedItems(leftId, rightId).done(() => {
                ml.UI.setEnabled($(".selectedOnly"), true);
            });
        }
        $("input", report).change(function () {
            that.hideShow();
        });
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        ml.UI.copyBuffer(filters, "copy to clipboard", report, this.panel, null, "copy overview");

        this.addDiffDownload(filters);
    }

    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private diffCancelled: boolean;

    private addDiffDownload(filters: JQuery) {
        let that = this;

        let diffConfig = <IDiffDocumentConfig>globalMatrix.ItemConfig.getSettingJSON("downloadDiff");
        if (diffConfig && diffConfig.doc && app.getItemTitle(diffConfig.doc)) {
            $(
                '<i class="fal fa-file-download" aria-hidden="true" style="padding-left:12px;cursor:pointer" data-original-title="create DOC"> create DOC</i>',
            )
                .appendTo(filters)
                .click(() => {
                    let dlg = $("<div id='progressDlg' style='z-index:2001'>").appendTo("body");
                    let content = $("<div id='progressDetails'>");
                    ml.UI.showDialog(
                        dlg,
                        "Creating Redline Document",
                        content,
                        900,
                        600,
                        [
                            {
                                text: "Cancel",
                                class: "btnCancel btnCancelStop",
                                click: function () {
                                    dlg.dialog("close");
                                },
                            },
                        ],
                        UIToolsConstants.Scroll.Vertical,
                        false,
                        true,
                        () => {
                            that.diffCancelled = true;
                            dlg.remove();
                        },
                    );

                    that.diffCancelled = false;
                    // copy the template
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    mDHF.preparePasteBuffer(null);
                    mDHF.copyTemplate([diffConfig.doc], 0, true).done(async () => {
                        content.append(`<p>Prepared document</p>`);

                        // prepare the differencing
                        let diff = that.prepareDiffSections(diffConfig);

                        // build the data
                        await that.createDiffContent(content, diff);

                        if (that.diffCancelled) {
                            ml.UI.showSuccess("Cancelled Redlining Document");
                        } else {
                            // create the document
                            that.createDiffDoc(diffConfig, diff).done((result) => {
                                ml.UI.showSuccess("Created Redlining Document");
                                $(".btnCancelStop").html("OK");
                                content.append(
                                    `<p>Finalized document ${ml.Item.renderLink(
                                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                        result.item.id,
                                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                        result.item.title,
                                        true,
                                    ).html()}</p>`,
                                );
                            });
                        }
                    });
                })
                .tooltip();
        }
    }

    /** This method creates the content for the diff doc, going through all displayed lines in the table */
    private async createDiffContent(progressDlg: JQuery, diff: IDiffDocumentContent): Promise<IDiffDocumentContent> {
        let that = this;

        async function getHTMLForDoc(itemData: IItem, v: number) {
            let itemControl = $("<div>");
            // render it
            let itemC = new ItemControl({
                control: itemControl,
                controlState: ControlState.HistoryView,
                isHistory: v,
                item: itemData,
                isItem: !itemData.children,
                parameter: { manualTableHeights: true, reviewMode: true },
            });
            await itemC.load();
            // prepare the object to be compared later

            let diffBase = $("<div>");
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            let versionInfo = itemData.history[itemData.history.length - v];
            diffBase.append(
                `<div class="diffInfo"><div class="diffTitle"><span>${itemData.id}</span> <span>${versionInfo.title}</span></div><div class="diffVersionInfo">diffVersionInfodiffVersionInfo</div></div>`,
            );
            $.each(
                $(itemControl).children(".panel-body-v-scroll").children(".dialog-body").children(),
                (idx, field) => {
                    diffBase.append(field);
                },
            );

            // prepare for printing (remove replace inputs with texts etc)
            ml.SmartText.prepareForReadReadRender($(diffBase));

            // prepare for diffing (add markup)
            $("textarea,pre", diffBase).each(function (idx, select) {
                $(select).replaceWith(
                    "<div>" +
                        $(select)
                            .html()
                            .replace(/(?:\r\n|\r|\n)/g, "<br>") +
                        "</div>",
                );
            });
            $(".fal.fa-square", diffBase).each((idx, elem) => {
                $(elem).parent().addClass("history-unchecked");
                $(elem).replaceWith("<span>&#10004; </span>");
            });
            $(".fal.fa-check-square", diffBase).each((idx, elem) => {
                $(elem).parent().addClass("history-checked");
                $(elem).replaceWith("<span>&#x25a2; </span>");
            });

            // remove markup for rendering in UI
            $(".refIdHyper", diffBase).each((idx, rih) => {
                $(rih)
                    .parent()
                    .replaceWith($(rih).text() + " ");
            });
            $(".baseControlHelp", diffBase).removeClass("baseControlHelp");

            // get the code
            return diffBase[0].outerHTML.replace(/pull-left/g, "");
        }
        function getHeader(itemData: IItem, v: number) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            let versionInfo = itemData.history[itemData.history.length - v];
            return ` <div class="diffRevision"><span class="diffAttribute">Revision:</span> <span class="diffValue">${v}</span></div>
                     <div class="diffAuthor"><span class="diffAttribute">Author:</span> <span class="diffValue">${versionInfo.user}</span></div>
                     <div class="diffDate"><span class="diffAttribute">Date:</span> <span class="diffValue">${versionInfo.date}</span></div>
                     <div class="diffAction"><span class="diffAttribute">Action:</span> <span class="diffValue">${versionInfo.action}</span></div>
                     <div class="diffComment"><span class="diffAttribute">Comment:</span> <span class="diffValue">${versionInfo.comment}</span></div>`;
        }
        async function compareTwoRevisions(itemId: string, v0: number, v1: number) {
            let ht = new HistoryTools();
            let versions: string[] = [];
            let headers: string[] = [];

            for (let v of [v0, v1]) {
                let itemData = await app.getItemAsync(itemId, v);

                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                versions.push(await getHTMLForDoc(itemData, v));
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                headers.push(getHeader(itemData, v));
            }
            let splittedVersions = ht.splitVersions(versions);

            let compareParams: XCPostCompareHtml = {
                arg: JSON.stringify(splittedVersions ? { versionsMultiple: splittedVersions } : { versions: versions }),
            };
            // call compare
            let compareResults = await app.compareHTML(compareParams);

            if (splittedVersions) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                for (let assemble = 0; assemble < compareResults.htmlMultiple.length; assemble++) {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    let left = compareResults.htmlMultiple[assemble][0].replace(
                        "diffVersionInfodiffVersionInfo",
                        headers[0],
                    );
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    let right = compareResults.htmlMultiple[assemble][1].replace(
                        "diffVersionInfodiffVersionInfo",
                        headers[1],
                    );
                    if ($(left).text() || $(right).text()) {
                        diff.diffText += `<tr><td>${left}</td><td>${right}</td></tr>`;
                    }
                }
            } else {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                let left = compareResults.html[0].replace("diffVersionInfodiffVersionInfo", headers[0]);
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                let right = compareResults.html[1].replace("diffVersionInfodiffVersionInfo", headers[1]);
                if ($(left).text() || $(right).text()) {
                    diff.diffText += `<tr><td>${left}</td><td>${right}</td></tr>`;
                }
            }
        }

        async function showRevision(itemId: string, vVersion: string) {
            let v = Number(vVersion.replace("v", ""));
            let itemData = await app.getItemAsync(itemId, v);

            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            let item = await getHTMLForDoc(itemData, v);
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            let header = getHeader(itemData, v);

            return item.replace("diffVersionInfodiffVersionInfo", header);
        }

        async function renderRow(itemId: string, row: JQuery) {
            let compareResult = $($("td", row)[3]).text();

            if (compareResult == "compare versions" && diff.diffSection) {
                let sm = $("span", $("td", row)[3]);
                await compareTwoRevisions(itemId, $(sm).data("v0"), $(sm).data("v1"));
            } else if (compareResult == "added/included" && diff.addedSection) {
                diff.addedText += await showRevision(itemId, $($("td", row)[2]).text());
            } else if (compareResult == "deleted/excluded" && diff.removedSection) {
                diff.removedText += await showRevision(itemId, $($("td", row)[1]).text());
            } else if (compareResult == "no changes" && diff.sameSection) {
                diff.sameText += await showRevision(itemId, $($("td", row)[1]).text());
            }
        }

        let table = $(".diffTable");
        if (!table.length) {
            // for < 2.4
            table = $(".compareItem").closest("table");
        }
        let headers = $("thead > tr > th", table);

        diff.diffText = diff.diffSection
            ? `<table class="table table-bordered diffSection"><thead><tr><th>${$(headers[1]).html()}</th><th>${$(
                  headers[2],
              ).html()}</th></tr></thead><tbody>`
            : "";
        diff.addedText = diff.addedSection ? "<div class='addedSection'>" : "";
        diff.removedText = diff.removedSection ? "<div class='removedSection'>" : "";
        diff.sameText = diff.sameSection ? "<div class='sameSection'>" : "";

        // all visible rows
        let rows = table.children("tbody").children("tr:visible");
        let count = rows.length;

        // prepare a visible todo list showing all items in table
        let progress: JQuery[] = [];
        let ul = $(`<p>`).appendTo(progressDlg);
        for (let idx = 0; idx < count; idx++) {
            let row = rows[idx];
            let p = $(
                `<div><i class="fal fa-square"> ${$(row).data("ci")} ${app.getItemTitle($(row).data("ci"))}</div>`,
            ).appendTo(ul);
            progress.push(p);
        }

        // process one item after the other and show progress
        for (let idx = 0; idx < count && !that.diffCancelled; idx++) {
            let row = rows[idx];
            $(".versionpane").remove();
            await renderRow($(row).data("ci"), $(row));
            $("i", progress[idx]).removeClass("fa-square").addClass("fa-check");
        }

        if (that.diffCancelled) {
            diff.diffText = "";
            diff.addedText = "";
            diff.removedText = "";
            diff.sameText = "";
        } else {
            diff.diffText += diff.diffSection ? `</tbody></table>` : "";
            diff.addedText += diff.addedSection ? "</div>" : "";
            diff.removedText += diff.removedSection ? "</div>" : "";
            diff.sameText += diff.sameSection ? "</div>" : "";
        }

        return diff;
    }

    /** This method fills DOC based on the differences computed  */
    private createDiffDoc(diffConfig: IDiffDocumentConfig, diff: IDiffDocumentContent): JQueryDeferred<IDBParent> {
        let res: JQueryDeferred<IDBParent> = $.Deferred();

        let that = this;

        let folderId = diffConfig.targetFolder ? diffConfig.targetFolder : "F-DOC-1";
        let currentBufferString = localStorage.getItem(mDHF.COPY_PASTE_BUFFER);
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        let pasteBuffer = (<IDHFPasteBuffer>JSON.parse(currentBufferString)).items[0];

        let sourceref = 0;
        let srfs = globalMatrix.ItemConfig.getFieldsOfType("sourceref", "DOC");
        if (srfs.length) {
            sourceref = srfs.length ? srfs[0].field.id : 0;
        }

        // prepare the item to be created
        let itemJson: IItemPut = {};

        itemJson.title = pasteBuffer.title + " " + app.getCurrentItemId();
        if (sourceref) {
            (<IGenericMap>itemJson)[sourceref] = pasteBuffer.sourceProject + "/" + pasteBuffer.sourceItem;
        }
        let fields = globalMatrix.ItemConfig.getItemConfiguration("DOC").fieldList;

        $.each(fields, function (fidx, f) {
            for (let idx = 0; idx < pasteBuffer.item.length; idx++) {
                if (pasteBuffer.item[idx].def.label === f.label && sourceref != f.id) {
                    (<IGenericMap>itemJson)[f.id] = pasteBuffer.item[idx].val;
                }
            }
        });

        // fill in diff section: items which differ between the two timelines

        if (diff.diffSection) {
            let val = JSON.parse(diff.diffSection.val);
            val.fieldValue = diff.diffText;
            itemJson[diff.diffSection.def.id] = JSON.stringify(val);
        }

        // fill in added section: items which have been added
        if (diff.addedSection) {
            let val = JSON.parse(diff.addedSection.val);
            val.fieldValue = diff.addedText;
            itemJson[diff.addedSection.def.id] = JSON.stringify(val);
        }

        // fill in added section: items which have been removed
        if (diff.removedSection) {
            let val = JSON.parse(diff.removedSection.val);
            val.fieldValue = diff.removedText;
            itemJson[diff.removedSection.def.id] = JSON.stringify(val);
        }

        // fill in unchanged section: items which are identical
        if (diff.sameSection) {
            let val = JSON.parse(diff.sameSection.val);
            val.fieldValue = diff.sameText;
            itemJson[diff.sameSection.def.id] = JSON.stringify(val);
        }

        app.createItemOfTypeAsync("DOC", itemJson, "redlining", folderId)
            .done(function (result) {
                console.log(result);
                res.resolve(result);
            })
            .fail(function (error) {});

        return res;
    }

    /** This method figures out what needs to go into the redlining diff document, depending on the configuration and the actual template */
    private prepareDiffSections(diffConfig: IDiffDocumentConfig): IDiffDocumentContent {
        // to collect results
        let diff: IDiffDocumentContent = {
            diffText: "",
            addedText: "",
            removedText: "",
            sameText: "",
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            diffSection: null,
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            addedSection: null,
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            removedSection: null,
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            sameSection: null,
        };

        // get the info from the template document
        let currentBufferString = localStorage.getItem(mDHF.COPY_PASTE_BUFFER);
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        let pasteBuffer = (<IDHFPasteBuffer>JSON.parse(currentBufferString)).items[0];

        // if there's a section define in the config, check if the same section is in the template
        if (diffConfig.diffSection) {
            let diffTextFields = pasteBuffer.item.filter(
                (f) =>
                    f.def.fieldType == FieldDescriptions.Field_dhf &&
                    f.val &&
                    JSON.parse(f.val) &&
                    JSON.parse(f.val).name.toLowerCase() == diffConfig.diffSection.toLowerCase(),
            );
            if (diffTextFields.length == 1) {
                diff.diffSection = diffTextFields[0];
            } else {
                console.log(
                    `Warning diffSection="${diffConfig.diffSection}" property in config does not exist in template document`,
                );
            }
        }
        if (diffConfig.addedSection) {
            let addedTextFields = pasteBuffer.item.filter(
                (f) =>
                    f.def.fieldType == FieldDescriptions.Field_dhf &&
                    f.val &&
                    JSON.parse(f.val) &&
                    JSON.parse(f.val).name.toLowerCase() == diffConfig.addedSection.toLowerCase(),
            );
            if (addedTextFields.length == 1) {
                diff.addedSection = addedTextFields[0];
            } else {
                console.log(
                    `Warning addedSection="${diffConfig.addedSection}" property in config does not exist in template document`,
                );
            }
        }
        if (diffConfig.removedSection) {
            let removedTextFields = pasteBuffer.item.filter(
                (f) =>
                    f.def.fieldType == FieldDescriptions.Field_dhf &&
                    f.val &&
                    JSON.parse(f.val) &&
                    JSON.parse(f.val).name.toLowerCase() == diffConfig.removedSection.toLowerCase(),
            );
            if (removedTextFields.length == 1) {
                diff.removedSection = removedTextFields[0];
            } else {
                console.log(
                    `Warning removedSection="${diffConfig.removedSection}" property in config does not exist in template document`,
                );
            }
        }
        if (diffConfig.sameSection) {
            let sameTextFields = pasteBuffer.item.filter(
                (f) =>
                    f.def.fieldType == FieldDescriptions.Field_dhf &&
                    f.val &&
                    JSON.parse(f.val) &&
                    JSON.parse(f.val).name.toLowerCase() == diffConfig.sameSection.toLowerCase(),
            );
            if (sameTextFields.length == 1) {
                diff.sameSection = sameTextFields[0];
            } else {
                console.log(
                    `Warning sameSection="${diffConfig.sameSection}" property in config does not exist in template document`,
                );
            }
        }

        return diff;
    }

    private hideShow() {
        let that = this;
        $("tr", this.panel).show();
        // by default show all
        $(".compareItem").show().removeClass("hideCopy");

        $.each($(".checkboxFilter", this.panel), function (idx, input) {
            if ($(input).data("hide")) {
                let sh = $("." + $(input).data("hide"), that.panel);
                if ($(input).prop("checked")) {
                    sh.show();
                    sh.removeClass("hideCopy");
                } else {
                    sh.hide();
                    sh.addClass("hideCopy");
                }
            }
        });

        if ($(".selectedOnly").prop("checked")) {
            $(".compareItem").each((idx, ci) => {
                if (that.selectedOnly.indexOf($(ci).data("ci")) == -1) {
                    $(ci).hide().addClass("hideCopy");
                }
            });
        }
    }

    private showDateSelection(report: JQuery) {
        let that = this;

        let bc = $('<div class="baseControl controlContainer">').appendTo(this.panel);
        let p = $("<p>").appendTo(bc);
        $('<span class="">From </span>').appendTo(p);
        let fromDate = $("<input type='text' class='form-control redlineDates'>").appendTo(p);
        $('<span class=""> to </span>').appendTo(p);
        let toDate = $("<input type='text' class='form-control redlineDates'>").appendTo(p);
        let goButton = $(
            '<button style="margin-left: 12px" type="button" class="btn btn-success">Compare</button>',
        ).appendTo(p);
        let timeWarp = $("<span>").appendTo(p);

        fromDate.datetimepicker({ format: ml.UI.DateTime.getSimpleDateTimeFormatMoment() });
        toDate.datetimepicker({
            defaultDate: new Date(),
            useCurrent: false, //Important!
            format: ml.UI.DateTime.getSimpleDateTimeFormatMoment(),
        });
        ml.UI.setEnabled(goButton, fromDate.data("DateTimePicker").date() && toDate.data("DateTimePicker").date());
        timeWarp.hide();
        fromDate.on("dp.change", function (e: any) {
            toDate.data("DateTimePicker").minDate(e.date);
            ml.UI.setEnabled(goButton, fromDate.data("DateTimePicker").date() && toDate.data("DateTimePicker").date());
            if (fromDate.data("DateTimePicker").date()) {
                timeWarp.html(
                    "<a style='margin-left: 12px' type='button' class='showMore' >Time warp</a>: show project as it was at " +
                        ml.UI.DateTime.renderCustomerHumanDate(new Date(fromDate.data("DateTimePicker").date()), false),
                );
                timeWarp.show();
            } else {
                timeWarp.hide();
            }
        });
        toDate.on("dp.change", function (e: any) {
            fromDate.data("DateTimePicker").maxDate(e.date);
            ml.UI.setEnabled(goButton, fromDate.data("DateTimePicker").date() && toDate.data("DateTimePicker").date());
        });

        goButton.click(function () {
            that.createRedLineDates(
                report,
                fromDate.data("DateTimePicker").date(),
                toDate.data("DateTimePicker").date(),
            );
        });
        timeWarp.click(function () {
            let win = window.open(
                globalMatrix.matrixBaseUrl +
                    "/" +
                    matrixSession.getProject() +
                    "?atDate=" +
                    fromDate.data("DateTimePicker").date().toISOString(),
                "_blank",
            );
            if (win) {
                //Browser has allowed it to be opened
                win.focus();
            } else {
                //Browser has blocked it
                alert("Please allow popups for this site");
            }
        });
    }

    private createRedLineDates(report: JQuery, fromDate: Date, toDate: Date) {
        let that = this;
        report.html("");
        report.append(ml.UI.getSpinningWait("retrieving changes between the dates"));

        // get the tree at both dates
        restConnection.getProject("tree?fancy&atDate=" + fromDate.toISOString()).done(function (fromTreeResults) {
            restConnection.getProject("tree?fancy&atDate=" + toDate.toISOString()).done(function (toTreeResult) {
                const fromTree: IDB[] = RestDB.filterLegacyReportCat(fromTreeResults as IDB[]);
                const toTree: IDB[] = RestDB.filterLegacyReportCat(toTreeResult as IDB[]);
                report.html("");
                that.addFilters(report);

                let diffTable = $("<div>").appendTo(report);
                let fromItems: XRItemSimpleType[] = [];
                let toItems: XRItemSimpleType[] = [];

                that.createItemsFromTree(fromTree, fromItems);
                that.createItemsFromTree(toTree, toItems);

                that.showDifferences(
                    diffTable,
                    fromItems,
                    toItems,
                    ml.UI.DateTime.renderHumanDate(new Date(fromDate.toISOString()), false),
                    ml.UI.DateTime.renderHumanDate(new Date(toDate.toISOString()), false),
                );
                that.hideShow();
            });
        });
    }

    private createItemsFromTree(tree: IDB[], items: XRItemSimpleType[]) {
        let that = this;
        $.each(tree, function (idx, node) {
            if (node.children) {
                // this is a folder
                that.createItemsFromTree(node.children, items);
            } else {
                // this is a leaf
                items.push({
                    author: "",
                    birth: "",
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    ref: node.id,
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    title: node.title,
                    version: node.version ? Number(node.version.split("/")[0]) : 0,
                });
            }
        });
    }

    private showDifferences(
        diffTable: JQuery,
        fromItems: XRItemSimpleType[],
        toItems: XRItemSimpleType[],
        fromText: string,
        toText: string,
    ) {
        let fromIds = fromItems
            .map(function (item) {
                return item.ref;
            })
            .sort(function (a, b) {
                return ml.Item.sort(a, b);
            });
        let toIds = toItems
            .map(function (item) {
                return item.ref;
            })
            .sort(function (a, b) {
                return ml.Item.sort(a, b);
            });

        // create a sorted list of ids (every id only once)
        let fromToIdsAll = fromIds.concat(toIds);
        let fromToIds: string[] = [];
        $.each(fromToIdsAll, function (ftidx, ft) {
            if (fromToIds.indexOf(ft) == -1) fromToIds.push(ft);
        });
        fromToIds = fromToIds.sort(function (a, b) {
            return ml.Item.sort(a, b);
        });

        // build table header
        let table = $("<table class='table table-bordered'>").appendTo(diffTable);
        let head = $("<thead>").appendTo(table);
        let trh = $("<tr>").appendTo(head);
        $("<th>").html("Item").appendTo(trh);

        // build table body
        let tbody = $("<tbody>").appendTo(table);

        $("<th>").html(fromText).appendTo(trh);
        $("<th>").html(toText).appendTo(trh);
        this.addItems(tbody, fromToIds, fromItems, toItems);

        $("<th class='hideCopy'>").html("Changes").appendTo(trh);

        $(".showMore", table).click(function (event: JQueryEventObject) {
            let ht = new HistoryTools();
            let link = $(event.delegateTarget);
            ht.compareVersions(link.data("item"), link.data("v0"), link.data("v1"));
        });
        table.highlightReferences();
    }
    private addItems(tbody: JQuery, fromToRefs: string[], fromRefs: XRItemSimpleType[], toRefs: XRItemSimpleType[]) {
        let that = this;

        $.each(fromToRefs, function (ftIdx, ft) {
            // left column / first doc
            let fromDetails = fromRefs.filter(function (ref) {
                return ref.ref == ft;
            });
            // right column / second doc
            let toDetails = toRefs.filter(function (ref) {
                return ref.ref == ft;
            });

            // check which version is in which column (if included)
            let both = 0;
            let afterIdx = 0;
            let beforeIdx = 0;
            let existsInFirst = false;
            let tr = $(`<tr class="compareItem" data-ci="${ft}">`).appendTo(tbody);
            let itemColumn = $("<td>").appendTo(tr);
            itemColumn.append($("<span class='hideCopy'>").html(ft + "!"));
            itemColumn.append($("<span class='replaceCopy'>").data("with", ft + "!"));

            if (fromDetails.length != 1) {
                $("<td>").html("not included").appendTo(tr);
                tr.addClass("column1_not_included");
            } else {
                existsInFirst = true;
                both++;
                beforeIdx = fromDetails[0].version;
                $("<td>")
                    .html("v" + beforeIdx)
                    .appendTo(tr);
            }

            if (toDetails.length != 1) {
                $("<td>").html("not included").appendTo(tr);
                tr.addClass("column2_not_included");
            } else {
                both++;
                afterIdx = toDetails[0].version;
                $("<td>")
                    .html("v" + afterIdx)
                    .appendTo(tr);
            }
            // add changes column
            let td = $("<td class='hideCopy'>").appendTo(tr);
            if (both != 2) {
                // not included in both docs - no need to show changes between
                if (existsInFirst) {
                    td.html("deleted/excluded");
                } else {
                    td.html("added/included");
                }
            } else if (afterIdx != beforeIdx) {
                let compare = $(
                    "<span class='showMore' data-item='" +
                        ft +
                        "' data-v0=" +
                        beforeIdx +
                        " data-v1=" +
                        afterIdx +
                        ">compare versions</span>",
                );
                tr.addClass("changed");
                td.append(compare);
            } else {
                td.html("no changes");
                tr.addClass("unchanged");
            }
        });
    }
}

function initialize() {
    plugins.register(new Redlining());
}
