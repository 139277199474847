import { IDHFSectionOptions } from "../../PluginManagerDocuments";
import { DropdownAbstractDocFieldHandler } from "./DropdownAbstractDocFieldHandler";

export interface IRemarkOptions extends IDHFSectionOptions {}

export class RemarksDocFieldHandler extends DropdownAbstractDocFieldHandler {
    getXmlValue(): string {
        let tt: { lines: string }[] = [];
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        tt.push({ lines: this.getData() });
        return JSON.stringify(tt);
    }

    getDefaultConfig(): IRemarkOptions {
        return {};
    }
}
