/// <reference types="matrixrequirements-type-declarations" />
import { IDataStorage } from "../globals";

export { ServerStorage };

class ServerStorage implements IDataStorage {
    private serverPrefix: string;
    private DOMPurify: any;

    constructor(matrixBaseUrl: string, dompurifylib: any) {
        this.serverPrefix = matrixBaseUrl + "_";
        this.DOMPurify = dompurifylib;
    }

    setItem(itemKey: string, itemVal: string, dontSanitize?: boolean): void {
        localStorage.setItem(
            this.serverPrefix + itemKey,
            dontSanitize ? itemVal : this.DOMPurify.sanitize(itemVal) + "",
        );
    }

    getItem(itemKey: string, dontSanitize?: boolean): string {
        let val = localStorage.getItem(this.serverPrefix + itemKey);
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        return dontSanitize ? val : this.DOMPurify.sanitize(val) + "";
    }

    getItemDefault(itemKey: string, defaultValue: string): string {
        let val = localStorage.getItem(this.serverPrefix + itemKey);
        if (!val) {
            val = defaultValue;
        }
        return this.DOMPurify.sanitize(val) + "";
    }
}
