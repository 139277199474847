/// <reference types="matrixrequirements-type-declarations" />
import { IDB } from "./DBCache";
import { ISimpleTree } from "./MatrixReq";
import { IPlugin, IProjectPageParam, plugins } from "./PluginManager";

import { HTMLCleaner } from "./../matrixlib/index";
import { ICategoryConfig } from "./ItemConfiguration";
import { XCPostCompareHtml } from "../../RestCalls";
import { RestDB } from "./RestDB";
import { IItemControlOptions, ILinkType } from "../UI/Components/index";
import { IBaseControl, IBaseControlOptions } from "../UI/Controls/BaseControl";
import { CascadingSelect } from "../UI/Controls/cascadingSelect";
import { IJcxhr } from "./RestConnector";
import { ITableRow } from "../UI/Controls/tableCtrl";
import { ILinkCategories } from "../UI/Controls/linkCollection";
import { DocBaseImpl, ISignaturesInfo } from "../UI/Controls/docBase.";
import { IFromToSelection } from "../UI/Controls/itemSelectionFromTo";
import { IPasteSourceSetting, ITemplateProjects, MarkAsTemplateImpl } from "../UI/Controls/markAsTemplate";
import { ml } from "./../matrixlib";
import { refLinkStyle, refLinkTooltip } from "../UI/Parts/RefLinkDefines";
import { ICreateDialogOptions, ItemCreationTools } from "../UI/Tools/ItemCreationView";
import { Layouter } from "../UI/Tools/Layouter";
import {
    IDHFConfig,
    IDHFConfigCustomColumn,
    IDHFConfigTableColumn,
    IDropdownOption,
    TableCellEditor,
} from "../../ProjectSettings";
import {
    XRCategoryExtendedType,
    XRCreateReportJobAck,
    XRGetProject_JobStatus_JobsStatusWithUrl,
    XRPostProject_CompareHtml_HtmlCompareResult,
    XRPostProject_LaunchReport_CreateReportJobAck,
} from "../../RestResult";
import { Redlining } from "../../client/plugins/Redlining";
import { Hidden } from "../../client/plugins/DocumentSections/Hidden";
import {
    IDropDownButtonOption,
    IGetReportProgressResult,
    IReportOptions,
    IReportTransferField,
    IUploadedFileInfo,
    UIToolsConstants,
} from "../matrixlib/MatrixLibInterfaces";
import {
    app,
    ControlState,
    globalMatrix,
    IControlDefinition,
    IGenericMap,
    IItem,
    IItemGet,
    IItemPut,
    IReference,
    IStringMap,
    matrixApplicationUI,
    matrixSession,
    restConnection,
} from "../../globals";
import { FieldDescriptions } from "./FieldDescriptions";
import { ColumnEditor } from "./FieldHandlers";
import { DocFieldHandlerFactory } from "./FieldHandlers/Document";
import { GenericDocFieldHandler } from "./FieldHandlers/Document/GenericDocFieldHandler";
import { ICustomSectionOptions } from "./FieldHandlers/Document/CustomDocFieldHandler";
import { IDhfTableOptions } from "./FieldHandlers/Document/GenericTableDocHandler";
import { SectionDescriptions } from "./FieldHandlers/Document/SectionDescriptions";

import {
    DocumentSectionType,
    IDHFControlDefinition,
    IDHFControlDefinitionValue,
    IDHFFactory,
    IDHFField,
    IDHFFieldListItem,
    IDHFFieldParameter,
    IDHFFieldValue,
    IDHFFileOption,
    IDHFGuidOid,
    IDHFPasteBuffer,
    IDHFPasteBufferItem,
    IDHFPasteBufferValue,
    IDHFReorder,
    IDHFSection,
    IDHFSectionOptions,
    IDHFWizardData,
    IDHFXMLOptions,
    IGrandMother,
    IPluginDocumentSection,
    ISectionInfo,
    ISectionMap,
    ItemSortInfo,
} from "./DHFInterfaces";
import { NavigationPanel } from "../UI/MainTree/MainTree";

export type {
    IPluginDocumentSection,
    IDHFFieldListItem,
    IDHFField,
    IDHFControlDefinition,
    IDHFControlDefinitionValue,
    IDHFSection,
    IDHFSectionOptions,
    IDHFFieldParameter,
    IDHFFieldValue,
    IDHFXMLOptions,
    IDHFPasteBuffer,
    IDHFPasteBufferItem,
    IDHFPasteBufferValue,
    IDHFReorder,
    IDHFFileOption,
    IDHFGuidOid,
    IDHFWizardData,
    IDHFFactory,
    ISectionInfo,
    IGrandMother,
    ISectionMap,
    ItemSortInfo,
};
export { DocumentSectionType, PluginManagerDocuments };
export { ColumnTypesInfo, mDHF };
export { InitializePluginManagerDocuments };

const DOC_NUM_NAME = "Document Number";

class PluginManagerDocuments implements IPlugin {
    private wasInformedToday: string;
    private wasInformedTodayAbout: string;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private dhf_config: IDHFConfig;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private item: IItem;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private jui: JQuery;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private ColumnTypes: ColumnTypesInfo;
    private sectionFactories: IDHFFactory[];
    private sectionTypeNames: ISectionMap;
    public readonly COPY_PASTE_BUFFER = "pasteBuffer";

    public isDefault = true;

    constructor() {
        this.wasInformedTodayAbout = "";
        this.wasInformedToday = "";
        this.sectionFactories = [];
        this.sectionTypeNames = {};
    }

    // **********************
    // plugin interface
    // **********************
    preSaveHookAsync = async (isItem: boolean, type: string, controls: IControlDefinition[]): Promise<{}> => {
        //This hook is called before the item is saved. It is used to modify the reportId in case the user has selected only custom sections or static section

        let deferred = $.Deferred();
        let that = this;

        let useReportNameOverride = true;
        let reportOverrideForDocSettings = matrixSession.getCustomerSettingJSON("reportNameOverrideForDoc", {
            enabled: true,
            reportNameOverride: "dhf_generic_print",
            positiveList: [
                "checkbox",
                "audittrail",
                "signaturebox",
                "templateapproval",
                "responsibilities",
                "genericTable",
                "document_options",
                "duedate",
                "figures_tables",
                "multiselect",
                "remarks",
                "richtext",
                "terms_abbreviations",
                "textline",
                "CustomSection",
            ],
        });

        if (reportOverrideForDocSettings.enabled) {
            $.each(this.item, function (key, val) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                if (val && globalMatrix.ItemConfig.getFieldType(that.item.type, key) === "dhf") {
                    let fieldVal = <IDHFControlDefinitionValue>JSON.parse(val);
                    if (reportOverrideForDocSettings.positiveList.indexOf(fieldVal.type) == -1) {
                        // Not in positive list so we dont fix the report id
                        useReportNameOverride = false;
                    }
                }
            });

            let field = globalMatrix.ItemConfig.getFieldByName("DOC", "reportId");
            if (field) {
                for (let c of controls) {
                    if (c.fieldId == field.id) {
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        c.control
                            .getController()
                            .setValue(
                                useReportNameOverride ? reportOverrideForDocSettings.reportNameOverride : "dhf_generic",
                            );
                    }
                }
            }
        }

        deferred.resolve();

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        return deferred;
    };

    initItem(_item: IItem, _jui: JQuery) {
        this.item = _item;
        this.jui = _jui;
    }

    updateMenu(ul: JQuery, hook: number) {
        // no tools
        let that = this;

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        if (ml.Item.parseRef(this.item.id).isFolder) {
            // find out if there items in buffer
            let pb = localStorage.getItem(this.COPY_PASTE_BUFFER);
            let itemsToPaste = pb ? (<IDHFPasteBuffer>JSON.parse(pb)).items.length : 0;

            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            if (itemsToPaste && this.isDocumentFormType(this.item.type)) {
                let s = itemsToPaste > 1 ? "s" : "";
                let pasteDoc = $(
                    '<li title="paste document' +
                        s +
                        ' from template"><a href="javascript:void(0)">Paste document' +
                        s +
                        "</a></li>",
                ).click(function () {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    that.pasteTemplates(that.item.id);
                });
                ul.append(pasteDoc);
            }
        }
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        if (this.isSignedType(this.item.type) || this.isDocumentFormType(this.item.type)) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            let s = ml.Item.parseRef(this.item.id).isFolder ? "s" : "";
            let copyDoc = $(
                '<li title="Copy document' +
                    s +
                    ' as template"><a href="javascript:void(0)">Copy document' +
                    s +
                    "</a></li>",
            ).click(function () {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                that.copyTemplates(that.item.id);
            });
            ul.append(copyDoc);
        }
    }

    supportsControl(ctrl: string): boolean {
        // should render dhf controls....
        return false;
    }

    createControl(ctrl: JQuery, options: IBaseControlOptions) {
        // should render dhf controls....
    }

    initProject(project: string) {
        let that = this;

        this.dhf_config = globalMatrix.ItemConfig.getDHFConfig();
        if (!this.dhf_config) {
            this.dhf_config = {};
        }
        if (!this.dhf_config.categories) {
            this.dhf_config.categories = {
                documentTypes: ["DOC", "SIGN", "REPORT"],
                documentForms: ["DOC"],
                documentSigned: ["SIGN"],
                documentTemplates: ["SIGN"],
                signAs: "SIGN",
            };
        }
        this.ColumnTypes = new ColumnTypesInfo(this.dhf_config);

        // add custom tables for each type
        $.each(this.dhf_config, function (key0, config0) {
            if (typeof config0 === "object") {
                $.each(config0, function (key1, config1) {
                    if (key1 === "columns") {
                        $.each(config1, function (idx: number, val: IDHFConfigTableColumn) {
                            if (val.columnType) {
                                val.editor = that.ColumnTypes.getEditorOfType(val.columnType);
                                let options = that.ColumnTypes.getOptionsOfType(val.columnType);
                                if (options) {
                                    val.options = options;
                                }
                            }
                        });
                    }
                });
            }
        });
    }

    getProjectPages(): Promise<IProjectPageParam[]> {
        return new Promise((resolve, reject) => {
            resolve([]);
        });
    }
    renderActionButtons(options: IItemControlOptions, body: JQuery, controls: IDHFControlDefinition[]): boolean {
        if (
            !matrixSession.hasDocs() &&
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            (this.isSignedType(options.item.type) || this.isDocumentFormType(options.item.type))
        ) {
            body.append("<span style='color:red'>Document module not licensed</span>");
            return true;
        }
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        if (this.dhf_config.categories.documentSigned.indexOf(options.type) !== -1) {
            this.renderControlsSIGN(options, body, controls);
            return true;
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        } else if (mDHF.isDocumentFormType(options.type)) {
            this.renderControlsDOC(options, body, controls);
            return true;
        }

        return false;
    }

    // *****************************
    // public interface through mDHF
    // *****************************

    /* this renders an empty dhf control. It needs the actual field value to find out
     */
    renderControl(ctrl: IDHFControlDefinition, ctrlParameter: IBaseControlOptions, fieldValue: string): boolean {
        let that = this;
        // store values in control so that they can be rendered by the controller
        let ctrlDef = ctrl;
        ctrlDef.isDhfType = true;

        let controller = this.dhfFactory(SectionDescriptions.section_hidden); // if nothing is configured - nothing to render
        controller.setFieldHandler(new GenericDocFieldHandler(SectionDescriptions.section_hidden, {}, ""));

        if (fieldValue) {
            // contains type,value,configuration, and xml for reports
            ctrlDef.dhfValue = <IDHFControlDefinitionValue>JSON.parse(fieldValue);
            // create the controller
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            controller = this.dhfFactory(ctrlDef.dhfValue.type);
            ctrlParameter.fieldValue = ctrlDef.dhfValue.fieldValue;
            ctrlParameter.help = ctrlDef.dhfValue.name;

            ctrlDef.dhfValue.ctrlConfig = DocFieldHandlerFactory.GetDHFFieldConfig(
                globalMatrix.ItemConfig,
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                ctrlDef.dhfValue.type,
                ctrlDef.dhfValue.ctrlConfig,
            );
            let docFieldHandler = DocFieldHandlerFactory.createHandler(globalMatrix.ItemConfig, ctrlDef.dhfValue);

            ctrlParameter.fieldHandler = docFieldHandler;
            controller.setFieldHandler(docFieldHandler);
            ctrlParameter.preciseSelection = matrixSession.getUISettings().preciseDocSelect ? true : false;
        }

        // render the control
        controller.renderControl(ctrlDef, ctrlParameter);

        if (
            ctrlParameter.canEdit &&
            ctrlDef.dhfValue &&
            !ctrlParameter.isTooltip &&
            !ctrlParameter.isPrint &&
            ctrlParameter.controlState != ControlState.HistoryView &&
            ctrlParameter.controlState != ControlState.Zen &&
            matrixSession.isEditor()
        ) {
            let search = $(
                "<button title='Update Selection' style='display:none' class='btn btn-xs btn-default hidden-print refreshButton refreshNeeded'> <span class='fal fa-sync-alt' style='margin-left: -1px;'></span></button>",
            );

            search.click(function (event) {
                that.runSearch(controller, ctrlDef);
            });

            // get the dhf section type
            let sectionName = "";
            if (ctrlDef && ctrlDef.dhfValue && ctrlDef.dhfValue.type && this.getDhfControls()[ctrlDef.dhfValue.type]) {
                sectionName = this.getDhfControls()[ctrlDef.dhfValue.type].sectionName;
            }

            // add buttons
            let button = $(
                "<button title='Configure " +
                    sectionName +
                    "' class='btn btn-xs btn-default hidden-print configbutton'> <span class='fal fa-cog'></span></button>",
            );
            if (ctrlDef.dhfValue.type == "checkbox") {
                $(".baseControl", ctrlDef.control).append(button);
            } else if ($(".inlineHelp", ctrlDef.control).length) {
                $(".inlineHelp", ctrlDef.control).before(search).before(button);
            } else {
                $(".baseControlHelp", ctrlDef.control).append(search).append(button);
            }
            let ctrlParam = ctrlParameter;

            if (ctrlDef.dhfValue.type == "CustomSection") {
                $(
                    "<button title='Layout " +
                        sectionName +
                        "' class='btn btn-xs btn-default hidden-print configbutton'> <span class='fal fa-table'></span></button>",
                )
                    .appendTo($(".baseControlHelp", ctrlDef.control))
                    .click(async function () {
                        let layouter = new Layouter();

                        let froms = "";
                        let tos = "";
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        let currentSelection = await app.getFieldValueAsync(ctrlDef.fieldId);

                        if (currentSelection) {
                            // get selected items from UI
                            let conf = <IFromToSelection>JSON.parse(currentSelection);
                            froms = (conf.from ? conf.from : []).map((x) => x.to).join(",");
                            tos = (conf.to ? conf.to : []).map((x) => x.to).join(",");
                        }
                        if (!froms) {
                            ml.UI.showError("need input!", "You need to select some items on which to report on");
                            return;
                        }
                        let config = <ICustomSectionOptions>controller.getConfig(ctrlDef);
                        controller.getFieldHandler().setDHFConfig(config);

                        let labelFilter =
                            globalMatrix.ItemConfig.getFieldsOfType("docFilter").length == 1
                                ? await app.getFieldValueAsync(
                                      globalMatrix.ItemConfig.getFieldsOfType("docFilter")[0].field.id,
                                  )
                                : "";

                        layouter.show(
                            app.getCurrentItemId(),
                            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                            ctrlDef.fieldId,
                            ml.JSON.clone({ ...config, ...{} }),
                            froms,
                            tos,
                            labelFilter,
                            (newCode: string) => {
                                (<ICustomSectionOptions>controller.getConfig(ctrlDef)).options = JSON.parse(newCode);
                                ctrl.configTouched = true;
                                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                ctrlParameter.valueChanged.apply(null);
                            },
                        );
                    });
            }

            button.click(function () {
                that.showConfigDialog(sectionName, controller, ctrlDef, ctrlParam, "Configuration Options", false);
            });

            if (controller.hasSearch && controller.hasSearch(ctrl)) {
                // show search button
                $(".baseControlHelp", ctrlDef.control).addClass("refreshNeeded");
                search.show();
                if (controller.verifySearchAsync) {
                    controller.verifySearchAsync(ctrlDef);
                }
            }
            if (controller.verifyContentAsync) controller.verifyContentAsync(ctrlDef);
        }

        return !ctrlDef.dhfValue || ctrlDef.dhfValue.type !== "checkbox";
    }

    // ItemForm
    async getValue(ctrl: IDHFControlDefinition) {
        if (!ctrl.dhfValue) {
            // unused control
            return "";
        }
        // create the controller
        let controller = <IDHFSection>(ctrl.control as any).getController();
        let fieldHandler = controller.getFieldHandler();
        // udpate the controller values
        ctrl.dhfValue["fieldValue"] = fieldHandler.getData();

        // get specific options of control and add global as a row. If dhfFieldConfig is not set, use empty object
        let controllerConfig = fieldHandler.dhfFieldConfig ?? {};

        let global: IDHFSectionOptions = {
            globalOptions: true,
            page_break: controllerConfig["page_break"],
            sub_section: controllerConfig["sub_section"],
            landscape: controllerConfig["landscape"],
            show_section_title: controllerConfig["show_section_title"],
            automation: controllerConfig["automation"],
        };

        // get specific options of control and add global as a row
        let options: IDHFXMLOptions[] = JSON.parse(await fieldHandler.getXmlValue());
        options.push(global);

        // update the xml dump
        ctrl.dhfValue["fieldValueXML"] = JSON.stringify(options);
        ctrl.dhfValue["ctrlConfig"] = controllerConfig;

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        ctrl.dhfValue.name = (DOMPurify.sanitize(ctrl.dhfValue.name) + "").replace(/&lt;/g, "<");
        return JSON.stringify(ctrl.dhfValue);
    }

    /* used by ItemForm to figure if item needs to be saved */
    configChanged(ctrl: IDHFControlDefinition) {
        if (matrixApplicationUI.lastMainItemForm) {
            let allControls = <IDHFControlDefinition[]>matrixApplicationUI.lastMainItemForm.controls;
            for (let idx = 0; idx < allControls.length; idx++) {
                if (allControls[idx].isDhfType && allControls[idx].dhfValue) {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    let controller = <IDHFSection>ctrl.control.getController();
                    if (controller.verifyContentAsync) controller.verifyContentAsync(allControls[idx]);
                }
            }
        }
        return ctrl.configTouched;
    }

    /* return default format for REPORTs and DOCUMENTs, used by ItemForm for REPORTs */
    getDefaultFormat(category: string): string {
        if (this.dhf_config.defaultFormats && this.dhf_config.defaultFormats[category]) {
            return this.dhf_config.defaultFormats[category].toLowerCase();
        }
        return category === "REPORT" ? "html" : "docx";
    }

    // DB Cache
    showInProjectFolder(category: string): boolean {
        return !(
            this.dhf_config &&
            this.dhf_config.renderInTree &&
            this.dhf_config.renderInTree.indexOf(category) !== -1
        );
    }

    // ItemForm, toolbar, ... (default DOC, SIGN, REPORT)
    isDocumentType(category: string): boolean {
        return this.getDocumentTypes().indexOf(category) !== -1;
    }

    // used from different document sections (default DOC, SIGN, REPORT)
    getDocumentTypes(): string[] {
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        return this.dhf_config ? ml.JSON.clone(this.dhf_config.categories.documentTypes) : [];
    }

    // DOC
    getDocumentFormTypes(): string[] {
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        return this.dhf_config ? this.dhf_config.categories.documentForms : [];
    }

    // SIGN (could be DOC and SIGN)
    getDocumentTemplatesTypes(): string[] {
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        return this.dhf_config ? this.dhf_config.categories.documentTemplates : [];
    }

    // KeyboardManager (default DOC)
    isDocumentFormType(category: string): boolean {
        return mDHF.getDocumentFormTypes().indexOf(category) !== -1;
    }

    // ItemReference, MatrixReq, PluginManager, RestDb (default SIGN)
    isSignedType(category: string): boolean {
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        return this.dhf_config.categories.documentSigned.indexOf(category) !== -1;
    }

    // docSIGN
    // get all SIGN of an doc, maybe with a specific filter
    getSignedAsync(docId: string, labelFilter?: string): JQueryDeferred<IReference[]> {
        let that = this;

        function addChildren(children: IDB, ids: string[]) {
            $.each(children, function (cidx: number, child: IDB) {
                if (child.isUnselected == 0) {
                    if (typeof child.children !== "undefined") {
                        addChildren(<any>child.children, ids);
                    } else {
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        ids.push(child.id);
                    }
                }
            });
        }
        let res = $.Deferred();
        // get the document details which has a list of all downlinks
        app.getItemAsync(docId)
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            .done(function (doc: IItemGet) {
                if (labelFilter) {
                    // get the tree with a label filter which shows which items have the label
                    let cmd = "/tree?fancy&filter=" + labelFilter;
                    restConnection
                        .getServer(matrixSession.getProject() + cmd)
                        .done(function (result) {
                            // build a list of item ids which are not filters
                            const tree: IDB[] = RestDB.filterLegacyReportCat(result as IDB[]);
                            let signs: string[] = [];
                            $.each(tree, function (catidx: number, cat: IDB) {
                                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                if (that.dhf_config.categories.documentSigned.indexOf(cat.type) !== -1) {
                                    addChildren(<any>cat.children, signs);
                                }
                            });
                            let refs: IReference[] = [];
                            // check for each downlink if it has the label or not
                            $.each(doc.downLinks, function (dlidx, dl) {
                                if (signs.indexOf(dl.to) !== -1) {
                                    refs.push(dl);
                                }
                            });
                            res.resolve(refs);
                        })
                        .fail(function (jqxhr: IJcxhr, textStatus: string, error: string) {
                            res.reject(jqxhr, textStatus, error);
                        });
                } else {
                    // return all downlinks
                    res.resolve(doc.downLinks);
                }
            })
            .fail(function (jqxhr: IJcxhr, textStatus: string, error: string) {
                res.reject(jqxhr, textStatus, error);
            });

        return <any>res;
    }

    // all different document sections
    registerSection(
        sectionType: DocumentSectionType,
        sectionId: string,
        sectionName: string,
        creator: (config?: IDHFConfig, dhfType?: string, columnTypes?: ColumnTypesInfo) => IDHFSection,
        hidden?: boolean,
    ) {
        this.registerSection2(sectionType, true, sectionId, sectionName, creator, hidden);
    }

    registerSection2(
        sectionType: DocumentSectionType,
        dynamic: boolean,
        sectionId: string,
        sectionName: string,
        creator: (config?: IDHFConfig, dhfType?: string, columnTypes?: ColumnTypesInfo) => IDHFSection,
        hidden?: boolean,
    ) {
        (<IGenericMap>this.sectionFactories)[sectionId] = creator;
        if (sectionName) {
            this.sectionTypeNames[sectionId] = {
                sectionName: sectionName,
                sectionType: sectionType,
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                hidden: hidden,
                dynamic: dynamic,
            };
        }
    }

    getSections(dynamic: boolean) {
        let that = this;
        let sections = Object.keys(this.sectionTypeNames);
        return sections.filter((section) => that.sectionTypeNames[section].dynamic == dynamic);
    }

    // in case the user wants to delete a DOC which happens to be a DOC from which a SIGN has been created which is used as template, ask
    public isUsedAsTemplate(itemId: string) {
        let type = ml.Item.parseRef(itemId).type;

        if (type == "DOC" || type == "SIGN") {
            let templates = <IPasteSourceSetting>(
                globalMatrix.ItemConfig.getSettingJSON(MarkAsTemplateImpl.PROJECT_SETTING)
            );
            if (templates && templates.templates) {
                let filter = templates.templates.filter(function (td) {
                    return type == "DOC" ? td.fromDOC == itemId : td.fromSign == itemId;
                });
                if (filter.length > 0) {
                    return true;
                }
            }
        }
        return false;
    }

    // in case the user wants to delete a DOC which happens to be a DOC from which a SIGN has been created which is used as template, remove the templates
    public removeAsTemplate(itemId: string) {
        let type = ml.Item.parseRef(itemId).type;
        if (type == "DOC" || type == "SIGN") {
            let templates = <IPasteSourceSetting>(
                globalMatrix.ItemConfig.getSettingJSON(MarkAsTemplateImpl.PROJECT_SETTING)
            );
            templates.templates = templates.templates.filter(function (td) {
                return type == "DOC" ? td.fromDOC != itemId : td.fromSign != itemId;
            });
            app.setSettingJSON(MarkAsTemplateImpl.PROJECT_SETTING, templates);
        }
    }

    private runSearch(controller: IDHFSection, ctrl: IDHFControlDefinition) {
        if (controller.executeSearch) {
            controller.executeSearch(ctrl);
        }
    }

    //show config dialog for section, some part like title are available for all, rest is provided by sections itself
    showConfigDialog(
        sectionName: string,
        _controller: IDHFSection,
        _ctrl: IDHFControlDefinition,
        _ctrlParameter: IBaseControlOptions,
        title: string,
        hideStandardOptions: boolean,
    ) {
        let controller = _controller;
        let ctrl = _ctrl;

        let ctrlParameter = _ctrlParameter;

        let controllerConfig = controller.getConfig(ctrl);
        if (typeof controllerConfig.show_section_title == "undefined") {
            // default show title and apply numbering if wanted
            controllerConfig.show_section_title = "auto";
        }

        let isCheckbox = ctrl && ctrl.dhfValue && ctrl.dhfValue.type == "checkbox";
        let isRichtext = ctrl && ctrl.dhfValue && ctrl.dhfValue.type == "richtext";
        let isRisk = ctrl && ctrl.dhfValue && ctrl.dhfValue.type == "riskstats";
        let isFirstSection = globalMatrix.ItemConfig.getFieldsOfType("dhf", "DOC")[0].field.id == ctrlParameter.fieldId;

        let ui = $("<div>");
        if (!hideStandardOptions) {
            // add common stuff (like name change)
            // TODO: convert to const and make sure it's still works
            // eslint-disable-next-line no-var
            var name = $("<div>").refLink({
                folder: false, // show id if it exists
                id: "Name" + (sectionName ? " of " + sectionName : "") + ":",
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                title: ctrl.dhfValue.name,
                style: refLinkStyle.edit,
                tooltip: refLinkTooltip.none,
                callback: function () {},
            });

            ui.append(name);

            if (!isCheckbox) {
                let globalOptionShowSectionTitle = $(
                    '<select class="docOptionSelect p_show_section_title form-control" style="">' +
                        '<option value="auto">Show section title</option>' +
                        '<option value="nonumber">Show section title without numbers</option>' +
                        (!isRichtext && !isRisk ? '<option value="notitle">Do not show section title</option>' : "") +
                        (isRichtext
                            ? '<option value="notitle">Do not show section title (note: do not use headings in the text box)</option>'
                            : "") +
                        "</select>",
                );

                ui.append($(globalOptionShowSectionTitle));
                globalOptionShowSectionTitle.val(controllerConfig.show_section_title);
            }

            let globalOptionNewPage =
                '<div class="checkbox" ><label><input type="checkbox" class="p_newpage" ' +
                (controllerConfig.page_break ? "checked" : "") +
                "> Add page break after this section (word and pdf)</label></div>";
            ui.append($(globalOptionNewPage));

            if (!isCheckbox) {
                let isFirstSection =
                    globalMatrix.ItemConfig.getFieldsOfType("dhf", "DOC")[0].field.id == ctrlParameter.fieldId;
                let globalOptionSubSection = `<div class="checkbox" ><label><input type="checkbox" class="p_subsection" ${
                    controllerConfig.sub_section ? "checked" : ""
                } ${isFirstSection && !controllerConfig.sub_section ? "disabled" : ""}> Make it a sub-section${
                    isFirstSection ? " (note: first section cannot be a sub-section!)" : ""
                }</label></div>`;
                ui.append($(globalOptionSubSection));
            }
            let globalOptionLandscape =
                '<div class="checkbox" ><label><input type="checkbox" class="p_landscape" ' +
                (controllerConfig.landscape ? "checked" : "") +
                "> Print this section in landscape</label></div>";
            ui.append($(globalOptionLandscape));

            let globalOptionAutomation = $(
                '<input autocomplete="off" style="height: 20px;width: 420px;float: right;" class="form-control p_automation" type="text" name="automation" value="" /> ',
            );
            let sp = $("<span>XSLT processing: </span>").append(globalOptionAutomation);
            ui.append(sp);
            ui.append("<div style='padding:4px'>");
            globalOptionAutomation.val(controllerConfig.automation ? controllerConfig.automation : "");
        }
        // per function stuff
        let custom = $("<div>");
        ui.append(custom);

        app.dlgForm.html("");
        app.dlgForm.append(ui);

        app.dlgForm.removeClass("dlg-no-scroll");
        app.dlgForm.addClass("dlg-v-scroll");

        let searchBefore =
            (<any>controllerConfig)["search"] +
            "," +
            (<any>controllerConfig)["searchFrom"] +
            "," +
            (<any>controllerConfig)["searchTo"];
        app.dlgForm.dialog({
            autoOpen: true,
            title: title,
            height: 580,
            width: 680,
            modal: true,
            open: function () {
                controller.showSpecificSettings(ctrl, ctrlParameter, custom);
            },
            resizeStop: function () {},
            buttons: [
                {
                    text: "Ok",
                    class: "btnDoIt",
                    click: async function () {
                        // rescue the show hide control and config controls in case it is rendered again
                        // common settings
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        if (!hideStandardOptions && ctrl.dhfValue.name !== name.getValue()) {
                            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                            ctrl.dhfValue.name = name.getValue();
                            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                            ctrl.control.getController().getFieldHandler().setFieldName(name.getValue());
                            ctrl.configTouched = true;
                            if (isCheckbox) {
                                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                (ctrl.control as any).find(".checkboxLabel").html(ctrl.dhfValue.name);
                            } else {
                                let bcs = (ctrl.control as any).find(".baseControlHelp").contents();
                                $.each(bcs, function (bcIdx, bc) {
                                    if (bc.nodeName == "#text") {
                                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                        bc.textContent = ctrl.dhfValue.name;
                                    }
                                });
                            }
                        }

                        controllerConfig = controller.getConfig(ctrl);

                        if (!isCheckbox) {
                            // save controls
                            let showHide = $("input:first", ctrl.control);
                            showHide.detach();
                            let label = $("label:first", ctrl.control);
                            label.detach();

                            // specific settings
                            if (await controller.saveSpecificSettingsAsync(ctrl, ctrlParameter, custom)) {
                                ctrl.configTouched = true;
                            }
                            // remove 'normal' help and add doc stuff again
                            $(".baseControlHelp", ctrl.control).remove();
                            (ctrl.control as any).prepend(label);
                            (ctrl.control as any).prepend(showHide);

                            let searchAfter =
                                (<any>controllerConfig)["search"] +
                                "," +
                                (<any>controllerConfig)["searchFrom"] +
                                "," +
                                (<any>controllerConfig)["searchTo"];
                            if (searchAfter.replace(/undefined/g, "").length == 2) {
                                $(".refreshButton", ctrl.control).removeClass("refreshNeeded").hide();
                                $(".baseControlHelp", ctrl.control).removeClass("refreshNeeded");
                            } else {
                                $(".refreshButton", ctrl.control).show();
                                if (searchAfter != searchBefore) {
                                    $(".refreshButton", ctrl.control).addClass("refreshNeeded");
                                    $(".baseControlHelp", ctrl.control).addClass("refreshNeeded");
                                }
                            }

                            if (
                                !hideStandardOptions &&
                                controllerConfig.show_section_title != $(ui.find(".p_show_section_title")[0]).val()
                            ) {
                                controllerConfig.show_section_title = $(ui.find(".p_show_section_title")[0]).val();
                                // save the checkbox change!
                                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                ctrl.control.getController().getFieldHandler().setDHFConfig(controllerConfig);
                                ctrl.configTouched = true;
                            }

                            if (
                                !hideStandardOptions &&
                                controllerConfig["sub_section"] != $(ui.find(".p_subsection")[0]).prop("checked")
                            ) {
                                controllerConfig["sub_section"] = $(ui.find(".p_subsection")[0]).prop("checked");
                                // save the checkbox change!
                                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                ctrl.control.getController().getFieldHandler().setDHFConfig(controllerConfig);

                                ctrl.configTouched = true;
                            }
                        }
                        if (
                            !hideStandardOptions &&
                            controllerConfig["page_break"] != $(ui.find(".p_newpage")[0]).prop("checked")
                        ) {
                            controllerConfig["page_break"] = $(ui.find(".p_newpage")[0]).prop("checked");
                            // save the checkbox change!
                            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                            ctrl.control.getController().getFieldHandler().setDHFConfig(controllerConfig);
                            ctrl.configTouched = true;
                        }

                        if (
                            !hideStandardOptions &&
                            controllerConfig["landscape"] != $(ui.find(".p_landscape")[0]).prop("checked")
                        ) {
                            controllerConfig["landscape"] = $(ui.find(".p_landscape")[0]).prop("checked");
                            // save the checkbox change!
                            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                            ctrl.control.getController().getFieldHandler().setDHFConfig(controllerConfig);
                            ctrl.configTouched = true;
                        }
                        if (
                            !hideStandardOptions &&
                            controllerConfig["automation"] != $(ui.find(".p_automation")[0]).val()
                        ) {
                            controllerConfig["automation"] = $(ui.find(".p_automation")[0]).val();
                            // save the checkbox change!
                            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                            ctrl.control.getController().getFieldHandler().setDHFConfig(controllerConfig);
                            ctrl.configTouched = true;
                        }
                        if (ctrl.configTouched && ctrlParameter.valueChanged) {
                            ctrlParameter.valueChanged.apply(null);
                        }
                        app.dlgForm.dialog("close");
                    },
                },
                {
                    text: "Cancel",
                    class: "btnCancelIt",
                    click: function () {
                        app.dlgForm.dialog("close");
                    },
                },
            ],
        });
    }

    // ContextFrames (Help Tool)
    getArchiveButtonName() {
        if (this.dhf_config && this.dhf_config.archiveButtonName) {
            return this.dhf_config.archiveButtonName;
        }
        return "Ready to Sign / Release";
    }
    getToolFolderName() {
        if (this.dhf_config && this.dhf_config.toolFolderName) {
            return this.dhf_config.toolFolderName;
        }
        return null;
    }
    // ItemControl
    showCreateFromDocx(options: ICreateDialogOptions) {
        let that = this;
        function doImport(params: IDHFWizardData) {
            // show spinning wait
            app.dlgForm.html("").append(ml.UI.getSpinningWait("Converting document ..."));
            ml.UI.setEnabled($("button", app.dlgForm.parent()), false);
            // convert document

            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            let fileId = JSON.parse(params.template)[0].fileId;
            let fileNo = Number(fileId.split("?")[0]);
            app.convertDocAsync(fileNo, options.parent).done(function (docId) {
                // patch the document
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                app.getItemAsync(docId).done(function (newDoc) {
                    let changes: IItemPut = { id: docId, onlyThoseFields: 1, onlyThoseLabels: 1 };
                    changes.title = params.dhfName;

                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    let type = ml.Item.parseRef(docId).type;
                    let docNumberField = globalMatrix.ItemConfig.getFieldId(type, DOC_NUM_NAME);

                    $.each(newDoc, function (fieldId, fieldValue) {
                        let fieldType = globalMatrix.ItemConfig.getFieldType(type, fieldId);
                        if (fieldType === FieldDescriptions.Field_dhf) {
                            if (fieldValue) {
                                let parsed = <IDHFControlDefinitionValue>JSON.parse(fieldValue);
                                if (parsed.type === FieldDescriptions.Field_richtext && parsed.fieldValue) {
                                    parsed.fieldValue = new HTMLCleaner(parsed.fieldValue, false).getClean(
                                        HTMLCleaner.CleanLevel.Strict,
                                        false,
                                    );
                                    parsed.fieldValueXML = JSON.stringify([{ globalOptions: true }]);
                                    (<IGenericMap>changes)[fieldId] = JSON.stringify(parsed);
                                }
                            }
                        } else if (
                            fieldType === FieldDescriptions.Field_textline &&
                            "" + fieldId == "" + docNumberField &&
                            params.dhfNumber
                        ) {
                            (<IGenericMap>changes)[fieldId] = params.dhfNumber;
                        }
                    });
                    if (params.dhfGUID) {
                        let guid = globalMatrix.ItemConfig.getFieldsOfType("guid", type);
                        if (guid.length == 1) {
                            (<IGenericMap>changes)[guid[0].field.id] = params.dhfGUID;
                        }
                    }
                    let fileAttachmentControl = globalMatrix.ItemConfig.getFieldsOfType("fileManager", type);
                    if (fileAttachmentControl.length > 0) {
                        (<IGenericMap>changes)[fileAttachmentControl[0].field.id] = params.template;
                    }
                    app.updateItemInDBAsync(changes, "post docx conversion").always(function (newItem) {
                        let createdItem = {
                            parent: options.parent,
                            position: 100000,
                            item: newItem,
                        };
                        app.insertInTree(createdItem);
                        app.updateCache(createdItem);

                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        app.treeSelectionChangeAsync(docId);
                        ml.UI.setEnabled($("button", app.dlgForm.parent()), true);
                        app.dlgForm.dialog("close");
                    });
                });
            });
        }
        app.dlgForm.html("");
        app.dlgForm.removeClass("dlg-no-scroll");
        app.dlgForm.addClass("dlg-v-scroll");

        app.dlgForm.dialog({
            autoOpen: true,
            title: "Import and Convert Word Document",
            height: 400,
            width: 720,
            modal: true,
            open: function () {
                let okButton = $(".btnDoIt", app.dlgForm.parent());
                that.showDHFCreateWizard(
                    options.type,
                    app.dlgForm,
                    $(".btnDoIt", app.dlgForm.parent()),
                    doImport,
                    true,
                );
            },
            close: function () {
                NavigationPanel.focusTree();
            },
            resizeStop: function () {},
            buttons: [
                {
                    text: "Ok",
                    class: "btnDoIt",
                    click: function () {},
                },
                {
                    text: "Cancel",
                    class: "btnCancelIt",
                    click: function () {
                        app.dlgForm.dialog("close");
                    },
                },
            ],
        });
    }

    // docReview control
    loadDocument(jobId: number, onLoad: (htmlDOM: any) => void) {
        let that = this;

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        app.getReportDetails(jobId).done(function (progress: XRGetProject_JobStatus_JobsStatusWithUrl) {
            if (progress.status === "Error" || progress.status.indexOf("Report generation error") === 0) {
                ml.UI.showError("Error creating document", "");
            } else if (progress.status !== "Done" || progress.progress < 100) {
                window.setTimeout(function () {
                    that.loadDocument(jobId, onLoad);
                }, 500);
            } else {
                app.downloadInMemory(jobId, progress.jobFile[progress.jobFile.length - 1].jobFileId.toString()).done(
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    function (htmlpage: string) {
                        let htmlDOM = $.parseHTML(htmlpage);
                        if (!htmlDOM) {
                            ml.Logger.log("error", "Received report is no (valid) HTML.");
                        } else {
                            for (let idx = 0; idx < htmlDOM.length; idx++) {
                                if ((<{ id: string }>htmlDOM[idx]).id === "report") {
                                    onLoad(htmlDOM[idx]);
                                }
                            }
                        }
                    },
                );
            }
        });
    }

    getSignatureMeanings(): IStringMap {
        if (this.dhf_config) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            return this.dhf_config.signatureMeanings;
        }
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        return null;
    }
    // *****************
    // private interface
    // *****************

    /* returns sections from a pre-configure document, e.g. report, plan, sop, ... */
    private getDefaultFields(option: string): IDHFField[] {
        let fields: IDHFField[] = [];
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        if (this.dhf_config.controlledDocs[option]) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            $.each(this.dhf_config.controlledDocs[option].fields, function (index, value) {
                $.each(value, function (key, val) {
                    fields.push({ type: key, name: val });
                });
            });
        }
        return fields;
    }

    getDhfControls(): ISectionMap {
        let that = this;
        let sn: ISectionMap = {};
        let keys = Object.keys(this.sectionTypeNames);
        keys.sort();

        $.each(keys, function (idx, key) {
            sn[key] = that.sectionTypeNames[key];
        });
        // add custom columns to section options
        if (this.dhf_config.customTables) {
            $.each(this.dhf_config.customTables, function (index, table) {
                sn[table.id] = {
                    sectionName: table.name,
                    sectionType: DocumentSectionType.CustomTable,
                    hidden: false,
                    dynamic: false,
                };
            });
        }

        return sn;
    }

    // for admin client
    setConfig(config: IDHFConfig) {
        this.dhf_config = config;
    }
    private appendConfigTool(creationButtons: JQuery, _config: ICategoryConfig, _item: IItem) {
        let that = this;

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        if (!matrixSession.isEditor() || !globalMatrix.ItemConfig.canEdit(_item.type)) {
            return;
        }
        let itemId = _item.id;

        let buttonHolder = $(
            "<button id='documentConfig' title='Document Sections' class='btn btn-item hidden-print'> <span class='fal fa-cog'></span></button>",
        );
        creationButtons.append(" ").append(buttonHolder);
        buttonHolder.click(function () {
            if (app.getNeedsSave()) {
                ml.UI.showError("Save Item", "Please save before modifying document options.");
                return;
            }
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            app.getItemAsync(itemId).done(function (item) {
                let added = false;
                let update: IItemPut = { onlyThoseFields: 1, onlyThoseLabels: 1 };
                $.each(item, function (key, val) {
                    if (
                        key === "type" ||
                        key === "title" ||
                        key === "id" ||
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        globalMatrix.ItemConfig.getFieldType(item.type, key)
                    ) {
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        if (!added && globalMatrix.ItemConfig.getFieldType(item.type, key) === "dhf" && !val) {
                            added = true;
                            (<IStringMap>update)[key] = JSON.stringify({
                                type: "document_options",
                                name: "Document Options",
                                fieldValue: "",
                                ctrlConfig: { auto_number: false, omit_title: false },
                            });
                        } else {
                            (<IStringMap>update)[key] = val;
                        }
                    }
                });
                if (!added) {
                    ml.UI.showError("Cannot add document options!", "");
                    return;
                }
                app.updateItemInDBAsync(update, "report update")
                    .done(function (result) {
                        app.renderItem(result);
                        $("#buttonDocOptions").click();
                    })
                    .fail(function (error) {
                        ml.UI.showError("Failed to save changes!", error);
                    });
            });
        });

        let button = $(
            "<button title='Document Sections' class='btn btn-item hidden-print dhfConfigureSections'> <span class='fal fa-list'></span></button>",
        );
        creationButtons.append(" ").append(button);
        button.click(function () {
            if (app.getNeedsSave()) {
                ml.UI.showError("Save Item", "Please save before modifying document sections.");
                return;
            }
            ml.UI.setEnabled(button, false);
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            app.getItemAsync(itemId).done(function (item) {
                ml.UI.setEnabled(button, true);
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                that.reorderDialog(item);
            });
        });
    }

    private getSignatures(allControls: IDHFControlDefinition[]): string[] {
        let signatures: string[] = [];
        for (let idx = 0; idx < allControls.length; idx++) {
            if (allControls[idx].isDhfType && allControls[idx].dhfValue) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                let controller = allControls[idx].control.getController();
                controller.fieldHandler.addSignatures(signatures, allControls[idx], true);
            }
        }
        return signatures;
    }

    private showDHFCreateWizard(
        category: string,
        dlgInner: JQuery,
        nextButton: JQuery,
        endWizard: Function,
        importDocx: boolean,
    ) {
        let that = this;

        async function enableNext() {
            let enabled =
                (await documentName.getController().getValueAsync()) &&
                (await documentName.getController().getValueAsync()) !== "";
            if (importDocx) {
                let val = await createTemplate.getController().getValueAsync();
                if (val && JSON.parse(val).length > 0) {
                    if (!enabled) {
                        documentName.getController().setValue(JSON.parse(val)[0].fileName.replace(".docx", ""));
                    }
                    enabled = true;
                } else {
                    enabled = false;
                }
            }
            ml.UI.setEnabled(nextButton, enabled);
        }
        // create wizard
        ml.UI.setEnabled(nextButton, false);
        // TODO: convert to const and make sure it's still works
        // eslint-disable-next-line no-var
        var documentName = $("<div class='controlContainer'>");
        documentName.plainText({
            controlState: ControlState.DialogCreate,
            canEdit: true,
            // TODO(modules): field dummyData wasn't in the original definition, why is it here?
            dummyData: false,
            help: "Document Name",
            fieldValue: "",
            valueChanged: function () {
                enableNext();
            },
            parameter: {
                rows: 1,
                allowResize: false,
            },
        });
        dlgInner.append(documentName);
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        let documentId: JQuery = null;
        if (globalMatrix.ItemConfig.getFieldId(category, DOC_NUM_NAME)) {
            documentId = $("<div  class='controlContainer'>");
            documentId.plainText({
                controlState: ControlState.DialogCreate,
                canEdit: true,
                dummyData: false,
                help: DOC_NUM_NAME,
                fieldValue: "",
                valueChanged: function () {},
                parameter: {
                    rows: 1,
                    allowResize: false,
                },
            });
            dlgInner.append(documentId);
        }
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        let documentGUID: JQuery = null;

        let createTemplate = $("<div  class='controlContainer'>");
        /* prepare pre-configured document types, e.g. report, plan, sop... to be used in a drop down */
        let options: IDHFFieldListItem[] = [];
        for (let opt in this.dhf_config.controlledDocs) {
            options.push({ id: opt, label: opt });
        }
        let dhfConfig = $("<div>");
        dlgInner.append(dhfConfig);

        if (importDocx) {
            createTemplate.fileManager({
                controlState: ControlState.DialogCreate,
                canEdit: true,
                help: "Word Document",
                fieldValue: "",
                valueChanged: function () {
                    enableNext();
                },
                parameter: {
                    readonly: false,
                    replace: "type_auto",
                    autohide: false,
                    manualOnly: true,
                    titleBarControl: null,
                    extensions: ["docx"],
                    textTodo: "Upload word document",
                    single: true,
                },
            });
        } else {
            // alow user to choose default layout
            createTemplate.mxDropdown({
                controlState: ControlState.DialogCreate,
                canEdit: true,
                dummyData: false,
                help: "Document Sections",
                fieldValue: "Empty",
                valueChanged: function () {},
                parameter: {
                    placeholder: "choose field preset",
                    create: false,
                    options: options,
                    maxItems: 1,
                    sort: false,
                },
            });
        }
        dlgInner.append(createTemplate);
        // execute the next only once
        nextButton.one("click", async function (event) {
            if (importDocx) {
                endWizard({
                    template: await createTemplate.getController().getValueAsync(),
                    dhfName: (await documentName.getController().getValueAsync())
                        ? await documentName.getController().getValueAsync()
                        : "no name",
                    dhfNumber:
                        documentId && (await documentId.getController().getValueAsync())
                            ? await documentId.getController().getValueAsync()
                            : "no id",
                    dhfGUID: documentGUID ? (<CascadingSelect>documentGUID.getController()).getGuid() : "",
                });
                if (event.preventDefault) event.preventDefault();
                return false;
            }
            documentName.hide();
            if (documentId) documentId.hide();
            if (documentGUID) documentGUID.hide();
            createTemplate.hide();

            let dhfDefaultFields = mDHF.getDefaultFields(await createTemplate.getController().getValueAsync());

            let sectionSelect: IDropdownOption[] = [];

            $.each(mDHF.getDhfControls(), function (key: string, val: ISectionInfo) {
                if (key !== "document_options" && !val.hidden) {
                    sectionSelect.push({ id: key, label: val.sectionName, class: val.sectionType.toString() });
                }
            });
            // sort by group + alphabet
            let sortedSectionTypes = [
                DocumentSectionType.Static.toString(),
                DocumentSectionType.Technical.toString(),
                DocumentSectionType.Database.toString(),
                DocumentSectionType.Table.toString(),
                DocumentSectionType.CustomTable.toString(),
            ];
            sectionSelect.sort(function (a, b) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                if (sortedSectionTypes.indexOf(a.class) < sortedSectionTypes.indexOf(b.class)) return -1;
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                if (sortedSectionTypes.indexOf(a.class) > sortedSectionTypes.indexOf(b.class)) return 1;

                return a.label < b.label ? -1 : 1;
            });
            // insert separators
            // sectionSelect.splice(0,0 ,{id:"CUSTOMSECTION", label:"CUSTOM SECTION", disabled:true});
            let pos = sectionSelect
                .map(function (ss) {
                    return ss.class;
                })
                .indexOf(DocumentSectionType.Static.toString());
            if (pos != -1) sectionSelect.splice(pos, 0, { id: "Static", label: "Static", disabled: true });
            pos = sectionSelect
                .map(function (ss) {
                    return ss.class;
                })
                .indexOf(DocumentSectionType.Database.toString());
            if (pos != -1) sectionSelect.splice(pos, 0, { id: "Static", label: "Dynamic (Database)", disabled: true });
            pos = sectionSelect
                .map(function (ss) {
                    return ss.class;
                })
                .indexOf(DocumentSectionType.Table.toString());
            if (pos != -1) sectionSelect.splice(pos, 0, { id: "Static", label: "TOCs and Indexes", disabled: true });
            pos = sectionSelect
                .map(function (ss) {
                    return ss.class;
                })
                .indexOf(DocumentSectionType.CustomTable.toString());
            if (pos != -1) sectionSelect.splice(pos, 0, { id: "Static", label: "Custom Tables", disabled: true });

            dhfConfig.tableCtrl({
                controlState: ControlState.DialogCreate,
                canEdit: true,
                dummyData: false,
                help: "Configure Sections",
                fieldValue: JSON.stringify(dhfDefaultFields),
                valueChanged: function () {},
                parameter: {
                    maxRows: that.getNumberOfDHFSections(category),
                    canBeModified: true,
                    columns: [
                        { name: "Section Title", field: "name", editor: ColumnEditor.textline },
                        { name: "Type", field: "type", editor: ColumnEditor.select, options: sectionSelect },
                    ],
                },
            });

            // prepare next step
            // disable next for one second
            nextButton.prop("disabled", true);
            nextButton.addClass("ui-state-disabled");
            window.setTimeout(function () {
                nextButton.prop("disabled", false);
                nextButton.removeClass("ui-state-disabled");
                $("span", nextButton).html("Create");
            }, 1000);

            nextButton.click(async function (event) {
                ml.UI.setEnabled(nextButton, false);
                let dhfItems = <ITableRow[]>(
                    ((await dhfConfig.getController().getValueAsync())
                        ? JSON.parse(await dhfConfig.getController().getValueAsync())
                        : [])
                );
                dhfItems = dhfItems
                    .filter(function (row) {
                        return !!row.type;
                    })
                    .map(function (row) {
                        if (!row.name) {
                            row.name = row.type;
                        }
                        return row;
                    });
                endWizard({
                    dhfItems: dhfItems,
                    dhfName: (await documentName.getController().getValueAsync())
                        ? await documentName.getController().getValueAsync()
                        : "no name",
                    dhfNumber:
                        documentId && (await documentId.getController().getValueAsync())
                            ? await documentId.getController().getValueAsync()
                            : "no id",
                    dhfGUID: documentGUID ? (<CascadingSelect>documentGUID.getController()).getGuid() : "",
                });
                if (event.preventDefault) event.preventDefault();
                return false;
            });
            if (event.preventDefault) event.preventDefault();
            return false;
        });
    }

    public getBaseDOCofSIGN(project: string, itemSigned: IItem) {
        let res = $.Deferred();

        let parents = itemSigned.upLinkList
            ? itemSigned.upLinkList.filter((ul) => ml.Item.parseRef(ul.itemRef).type == "DOC")
            : [];
        if (parents.length == 0) {
            ml.Logger.warning("there's a SIGN without a parent DOC - cannot fill sign Info");
            res.reject();
            return res;
        }

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        let signCreationDate = new Date(itemSigned.history[itemSigned.history.length - 1].date);
        let vl = parents[0].itemRef;
        let theDoc = ml.Item.parseRef(vl);

        app.getProjectItemAsync(project, theDoc.id)
            .done(function (docWithHistory) {
                // go through history of DOC and find the version which existed just before creating the SIGN
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                let docIdx = docWithHistory.history.length - 1;
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                while (docIdx >= 0 && new Date(docWithHistory.history[docIdx].date) <= signCreationDate) {
                    docIdx--;
                }
                // that's the version used to create the SIGN
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                let docVersion = docWithHistory.history[docIdx + 1].version;
                // get that version of the DOC
                app.getProjectItemAsync(project, theDoc.id, docVersion)
                    .done(function (itemDoc) {
                        res.resolve(itemDoc);
                    })
                    .fail(() => {
                        res.reject();
                    });
            })
            .fail(() => {
                res.reject();
            });
        return res;
    }
    private createFromTemplate(project: string, signId: string, title: string, options: ICreateDialogOptions) {
        let that = this;

        app.getProjectCatFields(project)
            .done(function (categories) {
                app.getProjectItemAsync(project, signId).done(function (itemSigned) {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    if (itemSigned.isDeleted) {
                        ml.UI.showError("Instantiating Template Failed", "SIGN has been deleted.");
                        return;
                    }

                    let signType = ml.Item.parseRef(signId).type;
                    if (that.isDocumentFormType(signType)) {
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        that.transformTemplate(signId, categories, itemSigned, itemSigned.title, project, options);
                    } else {
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        that.getBaseDOCofSIGN(project, itemSigned)
                            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                            .done((itemDoc: IItem) => {
                                that.transformTemplate(
                                    signId,
                                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                    categories,
                                    itemDoc,
                                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                    itemSigned.title ? itemSigned.title : title,
                                    project,
                                    options,
                                );
                            })
                            .fail(() => {
                                ml.UI.showError("Instantiating Template Failed", "The DOC has been deleted.");
                            });
                    }
                });
            })
            .fail(function (jqxhr, textStatus, error) {
                ml.UI.showError("Instantiating Template Failed", "You have no access to this template");
            });
    }

    private transformTemplate(
        signId: string,
        categories: XRCategoryExtendedType[],
        itemDoc: IItem,
        title: string,
        project: string,
        options: ICreateDialogOptions,
    ) {
        let that = this;

        let pasteBuffer: IDHFPasteBufferValue[] = [];
        let guidFieldId: number = 0;

        $.each(categories, function (cidx, cat) {
            if (cat.category.shortLabel === itemDoc.type) {
                let fields = cat.fieldList.field;
                $.each(fields, function (fidx, f) {
                    pasteBuffer.push({
                        def: f,
                        val: (<IGenericMap>itemDoc)[f.id],
                    });
                    if (f.fieldType === FieldDescriptions.Field_guid) {
                        guidFieldId = f.id;
                    }
                });
            }
        });

        let itemJson: IItemPut = {};

        itemJson.title = title;

        const docfields = globalMatrix.ItemConfig.getItemConfiguration(ml.Item.parseRef(options.parent).type).fieldList;
        const pastebufferDHF = pasteBuffer.filter((pb) => pb.def.fieldType === FieldDescriptions.Field_dhf);
        const pastebufferNonDHF = pasteBuffer.filter((pb) => pb.def.fieldType !== FieldDescriptions.Field_dhf);
        $.each(docfields, function (fidx, f) {
            for (let idx = 0; idx < pastebufferNonDHF.length; idx++) {
                if (pastebufferNonDHF[idx].def.label === f.label) {
                    (<IGenericMap>itemJson)[f.id] = pastebufferNonDHF[idx].val;
                }
            }
        });
        const dhfDocFields = docfields.filter((df) => df.fieldType === FieldDescriptions.Field_dhf);
        if (dhfDocFields.length < pastebufferDHF.length) {
            ml.UI.showError(
                "Template does not match target project",
                "The target project DOCs don't have enough section fields. Please contact your admin to increase the number of sections in the DOC category",
            );
        } else {
            for (let it = 0; it < pastebufferDHF.length; it++) {
                (<IGenericMap>itemJson)[dhfDocFields[it].id] = pastebufferDHF[it].val;
            }
            itemJson.title = title;
            that.pasteTemplate(
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                { tree: null, items: [] },
                0,
                options.parent,
                true,
                itemJson,
                guidFieldId ? (<IGenericMap>itemDoc)[guidFieldId] : "",
                project,
                signId,
            );
        }
    }

    // itemId:  an item : an item or folder
    private copyTemplates(itemId: string): JQueryDeferred<{}> {
        let res = $.Deferred();

        let tree = app.getSubTree(itemId);
        let items = app.getChildrenIdsRec(itemId);

        this.preparePasteBuffer(tree);

        let showProgressBar = items.length > 1;

        if (showProgressBar) {
            ml.UI.BlockingProgress.Init([{ name: "Copying Documents" }]);
            ml.UI.BlockingProgress.SetProgress(0, 1);
        }
        this.copyTemplate(items, 0, !showProgressBar)
            .done(function () {
                if (showProgressBar) {
                    ml.UI.BlockingProgress.SetProgress(0, 100);
                }
            })
            .fail(function (error: string) {
                if (showProgressBar) {
                    ml.UI.BlockingProgress.SetProgressError(0, error);
                }
            })
            .always(function () {
                res.resolve();
            });
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        return res;
    }
    public copyTemplate(items: string[], itemIdx: number, quiet: boolean): JQueryDeferred<{}> {
        let that = this;

        let res = $.Deferred();

        if (items.length == itemIdx) {
            res.resolve();
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            return res;
        }

        let itemId = items[itemIdx];

        if (!quiet) {
            ml.UI.BlockingProgress.SetProgress(0, (100 * itemIdx) / items.length);
        }
        app.getItemAsync(itemId).done(function (item) {
            if (that.isDocumentFormType(ml.Item.parseRef(itemId).type)) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                app.getItemAsync(itemId, item.history[0].version)
                    .done(function (item_h) {
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        that.addToPasteBuffer(item_h, item.title, itemId + "-v" + item.history[0].version, itemId);
                        that.copyTemplate(items, itemIdx + 1, quiet)
                            .done(function () {
                                res.resolve();
                            })
                            .fail(function (error) {
                                res.reject(error);
                            });
                    })
                    .fail(function (error) {
                        res.reject(error);
                    });
            } else if (
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                item.upLinkList &&
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                item.upLinkList.length &&
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                that.isDocumentFormType(ml.Item.parseRef(item.upLinkList[0].itemRef).type)
            ) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                let vl = item.upLinkList[0].itemRef;
                let docVersion = ml.Item.parseRef(vl);

                app.getItemAsync(docVersion.id, docVersion.version)
                    .done(function (item) {
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        that.addToPasteBuffer(item, item.title, vl, itemId);
                        that.copyTemplate(items, itemIdx + 1, quiet)
                            .done(function () {
                                res.resolve();
                            })
                            .fail(function (error) {
                                res.reject(error);
                            });
                    })
                    .fail(function (error) {
                        res.reject(error);
                    });
            }
        });
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        return res;
    }

    private pasteTemplates(folderId: string) {
        let that = this;

        let currentBufferString = localStorage.getItem(this.COPY_PASTE_BUFFER);
        let pasteBuffers = <IDHFPasteBuffer>(currentBufferString ? JSON.parse(currentBufferString) : { items: [] });
        if (pasteBuffers.items.length > 1) {
            ml.UI.BlockingProgress.Init([{ name: "Pasting Documents" }]);
        }
        this.pasteTemplate(pasteBuffers, 0, folderId, pasteBuffers.items.length == 1);
    }

    private pasteTemplate(
        pasteBuffers: IDHFPasteBuffer,
        pasteIdx: number,
        folderId: string,
        quiet: boolean,
        itemSource?: IItemPut,
        sourceGUID?: string,
        sourceProject?: string,
        sourceItem?: string,
    ) {
        let that = this;

        if (!quiet) {
            ml.UI.BlockingProgress.SetProgress(0, (100 * pasteIdx) / pasteBuffers.items.length);
        }

        let targetCategory = ml.Item.parseRef(folderId).type;

        let sourceref = 0;
        let srfs = globalMatrix.ItemConfig.getFieldsOfType("sourceref", targetCategory);
        if (srfs.length) {
            sourceref = srfs.length ? srfs[0].field.id : 0;
        }
        // prepare the item to be created
        let itemJson: IItemPut = {};
        if (itemSource) {
            itemJson = itemSource;
            if (sourceref) {
                (<IGenericMap>itemJson)[sourceref] = sourceProject + "/" + sourceItem;
            }
        } else {
            let pasteBuffer = pasteBuffers.items[pasteIdx];

            itemJson.title = pasteBuffer.title;
            if (sourceref) {
                (<IGenericMap>itemJson)[sourceref] = pasteBuffer.sourceProject + "/" + pasteBuffer.sourceItem;
            }
            let fields = globalMatrix.ItemConfig.getItemConfiguration(targetCategory).fieldList;
            $.each(fields, function (fidx, f) {
                for (let idx = 0; idx < pasteBuffer.item.length; idx++) {
                    if (pasteBuffer.item[idx].def.label === f.label && sourceref != f.id) {
                        (<IGenericMap>itemJson)[f.id] = pasteBuffer.item[idx].val;
                    }
                }
            });
        }

        app.createItemOfTypeAsync(targetCategory, itemJson, "instantiated template", folderId)
            .done(function (result) {
                if (pasteBuffers.items.length > pasteIdx + 1) {
                    that.pasteTemplate(pasteBuffers, pasteIdx + 1, folderId, quiet, itemSource, sourceGUID);
                } else {
                    if (!quiet) {
                        ml.UI.BlockingProgress.SetProgress(0, 100);
                    }
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    app.treeSelectionChangeAsync(result.item.id);
                }
            })
            .fail(function (error) {
                if (!quiet) {
                    ml.UI.BlockingProgress.SetProgressError(0, error);
                }
            });
    }

    // returns first field of a given
    private getFieldByType(fieldType: string, controls: IDHFControlDefinition[]): IBaseControl {
        for (let idx = 0; idx < controls.length; idx++) {
            if (controls[idx].fieldType === fieldType) {
                return (controls[idx].control as any).getController();
            }
        }
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        return null;
    }
    /** requires sectionName or sectionType to identify field(s) */
    private getDHFFieldValuesFromItem(sectionName: string, sectionType: string): IDHFControlDefinitionValue[] {
        let that = this;
        let value: IDHFControlDefinitionValue[] = [];

        $.each(this.item, function (key, val) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            if (val && globalMatrix.ItemConfig.getFieldType(that.item.type, key) === FieldDescriptions.Field_dhf) {
                let fieldVal = <IDHFControlDefinitionValue>JSON.parse(val);
                if (
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    (sectionName && fieldVal.name.toLocaleLowerCase() === sectionName.toLocaleLowerCase()) ||
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    (sectionType && fieldVal.type.toLocaleLowerCase() === sectionType.toLocaleLowerCase())
                ) {
                    value.push(fieldVal);
                }
            }
        });
        return value;
    }

    /** requires sectionName or sectionType to identify field */
    private setDHFFieldValueOfItem(sectionName: string, sectionType: string, newValue: string) {
        let that = this;

        $.each(this.item, function (key, val) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            if (val && globalMatrix.ItemConfig.getFieldType(that.item.type, key) === FieldDescriptions.Field_dhf) {
                let fieldVal = <IDHFControlDefinitionValue>JSON.parse(val);
                if (
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    (sectionName && fieldVal.name.toLocaleLowerCase() === sectionName.toLocaleLowerCase()) ||
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    (sectionType && fieldVal.type.toLocaleLowerCase() === sectionType.toLocaleLowerCase())
                ) {
                    fieldVal.fieldValue = newValue;
                    let change: IItemPut = { id: that.item.id, onlyThoseFields: 1, onlyThoseLabels: 1 };
                    (<IGenericMap>change)[<string>key] = JSON.stringify(fieldVal);
                    app.updateItemInDBAsync(change, "edit").done(function () {
                        app.renderItem();
                    });
                    return;
                }
            }
        });
    }

    public preparePasteBuffer(tree: ISimpleTree) {
        let pasteBuffer: IDHFPasteBuffer = { tree: tree, items: [] };
        localStorage.setItem(this.COPY_PASTE_BUFFER, JSON.stringify(pasteBuffer));
    }

    private addToPasteBuffer(item: IItemGet, title: string, pasteSource: string, sourceItem: string) {
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        let fields = globalMatrix.ItemConfig.getItemConfiguration(item.type).fieldList;
        let pasteBuffer: IDHFPasteBufferValue[] = [];
        $.each(fields, function (fidx, f) {
            pasteBuffer.push({
                def: f,
                val: (<IGenericMap>item)[f.id],
            });
        });

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        let currentBuffer = <IDHFPasteBuffer>JSON.parse(localStorage.getItem(this.COPY_PASTE_BUFFER));
        currentBuffer.items.push({
            sourceItem: sourceItem,
            sourceProject: matrixSession.getProject(),
            pasteSource: pasteSource,
            title: title,
            item: pasteBuffer,
        });
        localStorage.setItem(this.COPY_PASTE_BUFFER, JSON.stringify(currentBuffer));
    }

    private docHasContent(controls: IDHFControlDefinition[]) {
        // check if a doc would be empty int hat case: explain and return false
        let isEmpty = true;
        let sectionCount = 0;
        let noContentSections = 0;
        $.each(controls, function (cidx, control) {
            if (control.isDhfType && control.dhfValue) {
                sectionCount++;
                if (control.dhfValue.fieldValueXML && control.dhfValue.type != "document_options") {
                    isEmpty = false;
                }
                if (control.dhfValue.type === "terms_abbreviations") {
                    noContentSections++;
                }
            }
        });

        if (sectionCount > 0 && app.getNeedsSave()) {
            return true;
        }

        isEmpty = isEmpty && !(sectionCount > 0 && noContentSections === sectionCount);
        if (isEmpty) {
            let message = sectionCount
                ? "You need to add some content into the sections:<br><ul>" +
                  "<li>To edit a section's content click on the <span class='fa fa-chevron-right'></span> before the section name.</li>" +
                  "<li>To change how the content is rendered click on the <span class='fal fa-cog'></span> behind the section name."
                : "First you need to add some sections. To do this click on the <span class='fal fa-list'></span> icon behind '" +
                  this.getArchiveButtonName() +
                  "'";
            let dlg =
                '<div class="modal fade bs-example-modal-sm" role="dialog" >' +
                '    <div class="modal-dialog modal-sm">' +
                '        <div class="modal-content">' +
                '            <div class="modal-header">' +
                '                <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span class="sr-only">Close</span></button>' +
                '                <h4 class="modal-title">Your document is still empty</h4>' +
                "            </div>" +
                '            <div class="modal-body" id="message_ack_content" >' +
                message +
                '                <br><br>Find more info here <a href="https://urlshort.matrixreq.com/d24/manual/docs" target="blank">here</a>.' +
                "            </div>" +
                '            <div class="modal-footer">' +
                '                <button id="message_ack_ok" type="button" class="btn btn-default" data-dismiss="modal">Ok</button>' +
                "            </div>" +
                "        </div>" +
                "    </div>" +
                "</div>";
            $(dlg).modal();
        }

        return !isEmpty;
    }

    private dhfFactory(dhfType: string): IDHFSection {
        if (typeof (<IGenericMap>this.sectionFactories)[dhfType] !== "undefined") {
            let creator = (<IGenericMap>this.sectionFactories)[dhfType];
            return creator(this.dhf_config, dhfType, this.ColumnTypes);
        } else {
            let isTable = false;
            if (this.dhf_config.customTables) {
                $.each(this.dhf_config.customTables, function (index, table) {
                    if (dhfType === table.id) {
                        isTable = true;
                    }
                });
            }
            if (isTable) {
                let creator = (<IGenericMap>this.sectionFactories)["dhf_table"];
                return creator(this.dhf_config, dhfType, this.ColumnTypes);
            }

            return new Hidden();
        }
    }

    private getNumberOfDHFSections(type: string): number {
        let maxRows = 0;
        let fields = globalMatrix.ItemConfig.getFieldsOfType("dhf", type);
        for (let idx = 0; idx < fields.length; idx++) {
            maxRows++;
        }
        return maxRows;
    }

    private getControlFieldName(type: string): string {
        let name = type;
        $.each(this.getDhfControls(), function (key: string, val: ISectionInfo) {
            if (key === type) {
                name = val.sectionName;
            }
        });
        return name;
    }

    private reorderDialog(_item: IItem) {
        let that = this;

        let item = _item;
        let td: JQuery;

        let dhfTable: IDHFReorder[] = [];

        let idx = 0;
        $.each(item, function (key, val) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            if (globalMatrix.ItemConfig.getFieldType(item.type, key) === FieldDescriptions.Field_dhf) {
                if (val) {
                    try {
                        let fieldVal = <IDHFControlDefinitionValue>JSON.parse(val);
                        if (fieldVal.type !== "document_options") {
                            dhfTable.push({
                                idx: idx,
                                id: key,
                                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                name: fieldVal.name,
                                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                type: that.getControlFieldName(fieldVal.type),
                            });
                        }
                        idx++;
                    } catch (error) {
                        console.log("Error parsing dhf field: " + key + " - " + error);
                    }
                }
            }
        });

        let ui = $("<div style='position: absolute;top: 12px;bottom: 12px;left: 12px;right: 12px;'>");

        let nst = $('<div class="newSectionType">');

        app.dlgForm.html("");
        app.dlgForm.append(ui);

        app.dlgForm.removeClass("dlg-v-scroll");
        app.dlgForm.addClass("dlg-no-scroll");

        app.dlgForm.dialog({
            autoOpen: true,
            title: "Configure Document Sections",
            height: 600,
            width: 650,
            modal: true,
            open: function () {
                let enableAdd = async function () {
                    let table = ml.JSON.fromString(await td.getController().getValueAsync());

                    let count =
                        table.status === "ok"
                            ? (<ITableRow[]>ml.JSON.fromString(await td.getController().getValueAsync()).value).length
                            : 0;
                    let type = await nst.getController().getValueAsync();
                    let name = nsn.val();
                    // leave 1 spare for document config
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    let cannotAddSection = !type || !name || count + 1 >= that.getNumberOfDHFSections(item.type);
                    button.prop("disabled", cannotAddSection);
                    ml.UI.setEnabled($(".btnSectionAdd"), cannotAddSection);
                };

                let addRow = $("<div class='rowFlex newSectionWrapper'>");

                ui.append(addRow);

                // TODO: convert to const and make sure it's still works
                // eslint-disable-next-line no-var
                var nsn = $('<input  class="newSectionName form-control" placeholder="enter section name" />');
                nsn.on("input propertychange", function () {
                    enableAdd();
                });
                addRow.append(nsn);

                let sorted: IDropdownOption[] = [];
                $.each(mDHF.getDhfControls(), function (key: string, val: ISectionInfo) {
                    if (key !== "document_options") {
                        if (!val.hidden || matrixSession.isSuperAdmin())
                            sorted.push({ id: key, label: val.sectionName, class: val.sectionType.toString() });
                    }
                });
                sorted.sort(function (a, b) {
                    if (a.label < b.label) return -1;
                    if (a.label > b.label) return 1;
                    return 0;
                });
                addRow.append(nst);
                nst.mxDropdown({
                    controlState: ControlState.DialogCreate,
                    canEdit: true,
                    dummyData: false,
                    help: "",
                    fieldValue: "Empty",
                    valueChanged: function () {
                        enableAdd();
                    },
                    parameter: {
                        placeholder: "select section type",
                        create: false,
                        options: sorted,
                        groups: [
                            { value: DocumentSectionType.Static.toString(), label: "Static" },
                            { value: DocumentSectionType.Database.toString(), label: "Dynamic (Database)" },
                            { value: DocumentSectionType.Table.toString(), label: "TOCs and Indexes" },
                            { value: DocumentSectionType.CustomTable.toString(), label: "Custom Tables" },
                            { value: DocumentSectionType.Technical.toString(), label: "Static" },
                        ],
                        maxItems: 1,
                        sort: false,
                        maxHeight: "400px",
                    },
                    noMarkup: true,
                });
                $(".selectize-input", nst).css("width", "100%");
                // TODO: convert to const and make sure it's still works
                // eslint-disable-next-line no-var
                var button = $(
                    "<button title class='newSectionAdd btn btn-success hidden-print' style='height: 38px;'> Add </span></button>",
                );
                addRow.append(button);

                td = $(
                    "<div style='position: absolute;top: 65px;bottom: 0;left: 0;right: 0; overflow-x:hidden;overflow-y:auto;'>",
                );
                ui.append(td);
                td.tableCtrl({
                    help: "Re-order or delete sections",
                    controlState: ControlState.DialogCreate,
                    parameter: {
                        canBeModified: true,
                        columns: [
                            { name: "Section name", field: "name", editor: ColumnEditor.none },
                            { name: "Section Type", field: "type", editor: ColumnEditor.none },
                        ],
                        create: false,
                    },
                    canEdit: true,
                    create: false,
                    fieldValue: JSON.stringify(dhfTable),
                });
                enableAdd();

                button.click(async function () {
                    let type = await nst.getController().getValueAsync();
                    let name = nsn.val();
                    if (name == "insertall") {
                        $.each(that.getDhfControls(), function (key, val) {
                            td.insertLine({ idx: -1, id: key, type: key, name: key });
                        });
                        return;
                    }
                    let typeName = that.getControlFieldName(type);
                    td.insertLine({ idx: -1, id: type, type: typeName, name: name });
                    nsn.val("");
                    enableAdd();
                });
            },
            resizeStop: function () {
                app.dlgForm.width(app.dlgForm.parent().width() - 30);
                td.getController().resizeItem(app.dlgForm.parent().width(), true);
            },
            buttons: [
                {
                    text: "Ok",
                    class: "btnDoIt btnSectionAdd",
                    click: async function () {
                        // save old values
                        let old: IGenericMap = {};
                        let oidx = 0;
                        let document_options = "";

                        //TODO : Move this to a business logic.

                        $.each(item, function (key, val) {
                            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                            if (globalMatrix.ItemConfig.getFieldType(item.type, key) === FieldDescriptions.Field_dhf) {
                                old[oidx] = val;
                                oidx++;
                                try {
                                    let js = <{ type: string }>JSON.parse(val);
                                    if (js.type === "document_options") {
                                        document_options = val;
                                    }
                                } catch (Exception) {
                                    // nous nous
                                }
                            }
                        });
                        let newTable = <ITableRow[]>JSON.parse(await td.getController().getValueAsync());
                        newTable = newTable
                            .filter(function (row) {
                                return !!row.type;
                            })
                            .map(function (row) {
                                if (!row.name) {
                                    row.name = row.type;
                                }
                                return row;
                            });

                        let nidx = 0;
                        $.each(item, function (key, val) {
                            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                            if (globalMatrix.ItemConfig.getFieldType(item.type, key) === FieldDescriptions.Field_dhf) {
                                if (nidx < newTable.length) {
                                    let line = newTable[nidx];
                                    if (line.idx === -1) {
                                        // get default settings / value of item
                                        let sectionCtrl: IDHFControlDefinition = { dhfValue: { fieldValueXML: "" } };
                                        let section = that.dhfFactory(line.id);
                                        try {
                                            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                            sectionCtrl.dhfValue["fieldValueXML"] == sectionCtrl;
                                        } catch (Exception) {
                                            // nous nous (this could be legacy doc section plugins)
                                        }
                                        // new section
                                        let dhfField = {
                                            type: line.id,
                                            name: line.name,
                                            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                            fieldValueXML: sectionCtrl.dhfValue.fieldValueXML,
                                        };
                                        (<IGenericMap>item)[key] = JSON.stringify(dhfField);
                                    } else {
                                        (<IGenericMap>item)[key] = old[line.idx];
                                    }
                                } else {
                                    // store the remembered document options (once)/clean the rest
                                    (<IGenericMap>item)[key] = document_options;
                                    document_options = "";
                                }
                                nidx++;
                            }
                        });

                        let update: IItemPut = { onlyThoseFields: 1, onlyThoseLabels: 1 };
                        $.each(item, function (key, val) {
                            if (
                                key === "type" ||
                                key === "title" ||
                                key === "id" ||
                                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                globalMatrix.ItemConfig.getFieldType(item.type, key)
                            ) {
                                (<IGenericMap>update)[key] = val;
                            }
                        });

                        // compare old with new
                        let changed = false;
                        let newIdx = 0;

                        $.each(update, function (key, val) {
                            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                            if (globalMatrix.ItemConfig.getFieldType(item.type, key) === FieldDescriptions.Field_dhf) {
                                if ((old[newIdx] || val) && old[newIdx] != val) {
                                    changed = true;
                                }
                                newIdx++;
                            }
                        });

                        if (changed) {
                            app.updateItemInDBAsync(update, "edit")
                                .done(function (result) {
                                    app.renderItem(result);
                                    app.dlgForm.dialog("close");
                                })
                                .fail(function (error) {
                                    ml.UI.showError("Failed to save changes!", error);
                                });
                        }
                    },
                },
                {
                    text: "Cancel",
                    class: "btnCancelIt",
                    click: function () {
                        app.dlgForm.dialog("close");
                    },
                },
            ],
        });
    }

    // check if SIGN or DOC has a file attachment control and file attachments
    private hasFileAttachments(type: string, controls: IControlDefinition[], item: IItem): boolean {
        let hfa = false;
        $.each(controls, function (ci, c) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            if (globalMatrix.ItemConfig.getFieldType(type, c.fieldId) === FieldDescriptions.Field_fileManager) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                let iv = (<IGenericMap>item)[c.fieldId];
                if (iv && (<any[]>JSON.parse(iv)).length > 0) {
                    hfa = true;
                }
            }
        });
        return hfa;
    }

    private hideFileOption(category: string, format: string) {
        let hide = false;
        if (this.dhf_config && this.dhf_config.hideFileFormats) {
            $.each(this.dhf_config.hideFileFormats, function (hfoidx, hfo) {
                if (hfo.category === category && hfo.format === format) {
                    hide = true;
                }
            });
        }
        return hide;
    }

    private renderControlsSIGN(options: IItemControlOptions, body: JQuery, controls: IDHFControlDefinition[]) {
        if (options.isItem) {
            if (options.controlState !== ControlState.Print && options.controlState !== ControlState.Tooltip) {
                let container = $("<div class='controlContainer'>").appendTo(body);
                $("<div style='padding: 12px 0' class='baseControlHelp'>Manage Documents</div>").appendTo(container);
                let rt = $("<div class='baseControl rowFlex'>").appendTo(container);
                this.showPreviewSign(rt, options);
                this.renderSignDownload(options, rt, controls);
            }
        } else {
            if (options.controlState === ControlState.FormEdit) {
                body.append($("<span class='baseControlHelp'>Tools</span>"));
                let folderEdit = $("<div class='hidden-print baseControl'></div>");
                body.append(folderEdit);
                let createTools = new ItemCreationTools();
                createTools.renderButtons({
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    parent: options.item.id,
                    control: folderEdit,
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    linkTypes: [{ type: options.item.type, name: "Folder", buttonName: "Create", folder: true }],
                    type: options.type,
                    dontOpenNewItem: false,
                });
            }
        }
    }

    private getFilterCtrl(type: string, allControls: IControlDefinition[]) {
        for (let idx = 0; idx < allControls.length; idx++) {
            if (
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                globalMatrix.ItemConfig.getFieldType(type, allControls[idx].fieldId) ===
                FieldDescriptions.Field_docFilter
            ) {
                return allControls[idx].control;
            }
        }
        return null;
    }
    private async getFilterValue(filterControl: JQuery) {
        if (filterControl) {
            let v = ml.JSON.fromString(await filterControl.getController().getValueAsync());
            if (v.status === "ok") {
                let list = (<string[]>v.value).join(",");
                return list;
            }
        }
        return null;
    }

    private showPreviewDoc(anchor: JQuery, id: string, controls: IDHFControlDefinition[]) {
        let that = this;
        if (globalMatrix.ItemConfig.getTimeWarp()) {
            return;
        }
        // handle ACL
        if (!globalMatrix.ItemConfig.canReport("DOC")) {
            return;
        }

        $("<button class='btn btn-success'> Preview </button>")
            .appendTo(anchor)
            .click(async () => {
                let frame = $(`<div class="inlineViewer">`).appendTo("body");
                let spinning = ml.UI.getSpinningWait("loading document: <span class='dprogress'></span> done").appendTo(
                    frame,
                );
                $(`<div class='message_close_big'><i class='fal fa-window-close'></i></div>`)
                    .appendTo(frame)
                    .click(function () {
                        frame.remove();
                    });

                let params: IReportOptions = { format: "pdf", inline: true };
                let labelFilter = await that.getFilterValue(<any>that.getFilterCtrl("DOC", controls));
                if (labelFilter) {
                    params.filter = labelFilter;
                }

                ml.ReportGenerator.SaveAndCreate(
                    id,
                    params,
                    "viewing pdf",
                    (lastCreatedObject: any, path: string) => {
                        spinning.remove();
                        frame.append(`<embed src="${path + "?disposition=inline"}" width="100%" height="100%">`);
                    },
                    (error: string) => {
                        spinning.remove();
                        frame.append(`document creation failed with error: ${error}`);
                    },
                    (progress: number) => {
                        $(".dprogress", spinning).html(progress + "%");
                    },
                );
            });
    }

    private showPreviewSign(anchor: JQuery, options: IItemControlOptions) {
        let that = this;
        if (globalMatrix.ItemConfig.getTimeWarp()) {
            return;
        }

        if (!globalMatrix.ItemConfig.canReport("SIGN")) {
            return;
        }

        $("<button class='btn btn-success  inlineView' title='View'>Preview</button>")
            .appendTo(anchor)
            .click(() => {
                let frame = $(`<div class="inlineViewer">`).appendTo("body");
                let spinning = ml.UI.getSpinningWait("loading document: <span class='dprogress'></span> done").appendTo(
                    frame,
                );
                $(`<div class='message_close_big'><i class='fal fa-window-close'></i></div>`)
                    .appendTo(frame)
                    .click(function () {
                        frame.remove();
                    });

                let params: IReportOptions = { format: "pdf", inline: true };
                let signCache = that.getCached(options);

                if (signCache["pdf"]) {
                    // signCache["pdf"] is a file attachment url like "5745?key\u003dkey_2d6a3cju4dr5qnm9ogvstfqkn2"

                    let clean = signCache["pdf"].replace("\u003d", "=");

                    let path =
                        globalMatrix.matrixRestUrl +
                        "/" +
                        matrixSession.getProject() +
                        "/file/" +
                        clean +
                        "&disposition=inline";

                    spinning.remove();
                    frame.append(`<embed src="${path}" width="100%" height="100%">`);
                } else {
                    if (app.getNeedsSave()) {
                        ml.UI.showError("Save Item", "Please save before downloading the signed item.");
                        frame.remove();
                        return;
                    }
                    ml.ReportGenerator.SaveAndCreate(
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        options.id,
                        params,
                        "viewing pdf",
                        (lastCreatedObject: any, path: string) => {
                            spinning.remove();
                            frame.append(`<embed src="${path}?disposition=inline" width="100%" height="100%">`);
                        },
                        (error: string) => {
                            spinning.remove();
                            frame.append(`document creation failed with error: ${error}`);
                        },
                        (progress: number) => {
                            $(".dprogress", spinning).html(progress + "%");
                        },
                    );
                }
            });
    }

    private renderControlsDOC(options: IItemControlOptions, body: JQuery, controls: IDHFControlDefinition[]) {
        let that = this;

        let category = options.type; // default type for creation

        if (options.item && options.item.type) {
            category = options.item.type;
        }

        let id = options.item ? options.item.id : "";

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        let config = globalMatrix.ItemConfig.getItemConfiguration(options.isItem ? category : "FOLDER");

        let showTools = true;
        if (
            options.controlState === ControlState.Print ||
            options.controlState === ControlState.Tooltip ||
            options.controlState === ControlState.DialogCreate ||
            options.controlState === ControlState.HistoryView ||
            options.controlState === ControlState.Zen
        ) {
            showTools = false;
        }

        let signedDocuments: JQuery; // in case of signed report this is a list of instances (SIGN) created

        // main logic to create UI
        if (options.isItem) {
            if (options.controlState === ControlState.DialogCreate) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                if (this.dhf_config.categories.documentForms.indexOf(category) !== -1) {
                    // start wizard to create new controlled doc
                    RenderDocDefinitionWizard(body, controls);
                }
            } else if (showTools) {
                let container = $("<div class='controlContainer'>").appendTo(body);
                // show tools to preview and generate signed doc
                $("<div style='padding: 12px 0;' class='baseControlHelp'>Manage Documents</div>").appendTo(container);

                let rt = $("<div style='margin-bottom:12px' class='baseControl rowFlex'>").appendTo(container);

                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                that.showPreviewDoc(rt, options.id, controls);

                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                if (globalMatrix.ItemConfig.canReport(options.type)) {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    rt.append(createPreviewReportButton(category, controls, options.item));

                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    rt.append(createExcelButton(category, controls, options.item));

                    this.createCompareButton(rt, options, controls);
                    this.createCompareButtonWord(rt, options);

                    if (matrixSession.isEditor()) {
                        // can create signed doc
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        let dhfConfigTool = createSignatureButton(category, controls, this.getArchiveButtonName());
                        rt.append(dhfConfigTool);

                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        if (app.canEditItem(options.item) && !options.locked) {
                            let x = $("<div class='btn-group'>");
                            rt.append(x);

                            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                            this.appendConfigTool(x, config, options.item);
                        }
                    }
                } else {
                    rt.append("<div class='inlineHelp'>You have no rights to download documents</div>");
                }

                let linkTypes: ILinkCategories[] = [];
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                $.each(this.dhf_config.categories.documentSigned, function (idx, cat) {
                    linkTypes.push({ type: cat });
                });
                // show previously created signed docs
                signedDocuments = $("<div class='controlContainer' >").linkCollection({
                    // TODO(modules): I added item to the definition -- error?
                    item: options.item,
                    parameter: {
                        linkTypes: linkTypes,
                        readonly: true,
                        none: "no signed documents have been created so far",
                    },
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    fieldValue: options.item.downLinks,
                    help: "Previously created signed documents",
                });
                body.append(signedDocuments);
            }
        } else if (options.controlState === ControlState.FormEdit) {
            body.append($("<span class='baseControlHelp'>Tools</span>"));
            let folderEdit = $("<div class='hidden-print baseControl'></div>");
            body.append(folderEdit);
            let createTools = new ItemCreationTools();
            let createButtons: ILinkType[] = [
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                { type: options.item.type, name: "Folder", folder: true },
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                { type: options.item.type, name: "DOCX", import: true, buttonName: "Import" },
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                { type: options.item.type, name: globalMatrix.ItemConfig.getCategoryLabel(category) },
            ];

            createTools.renderButtons({
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                parent: options.item.id,
                control: folderEdit,
                linkTypes: createButtons,
                dontOpenNewItem: false,
            });

            let templateProjects = <ITemplateProjects>(
                matrixSession.getCustomerSettingJSON(MarkAsTemplateImpl.PROJECT_SETTING_Projects)
            );
            if (templateProjects && templateProjects.projects && templateProjects.projects.length) {
                let accessibleProjects = matrixSession.getProjectList(true).map(function (ap) {
                    return ap.shortLabel;
                });
                let templateSources = templateProjects.projects.filter(function (p) {
                    return accessibleProjects.indexOf(p) != -1;
                });
                let templateErrors = templateProjects.projects.filter(function (p) {
                    return accessibleProjects.indexOf(p) == -1;
                });

                appendTemplates(folderEdit, templateSources, 0, [], templateErrors);
            }
        }

        function appendTemplates(
            folderEdit: JQuery,
            projects: string[],
            next: number,
            templateOptions: IDropDownButtonOption[],
            errors: string[],
        ) {
            if (next >= projects.length) {
                if (templateOptions.length) {
                    let ddb = ml.UI.createDropDownButton(
                        "Create from <b>Template</b>",
                        templateOptions,
                        false,
                        "createByTemplateId",
                        true,
                    );
                    folderEdit.append(ddb);
                    ddb.addClass("ddAlignBottom");
                }
                if (errors.length) {
                    folderEdit.append(
                        $("<div style='padding: 12px 0;font-style: italic;'>").html(
                            "There are also templates in some projects where you have no read access: " + errors.join(),
                        ),
                    );
                }
                return;
            }

            app.readSettingJSONAsync(MarkAsTemplateImpl.PROJECT_SETTING, projects[next], true)
                .done(function (templates: IPasteSourceSetting) {
                    if (templates && templates.templates) {
                        // find templates for this project

                        $.each(templates.templates, function (idx, ps) {
                            if (ps.canUseIn.indexOf(matrixSession.getProject()) != -1) {
                                templateOptions.push({
                                    name: ps.fromProject + "/" + ps.fromSign + " " + ps.fromName,
                                    click: function () {
                                        that.createFromTemplate(ps.fromProject, ps.fromSign, ps.fromName, <any>{
                                            parent: app.getCurrentItemId(),
                                        });
                                    },
                                });
                            }
                        });
                    }
                    appendTemplates(folderEdit, projects, next + 1, templateOptions, errors);
                })
                .fail(function () {
                    ml.UI.hideError();
                    errors.push(projects[next]);
                    appendTemplates(folderEdit, projects, next + 1, templateOptions, errors);
                });
        }

        function addFileOption(
            type: string,
            controls: IDHFControlDefinition[],
            formats: IDHFFileOption[],
            name: string,
            format: string,
        ) {
            let pos = mDHF.getDefaultFormat(type) === format ? 0 : formats.length;
            formats.splice(pos, 0, {
                name: name,
                click: function () {
                    that.createConfirmedDownloadOrSIGN(controls, async function () {
                        ml.ReportGenerator.CreateDoc(
                            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                            id,
                            { format: format },
                            await that.getFilterValue(<any>that.getFilterCtrl(type, controls)),
                        );
                    });
                    return false;
                },
            });
        }

        function createPreviewReportButton(type: string, controls: IDHFControlDefinition[], item: IItem) {
            let formats: IDHFFileOption[] = [];

            if (!that.hideFileOption(type, "docx")) {
                addFileOption(type, controls, formats, "Word", "docx");
                if (that.hasFileAttachments(type, controls, item)) {
                    addFileOption(type, controls, formats, "Word with attachments", "zipdocx");
                }
            }

            if (!that.hideFileOption(type, "pdf")) {
                addFileOption(type, controls, formats, "PDF", "pdf");
                if (that.hasFileAttachments(type, controls, item)) {
                    addFileOption(type, controls, formats, "PDF with attachments", "zippdf");
                }
            }

            if (!that.hideFileOption(type, "html")) {
                addFileOption(type, controls, formats, "HTML", "html");
            }

            if (that.hasPackage(controls, item)) {
                addFileOption(type, controls, formats, "Package", "package");
            }

            return ml.UI.createDropDownButton("Download document", formats, false, "btnDownload");
        }

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        function createExcelButton(type: string, controls: IDHFControlDefinition[], item: IItem): JQuery {
            if (!that.hideFileOption(type, "xlsx")) {
                if (PluginManagerDocuments.hasCustomSection(item)) {
                    return PluginManagerDocuments.excelButtonControl(() => {
                        that.createConfirmedDownloadOrSIGN(controls, async function () {
                            ml.ReportGenerator.CreateDoc(
                                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                id,
                                { format: "xlsx" },
                                await that.getFilterValue(<any>that.getFilterCtrl(type, controls)),
                            );
                        });
                        return false;
                    });
                }
            }
        }

        function createSignatureButton(type: string, controls: IDHFControlDefinition[], btnName: string) {
            let filterControl = that.getFilterCtrl(type, controls);

            let formats = [
                {
                    name: "SIGN",
                    click: function () {
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        that.createSIGN(id, signedDocuments, filterControl as JQuery, controls);
                    },
                },
            ];

            return ml.UI.createDropDownButton(btnName, formats, false, "archiveBtnId");
        }

        function RenderDocDefinitionWizard(body: JQuery, controls: IDHFControlDefinition[]) {
            let dlgInner = body.parent();
            let dlgOuter = dlgInner.parent();
            let createButton: JQuery;
            let nextButton = $(
                '<button type="button" class="btnDoIt2 ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only btn" role="button" aria-disabled="false">',
            );

            // hide all controls
            for (let idx = 0; idx < controls.length; idx++) {
                (controls[idx].control as any).hide();
            }

            window.setTimeout(function () {
                let niceSize = ml.UI.getNiceDialogSize(730, 550);
                dlgOuter.dialog("option", "height", niceSize.height);
                dlgOuter.dialog("option", "width", niceSize.width);
                $(".dlgCreateMultiple", dlgOuter.parent()).hide();
                nextButton.append('<span class="ui-button-text">Next</span>');
                createButton = $(".ui-dialog-buttonpane button:contains('Create')", app.dlgForm.parent());
                createButton.hide();
                createButton.after(nextButton);
            }, 1);

            function updateControls(wizardData: IDHFWizardData) {
                //TODO : Move this to business logic.
                let dhfItemIdx = 0;
                for (let idx = 0; idx < controls.length; idx++) {
                    if (
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        globalMatrix.ItemConfig.getFieldType(category, controls[idx].fieldId) ===
                        FieldDescriptions.Field_dhf
                    ) {
                        // this has been configured before...
                        if (dhfItemIdx < wizardData.dhfItems.length) {
                            (controls[idx].control as any)
                                .getController()
                                .setValue(JSON.stringify(wizardData.dhfItems[dhfItemIdx]));
                            dhfItemIdx++;
                        } else if (dhfItemIdx === wizardData.dhfItems.length) {
                            let dhfField = {
                                type: "document_options",
                                name: "Document Options",
                                fieldValue: "",
                                ctrlConfig: { auto_number: true, omit_title: true },
                            };
                            (controls[idx].control as any).getController().setValue(JSON.stringify(dhfField));

                            dhfItemIdx++;
                        }
                    } else if (controls[idx].name === DOC_NUM_NAME) {
                        (controls[idx].control as any).getController().setValue(wizardData.dhfNumber);
                    } else if (controls[idx].fieldType === FieldDescriptions.Field_guid) {
                        (controls[idx].control as any).getController().setValue(wizardData.dhfGUID);
                    } else if (controls[idx].name === "Title") {
                        (controls[idx].control as any).getController().setValue(wizardData.dhfName, true);
                    } else if (controls[idx].name === "reportId") {
                        (controls[idx].control as any).getController().setValue("dhf_generic");
                    }
                }
                createButton.trigger("click");
            }

            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            that.showDHFCreateWizard(category, dlgInner, nextButton, updateControls, false);
        }
    }

    private static hasCustomSection(item: IItem): boolean {
        for (const keyString in item) {
            const key = parseInt(keyString);
            if (isNaN(key)) {
                // skip, something's wrong with the key
            } else {
                const value = item[key];
                if (typeof value === "string") {
                    try {
                        const section = JSON.parse(value);
                        if (section != null && section.type === "CustomSection") {
                            return true;
                        }
                    } catch (e) {
                        // Not JSON, next...
                    }
                }
            }
        }
        return false;
    }

    private createSIGN(id: string, signedDocuments: JQuery, filterControl: JQuery, controls: IDHFControlDefinition[]) {
        let that = this;

        function fill0(sn: number, len: number) {
            let sns = sn.toString();
            while (sns.length < len) {
                sns = "0" + sns;
            }

            return sns;
        }

        function last2(x: string) {
            return x.substr(x.length - 2);
        }

        let versionFromTable = "";
        async function createSignDoc(signatures: string[]) {
            let transfer: IReportTransferField[] = [];

            // build name of signed doc
            let hasTitle = false;
            let hasHideTitle = false;
            let signName = "";
            let signDocNumber = "";
            // if document has a DOC NUMBER field, create the title of the SIGN
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            let dnid = globalMatrix.ItemConfig.getFieldId(that.item.type, DOC_NUM_NAME);
            if ((<IGenericMap>that.item)[dnid] && (<IGenericMap>that.item)[dnid] !== "no id") {
                let docNumber = <string>(<IGenericMap>that.item)[dnid];
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                let sn = parseInt(that.item.id.split("-")[1]);

                hasTitle = docNumber.indexOf("_title_") !== -1;
                hasHideTitle = docNumber.indexOf("_notitle_") !== -1;

                signDocNumber = docNumber
                    .replace("_notitle_", "") // just a dummy placeholder to ignore the DOC's title
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    .replace("_id_", that.item.id)
                    .replace("_revision_", versionFromTable)
                    .replace("_serial_", fill0(sn, 3)) // serial XX -> 0XX
                    .replace("_serial1_", fill0(sn, 1)) // serial XX -> XX
                    .replace("_serial2_", fill0(sn, 2)) // serial XX -> XX
                    .replace("_serial3_", fill0(sn, 3)) // serial XX -> 0XX
                    .replace("_serial4_", fill0(sn, 4)) // serial XX -> 00XX
                    .replace("_project_", matrixSession.getProject())
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    .replace("_title_", that.item.title)
                    .replace("_YYYY_", new Date().getFullYear().toString())
                    .replace("_YY_", last2("00" + (new Date().getFullYear() - 2000).toString()))
                    .replace("_MM_", last2("00" + (new Date().getMonth() + 1).toString()))
                    .replace("_DD_", last2("00" + new Date().getDate().toString()))
                    .replace(
                        "_YYYYMMDD_",
                        new Date().getFullYear().toString() +
                            last2("00" + (new Date().getMonth() + 1).toString()) +
                            last2("00" + new Date().getDate().toString()),
                    );

                signName = signDocNumber;
            }
            // add / use item title to SIGN item name
            if (!hasTitle && !hasHideTitle) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                signName = signName ? signName + " " + that.item.title : that.item.title;
            }

            // find values which need to be transferred (they need to have same label and copyfromdoc set to true
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            let signConfig = globalMatrix.ItemConfig.getItemConfiguration(that.dhf_config.categories.signAs);
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            let docConfig = globalMatrix.ItemConfig.getItemConfiguration(that.item.type);
            $.each(signConfig.fieldList, function (configIdx, config) {
                if (config.parameterJson && config.parameterJson.copyfromdoc) {
                    let toId = config.id;
                    $.each(docConfig.fieldList, function (docIdx, docConfig) {
                        if (docConfig.label.toLowerCase() === config.label.toLowerCase()) {
                            let fromId = docConfig.id;
                            transfer.push({ fromId: fromId.toString(), toId: toId.toString() });
                        }
                    });
                }
            });

            ml.ReportGenerator.CreateSignedDoc(
                id,
                signatures,
                signedDocuments,
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                await that.getFilterValue(filterControl),
                signName,
                transfer,
                [],
                function (signID) {
                    let signatureInfo = that.getSignatureInfo();
                },
            );
        }

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        let revisionTableControl: IDHFControlDefinition = null;
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        let revisionTableColumn: string = null;
        for (let control of controls) {
            if (
                control.dhfValue &&
                control.dhfValue &&
                control.dhfValue.ctrlConfig &&
                (<IDhfTableOptions>control.dhfValue.ctrlConfig).columns
            ) {
                for (let column of (<IDhfTableOptions>control.dhfValue.ctrlConfig).columns) {
                    if (column.columnType == "type12") {
                        revisionTableControl = control;
                        revisionTableColumn = column.field;
                    }
                }
            }
        }

        if (revisionTableControl) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            that.verifyVersionInfo(id, revisionTableControl, revisionTableColumn).done((version: string) => {
                versionFromTable = version;

                that.verifyVersionTableComplete(revisionTableControl).done(() => {
                    that.createConfirmedDownloadOrSIGN(controls, createSignDoc);
                });
            });
        } else {
            that.createConfirmedDownloadOrSIGN(controls, createSignDoc);
        }
    }

    // make sure all values are set
    private verifyVersionTableComplete(revisionTableControl: IDHFControlDefinition) {
        let that = this;
        let res = $.Deferred();
        let table = <
            IStringMap[] // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        >(revisionTableControl.dhfValue.fieldValue ? JSON.parse(revisionTableControl.dhfValue.fieldValue) : null);
        if (!table || !table.length) {
            ml.UI.showConfirm(
                -1,
                {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    title: `The table '${revisionTableControl.dhfValue.name}' with the revision field is empty!`,
                    ok: "Create SIGN",
                    nok: "Cancel",
                },
                () => {
                    res.resolve("");
                },
                () => {
                    res.reject();
                },
            );
            return res;
        } else {
            let lastRow = table[table.length - 1];
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            for (let column of (<IDhfTableOptions>revisionTableControl.dhfValue.ctrlConfig).columns) {
                if (!lastRow[column.field]) {
                    ml.UI.showConfirm(
                        -1,
                        {
                            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                            title: `The field '${column.name}' in table '${revisionTableControl.dhfValue.name}' is empty!`,
                            ok: "Create SIGN",
                            nok: "Cancel",
                        },
                        () => {
                            res.resolve("");
                        },
                        () => {
                            res.reject();
                        },
                    );
                    return res;
                }
            }
        }
        // no problem!
        res.resolve();
        return res;
    }

    // compares the current Revision from the table with the one from the SIGN with the highest id
    private verifyVersionInfo(docId: string, revisionTableControl: IDHFControlDefinition, revisionTableColumn: string) {
        let that = this;
        let res: JQueryDeferred<string> = $.Deferred();
        let currentRevision = revisionTableControl.dhfValue
            ? // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
              this.getVersionFromTable(revisionTableControl.dhfValue.fieldValue, revisionTableColumn)
            : "";
        let revisionTable = revisionTableControl.dhfValue ? revisionTableControl.dhfValue.name : "";
        if (!currentRevision) {
            ml.UI.showConfirm(
                -1,
                {
                    title: `There is no revision defined in table '${revisionTable}'!`,
                    ok: "Create SIGN",
                    nok: "Cancel",
                },
                () => {
                    res.resolve("");
                },
                () => {
                    res.reject();
                },
            );
        } else {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            this.getSigns(docId).done((otherSIGNs: string[]) => {
                if (!otherSIGNs.length) {
                    // first sign... to be created so all is good
                    res.resolve(currentRevision);
                } else {
                    let highest = ml.Item.parseRef(otherSIGNs[0]).number;
                    for (let next of otherSIGNs) {
                        highest = Math.max(ml.Item.parseRef(next).number, highest);
                    }
                    let highestSIGNId = ml.Item.parseRef(otherSIGNs[0]).type + "-" + highest;
                    app.getItemAsync(highestSIGNId).done(function (signItem) {
                        // get the date the sign was created
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        let signCreationDate = new Date(signItem.history[signItem.history.length - 1].date);

                        // go through history of DOC and find the version which existed just before creating the SIGN
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        let docRevisionBeforeSign = that.item.history.length - 1;
                        while (
                            docRevisionBeforeSign >= 0 &&
                            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                            new Date(that.item.history[docRevisionBeforeSign].date) <= signCreationDate
                        ) {
                            docRevisionBeforeSign--;
                        }

                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        docRevisionBeforeSign = that.item.history[docRevisionBeforeSign + 1].version;

                        app.getItemAsync(docId, docRevisionBeforeSign)
                            .done(function (docBeforeSign) {
                                // now we have the old doc. The revision table might anywhere in one of the properties
                                let previousRevision = "";
                                for (let prop in docBeforeSign) {
                                    let valStr = docBeforeSign[prop];
                                    if (valStr && valStr.length > 2 && valStr[0] == "{") {
                                        try {
                                            let valJson = JSON.parse(valStr); // this might be a table
                                            if (
                                                valJson.ctrlConfig &&
                                                valJson.ctrlConfig.columns &&
                                                valJson.ctrlConfig.columns.length
                                            ) {
                                                // pretty sure this is a table
                                                let revisionColumns = valJson.ctrlConfig.columns.filter(
                                                    (column: { columnType: string }) => column.columnType == "type12",
                                                );
                                                if (revisionColumns.length > 0) {
                                                    // this is a table with a revision column! we take this

                                                    let valFieldStr = valJson.fieldValue; // check if there is a value stored in the table
                                                    if (
                                                        valFieldStr &&
                                                        valFieldStr.length > 2 &&
                                                        valFieldStr[0] == "["
                                                    ) {
                                                        previousRevision = that.getVersionFromTable(
                                                            valFieldStr,
                                                            revisionColumns[0].field,
                                                        );
                                                    }
                                                }
                                            }
                                        } catch (e) {}
                                    }
                                }

                                if (currentRevision != previousRevision) {
                                    // we assume all is good...
                                    res.resolve(currentRevision);
                                    return res;
                                } else {
                                    ml.UI.showConfirm(
                                        -1,
                                        {
                                            title: `The current revision in table '${revisionTable}' is the same as it was in the last created SIGN!`,
                                            ok: "Create SIGN",
                                            nok: "Cancel",
                                        },
                                        () => {
                                            res.resolve(currentRevision);
                                            return res;
                                        },
                                        () => {
                                            res.reject();
                                            return res;
                                        },
                                    );
                                }
                            })
                            .fail(function (error) {
                                res.reject(error);
                            });
                    });
                }
            });
        }
        return res;
    }

    private getVersionFromTable(table: string, column: string) {
        if (!table) return "";

        let currentTable = <IStringMap[]>JSON.parse(table);
        if (currentTable.length) {
            let lastRow = currentTable[currentTable.length - 1];
            return lastRow[column];
        }

        return "";
    }

    private hasPackage(controls: IDHFControlDefinition[], item: any) {
        for (let idx = 0; idx < controls.length; idx++) {
            if (
                controls[idx].fieldId &&
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                item[controls[idx].fieldId] &&
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                globalMatrix.ItemConfig.getFieldType(item.type, controls[idx].fieldId) === "dhf"
            ) {
                // this is a configured dhf field
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                let parsed = ml.JSON.fromString(item[controls[idx].fieldId]);
                if (parsed.status == "ok") {
                    if ((<IDHFControlDefinitionValue>parsed.value).type == "package") {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    private createConfirmedDownloadOrSIGN(controls: IDHFControlDefinition[], createFct: Function) {
        let that = this;

        if (!that.docHasContent(controls)) {
            return;
        }

        // check if the document contains smart text and the user needs to verify that it's correct
        let config = globalMatrix.ItemConfig.getSmartText();
        if (!config || !config.replacements) {
            config = { replacements: [] };
        }
        let table = $("<table class='table table-bordered text-left'>");
        $.each(config.replacements, function (optIdx, opt) {
            if (opt.warn) {
                let tr = $("<tr>");
                tr.append($("<td>").html(opt.what));
                tr.append($("<td>").html(opt.with));
                table.append(tr);
            }
        });
        let dateNow = new Date().toDateString();

        let allSignatures = that.getSignatures(controls);
        let signatures = allSignatures.filter(function (user) {
            return user && globalMatrix.ItemConfig.getUserIds().indexOf(user) != -1;
        });
        let msgs: string[] = [];
        if (signatures.length != allSignatures.length) {
            msgs.push("There are missing users or users without access in a signature table!");
        }
        if ($(".refreshNeeded:visible").length != 0) {
            msgs.push("Some item selections based on search expressions have not been updated!");
        }
        if (
            $("tr", table).length > 0 &&
            (that.wasInformedToday !== dateNow || that.wasInformedTodayAbout !== table.html())
        ) {
            msgs.push(
                "Verify whether the following smart text blocks are correct<br/>" +
                    $("<div>").append(table.clone()).html(),
            );
        }
        if (msgs.length != 0) {
            ml.UI.showConfirm(
                6,
                { title: msgs.join("<br><br>"), ok: "Yes, continue" },
                function () {
                    // remember that user was informed about something today
                    that.wasInformedToday = dateNow;
                    that.wasInformedTodayAbout = table.html();
                    createFct(signatures);
                },
                function () {},
            );
        } else {
            createFct(signatures);
        }
    }

    // return number of required signatures (in SIGN docs) and number of given signatures
    private getSignatureInfo(item?: IItem): ISignaturesInfo {
        if (!item) {
            item = this.item;
        }

        return DocBaseImpl.readSignatureInfo(item);
    }

    private getCached(options: IItemControlOptions) {
        let signCache: IStringMap = {};
        let signCacheFields = globalMatrix.ItemConfig.getFieldsOfType("signCache", options.type);
        if (signCacheFields.length == 1) {
            let signCacheField = (<IStringMap>options.item)[signCacheFields[0].field.id];
            if (signCacheField) {
                let cached = <IUploadedFileInfo[]>JSON.parse(signCacheField);
                $.each(cached, function (idx, cache) {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    let key = <any>cache.fileName.split(".").pop().toLowerCase();
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    signCache[key] = cache.fileId;
                });
            }
        }
        return signCache;
    }

    private renderSignDownload(options: IItemControlOptions, body: JQuery, controls: IDHFControlDefinition[]) {
        let that = this;

        // if there's a control to cache signs, get it (note at this time it's not yet rendered, so no need to read it's content)
        let signCache: IStringMap = this.getCached(options);

        // the sign cache is now a lookup per extensions, e.g. signCache["pdf"] = "5745?key\u003dkey_2d6a3cju4dr5qnm9ogvstfqkn2";

        if (
            options.controlState === ControlState.Print ||
            options.controlState === ControlState.HistoryView ||
            options.controlState === ControlState.Zen ||
            options.controlState === ControlState.Tooltip
        ) {
            return;
        }
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        let defaultFormat = mDHF.getDefaultFormat(options.type);
        let formats: IDHFFileOption[] = [];

        function addOption(format: string, name: string, signCache: IStringMap) {
            let pos = defaultFormat === format ? 0 : formats.length;
            formats.splice(pos, 0, {
                name: name,
                click: function () {
                    if (signCache[format]) {
                        startDownloadCached(signCache[format]);
                    } else {
                        // create and download the file
                        startDownload(format);
                    }
                },
            });
        }

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        if (!that.hideFileOption(options.type, "docx")) {
            addOption("docx", "Word", signCache);
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            if (this.hasFileAttachments(options.type, controls, options.item)) {
                addOption("zipdocx", "Word with attachments", {});
            }
        }

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        if (!that.hideFileOption(options.type, "pdf")) {
            addOption("pdf", "PDF", signCache);
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            if (this.hasFileAttachments(options.type, controls, options.item)) {
                addOption("zippdf", "PDF with attachments", {});
            }
        }
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        if (!that.hideFileOption(options.type, "html")) {
            addOption("html", "HTML", signCache);
        }
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        if (options.item.docHasPackage) {
            addOption("package", "Package", {});
        }

        let signatureInfo = this.getSignatureInfo();

        // default buttons
        if (signatureInfo.missingSignatures) {
            const request = $(
                "<button class='btn btn-default' style='margin-right:5px !important'>Request Signatures</button>",
            );
            body.append(request);
            request.click(function () {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                let message = ml.Mail.getCannedMessage("please_sign", "", options.item.id);
                ml.Mail.sendMailDlg(
                    signatureInfo.missing.join(","),
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    null,
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    "Please review and sign " + options.item.id,
                    message,
                    "",
                );
            });
        } else {
            let markAsTemplates = globalMatrix.ItemConfig.getFieldsOfType("markAsTemplate", this.item.type);

            let can = "release_note";
            let subject = "A document has been released ";
            let button = "Send Release Mail";
            let users = globalMatrix.ItemConfig.getUserIds();

            if (
                markAsTemplates.length == 1 &&
                MarkAsTemplateImpl.getRequiredApprovals((<any>this.item)[markAsTemplates[0].field.id]).length > 0
            ) {
                can = "approve_note";
                subject = "Approval for template ";
                button = "Ask for approval ";
                users = MarkAsTemplateImpl.getRequiredApprovals((<any>this.item)[markAsTemplates[0].field.id]);
            }
            // normal document which has been approved and completely signed
            const request = $(
                "<button class='btn btn-default' style='margin-right:5px !important'>" + button + "</button>",
            );
            body.append(request);
            request.click(function () {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                let message = ml.Mail.getCannedMessage(can, "", options.item.id);

                let to = users.filter(function (user) {
                    return user != matrixSession.getUser();
                });

                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                ml.Mail.sendMailDlg(to.join(), null, subject + options.item.id, message, "");
            });
        }

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        if (globalMatrix.ItemConfig.canReport(options.type)) {
            let download = ml.UI.createDropDownButton("Download document", formats, false, "btnDownload");
            body.append(download);

            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            if (!that.hideFileOption(options.type, "xlsx")) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                that.getBaseDOCofSIGN(matrixSession.getProject(), options.item).done((doc) => {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    if (PluginManagerDocuments.hasCustomSection(doc)) {
                        download.after(
                            PluginManagerDocuments.excelButtonControl(() => {
                                startDownload("xlsx");
                                return false;
                            }),
                        );
                    }
                });
            }
        } else {
            body.append("<div class='inlineHelp'>You have no rights to download documents</div>");
        }

        this.createCompareButton(body, options, controls);
        this.createCompareButtonWord(body, options);

        function startDownload(format: string) {
            // MATRIX-3721 if user modified title (or something else), ask to save first
            if (app.getNeedsSave()) {
                ml.UI.showError("Save Item", "Please save before downloading the signed item.");
                return;
            }

            // MATRIX-4863 wrap in function for use in async download
            function performDownload() {
                // MATRIX-3721 force the item to be readonly, this prevents tampering while the server (silently creates a new version)
                // unless the user selects something else and comes back (that we need to catch somewhere else...)
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                matrixApplicationUI.forceReadonly(options.item.id);

                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                ml.ReportGenerator.DownloadSignedDoc(options.item.id, { format: format });

                let signatureInfo = that.getSignatureInfo();

                if (signatureInfo.missingSignatures && that.dhf_config.warnMissingSign) {
                    ml.UI.showAck(-1, "There are still some signatures missing");
                }
            }

            if (format == "xlsx") {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                that.getBaseDOCofSIGN(matrixSession.getProject(), options.item)
                    .done((doc) => {
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        if (PluginManagerDocuments.hasCustomSection(doc)) {
                            performDownload();
                        } else {
                            ml.UI.showError(
                                "No Custom section",
                                "Only DOC/SIGN with a Custom section can be downloaded as an Excel file.",
                            );
                        }
                    })
                    .fail(() => {
                        ml.UI.showError("Error checking DOC", "Unable to load original DOC to check for Excel data");
                    });
            } else {
                performDownload();
            }
        }
        function startDownloadCached(target: string) {
            // target is a file attachment url like "5745?key\u003dkey_2d6a3cju4dr5qnm9ogvstfqkn2"
            let clean = target.replace("\u003d", "=");
            let param = clean.split("?")[1].split("="); // ["key", "key_2d6a3cju4dr5qnm9ogvstfqkn2"]
            let params: IStringMap = {};
            params[param[0]] = param[1]; // params= {key:"key_2d6a3cju4dr5qnm9ogvstfqkn2"}

            app.downloadFromUrl("file/" + clean.split("?")[0], params); // 5745, para,s
        }
    }

    private getSigns(thisDoc: string): JQueryDeferred<string[]> {
        let res = $.Deferred();
        let otherSigns: string[] = [];
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        app.getItemAsync(thisDoc).done(function (doc: IItemGet) {
            $.each(doc.downLinks, function (idx, sign) {
                let ref = ml.Item.parseRef(sign.to).id;
                otherSigns.push(ref);
            });
            res.resolve(otherSigns);
        });
        return <any>res;
    }

    private createCompareButtonWord(rt: JQuery, options: IItemControlOptions) {
        let placeHolderRL = $("<div class='btnCompareWord'>");
        rt.append(placeHolderRL);

        let versionSelectRL: IDropDownButtonOption[] = [];
        // find the mother DOC or use as mother DOC if it is already a doc
        if (options.type === "SIGN" && options.item?.upLinkList && options.item.upLinkList.length > 0) {
            let ref = ml.Item.parseRef(options.item.upLinkList[0].itemRef).id;
            let signDocument = options.id;
            if (signDocument !== undefined) {
                versionSelectRL.push({
                    name: ref,
                    click: () => {
                        this.redlineDocumentsWord(signDocument!.toString(), ref);
                    },
                });

                this.getSigns(ref).done((otherSigns) => {
                    if (otherSigns === undefined) {
                        return;
                    }
                    let otherSignFiltered = otherSigns.filter((sign) => sign !== signDocument);
                    this.createCompareButtonCommon(placeHolderRL, versionSelectRL, signDocument!, otherSignFiltered);
                });
            }
        } else {
            let document = options.id;
            if (document !== undefined) {
                this.getSigns(document).done((otherSigns) => {
                    if (otherSigns === undefined) {
                        return;
                    }
                    this.createCompareButtonCommon(placeHolderRL, versionSelectRL, document!, otherSigns);
                });
            }
        }
    }

    private createCompareButtonCommon(
        placeHolderRL: JQuery,
        versionSelectRL: IDropDownButtonOption[],
        sourceDoc: string,
        otherSigns: string[],
    ) {
        $.each(otherSigns, (id, otherSign: string) => {
            versionSelectRL.splice(0, 0, {
                name: otherSign + " " + app.getItemTitle(otherSign),
                click: () => {
                    this.redlineDocumentsWord(otherSign, sourceDoc!.toString());
                },
            });
        });
        if (versionSelectRL.length === 1) {
            placeHolderRL.replaceWith(
                ml.UI.createDropDownButton(
                    "Redline against " + versionSelectRL[0].name + " (DOCX)",
                    versionSelectRL,
                    false,
                    "btnCompareWord",
                ),
            );
        } else if (versionSelectRL.length > 0) {
            placeHolderRL.replaceWith(
                ml.UI.createDropDownButton("Redline against (DOCX)", versionSelectRL, false, "btnCompareWord"),
            );
        } else {
            // nothing released yet
            placeHolderRL.remove();
        }
    }

    private createCompareButton(rt: JQuery, options: IItemControlOptions, controls: IDHFControlDefinition[]) {
        // add button for redlining
        let placeHolderRL = $("<div class='btnCompare'>");
        rt.append(placeHolderRL);

        let versionSelectRL: IDropDownButtonOption[] = [];

        let thisSign: string | undefined;
        let thisDoc;
        // find the mother DOC or use as mother DOC if it is already a doc
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        if (options.type === "SIGN" && options.item.upLinkList && options.item.upLinkList.length > 0) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            let ref = ml.Item.parseRef(options.item.upLinkList[0].itemRef).id;
            thisDoc = ref;
            thisSign = options.id;
            versionSelectRL.push({
                name: ref,
                click: () => {
                    this.redlineDocuments(ref);
                },
            });
        } else {
            thisDoc = options.id;
        }

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        this.getSigns(thisDoc).done((otherSigns) => {
            otherSigns = otherSigns?.filter((sign) => sign !== thisSign);
            $.each(otherSigns, (idx, otherSign) => {
                versionSelectRL.splice(0, 0, {
                    name: otherSign + " " + app.getItemTitle(otherSign),
                    click: () => {
                        this.redlineDocuments(otherSign);
                    },
                });
            });
            if (versionSelectRL.length === 1) {
                placeHolderRL.replaceWith(
                    ml.UI.createDropDownButton(
                        "Redline against " + versionSelectRL[0].name,
                        versionSelectRL,
                        false,
                        "btnCompare",
                    ),
                );
            } else if (versionSelectRL.length > 0) {
                placeHolderRL.replaceWith(
                    ml.UI.createDropDownButton("Redline against ...", versionSelectRL, false, "btnCompare"),
                );
            } else {
                // nothing released yet
                placeHolderRL.remove();
            }
        });

        // add button for side by side compare
        let extras = globalMatrix.ItemConfig.getExtrasConfig();

        if (extras && ml.JSON.isTrue(extras.compareInsideX)) {
            let placeHolder = $("<div class='btnCompare'>");
            rt.append(placeHolder);

            let versionSelect: IDropDownButtonOption[] = [];

            let thisSign: string;
            let thisDoc: string;
            // find the mother DOC or use as mother DOC if it is already a doc
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            if (this.isDocumentFormType(options.item.type)) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                thisDoc = options.id;
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            } else if (options.item.upLinkList) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                let docsInList = options.item.upLinkList.filter((ul) => {
                    return this.isDocumentFormType(ml.Item.parseRef(ul.itemRef).type);
                });
                if (docsInList.length) {
                    thisDoc = ml.Item.parseRef(docsInList[0].itemRef).id;
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    thisSign = options.id;
                    versionSelect.push({
                        name: thisDoc + " " + app.getItemTitle(thisDoc),
                        click: () => {
                            this.compareDocuments(thisDoc, controls);
                        },
                    });
                }
            }

            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            if (!thisDoc) {
                // no more history
                placeHolder.remove();
                return;
            }

            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            this.getSigns(thisSign, thisDoc).done((otherSigns) => {
                $.each(otherSigns, (idx, otherSign) => {
                    versionSelect.push({
                        name: otherSign + " " + app.getItemTitle(otherSign),
                        click: () => {
                            this.compareDocuments(otherSign, controls);
                        },
                    });
                });
                if (versionSelect.length === 1) {
                    placeHolder.replaceWith(
                        ml.UI.createDropDownButton(
                            "Compare with " + versionSelect[0].name,
                            versionSelect,
                            false,
                            "btnCompare",
                        ),
                    );
                } else if (versionSelect.length > 0) {
                    placeHolder.replaceWith(
                        ml.UI.createDropDownButton("Compare to ...", versionSelect, false, "btnCompare"),
                    );
                } else {
                    // nothing released yet
                    placeHolder.remove();
                }
            });
        }
    }

    private async compareDocuments(target: string, otherControls: IDHFControlDefinition[]) {
        let ui = $("<div style='width:100%'>");
        ui.append(ml.UI.getSpinningWait("retrieving documents..."));

        let left: string;
        let right: string;

        // show dialog
        app.dlgForm.html("").append(ui);

        app.dlgForm.addClass("dlg-v-scroll");
        app.dlgForm.removeClass("dlg-no-scroll");

        app.dlgForm
            .dialog({
                autoOpen: true,
                title: "Compare documents",
                height: app.itemForm.height() * 0.9,
                width: $(document).width() * 0.9,
                modal: true,
                close: function () {
                    app.dlgForm.removeClass("forcePrint");
                    $(".compareTools").remove();
                },
                open: function () {
                    app.dlgForm.addClass("forcePrint");
                },
                resizeStop: function (event, ui) {
                    app.dlgForm.resizeDlgContent([]);
                },
                buttons: [
                    {
                        text: "Ok",
                        class: "btnCancelIt",
                        click: function () {
                            app.dlgForm.dialog("close");
                        },
                    },
                ],
            })
            .resizeDlgContent([], false);

        let otherType = ml.Item.parseRef(target).type;
        if (this.isDocumentFormType(otherType)) {
            // the other document is a DOC need to retrieve it to know if there's any label filters to take into account
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            app.getItemAsync(target).done((otherDOC: IItem) => {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                let docFilter = globalMatrix.ItemConfig.getFields(otherType).filter(function (field) {
                    return field.fieldType == "docFilter";
                });

                let otherOptions: IReportOptions = { format: "html" };
                if (docFilter.length && (<any>otherDOC)[docFilter[0].id]) {
                    let filter = JSON.parse((<any>otherDOC)[docFilter[0].id]);
                    if (filter.length) {
                        otherOptions.filter = filter.join(",");
                    }
                }
                app.startCreateDocumentAsync(target, otherOptions).done(
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    (result: XRPostProject_LaunchReport_CreateReportJobAck) => {
                        mDHF.loadDocument(result.jobId, (htmlDOM: any) => {
                            right = htmlDOM;
                            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                            this.compareDocumentsContent(this.item.id, target, left, right, ui);
                        });
                    },
                );
            });
        } else {
            app.startCreateDocumentAsync(target, { format: "html" }).done(
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                (result: XRPostProject_LaunchReport_CreateReportJobAck) => {
                    mDHF.loadDocument(result.jobId, (htmlDOM: any) => {
                        right = htmlDOM;
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        this.compareDocumentsContent(this.item.id, target, left, right, ui);
                    });
                },
            );
        }

        // start report generation for this DOC or SIGN, if it is a DOC make sure label filters are active
        let thisOptions: IReportOptions = { format: "html" };
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        if (this.isDocumentFormType(this.item.type) && otherControls && otherControls.length) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            let labels = await this.getFilterValue(<any>this.getFilterCtrl(this.item.type, otherControls));
            if (labels) thisOptions.filter = labels;
        }
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        app.startCreateDocumentAsync(this.item.id, thisOptions).done(
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            (result: XRPostProject_LaunchReport_CreateReportJobAck) => {
                mDHF.loadDocument(result.jobId, (htmlDOM: any) => {
                    left = htmlDOM;
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    this.compareDocumentsContent(this.item.id, target, left, right, ui);
                });
            },
        );
    }

    private redlineDocumentsWord(source: string, target: string) {
        if (app.needsSave()) {
            ml.UI.showError("Please Save", "Please save before redlining documents.");
            return;
        }

        let redlining = new Redlining();
        ml.UI.Progress.Init("Start generating reline");
        redlining
            .compareDocumentsWord(source, target)
            .then((compareResult: XRCreateReportJobAck | undefined) => {
                if (compareResult !== undefined) {
                    let jobId = compareResult!.jobId;
                    this.downloadCompareDocument(jobId, 0);
                }
            })
            .catch((reason) => {
                ml.UI.Progress.ErrorHide(reason, 2000);
            });
    }

    private downloadCompareDocument(jobId: number, retry: number) {
        if (retry > 70) {
            return;
        }
        app.getReportDetails(jobId)
            .done((progress: IGetReportProgressResult | undefined) => {
                if (progress === undefined) {
                    return;
                }
                if (progress.progress > 100 && progress.status === "Error") {
                    ml.UI.Progress.ErrorHide("Error generating redline document", 2000);
                } else if (progress.progress == 100) {
                    let redlineToDownload = progress.jobFile.find((file) => {
                        let exactMatch = new RegExp("/generated/redline_.*.docx");
                        let match = file.internalPath.match(exactMatch);
                        return match != null && match.length == 1 && file.internalPath == match[0];
                    });
                    if (redlineToDownload !== undefined) {
                        app.download(jobId, redlineToDownload.jobFileId);
                    } else {
                        retry++;
                        window.setTimeout(() => {
                            this.downloadCompareDocument(jobId, retry);
                        }, 1000);
                    }
                    ml.UI.Progress.SuccessHide("Redline downloaded", progress.progress);
                } else {
                    ml.UI.Progress.Update(progress.progress);
                    window.setTimeout(() => {
                        this.downloadCompareDocument(jobId, retry);
                    }, 500);
                }
            })
            .fail(function () {
                ml.UI.Progress.ErrorHide("Error generating redline document", 2000);
            });
    }

    private redlineDocuments(target: string) {
        if (app.needsSave()) {
            ml.UI.showError("Please Save", "Please save before redlining documents.");
            return;
        }

        let ui = $("<div style='width:100%'>");
        ui.append(ml.UI.getSpinningWait("examining documents..."));

        // show dialog
        let dlg = $("<div class='forcePrint'>").appendTo($("body"));

        let redlining = new Redlining();

        ml.UI.showDialog(
            dlg,
            "Redline",
            ui,
            $(document).width() * 0.9,
            app.itemForm.height() * 0.9,
            [
                {
                    text: "Ok",
                    class: "btnCancelIt",
                    click: function () {
                        dlg.dialog("close");
                    },
                },
            ],
            UIToolsConstants.Scroll.Vertical,
            true,
            true,
            () => {
                redlining.cancelCompare();
                dlg.remove();
            },
            () => {},
            () => {
                dlg.resizeDlgContent([]);
            },
        );

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        redlining.compareDocuments(ui, this.item.id, target);
    }
    private filterAlignItems(showDifferent: boolean, showSorted: boolean) {
        // hide all
        $(".compareItem").hide();

        if (showDifferent && showSorted) {
            // not sorted AND not identical
            //  show sorted                 show different
            $(".compareItem").not(".compareItemOriginal").not(".compareItemSame").show();
        } else if (showDifferent && !showSorted) {
            // not sorted AND not identical
            //  show original         show different
            $(".compareItemOriginal").not(".compareItemSame").show();
        } else if (!showDifferent && showSorted) {
            $(".compareItem").not(".compareItemOriginal").show();
        } else {
            $(".compareItem.compareItemOriginal").show();
        }
    }
    compareDocumentsContent(thisId: string, otherId: string, left: string, right: string, ui: JQuery) {
        let that = this;
        let itemHideIdentical = false;
        let itemSortAlign = false;

        if (!left || !right) {
            return;
        }
        left = new HTMLCleaner($(left).html(), false).getClean(HTMLCleaner.CleanLevel.Strict, true);
        right = new HTMLCleaner($(right).html(), false).getClean(HTMLCleaner.CleanLevel.Strict, true);
        let param: XCPostCompareHtml = {
            arg: JSON.stringify({ versions: [left, right] }),
        };

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        app.compareHTML(param).done(function (result: XRPostProject_CompareHtml_HtmlCompareResult) {
            ui.html("");
            let ctrls = $("<div class='compareTools'>").appendTo(app.dlgForm.parent().find(".ui-dialog-titlebar"));
            let sectionTools = $("<div class='compareToolsGroup'>").appendTo(ctrls);
            let itemTools = $("<div class='compareToolsGroup'>").appendTo(ctrls);

            $('<span class="compareToolsHeader">Sections:</span>').appendTo(sectionTools);
            $('<div class="checkbox compareTool" ><label><input type="checkbox">hide identical sections</label></div>')
                .appendTo(sectionTools)
                .click(function (event: JQueryMouseEventObject) {
                    $("input", $(event.delegateTarget)).prop("checked")
                        ? $(".compareRowSame").hide()
                        : $(".compareRowSame").show();
                });
            $(
                `<div class="checkbox compareTool" ><label><input type="checkbox">hide sections only in ${thisId}</label></div>`,
            )
                .appendTo(sectionTools)
                .click(function (event: JQueryMouseEventObject) {
                    $("input", $(event.delegateTarget)).prop("checked")
                        ? $(".compareLeftOnly").hide()
                        : $(".compareLeftOnly").show();
                });
            $(
                `<div class="checkbox compareTool" ><label><input type="checkbox">hide sections only in ${otherId}</label></div>`,
            )
                .appendTo(sectionTools)
                .click(function (event: JQueryMouseEventObject) {
                    $("input", $(event.delegateTarget)).prop("checked")
                        ? $(".compareRightOnly").hide()
                        : $(".compareRightOnly").show();
                });
            $('<span class="compareToolsHeader">Items:</span>').appendTo(itemTools);
            $('<div class="checkbox compareTool" ><label><input type="checkbox">hide identical items</label></div>')
                .appendTo(itemTools)
                .click(function (event: JQueryMouseEventObject) {
                    itemHideIdentical = $("input", $(event.delegateTarget)).prop("checked");
                    that.filterAlignItems(itemHideIdentical, itemSortAlign);
                });
            $('<div class="checkbox compareTool" ><label><input type="checkbox">sort and align items</label></div>')
                .appendTo(itemTools)
                .click(function (event: JQueryMouseEventObject) {
                    itemSortAlign = $("input", $(event.delegateTarget)).prop("checked");
                    that.filterAlignItems(itemHideIdentical, itemSortAlign);
                });

            let headers = $("<div class='compareHeaders'>").appendTo(ui);
            $("<div class='compareHeader'>").appendTo(headers).html(`This document (${thisId})`);
            $("<div class='compareHeader'>").appendTo(headers).html(`Other document (${otherId})`);

            that.fillSideBySide($(result.html[0]), $(result.html[1]), ui);
        });
    }

    private fillSideBySide(left: JQuery, right: JQuery, ui: JQuery) {
        let buffer = $("<div style='display:none'>").appendTo("body");
        buffer.append(left);

        let row: JQuery = $("<div>");
        let leftUI: JQuery = $("<div>");
        let rightUI: JQuery = $("<div>");
        // add left report - each section one row
        let ssc = $($(".subchapter", buffer)[0]);
        while (ssc && ssc.length > 0) {
            if (ssc.hasClass("subchapter")) {
                row = $("<div class='compareRow compareLeftOnly'>").appendTo(ui);
                leftUI = $("<div class='compareLeft'>").appendTo(row);
                rightUI = $("<div class='compareRight'>").appendTo(row);
            }
            let next = ssc.next();
            leftUI.append(ssc);
            ssc = next;
        }
        // add right report - if same section (title) exists in left only match it, if not append
        buffer.html("");
        buffer.append(right);
        ssc = $($(".subchapter", buffer)[0]);
        while (ssc && ssc.length > 0) {
            if (ssc.hasClass("subchapter")) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                row = null;
                $(".compareRow.compareLeftOnly", ui).each(function (lri, lr) {
                    if ($.trim($(".subchapter", lr).text()) == $.trim(ssc.text())) {
                        row = $(lr);
                        row.removeClass("compareLeftOnly");
                        rightUI = $(".compareRight", row);
                        return;
                    }
                });
                if (!row) {
                    row = $("<div class='compareRow compareRightOnly'>").appendTo(ui);
                    leftUI = $("<div class='compareLeft'>").appendTo(row);
                    rightUI = $("<div class='compareRight'>").appendTo(row);
                }
            }
            let next = ssc.next();
            rightUI.append(ssc);
            ssc = next;
        }

        // find sections which match
        $(".compareRow", ui).each(function (ridx, row) {
            // remove all itemspacers (these are added by report engine to have space between items)
            $(".compareRow .itemspacer").remove();

            if (!$(row).hasClass("compareRightOnly") && !$(row).hasClass("compareLeftOnly")) {
                leftUI = $(row).find(".compareLeft");
                rightUI = $(row).find(".compareRight");

                if (leftUI.html() === rightUI.html()) {
                    $(row).addClass("compareRowSame");
                }

                // build lists of all items in sections left and right
                let sortLeft: ItemSortInfo[] = [];
                let sortRight: ItemSortInfo[] = [];
                // buffer with name
                $.each($(".wide_item_title", leftUI), function (lii, leftItem) {
                    let lt = $(leftItem).closest("table");
                    lt.addClass("compareItem");
                    sortLeft.push({ itemId: $("a", $(leftItem)).text(), item: lt, existsInBoth: false });
                });
                $.each($(".wide_item_title", rightUI), function (lii, rightItem) {
                    let rt = $(rightItem).closest("table");
                    rt.addClass("compareItem");
                    sortRight.push({ itemId: $("a", $(rightItem)).text(), item: rt, existsInBoth: false });
                });
                // if there are any items either left or right, mark everything in both sections
                if (sortLeft.length > 0 || sortRight.length > 0) {
                    leftUI.children().each(function (idx, c) {
                        $(c).addClass("compareItemOriginal");
                    });
                    rightUI.children().each(function (idx, c) {
                        $(c).addClass("compareItemOriginal");
                    });
                }
                // mark matching and identical items
                $.each(sortLeft, function (lii, lt) {
                    $.each(sortRight, function (rii, rt) {
                        if (lt.itemId == rt.itemId) {
                            lt.existsInBoth = true;
                            rt.existsInBoth = true;
                        }
                        if (lt.item.html() == rt.item.html()) {
                            lt.item.addClass("compareItemSame");
                            rt.item.addClass("compareItemSame");
                        }
                    });
                });
                // sort: first matching in top, than by item id
                sortLeft = sortLeft.sort(function (a, b) {
                    if (a.existsInBoth && !b.existsInBoth) {
                        return -1;
                    }
                    if (b.existsInBoth && !a.existsInBoth) {
                        return 1;
                    }
                    let an = ml.Item.parseRef(a.itemId);
                    let bn = ml.Item.parseRef(b.itemId);
                    let at = an.type + an.isFolder ? "-F" : "";
                    let bt = bn.type + bn.isFolder ? "-F" : "";

                    return at < bt || (at == bt && an.number < bn.number) ? -1 : 1; // there should not be doubles
                });
                sortRight = sortRight.sort(function (a, b) {
                    if (a.existsInBoth && !b.existsInBoth) {
                        return -1;
                    }
                    if (b.existsInBoth && !a.existsInBoth) {
                        return 1;
                    }
                    let an = ml.Item.parseRef(a.itemId);
                    let bn = ml.Item.parseRef(b.itemId);
                    let at = an.type + an.isFolder ? "-F" : "";
                    let bt = bn.type + bn.isFolder ? "-F" : "";

                    return at < bt || (at == bt && an.number < bn.number) ? -1 : 1; // there should not be doubles
                });
                // insert copies of sorted items
                $.each(sortLeft, function (idx, sl) {
                    if (sl.existsInBoth) {
                        // find right idx
                        let rightIdx = 0;
                        // identical id in right must exist
                        while (sortRight[rightIdx].itemId != sl.itemId) {
                            rightIdx++;
                        }

                        let nl = sl.item.clone();
                        nl.removeClass("compareItemOriginal");
                        nl.addClass("compareItemSorted");
                        leftUI.append(nl);

                        let nr = sortRight[rightIdx].item.clone();
                        nr.removeClass("compareItemOriginal");
                        nr.addClass("compareItemSorted");
                        rightUI.append(nr);
                        if (nl.height() > nr.height()) {
                            nr.css("cssText", "margin-bottom:" + (6 + nl.height() - nr.height()) + "px !important");

                            // 10 is the margin of compareitem (which is not part of hight calculation) - 4 pixels magic...
                        } else if (nl.height() < nr.height()) {
                            nl.css("cssText", "margin-bottom:" + (6 + nr.height() - nl.height()) + "px !important");
                        }

                        nl.hide();
                        nr.hide();
                    } else {
                        let nl = sl.item.clone();
                        nl.removeClass("compareItemOriginal");
                        nl.addClass("compareItemSorted");
                        leftUI.append(nl);
                        let nr = $("<div class='compareItem compareItemSorted'>");
                        rightUI.append(nr);
                        nr.height(nl.height());
                        nl.hide();
                        nr.hide();
                    }
                });
                $.each(sortRight, function (idx, sr) {
                    if (sr.existsInBoth) {
                        // did this already
                    } else {
                        let nr = sr.item.clone();
                        nr.removeClass("compareItemOriginal ");
                        nr.addClass("compareItemSorted");
                        rightUI.append(nr);

                        let nl = $("<div class='compareItem compareItemSorted'>");
                        leftUI.append(nl);
                        nl.height(nr.height());
                        nl.hide();
                        nr.hide();
                    }
                });
            }
        });

        buffer.html("");
    }

    getDocumentStatus(item: IItem): IDocumentStatus {
        let status: IDocumentStatus = {};
        let markAsTemplates = globalMatrix.ItemConfig.getFieldsOfType("markAsTemplate", item.type);
        let requiredTemplateApprovals: string[] = [];
        let templateApprovalStatus: ISignaturesInfo = <any>{};
        if (markAsTemplates.length == 1) {
            requiredTemplateApprovals = MarkAsTemplateImpl.getRequiredApprovals(
                (<any>item)[markAsTemplates[0].field.id],
            );
            templateApprovalStatus = MarkAsTemplateImpl.getTemplateSignatureStatus(
                (<any>item)[markAsTemplates[0].field.id],
            );
        }
        let signatureStatus = DocBaseImpl.readSignatureInfo(item);

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        if (this.isDocumentFormType(item.type)) {
            status.isDoc = true;
            if (requiredTemplateApprovals.length > 0) {
                status.isDocWithTemplate = true;
            } else if (markAsTemplates.length == 1 && requiredTemplateApprovals.length == 0) {
                status.isDocWithEmptyTemplate = true;
            }
            // check if there's a signature table and that the status is
            $.each(item, function (key, val) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                if (val && globalMatrix.ItemConfig.getFieldType(item.type, key) === FieldDescriptions.Field_dhf) {
                    // special dhf control
                    let fieldVal = <IDHFControlDefinitionValue>JSON.parse(val);
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    if (fieldVal.type.toLocaleLowerCase() === "signaturebox") {
                        if (fieldVal.fieldValue && JSON.parse(fieldVal.fieldValue).length) {
                            status.isDocWithFilledSignatureTable = true;
                        } else {
                            status.isDocWithEmptySignatureTable = true;
                        }
                    }
                }
            });
            if (!status.isDocWithFilledSignatureTable && !status.isDocWithEmptySignatureTable) {
                status.isDocWithoutSignatureTable = true;
            }
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        } else if (this.isSignedType(item.type)) {
            status.isSign = true;
            if (requiredTemplateApprovals.length > 0) {
                status.isSignNeedingTemplateApproval = true;
                if (requiredTemplateApprovals.indexOf(matrixSession.getUser()) != -1) {
                    status.isSignNeedingTemplateMyApproval = true;
                }
            } else if (templateApprovalStatus.givenSignatures) {
                status.isSignApprovedTemplate = true;
            } else if (signatureStatus.hasSignature && signatureStatus.missingSignatures) {
                status.isSignNeedingSignature = true;
            } else if (signatureStatus.hasSignature) {
                status.isSignCompletlySigned = true;
            }
            if (
                !status.isSignNeedingTemplateApproval &&
                !status.isSignApprovedTemplate &&
                !status.isSignNeedingSignature &&
                !status.isSignCompletlySigned
            ) {
                status.isSignNoSignatureNoTemplate = true;
            }
        } else {
            // not a doc not a sign
        }

        return status;
    }

    static excelButtonControl(click: () => boolean): JQuery {
        const tooltip = `Export Custom sections to an Excel file.\nThis only works with Custom sections, other parts of the document are ignored.\nThe Custom sections should ideally be tables.`;
        return $(
            `<button class='btn btn-default' title='${tooltip}' style='margin-right:5px'>Excel Export</button>`,
        ).click(click);
    }
}

interface IDocumentStatus {
    isDoc?: boolean;
    isDocWithTemplate?: boolean; // it's a DOC with a template field, with some users in there
    isDocWithEmptyTemplate?: boolean; // it's a DOC with a template field which is empty
    isDocWithFilledSignatureTable?: boolean; // it's a DOC with a signature field with some users mentioned
    isDocWithEmptySignatureTable?: boolean; // it's a DOC with a signature field without users mentioned
    isDocWithoutSignatureTable?: boolean; // it's a DOC without a signature

    isSign?: boolean;

    isSignNeedingTemplateApproval?: boolean; // it's a template which needs to be approved
    isSignNeedingTemplateMyApproval?: boolean; // it's a template which needs to be approved by ME
    isSignApprovedTemplate?: boolean; // it's a template which has been approved

    isSignNeedingSignature?: boolean; // it's a SIGN which needs to be signed
    isSignCompletlySigned?: boolean; // it's a SIGN which has been signed
    isSignNoSignatureNoTemplate?: boolean; // is's a SIGN no template and no signature
}
class ColumnTypesInfo {
    private config: IDHFConfig;

    private editorOfType = {
        type0: ColumnEditor.textline,
        type1: ColumnEditor.text,
        type2: ColumnEditor.versionletter,
        type3: ColumnEditor.none,
        type4: ColumnEditor.user,
        type5: ColumnEditor.none,
        type6: ColumnEditor.signaturemeaning,
        type7: ColumnEditor.eco,
        type8: ColumnEditor.ecocapa,
        type9: ColumnEditor.date,
        //        type10:ColumnEditor.groupuser, // allow user to select a group or a user to sign
        type11: ColumnEditor.group, // allow to select a group (just as text field, like a role)
        type12: ColumnEditor.revision,
        type13: ColumnEditor.date_today,
        type14: ColumnEditor.number,
    };

    private nameOfType = {
        type0: "Text Line",
        type14: "Number",
        type1: "Rich Text",
        type4: "Name",
        type9: "Date",
        type13: "Date (auto)",
        type2: "Version Letter",
        type6: "Signature Meaning",
        type3: "eSignature Signature",
        type5: "eSignature Date",
        type7: "ECO", // don't show as option in new tables
        type8: "ECO/CAPA", // don't show as option in new tables
        //        type10: "Name / Group",
        type11: "Group / Role",
        type12: "Revision",
    };

    constructor(config: IDHFConfig) {
        this.config = config;
    }

    private getCustomTypeDef(type: string): IDHFConfigCustomColumn {
        if (this.config.customColumns) {
            for (let idx = 0; idx < this.config.customColumns.length; idx++) {
                if (this.config.customColumns[idx].type === type) {
                    return this.config.customColumns[idx];
                }
            }
        }
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        return null;
    }

    getCustomDropDownOptions(type: string): IDropdownOption[] {
        if (this.config.customColumns) {
            for (let idx = 0; idx < this.config.customColumns.length; idx++) {
                if (this.config.customColumns[idx].type === type) {
                    return this.config.customColumns[idx].options;
                }
            }
        }
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        return null;
    }

    getNames(currentColumns: string[]): { [key: string]: string } {
        let names = ml.JSON.clone(this.nameOfType);

        //type7: "ECO", // don't show as option
        //type8: "ECO/CAPA",// don't show as option
        if (currentColumns.indexOf("type7") == -1) {
            delete names.type7;
        }
        if (currentColumns.indexOf("type8") == -1) {
            delete names.type8;
        }

        if (this.config.customColumns) {
            for (let idx = 0; idx < this.config.customColumns.length; idx++) {
                names[this.config.customColumns[idx].type] = this.config.customColumns[idx].name;
            }
        }

        return names;
    }

    getEditorOfType(type: string): TableCellEditor {
        let colDef = this.getCustomTypeDef(type);
        if (colDef) {
            return <TableCellEditor>(colDef.editor ? colDef.editor : "select");
        }

        return <TableCellEditor>(<IGenericMap>this.editorOfType)[type];
    }

    getOptionsOfType(type: string): IDropdownOption[] {
        let colDef = this.getCustomTypeDef(type);
        if (colDef) {
            return colDef.options;
        }
        return [];
    }

    getNameOfType(type: string): string {
        let colDef = this.getCustomTypeDef(type);
        if (colDef) {
            return colDef.name;
        }
        return (<IGenericMap>this.nameOfType)[type];
    }
}

// TODO: convert to const and make sure it's still works
// eslint-disable-next-line no-var
var mDHF: PluginManagerDocuments;

function InitializePluginManagerDocuments() {
    mDHF = new PluginManagerDocuments();
    plugins.register(mDHF);
}
