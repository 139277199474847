/// <reference types="matrixrequirements-type-declarations" />
import { ControlState, globalMatrix } from "../../../globals";
import { IFieldParameter } from "../../../ProjectSettings";
import { ml } from "../../matrixlib";
import { HTMLCleaner } from "../../matrixlib/index";
import { IBaseControlOptions, BaseControl } from "./BaseControl";
import { FieldHandlerFactory } from "../../businesslogic";
import { FieldDescriptions } from "../../businesslogic/FieldDescriptions";
import { GenericFieldHandler } from "../../businesslogic/FieldHandlers/GenericFieldHandler";

export type { IPlainTextParams, IPlainTextControlOptions, CodeLanguage };
export { PlainTextImpl };

interface IPlainTextParams extends IFieldParameter {
    externalHelp?: string;
    readonly?: boolean;
    allowResize?: boolean;
    rows?: number;
    code?: boolean | CodeLanguage; // default false: can be a supported language if code mirror should be used supported: (xml,json)
    lineNumbers?: boolean; // default true: in code mode: show or hide line numbers
    tabSize?: number; // default 3: in code mode: tab size ;-)
    height?: number; // default250: in code mode: number of pixels to use for height,
    password?: boolean; // default false: can be set to true for password fields
    autoEdit?: boolean; // default false: control needs to be activated by a click
    autoFormat?: boolean; // if set to true the code will be formatted by the editor on first load
    showJSONFormat?: boolean;
    requiresContent?: boolean;
    inlineHelp?: string;
    magic?: boolean; // temp button for developing print stuff
    apiHelp?: string; // if specified it shows some help next to the code editor (needs code to be set)
    initialContent?: string; // allows to set the initial content of text boxes
    hideFullscreen?: boolean; // set to true to hide fullscreen button,
    purify?: boolean; // purify the text (don't allow jscript, xml etc)
}

interface IPlainTextControlOptions extends IBaseControlOptions {
    dummyData?: any;
    lostFocus?: Function;
    parameter?: IPlainTextParams;
}
type CodeLanguage = "xml" | "json" | "css";

$.fn.plainText = function (this: JQuery, options: IPlainTextControlOptions) {
    if (!options.fieldHandler) {
        options.fieldHandler = FieldHandlerFactory.CreateHandler(
            globalMatrix.ItemConfig,
            FieldDescriptions.Field_text,
            options,
        );
        options.fieldHandler.initData(JSON.stringify(options.fieldValue));
    }
    let baseControl = new PlainTextImpl(this, options.fieldHandler as GenericFieldHandler);
    this.getController = () => {
        return baseControl;
    };
    baseControl.init(options);
    return this;
};

class PlainTextImpl extends BaseControl<GenericFieldHandler> {
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private settings: IPlainTextControlOptions;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private lastValueChanged: number;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private isCode: boolean;
    private myCodeMirror: any;
    private changedBefore = false;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private _editor: JQuery;
    private doesRequireContent = false;

    constructor(control: JQuery, fieldHandler: GenericFieldHandler) {
        super(control, fieldHandler);
    }

    init(options: IPlainTextControlOptions) {
        let that = this;

        let defaultOptions: IPlainTextControlOptions = {
            controlState: ControlState.FormView, // read only rendering
            canEdit: false, // whether data can be edited
            dummyData: false, // fill control with a dumy text (for form design...)
            valueChanged: function () {}, // callback to call if value changes
            parameter: {
                readonly: false, // can be set to overwrite the default readonly status
                rows: 5, // number of rows in code editor
                allowResize: true, // allow to resize control
                code: false, // can be a supported language if code mirror should be used supported: (xml,json)
                autoFormat: false, // if set to true the code will be formatted by the editor on first load
                lineNumbers: true, // in code mode: show or hide line numbers
                tabSize: 3, // in code mode: tab size ;-)
                height: 250, // in code mode: number of pixels to use for height, (in code mode 0 => 100%)
                password: false, // can be set to true for password fields
                hideFullscreen: false, //  hide fullscreen
                purify: false, // by default don't purify
            },
        };
        this.settings = ml.JSON.mergeOptions(defaultOptions, options);
        // have default values
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        if (!this.settings.fieldValue && this.settings.parameter.initialContent && !this.settings.item) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            this.settings.fieldValue = this.settings.parameter.initialContent;
        }
        if (typeof this.settings.fieldValue === "undefined" || this.settings.fieldValue === "") {
            this.settings.fieldValue = "";
        }

        this.isCode =
            !!this.settings.parameter?.code &&
            this.settings.controlState !== ControlState.HistoryView &&
            this.settings.controlState !== ControlState.Zen;
        if (this.settings.controlState === ControlState.Print) {
            this._root.append(super.createHelp(this.settings));

            this._root.append(
                "<pre class='printBox'>" +
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    (this.settings.parameter.unsafeHtml
                        ? this.forDB(this.settings.fieldValue, 0)
                        : this.settings.fieldValue) +
                    "</pre>",
            );

            return;
        }
        if (
            this.settings.controlState === ControlState.HistoryView ||
            this.settings.controlState === ControlState.Zen ||
            this.settings.controlState === ControlState.Tooltip
        ) {
            this._root.append(super.createHelp(this.settings));

            this._root.append(
                "<div>" +
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    (this.settings.parameter.unsafeHtml
                        ? this.forDB(this.settings.fieldValue, 0)
                        : this.settings.fieldValue) +
                    "</div>",
            );

            return;
        }

        if (options.parameter && options.parameter.requiresContent) {
            this.doesRequireContent = options.parameter.requiresContent;
        }

        let helpLine = this._root.append(super.createHelp(this.settings));
        let ctrlContainer = $("<div>").addClass("baseControl");

        this._root.append(ctrlContainer);

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        if (this.settings.parameter.password) {
            this._editor = $(
                ' <input class="lineInput form-control" type="password" autocomplete="new-password"' +
                    (this.settings.canEdit ? "" : "readonly ") +
                    " />",
            );
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        } else if (this.settings.parameter.rows === 1) {
            this._editor = $(
                '<input autocomplete="off" class="lineInput form-control" type="text" ' +
                    (this.settings.canEdit ? "" : "readonly ") +
                    " />",
            );
        } else {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            let rows = 'rows="' + this.settings.parameter.rows + '"';
            this._editor = $(
                "<textarea " + (this.settings.canEdit ? "" : "readonly ") + rows + "></textarea>",
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            ).addClass(ml.JSON.isTrue(this.settings.parameter.allowResize) ? "resizableControl" : "fixedControl");
        }
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        this._editor.val(this.forUI(this.settings.fieldValue, that.settings.fieldId));
        if (this.isCode) {
            this._editor.css("display", "none");
        }

        ctrlContainer.append(this._editor);

        if (this.isCode) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            if (this.settings.parameter.apiHelp) {
                let sidebar = localStorage.getItem("scriptedit_sidebar");
                if (!sidebar) {
                    sidebar = "300px";
                }
                that._root.css("height", "100%");
                let help = $(`<div class='apiHelp' style="width:${sidebar}">`)
                    .appendTo(that._root)
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    .html(this.settings.parameter.apiHelp);
                let dragbar = $(`<div class="apiDrag">`).appendTo(that._root);
                let edit = $(`<div class="apiEdit">`).appendTo(that._root);
                $(".baseControlHelp", that._root).appendTo(edit);
                $(".baseControl", that._root).appendTo(edit);
                dragbar.mousedown(function (e) {
                    if (e.preventDefault) e.preventDefault();
                    $(document).mousemove(function (e) {
                        let maxWidth = that._root.width() - 100;
                        let mousePos = e.pageX - help.offset().left; // mousepos relative to left border of help
                        if (mousePos > 100 && mousePos < maxWidth) {
                            localStorage.setItem("scriptedit_sidebar", mousePos - 15 + "px");
                            help.width(mousePos - 15);
                        }
                    });
                });
            }
            $(".baseControlHelp", helpLine).css("float", "none").css("padding-right", "0");
            window.setTimeout(function () {
                that.myCodeMirror = CodeMirror.fromTextArea(that._editor.get(0), {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    lineNumbers: that.settings.parameter.lineNumbers,
                    mode:
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        that.settings.parameter.code == "json"
                            ? { name: "javascript", json: true }
                            : // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                              that.settings.parameter.code,
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    tabSize: that.settings.parameter.tabSize,
                    readOnly: !that.settings.canEdit,
                });
                that.myCodeMirror.setSize(
                    "100%",
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    that.settings.parameter.height ? that.settings.parameter.height + "px" : "100%",
                );

                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                if (that.settings.parameter.autoFormat) {
                    CodeMirror.commands["selectAll"](that.myCodeMirror);
                    that.myCodeMirror.autoFormatRange(
                        that.myCodeMirror.getCursor(true),
                        that.myCodeMirror.getCursor(false),
                    );
                }
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                if (!that.settings.parameter.hideFullscreen) {
                    // TODO: convert to const and make sure it's still works
                    // eslint-disable-next-line no-var
                    var fs = $(
                        '<button type="button" class="btn btn-default btn-sm btn-small fullscreenButton" title="" data-event="fullscreen" tabindex="-1" data-original-title="Full Screen"><i class="fa fa-arrows-alt icon-fullscreen"></i></button>',
                    );
                    fs.click(function () {
                        let isFullScreen = $(that._editor.parent().parent()).hasClass("fullscreen");
                        $(".navbar-fixed-top").css("display", isFullScreen ? "block" : "none");
                        $(".navbar-fixed-bottom").css("display", isFullScreen ? "block" : "none");
                        $(".ui-layout-resizer").css("display", isFullScreen ? "block" : "none");
                        $(that._editor.parent().parent()).toggleClass("fullscreen");
                        $(that._editor.parent()).toggleClass("fullscreenEditor");
                        $(".CodeMirror", that._editor.parent()).toggleClass("fullscreenEditor");
                    });
                }
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                if (that.settings.parameter.showJSONFormat && that.settings.canEdit) {
                    let compact = $("<button class='btn  btn-sm btn-small btnCodeFormat'>format (compact)</button>");
                    $(".baseControlHelp", that._root).append(compact);
                    compact.click(function () {
                        let code = that.myCodeMirror.getValue();

                        try {
                            jsl.parser.parse(code);
                            let codeClean = that.compactizeJSON(JSON.stringify(JSON.parse(code), null, 2));

                            that.myCodeMirror.setValue(codeClean);
                            that.valueChanged();
                        } catch (parseException) {
                            alert(parseException instanceof Error ? parseException.message : "Invalid code");
                        }
                    });
                    let expl = $("<button class='btn btn btn-sm btn-small btnCodeFormat'>format</button>");
                    $(".baseControlHelp", that._root).append(expl);
                    expl.click(function () {
                        CodeMirror.commands["selectAll"](that.myCodeMirror);
                        that.myCodeMirror.autoFormatRange(
                            that.myCodeMirror.getCursor(true),
                            that.myCodeMirror.getCursor(false),
                        );
                        that.valueChanged();
                    });
                }

                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                $(".baseControlHelp", that._root).append(fs);

                that.myCodeMirror.on("changes", function () {
                    that.valueChanged();
                });
            }, 100);
        }

        // remove mouseout to avoid frequent changes change
        this._editor.on("mouseup keyup", function () {
            clearTimeout(that.lastValueChanged);
            that.lastValueChanged = window.setTimeout((noCallBack?: boolean) => that.valueChanged(noCallBack), 333);
        });
        this._editor.on("blur", function () {
            if (that.settings.focusLeft) {
                that.settings.focusLeft();
            }
        });
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        let rt = this.forDB(this._editor.val(), that.settings.fieldId);
        this._root.data("original", rt);
        this._root.data("new", rt);
        this.fieldHandler.initData(this.settings.fieldValue);
    }

    // public interface
    async hasChangedAsync() {
        // make sure no changes are pending
        clearTimeout(this.lastValueChanged);
        // this will take and text from the editor and put it in the variable  _root.data("new")
        // but it will not recursively trigger a change
        this.valueChanged(true);
        // now compare
        return this._root.data("original") !== this._root.data("new");
    }

    async getValueAsync() {
        // make sure no changes are pending
        if (this.isCode) {
            $(".navbar-fixed-top").css("display", "block");
            $(".navbar-fixed-bottom").css("display", "block");
            $(".ui-layout-resizer").css("display", "block");
        }

        clearTimeout(this.lastValueChanged);
        this.valueChanged(true);
        let text = this._root.data("new");

        return this.settings.purify ? new HTMLCleaner(text, false).getClean(HTMLCleaner.CleanLevel.PurifyOnly) : text;
    }

    requiresContent() {
        return this.doesRequireContent;
    }

    refresh() {
        if (this.isCode && this.myCodeMirror) {
            this.myCodeMirror.refresh();
        }
    }
    setValue(newValueDirty: string, reset?: boolean) {
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        let newValue = this.forDB(newValueDirty, this.settings.fieldId);

        if (this.isCode && this.myCodeMirror) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            this.myCodeMirror.setValue(this.forUI(newValue, this.settings.fieldId));
        } else if (this._editor) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            this._editor.val(this.forUI(newValue, this.settings.fieldId));
        }

        this._root.data("new", newValue);
        this.fieldHandler.setData(newValue);
        if (reset) {
            this._root.data("original", newValue);
        }
    }

    destroy() {
        if (this.myCodeMirror) {
            this.myCodeMirror.off();
            this.myCodeMirror = null;
        }
        if (this._editor) {
            this._editor.off();
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            this._editor = null;
        }
    }

    resizeItem() {}

    //  private functions

    /** if it's code, don't escape < sign as it will not work as code anymore */
    private forDB(val: string, field: number) {
        if (this.isCode && this.myCodeMirror) {
            return val;
        } else {
            return ml.UI.lt.forDB(val, field);
        }
    }
    /** if it's code, don't escape < sign as it will not work as code anymore */
    private forUI(val: string, field: number) {
        if (this.isCode && this.myCodeMirror) {
            return val;
        } else {
            return ml.UI.lt.forUI(val, field);
        }
    }

    private valueChanged(noCallback?: boolean) {
        if (this.isCode && this.myCodeMirror) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            this._root.data("new", this.forDB(this.myCodeMirror.getValue(), this.settings.fieldId));
        } else if (this._editor) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            this._root.data("new", this.forDB(this._editor.val(), this.settings.fieldId));
        }
        if (this.settings.valueChanged && !noCallback) {
            if (this._root.data("new") != this._root.data("original") || this.changedBefore) {
                this.changedBefore = true; // this will make it also fire if the field was changed to its initial value
                this.settings.valueChanged.apply(null);
            }
        }
        this.fieldHandler.initData(this._root.data("new"));
    }

    // initialize object

    private compactizeJSON(code: string) {
        let lastCloseIdx = -1;
        let lastClose = "";
        let idx = code.length;
        while (idx > 0) {
            idx--;
            if (
                code.charAt(idx) === "}" &&
                (idx === code.length - 1 || code.charCodeAt(idx + 1) === 10 || code.charAt(idx + 1) === ",")
            ) {
                lastClose = "{";
                lastCloseIdx = idx;
            }
            if (
                code.charAt(idx) === "]" &&
                (idx === code.length - 1 || code.charCodeAt(idx + 1) === 10 || code.charAt(idx + 1) === ",")
            ) {
                lastClose = "[";
                lastCloseIdx = idx;
            }
            if (code.charAt(idx) === lastClose) {
                lastClose = "";
                let lastNoSpace = lastCloseIdx;
                while (lastCloseIdx > idx) {
                    // remember to remove spaces after newline
                    if (code.charCodeAt(lastCloseIdx) !== 32 && code.charCodeAt(lastCloseIdx) !== 10) {
                        lastNoSpace = lastCloseIdx;
                    }
                    if (code.charCodeAt(lastCloseIdx) === 10) {
                        // replace \n's with " "
                        code = code.substr(0, lastCloseIdx) + " " + code.substr(lastNoSpace);
                    }
                    lastCloseIdx--;
                }
            }
        }

        return code;
    }
}
