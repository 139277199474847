/// <reference types="matrixrequirements-type-declarations" />
import { ControlState, IReference } from "../../../globals";
import { IDB } from "../../businesslogic/index";
import { IBaseControlOptions } from "../Controls/BaseControl";
import { ITreeDropInfo } from "../MainTree/MainTree";

export type {
    IProjectPanelControlOptions,
    IVizMode,
    IDelayedAction,
    MyNodeData,
    MyNode,
    MyDDData,
    MyFancytree,
    MyFancytreeOption,
};
export { SelectMode, SearchState, SearchUpdate };

interface IProjectPanelControlOptions extends IBaseControlOptions {
    parameter?: {
        readonly?: boolean; // can be set to overwrite the default readonly status
        placeholder?: string;
        maxItems?: number;
        options?: any;
    };
    noAnimation?: boolean; // set to true not to anmite opening / closing of tree
    dragAndDrop?: boolean;
    controlState?: ControlState; // read only rendering
    highlight?: boolean; // true: input should be highlighted in complete page
    canFilter?: boolean; //  true: filter / search box should be shown
    isConfigSearch?: boolean;
    serverSearch?: boolean; // true: search server should be enabled: disabled only in admin client
    expand?: number; //0, // minimum number of levels to expand
    tree?: IDB[]; // tree in fancy tree style
    tooltips?: boolean; // show tooltips if available
    collectionChanged?: (count: number) => void;
    selectionChanged?: (id: string) => void;
    onExpand?: (id: string) => void; // optional callback, called when folder is opened
    dropCallback?: (moveDetails: ITreeDropInfo) => boolean; // callback after node was dropped
    crossProject?: string; // if tree is for another project

    selectedItems?: IReference[]; // list of selected items,
    selectMode?: SelectMode; // if and how items can be selected
    glueToBottom?: boolean; // if set to true the tree will be as big as possible (e.g. for JIRA plugin)
    isMainTree?: boolean; // set to true if this is the main tree on left
    autoScroll?: boolean; // Scroll to selection, defaults to true
}
enum SelectMode {
    /*** DO NOT CHANGED numbers use from baseControl */
    none = 0, // cannot select
    items = 1, // can select items (no folders)
    folders = 2, // can select folders (no items)
    singleItem = 3, // can select one item (no folders)
    singleFolder = 4, // can select one folder (no items)
    independent = 5, // can select folder and items (independently)
    auto = 6, // if all children in folder are selected, selection changes to parent
    independentAuto = 7, //  can select folder and items independently -> but when checking a folder it adds all children
    autoPrecise = 8, //  if you select a folder it select everything within, if you unselect something it unselects all parents
}

interface MyNodeData extends Fancytree.FancytreeNode {
    cstrender?: boolean;
    background?: string;
    shortTitle?: string;
    border?: string;
    type?: string;
    icon?: string;
    hideCheckbox?: boolean;
    isUnselected?: boolean;
    extraStyle?: string;
    classes?: string[];
    mode?: string;
}
interface MyNode extends Fancytree.FancytreeNode {
    selected?: boolean;
    unselectable?: boolean;
    ul?: JQuery;
    span?: JQuery;
    type?: string;
    subMatch?: boolean;
    shortTitle?: string;
}
interface MyDDData {
    otherNode?: MyNode;
    hitMode?: string;
}
interface MyFancytree extends Fancytree.Fancytree {
    options?: Fancytree.FancytreeOptions;
}
interface MyFancytreeOption extends Fancytree.FancytreeOptions {
    filter?: { mode?: string };
}

enum SearchState {
    NoSearch = 0, // the whole tree is shown
    FilterDone, // a local search (filter of the tree by title and id)
    ServerRunning, // a search request was send to the server
    ServerDone, // the search results returned
}
enum SearchUpdate {
    inserted_node = 1, // called if a new node has been inserted
    updated_rec, // called after TC have been executed on server, a whole subtree needs to be inserted
    filter_status_changed, // called when label and therefore visibility is changed
    title_changed, // the item's title was changed
    item_dropped, // an item was draged and dropped to a new place (if this happens after creating it , it must be refreshed)
}
interface IVizMode {
    uid: string;
    icon: string;
    text: string;
    mainTree: boolean;
    asList: boolean;
    hide: boolean;
    check: boolean;
    expand: boolean;
}

interface IDelayedAction {
    type: string;
    expression: string;
}
