import { IDHFSectionOptions, IDHFControlDefinition, DocumentSectionType, mDHF } from "../../../../common/businesslogic";
import { ml } from "../../../../common/matrixlib";
import { IBaseControlOptions } from "../../../../common/UI/Controls/BaseControl";
import { IReference } from "../../../../globals";
import { ISingleDefaultControllerConfig, SingleSelectBase } from "../../../../SingleSelectBase";
import { IItemsOptions } from "../../../../common/businesslogic/FieldHandlers/Document/ItemSelectionAbstractDocFieldHandler";

export { Items };

class Items extends SingleSelectBase<IItemsOptions> {
    renderControl(ctrl: IDHFControlDefinition, ctrlParameter: IBaseControlOptions) {
        let noTypes = ["REPORT", "DOC", "SIGN"];
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        ctrl.control.itemSelection(
            ml.JSON.mergeOptions(ctrlParameter, {
                parameter: {
                    prefix: "Include the following items:",
                    buttonName: "Select Items",
                    showNot: noTypes,
                },
            }),
        );
    }

    showSpecificSettings(
        ctrl: IDHFControlDefinition,
        ctrlParameter: IBaseControlOptions,
        custom: JQuery,
        hideFolder?: boolean,
    ) {
        let controllerConfig = this.getConfig(ctrl);

        let breadcrumb = $(
            '<div class="checkbox" style=""><label><input type="checkbox" class="p1" ' +
                (controllerConfig.breadcrumb ? "checked" : "") +
                "> Include parent folders for each item (breadcrumb)</label></div>",
        );

        let loAll =
            controllerConfig.showlinks && !controllerConfig.showUpOnly && !controllerConfig.showDownOnly
                ? "selected"
                : "";
        let loUp = controllerConfig.showlinks && controllerConfig.showUpOnly ? "selected" : "";
        let loDown = controllerConfig.showlinks && controllerConfig.showDownOnly ? "selected" : "";
        let loNone = !controllerConfig.showlinks ? "selected" : "";

        let linkOptions = $(
            '<select class="docOptionSelect linkOptions form-control" style="">' +
                '<option value="all" ' +
                loAll +
                ">Show up and downlinks</option>" +
                '<option value="up" ' +
                loUp +
                ">Show uplinks only</option>" +
                '<option value="down"' +
                loDown +
                ">Show downlinks only</option>" +
                '<option value="none"' +
                loNone +
                ">Do not show links</option>" +
                "</select>",
        );

        let showDeeplinks = $(
            '<div class="checkbox" style="" ><label><input type="checkbox" class="p2d" ' +
                (controllerConfig.showDeeplinks ? "checked" : "") +
                "> Show links recursively</label></div>",
        );
        let showlinkerrors = $(
            '<div class="checkbox" style="" ><label><input type="checkbox" class="p2b" ' +
                (controllerConfig.hideLinkErrors ? "" : "checked") +
                "> Show traceability errors</label></div>",
        );
        let showlabels = $(
            '<div class="checkbox" style="" ><label><input type="checkbox" class="p2c" ' +
                (controllerConfig.hideLabels ? "" : "checked") +
                "> Show labels</label></div>",
        );
        let itemInfo = $(
            '<select class="docOptionSelect p3 form-control" style="">' +
                '<option value="">Show items with version info</option>' +
                '<option value="N1">Show items without version info</option>' +
                '<option value="N2">Show items with minimal markup</option>' +
                '<option value="DR">Show items with design review fields (all items)</option>' +
                '<option value="PF">Show items with additional pass / fail fields (for use and test cases)</option>' +
                '<option value="EX">Show items with test results (for executed test cases)</option>' +
                "</select>",
        );

        let folderInfo = $(
            '<select class="docOptionSelect p4 form-control" style="">' +
                '<option value="">Do not include folders</option>' +
                '<option value="SORTNUMERICAL">Do not include folders and sort numerically</option>' +
                '<option value="F1">Show folder titles as headings</option>' +
                '<option value="F2">Show folder titles as heading with details without markup in table</option>' +
                '<option value="F4">Show folder titles as heading with details without markup as text</option>' +
                '<option value="F3">Show folder the same way as items</option>' +
                "</select>",
        );
        let hideEmptyFolders = $(
            '<div class="checkbox" style=""><label><input type="checkbox" class="p4a" ' +
                (controllerConfig.hideEmptyFolders ? "checked" : "") +
                "> Hide empty folders</label></div>",
        );
        let showAllParentFolders = $(
            '<div class="checkbox" style=""><label><input type="checkbox" class="p4b" ' +
                (controllerConfig.showAllParentFolders ? "checked" : "") +
                "> Show all parent folders</label></div>",
        );
        let traces = $(
            '<select class="docOptionSelect p5 form-control" style="">' +
                '<option value="">Ignore dates (do not show outdated traces)</option>' +
                '<option value="warning">Indicate outdated traces</option>' +
                '<option value="error">Treat outdated traces as traceability errors</option>' +
                "</select>",
        );

        let external_links = $(
            '<select class="docOptionSelect p7 form-control" style="">' +
                '<option value="0">Ignore external links</option>' +
                '<option value="1">Show external links</option>' +
                '<option value="3">Show only done external links</option>' +
                '<option value="2">Show only external links which need to be done</option>' +
                "</select>",
        );

        let breakAfterItems = $(
            '<div class="checkbox" style="" ><label><input type="checkbox" class="breakAfterItems" ' +
                (controllerConfig.breakAfterItems ? "checked" : "") +
                "> Insert page break after items</label></div>",
        );

        if (!hideFolder) {
            let itoc =
                '<div class="checkbox" ><label><input type="checkbox" class="includeInToc" ' +
                (controllerConfig.includeInToc ? "checked" : "") +
                "> Add a reference to included items to table of content</label></div>";
            custom.append($(itoc));
        }
        custom.append(breadcrumb);
        custom.append(linkOptions);
        custom.append(showDeeplinks);
        custom.append(showlinkerrors);
        custom.append(showlabels);
        custom.append(breakAfterItems);
        custom.append(itemInfo);
        if (!hideFolder) {
            custom.append(folderInfo);
            custom.append(hideEmptyFolders);
            custom.append(showAllParentFolders);
        }
        custom.append(traces);
        custom.append(external_links);

        $(custom.find(".p3")[0]).val(controllerConfig.extracolumn);
        function showHideDetails() {
            switch (itemInfo.val()) {
                case "":
                case "DR":
                case "PF":
                case "EX":
                    breadcrumb.css("color", "").find("input").prop("disabled", false);
                    linkOptions.css("color", "").find("input").prop("disabled", false);
                    showDeeplinks.css("color", "").find("input").prop("disabled", false);
                    break;
                default:
                    breadcrumb.css("color", "#ccc").find("input").prop("disabled", true);
                    linkOptions.css("color", "#ccc").find("input").prop("disabled", true);
                    showDeeplinks.css("color", "").find("input").prop("disabled", true);
                    break;
            }
            // disable link error option if no links  should be shown

            let hideLinkDetails = $(custom.find(".linkOptions")[0]).val() == "none";

            $(custom.find(".p2b")[0]).prop("disabled", hideLinkDetails);
            $(custom.find(".p2d")[0]).prop("disabled", hideLinkDetails);
            $(custom.find(".p5")[0]).prop("disabled", hideLinkDetails);

            showlinkerrors.css("color", hideLinkDetails ? "#ccc" : "");
            showDeeplinks.css("color", hideLinkDetails ? "#ccc" : "");
            if ($(custom.find(".p4")[0]).val() === "" || $(custom.find(".p4")[0]).val() === "SORTNUMERICAL") {
                $(custom.find(".p4a")[0]).parent().parent().hide();
                $(custom.find(".p4b")[0]).parent().parent().hide();
            } else {
                $(custom.find(".p4a")[0]).parent().parent().show();
                $(custom.find(".p4b")[0]).parent().parent().show();
            }
        }
        linkOptions.change(function () {
            showHideDetails();
        });
        itemInfo.change(function () {
            showHideDetails();
        });
        $(custom.find(".p2")).change(function () {
            showHideDetails();
        });
        $(custom.find(".p4")[0])
            .val(controllerConfig.folderDetails)
            .change(function () {
                showHideDetails();
            });
        showHideDetails();

        $(custom.find(".p5")[0]).val(controllerConfig.dateoptions);

        $(custom.find(".p7")[0]).val(controllerConfig.showExternal ? controllerConfig.showExternal : 0);

        if (!hideFolder) {
            this.addSpecificSettings(controllerConfig, custom);
        }
    }
    async saveSpecificSettingsAsync(ctrl: IDHFControlDefinition, ctrlParameter: IBaseControlOptions, custom: JQuery) {
        let controllerConfig = this.getConfig(ctrl);

        let changed = false;

        changed = changed || controllerConfig.breadcrumb !== $(custom.find(".p1")[0]).prop("checked");

        let show = false;
        let up = false;
        let down = false;
        switch ($(".linkOptions").val()) {
            case "all":
                show = true;
                up = false;
                down = false;
                break;
            case "up":
                show = true;
                up = true;
                down = false;
                break;
            case "down":
                show = true;
                up = false;
                down = true;
                break;
            case "none":
                show = false;
                up = false;
                down = false;

                break;
        }

        changed = changed || controllerConfig.showlinks != show;
        changed = changed || controllerConfig.showDownOnly != down;
        changed = changed || controllerConfig.showUpOnly != up;

        changed = changed || controllerConfig.showDeeplinks !== $(custom.find(".p2d")[0]).prop("checked");
        changed = changed || controllerConfig.hideLinkErrors !== !$(custom.find(".p2b")[0]).prop("checked");
        changed = changed || controllerConfig.hideLabels !== !$(custom.find(".p2c")[0]).prop("checked");
        changed = changed || controllerConfig.extracolumn !== $(custom.find(".p3")[0]).val();
        changed = changed || controllerConfig.folderDetails !== $(custom.find(".p4")[0]).val();
        changed = changed || controllerConfig.hideEmptyFolders !== $(custom.find(".p4a")[0]).prop("checked");
        changed = changed || controllerConfig.showAllParentFolders !== $(custom.find(".p4b")[0]).prop("checked");

        changed = changed || controllerConfig.dateoptions !== $(custom.find(".p5")[0]).val();
        changed = changed || controllerConfig.breakAfterItems !== $(custom.find(".breakAfterItems")[0]).prop("checked");
        changed = changed || controllerConfig.showExternal !== $(custom.find(".p7")[0]).val();
        changed = changed || controllerConfig.includeInToc !== $(custom.find(".includeInToc")[0]).prop("checked");

        controllerConfig.breadcrumb = $(custom.find(".p1")[0]).prop("checked");
        controllerConfig.showlinks = show;
        controllerConfig.showDownOnly = down;
        controllerConfig.showUpOnly = up;
        controllerConfig.showDeeplinks = $(custom.find(".p2d")[0]).prop("checked");
        controllerConfig.hideLinkErrors = !$(custom.find(".p2b")[0]).prop("checked");
        controllerConfig.hideLabels = !$(custom.find(".p2c")[0]).prop("checked");
        controllerConfig.breakAfterItems = $(custom.find(".breakAfterItems")[0]).prop("checked");
        controllerConfig.extracolumn = $(custom.find(".p3")[0]).val();
        controllerConfig.folderDetails = $(custom.find(".p4")[0]).val();
        controllerConfig.hideEmptyFolders = $(custom.find(".p4a")[0]).prop("checked");
        controllerConfig.showAllParentFolders = $(custom.find(".p4b")[0]).prop("checked");
        controllerConfig.dateoptions = $(custom.find(".p5")[0]).val();
        controllerConfig.showExternal = $(custom.find(".p7")[0]).val();
        controllerConfig.includeInToc = $(custom.find(".includeInToc")[0]).prop("checked");

        let specificChanged = this.setSpecificSettings(controllerConfig, custom);

        this.fieldHandler.setDHFConfig(controllerConfig);

        return changed || specificChanged;
    }
}
