import { FieldDescriptions } from "../FieldDescriptions";
import { IBaseDropdownFieldParams } from "./DropdownFieldHandler";
import { ItemConfiguration } from "../ItemConfiguration";

import { IFieldHandler } from "./IFieldHandler";
import { BasicFunctions } from "../BasicFunctions";
import { ITestFieldParam } from "../TestManagerConfiguration";

interface IUserFieldHandlerParams extends Omit<IBaseDropdownFieldParams, "splitHuman"> {}

// TODO: UserFieldHandler is a lot like DropdownFieldHandler. Combine them somehow.
export class UserFieldHandler implements IFieldHandler {
    private rawData: string | undefined;
    private params: IUserFieldHandlerParams;

    constructor(params: IUserFieldHandlerParams, initialValue?: string) {
        this.params = params;
        this.rawData = undefined;
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        this.initData(initialValue);
    }

    // TODO: IDropdownParams is too broad. Use a narrower type
    public static UpdateFieldConfig(params: ITestFieldParam, fieldValue: string, itemConfig: ItemConfiguration) {
        let userDropdown = itemConfig.getValidUserOptions(
            !BasicFunctions.isFalse(params.showUsers), // by default show users, if not specified
            BasicFunctions.isTrue(params.showGroups), // by default do not show groups
            undefined,
            fieldValue, // special treatment for deleted users
        );

        params.create = false;

        // TODO: WARNING ! ERROR ! BUG ! This is altering the params object stored in the item configuration
        // where it was originally retrieved from. We need a clone operation by the caller to stop doing this, however,
        // I'm wondering if we have code that relies on these things being updated.

        params.options = userDropdown;
        params.maxItems = params.maxItems ? params.maxItems : 1;
    }

    getData(): string | undefined {
        return this.rawData;
    }

    setData(value: string) {
        this.initData(value);
    }

    getFieldType(): string {
        return FieldDescriptions.Field_user;
    }

    initData(serializedFieldData: string) {
        this.rawData = serializedFieldData;
    }

    getValues(filterOnOptions = true): string[] {
        if (this.rawData) {
            let values = this.rawData.split(",");
            if (filterOnOptions && !this.params.create) {
                return values.filter((value) => {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    return this.params.options.some((option) => {
                        return option.id === value;
                    });
                });
            } else {
                return values;
            }
        }

        return [];
    }

    private getMaxItems(): number | undefined {
        if (this.params.maxItems) {
            return Number(this.params.maxItems);
        }
        return undefined;
    }

    setValues(values: string[]) {
        const maxItems = this.getMaxItems();

        if (maxItems && maxItems < values.length) {
            throw new Error(`The field may only store ${maxItems} items`);
        }

        if (!this.params.create) {
            // Validate against the existing options.
            // TODO: validation should probably be in a central method checked by
            // setData().
            for (let value of values) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                if (!this.params.options.some((o) => o.id === value)) {
                    throw new Error(`Value ${value} not found in accepted options`);
                }
            }
        }
        this.initData(values.join());
    }

    getHuman() {
        const dataArray = this.rawData?.split(",") || [];

        return (this.params.options || [])
            .filter((option) => dataArray.includes(option.id))
            .map((option) => option.label)
            .join(",");
    }
}
