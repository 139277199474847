import { IDHFSectionOptions, IDHFControlDefinition, DocumentSectionType, mDHF } from "../../../../common/businesslogic";
import { ml } from "../../../../common/matrixlib";
import { IBaseControlOptions } from "../../../../common/UI/Controls/BaseControl";
import { globalMatrix, IReference } from "../../../../globals";

import { ISingleDefaultControllerConfig, SingleSelectBase } from "../../../../SingleSelectBase";
import {
    IRiskStatsOptions,
    RiskStatsDocFieldHandler,
} from "../../../../common/businesslogic/FieldHandlers/Document/RiskStatsDocFieldHandler";
import { IDocFieldHandler } from "../../../../common/businesslogic/FieldHandlers/Document/IDocFieldHandler";

export { RiskStats };

class RiskStats extends SingleSelectBase<IRiskStatsOptions> {
    renderControl(ctrl: IDHFControlDefinition, ctrlParameter: IBaseControlOptions) {
        let riskConfig = globalMatrix.ItemConfig.getRiskConfig();
        let riskCategory = riskConfig.riskCategory ? riskConfig.riskCategory : "RISK";
        let allLinkTypes = globalMatrix.ItemConfig.getCategories();
        if (!riskConfig.riskCategories) {
            riskConfig.riskCategories = [];
        }
        // in case there's a risk field in a category it's considered a risk category
        $.each(globalMatrix.ItemConfig.getFieldsOfType("risk2"), function (fidex, field) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            if (riskConfig.riskCategories.indexOf(field.category) == -1) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                riskConfig.riskCategories.push(field.category);
            }
        });
        // define possible to types: no folders, reports (maybe no DOC, no SIGN)
        let noTypes: string[] = [];

        for (let idx = 0; idx < allLinkTypes.length; idx++) {
            if (riskConfig.riskCategories.indexOf(allLinkTypes[idx]) == -1 && allLinkTypes[idx] != riskCategory) {
                noTypes.push(allLinkTypes[idx]);
            }
        }

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        ctrl.control.itemSelection(
            ml.JSON.mergeOptions(ctrlParameter, {
                parameter: {
                    prefix: "Include the following items:",
                    buttonName: "Select Risks",
                    showNot: noTypes,
                },
            }),
        );
    }

    showSpecificSettings(ctrl: IDHFControlDefinition, ctrlParameter: IBaseControlOptions, custom: JQuery) {
        let controllerConfig: IRiskStatsOptions = this.getConfig(ctrl);

        let itoc =
            '<div class="checkbox" ><label><input type="checkbox" class="includeInToc" ' +
            (controllerConfig.includeInToc ? "checked" : "") +
            "> Add a reference to included risks to table of content</label></div>";
        custom.append($(itoc));

        custom.append($("<div>Remove a section title to hide the section</span>"));

        custom.append(
            $("<div class='sectionConfOption'>").append(
                $("<span>Risk Summary Table: </span>").append(
                    $(
                        '<input style="height: 20px;width: 420px;float: right;" class="form-control p_table" type="text" value="" /> ',
                    ),
                ),
            ),
        );
        custom.append(
            $("<div class='sectionConfOption'>").append(
                $("<span>Stats RBM: </span>").append(
                    $(
                        '<input style="height: 20px;width: 420px;float: right;" class="form-control p_rbm" type="text" value="" /> ',
                    ),
                ),
            ),
        );
        custom.append(
            $("<div class='sectionConfOption'>").append(
                $("<span>Stats RAM: </span>").append(
                    $(
                        '<input style="height: 20px;width: 420px;float: right;" class="form-control p_ram" type="text" value="" /> ',
                    ),
                ),
            ),
        );
        custom.append(
            $("<div class='sectionConfOption'>").append(
                $("<span>Stats risk w/o test: </span>").append(
                    $(
                        '<input style="height: 20px;width: 420px;float: right;" class="form-control p_noTest" type="text" value="" /> ',
                    ),
                ),
            ),
        );
        custom.append(
            $("<div class='sectionConfOption'>").append(
                $("<span>Risk needing reduction: </span>").append(
                    $(
                        '<input style="height: 20px;width: 420px;float: right;" class="form-control p_mustReduce" type="text" value="" /> ',
                    ),
                ),
            ),
        );
        custom.append(
            $("<div class='sectionConfOption'>").append(
                $("<span>Risk benefits: </span>").append(
                    $(
                        '<input style="height: 20px;width: 420px;float: right;" class="form-control p_noBenefits" type="text" value="" /> ',
                    ),
                ),
            ),
        );
        custom.append(
            $("<div class='sectionConfOption'>").append(
                $("<span>Risk by zone before: </span>").append(
                    $(
                        '<input style="height: 20px;width: 420px;float: right;" class="form-control p_riskBeforeByZone" type="text" value="" /> ',
                    ),
                ),
            ),
        );
        custom.append(
            $("<div class='sectionConfOption'>").append(
                $("<span>Risk by zone after: </span>").append(
                    $(
                        '<input style="height: 20px;width: 420px;float: right;" class="form-control p_riskAfterByZone" type="text" value="" /> ',
                    ),
                ),
            ),
        );
        custom.append(
            $("<div class='sectionConfOption'>").append(
                $("<span>Risk missing tests: </span>").append(
                    $(
                        '<input style="height: 20px;width: 420px;float: right;" class="form-control p_riskNoTest" type="text" value="" /> ',
                    ),
                ),
            ),
        );
        custom.append(
            $("<div class='sectionConfOption'>").append(
                $("<span>Risk controls w/o test: </span>").append(
                    $(
                        '<input style="height: 20px;width: 420px;float: right;" class="form-control p_mitNoTest" type="text" value="" /> ',
                    ),
                ),
            ),
        );
        custom.append(
            $("<div class='sectionConfOption'>").append(
                $("<span>Tests for risk controls: </span>").append(
                    $(
                        '<input style="height: 20px;width: 420px;float: right;" class="form-control p_traceMitTest" type="text" value="" /> ',
                    ),
                ),
            ),
        );

        $(custom.find(".p_table")[0]).val(controllerConfig.table);
        $(custom.find(".p_rbm")[0]).val(controllerConfig.rbm);
        $(custom.find(".p_ram")[0]).val(controllerConfig.ram);
        $(custom.find(".p_noTest")[0]).val(controllerConfig.noTest);
        $(custom.find(".p_mustReduce")[0]).val(controllerConfig.mustReduce);
        $(custom.find(".p_noBenefits")[0]).val(controllerConfig.noBenefits);
        $(custom.find(".p_riskAfterByZone")[0]).val(controllerConfig.riskAfterByZone);
        $(custom.find(".p_riskBeforeByZone")[0]).val(controllerConfig.riskBeforeByZone);
        $(custom.find(".p_riskNoTest")[0]).val(controllerConfig.riskNoTest);
        $(custom.find(".p_mitNoTest")[0]).val(controllerConfig.mitNoTest);
        $(custom.find(".p_traceMitTest")[0]).val(controllerConfig.traceMitTest);

        this.addSpecificSettings(controllerConfig, custom);
    }
    async saveSpecificSettingsAsync(ctrl: IDHFControlDefinition, ctrlParameter: IBaseControlOptions, custom: JQuery) {
        let controllerConfig = this.getConfig(ctrl);

        let changed = false;

        let newVal = $(custom.find(".p_rbm")[0]).prop("checked");
        changed = changed || newVal !== controllerConfig.includeInToc;
        controllerConfig.includeInToc = newVal;

        newVal = $(custom.find(".p_rbm")[0]).val();
        changed = changed || newVal !== controllerConfig.rbm;
        controllerConfig.rbm = newVal;

        newVal = $(custom.find(".p_ram")[0]).val();
        changed = changed || newVal !== controllerConfig.ram;
        controllerConfig.ram = newVal;

        newVal = $(custom.find(".p_noTest")[0]).val();
        changed = changed || newVal !== controllerConfig.noTest;
        controllerConfig.noTest = newVal;

        newVal = $(custom.find(".p_mustReduce")[0]).val();
        changed = changed || newVal !== controllerConfig.mustReduce;
        controllerConfig.mustReduce = newVal;

        newVal = $(custom.find(".p_noBenefits")[0]).val();
        changed = changed || newVal !== controllerConfig.noBenefits;
        controllerConfig.noBenefits = newVal;

        newVal = $(custom.find(".p_table")[0]).val();
        changed = changed || newVal !== controllerConfig.table;
        controllerConfig.table = newVal;

        newVal = $(custom.find(".p_riskAfterByZone")[0]).val();
        changed = changed || newVal !== controllerConfig.riskAfterByZone;
        controllerConfig.riskAfterByZone = newVal;

        newVal = $(custom.find(".p_riskBeforeByZone")[0]).val();
        changed = changed || newVal !== controllerConfig.riskBeforeByZone;
        controllerConfig.riskBeforeByZone = newVal;

        newVal = $(custom.find(".p_riskNoTest")[0]).val();
        changed = changed || newVal !== controllerConfig.riskNoTest;
        controllerConfig.riskNoTest = newVal;

        newVal = $(custom.find(".p_mitNoTest")[0]).val();
        changed = changed || newVal !== controllerConfig.mitNoTest;
        controllerConfig.mitNoTest = newVal;

        newVal = $(custom.find(".p_traceMitTest")[0]).val();
        changed = changed || newVal !== controllerConfig.traceMitTest;
        controllerConfig.traceMitTest = newVal;

        let specificChanged = this.setSpecificSettings(controllerConfig, custom);

        this.fieldHandler.setDHFConfig(controllerConfig);

        return changed || specificChanged;
    }
}
