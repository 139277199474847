import { IDHFControlDefinition, mDHF } from "../../../../common/businesslogic";
import { ml } from "../../../../common/matrixlib";
import { IBaseControlOptions } from "../../../../common/UI/Controls/BaseControl";
import { IGenericMap, globalMatrix } from "../../../../globals";

import { IDoubleDefaultControllerConfig } from "../../../../SingleSelectBase";
import { Items } from "../SingleItemsSelection/Items";
import {
    DerivedFromDocFieldHandler,
    IDerivedFromOptions,
} from "../../../../common/businesslogic/FieldHandlers/Document/DerivedFromDocFieldHandler";
import { IDocFieldHandler } from "../../../../common/businesslogic/FieldHandlers/Document/IDocFieldHandler";
import { DoubleSelectBase } from "../../../../DoubleSelectBase";

export { DerivedFrom };

class DerivedFrom extends DoubleSelectBase<IDerivedFromOptions> {
    private itemRender: Items;

    constructor() {
        super();
        let that = this;
        this.itemRender = new Items();
    }

    setFieldHandler(fieldHandler: IDocFieldHandler): void {
        super.setFieldHandler(fieldHandler);
        this.itemRender.setFieldHandler(fieldHandler);

        // TODO: weird logic. try to remove and get rid of the static property on DerivedFromDocFieldHandler class
        $.each(this.itemRender.getConfig({ dhfValue: {} }), function (param, value) {
            (<IGenericMap>DerivedFromDocFieldHandler.default_config.default)[param] = value;
        });
    }

    renderControl(ctrl: IDHFControlDefinition, ctrlParameter: IBaseControlOptions) {
        let hideTypes: string[] = [];
        let traceConfig = globalMatrix.ItemConfig.getTraceConfig();

        // MATRIX-1755 allow to Select DOCs if set as Design
        $.each(mDHF.getDocumentTypes(), function (hidx, cat) {
            let isExcluded = true;

            $.each(traceConfig.rules, function (ridx, rule) {
                if (rule.reporting && rule.category === cat && rule.reporting.indexOf("Design") !== -1) {
                    isExcluded = false;
                }
            });
            if (isExcluded) {
                hideTypes.push(cat);
            }
        });
        //hideTypes.push(mTM.getXTCType());
        let toTypes = ["FOLDER"];
        let allLinkTypes = globalMatrix.ItemConfig.getCategories();
        for (let idx = 0; idx < allLinkTypes.length; idx++) {
            if (hideTypes.indexOf(allLinkTypes[idx]) === -1) {
                toTypes.push(allLinkTypes[idx]);
            }
        }
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        ctrl.control.itemSelectionFromTo(
            <any>ml.JSON.mergeOptions(ctrlParameter, {
                parameter: {
                    prefixFrom: "Include items derived from:",
                    buttonNameFrom: "Select Source Items",
                    showNotFrom: hideTypes,
                    prefixTo: "Include item from these categories:",
                    buttonNameTo: "Select Items to Show",
                    showOnlyTo: toTypes,
                },
            }),
        );
    }

    showSpecificSettings(ctrl: IDHFControlDefinition, ctrlParameter: IBaseControlOptions, custom: JQuery) {
        function showHide(show: boolean) {
            if (show) {
                subOptions.show();
            } else {
                subOptions.hide();
            }
        }
        let controllerConfig: IDerivedFromOptions = this.getConfig(ctrl);

        let itoc =
            '<div class="checkbox" ><label><input type="checkbox" class="includeInToc" ' +
            (controllerConfig.includeInToc ? "checked" : "") +
            "> Add a reference to included items to table of content</label></div>";
        custom.append($(itoc));

        let numo =
            '<div class="checkbox" ><label><input type="checkbox" class="numericalOrder" ' +
            (controllerConfig.numericalOrder ? "checked" : "") +
            "> List elements in numerical order</label></div>";
        custom.append($(numo));

        let renderInfo = $(
            '<select class="q0 docOptionSelect form-control">' +
                '<option value="items">Show the items</option>' +
                '<option value="list">Show item references</option>' +
                '<option value="table">Show as table (source|target)</option>' +
                '<option value="tree">Show as tree (source->target)</option>' +
                "</select>",
        );
        custom.append(renderInfo);

        custom.append(
            $("<div class='noHitMessage'>").append(
                $("<span>Message, if no items<br>match selected input </span>").append(
                    $(
                        '<input autocomplete="off" style="height: 20px;width: 420px;float: right;" class="form-control p_noHitMessage" type="text" value="" /> ',
                    ),
                ),
            ),
        );

        $(".p_noHitMessage").val(controllerConfig.noHitMessage);

        renderInfo.change(function () {
            let info = $(custom.find(".q0")[0]).val();
            showHide(info === "items");
        });

        // TODO: convert to const and make sure it's still works
        // eslint-disable-next-line no-var
        var subOptions = $("<div>");
        custom.append(subOptions);

        this.itemRender.showSpecificSettings(ctrl, ctrlParameter, subOptions, true);

        renderInfo.val(controllerConfig.render);
        showHide($(custom.find(".q0")[0]).val() === "items");

        this.addSpecificSettings(controllerConfig, custom);
    }

    async saveSpecificSettingsAsync(ctrl: IDHFControlDefinition, ctrlParameter: IBaseControlOptions, custom: JQuery) {
        let controllerConfig = this.getConfig(ctrl);

        let changed = await this.itemRender.saveSpecificSettingsAsync(ctrl, ctrlParameter, custom);

        let newVal = $(custom.find(".q0")[0]).val();
        changed = changed || newVal !== controllerConfig.render;
        controllerConfig.render = newVal;

        newVal = $(custom.find(".p_noHitMessage")[0]).val();
        changed = changed || newVal !== controllerConfig.noHitMessage;
        controllerConfig.noHitMessage = newVal;

        newVal = $(custom.find(".numericalOrder")[0]).prop("checked");
        changed = changed || newVal !== controllerConfig.numericalOrder;
        controllerConfig.numericalOrder = newVal;

        newVal = $(custom.find(".numericalOrder")[0]).prop("checked");
        changed = changed || newVal !== controllerConfig.numericalOrder;
        controllerConfig.numericalOrder = newVal;

        $.each(this.itemRender.getConfig(ctrl), function (param, value) {
            (<IGenericMap>controllerConfig)[param] = value;
        });

        let specificChanged = this.setSpecificSettings(controllerConfig, custom);

        this.itemRender.getFieldHandler().setDHFConfig(controllerConfig);

        return changed || specificChanged;
    }

    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    protected config: IDoubleDefaultControllerConfig;
}
