import { app, ControlState, globalMatrix } from "../../../globals";
import { LabelSwitches } from "../Parts/LabelSwitches";
import { IBaseControlOptions, BaseControl } from "./BaseControl";
import { ml } from "./../../matrixlib";
import { FieldHandlerFactory } from "../../businesslogic";
import { FieldDescriptions } from "../../businesslogic/FieldDescriptions";
import { EmptyFieldHandler } from "../../businesslogic/FieldHandlers/EmptyFieldHandler";

export type { ILabelsControlOptions };
export { LabelsControlImpl };

interface ILabelsControlOptions extends IBaseControlOptions {
    controlState?: ControlState;
    canEdit?: boolean;
    help?: string;
    fieldValue?: string;
    valueChanged?: Function;
    parameter?: {
        titleBarControl?: JQuery;
        renderSliders?: boolean; // legacy
        hide_UI?: boolean;
    };
    type?: string; // category
    restrictEditTo?: string[]; // if set a list of labels which can be edited
}

$.fn.labelsControl = function (this: JQuery, options: ILabelsControlOptions) {
    if (!options.fieldHandler) {
        options.fieldHandler = FieldHandlerFactory.CreateHandler(
            globalMatrix.ItemConfig,
            FieldDescriptions.Field_dummy,
            options,
        );
        options.fieldHandler.initData(options.fieldValue);
    }

    let baseControl = new LabelsControlImpl(this, options.fieldHandler as EmptyFieldHandler);
    this.getController = () => {
        return baseControl;
    };
    baseControl.init(options);
    return this;
};

class LabelsControlImpl extends BaseControl<EmptyFieldHandler> {
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private settings: ILabelsControlOptions;
    private space?: JQuery;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private mode: string;

    constructor(control: JQuery, fieldHandler: EmptyFieldHandler) {
        super(control, fieldHandler);
    }

    init(options: ILabelsControlOptions) {
        let that = this;
        let currentLabelsOn: string[] = [];
        let defaultOptions: ILabelsControlOptions = {
            controlState: ControlState.FormView, // read only rendering
            canEdit: false, // whether data can be edited
            valueChanged: function () {}, // callback to call if value changes
            parameter: {
                renderSliders: false,
            },
        };
        this.settings = <any>ml.JSON.mergeOptions(defaultOptions, options);

        if (!this.settings.isItem || !ml.LabelTools.hasLabels()) {
            return;
        }

        this.mode = "item_title";
        if (this.settings.controlState === ControlState.DialogCreate) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            currentLabelsOn = ml.LabelTools.getDefaultLabels(this.settings.type);
            this._root.append(super.createHelp(this.settings));
            this.space = $("<div class='baseControl'>");
            this._root.append(this.space);
            this.mode = "item_create";
        } else if (this.settings.controlState === ControlState.Tooltip) {
            this.mode = "item_title";
            this._root.append(super.createHelp(this.settings));
            this.space = $("<div  class='baseControl controlContainer' >");
            this._root.append(this.space);
            this.settings.canEdit = false;
        }
        // MATRIX-6909 : Review state should behave like historyView
        else if (
            this.settings.controlState === ControlState.HistoryView ||
            this.settings.controlState === ControlState.Zen ||
            this.settings.controlState === ControlState.Review
        ) {
            this.mode = "item_history";
            this._root.append(super.createHelp(this.settings));
            this.space = $("<div>");
            this._root.append(this.space);
            this.settings.canEdit = false;
        } else if (this.settings.controlState === ControlState.Print) {
            this.mode = "item_print";
            this._root.append(super.createHelp(this.settings));
            this.space = $("<div>");
            this._root.append(this.space);
            this.settings.canEdit = false;
        } else {
            this.space = $("<div  class=' controlContainer' >");
            this._root.parent().prepend(this.space);
        }
        this.space.addClass("rowFlex");

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        if (this.settings.parameter.hide_UI) {
            this._root.hide();
            this.space.hide();
        }

        if (!this.space) {
            return;
        }

        if (this.settings.fieldValue) {
            currentLabelsOn = ml.JSON.clone(ml.JSON.fromString(this.settings.fieldValue).value);
        }
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        this.showLabels(currentLabelsOn, this.settings.restrictEditTo);
        this._root.data("original", JSON.stringify(currentLabelsOn));
        this._root.data("new", JSON.stringify(currentLabelsOn));

        // Hide the section if there are  label definitions for those labels.
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        let labelDefinition = ml.LabelTools.getLabelDefinitions([options.type]);
        let allButtonsHidden = labelDefinition.length === 0;
        // Hide the container if all buttons are hidden
        if (allButtonsHidden) {
            this._root.hide();
        }
        if (this.space.html() === "") {
            this.space.remove();
        }
    }

    // public interface
    async hasChangedAsync() {
        return this._root.data("original") !== this._root.data("new");
    }

    async getValueAsync() {
        return this._root.data("new");
    }

    setValue(labelStr: string) {
        let currentLabelsOn: string[] = labelStr ? JSON.parse(labelStr) : [];
        this._root.data("new", labelStr);
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        this.showLabels(currentLabelsOn, null);
        if (this.settings.valueChanged) {
            this.settings.valueChanged.apply(null);
        }
    }
    destroy() {}

    resizeItem() {}

    private showLabels(onList: string[], restrictEditTo: string[]) {
        let that = this;

        if (!this.space) {
            return;
        }

        let currentLabelsOn = ml.JSON.clone(onList);
        this.space.html("");
        let lt = new LabelSwitches(
            this.space,
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            this.settings.canEdit,
            this.settings.type,
            currentLabelsOn,
            this.mode,
            function (newSelection: string[]) {
                // store information and mark item as dirty
                that._root.data("new", JSON.stringify(newSelection));
                if (that.settings.valueChanged) {
                    that.settings.valueChanged.apply(null);
                }
            },
            that.settings.item,
            restrictEditTo,
        );
    }
}
