import { ControlState, globalMatrix, matrixSession } from "../../../globals";
import { IBaseControlOptions, BaseControl } from "./BaseControl";
import { IHtmlFormOptions } from "./htmlform";
import { ml } from "./../../matrixlib";
import { FieldHandlerFactory } from "../../businesslogic";
import { FieldDescriptions } from "../../businesslogic/FieldDescriptions";
import { EmptyFieldHandler } from "../../businesslogic/FieldHandlers/EmptyFieldHandler";

export type { ISyncSourceInfoOptions, ISyncSourceInfo };
export { SyncSourceInfoImpl };

interface ISyncSourceInfoOptions extends IBaseControlOptions {
    controlState?: ControlState;
    canEdit?: boolean;
    help?: string;
    fieldValue?: string;
    valueChanged?: Function;
    parameter?: {
        readonly?: boolean;
    };
}
interface ISyncSourceInfo {
    param: string;
    type: string;
    value: string;
}

$.fn.syncSourceInfo = function (this: JQuery, options: ISyncSourceInfoOptions) {
    if (!options.fieldHandler) {
        options.fieldHandler = FieldHandlerFactory.CreateHandler(
            globalMatrix.ItemConfig,
            FieldDescriptions.Field_syncSourceInfo,
            options,
        );
        options.fieldHandler.initData(JSON.stringify(options.fieldValue));
    }
    let baseControl = new SyncSourceInfoImpl(this, options.fieldHandler as EmptyFieldHandler);
    this.getController = () => {
        return baseControl;
    };
    baseControl.init(options);
    return this;
};

const enum InfoType {
    Date,
    Text,
    Url,
}

class SyncSourceInfoImpl extends BaseControl<EmptyFieldHandler> {
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private settings: ISyncSourceInfoOptions;

    constructor(control: JQuery, fieldHandler: EmptyFieldHandler) {
        super(control, fieldHandler);
    }

    init(options: IHtmlFormOptions) {
        let that = this;

        if (!matrixSession.hasAgileSync()) {
            this._root.append("agile sync module is not licensed");
            return;
        }
        // initialize options
        let defaultOptions = {
            controlState: ControlState.FormView, // read only rendering
            dummyData: false, // fill control with a dumy text (for form design...)
            canEdit: false, // whether data can be edited
            valueChanged: function () {}, // callback to call if value changes
            parameter: {
                readonly: false, // can be set to overwrite the default readonly status
            },
        };
        this.settings = ml.JSON.mergeOptions(defaultOptions, options);
        this._root.css("margin-top", "12px");
        this._root.append(super.createHelp(this.settings));

        let ctrlContainer = $("<div style='padding-bottom: 1px;'>").addClass("baseControl");
        this._root.append(ctrlContainer);
        if (!this.settings.fieldValue) {
            ctrlContainer.append("<span class='noSyncSourceInfo'>no information about sync source known</span>");
            return;
        }

        let syncSourceInfo = <ISyncSourceInfo[]>ml.JSON.fromString(this.settings.fieldValue).value;
        if (!syncSourceInfo || syncSourceInfo.length === 0) {
            return;
        }
        let ul = $("<ul class='syncSourceInfoList'>");
        $.each(syncSourceInfo, function (idx, ssi) {
            ul.append(that.renderValue(ssi.param, ssi.type === "url" ? InfoType.Url : InfoType.Text, ssi.value));
        });
        ctrlContainer.append(ul);
    }

    async hasChangedAsync() {
        return false;
    }

    async getValueAsync() {
        return this.settings.fieldValue;
    }

    setValue(syncStatusString: string) {
        // dummy function - do not remove otherwise the control will removed while syncing
    }
    destroy() {}

    resizeItem() {}

    private renderValue(name: string, infoType: InfoType, value: number | string): JQuery {
        let li = $("<li>");
        li.append($("<span class='syncSourceInfoName'>").html(name + ":"));
        if (typeof value === "undefined") {
            li.append($("<span class='syncSourceInfoValue syncSourceInfoValueUnkonwn'>").html("unkown"));
        } else {
            switch (infoType) {
                case InfoType.Url:
                    li.append(
                        $(
                            "<a class='syncSourceInfoValue helpLink' target='blank' href='" + value.toString() + "'>",
                        ).html(value.toString()),
                    );
                    break;
                case InfoType.Date:
                    li.append(
                        $("<span class='syncSourceInfoValue'>").html(
                            ml.UI.DateTime.renderHumanDate(new Date(value.toString())),
                        ),
                    );
                    break;
                default:
                    li.append($("<span class='syncSourceInfoValue'>").html(value.toString()));
                    break;
            }
        }
        return li;
    }
}
