import { FieldDescriptions } from "../FieldDescriptions";
import { TestManagerConfiguration } from "../TestManagerConfiguration";
import { IBaseDropdownFieldParams } from "./DropdownFieldHandler";
import { IFieldHandler } from "./IFieldHandler";
import { ITestRuleAuto } from "../../../ProjectSettings";

export { TestResultFieldHandler };

// TODO: This is configuring a dropdown control. It should be possible to combine this code
// with the DropdownFieldHandler code.
class TestResultFieldHandler implements IFieldHandler {
    private rawData: string | undefined;
    private human = "";
    private params: IBaseDropdownFieldParams & { automaticOptions?: ITestRuleAuto[] };

    public static UpdateFieldConfig(
        params: IBaseDropdownFieldParams & { automaticOptions?: ITestRuleAuto[] },
        testConfig: TestManagerConfiguration,
    ) {
        // There are default params for dropdown for test results

        params.maxItems = 1; // maxItems which can be selected
        (params.options = []), // options in dropdown  [{id:..., label: ...}]
            (params.create = false), // true if values can be added
            (params.splitHuman = true); // if human values differ from real values (e.g. for XTCs which are passed|ok|p (only the fist part is interesting).

        // This field handler gets its configuration options from the test manager.
        params.options = testConfig.getTestRunResultOptions();
        params.automaticOptions = testConfig.XTCconfig.automatic;
    }

    constructor(params: IBaseDropdownFieldParams, initialValue?: string) {
        this.params = params;
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        this.rawData = undefined;
        this.initData(initialValue);
    }

    getFieldType(): string {
        return FieldDescriptions.Field_test_result;
    }

    getData(): string | undefined {
        return this.rawData;
    }

    setData(value: string, doValidation?: boolean) {
        this.initData(value);
    }

    initData(serializedFieldData: string | undefined) {
        this.rawData = serializedFieldData;

        if (typeof serializedFieldData === "undefined") {
            serializedFieldData = "";
        }

        if (this.params.splitHuman && serializedFieldData.includes("|")) {
            let split = serializedFieldData.split("|");
            this.rawData = split[0];
            this.human = split[split.length - 1];
        } else {
            this.rawData = serializedFieldData;
            for (let value of this.params.options || []) {
                if (value.id === this.rawData) {
                    this.human = value.label;
                    return;
                }
            }
            for (let value of this.params.automaticOptions || []) {
                if (value.code === this.rawData) {
                    this.human = value.human;
                }
            }
        }
    }

    getValues(filterOnOptions = true): string[] {
        if (this.rawData) {
            let values = this.rawData.split(",");
            if (filterOnOptions && !this.params.create) {
                return values.filter((value) => {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    return this.params.options.some((option) => {
                        return option.id === value;
                    });
                });
            } else {
                return values;
            }
        }

        return [];
    }

    getHuman() {
        return this.human;
    }
}
