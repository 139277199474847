import type { IContextPageConfig, IContextPageConfigTab } from "../../ProjectSettings";
// import { mDHF } from "../businesslogic/index";
// import { mTM } from "../businesslogic/index";
import { ContextFrameManager, mDHF } from "../businesslogic/index";
import { ItemControl } from "../UI/Components/index";

import {
    app,
    ControlState,
    globalMatrix,
    IBooleanMap,
    IDevice,
    IEmail,
    IItem,
    IStringStringArrayMap,
    matrixApplicationUI,
    matrixSession,
} from "./../../globals";
import { ml } from "./../matrixlib";
import { IContextFramesTools, IContextInformation } from "./MatrixLibInterfaces";
import { FieldDescriptions } from "../businesslogic/FieldDescriptions";

export { ContextFramesTools };

class ContextFramesTools implements IContextFramesTools {
    static defaultPages: IContextPageConfig = {
        tabs: [
            { title: "Help", type: "help", hipchat: false },
            { title: "Support", type: "support", hipchat: false },
        ],
    };

    private exists: boolean = false;
    private visible: boolean = false;
    private support: boolean = false;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private context: IContextInformation;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private controls: ItemControl[];
    private resetSmartLinks = true;
    private maxNumberOfLinks = 20;
    private shouldBeVisible = false;

    constructor() {
        // to be done once
        let that = this;
        $("#contextframesizer").mousedown(function (e) {
            if (e.preventDefault) e.preventDefault();

            $(document).mousemove(function (e) {
                let contextframeWidth = Math.max(0, $(document).width() - e.pageX + 2);
                if (contextframeWidth < 150) {
                    contextframeWidth = 500;
                    localStorage.setItem("contextframeWidth", contextframeWidth.toString());
                    that.hideContextFrames();
                } else {
                    localStorage.setItem("contextframeWidth", contextframeWidth.toString());
                    that.showContextFrames();
                }
                $(document).trigger("contextFrameResized");

                app.resizeItem(true);
            });
        });

        // to hide / resize context frame if it would be to small
        let resizeHandleTimeout: number;
        $(window).resize(function () {
            clearTimeout(resizeHandleTimeout);
            resizeHandleTimeout = window.setTimeout(function () {
                if (that.visible) {
                    that.showContextFrames();
                }
            }, 300);
        });
    }
    protected setToogleIcon(allowClose: boolean) {
        let faIcon = $("#contextFrameButton span.fal");
        faIcon.removeClass("fa-arrow-to-right");
        faIcon.removeClass("fa-arrow-from-right");
        if (allowClose) {
            faIcon.addClass("fa-arrow-to-right");
        } else {
            faIcon.addClass("fa-arrow-from-right");
        }
    }
    protected toggleFunction() {
        // toggle status;
        let contextframeWidth = Number(localStorage.getItem("contextframeWidth"));
        localStorage.setItem("contextframeWidth", (-contextframeWidth).toString());
        if (this.visible) {
            this.shouldBeVisible = false;
            this.hideContextFrames();
        } else {
            this.shouldBeVisible = true;
            this.showContextFrames();
            this.renderContextFrames();
        }
        this.setToogleIcon(this.shouldBeVisible); // if visible allow close
        app.resizeItem(true);
    }

    public showContextFrame(tabType: string, makeVisible: boolean) {
        let tab = "";
        $.each(this.getTabs(), function (tabIdx, tabDef) {
            if (tabDef.type == tabType) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                tab = tabDef.tabId;
            }
        });
        if (!tab) return false;

        // toggle status;
        if (!this.visible) {
            if (!makeVisible) {
                return false;
            }
            this.showContextFrames();
            this.renderContextFrames();
        }

        $('.nav-tabs a[href="#' + tab + '"]').tab("show");
        return true;
    }

    getExpender() {
        let that = this;
        let direction = this.shouldBeVisible ? "fa-arrow-to-right" : "fa-arrow-from-right";
        let icon = $(
            '<div class="btn-group contextCloseX" ><button id="contextFrameButton" tabindex="-1" class="btn btn-item" data-original-title="Context frame"><span class="fal ' +
                direction +
                '"  ></span></button></div>',
        );
        let faIcon = $(".fal", icon);

        icon.click(function () {
            that.toggleFunction();
        });
        return icon;
    }

    // get tabs enabled for project
    private getTabs(): IContextPageConfigTab[] {
        let tabs: IContextPageConfigTab[] = [];

        let projectHelp: IContextPageConfig =
            globalMatrix.ItemConfig && globalMatrix.ItemConfig.getContextPagesConfig();
        if (projectHelp && projectHelp.tabs) {
            tabs = projectHelp.tabs;
        } else {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            tabs = ContextFramesTools.defaultPages.tabs;
        }

        // Write an id to each tab object.
        tabs.forEach((tab, idx) => {
            tab.tabId = "contextFrameSettingTab_" + idx;
        });
        return tabs;
    }

    visibility(enabled: boolean) {
        if (enabled) {
            if (this.shouldBeVisible && !this.visible) {
                this.showContextFrames();
            }
        } else if (this.visible) {
            this.hideContextFrames();
        }
    }

    // hide context frames, remember as hidden
    hideContextFrames() {
        $(".contextCloseX").show();

        $("#contextframemenu").html("Show Context Pages");

        $("#contextframe").addClass("hidden");
        $("#main").css("margin-right", "5px");
        //$('#contextframesizer').addClass('hidden');
        $("#contextframesizer").css("right", "0px");

        let contextframeWidth: number = Math.abs(Number(localStorage.getItem("contextframeWidth")));
        localStorage.setItem("contextframeWidth", (-contextframeWidth).toString());

        this.visible = false;
        this.setToogleIcon(false); // don't allow close it's closed

        $(document).trigger("contextFrameResized");
    }

    // show context frames, remember as displayed
    showContextFrames() {
        let ww = $(window).width();
        let sw = $("#sidebar").width() + $("#sidebar").position().left;
        let cw = Math.abs(Number(localStorage.getItem("contextframeWidth")));

        if (ww < 767) {
            // mobile device... hide
            $("#contextframe").addClass("hidden");
            $("#main").css("width", "unset");
            $("#contextframesizer").addClass("hidden");

            return;
        }
        // adjust context frame width (i.e. if the screen size changed)
        if (cw > Math.max(ww - sw, 0) / 2) {
            cw = (ww - sw) / 2;
            if (cw < 100) {
                // hide temporarily... until screen is bigger
                $("#main").css("width", "unset");
                $("#contextframesizer").addClass("hidden");

                return;
            }
        }
        localStorage.setItem("contextframeWidth", cw.toString());

        $("#contextframe")
            .css("width", cw - 5 + "px")
            .removeClass("hidden");
        $("#main").css("width", cw + "px");
        $("#contextframesizer")
            .css("right", cw + "px")
            .removeClass("hidden");

        let container = $("#contextframe .contextFrameContainer");
        let content = $("#contextframe .tab-content");
        let tabs = $("#contextframe .contextFrameTabs");

        content.css("height", `calc( 100% - ${tabs.height() + 10}px )`);

        this.visible = true;
        this.setToogleIcon(true);

        $(document).trigger("contextFrameResized");
        $(document).trigger("contextFrameResized");
    }

    private showSupport(panel: JQuery, tabData: IContextPageConfigTab) {
        let that = this;
        if (!this.support) {
            let formContainer = $(`<div data-cy="${tabData.title}">`);
            let requireEmail =
                globalMatrix.matrixBaseUrl.indexOf("demo.matrixreq.com") !== -1 || matrixSession.isSuperAdmin();

            formContainer.append("<p>You can use the form below to create a ticket with the Matrix help desk:</p>");
            formContainer.append(
                '<form id="supportMailForm" role="form">' +
                    '  <div class="form-group"><label for="sup_summary">Summary:</label><input autocomplete="off" type="text" class="form-control" id="sup_summary" /></div>' +
                    '  <div class="form-group"><label for="sup_description">Description:</label> <textarea  class="form-control" id="sup_description" ></textarea> </div>' +
                    (requireEmail
                        ? '  <div class="form-group sup_email"><label for="sup_email">E-mail:</label><input type="email" class="form-control" id="sup_email" /></div>'
                        : "") +
                    '  <div class="text-center"><button href="#" class="btn btn-large btn-success" id="support_send" >Create Ticket</button></div>' +
                    '  <div class="text-center" style="padding:12px"><span id="sup_last_ticket">sending...</span></div>' +
                    '  <div class="form-group sup_metainfo"><label for="sup_project">Related Project(s):</label><input autocomplete="off" type="text" class="form-control" id="sup_project" /></div>' +
                    '  <div class="form-group sup_metainfo"><label for="sup_item">Related Item(s):</label><input autocomplete="off" type="text" class="form-control" id="sup_item" /></div>' +
                    '  <div class="form-group sup_metainfo"><label for="sup_browser">Browser Info:</label><textarea class="form-control" id="sup_browser" ></textarea></div>' +
                    '  <div class="form-group sup_metainfo"><label for="sup_log">Application Log:</label><textarea class="form-control" id="sup_log"></textarea>' +
                    ' <input type="hidden" id="sup_version" />' +
                    "</div>",
            );

            panel.html("");
            panel.append(formContainer);

            $("#supportMailForm").on("keyup keypress", function (e: JQueryKeyEventObject) {
                // prevent people hitting return (even multiple times? ...MATRIX-2253)
                if (e.originalEvent && e.originalEvent.target && (<any>e.originalEvent.target).nodeName == "TEXTAREA") {
                    return true;
                }

                let keyCode = e.keyCode || e.which;
                if (keyCode === 13) {
                    e.preventDefault();
                    return false;
                }
                return true;
            });

            $("#support_send").prop("disabled", true);
            $("#sup_last_ticket").hide();
            $("#sup_summary").on("change keyup paste", function () {
                $("#support_send").prop(
                    "disabled",
                    $("#sup_summary").val() == "" || (requireEmail && $("#sup_email").val() == ""),
                );
            });
            $("#sup_email").on("change keyup paste", function () {
                $("#support_send").prop(
                    "disabled",
                    $("#sup_summary").val() == "" || (requireEmail && $("#sup_email").val() == ""),
                );
            });
            $("#support_send").on("click", function (evt) {
                $("#support_send").prop("disabled", true);
                if (evt.preventDefault) evt.preventDefault();
                if (evt.stopPropagation) evt.stopPropagation();
                let re =
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if (requireEmail && !re.test($("#sup_email").val())) {
                    ml.UI.showError("Invalid Email", "Please enter a correct email.");
                    return;
                }
                let param: IEmail = {
                    summary: $("#sup_summary").val(),
                    description: $("#sup_description").val(),
                    matrixProject: $("#sup_project").val(),
                    matrixItem: $("#sup_item").val(),
                    browser: $("#sup_browser").val(),
                    log: $("#sup_log").val(),
                };
                $("#sup_summary").val("");

                if (requireEmail) {
                    param.email = $("#sup_email").val();
                }
                $("#sup_last_ticket").show();
                let call_param: JQueryAjaxSettings = {
                    type: "POST",
                    url: globalMatrix.matrixBaseUrl + "/rest/1/all/servicedesk",
                    data: JSON.stringify(param),
                    contentType: "application/json; charset=utf-8",
                    dataType: "json",
                    success: function (data: any) {
                        if (data.key) {
                            $("#sup_last_ticket").html("Created ticket " + data.key + " " + param.description);
                        } else {
                            $("#sup_last_ticket").html(data.message);
                        }
                    },
                    error: function (errMsg) {
                        $("#sup_last_ticket").html("Failed to create ticket: " + errMsg);
                    },
                };
                $.ajax(call_param);
            });
            $("#sup_url").val(globalMatrix.matrixBaseUrl);

            this.support = true;
        }
        let tz_offset = -new Date().getTimezoneOffset() / 60;
        let device: IDevice = {
            screen: {
                width: window.screen.width,
                height: window.screen.height,
            },
            viewport: {
                width: 0,
                height: 0,
            },
        };
        let width: number = 0,
            height: number = 0;
        try {
            width =
                window.innerWidth || globalMatrix.doc.documentElement.clientWidth || globalMatrix.doc.body.clientWidth;
        } catch (e) {}
        try {
            height =
                window.innerHeight ||
                globalMatrix.doc.documentElement.clientHeight ||
                globalMatrix.doc.body.clientHeight;
        } catch (e) {}
        device.viewport = {
            width: width,
            height: height,
        };

        $("#sup_browser").val(
            navigator.userAgent +
                "\nTimezone: " +
                tz_offset +
                "\nScreen width: " +
                device.screen.width +
                "\nScreen height: " +
                device.screen.height +
                "\nScreen viewport width: " +
                device.viewport.width +
                "\nScreen viewport height: " +
                device.viewport.height,
        );
        // Get full version for SER ticket creation
        $("#sup_version").val(app.getVersion());

        $("#sup_log").val(ml.Logger.getLog());
        const user = matrixSession.getUser();
        let umail = !globalMatrix.ItemConfig || user == "demo" ? "" : globalMatrix.ItemConfig.getEmail(user);
        $("#sup_email").val(umail);
        $("#sup_user").val(matrixSession ? matrixSession.getUser() : "");
        $("#sup_project").val(matrixSession ? matrixSession.getProject() : "");
        $("#sup_item").val(app ? app.getCurrentItemId() : "");
    }

    private showHelp(panel: JQuery, tabData: IContextPageConfigTab) {
        panel.html("");
        let that = this;
        let help = "";
        if (
            !this.context ||
            !this.context.itemId ||
            (matrixSession && matrixSession.getProject() !== this.context.project)
        ) {
            if (matrixSession) {
                help += '<p class="cathelp_summary">Welcome to the project ' + matrixSession.getProject() + "</p>";
                help += '<p class="">To get started explore the categories on the left.</p>';
                help += "<br>";
                help += "<br>";
                help += "<br>";
                help += "<br>";
                help += "<br>";

                panel.append(help);
            }
            return;
        }
        let item_type = ml.Item.parseRef(this.context.itemId).type;
        let projectHelp = globalMatrix.ItemConfig.getContextPagesConfig();
        if (projectHelp && projectHelp.itemHelp && projectHelp.itemHelp[this.context.itemId]) {
            panel.append('<div class="cathelp text-left">' + projectHelp.itemHelp[this.context.itemId] + "</div>");
        } else if (projectHelp && projectHelp.categoryHelp && projectHelp.categoryHelp[item_type]) {
            panel.append('<div class="cathelp text-left">' + projectHelp.categoryHelp[item_type] + "</div>");
        } else {
            switch (this.context.itemId) {
                case "F-PROJECT":
                    help += '<p class="cathelp_summary">Dashboards, reports and documents</p>';
                    help +=
                        '<p class="">In this folder you find <b>dashboards</b>, <b>reports</b>, <b>documents</b> as well as <b>signed / archived documents</b>.</p>';
                    help += '<ul class="cathelp_details">';
                    help +=
                        "<li>Dashboards are in the folder <b>AUDIT</b>. They can be used to see summaries of activities from the past</li>";
                    help +=
                        "<li>Reports are in the folder <b>REP</b>. Reports help to manage the project by extracting information about the status and the relationship of items in the database.</li>";
                    help +=
                        "<li>Documents are in <b>DOC</b>. Documents allow you to create your own reports with additional document headers and fields such as audit log or signature tables.</li>";
                    help +=
                        "<li>Signed / archived documents are in <b>SIGN</b>. Documents can be archived when a milestone has been reached. Once archived they will not change even if the items in the database changes and they can be signed electronically.</li>";
                    help += "</ul>";
                    break;
                case "AUDIT":
                    help += '<p class="cathelp_summary">Audit tools show activities in the project</b>.</p>';
                    help +=
                        '<p class="">Depending on your project configuration the following tools are available in this folder:</p>';
                    help += '<ul class="cathelp_details">';
                    help += "<li><b>Changes</b> Shows a calendar view with all changes done by day.</li>";
                    help += "<li><b>Deleted Items</b> Show a list of deleted items, ordered by time.</li>";
                    help +=
                        "<li><b>Tags</b> Show a list tags that have been assigned to some point/version in the past.</li>";
                    help +=
                        "<li><b>Document Changes and Downloads</b> Shows when and by whom DOCs and SIGN items have been created and downloaded.</li>";
                    help += " </ul>";
                    break;
                case "DELETED":
                    help +=
                        '<p class="cathelp_summary">Deleted Items. Show a list of deleted items, ordered by time.</p>';
                    help += '<ul class="cathelp_details">';
                    help +=
                        "<li>You can restore deleted items by clicking on <b>restore</b>. This will restore the item into the project tree - in the same folder where it was when it was deleted. The item will be restored with all its history.</li>";
                    help +=
                        "<li>You tag (mark the point in time) when the item was deleted by clicking on <b>tag version</b>.</li>";
                    help += "</ul>";
                    break;
                case "SYNC":
                    help += '<p class="cathelp_summary">Agile Sync.</p>';
                    help += '<ul class="cathelp_details">';
                    help +=
                        "<li>The agile sync module allows you to sync data from external tools such as JIRA, Confluence, TFS, Zephyr into Matrix</li>";
                    help += "<li>This dashboard allows you to sync multiple items quickly</b>.</li>";
                    help += "</ul>";
                    break;
                case "MYDOCS":
                    help += '<p class="cathelp_summary">Signatures.</p>';
                    help += '<ul class="cathelp_details">';
                    help += "<li>Shows documents I need to sign</b>.</li>";
                    help += "</ul>";
                    break;
                case "REDLINE":
                    help += '<p class="cathelp_summary">Redlining allows to compare project data between two dates</p>';
                    help += '<ul class="cathelp_details">';
                    help +=
                        '<li>Select a from and to date/time and click on "compare" to show differences in items.</b>.</li>';
                    help +=
                        '<li>Select a from and click on "Timewarp" to browse the project as it was that day.</b>.</li>';
                    help += "</ul>";
                    break;
                case "REVIEWS":
                    help += '<p class="cathelp_summary">Review Dashboard</p>';
                    help += '<ul class="cathelp_details">';
                    help +=
                        "<li>My Reviews: show all reviews I need to participate in and how much work I did already.</b></li>";
                    help += "<li>All Reviews: show all reviews how much work needs to be done.</b></li>";
                    help += "<li>Reviews Per Item: show all reviews done per item.</b></li>";
                    help += "</ul>";
                    break;

                case "TAGS":
                    help +=
                        '<p class="cathelp_summary">Tags. Show a list of tags that have been assigned to some point/version in the past.</p>';
                    help += '<ul class="cathelp_details">';
                    help += "    <li>Tags mark a point in time when a change occurred.</li>";
                    help +=
                        "    <li>Tags can be set in to changes done in the past (through the CHANGES dashboard).</li>";
                    help +=
                        "    <li>Tags can be used to restore a project until a certain point in the past (branch in the past).</li>";
                    help += "</ul>";
                    break;
                case "CHANGES":
                    help +=
                        '<p class="cathelp_summary">Change Summary. Shows a calendar view with all changes done by day.</p>';
                    help += '<ul class="cathelp_details">';
                    help +=
                        "    <li>In the calendar view you can select a day or selection of days to see what changed in that period.</li>";
                    help += "    <li>You can tag version or just see the details of the changes in this stream.</li>";
                    help += "</ul>";
                    break;
                case "DOCS":
                    help += '<p class="cathelp_summary">Document status and activity</p>';
                    help +=
                        '<p class="">Document Status. Shows when and by whom DOCs and SIGN items have been created, signed and downloaded.</p>';
                    break;
                default:
                    if (this.context.itemId) {
                        let itemId = this.context.itemId;
                        let isFolder = itemId.indexOf("F-") === 0;
                        let isTC = false; // mTM.isTC(item_type);
                        let isXTC = false; // mTM.isXTC(item_type);
                        let folderName = app.getItemTitle("F-" + item_type + "-1");
                        let isSpecialItem = folderName === app.getItemTitle("_NON_EXISTING_CATEGORY__");
                        let isXSLTType = item_type === "DOC" || item_type === "SIGN" || item_type === "REPORT";
                        // For all folders besides reports, docs, sign
                        if (!isXSLTType && isFolder && itemId.lastIndexOf("-1") + 2 === itemId.length) {
                            help +=
                                '<p class="cathelp_summary">This is the root folder of the category <span>' +
                                app.getItemTitle("F-" + item_type + "-1") +
                                "</span></p>";
                            help += '<ul class="cathelp_details">';
                            help +=
                                "    <li>You can create a folder structure to organize the items of this category.</li>";
                            help += "    <li>You can create items of this type</li>";
                            help += "</ul>";
                        } else if (!isXSLTType && isFolder && itemId.lastIndexOf("-1") + 2 !== itemId.length) {
                            help +=
                                '<p class="cathelp_summary">This is a folder of the category <span>' +
                                app.getItemTitle("F-" + item_type + "-1") +
                                "</span></p>";

                            help += '<p class="">You can</p>';
                            help += '<ul class="cathelp_details">';
                            help += "    <li>create more folders of this category inside this folder.</li>";
                            if (!isXTC)
                                help +=
                                    "    <li>create items of the type " +
                                    app.getItemTitle("F-" + item_type + "-1") +
                                    ".</li>";
                            if (isXTC) help += "    <li>create test forms based on defined tests and use cases.</li>";
                            help += "</ul>";
                        }

                        // for all items besides DOC, SIGN, and REPORT, and special custom pages like JIRA, ZOHO
                        if (!isFolder && !isXSLTType && !isSpecialItem) {
                            help +=
                                '<p class="cathelp_summary">This item is of type <span>' + folderName + "</span></p>";
                            help += '<ul class="cathelp_details">';
                            help +=
                                "    <li>You can modify the title or the content and save it (hint: use ctrl-s).</li>";
                            help +=
                                '    <li>You can look at its history (<span class="helpGlyph fal fa-history"></span>).</li>';
                            help +=
                                '    <li>You can look at or modify the up or downlinks (<i class="helpGlyph fal fa-sitemap" aria-hidden="true"></i>).</li>';

                            if (isTC) {
                                help +=
                                    '<li>You can create a test form from this (<span class="helpGlyph fal fa-share">)</span>: <span style="font-style:italic">Prepare for test run</span>.</li>';
                            }

                            if (isXTC) {
                                help +=
                                    '<li>Execute the test. Hints: <ul><li>use keyboard shortcuts to set <span style="font-style:italic">actual results</span>';
                                help +=
                                    '<li><span style="font-style:italic">tab</span> to navigate between the cells</li>';
                                help +=
                                    '<li><span style="font-style:italic">shift return</span> in the comment cells to open a rich text editor, e.g. to attach images and files</li></ul></li>';
                            }

                            if (globalMatrix.ItemConfig.getFieldsOfType("risk2", item_type).length > 0) {
                                help +=
                                    "<li>You can enter the parameters according to the configured risk formula.</li>";
                            }

                            help += "</ul>";

                            help += "<p>Traceability Information:</p>";

                            help += '<ul class="cathelp_details">';

                            $.each([true, false], function (tfi, required) {
                                $.each([false, true], function (tfi, updown) {
                                    let cats = globalMatrix.ItemConfig.getLinkTypes(item_type, updown, required);
                                    let text =
                                        (required ? "required" : "optional") + " " + (updown ? "downlinks" : "uplinks");
                                    if (cats.length === 0) {
                                        help += "    <li>There are no " + text + " configured.</li>";
                                    } else {
                                        help += "    <li>There are " + text + ": " + cats.join(", ") + ".</li>";
                                    }
                                });
                            });

                            help += "</ul>";
                        }
                        // ZOHO JIRA,...
                        if (isSpecialItem) {
                        }

                        // for DOC
                        if (item_type === "DOC") {
                            if (isFolder) {
                                help += '<p class="cathelp_summary">Controlled documents basics</p>';
                                help +=
                                    '<p class="">Controlled documents allow you to extract and analyze data from the database in order to archive and electronically sign it as PDF or Word File.</p>';
                                help += '<p class="">You can</p>';
                                help += '<ul class="cathelp_details">';
                                help +=
                                    "    <li>define what the documents look like by selecting the sections, such as audit trail, purpose, scope, trace tables, list items, etc.</li>";
                                help +=
                                    "    <li>decide which content goes into the document, e.g. all requirements from one specific folder, or all tests results for a selected set of specifications.</li>";
                                help += "    <li>download and review it as word, pdf or html.</li>";
                                help +=
                                    "    <li>archive it (this will make sure the content of document will not change even if the items in the database get updated afterwards)</li>";
                                help += "    <li>electronically sign it</li>";
                                help += "</ul>";
                                help +=
                                    '<img  class="cathelp_img" src="' +
                                    globalMatrix.matrixBaseUrl +
                                    '/img/help/doc0.png">';
                            } else {
                                help += '<p class="cathelp_summary">Controlled document</p>';
                                help += '<p class="">You can</p>';
                                help += '<ul class="cathelp_details">';
                                help += "    <li>modify the document.</li>";
                                help += "    <li>download it as PDF, Word or html.</li>";
                                help += "    <li>archive (freeze) it so that the content does not change anymore.</li>";
                                help += "</ul>";
                            }
                            help += '<p class="cathelp_summary">Defining the structure</p>';
                            help += '<p class="">You can</p>';
                            help += '<ul class="cathelp_details">';
                            if (isFolder)
                                help +=
                                    '    <li>use a wizard to create new documents from scratch, by clicking on "Create Controlled Documents" button.</li>';
                            if (isFolder)
                                help +=
                                    '    <li>copy and paste an existing document from this or another project using the tool menu: <span class="fal fa-share"></span>.</li>';
                            // if (!isFolder) help += '    <li>modify an existing document by clicking on the configure section icon next to "' + mDHF.getArchiveButtonName() + '": <span class="fal fa-list"></span></li>';
                            if (!isFolder)
                                help +=
                                    '    <li>modify an existing document by clicking on the configure section icon next to "' +
                                    mDHF.getArchiveButtonName() +
                                    '": <span class="fal fa-list"></span></li>';
                            help += "</ul>";
                            help += '<p class="">There are two types of document sections</p>';
                            help += '<ul class="cathelp_details">';
                            help += "    <li>Static sections: you type the content</li>";
                            help +=
                                "    <li>Dynamic sections: the content is taken from the database of entered items</li>";
                            help += "</ul>";
                            if (!isFolder) {
                                help +=
                                    '<img  class="cathelp_img" src="' +
                                    globalMatrix.matrixBaseUrl +
                                    '/img/help/doc1.png">';
                                help += '<p class="cathelp_summary">Changing the content and looks of sections</p>';
                                help += '<p class="">You can</p>';
                                help += '<ul class="cathelp_details">';
                                help +=
                                    "    <li>change the content by clicking on the triangle on the left of the section title</li>";
                                help +=
                                    '    <li>the looks by clicking on the <span class="fal fa-cog"></span> on the right of the section title</span></li>';
                                help += "</ul>";
                                help +=
                                    '<img  class="cathelp_img" src="' +
                                    globalMatrix.matrixBaseUrl +
                                    '/img/help/doc2.png">';
                            }
                        }

                        // for SIGN
                        if (item_type === "SIGN") {
                            if (isFolder) {
                                help += '<p class="cathelp_summary">Archived documents</p>';
                                help +=
                                    '<p class="">Controlled documents allow you to extract and analyze data from the database in order to archive and electronically sign it as PDF or Word File.</p>';
                                help += '<ul class="cathelp_details">';
                                help +=
                                    "   <li>Archived documents are controlled documents that have been archived and therefore will not change anymore.</li>";
                                help += "   <li>You can only sign and download archived documents.</li>";
                                help += "</ul>";
                            } else {
                                help += '<p class="cathelp_summary">Archived document</p>';
                                help +=
                                    '<p class="">Controlled documents with a signature table can be signed here.</p>';
                            }
                        }

                        // for REPORTS
                        if (item_type === "REPORT") {
                            if (isFolder) {
                                help += '<p class="cathelp_summary">Reports</p>';
                                help += '<p class="">Reports allow you to manage your project by</p>';
                                help += '<ul class="cathelp_details">';
                                help += "   <li>reviewing items</li>";
                                help += "   <li>reviewing traceability</li>";
                                help += "   <li>analyzing risks</li>";
                                help += "   <li>planning tests</li>";
                                help += "   <li>...</li>";
                                help += "</ul>";
                                help +=
                                    '<p class="">If you want to create documents for certification, audits or archiving, see Controlled Documents (DOC) on the left side.</p>';
                            } else {
                                help += '<p class="cathelp_summary">Report</p>';
                                help += '<p class="">This report can be created as html, PDF or word file.</p>';
                            }
                        }
                    }

                    break;
            }

            panel.append('<div class="cathelp text-left">' + help + "</div>");

            panel.append("<hr>");
            let moreHelp = 'Questions? Here is the online <span id="helpDocu" class="helpLink">user guide</span>';
            if (tabData.hipchat) {
                moreHelp +=
                    ', <a href="http://urlshort.matrixreq.com/helpdesk" target="_blank" class="helpLink" >Contact our service desk</a> with us send us an <span id="helpMail" class="helpLink">e-mail</span>';
            } else {
                moreHelp += ' or send us an <span id="helpMail" class="helpLink">e-mail</span>';
            }

            panel.append("<p>" + moreHelp + ".</p>");
            $("#helpDocu").click(function () {
                window.open("https://urlshort.matrixreq.com/d24/manual/main");
            });

            $("#helpMail").click(function () {
                let email = matrixSession.serverConfig.serviceEmail
                    ? matrixSession.serverConfig.serviceEmail
                    : "support@matrixreq.com";
                window.location.href = "mailto:" + email;
            });
        }
    }

    private showSmartLinksTab(panel: JQuery, tabData: IContextPageConfigTab) {
        $(`a[href='#${tabData.tabId}']`).css("color", "");

        if (this.context.item && !ml.Item.parseRef(this.context.itemId).isFolder && this.resetSmartLinks) {
            this.resetSmartLinks = false; // avoid reloading same frame on tab switch
            panel.html("");
            let smartlinks = $(`<div data-cy="${tabData.title}">`);
            panel.append(smartlinks);
            panel.append("<br>");
            let item = JSON.parse(decodeURI(this.context.item));

            // match references (smart links) into project
            // not (folders) F-REQ-1 not (cross project).../REQ-1
            let regexstr = "((F-)*(/)*(" + globalMatrix.ItemConfig.getCategories(true).join("|") + ")-[1-9]+[0-9]*)";
            let re = new RegExp(regexstr, "g");

            // parse fields
            let links: string[] = [];
            let inSection: IStringStringArrayMap = {};
            $.each(globalMatrix.ItemConfig.getItemConfiguration(item.type).fieldList, function (idx, field) {
                if (
                    field.fieldType === FieldDescriptions.Field_richtext ||
                    field.fieldType === FieldDescriptions.Field_steplist ||
                    field.fieldType === FieldDescriptions.Field_test_steps ||
                    field.fieldType === FieldDescriptions.Field_test_steps_result ||
                    field.fieldType === FieldDescriptions.Field_dhf
                ) {
                    let fieldVal = item[field.id];
                    let match = fieldVal ? fieldVal.match(re) : null;
                    if (match) {
                        $.each(match, function (midx, m) {
                            if (m.indexOf("F-") == 0 || m.indexOf("/") == 0) return; // folder or cross project are not yet supported
                            if (links.indexOf(m) === -1) {
                                links.push(m);
                            }
                            let sn =
                                field.fieldType === FieldDescriptions.Field_dhf
                                    ? JSON.parse(fieldVal).name
                                    : field.label;
                            if (!inSection[m]) {
                                inSection[m] = [];
                            }
                            if (inSection[m].indexOf(sn) == -1) {
                                inSection[m].push(sn);
                            }
                        });
                    }
                }
            });
            if (links.length === 0) {
                smartlinks.append($("<div class='contextFrame-select-item'>").html("there are no smart links"));
            } else if (links.length > this.maxNumberOfLinks) {
                this.renderIds(
                    tabData,
                    links,
                    smartlinks,
                    "contextFramePreview contextFramePreviewUp",
                    inSection,
                    "There are to many smart links to show details.",
                );
            } else {
                this.renderFromIds(tabData, links, smartlinks, "contextFramePreview contextFramePreviewUp", inSection);
            }
        } else if (this.resetSmartLinks) {
            panel.html("").append($("<div class='contextFrame-select-item'>").html("select an item to see smartlinks"));
        }
    }

    private showReferencesTab(panel: JQuery, tabData: IContextPageConfigTab) {
        if (this.context.item && !ml.Item.parseRef(this.context.itemId).isFolder) {
            panel.html("");
            let uplinks = $("<div>");
            let downlinks = $("<div>");
            panel.append(uplinks);
            panel.append("<hr>");
            panel.append(downlinks);
            panel.append("<br>");
            let item: IItem = JSON.parse(decodeURI(this.context.item));

            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            if (item.upLinks.length === 0) {
                uplinks.append($("<div class='contextFrame-select-item'>").html("there are no uplinks"));
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            } else if (item.upLinks.length > this.maxNumberOfLinks) {
                this.renderIds(
                    tabData,
                    this.getUpLinks(item),
                    uplinks,
                    "contextFramePreview contextFramePreviewUp",
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    null,
                    "There are to many up links to show details.",
                );
            } else {
                this.renderFromIds(
                    tabData,
                    this.getUpLinks(item),
                    uplinks,
                    "contextFramePreview contextFramePreviewUp",
                );
            }

            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            if (item.downLinks.length === 0) {
                downlinks.append($("<div class='contextFrame-select-item'>").html("there are no downlinks"));
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            } else if (item.downLinks.length > this.maxNumberOfLinks) {
                this.renderIds(
                    tabData,
                    this.getDownLinks(item),
                    downlinks,
                    "contextFramePreview contextFramePreviewUp",
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    null,
                    "There are to many down links to show details.",
                );
            } else {
                this.renderFromIds(
                    tabData,
                    this.getDownLinks(item),
                    downlinks,
                    "contextFramePreview contextFramePreviewDown",
                );
            }
        } else {
            panel
                .html("")
                .append($("<div class='contextFrame-select-item'>").html("select an item to see up and downlinks"));
        }
    }

    private showItemsInFolderTab(panel: JQuery, tabData: IContextPageConfigTab) {
        if (this.context.item && ml.Item.parseRef(this.context.itemId).isFolder) {
            panel.html("");
            let items = $("<div>");
            panel.append(items);
            panel.append("<br>");

            this.renderAllInFolder(this.context.itemId, items);
        } else {
            panel
                .html("")
                .append($("<div class='contextFrame-select-item'>").html("select a folder to see it's items"));
        }
    }

    private showUpReferences(panel: JQuery, tabData: IContextPageConfigTab) {
        if (this.context.item && !ml.Item.parseRef(this.context.itemId).isFolder) {
            let links = $("<div>");
            panel.append(links);
            panel.append("<br>");

            this.renderAllUpOrDown(this.context.itemId, false, links);
        } else {
            panel
                .html("")
                .append($("<div class='contextFrame-select-item'>").html("select an item to see the up references"));
        }
    }

    private showDownReferences(panel: JQuery, tabData: IContextPageConfigTab) {
        if (this.context.item && !ml.Item.parseRef(this.context.itemId).isFolder) {
            let links = $("<div>");
            panel.append(links);
            panel.append("<br>");
            this.renderAllUpOrDown(this.context.itemId, true, links);
        } else {
            panel
                .html("")
                .append($("<div class='contextFrame-select-item'>").html("select an item to see the down references"));
        }
    }

    private getUpLinks(item: IItem): string[] {
        let toDo: string[] = [];
        $.each(item.upLinks, function (lidx, link) {
            toDo.push(link.to);
        });
        return toDo;
    }

    private getDownLinks(item: IItem): string[] {
        let toDo: string[] = [];
        $.each(item.downLinks, function (lidx, link) {
            toDo.push(link.to);
        });
        return toDo;
    }

    // get full items for a number of items and render them
    private renderFromIds(
        tabData: IContextPageConfigTab,
        toDoList: string[],
        render: JQuery,
        renderClass: string,
        inSection?: IStringStringArrayMap,
    ) {
        let that = this;
        if (toDoList.length === 0) {
            return;
        }
        let searchExpr = toDoList
            .map(function (id) {
                return "id=" + id;
            })
            .join(" OR ");

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        app.getNeedlesAsync(searchExpr, false, true, "*", true, true).done(async function (items: IItem[]) {
            for (let itemId of toDoList) {
                let data = items.filter(function (item) {
                    return item.id == itemId;
                });
                if (data.length == 0) {
                    let linkDoesNotExist = $("<div class='contextFramePreview contextFramePreviewError'>");
                    render.append(linkDoesNotExist);
                    matrixApplicationUI.renderErrorControl(
                        linkDoesNotExist,
                        "" + itemId + " does not exist",
                        "Please correct the link.",
                        true,
                    );
                    $(`a[href='#${tabData.tabId}']`).css("color", "red");
                } else if (app.canViewItem(data[0])) {
                    let preview = $("<div class='" + renderClass + "'>");
                    render.append(preview);

                    let ctrl = new ItemControl({
                        control: preview,
                        controlState: ControlState.Tooltip,
                        item: data[0],
                        isItem: typeof data[0].children === "undefined",
                    });
                    await ctrl.load();
                    that.controls.push(ctrl);
                } else {
                    let noView = $("<div class='contextFramePreview contextFramePreviewError'>");
                    render.append(noView);
                    matrixApplicationUI.renderErrorControl(
                        noView,
                        "You have no rights to view " + itemId + "",
                        "Talk to the project administrator.",
                        true,
                    );
                }
                if (inSection && inSection[itemId] && inSection[itemId].length) {
                    render.append($("<div class='TitleSpecial'>").html("used in: " + inSection[itemId].join(",")));
                }
            }
        });
    }

    // show item ids as a flat list
    private renderIds(
        tabData: IContextPageConfigTab,
        toDoList: string[],
        render: JQuery,
        renderClass: string,
        inSection?: IStringStringArrayMap,
        infotext?: string,
    ) {
        let that = this;

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        render.append(infotext);
        render.append("<br>");

        let ul = $("<div>").appendTo(render);

        $.each(toDoList, function (idx, itemId) {
            let title = app.getItemTitle(itemId);
            if (!title) {
                let dash = itemId.indexOf("-");
                $(
                    "<li><span style='font-weight:bold;color:red'><span>" +
                        itemId.substr(0, dash) +
                        "</span>" +
                        itemId.substr(dash) +
                        "</span> does not exist.</li>",
                ).appendTo(ul);
                $(`a[href='#${tabData.tabId}']`).css("color", "red");
            } else {
                $("<li>" + itemId + "!</li>").appendTo(ul);
            }
        });
        ul.highlightReferences();
    }

    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private nonBlockingRenderNext: number;
    private async nonBlockingRender(
        items: IItem[],
        index: number,
        renderClass: string,
        render: JQuery,
        hideShow: boolean,
        categoryFilter?: IBooleanMap,
    ) {
        let that = this;

        if (items.length <= index) return;

        let item = items[index];
        if (app.canViewItem(item)) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            let type = ml.Item.parseRef(item.id).type;
            let preview = $("<div class='" + renderClass + " " + type + "'>");

            render.append(preview);
            // need to hide after adding to DOM
            if (hideShow && categoryFilter && categoryFilter[type] === false) preview.hide();

            let ctrl = new ItemControl({
                control: preview,
                controlState: ControlState.Tooltip,
                item: item,
                isItem: typeof item.children === "undefined",
            });
            await ctrl.load();
            that.controls.push(ctrl);
            // need to re-hide after adding to rendering
            if (hideShow && categoryFilter && categoryFilter[type] === false) preview.hide();
        } else {
            let noView = $("<div class='contextFramePreview contextFramePreviewUp'>");
            render.append(noView);
            matrixApplicationUI.renderErrorControl(
                noView,
                "You have no rights to view " + item.id + "",
                "Talk to the project administrator.",
                true,
            );
        }

        this.nonBlockingRenderNext = window.setTimeout(async function () {
            await that.nonBlockingRender(items, index + 1, renderClass, render, hideShow, categoryFilter);
        }, 200);
    }

    private renderAllUpOrDown(fromItemId: string, down: boolean, render: JQuery) {
        let that = this;

        let renderSettings = {
            recursive: localStorage.getItem("recursive") == "1",
        };
        let filter = $("<div style='display:table'>").appendTo(render);
        ml.UI.addCheckbox(filter, "recursively", renderSettings, "recursive", function () {
            localStorage.setItem("recursive", renderSettings.recursive ? "1" : "0");
            render.html("");
            that.renderAllUpOrDown(fromItemId, down, render);
        })
            .css("display", "table-cell")
            .addClass("showall_recursive");

        let renderClass = down
            ? "contextFramePreview contextFramePreviewDown"
            : "contextFramePreview contextFramePreviewUp";

        let recurse = renderSettings.recursive ? "m" : "";
        let searchExpr = down
            ? "uplink" + recurse + "=" + fromItemId
            : "category!=XTC and downlink" + recurse + "=" + fromItemId;

        window.clearTimeout(this.nonBlockingRenderNext);
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        app.getNeedlesAsync(searchExpr, false, true, "*", true, true).done(function (items: IItem[]) {
            if (items.length == 0) {
                that.showNothingFound(render);
                return;
            }

            // find out which categories are returned and add checkbox to UI to show/hide that category
            let categories: string[] = [];
            let categoryFilter: IBooleanMap = {}; // default is show all

            for (let item of items) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                let type = ml.Item.parseRef(item.id).type;
                if (categories.indexOf(type) == -1) {
                    // by default 'show items"
                    if (typeof categoryFilter[type] == "undefined") {
                        categoryFilter[type] = type != "XTC";
                    }
                    ml.UI.addCheckbox(filter, type, categoryFilter, type, function () {
                        if (categoryFilter[type] === true) $("." + type, render).show();
                        else $("." + type, render).hide();
                    })
                        .css("display", "table-cell")
                        .css("padding-left", "12px")
                        .addClass("showall_" + type);
                    categories.push(type);
                }
            }
            // render items
            that.nonBlockingRenderNext = window.setTimeout(async () => {
                that.nonBlockingRender(items, 0, renderClass, render, true, categoryFilter);
            }, 200);
        });
    }

    private renderAllInFolder(folderId: string, render: JQuery) {
        let that = this;

        let renderSettings = {
            recursive: localStorage.getItem("recursiveFolder") == "1",
        };
        ml.UI.addCheckbox(render, "recursively", renderSettings, "recursive", function () {
            localStorage.setItem("recursiveFolder", renderSettings.recursive ? "1" : "0");
            render.html("");
            that.renderAllInFolder(folderId, render);
        }).addClass("showall_recursive");

        let renderClass = "contextFramePreview contextFramePreviewUp";

        let searchExpr = "folder" + (renderSettings.recursive ? "m" : "") + "=" + folderId;

        window.clearTimeout(this.nonBlockingRenderNext);
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        app.getNeedlesAsync(searchExpr, false, true, "*", true, true).done(function (items: IItem[]) {
            if (items.length == 0) {
                that.showNothingFound(render);
                return;
            }
            that.nonBlockingRenderNext = window.setTimeout(async function () {
                await that.nonBlockingRender(items, 0, renderClass, render, false);
            }, 200);
        });
    }

    private showNothingFound(render: JQuery) {
        render.append("No items found.");
    }

    // write data into frames
    renderContextFrames() {
        this.controls = [];
        if (!this.exists || !this.visible || !this.context) {
            return;
        }
        let that = this;
        $.each(this.getTabs(), function (tabIdx: number, tabData: IContextPageConfigTab) {
            let panel = $(`#${tabData.tabId}`);
            if (tabData.type === "faq") {
                // this is rendered statically once - keep it
            } else if (tabData.type === "support") {
                that.showSupport(panel, tabData);
            } else if (tabData.type === "smartlinks") {
                that.showSmartLinksTab(panel, tabData);
            } else if (tabData.type === "help") {
                that.showHelp(panel, tabData);
            } else if (!panel.hasClass("active")) {
                // don't actually render it just now, wait until user shows it
                if (!panel.hasClass("noRefresh")) {
                    panel.html("").append(ml.UI.getSpinningWait("retrieving data"));
                }
            } else if (tabData.type === "iframeget") {
                let jp: IContextInformation = ml.JSON.clone(that.context);
                jp.item = "";
                jp.fieldList = "";
                let param = $.param(jp, true);
                let page = tabData.baseURL + "?" + param;
                (<HTMLIFrameElement>$(".contextframeIframeget", panel)[0]).src = page;
            } else if (tabData.type === "iframe") {
                let iframePoster = $(
                    '<form style="display:none" target="contextframeIframe_' +
                        tabIdx +
                        '" method="post" action="' +
                        tabData.baseURL +
                        '">',
                );
                $("body").append(iframePoster);
                $.each(that.context, function (vaIdx, va) {
                    iframePoster.append('<input type="hidden" name="' + vaIdx + '" value="' + va + '">');
                });
                iframePoster.submit().remove();
            } else if (ContextFrameManager.implements(tabData.type)) {
                ContextFrameManager.renderTab(panel, tabData.type, tabData, that.context);
            } else if (tabData.type === "references") {
                that.showReferencesTab(panel, tabData);
            } else if (tabData.type === "foldercontent") {
                that.showItemsInFolderTab(panel, tabData);
            } else if (tabData.type === "upreferences") {
                panel.html("");
                that.showUpReferences(panel, tabData);
            } else if (tabData.type === "downreferences") {
                panel.html("");
                that.showDownReferences(panel, tabData);
            } else {
                panel.html(tabData.type);
            }
        });
    }

    fillContextFrame(_data: IItem, itemId: string) {
        if (!this.exists) {
            return;
        }
        // save the data
        this.context = {
            project: matrixSession.getProject(),
            user: matrixSession.getUser(),
            server: globalMatrix.matrixBaseUrl,
            version: app.getVersion(),
            product: globalMatrix.matrixProduct,
            itemId: itemId,
            item: _data ? encodeURI(JSON.stringify(_data)) : "",
            fieldList: _data
                ? // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                  encodeURI(JSON.stringify(globalMatrix.ItemConfig.getItemConfiguration(_data.type).fieldList))
                : "",
        };

        // fill the frames
        this.resetSmartLinks = true;
        this.renderContextFrames();
    }

    // show context frames, remember as displayed
    init() {
        let contextBar = $("#contextframesizer");
        let container = $("#contextframe");

        this.support = false; // make sure support tab is initialized
        this.exists = false;
        if ($("#contextframe").length === 0) {
            // client has no context frames (e.g. admin, mobile, ...)
            return;
        }
        container.empty();
        if (this.getTabs().length === 0) {
            return;
        }
        let that = this;

        this.exists = true;

        // paint the tabs
        let tabpanel = $('<div role="tabpanel" class="tabpanel-container contextFrameContainer">');
        container.append(tabpanel);

        let tabpanelul = $('<ul class="nav nav-tabs contextFrameTabs" role="tablist">');
        tabpanel.append(tabpanelul);
        let tabpanels = $('<div class="tab-content">');
        tabpanel.append(tabpanels);

        $.each(this.getTabs(), function (tabIdx: number, tabData: IContextPageConfigTab) {
            tabpanelul.append(
                '<li data-cy="tab ' +
                    tabData.title +
                    '" role="presentation" ' +
                    (tabIdx === 0 ? 'class="active"' : "") +
                    '><a href="#' +
                    tabData.tabId +
                    '"  role="tab" data-toggle="tab">' +
                    tabData.title +
                    "</a></li>",
            );
            let panel = $(
                '<div role="tabpanel"  style="height:100%" class="tabpaneltab tab-pane ' +
                    (tabIdx === 0 ? "active" : "") +
                    '" id="' +
                    tabData.tabId +
                    '" >',
            );
            tabpanels.append(panel);
            if (tabData.type === "iframe") {
                panel.append(
                    `<iframe data-cy="iframe ${tabData.title}" class="contextframeIframe" name="contextframeIframe_${tabIdx}"></iframe>`,
                );
            }
            if (tabData.type === "iframeget") {
                panel.append(
                    `<iframe data-cy="iframe ${tabData.title}"  class="contextframeIframeget" name="contextframeIframe_${tabIdx}"></iframe>`,
                );
            } else if (tabData.type === "faq") {
                panel.append(
                    `<iframe data-cy="iframe ${tabData.title}" class="contextframeIframe" name="contextframeIframe_${tabIdx}" src="https://urlshort.matrixreq.com/d24/faq" allowfullscreen="allowfullscreen"></iframe>`,
                );
            } else if (tabData.type === "support") {
                that.showSupport(panel, tabData);
            } else if (tabData.type === "help") {
                that.showHelp(panel, tabData);
            }
        });

        $('a[data-toggle="tab"]', tabpanel).on("shown.bs.tab", function (e) {
            let target = $(e.target).attr("href"); // activated tab
            that.renderContextFrames();
        });

        // prepare initial size
        if (!localStorage.getItem("contextframeWidth")) {
            // it has never been shown, calculate default size amd
            let contextframeWidth: number = $(document).width() - 722 - $("#main").position().left;
            if (contextframeWidth < 400) {
                contextframeWidth = 400;
            }
            localStorage.setItem("contextframeWidth", contextframeWidth.toString());
        }

        // show frames if they should be visible
        let contextframeWidth = Number(localStorage.getItem("contextframeWidth"));
        if (Number(contextframeWidth) > 0) {
            this.shouldBeVisible = true;
            that.showContextFrames();
        } else {
            this.shouldBeVisible = false;
            that.hideContextFrames();
        }

        // to avoid bug that mouse up is stolen by iframe
        $(".contextframeIframe").mouseenter(function (e) {
            $(document).off("mousemove");
        });
        $(".contextframeIframeget").mouseenter(function (e) {
            $(document).off("mousemove");
        });
    }
}
