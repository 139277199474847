import { ControlState, globalMatrix, matrixSession } from "../../../globals";
import { Tasks } from "../../businesslogic/Tasks";
import { IBaseControlOptions, BaseControl } from "./BaseControl";
import { ml } from "./../../matrixlib";
import { FieldHandlerFactory } from "../../businesslogic";
import { FieldDescriptions } from "../../businesslogic/FieldDescriptions";
import { EmptyFieldHandler } from "../../businesslogic/FieldHandlers/EmptyFieldHandler";

export type { ITasksControlOptions };
export { TasksControlImpl };

interface ITasksControlOptions extends IBaseControlOptions {
    controlState?: ControlState;
    canEdit?: boolean;
    help?: string;
    fieldValue?: any;
    valueChanged?: Function;
    parameter?: {
        readonly?: boolean;
        plugins?: number[]; // can be a positive filter to show only some plugins, e.g. [100] will only allow AnyUrl links
    };
    readOnly?: boolean;
}

$.fn.tasksControl = function (this: JQuery, options: ITasksControlOptions) {
    if (!options.fieldHandler) {
        options.fieldHandler = FieldHandlerFactory.CreateHandler(
            globalMatrix.ItemConfig,
            FieldDescriptions.Field_tasksControl,
            options,
        );
        options.fieldHandler.initData(JSON.stringify(options.fieldValue));
    }
    let baseControl = new TasksControlImpl(this, options.fieldHandler as EmptyFieldHandler);
    this.getController = () => {
        return baseControl;
    };
    baseControl.init(options);
    return this;
};

class TasksControlImpl extends BaseControl<EmptyFieldHandler> {
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private settings: ITasksControlOptions;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private dragCounter: number;

    constructor(control: JQuery, fieldHandler: EmptyFieldHandler) {
        super(control, fieldHandler);
    }

    init(options: ITasksControlOptions) {
        let defaultOptions: ITasksControlOptions = {
            controlState: ControlState.FormView, // read only rendering
            canEdit: false, // whether data can be edited
            fieldValue: [], //
            parameter: {
                readonly: false, // can be set to overwrite the default readonly status
            },
            readOnly: false,
        };
        this.settings = <ITasksControlOptions>ml.JSON.mergeOptions(defaultOptions, options);
        this.init2();
    }

    updateControl() {
        this.destroy();
        this._root.html("");
        this.init2();
    }

    init2() {
        let that = this;

        if (this.settings.controlState === ControlState.Print) return; // not showing links (to expensive to get it just for this...)
        if (this.settings.controlState === ControlState.DialogEdit) return; // not showing in dialog : the dialog might show other item than the one currently selected in tree
        if (this.settings.controlState === ControlState.Tooltip) return; // nothing to do
        if (this.settings.controlState === ControlState.HistoryView) return; // nothing to do
        if (this.settings.controlState === ControlState.Zen) return; // nothing to do
        if (this.settings.controlState === ControlState.DialogCreate) return; // nothing to do

        this._root.addClass("tasksControl");

        this._root.append(super.createHelp(this.settings));
        let theTasks = $("<div class='baseControl'>").appendTo(this._root);

        this.dragCounter = 0;
        if (this.settings.canEdit) {
            this._root
                .on("dragover", function (e) {
                    if (e.preventDefault) e.preventDefault();
                    if (e.stopPropagation) e.stopPropagation();
                    return false;
                })
                .on("dragenter", function (e) {
                    if (matrixSession.isEditor()) {
                        that._root.addClass("drop-target-active");
                    }
                    if (e.preventDefault) e.preventDefault();
                    if (e.stopPropagation) e.stopPropagation();
                    that.dragCounter++;
                    return false;
                })
                .on("dragleave", function (e) {
                    that.dragCounter--;
                    if (that.dragCounter === 0) {
                        that._root.removeClass("drop-target-active");
                    }
                    if (e.preventDefault) e.preventDefault();
                    if (e.stopPropagation) e.stopPropagation();
                });
            this._root[0].addEventListener("drop", (event: DragEvent) => this.onDrop(event), false);
        }
        Tasks.showTasks(
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            this.settings.item.id,
            theTasks,
            this.settings.canEdit && matrixSession.isEditor(),
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            this.settings.parameter.plugins,
        );
    }

    // implement interface
    async hasChangedAsync() {
        return false;
    }
    async getValueAsync() {
        return "";
    }
    destroy() {
        if (this._root) {
            this._root.off();
            this._root[0].removeEventListener("drop", this.onDrop, false);
        }
    }
    resizeItem() {}

    getPluginFilter() {
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        return this.settings.parameter.plugins;
    }
    // on drop function
    private onDrop(e: DragEvent) {
        if (e.preventDefault) e.preventDefault();
        if (e.stopImmediatePropagation) e.stopImmediatePropagation();

        if (!matrixSession.isEditor()) {
            return;
        }

        this.dragCounter = 0;
        this._root.removeClass("drop-target-active");

        let hasFiles = false;
        let types: string[] = [];
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        $.each(e.dataTransfer.types, function (idx, type) {
            // special treatment for firefox (in normal browsers the array is a real array :-)
            types.push(type);
            hasFiles = hasFiles || type === "Files";
        });

        if (hasFiles) {
            ml.UI.showError("You cannot drag files into this control", "");
            return;
        }

        let uri = types.indexOf("text/uri-list");
        if (uri) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            Tasks.createTaskFromUrl(this.settings.item.id, e.dataTransfer.getData("text/uri-list"));
        }
    }
}
