import { globalMatrix } from "../../../globals";
import { IVizMode, SelectMode } from "./ProjectViewDefines";
import { ProjectView } from "./ProjectView";

export { ViewModeSelector };

// this function manages the display style selection of the tree
class ViewModeSelector {
    private isMainTree: boolean;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private btn: JQuery;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private img: JQuery;
    private searchVizModes: IVizMode[];
    private panel: ProjectView;

    constructor(panel: ProjectView) {
        this.panel = panel;
        this.isMainTree = panel.settings.canSelectItems;

        this.searchVizModes = [
            // uid: a unique id used by selenium tests...
            // icon: icon to show
            // text: text in drop down
            // maintree: whether available in main tree,
            // asList: whether to show as list
            // hide: whether to hide elements which don't match
            // expand: whether to expand tree
            {
                uid: "0",
                icon: "search_dimtree.svg",
                text: "grey out mismatches",
                mainTree: true,
                asList: false,
                hide: false,
                check: false,
                expand: false,
            },
            {
                uid: "1",
                icon: "search_expanddimtree.svg",
                text: "grey out mismatches and expand tree",
                mainTree: true,
                asList: false,
                hide: false,
                check: false,
                expand: true,
            },
            {
                uid: "2",
                icon: "search_hidetree.svg",
                text: "hide mismatches",
                mainTree: true,
                asList: false,
                hide: true,
                check: false,
                expand: false,
            },
            {
                uid: "3",
                icon: "search_expandtree.svg",
                text: "hide mismatches and expand tree",
                mainTree: true,
                asList: false,
                hide: true,
                check: false,
                expand: true,
            },

            //    ,{ icon:"search_checklist.png", text:"select in list",   mainTree:false,   asList:true,  hide:true,    check:true , expand:false}
            //   ,{ icon:"search_checktree.png", text:"select in tree",   mainTree:false,   asList:false, hide:false,   check:true, expand:false }
        ];
        if (!this.selectFoldersOnly()) {
            this.searchVizModes.push({
                uid: "4",
                icon: "search_list.svg",
                text: "show as list",
                mainTree: true,
                asList: true,
                hide: true,
                check: false,
                expand: false,
            });
        }
        if (this.panel.settings.isConfigSearch ?? false) {
            this.searchVizModes = this.searchVizModes.filter((i) => {
                return i.uid === "1";
            });
        }
    }

    private selectFoldersOnly() {
        return !!(
            this.panel &&
            this.panel.settings &&
            (this.panel.settings.selectMode == SelectMode.singleFolder ||
                this.panel.settings.selectMode == SelectMode.folders)
        );
    }
    getVizModeControl(onChange: () => void): JQuery {
        let that = this;

        let lastMode = this.getMode();

        let current = this.searchVizModes[lastMode];
        let btn_grp = $('<div class="searchVizMode" style="">');
        this.btn = $(
            '<div data-cy="searchMode"" type="button" title="" data-original-title="Tree search filter mode" data-toggle="dropdown" class=" earchVizMode btn btn-stree btn-sm dropdown-toggle">',
        );
        this.img = $(
            '<img style="height:24px; margin-left: 0px;" src="' +
                globalMatrix.matrixBaseUrl +
                "/img/" +
                current.icon +
                '" />',
        );
        this.btn.append(`<div class="btnContent"> <img style="margin-left: -6px;" src="${
            globalMatrix.matrixBaseUrl + "/img/" + current.icon
        }" />
                                   <span style="padding-left:10px">${current.text}<span></div>`);

        this.btn.append('<span style="margin-left: 0px;"  class="caret">');
        btn_grp.append(this.btn);
        this.btn.data("uid", current.uid);
        this.setEnabled(false);
        let ul = $(' <ul class="dropdown-menu dropdown-menu-sub" role="menu"> ');
        for (let idx = 0; idx < this.searchVizModes.length; idx++) {
            if (!this.isMainTree || this.searchVizModes[idx].mainTree) {
                let img_src = globalMatrix.matrixBaseUrl + "/img/" + this.searchVizModes[idx].icon;

                let li = $(`<li data-cy="${this.searchVizModes[idx].text}"  title="${this.searchVizModes[idx].text}">
                        <a href="javascript:void(0)">
                            <img style="margin-left: -6px;" src="${img_src}" />
                            <span style="padding-left:10px">${this.searchVizModes[idx].text}<span>
                        </a>
                    </li>`);
                ul.append(li);
                li.click((event: JQueryEventObject) => {
                    let selIdx = $(event.delegateTarget).data("lidx");
                    let imgLink = globalMatrix.matrixBaseUrl + "/img/" + this.searchVizModes[selIdx].icon;
                    that.btn.data("uid", $(event.delegateTarget).data("uid"));
                    that.btn.find(".btnContent").html(`<img style="margin-left: -6px;" src="${imgLink}" />
                            <span style="padding-left:10px">${this.searchVizModes[selIdx].text}<span>`);

                    if (
                        that.panel.settings.isConfigSearch == undefined ||
                        that.panel.settings.isConfigSearch == false
                    ) {
                        that.setMode(selIdx);
                    }
                    onChange();
                })
                    .data("lidx", idx)
                    .data("uid", this.searchVizModes[idx].uid)
                    .data("img_src", img_src);
            }
        }
        btn_grp.append(ul);
        return btn_grp;
    }

    // function to grey out button (in case nothing is in search bar)
    setEnabled(enabled: boolean): void {
        $("img", this.btn).css("opacity", enabled ? 1 : 0.3);
    }

    showAsList(): boolean {
        return this.searchVizModes[this.getMode()].asList;
    }

    hideMismatches(): boolean {
        return this.searchVizModes[this.getMode()].hide;
    }

    isExpandTree(): boolean {
        return this.searchVizModes[this.getMode()].expand;
    }

    // rememebered last filter
    private getMode(): number {
        let svm = localStorage.getItem("searchFilter" + (this.isMainTree ? "Select" : "Main"));
        if (svm) {
            let option = parseInt(svm);
            return option >= this.searchVizModes.length ? 0 : option;
        } else {
            return 0; // default dimmable tree
        }
    }
    // remember last filter method
    private setMode(mode: number) {
        localStorage.setItem("searchFilter" + (this.isMainTree ? "Select" : "Main"), mode.toString());
    }
}
