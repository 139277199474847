import { BaseDHFSection } from "./BaseDHFSection";
import {
    FieldHandlerFactory,
    IDHFControlDefinition,
    IDHFSection,
    IDHFSectionOptions,
    IPluginCoreForDashboard,
    IPluginFieldValueBase,
    PluginManager,
    plugins,
} from "../../../common/businesslogic";
import { BaseControl, IBaseControlOptions } from "../../../common/UI/Controls/BaseControl";
import { ml } from "../../../common/matrixlib";
import { app, ControlState, globalMatrix } from "../../../globals";
import { IDashboardParametersBase } from "../../../common/businesslogic/FieldHandlers/Document/IDashboardParametersBase";
import { FieldDescriptions } from "../../../common/businesslogic/FieldDescriptions";
import { GenericFieldHandler } from "../../../common/businesslogic/FieldHandlers/GenericFieldHandler";
import { IPlainTextControlOptions, IPlainTextParams } from "../../../common/UI/Controls/plainText";
import {
    DashboardDocFieldHandler,
    IDashboardDocFieldValue,
} from "../../../common/businesslogic/FieldHandlers/Document/DashboardDocFieldHandler";

interface IDashboarControlOptions extends IBaseControlOptions {
    parameter?: IDashboardParams;
}
export interface IDashboardParams extends IDHFSectionOptions {
    dashboardParams: IDashboardParametersBase;
    dashboardID: string;
}

$.fn.dashboardForDoc = function (this: JQuery, options: IDashboarControlOptions) {
    if (!options.fieldHandler) {
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        options.fieldHandler = new DashboardDocFieldHandler("dashboard", options.parameter, options.fieldValue);
    }
    let baseControl = new DashboardImpl(this, options.fieldHandler as DashboardDocFieldHandler);
    this.getController = () => {
        return baseControl;
    };
    baseControl.init(options);
    return this;
};

export class DashboardImpl extends BaseControl<DashboardDocFieldHandler> {
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private originalValue: IDashboardDocFieldValue;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private settings: IDashboarControlOptions;
    private defaultOptions = {};
    constructor(container: JQuery, fieldHandler: DashboardDocFieldHandler) {
        super(container, fieldHandler);
    }
    destroy(): void {}

    async getValueAsync() {}
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    forceUpdate: boolean;
    async hasChangedAsync(): Promise<boolean> {
        let currentValue = this.fieldHandler.getValue();
        if (this.forceUpdate) return true;

        return (
            this.forceUpdate ||
            JSON.stringify(currentValue.value) != JSON.stringify(this.originalValue.value) ||
            currentValue.html != this.originalValue.html
        );
    }

    resizeItem(width: number | undefined, forceRedraw: boolean | undefined): void {}

    async init(options: IDashboarControlOptions) {
        this.settings = <IDashboarControlOptions>ml.JSON.mergeOptions(this.defaultOptions, options);
        this._root.append(super.createHelp(this.settings)); // render name of
        const container = $("<div class='baseControl'>").appendTo(this._root); // create a container
        await this.renderControl(container, this.settings);

        //baseControl.append(await this.fieldHandler.getHtmlValue());
    }

    async getDashboardList(): Promise<string[]> {
        let options = [];
        let pluginsList = plugins.getPlugins();
        for (let p of pluginsList) {
            if ("Plugin" in p) {
                let plugin = <IPluginCoreForDashboard<IDashboardParametersBase>>p["Plugin"];
                if (plugin.getDashboardAsync) {
                    let dashboard = await plugin.getDashboardAsync();
                    // Not every plugin implements a dashboard.
                    if (dashboard && dashboard.renderProjectPageWithParams) {
                        options.push(plugin.getConfig().dashboard.id);
                    }
                }
            }
        }
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        return options;
    }

    private async renderControl(container: JQuery, settings: IDashboarControlOptions) {
        container.css("margin-left", "20px");
        this.settings = settings;
        let currentValue: IDashboardDocFieldValue = {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            value: { dashboardID: undefined, dashboardParams: undefined },
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            html: "",
            ...this.fieldHandler.getValue(),
        };
        this.originalValue = JSON.parse(JSON.stringify(currentValue));
        ml.UI.addDropdownToValue(
            container,
            " Select a Dashboard",
            currentValue.value,
            "dashboardID",
            (await this.getDashboardList()).map((o) => {
                return { id: o, label: o };
            }),
            false,
            false,
            () => {
                if (currentValue && currentValue.value && currentValue.value.dashboardID) {
                    this.fieldHandler.setDashboardID(currentValue.value.dashboardID);
                }
                if (settings.valueChanged) settings.valueChanged();
            },
        );
        let editor = $("<div>");
        editor.html("").plainText({
            id: "",
            help: "&nbsp;",
            controlState: ControlState.DialogCreate,
            valueChanged: async () => {
                let value = await editor.getController().getValueAsync();
                try {
                    // Let try to save it
                    let dashboardParams = JSON.parse(value);
                    this.fieldHandler.setDashboardParams(dashboardParams);
                    if (settings.valueChanged) settings.valueChanged();
                } catch (e) {
                    // Do nothing
                }
            },
            isFolder: true,
            canEdit: true,
            fieldValue: JSON.stringify(currentValue.value.dashboardParams),
            parameter: {
                code: "json",
                height: 200,
                autoFormat: true,
                showJSONFormat: true,
            },
        });
        container.append(editor);

        let buttonPreview = $("<button>").text("Preview").appendTo(container);
        buttonPreview.click(async () => {
            let value = this.fieldHandler.getValue();
            let html = await this.fieldHandler.getNewHtmlValue();
            this.showDialog(value.value.dashboardID, html);
            this.fieldHandler.setHtmlValue(html);
        });
        let buttonForceUpdate = $("<button>").text("Force Update").appendTo(container);
        buttonForceUpdate.click(async () => {
            this.forceUpdate = true;
            this.fieldHandler.setHtmlValue(await this.fieldHandler.getNewHtmlValue());
            if (settings.valueChanged) settings.valueChanged();
        });
    }

    showDialog(title: string, html: string) {
        app.dlgForm.html("");
        app.dlgForm.addClass("dlg-v-scroll");
        app.dlgForm.removeClass("dlg-no-scroll");
        let content = $("<div>").appendTo(app.dlgForm);
        app.dlgForm.dialog({
            autoOpen: true,
            title: title,
            height: app.itemForm.height() * 0.9,
            width: $(document).width() * 0.9,
            modal: true,
            close: function () {},
            open: function () {
                content.html(html);
            },
            resizeStop: function (event, ui) {},
            buttons: [
                {
                    text: "Force update",
                    class: "btnDoIt",
                    click: async () => {
                        this.forceUpdate = true;
                        if (this.settings.valueChanged) this.settings.valueChanged();

                        app.dlgForm.dialog("close");
                    },
                },
                {
                    text: "Close",
                    class: "btnCancelIt",
                    click: function () {
                        app.dlgForm.dialog("close");
                    },
                },
            ],
        });
    }
}
export class Dashboard extends BaseDHFSection<IDashboardParams> implements IDHFSection {
    renderControl(ctrl: IDHFControlDefinition, ctrlParameter: IBaseControlOptions) {
        let ctrlConfig = this.getConfig(ctrl);
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        ctrl.control.dashboardForDoc(ctrlParameter);
    }

    async showSpecificSettings(ctrl: IDHFControlDefinition, ctrlParameter: IBaseControlOptions, custom: JQuery) {}

    async saveSpecificSettingsAsync(ctrl: IDHFControlDefinition, ctrlParameter: IBaseControlOptions, custom: JQuery) {
        return false;
    }

    async verifyContentAsync(ctrl: IDHFControlDefinition) {}
}
