import { IImportMergeItem } from "../../common/businesslogic/Branching";
import { ml } from "../../common/matrixlib";
import { ItemControl } from "../../common/UI/Components/index";
import { HistoryTools } from "../../common/UI/Tools/ItemHistoryView";
import { IStringNumberMap, IItem, IItemHistory, ControlState, globalMatrix, app, matrixSession } from "../../globals";

export { ImportMergeBase };

class ImportMergeBase {
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    protected fieldMapping: IStringNumberMap;

    // make a two comparison with one item from this, one from another project and maybe a third one from somewhere else
    protected compare3Way(item1: IImportMergeItem, item2: IImportMergeItem, commonBase: IImportMergeItem): void {
        let that = this;

        if (!item1 || !item2) {
            // that should never be the case
            return;
        }

        app.dlgForm.html("");
        app.dlgForm.removeClass("dlg-no-scroll");
        app.dlgForm.addClass("dlg-scroll");

        app.dlgForm
            .dialog({
                autoOpen: true,
                title: "Compare Item Revisions",
                height: $(window).height() - 40,
                width: $(window).width() - 40,
                modal: true,
                resizeStop: function () {
                    // app.dlgForm.resizeDlgContent(that.controls);
                },
                open: function () {
                    ml.UI.pushDialog(app.dlgForm);

                    // TODO: switch to Promise.all
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    that.getItem(item1).done(function (mainlineItem: IItem) {
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        that.getItem(item2).done(function (branchItem: IItem) {
                            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                            that.getItem(commonBase).done(async function (baseItem: IItem) {
                                let changeView = $("<div>").appendTo(app.dlgForm);

                                let flex = $("<div class='merge-compare__wrapper'>").appendTo(changeView);

                                let category = ml.Item.parseRef(item1.id).type;

                                if (baseItem) {
                                    let base = $("<div class='versionpane'>").appendTo(flex);
                                    that.renderVersion(
                                        base,
                                        commonBase.description,
                                        baseItem,
                                        commonBase.revision,
                                        category,
                                    );
                                }
                                let changed = $("<div class='versionpane'>").appendTo(flex);
                                let current = $("<div class='versionpane'>").appendTo(flex);

                                await that.renderVersion(
                                    changed,
                                    item1.description,
                                    mainlineItem,
                                    item1.revision,
                                    category,
                                );
                                await that.renderVersion(
                                    current,
                                    item2.description,
                                    branchItem,
                                    item2.revision,
                                    category,
                                    item2.project,
                                );

                                new HistoryTools().updateVersionPanes();
                            });
                        });
                    });
                },
                close: function () {
                    ml.UI.popDialog(app.dlgForm);
                },
                buttons: [
                    {
                        text: "Ok",
                        class: "btnOk",
                        click: function () {
                            app.dlgForm.removeClass("dlg-scroll");
                            app.dlgForm.dialog("close");
                        },
                    },
                ],
            })
            .resizeDlgContent([]);
    }

    // show a revision of an item from mainline or branch in a dialog
    protected showItem(show: IImportMergeItem): void {
        // source:string, category:string, item:IItem, version:number):void {
        let that = this;

        app.dlgForm.html("");
        app.dlgForm.removeClass("dlg-no-scroll");
        app.dlgForm.addClass("dlg-scroll");

        app.dlgForm
            .dialog({
                autoOpen: true,
                title: "Item Viewer",
                height: $(window).height() - 40,
                width: 800,
                modal: true,
                resizeStop: function () {
                    // app.dlgForm.resizeDlgContent(that.controls);
                },
                open: function () {
                    ml.UI.pushDialog(app.dlgForm);

                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    that.getItem(show).done(function (item: IItem) {
                        let changeView = $("<div>").appendTo(app.dlgForm);

                        let flex = $("<div style='display:flex;justify-content: center;'>").appendTo(changeView);
                        let current = $("<div class='versionpane'>").appendTo(flex);

                        that.renderVersion(
                            current,
                            show.description,
                            item,
                            show.revision,
                            ml.Item.parseRef(show.id).type,
                            show.project,
                        );
                    });
                },
                close: function () {
                    ml.UI.popDialog(app.dlgForm);
                },
                buttons: [
                    {
                        text: "Ok",
                        class: "btnOk",
                        click: function () {
                            app.dlgForm.removeClass("dlg-scroll");
                            app.dlgForm.dialog("close");
                        },
                    },
                ],
            })
            .resizeDlgContent([]); //this.controls);
    }

    // "fix" field id's of items from other projects than this
    protected remapItem(itemFromOtherProject: IItem) {
        let that = this;

        let mappedItem: any = {};

        $.each(itemFromOtherProject, function (field: string, value: any) {
            if (isNaN(<any>field) || that.fieldMapping[field]) {
                mappedItem[that.fieldMapping[field] ? that.fieldMapping[field] : field] = value;
            }
        });

        // if the branch item is a folder, simulate in mapped item
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        if (itemFromOtherProject.children || itemFromOtherProject.id.indexOf("F-") == 0) {
            mappedItem.children = {};
        }

        // fix remove some special properties of DOC items
        delete mappedItem.availableFormats;

        return <IItem>mappedItem;
    }

    // get item from this project, other project or other instance/project
    protected getItem(item: IImportMergeItem) {
        let that = this;
        let res = $.Deferred();

        if (!item || !item.id) {
            res.resolve(null);
        } else if (item.project) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            app.getProjectItemAsync(item.project, item.id, item.revision, true).done(function (branchItem: IItem) {
                res.resolve(that.remapItem(branchItem));
            });
        } else {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            app.getItemAsync(item.id, item.revision, false, false).done(function (mainlineItem: IItem) {
                res.resolve(mainlineItem);
            });
        }

        return res;
    }

    // renders the contents of an item into html
    protected async renderVersion(
        hi: JQuery,
        source: string,
        item: IItem,
        version: number,
        category: string,
        branch?: string,
    ) {
        let vi = $("<div class='versionInfo'>");
        let header = $("<div class='baseControlHelp'>Info</div>");
        hi.append(header);
        hi.append(vi);
        let versionInfo: IItemHistory;
        if (item.history && item.history.filter((h) => h.version == version).length) {
            versionInfo = item.history.filter((h) => h.version == version)[0];
        }
        vi.append("<div class=''>Source: <span class='historyDlgVersionDetail'>" + source + "</span></div>");
        vi.append("<div class=''>Version: <span class='historyDlgVersionDetail'>" + version + "</span></div>");
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        if (versionInfo) {
            vi.append(
                "<div class='' style='white-space: pre-wrap'>Comment: <span class='historyDlgVersionDetail'>" +
                    versionInfo.comment +
                    "</span></div>",
            );
            vi.append(
                "<div class=''>Author: <span class='historyDlgVersionDetail'>" + versionInfo.user + "</span></div>",
            );
        }
        vi.append(
            "<div class=''>Date: <span class='historyDlgVersionDetail'>" +
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                ml.UI.DateTime.renderCustomerHumanDate(new Date(item.modDate)) +
                "</span></div>",
        );

        hi.append("<hr>");

        let itemC = new ItemControl({
            control: hi,
            controlState: ControlState.HistoryView,
            isHistory: 1,
            item: item,
            type: category,
            isItem: !item.children,
            parameter: {
                manualTableHeights: true,
                reviewMode: true, // don't show outdated icons
            },
        });
        await itemC.load();

        let itemDetails = $(".panel-body-v-scroll", hi);
        if (branch) {
            $(".itemTitle>a", hi).attr(
                "href",
                $(".itemTitle>a", hi)
                    .attr("href")
                    .replace("/" + matrixSession.getProject() + "/", "/" + branch + "/"),
            );
        }

        ml.SmartText.prepareForReadReadRender(itemDetails);
        hi.data("status", "show");
        hi.show();
    }
}
