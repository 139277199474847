import { IDHFSectionOptions, IDHFControlDefinition, DocumentSectionType, mDHF } from "../../../../common/businesslogic";
import { mTM } from "../../../../common/businesslogic";
import { ml } from "../../../../common/matrixlib";
import { IBaseControlOptions } from "../../../../common/UI/Controls/BaseControl";
import { IFromToSelection } from "../../../../common/UI/Controls/itemSelectionFromTo";
import { globalMatrix, IGenericMap } from "../../../../globals";

import { IDoubleDefaultControllerConfig } from "../../../../SingleSelectBase";
import {
    ITraceMatrixOptions,
    TraceMatrixDocFieldHandler,
} from "../../../../common/businesslogic/FieldHandlers/Document/TraceMatrixDocFieldHandler";
import { IDocFieldHandler } from "../../../../common/businesslogic/FieldHandlers/Document/IDocFieldHandler";
import { DoubleSelectBase } from "../../../../DoubleSelectBase";

export { TraceMatrix };

class TraceMatrix extends DoubleSelectBase<ITraceMatrixOptions> {
    renderControl(ctrl: IDHFControlDefinition, ctrlParameter: IBaseControlOptions) {
        let traceConfig = globalMatrix.ItemConfig.getTraceConfig();
        let allLinkTypes = globalMatrix.ItemConfig.getCategories();

        // define possible to types: no folders, reports (maybe no DOC, no SIGN)
        let hideToTypes: string[] = [];

        // MATRIX-1755 allow to Select DOCs if set as Design
        $.each(mDHF.getDocumentTypes(), function (hidx, cat) {
            let isExcluded = true;

            $.each(traceConfig.rules, function (ridx, rule) {
                if (rule.reporting && rule.category === cat && rule.reporting.indexOf("Design") !== -1) {
                    isExcluded = false;
                }
            });
            if (isExcluded) {
                hideToTypes.push(cat);
            }
        });
        // MATRIX-2716 removed: hideToTypes.push(mTM.getXTCType());
        hideToTypes.push("FOLDER");
        let toTypes: string[] = [];
        for (let idx = 0; idx < allLinkTypes.length; idx++) {
            if (hideToTypes.indexOf(allLinkTypes[idx]) === -1) {
                toTypes.push(allLinkTypes[idx]);
            }
        }

        // from categories (rows): all but docs & XTC
        let hideFromTypes: string[] = mDHF.getDocumentTypes();
        hideFromTypes.push(mTM.getXTCType());
        hideFromTypes.push("FOLDER");

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        ctrl.control.itemSelectionFromTo(
            <any>ml.JSON.mergeOptions(ctrlParameter, {
                parameter: {
                    prefixFrom: "Traces from:",
                    buttonNameFrom: "Select Source Items",
                    showNotFrom: hideFromTypes,
                    prefixTo: "Traces to:",
                    buttonNameTo: "Select Target Items",
                    showOnlyTo: toTypes,
                },
            }),
        );
    }

    showSpecificSettings(ctrl: IDHFControlDefinition, ctrlParameter: IBaseControlOptions, custom: JQuery) {
        let controllerConfig: ITraceMatrixOptions = this.getConfig(ctrl);

        let itoc =
            '<div class="checkbox" ><label><input type="checkbox" class="includeInToc" ' +
            (controllerConfig.includeInToc ? "checked" : "") +
            "> Add a reference to included items to table of content</label></div>";
        custom.append($(itoc));

        let numo =
            '<div class="checkbox" ><label><input type="checkbox" class="numericalOrder" ' +
            (controllerConfig.numericalOrder ? "checked" : "") +
            "> List elements in numerical order</label></div>";
        custom.append($(numo));

        let sio =
            '<div class="checkbox" ><label><input type="checkbox" class="showIdOnly" ' +
            (controllerConfig.showIdOnly ? "checked" : "") +
            "> Show only item id</label></div>";
        custom.append($(sio));

        let colInput = $(
            '<input autocomplete="off" style="height: 20px;width: 420px;float: right;" class="form-control p_colDef" type="text" name="search" value="" /> ',
        );
        let colDef = $("<div class='sectionConfOption'>Define Columns </div>").append(colInput);
        custom.append(colDef);
        colInput.val(controllerConfig.columnDef ? controllerConfig.columnDef : "");

        this.addSpecificSettings(controllerConfig, custom);
    }
    async saveSpecificSettingsAsync(ctrl: IDHFControlDefinition, ctrlParameter: IBaseControlOptions, custom: JQuery) {
        let controllerConfig = this.getConfig(ctrl);

        let changed = false;

        let newVal = $(custom.find(".includeInToc")[0]).prop("checked");
        changed = changed || newVal !== controllerConfig.includeInToc;
        controllerConfig.includeInToc = newVal;

        newVal = $(custom.find(".numericalOrder")[0]).prop("checked");
        changed = changed || newVal !== controllerConfig.numericalOrder;
        controllerConfig.numericalOrder = newVal;

        newVal = $(custom.find(".showIdOnly")[0]).prop("checked");
        changed = changed || newVal !== controllerConfig.showIdOnly;
        controllerConfig.showIdOnly = newVal;

        let newColDef = this.removeSpaces($(custom.find(".p_colDef")[0]).val());
        changed = changed || newColDef !== controllerConfig.columnDef;
        controllerConfig.columnDef = newColDef;

        let specificChanged = this.setSpecificSettings(controllerConfig, custom);

        this.fieldHandler.setDHFConfig(controllerConfig);

        return changed || specificChanged;
    }
}
