import { ControlState, globalMatrix } from "../../../globals";
import { BaseControl, IBaseControlOptions } from "./BaseControl";
import { ml } from "./../../matrixlib";
import {
    ICascadingSelect,
    ICascadingSelectSelect,
    ICascadingSelectText,
    ICascadingSelectNumber,
    ICascadingSelectGroup,
} from "../../../ProjectSettings";
import { FieldHandlerFactory } from "../../businesslogic";
import { FieldDescriptions } from "../../businesslogic/FieldDescriptions";
import { IFieldHandler } from "../../businesslogic/FieldHandlers/IFieldHandler";
import { EmptyFieldHandler } from "../../businesslogic/FieldHandlers/EmptyFieldHandler";

export type { ICascadingSelectOptions, ICascadingOptionSelector };
export { CascadingSelect };

interface ICascadingSelectOptions extends IBaseControlOptions {
    controlState?: ControlState;
    canEdit?: boolean;
    help?: string;
    fieldValue?: string;
    valueChanged?: Function;
    parameter?: {
        cascadingOptions?: ICascadingSelect;
    };
}

interface ICascadingOptionSelector {
    groupId: string;
    groupValue: string;
}

$.fn.cascadingSelect = function (this: JQuery, options: ICascadingSelectOptions) {
    if (!options.fieldHandler) {
        options.fieldHandler = FieldHandlerFactory.CreateHandler(
            globalMatrix.ItemConfig,
            FieldDescriptions.Field_dummy,
            options,
        );
        options.fieldHandler.initData(options.fieldValue);
    }

    let baseControl = new CascadingSelect(this, options.fieldHandler as EmptyFieldHandler);
    this.getController = () => {
        return baseControl;
    };
    baseControl.init(options);
    return this;
};

class CascadingSelect extends BaseControl<EmptyFieldHandler> {
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private settings: ICascadingSelectOptions;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private optionsChain: ICascadingOptionSelector[];

    constructor(control: JQuery, fieldHandler: EmptyFieldHandler) {
        super(control, fieldHandler);
    }

    init(options: ICascadingSelectOptions) {
        let that = this;
        let defaultOptions = {
            controlState: ControlState.FormView, // read only rendering
            canEdit: false, // whether data can be edited
            dummyData: false, // fill control with a dumy text (for form design...)
            valueChanged: function () {}, // callback to call if value changes
            parameter: {
                cascadingOptions: {},
                readonly: false, // can be set to overwrite the default readonly status
            },
        };
        this.settings = <ICascadingSelectOptions>ml.JSON.mergeOptions(defaultOptions, options);

        let displayValue = "not specified";
        if (typeof this.settings.fieldValue === "undefined" || this.settings.fieldValue === "") {
            this.optionsChain = this.completeChain([]);
        } else {
            displayValue = "";
            $.each(<ICascadingOptionSelector[]>JSON.parse(this.settings.fieldValue), function (idx, oc) {
                displayValue += "<span class='cascadingSelect'>" + oc.groupValue + "</span>";
            });
            this.optionsChain = this.completeChain(JSON.parse(this.settings.fieldValue));
        }

        this._root.append(super.createHelp(this.settings));
        if (
            this.settings.controlState === ControlState.Print ||
            this.settings.controlState === ControlState.Tooltip ||
            !this.settings.canEdit
        ) {
            this._root.append("<div class='printBox'>" + displayValue + "</div>");
            return;
        }

        let ctrlContainer = $("<div class='baseControl'>").appendTo(this._root);
        this.renderControls(ctrlContainer);
    }

    // public interface
    async hasChangedAsync() {
        return !this.settings.fieldValue || JSON.stringify(this.optionsChain) !== this.settings.fieldValue;
    }
    async getValueAsync() {
        return JSON.stringify(this.optionsChain);
    }
    destroy() {}
    resizeItem() {}

    // return an actual GUID based on value
    getGuid() {
        let guid: string = "";
        $.each(this.optionsChain, function (step, val) {
            guid += val.groupValue;
        });
        return guid;
    }
    // private functions

    // renderValue
    // make all options have a value
    private completeChain(parts: ICascadingOptionSelector[]): ICascadingOptionSelector[] {
        let newParts: ICascadingOptionSelector[] = [];
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        let groupId = this.settings.parameter.cascadingOptions.startGroupId;
        let chainIdx = 0;

        while (groupId) {
            let group = this.getGroup(groupId);
            // make sure this value in the chain is correct / use the default one (if it is not correct or does not exist)
            let groupValue = "";
            let nextGroupId = "";
            switch (group.type) {
                case "select":
                    // default first in the list
                    groupValue = (<ICascadingSelectSelect[]>group.spec)[0].text;
                    nextGroupId = (<ICascadingSelectSelect[]>group.spec)[0].nextGroupId;
                    // if something existing is specified take this
                    for (
                        let idx = 1;
                        chainIdx < parts.length && idx < (<ICascadingSelectSelect[]>group.spec).length;
                        idx++
                    ) {
                        if ((<ICascadingSelectSelect[]>group.spec)[idx].text === parts[chainIdx].groupValue) {
                            groupValue = parts[chainIdx].groupValue;
                            nextGroupId = (<ICascadingSelectSelect[]>group.spec)[idx].nextGroupId;
                        }
                    }
                    break;
                case "text":
                    groupValue = (<ICascadingSelectText>group.spec).text;
                    nextGroupId = (<ICascadingSelectText>group.spec).nextGroupId;
                    break;
                case "number":
                    groupValue =
                        "{" +
                        (<ICascadingSelectNumber>group.spec).counter +
                        "," +
                        (<ICascadingSelectNumber>group.spec).format +
                        "}";
                    (<ICascadingSelectNumber>group.spec).nextGroupId;
                    break;
            }
            newParts.push({ groupValue: groupValue, groupId: groupId });
            groupId = nextGroupId;
            chainIdx++;
        }
        return newParts;
    }
    private getGroup(groupId: string) {
        let group: ICascadingSelectGroup;
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        $.each(this.settings.parameter.cascadingOptions.groups, function (idx, groupDef) {
            if (groupDef.groupId === groupId) {
                group = groupDef;
                return;
            }
        });
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        return group;
    }
    // render controls based on (correct option chain)
    private renderControls(ctrl: JQuery) {
        let that = this;

        for (let cidx = 0; cidx < this.optionsChain.length; cidx++) {
            let group = this.getGroup(this.optionsChain[cidx].groupId);
            let groupValue = this.optionsChain[cidx].groupValue;
            switch (group.type) {
                case "select": {
                    let select = $("<select class='cascadingSelect cascadingSelectSelect'>").appendTo(ctrl);

                    for (let soIdx = 0; soIdx < (<ICascadingSelectSelect[]>group.spec).length; soIdx++) {
                        let text = (<ICascadingSelectSelect[]>group.spec)[soIdx].text;
                        let selected = text === groupValue ? "selected" : "";
                        let value = (<ICascadingSelectSelect[]>group.spec)[soIdx].text;
                        let help = (<ICascadingSelectSelect[]>group.spec)[soIdx].help;
                        select.append(
                            $("<option value='" + value + "' " + selected + ">").html(
                                value + (help ? " (" + help + ")" : ""),
                            ),
                        );
                    }
                    select.data("index", cidx);
                    select.change(function (event: JQueryEventObject) {
                        let newVal = $(":selected", $(event.delegateTarget)).val();
                        if (that.optionsChain[$(event.delegateTarget).data("index")].groupValue !== newVal) {
                            that.optionsChain[$(event.delegateTarget).data("index")].groupValue = newVal;
                            that.updateControls(ctrl);
                        }
                    });
                    break;
                }
                case "text":
                    ctrl.append($("<span class='cascadingSelect cascadingSelectText'>").html(groupValue));
                    break;
                case "number":
                    ctrl.append($("<span class='cascadingSelect cascadingSelectNumber'>").html(groupValue));
                    break;
            }
        }
    }

    // update controls after chain change
    private updateControls(ctrl: JQuery) {
        ctrl.html("");

        this.optionsChain = this.completeChain(this.optionsChain);
        this.renderControls(ctrl);
        if (this.settings.valueChanged) {
            this.settings.valueChanged();
        }
    }
}
