import { globalMatrix } from "../globals";
import {
    IMatrix,
    ISearchTools,
    IItemTools,
    IContextFramesTools,
    IJSONTools,
    IXPathTools,
    IURLTools,
    IMailTools,
    ILoggerTools,
    ILabelTools,
    IUIToolsEnum,
    IFileTools,
    IReportGeneratorTools,
    ISmartTextTools,
    UIToolsConstants,
} from "./matrixlib/MatrixLibInterfaces";
import {
    ContextFramesTools,
    FileTools,
    ItemTools,
    JSONTools,
    LabelTools,
    LoggerTools,
    MailTools,
    ReportGeneratorTools,
    SearchTools,
    SmartTextTools,
    UIToolsEnum,
    URLTools,
    XPathTools,
} from "./matrixlib/index";

export { ml, LoggerTools, JSONTools };

class matrix implements IMatrix {
    public Search: ISearchTools;
    public Item: IItemTools;
    public ContextFrames: IContextFramesTools;
    public JSON: IJSONTools;
    public XPath: IXPathTools;
    public URL: IURLTools;
    public Mail: IMailTools;
    public Logger: ILoggerTools;
    public LabelTools: ILabelTools;
    public UI: IUIToolsEnum;
    public File: IFileTools;
    public ReportGenerator: IReportGeneratorTools;
    public SmartText: ISmartTextTools;

    constructor() {
        this.Search = new SearchTools();
        this.Item = new ItemTools();
        this.ContextFrames = new ContextFramesTools();

        // highlight / search function
        this.XPath = new XPathTools();
        this.URL = new URLTools();
        this.Mail = new MailTools();

        // tooltipsmessage boxes and other ui controls
        this.UI = new UIToolsEnum();
        this.File = new FileTools();
        this.ReportGenerator = new ReportGeneratorTools();
        this.SmartText = new SmartTextTools();

        // console / error logging
        this.Logger = new LoggerTools(this.UI.DateTime.renderHumanDate.bind(this.UI.DateTime));
        this.JSON = new JSONTools(this.Logger);

        // label tools (labels and filters)
        this.LabelTools = this.CreateNewLabelTools();
    }

    CreateNewLabelTools(): ILabelTools {
        return new LabelTools(this.Logger, this.JSON);
    }
}

let ml: IMatrix;

ml = new matrix();

// Let's save this on the window.
// @ts-ignore TODO: get rid of global
globalThis.ml = ml;
// TODO(modules): UIToolsEnum was split into two classes because of circular dependencies.
// UIToolsConstants was the result. Legacy plugins often use those constants, so let's
// make it easy for them by exposing those in the old name they know. This is a TODO
// because it reflects that those plugins need to be refactored.
// @ts-ignore TODO: get rid of global
globalThis.UIToolsEnum = UIToolsConstants;
