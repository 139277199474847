import { IDHFSection, IDHFControlDefinition } from "../../../common/businesslogic/index";
import { ml } from "../../../common/matrixlib";
import { IBaseControlOptions } from "../../../common/UI/Controls/BaseControl";
import { ISmartTextOptions } from "../../../common/businesslogic/FieldHandlers/Document/SmartTextDocFieldHandler";
import { BaseDHFSection } from "./BaseDHFSection";
export { SmartText };

class SmartText extends BaseDHFSection<ISmartTextOptions> implements IDHFSection {
    renderControl(ctrl: IDHFControlDefinition, ctrlParameter: IBaseControlOptions) {
        let controllerConfig = this.getConfig(ctrl);
        let includes: string[] = [];
        if (controllerConfig.abbreviations) includes.push("abbreviation");
        if (controllerConfig.terms) includes.push("term");
        if (controllerConfig.richtext) includes.push("richtext");
        if (controllerConfig.plaintext) includes.push("plaintext");

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        ctrl.control.html("");
        ctrlParameter.fieldValue =
            "includes " + (controllerConfig.includeAll ? "all " : "") + includes.join(" and ") + " definitions";
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        ctrl.control.plainText(
            ml.JSON.setOptions(ml.JSON.clone(ctrlParameter), {
                parameter: { readonly: true, rows: 1, allowResize: false },
            }),
        );
    }

    showSpecificSettings(ctrl: IDHFControlDefinition, ctrlParameter: IBaseControlOptions, custom: JQuery) {
        let controllerConfig = this.getConfig(ctrl);

        let abbreviations = $(
            '<div class="checkbox" style="" ><label><input type="checkbox" class="p1" ' +
                (controllerConfig.abbreviations ? "checked" : "") +
                "> Include abbreviations</label></div>",
        );
        let terms = $(
            '<div class="checkbox" style="" ><label><input type="checkbox" class="p2" ' +
                (controllerConfig.terms ? "checked" : "") +
                "> Include terms</label></div>",
        );
        let richtext = $(
            '<div class="checkbox" style="" ><label><input type="checkbox" class="p3" ' +
                (controllerConfig.richtext ? "checked" : "") +
                "> Include rich text macros</label></div>",
        );
        let plaintext = $(
            '<div class="checkbox" style=""><label><input type="checkbox" class="p4" ' +
                (controllerConfig.plaintext ? "checked" : "") +
                "> Include plain text macros</label></div>",
        );
        let includeAll = $(
            '<div class="checkbox" style=""><label><input type="checkbox" class="p5" ' +
                (controllerConfig.includeAll ? "checked" : "") +
                "> Show also unused abbreviations/terms</label></div>",
        );

        custom.append(abbreviations);
        custom.append(terms);
        custom.append(richtext);
        custom.append(plaintext);
        custom.append(includeAll);

        custom.append(
            $("<div>").append(
                $("<span>Header 1st Column: </span>").append(
                    $(
                        '<input style="height: 20px;width: 420px;float: right;" class="form-control p_firstColumnName" type="text" value="" /> ',
                    ),
                ),
            ),
        );
        custom.append(
            $("<div>").append(
                $("<span>Header 2nd Column: </span>").append(
                    $(
                        '<input style="height: 20px;width: 420px;float: right;" class="form-control p_secondColumnName" type="text" value="" /> ',
                    ),
                ),
            ),
        );

        $(custom.find(".p_firstColumnName")[0]).val(controllerConfig.firstColumnName);
        $(custom.find(".p_secondColumnName")[0]).val(controllerConfig.secondColumnName);
    }
    async saveSpecificSettingsAsync(ctrl: IDHFControlDefinition, ctrlParameter: IBaseControlOptions, custom: JQuery) {
        let controllerConfig = this.getConfig(ctrl);

        let changed = false;

        changed = changed || controllerConfig.abbreviations !== $(custom.find(".p1")[0]).prop("checked");
        changed = changed || controllerConfig.terms !== $(custom.find(".p2")[0]).prop("checked");
        changed = changed || controllerConfig.richtext !== $(custom.find(".p3")[0]).prop("checked");
        changed = changed || controllerConfig.plaintext !== $(custom.find(".p4")[0]).prop("checked");
        changed = changed || controllerConfig.includeAll !== $(custom.find(".p5")[0]).prop("checked");
        changed = changed || controllerConfig.firstColumnName !== $(custom.find(".p_firstColumnName")[0]).val();
        changed = changed || controllerConfig.secondColumnName !== $(custom.find(".p_secondColumnName")[0]).val();

        controllerConfig.abbreviations = $(custom.find(".p1")[0]).prop("checked");
        controllerConfig.terms = $(custom.find(".p2")[0]).prop("checked");
        controllerConfig.richtext = $(custom.find(".p3")[0]).prop("checked");
        controllerConfig.plaintext = $(custom.find(".p4")[0]).prop("checked");
        controllerConfig.includeAll = $(custom.find(".p5")[0]).prop("checked");
        controllerConfig.firstColumnName = $(custom.find(".p_firstColumnName")[0]).val();
        controllerConfig.secondColumnName = $(custom.find(".p_secondColumnName")[0]).val();

        this.fieldHandler.setDHFConfig(controllerConfig);

        this.renderControl(ctrl, ctrlParameter);
        return changed;
    }
    async verifyContentAsync(ctrl: IDHFControlDefinition) {
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        let lastVal = await ctrl.control.getController().getValueAsync();
        if (!lastVal) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            ctrl.control.closest(".ft_dhf").find(".baseControlHelp").addClass("contentNeeded");
        } else {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            ctrl.control.closest(".ft_dhf").find(".baseControlHelp").removeClass("contentNeeded");
        }
    }
}
