// example generation of json schema from typescript type
//
//  typescript-json-schema web/ts/ProjectSettings.ts ITestConfig > test.txt

import { IStringMap, IStringStringArrayMap } from "./globals";
import { JsonEditorValidation } from ".././jsonvalidation/JsonValidator";
import { IPrintFunctionParamsOverwrites } from "./printinterface/PrintFunction";
import { ColumnEditor } from "./common/businesslogic";

export type {
    IDeletedProjects,
    IWorkflowConfig,
    IWorkflowConfigOne2One,
    IWorkflowConfigProjectsAndTypes,
    IWorkflowConfigIssueType,
    IWorkflowConfigStates,
    IWorkflowConfigStateDetails,
    IWorkflowConfigJira,
    IWorkflowConfigZoho,
    IWorkflowConfigIssueTypesZoho,
    ITraceConfig,
    ITraceConfigDetails,
    ITraceConfigRule,
    TTraceRule,
    IQMSConfig,
    ITraining,
    ITrainingMessages,
    IPublication,
    IPublicationCategory,
    IPublicationCategoryGroup,
    IPublishLegacy,
    ILabelsConfig,
    ILabelStyle,
    ILabel,
    ILabelInstance,
    ILabelTemplate,
    ILabelGroup,
    IFilterMenu,
    IDesignReview,
    IMailConfig,
    IMailConfigCanned,
    IMailConfigCustom,
    ISearchConfig,
    ISearchConfigSearch,
    IInitialSearches,
    ILabelLockConfig,
    ILabelLockConfigLocks,
    IRiskConfig,
    IRiskConfigMethod,
    IRiskConfigRT,
    IRiskConfigMitgationType,
    IRiskConfigFactor,
    IColDef,
    IRiskConfigMitgationTable,
    IRiskConfigTablesColumn,
    RiskTableCellEditor,
    IRiskConfigFactorInputType,
    IRiskConfigFactorOption,
    IRiskConfigSelectChanges,
    IRiskConfigFactorWeight,
    IRiskConfigFactorWeightValue,
    IRiskConfigReduction,
    IRiskConfigReductionOptions,
    IRiskConfigZone,
    IRiskConfigRPN,
    IRiskPostReduction,
    ICascadingSelect,
    ICascadingSelectGroup,
    ICascadingSelectNumber,
    ICascadingSelectSelect,
    ICascadingSelectText,
    IDHFConfig,
    ICaptions,
    ICaption,
    IDHFCategories,
    IDHFConfigCustomReports,
    IDHFConfigDefaultFormats,
    IDHFConfigDefaultFormatsOption,
    IDHFConfigHideFormat,
    IDHFConfigCustomColumn,
    IDHFConfigTable,
    IDHFConfigTableColumn,
    IDHFConfigTableColumnType,
    IDHFConfigCustomTable,
    IDHFConfigStandardDocs,
    IDHFConfigStandardDocsDef,
    IDHFConfigStandardDocsSection,
    IContextPageConfig,
    IContextPageConfigHelp,
    IContextPageConfigTab,
    ContextPageConfigTabOption,
    ITestConfig,
    ITestConfigPresetField,
    ITestConfigTables,
    ITestConfigTablesColumns,
    ITestConfigTablesColumn,
    TableCellEditor,
    ITestRule,
    ITestRuleAuto,
    ITestRuleManual,
    ITestRuleStep,
    TestResultType,
    TestResultRule,
    ICategorySetting,
    ICategorySettingTitle,
    ICategorySettingTextIcon,
    ICategorySettingTabs,
    ICategorySettingTab,
    ICategoryGroups,
    ICategoryGroup,
    IACL,
    IACLRules,
    IACLGroupsAcl,
    IFieldParameter,
    IDropDownConfig,
    IDropdownGroup,
    IDropdownOption,
    IAutoFillSetting,
    ISmartTextConfig,
    ISmartTextConfigReplacement,
    IProjectLogo,
    IExtras,
    IImportConfig,
    IImportConfigDetails,
    IEmbeddedReport,
    IProjectGroups,
    IProjectGroup,
    IFieldCapabilities,
    IFieldDescription,
    INotificationConfig,
    IAutoColumn,
    IAutoColumnMap,
    IAutoColumnMapping,
    ICleanup,
    ICleanupProtocol,
};

export {
    EnumItemPublish,
    qmsDefaultConfig,
    mailConfigSetting,
    smartTextConfigSetting,
    autoColumnSetting,
    autoColumnDefault,
    notificationSetting,
    defaultNotificationConfig,
    ACL_SETTING,
};

// --------------------------------------------------
// public project settings in admin client
// --------------------------------------------------

interface IDeletedProjects {
    deleted: string[];
}

// *******************************
// workflow (JIRA, Zoho, Weblinks
// *******************************

interface IWorkflowConfig {
    enabled?: boolean;
    defaultProjectId?: string;
    defaultTypeId?: string;
    projectsAndTypes?: IWorkflowConfigProjectsAndTypes[];
    one2one?: IWorkflowConfigOne2One;
    states?: IWorkflowConfigStates[];
    pluginId: number;
}
interface IWorkflowConfigOne2One {
    projectId: string;
    typeId: string;
}
interface IWorkflowConfigProjectsAndTypes {
    key: string;
    id: string;
    issueTypes: IWorkflowConfigIssueType[];
    name: string;
}
interface IWorkflowConfigIssueType {
    id: string;
    name: string;
    icon?: string;
    quickIssue?: boolean;
}
interface IWorkflowConfigStates {
    projectId: string;
    typeId: string;
    states: IWorkflowConfigStateDetails[];
}
interface IWorkflowConfigStateDetails {
    name: string;
    color: string;
    background: string;
    done: boolean | string;
}
interface IWorkflowConfigJira extends IWorkflowConfig {
    hideCreateJiraSimpleIssue?: boolean;
    hideCreateJiraNativeIssue?: boolean;
    requireCommitTicket?: boolean;
    addJIRAComment?: boolean;
}
interface IWorkflowConfigZoho extends IWorkflowConfig {
    zohoBaseUrl?: string;
}
interface IWorkflowConfigIssueTypesZoho extends IWorkflowConfigIssueType {
    prefix: string;
    details?: string;
}

// *******************************
// trace config
// *******************************
interface ITraceConfig {
    rules: ITraceConfigDetails[];
} // configuration of tracability rules
interface ITraceConfigDetails {
    category: string; // id of category
    creates_end: string | boolean;
    end_point?: string;
    reporting: string[];
    up_rules: ITraceConfigRule[];
    down_rules: ITraceConfigRule[];
}
interface ITraceConfigRule {
    message: string;
    name: string;
    rule: TTraceRule;
    any_of: string[];
}
type TTraceRule = "can_have" | "must_have";
// *******************************
// qms configuration
// *******************************

enum EnumItemPublish {
    IfNotInGroup = 0, //allow items to be published unless they are in a group
    Always = 1, // allow items to be published even if they are in a group
    Never = 2, // items cannot be published by itself
}

interface IQMSConfig {
    userMode?: string;
    /** there's only one publication in the array supported */
    publications: IPublication[];
    // roles are defined globally
    rolesTargetProjects?: string[]; // a list of projects to which the roles can be published to
    affectedByField?: string; //optional to overwrite the default field name "Affected by Process";
    responsibleForField?: string; // optional to overwrite the default field name "Responsible for Process Application";
    training?: ITraining;
    /** Experimental: comma separated list of labels to show in published site -> if they are set */
    showLabels?: string;
    allowExport: boolean;
    /** obsolete */
    legacyRoles: boolean; // set to true to show roles as drop downs and not as groups
}
interface ITraining {
    messages?: ITrainingMessages;
}
interface ITrainingMessages {
    trainingSub?: string;
    trainingText?: string;
    overdueSub?: string;
    overdueText?: string;
}

interface IPublication {
    /** information for each publishable category */
    rules: IPublicationCategory[]; // which categories to publish when
    /** needs to be PUB right now */
    toCategory: string; // into which category to publish default PUB
    /** needs to be pub right now */
    target: string; // same as toTarget for URL, default pub
    /** comma separated list of users who can publish */
    publisher: string; // comma separated list of publishers
    /** beta: userMode: default 0 - select the logged in user, 1 no user pre selected, 2: hide all other users , 3: hide all other users (Strict mode) */
    userMode?: number;
    /** obsolete */
    keepFlatList: boolean; // if true items are created as flat list. if not set (new default) items are created in a folder hierarchy
}
interface IPublicationCategory {
    /** the category for which the rule applies  */
    category: string; // category id
    /** a list of labels which must be set to be able to publish (approved labels)*/
    readyLabels: string[]; // in case there are labels which must be set to be able to publish
    /** how individual items can be published:  0 - only if they are not in a group, 1 - independently of groups, 2 - only as part of a group*/
    itemRules: EnumItemPublish; // to define if items should only be published as part of a group,
    /** name of the group, displayed in the UI as heading in the navigation of the live qms */
    groupName?: string; // name of group like SOP
    /** identification of labels which define the group (groupType setting in labels)  */
    groupLabelType?: string; // identification of label, e.g. SOP labels
    /** if a PROC should only be published with its WI,s the group down would be WI*/
    groupDown?: string[]; // categories which are pulled in group
}

interface IPublicationCategoryGroup {
    category: string; // grouping based on which category
    groupName: string; // name of group like SOP
    groupLabelType: string; // identification of label
}

const qmsDefaultConfig: IQMSConfig = {
    publications: [
        {
            rules: [
                {
                    category: "PROC", // category id
                    readyLabels: ["PROCOK"], // in case there are labels which must be set to be able to publish
                    itemRules: EnumItemPublish.IfNotInGroup,
                    groupName: "SOP",
                    groupLabelType: "SOPS",
                    groupDown: ["WI"],
                },
                {
                    category: "WI", // category id
                    readyLabels: ["WIOK"], // in case there are labels which must be set to be able to publish
                    itemRules: EnumItemPublish.IfNotInGroup,
                },
            ], // which categories to publish when
            toCategory: "PUB", // into which category to publish
            target: "pub", // the name under which to publish the category (part of the URL, defaults to pub)
            keepFlatList: false, // if true items are created as flat list. if not set (new default) items are created in a folder hierarchy
            publisher: "_", // comma separated list of publishers _ for legacy: look in category setting for PUB,
        },
    ],
    legacyRoles: false, // use user groups
    allowExport: false,
};

interface IPublishLegacy {
    publisher: string; // comma separated list of users who can click on publish button
}

// *******************************
// labels configuration
// *******************************
interface ILabelsConfig {
    /** basic label definitions */
    labels: ILabel[];
    /** grouping of labels to or, xor or review groups */
    groups: ILabelGroup[];
    /** array of the design review labels */
    design_reviews?: IDesignReview[];
    /** allows to change the background color of the top bar if a filter is selected */
    filterBackgroundColor?: string;
    /** if set to true, the filters are shown in a drop down menu (each group needs to be added as well)*/
    useFilterMenu?: boolean;
    /** beta: when looking at an item, it shows the labels underneath the item if set to true - this allows for more items.. */
    useLabelBar?: boolean;
    /** invert groups in items (this will make groups show in the wrong order... as it was in 2.2 and before) */
    invertGroups?: boolean;
}

interface ILabelStyle {
    label: {
        on: {
            foreground: string;
            background: string;
            icon?: string;
            displayName: string;
            tooltip?: string;
        };
        off: {
            foreground: string;
            background: string;
            icon?: string;
            displayName: string;
            tooltip?: string;
        };
    };
    filter: {
        on: {
            foreground: string;
            background: string;
            icon?: string;
            displayName: string;
            tooltip?: string;
        };
        off: {
            foreground: string;
            background: string;
            icon?: string;
            displayName: string;
            tooltip?: string;
        };
    };
}
interface ILabel {
    label: string;
    categories: string[];
    editors?: string[]; // can be set to restrict clicking on labels to comma seperated list of users
    reportName: string;
    reportHide?: string; // can be 'unset' in order to not show it if it is NOT set
    default?: boolean | string; // set the label when creating new elements
    defaultAsk?: boolean; // show in UI to set when creating new elements
    style: ILabelStyle;
    // dynamic
    isSelected?: boolean;
    isNegative?: boolean;
    askForComment?: boolean;
    displayName?: string;
    toolTipFilterOn?: string;
    color?: string;
    template?: ILabelTemplate;
    dontCopy?: boolean; // if set to true the label will be ignored during copy and paste of items
}

interface ILabelInstance extends ILabel {
    btn: JQuery;
}

interface ILabelTemplate {
    id: number;
    name: string;
    key: string;
}
interface ILabelGroup {
    selection: string;
    labels: string[];
    filterMenu?: IFilterMenu;
    // only for some groups
    default?: string;
    defaultAsk?: boolean;
    noColor?: string;
    noName?: string;
    noIcon?: string;
    reset?: boolean;
    foreground?: string;
    background?: string;
    askForComment?: boolean;
    showComments?: boolean;
    tooltip?: string;
    reviewers?: string[]; // can be a list of reviewers: if set only these users can change the label
    groupType?: string; // can be used to set a group type (used internally e.g. to indicate a group is a SOP group)
    //dynamic
    labelDef?: ILabel[];
    filterSelection?: string;
    virtualGroup?: boolean; // set to true of the group is created on the fly for labels which are in no real group
}

interface IFilterMenu {
    displayName: string;
    on?: {
        foreground: string;
        background: string;
        icon?: string;
        displayName: string;
        tooltip?: string;
    };
    off?: {
        foreground: string;
        background: string;
        icon?: string;
        displayName: string;
        tooltip?: string;
    };
}
interface IDesignReview {
    /** id of label (no spaces, special characters) */
    label: string;
    /** define who can approve the review. Empty array -> everybody.  */
    reviewers?: string[];
    /** beta: allow to specify the name of a field from which to take possible approvers. If set it has precedence over reviewers */
    reviewerField?: string;
    /** set to true if labels should be reset if item is changed */
    reset: boolean;
    /** title of review  */
    reviewName: string;
    /** sub title */
    reviewHelp: string;
    /** list of questions, checkboxes, or a signature box */
    reviewDetails: { name: string; help?: string; type?: string }[];
    /**  can be set to rename comment field to something else than "Design Review Comment" */
    commentHeading?: string;
    /** can be used to set a group type (used internally e.g. to indicate a group is a design review for a Process) */
    groupType?: string;
    /** can be used to put the reviews' filter into the filter menu if enabled */
    filterMenu?: IFilterMenu;
    /** beta: allows to name a table field. If this exists, the revision need to increase when setting the label */
    revisionTableName?: string;
}

// *******************************
// mail config
// *******************************
const mailConfigSetting = "mail_config";
interface IMailConfig {
    canned?: IMailConfigCanned; // can be used to overwrite standard email messages send / proposed by the system
    defaultCC?: string[]; // can be set to a default CC user to be added to all emails
}
interface IMailConfigCanned {
    /**  replacement for please sign message (in SIGN page)*/
    please_sign?: string;
    /** replacement for look at item message (in tool menu)*/
    look_at?: string;
    /** replacement for training message (in SIGN page)*/
    training?: string;
    /** replacement for document released message (in SIGN page)*/
    release_note?: string;
    /** Generic replacement */
    [key: string]: string | undefined;
}
interface IMailConfigCustom {
    name: string; // name of this mail in send to menu
    categories: string[]; // categories for which message should be available
    to: string[]; // a list of users who should get the message
    subject: string; // the subject
    message: string; // the message
}

// *******************************
// *******************************
interface ISearchConfig {
    searches: ISearchConfigSearch[];
    init?: IInitialSearches[];
}
interface ISearchConfigSearch {
    name: string;
    expr: string;
}
interface IInitialSearches {
    expr: string;
    style: string;
    computeFolder: number;
}
// *******************************
// label lock config
// *******************************

interface ILabelLockConfig {
    locks: ILabelLockConfigLocks[];
}
interface ILabelLockConfigLocks {
    label: string; // label which locks
    lockKeeper: string[]; // user who can unlock the item (by resetting the label)
    /**  if set to true, the user can add up / down traces to the item even if the item is locked*/
    allowTraces?: boolean;
}

// *******************************
// risk config
// *******************************
interface IRiskConfig {
    factors?: IRiskConfigFactor[]; //  a list of factors contributing to risk levels, see below
    method?: IRiskConfigMethod; // "+" or "*" whether to add or multiply the risk factors, "lookup" to get values from lookup table
    // '+' / '*' as method:
    maxGreen?: number; // the maximum risk level value which will be shown in green (low risk)
    maxYellow?: number; // the maximum risk level value which will be shown in yellow (moderate risk), all above will be red (high risk)
    // 'lookup' as method
    charts?: IRiskConfigZone[]; // colors and text per risk class
    rpns?: IRiskConfigRPN[]; // lookup table to determine for risk priority numbers based on weights
    // risk controls
    riskCategory?: string; // can be used to overwrite the risk category which by default is RISK
    riskCategories?: string[]; // can be used to specify several risk categories 8if there's more than one)
    mitigationTypes?: IRiskConfigMitgationType[]; // a list of categories which can be used as risk controls, see below. If there are no mitigations the RBM section will not be shown.
    mitigationTable?: IRiskConfigMitgationTable; // when user wants to show risk in table instead of 'normal' item list
    mitigationColDef?: IColDef; // can be used to specify css of column if rendered as table
    reductions?: IRiskConfigReduction[]; // a list describing how risk levels can be reduced by risk controls, see below.
    postReduction?: IRiskPostReduction; // option for user selection after risk controls
    // optional setting to configure if/how risk assessment is rendered
    rbm?: IRiskConfigRT; // allows to overwrite the default text used for Risk Before Mitigation fields
    ram?: IRiskConfigRT; // allows to overwrite the default text used for Risk After Mitigation fields (RAM)
    controls?: string; // allows to overwrite the default text used for Risk Controls
    //
    hazard_category?: string;
}
type IRiskConfigMethod = "+" | "*" | "lookup";

interface IRiskConfigRT {
    short: string; // text used in UI just before the field
    long: string; // text used in tooltip in UI
    report: string; // text used in report
    hidden?: boolean; // default:false, set to true if this should not be displayed/reported
    colDef?: IColDef; // can be used to specify css of column if rendered as table
}
interface IRiskConfigMitgationType {
    type: string; //  the short label of the category, like REQ, SPEC, TC, ...
    name: string; //  the name shown on the button to select the items, e.g.Requirement, Risk Control, ...
}
interface IRiskConfigFactor {
    type: string; // an unique identification of the factor
    label: string; // the label displayed in the UI, where user can enter some text, e.g. "Cause" or "Effect"
    weights: IRiskConfigFactorWeight[]; // an array of definitions describing how the factor contributes to the risk level, each weight is a a drop down of values
    hideTextInput?: boolean; // default: false, set to trueif there should not be a text input field to specify details
    readonly?: boolean; //  default: false, can be set to true if user should not be able to change the field
    inputType?: IRiskConfigFactorInputType; // default:text (a single line text input), can be used to overwrite the input as a drop down selection or a text area
    options?: IRiskConfigFactorOption[]; // if inputType is "select" this contains the values which can be selected
    spancols?: boolean; // if true and there are no weights, the input field will be spanning over full width

    colDef?: IColDef; // can be used to specify css of column if rendered as table
}

interface IColDef {
    width?: string; // if rendered as table this can be used to specify the css of the width
    minWidth?: string; // if rendered as table this can be used to specify the css of the min-width
    maxWidth?: string; // if rendered as table this can be used to specify the css of the max-width
    rowSpan?: boolean; // if set to true, all rows, share the same value for that column
}

interface IRiskConfigMitgationTable {
    columns: IRiskConfigTablesColumn[];
}

interface IRiskConfigTablesColumn {
    name: string;
    field: string;
    editor: RiskTableCellEditor;
    options?: any;
}
type RiskTableCellEditor = "control" | "reduction"; // note these are the 'special' editors  for risk reduction table, all others works also...

type IRiskConfigFactorInputType = "text" | "select" | "textarea" | "richtext";

interface IRiskConfigFactorOption {
    value: string; // a unique identifier
    label: string; // text to display
    changes: IRiskConfigSelectChanges[]; // for input select only. Allows to automatically change other inputs / weights when the select changes
}

interface IRiskConfigSelectChanges {
    changesFactor?: string; // identifier (type) the factor to be modified OR
    changesWeight?: string; // identifier (type) the weight to be modified
    value: number | string; // the new value to set
}

interface IRiskConfigFactorWeight {
    type: string; // an identifier of the contributing factor
    help: boolean; // if set true a help popover will explain the values
    label: string; // the label displayed in the UI, where the user can select a value from the drop down, e.g. "Probability", "Severit"y or "Detectability"
    readonly?: boolean; // default false, set to true, if an input field should not be changeable by the user
    hidden?: boolean; // default false, set to true, if field should be hidden
    values: IRiskConfigFactorWeightValue[]; // an array of options of the drop down
    colDef?: IColDef; // can be used to specify css of column if rendered as table
}

interface IRiskConfigFactorWeightValue {
    shortname: string; // name displayed in the UI, can omitted to only show the factor
    help: string; // name displayed in tool tip help
    factor: number; //factor which goes into calculation of risk
}

interface IRiskConfigReduction {
    name: string; // a unqiue id of the reduction factor
    options: IRiskConfigReductionOptions[]; // an array of reductions factors describing how the risk level can be reduced. These show up as drop down of values:
}
interface IRiskConfigReductionOptions {
    shortname: string; // the string shown in drop down
    by: number; // the amount by which a factor of the risk is changed (so negative values reduce the risk level)
    changes: string; // defines which weight is reduced.
}
interface IRiskConfigZone {
    zone?: string; // unqiue ID
    foreground?: string; // foreground color in the user interface
    background?: string; // background color in the user interface
    textColor?: string; // text color in reports
    label?: string; // text to be displayed
}

interface IRiskConfigRPN {
    // e.g. { "probability":5, "severity":4, "zone":"LOW", "text":"5 x 4 = 20" }
    zone: string; // ID of zone
    text: string; // for below combination of numbers: the resulting zone(char) and text
    [key: string]: string | number; // properties for each weight defining the lookup value for this lookup entry
}

interface IRiskPostReduction {
    weights: IRiskConfigFactorWeight[];
    help?: string; // help text, defaults to "Risk After Risk Controls"
}

// *******************************
// cascading select config
// *******************************

interface ICascadingSelect {
    startGroupId: string; // selectes one of the define groups to start with
    groups: ICascadingSelectGroup[]; // GUID is made out a combination of the defined groups
}
interface ICascadingSelectGroup {
    groupId: string; // id of a group
    type: "text" | "number" | "select";
    spec: ICascadingSelectText | ICascadingSelectNumber | ICascadingSelectSelect[]; // depends on type
}
interface ICascadingSelectNumber {
    counter: string; // db counter name
    format: string; // how number is displayed, e.g. ### or ##, ...
    nextGroupId: string; // possibly another group to follow
}
interface ICascadingSelectSelect {
    text: string; // option in dropdown menu
    help?: string; // help text for user
    nextGroupId: string; // if option is selected, possibly another group to follow
}
interface ICascadingSelectText {
    text: string; // static text block
    nextGroupId: string; // possibly another group to follow
}

// *******************************
// dhf config
// *******************************

interface IDHFConfig {
    /** if set to true the created PDFs won't be locked */
    doNotLockPDF?: boolean;
    /** default formats for different report types */
    defaultFormats?: IDHFConfigDefaultFormats;
    /** table configuration for audit trail table */
    audittrail?: IDHFConfigTable; //
    /** table configuration for signature table*/
    signatures?: IDHFConfigTable;
    /** table configuration for responsibilities table */
    responsibilities?: IDHFConfigTable;
    /** list with predefined document sections when creating new DOC items */
    controlledDocs?: IDHFConfigStandardDocs;
    /** can be used to define custom table columns (e.g. dropdowns) */
    customColumns?: IDHFConfigCustomColumn[];
    /** allows to specify an alternative for "Ready to Sign / Release" button*/
    archiveButtonName?: string;
    /** if set to true a warning will be shown if there's a missing signature */
    warnMissingSign?: boolean;
    // 2.0
    /** possibility to overwrite options in signature meaning column */
    signatureMeanings?: IStringMap; //

    // 2.3
    /**  beta: if true a user can reject instead of signing. the reject comment will be stored and showed, other cannot sign anymore */
    canReject?: boolean;
    /**  beta:  if canReject is true and this is true the user can reject without supplying a password */
    rejectWithoutPass?: boolean;
    /** beta: if canReject is true and this is true the user needs to supply special comment to reject */
    rejectNeedsComment?: boolean;
    /** beta: text shown in signature box if someone needs to sign something */
    signatureHint?: string;
    /** beta: list of users who can change name of person who needs to sign */
    proxyRights?: string[];
    /** beta: a list of options to declare what user is signing for ["i release", "i confirm correct","I don't know"], */
    signedMeaning?: string[];
    /** beta: removes not needed notifications and adds new ones when changing signatures proxies */
    fixNotifications?: boolean;
    /** beta: 0 leave original sign info. 1 replace name and title when changing signature proxies */
    proxySignTableUpdate?: number;
    /** beta: set to true show a column with sign meaning in SIGN */
    showOriginalSignMeaning?: boolean;

    // obsolete:
    /** obsolete */
    hideFileFormats?: IDHFConfigHideFormat[]; // possibility to hide formats per report type
    /** obsolete */
    customTables?: IDHFConfigCustomTable[]; // possibility to define custom tables
    //  customTable1_id": IDHFConfigTable,
    // customTable2_id: IDHFConfigTable,
    /** obsolete  */
    customReports?: IDHFConfigCustomReports; // legacy option defining customer specific reports
    /**  obsolete */
    categories?: IDHFCategories; // allows to overwrite defaults
    /**  obsolete */
    captions?: ICaptions; // set to at least {} will enable captions for figures/tables (see below for more options)
    /**  obsolete */
    renderInTree?: string[]; // if REPORT, SIGN, DOC should be rendered in normal tree
    /** obsolete */
    toolFolderName?: string; // can be set to rename the "Project, Reports & Controlled Documents" folder
    /** obsolete */
    functionDefaults?: IPrintFunctionParamsOverwrites; // overwrites for print functions
}

// definition of formatting of captions and references to captions
interface ICaptions {
    figure?: ICaption; // optional: specify how references to captions are rendered (default italic)
    table?: ICaption; // optional: specify how references to captions are rendered (default italic)
}
interface ICaption {
    preNo: string; // e.g. "Figure "
    postNo: string; // e.g "."
}
interface IDHFCategories {
    documentTypes: string[]; // DOC, SIGN, REPORT
    documentForms: string[]; // DOC
    documentSigned: string[]; // SIGN items which have / can be signed (frozen)
    documentTemplates: string[]; // SIGN items which can be used as a template
    signAs: string; // SIGN category to create in order to sign
}

interface IDHFConfigCustomReports {
    // allows to add custom reports (legacy option, not available to customers)
    group: string;
}
interface IDHFConfigDefaultFormats {
    DOC: IDHFConfigDefaultFormatsOption; // default format of documents create from DOC items
    SIGN: IDHFConfigDefaultFormatsOption; // default format of documents create from SIGN items
    REPORT: IDHFConfigDefaultFormatsOption; // default format of documents create from REPORT items
    [map: string]: IDHFConfigDefaultFormatsOption;
}
type IDHFConfigDefaultFormatsOption =
    | "docx" // word
    | "pdf" // pdf
    | "html"; // html

interface IDHFConfigHideFormat {
    category: string;
    format: IDHFConfigDefaultFormatsOption;
}
interface IDHFConfigCustomColumn {
    type: string;
    options: IDropdownOption[] | any;
    name: string;
    editor?: string;
}
interface IDHFConfigTable {
    columns: IDHFConfigTableColumn[];
}
interface IDHFConfigTableColumn {
    name: string;
    field: string; // col0, col1, col2, etc.
    columnType: IDHFConfigTableColumnType;
    pos: number; // pos of column 0,1,2
    editor?: TableCellEditor;
    options?: IDropdownOption[];
}
type IDHFConfigTableColumnType =
    | "type0" // Short Text (one line text)
    | "type1" // Long Text (multi line text)
    | "type2" // Version Letter (A, B, C, ...)
    | "type3" // eSignature (for electronic signatures)
    | "type4" // Name (a printed user name)
    | "type5" // eDate (automatic date if there is a eSignature in the same row)
    | "type6" // : "signaturemeaning",
    | "type7" // : "eco",
    | "type8" // "ecocapa"
    | "type9" // date
    | "type10" // not used anymore
    | "type11" // Group / Role
    | "type12" // Revision
    | "type13" // auto date: when creating a new row set the date
    | "type14"; // number
interface IDHFConfigCustomTable {
    name: string; // name of table (in section selection dialog
    id: string; // internal id of table (valid json keys, e.g. customTable1_id)
}

interface IDHFConfigStandardDocs {
    [key: string]: IDHFConfigStandardDocsDef; // defines a standard doc template, e.g "Plan": IDHFConfigStandardDocsDef
}
interface IDHFConfigStandardDocsDef {
    fields: IDHFConfigStandardDocsSection[]; // defines sections of a standard doc template
}
interface IDHFConfigStandardDocsSection {
    [key: string]: string;
}

// *******************************
// context pages
// *******************************

interface IContextPageConfig {
    categoryHelp?: IContextPageConfigHelp; // allows to define specific help text per category (needs "help" tab enabled)
    itemHelp?: IContextPageConfigHelp; // allows to define specific help text per item (needs "help" tab enabled)
    tabs?: IContextPageConfigTab[]; // defines the tabs in the context pages
}
interface IContextPageConfigHelp {
    [key: string]: string; // defines custom help information for a given category if na item of this category is selected, e.g. "UREQ":"please enter user requirements","SREQ":"define system requirements here"
}
interface IContextPageConfigTab {
    title: string; // the name of the context tab in the ui
    type: ContextPageConfigTabOption; // defines the content of the tab
    tabId?: string; // internal tab id once generated
    hipchat?: boolean; // depriciated
    baseURL?: string; // (required for type iframe) Note: the url must be on an https://  protocol, otherwise the browser will reject to load it.
}

type ContextPageConfigTabOption =
    | "help" // shows a tab with pre-defined (or custom) help explaining what you can do with the selected item, depedning on its category
    | "support" // allows to create help desk tickets from within the software including information about the item/project and operating system / browser
    | "faq" // shows a tab with faqs about the product. This is useful for first time visitors
    | "references" //  shows a tab with all up and down references of the selected items
    | "smartlinks" // shows a tab with all smartlinks in the text boxes and tables of the selected item
    | "iframe" // embeds an iframe. This iframe can be page which is hosted on your own servers. When called it receives the content of the selected item (as payload)
    | "iframeget" // embeds an iframe. This iframe can be page which is hosted on your own servers. When called it receives part of the item information in the called url
    | "upreferences" // (recursive) uplinks
    | "downreferences" // (recursive) downlinks
    | "foldercontent" //  shows the items and folders in a folder
    | "trainings"; // shows any registered trainings

// *******************************
// XTCconfig
// *******************************

interface ITestConfig {
    xtcType: string; // must be "XTC"
    cloneSources: string[]; // all categories which can be converted to XTC, e.g. [ "TC", "ITC" ]
    presetFields: ITestConfigPresetField[]; // fields that the user can enter when creating XTCs, e.g. [ { "field": "name", "value": "Version" } ]
    render: ITestConfigTables; // defines how tables look like
    defaultTestResultResult: string; // "an|warning|not started",
    automatic: ITestRuleAuto[];
    manual: ITestRuleManual[];
    perStep: ITestRuleStep[];
    reExecute?: string; // a (search expression) to find failed tests to re execute them
    autoFillTester?: string; // first - set it to user saving if empty, last - always set it to user saving, "" - don't set it
}

interface ITestConfigPresetField {
    field: string; // how to identify the field ("name" means the displayed name", "meaning" can refer to a field setting fieldMeaning)
    value: string; // the what the field name should be
}
interface ITestConfigTables {
    [key: string]: ITestConfigTablesColumns;
}

interface ITestConfigTablesColumns {
    columns: ITestConfigTablesColumn[];
}

interface ITestConfigTablesColumn {
    name: string;
    field: string;
    editor: TableCellEditor;
    options?: IDropdownOption[] | any;
}

type TableCellEditor =
    | ColumnEditor.text
    | ColumnEditor.none
    | ColumnEditor.textline
    | ColumnEditor.design
    | ColumnEditor.uprules
    | ColumnEditor.downrules
    | ColumnEditor.rules
    | ColumnEditor.result
    | ColumnEditor.user
    | ColumnEditor.versionletter
    | ColumnEditor.date
    | ColumnEditor.select;

interface ITestRule {
    human: string; //a human readable text explaining the test result
    code: string; // a unique identifier for the status of the test result
    render: TestResultType; // whether test result means 'ok' (all good), 'error' (test failed), 'warning' (e.g. not completed): this is used in report renderings
}

interface ITestRuleAuto extends ITestRule {
    rule: TestResultRule; // the type of rule to determine from the test steps the overall status. Can be 'one', 'all' or ''. The first rule matching determines the status
    param: string; // parameter for the rule, i.e. a code of a test step
}

interface ITestRuleManual extends ITestRule {
    command: string; // Tthe string in the drop down menu
}
interface ITestRuleStep extends ITestRuleManual {
    key: string; // the keyboard shortcut
    image: string; // so far only internally used to show icons for common states (passed, fail and docu)
}

type TestResultType = "ok" | "error" | "warning";
type TestResultRule = "all" | "one" | "";

// --------------------------------------------------
// project settings full admin client
// --------------------------------------------------

// *******************************
// categories rendering / label + color
// *******************************
interface ICategorySetting {}
interface ICategorySettingTitle extends ICategorySetting {
    // name of category setting: title
    // example of value:  {create:"TITLE OF THIS", placeholder:"enter title of this"}
    create?: string; // if user can enter title of an item, this is the text of th ehlpe to displayed, if not specified the default is Title:
    placeholder?: string; // if this is set this is the placeholder for the title input input field. Default "enter title"
}
interface ICategorySettingTextIcon extends ICategorySetting {
    // name of category setting: textIcon
    // example of value:  {color:"#345500", text:"RC"}
    color?: string; // html color of category font in tree
    text?: string; //  short label of category in tree
}
interface ICategorySettingTabs extends ICategorySetting {
    // define tabs inside an item
    tabs: ICategorySettingTab[];
}
interface ICategorySettingTab {
    id?: string; // temp ids for ui,
    name: string; //  tab name
    fields: string[]; // fields to display in tab
}
// *******************************
// grouping of categories in meta categories
// *******************************

interface ICategoryGroups {
    groups: ICategoryGroup[]; // a list of groups
}

interface ICategoryGroup {
    categories: string[]; // list of categories in that group
    text: string; // label displyed in tree (short form / bold)
    name: string; // name displayed in tree (long form)
    color: string; // color of group in tree
    position?: number; // position
    helpPage?: string; // link to a page which contains group specific help
}

// *******************************
// ACL user access lists
// *******************************
const ACL_SETTING = "acl";

interface IACL {
    rules: IACLRules[]; // a list of rules
}

interface IACLRules {
    name: string; //
    groups: string[]; // group names
    acl: IACLGroupsAcl[]; // access rights for group
}
interface IACLGroupsAcl {
    category: string; // name of the category
    fields?: string[]; // fields array, can exist for edit... if fields array exist but is empty no user can edit anything
    // in field array you'll find field name which allow editing
    rights: string[]; // given rights
}
/*
 { category:"REQ", rights:["edit"], fields:[]}
 { category:"REQ", rights:["edit"]}
*/
// --------------------------------------------------
// field settings in full admin client
// --------------------------------------------------

interface IFieldParameter {
    [key: string]: any;
    // common control options = all filed types and all categories
    /** if set to true, the control will not be editable by the user */
    readonly?: boolean;
    /** if set to true the field will not be shown in reports */
    hide_report?: boolean;
    /** if set to true the field will not be shown in documents */
    hide_document?: boolean;
    /** if set to true the field will not be shown in published (QMS) */
    hide_publication?: boolean;
    // options to show help for an input field
    hide_UI?: boolean;
    /** set to 1 to not clean the field values */
    unsafeHtml?: number;
    /** this can be set to point to a an external website which is openend when user clicks on a link, e.g.
     * "externalHelp":"matrix.com". Note this must be a website which is accesible through https://url */
    externalHelp?: string;
    /** this can be set to show help as a tooltip, e.g. "popupHelp":"enter a short description" */
    popupHelp?: string;
    /** this can be set to render a help line underneath the "heading":"enter a long description" */
    inlineHelp?: string;
    /** for folder category */
    visibleOption?: string;
    /** for SIGN category
     * if set to true, the field is not visible (only makes sense with the above flag)
     */
    invisible?: boolean;
    /** this allows to copy fields from underlying DOC into the SIGN item.
     * Note: Fields are copied only if this is set to true and the labels (names) of the field is the same in the
     * DOC and the SIGN */
    copyfromdoc?: boolean;
    /** legacy options */
    adminVisibility?: boolean;
    /** legacy options */
    requiresContent?: boolean;
}

// *******************************
// drop down options
// *******************************
interface IDropDownConfig {
    fieldMeaning?: string; // can be used in reports / docs
    placeholder?: string; // to be shown if no value is selected
    options: IDropdownOption[]; // list of options in drop down
    groups?: IDropdownGroup[]; // grouping of option
}
interface IDropdownGroup {
    value: string; // id of group (as class reference in drop down option), groups are sorted by this ID
    label: string; // name of group as displayed in UI
}

interface IDropdownOption {
    id: string; // internal ID
    label: string; // visible name in drop down
    class?: string; // if option should be in a group of options, this is the id (value) of  group
    disabled?: boolean; // for classic select dropdowns in table, allow to define unselectable things
    strikethrough?: boolean; // to visually indicate that a selection is deleted, out-of-date, etc
}

// --------------------------------------------------
// settings with user interface
// --------------------------------------------------

interface IAutoFillSetting {
    allowAutoFill: boolean; // if set to true auto fill is allowed for all of the below
    allowLoginAutoFill: boolean; // overwrite auto fill setting for login
    allowDocSignAutoFill: boolean; // overwrite auto fill setting for electronic signatures
    allowLabelSignAutoFill: boolean; // overwrite auto fill setting for label signatures
    allowGateAutoFill: boolean; // overwrite auto fill setting for gate signatures
    allowPublishAutoFill: boolean; // overwrite auto fill setting for publications
}

// *******************************
// smart text , abbreviations, ....
// *******************************
const smartTextConfigSetting = "rtf";
interface ISmartTextConfig {
    replacements?: ISmartTextConfigReplacement[];
}
interface ISmartTextConfigReplacement {
    /** id of the macro: needs to be simple combination of letters and digits */
    what: string;
    /** the text to display */
    with: string;
    /** value from 1 to 4: 1=plain text 2=rich text 3=term 4=abbreviation */
    tagType: number;
    /**  additional explanation for terms*/
    description?: string;
    /** set to true to ask user to review before creating document */
    warn: boolean;
    /** date/time of creation */
    when: string;
    /** internal: not to be used */
    projectSetting?: boolean;
    /** legacy: now handled by tagType */
    plain?: boolean;
    /**  Include this term/abv in the List of Terms and abbreviation doc section when not surrounded with _ */
    tableNoUnderscore?: boolean;
}
interface IProjectLogo {
    fileId: string;
}

// --------------------------------------------------
// hidden / beta features
// --------------------------------------------------

interface IExtras {
    tableCanImport: boolean | string; // set to true to allow importing into table
    copyPaste: boolean | string; // set to true to allow copy paste
    moveIn: boolean | string; // set to true to allow to move into folder
    excelImport: boolean | string; // set to true to allow to import from excel
    deepTouch: boolean | string; // allows items to be touched recursively
    noTouch: boolean | string; // allows items to hide touch from the menu
    setLabel: boolean | string; // allow to set labels on multiple items in tree
    compareX: boolean | string; // allow to compare any documents (also between projects)
    compareInsideX: boolean | string; // allow to compare any documents
    indexer: boolean; // allow to re-index database after config changes
    cleanup: boolean | string; // show cleanup tools
    defaultToNewEditor: boolean | string; // show cleanup tools
    enableLegacyReport?: "0" | "1" | "2"; // Allow to re-enabled the legacy REPORT category. 0=warning, 1=removed, 2=enabled
}

interface IEmbeddedReport {
    reportId: string;
}

interface IProjectGroups {
    groups: IProjectGroup[];
}

interface IProjectGroup {
    name: string;
    projects: string[];
}

// --------------------------------------------------
// (custom) fields
// --------------------------------------------------

interface IFieldCapabilities {
    /** if set only one of this kind of fields can be created per category*/
    onlyOne?: boolean;
    /** can be published to QMS site */
    canBePublished: boolean;
    /** can be set to readonly */
    canBeReadonly: boolean;
    /** whether field can be hidden in DOCs */
    canHideInDoc: boolean;
    /** whether a field can be a preset in an XTC */
    canBeXtcPreset: boolean;
    /** whether a field needs to have a value set */
    canRequireContent?: boolean;
    /** whether a field can be added to DOCs and SIGN */
    canBeUsedInDocs?: boolean;
    /** an optional validation function to run against the code*/
    validationFunction?: JsonEditorValidation;
    /** the name of interface schema. If no validation is provided this will also be used for validation*/
    schema?: string;
    /** if true the user needs to set some settings to make it useful*/
    needsConfiguration?: boolean; //
    /**  user can allow unsafe text entry,*/
    canBeUnsafe?: boolean; //
    /** Can be imported from excel */
    canImportedFromExcel?: boolean;
}

interface IFieldDescription {
    id: string;
    label: string;
    class: string; // in which categories it can be used: all (well all categories), toolbar, docs, report, sign, sync, beta
    help: string;
    capabilities: IFieldCapabilities; // what the field can be used
}
// --------------------------------------------------
// notification
// --------------------------------------------------
interface INotificationConfig {
    enabled: boolean; // show notification user interface
    closeAuto: boolean; // allow user to close automatically created notification
    manualCreate: boolean; // allow user to create
    browserNotificationDisabled?: boolean;
    browserNotificationAutoCloseAfter?: number;
}

let notificationSetting = "settingsNotification";
let defaultNotificationConfig: INotificationConfig = {
    enabled: true,
    closeAuto: true,
    manualCreate: true,
    browserNotificationDisabled: false,
    browserNotificationAutoCloseAfter: 9000,
};
// --------------------------------------------------
// column autofill
// --------------------------------------------------

/** allow to set cell in table column if another dropdown cell in same row changes*/
interface IAutoColumn {
    /** a list of mappings (which cell is updated upon change of which cell) */
    maps: IAutoColumnMap[];
}

interface IAutoColumnMap {
    /** name of cell which triggers change */
    dropdownColumnName: string;
    /** name of cell which is updated  */
    textColumnName: string;
    /** mapping from selected to set value */
    mapping: IAutoColumnMapping[];
}

interface IAutoColumnMapping {
    /** selected value */
    dropdownValue: string;
    /** set value */
    textValue: string;
}

// setting can be for customer (e.g. user titles) and project (user roles)
const autoColumnSetting = "autoColumn";
const autoColumnDefault: IAutoColumn = { maps: [] };

// -----------------------------------------
// html cleanup
// -----------------------------------------

interface ICleanup {
    cleanup: boolean;
    tags: string[];
    attributes: IStringStringArrayMap;
    enforcedAttributes: IStringStringArrayMap;
    protocolAttributes: ICleanupProtocol[];
}
interface ICleanupProtocol {
    element: string;
    attribute: string;
    protocols: string[];
}

/** defines parameters for imports */
interface IImportConfig {
    /** includes keep same id */
    includes: IImportConfigDetails;
    /** copies are editable new copies */
    copies: IImportConfigDetails;
}

interface IImportConfigDetails {
    /** includes can be locked by specifying a lock label */
    lockLabel: string;
    /** there can be a list of users who can import (if there's nobody in there, or no list is defined: everybody can) */
    importMasters?: string[];
    /** ItemParentMode for new includes or copies. By default "orphan", otherwise "preserve" */
    itemParentMode?: string;
}
