/**
 * Basic Functions can be referred to by other businesslogic modules and elsewhere,
 * however it should only import the absolute minimum necessary dependencies.
 * It supports the Matrix SDK, which runs in non-web environments.
 */
export { BasicFunctions };
class BasicFunctions {
    static isTrue(obj: undefined | null | boolean | string | number): boolean {
        if (obj && (obj.toString().toLowerCase() === "true" || obj.toString() === "1")) {
            return true;
        }
        return false;
    }

    static isFalse(obj: undefined | null | boolean | string | number): boolean {
        if (typeof obj == "undefined") {
            return false;
        }
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        if (obj == false || obj == 0 || obj.toString().toLowerCase() === "false" || obj.toString() === "0") {
            return true;
        }
        return false;
    }
}
