import { IDHFControlDefinition, IDHFSection } from "../../../common/businesslogic/index";
import { ml } from "../../../common/matrixlib";
import { IBaseControlOptions } from "../../../common/UI/Controls/BaseControl";
import { globalMatrix } from "../../../globals";
import { IMultiSelectOptions } from "../../../common/businesslogic/FieldHandlers/Document/DropdownDocFieldHandler";
import { BaseDHFSection } from "./BaseDHFSection";

export { MultiSelect };

class MultiSelect extends BaseDHFSection<IMultiSelectOptions> implements IDHFSection {
    renderControl(ctrl: IDHFControlDefinition, ctrlParameter: IBaseControlOptions) {
        let controllerConfig = this.getConfig(ctrl);

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        ctrl.control.mxDropdown(
            ml.JSON.mergeOptions(ctrlParameter, {
                parameter: {
                    maxItems: controllerConfig.maxItems,
                    sort: controllerConfig.sort,
                    optionSetting: controllerConfig.optionSetting,
                    create: controllerConfig.create,
                },
            }),
        );
    }

    showSpecificSettings(ctrl: IDHFControlDefinition, ctrlParameter: IBaseControlOptions, custom: JQuery) {
        let controllerConfig = this.getConfig(ctrl);

        let maxItems = `<select class="opt_maxItems docOptionSelect form-control" style="margin:10px;width:80%">
                <option value="1" ${controllerConfig.maxItems == 1 ? "selected" : ""}>1</option>
                <option value="2" ${controllerConfig.maxItems == 2 ? "selected" : ""}>2</option>
                <option value="3" ${controllerConfig.maxItems == 3 ? "selected" : ""}>3</option>
                <option value="4" ${controllerConfig.maxItems == 4 ? "selected" : ""}>4</option>
                <option value="5" ${controllerConfig.maxItems == 5 ? "selected" : ""}>5</option>
                <option value="6" ${controllerConfig.maxItems == 6 ? "selected" : ""}>6</option>
                <option value="7" ${controllerConfig.maxItems == 7 ? "selected" : ""}>7</option>
                <option value="8" ${controllerConfig.maxItems == 8 ? "selected" : ""}>8</option>
                <option value="9" ${controllerConfig.maxItems == 9 ? "selected" : ""}>9</option>
                <option value="99" ${controllerConfig.maxItems == 99 ? "selected" : ""}>99</option>
                </select>`;
        let create =
            '<div class="checkbox" ><label><input type="checkbox" class="opt_create" ' +
            (controllerConfig.create ? "checked" : "") +
            "> allow to create options</label></div>";
        let sort =
            '<div class="checkbox" ><label><input type="checkbox" class="opt_sort" ' +
            (controllerConfig.sort ? "checked" : "") +
            "> sort options</label></div>";

        let options = $('<select class="opt_options form-control" style="margin:10px;width:80%">');
        $.each(globalMatrix.ItemConfig.getDropDowns(), function (idx, dd) {
            options.append(
                $(
                    `<option value="${dd.id}" ${dd.id == controllerConfig.optionSetting ? "selected" : ""}>${
                        dd.label
                    }</option>`,
                ),
            );
        });

        custom.append($("<span>Options: </span>").append(options));
        custom.append($("<span>Maximum selectable options: </span>").append(maxItems));
        custom.append(create);
        custom.append(sort);
    }
    async saveSpecificSettingsAsync(ctrl: IDHFControlDefinition, ctrlParameter: IBaseControlOptions, custom: JQuery) {
        let controllerConfig = this.getConfig(ctrl);
        let before = JSON.stringify(controllerConfig);
        // read values from UI
        controllerConfig.maxItems = $(custom.find(".opt_maxItems")[0]).val();
        controllerConfig.create = $(custom.find(".opt_create")[0]).is(":checked");
        controllerConfig.sort = $(custom.find(".opt_sort")[0]).is(":checked");

        controllerConfig.optionSetting = $(custom.find(".opt_options")[0]).val();

        this.fieldHandler.setDHFConfig(controllerConfig);

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        ctrl.control.html("");
        this.renderControl(ctrl, ctrlParameter);

        return JSON.stringify(this.getConfig(ctrl)) != before;
    }
    async verifyContentAsync(ctrl: IDHFControlDefinition) {
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        let lastVal = await ctrl.control.getController().getValueAsync();
        if (!lastVal) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            ctrl.control.closest(".ft_dhf").find(".baseControlHelp").addClass("contentNeeded");
        } else {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            ctrl.control.closest(".ft_dhf").find(".baseControlHelp").removeClass("contentNeeded");
        }
    }
}
