import { IPlugin, plugins } from "../../common/businesslogic/index";
import { mTM } from "../../common/businesslogic/index";
import { ml } from "../../common/matrixlib";
import { UIToolsConstants } from "../../common/matrixlib/MatrixLibInterfaces";
import { ItemControl } from "../../common/UI/Components/index";
import { IDropdownParams } from "../../common/UI/Controls/dropdown";
import {
    IRiskParameter,
    IRiskValueFactorWeight,
    RiskControlImpl,
    IRiskValue,
} from "../../common/UI/Controls/riskCtrl2";
import {
    IItem,
    app,
    globalMatrix,
    matrixSession,
    ControlState,
    IStringNumberMap,
    IStringMap,
    IItemPut,
    IGenericMap,
} from "../../globals";

import { IDropdownOption } from "../../ProjectSettings";
import { FieldDescriptions } from "../../common/businesslogic/FieldDescriptions";

export type { IImportColumn, IImportRow };
export { initialize };

interface IImportColumn {
    label: string; // as displayed
    id: string; // id in json
    isLabel?: boolean; // true if column is a label

    index?: number;
    fieldId?: number;
    fieldType?: string;
}
interface IImportRow {
    cells: string[];
}

class MassImportImpl implements IPlugin {
    /* *************************************************
        UI
    ************************************************* */
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private item: IItem;
    private errorLog: string[] = [];
    private duplicates: string[] = [];
    public isDefault = true;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private xml: JQuery;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private dlg: JQuery;

    private jsonFields = FieldDescriptions.get()
        .filter((o) => o.capabilities.canImportedFromExcel)
        .map((o) => o.id);

    initItem(item: IItem, jui: JQuery) {
        this.item = item;
    }

    initServerSettings() {}
    initProject() {}
    supportsControl() {
        return false;
    }

    updateMenu(ul: JQuery) {
        let that = this;

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        if (!this.item || !app.isFolder(this.item.id)) {
            return; // do show only for folders
        }

        let extras = globalMatrix.ItemConfig.getExtrasConfig();

        if (
            !extras ||
            (!ml.JSON.isTrue(extras.excelImport) && !(extras.excelImport === "admin" && matrixSession.isAdmin()))
        ) {
            return; // not enabled
        }

        if (!matrixSession.isEditor()) {
            return;
        }

        $(`<li><span class="toolmenu">Import Items from Excel</span></li>`)
            .appendTo(ul)
            .click(function (event: JQueryMouseEventObject) {
                let menu = $(event.delegateTarget);

                // show dialog
                that.dlg = $("<div>").appendTo($("body"));
                let ui = $("<div style='height:100%;width:100%'>");

                ml.UI.showDialog(
                    that.dlg,
                    "Convert Excel rows to items",
                    ui,
                    $(document).width() * 0.9,
                    app.itemForm.height() * 0.9,
                    [
                        {
                            text: "Next",
                            class: "btnDoIt",
                            click: function () {},
                        },
                    ],
                    UIToolsConstants.Scroll.Vertical,
                    true,
                    true,
                    () => {
                        that.dlg.remove();
                    },
                    () => {
                        that.wizardStepPrepare(ui, $(".btnDoIt", that.dlg.parent()));
                    },
                    () => {},
                );
            });

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        if (mTM.isTC(this.item.type)) {
            $(`<li><span class="toolmenu">Import Tests from Excel</span></li>`)
                .appendTo(ul)
                .click(function (event: JQueryMouseEventObject) {
                    let menu = $(event.delegateTarget);

                    // show dialog
                    that.dlg = $("<div>").appendTo($("body"));
                    let ui = $("<div style='height:100%;width:100%'>");

                    ml.UI.showDialog(
                        that.dlg,
                        "Convert Excel to tests",
                        ui,
                        $(document).width() * 0.9,
                        app.itemForm.height() * 0.9,
                        [
                            {
                                text: "Next",
                                class: "btnDoIt",
                                click: function () {},
                            },
                        ],
                        UIToolsConstants.Scroll.Vertical,
                        true,
                        true,
                        () => {
                            that.dlg.remove();
                        },
                        () => {
                            that.wizardStepPrepareTC(ui, $(".btnDoIt", that.dlg.parent()));
                        },
                        () => {},
                    );
                });
        }
    }

    /* *************************************************
        Wizard
    ************************************************* */

    // import excel one WS at a time mapping column to fields
    private wizardStepPrepare(ui: JQuery, next: JQuery) {
        let that = this;
        ui.html("<h1>Step 1: Prepare the excel</h1>");
        let ol = $("<ol>").appendTo(ui);
        $("<li>Unmerge all cells in all worksheets</li>").appendTo(ol);
        $("<li>Remove all comments</li>").appendTo(ol);

        ui.append("<h1>Step 2: Upload the file</h1>");

        this.appendFileUpload(ui, next, (ui, next) => this.wizardStepSelectWS(ui, next));

        ml.UI.setEnabled(next, false);
    }

    // import excel, each WS will be one new TC, can only fill test table
    private wizardStepPrepareTC(ui: JQuery, next: JQuery) {
        let that = this;
        ui.html("<h1>Step 1: Prepare the excel</h1>");
        let ol = $("<ol>").appendTo(ui);
        $("<li>Remove worksheets without tests (e.g. title pages)</li>").appendTo(ol);
        $("<li>Unmerge all cells in all worksheets</li>").appendTo(ol);
        $("<li>Make sure all worksheets have the same structure</li>").appendTo(ol);

        ui.append("<h1>Step 2: Upload the file</h1>");

        this.appendFileUpload(ui, next, (ui, next) => this.wizardStepSelectColumns(ui, next));

        ml.UI.setEnabled(next, false);
    }

    // add a section to upload an excel, which is converted to xml and handed to the next step
    private appendFileUpload(ui: JQuery, next: JQuery, nextStep: (ui: JQuery, next: JQuery) => void) {
        let that = this;

        $("<div>")
            .appendTo(ui)
            .fileManager({
                parameter: {
                    readonly: false,
                    manualOnly: true,
                    extensions: [".xls", ".xlsx"],
                    single: true,
                    textTodo: " ",
                    hideNoFileInfo: true,
                },
                controlState: ControlState.FormEdit,
                canEdit: true,
                help: " ",
                fieldValue: "[]",
                valueChanged: function () {},
                processExternally: function (files: FileList) {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    ml.File.convertXLSXAsync(files[0]).done(function (text: string) {
                        // "fix" not exported cells <Cell />
                        text = text.replace(/<Cell \/>/g, "<Cell></Cell>");
                        that.xml = $(text);
                        if (that.xml.length == 0) {
                            ml.UI.showError("Cannot read xml", "Conversion failed somehow...");
                            return;
                        }
                        let worksheets = $("Worksheet", that.xml);
                        if (worksheets.length == 0) {
                            ml.UI.showError("No worksheets in xml", "There are no worksheets in xml...");
                            return;
                        }
                        nextStep(ui, next);
                    });
                    return false;
                },
            });
    }

    // for normal file excel to item:
    private wizardStepSelectWS(ui: JQuery, next: JQuery) {
        let that = this;
        ui.html("");
        ui.closest(".ui-dialog-content").removeClass("dlg-v-scroll");
        let h1 = $("<h1>Step 3: Select the worksheet to import</h1>").appendTo(ui);
        let thead: JQuery;
        let tbody: JQuery;
        let select = $("<div>").appendTo(ui);
        let tableContainer = $("<div>").appendTo(ui);
        let worksheets = $("Worksheet", this.xml);
        let ws: IDropdownOption[] = [];
        $.each(worksheets, function (idx, worksheet) {
            ws.push({ id: idx, label: $(worksheet).attr("ss:Name") });
        });

        select.mxDropdown({
            controlState: ControlState.FormEdit,
            canEdit: true,
            help: "",
            fieldValue: "",
            valueChanged: async function () {
                if (select) {
                    let selected = await select.getController().getValueAsync();
                    tableContainer.html("");
                    let table = $("<table class='table table-bordered'>").appendTo(tableContainer);
                    thead = $("<thead>").appendTo(table);
                    thead.append("<th style='padding:0'>include</th>");
                    tbody = $("<tbody>").appendTo(table);

                    ml.UI.setEnabled(next, selected != "");
                    if (selected) {
                        that.rows = [];

                        let wsRows = $("row", worksheets[selected]);

                        // count max columns and copy data
                        let maxColumns = 0;
                        $.each(wsRows, function (rowIdx, row) {
                            let cells: string[] = [];

                            let cellIdx = 0;
                            $(row)
                                .children("cell")
                                .each((cidx, cell) => {
                                    cellIdx = that.getCellIndex($(cell), cellIdx);
                                    cells[cellIdx] = $(cell).text().trim();
                                    maxColumns = Math.max(maxColumns, cellIdx);
                                    cellIdx++;
                                });
                            that.rows.push({ cells: cells });
                        });
                        // create header
                        maxColumns++;
                        for (let cellIdx = 0; cellIdx < maxColumns; cellIdx++) {
                            thead.append("<th style='padding:0'>");
                        }
                        // create table content
                        $.each(that.rows, function (rowIdx, row) {
                            const tr = $("<tr>").appendTo(tbody);
                            $("<td><input type='checkbox' checked></td>").appendTo(tr);
                            for (let cellIdx = 0; cellIdx < maxColumns; cellIdx++) {
                                tr.append("<td>" + (row.cells[cellIdx] ? row.cells[cellIdx] : "") + "</td>");
                            }
                        });
                    }
                }
            },
            parameter: {
                placeholder: "select worksheet",
                maxItems: 1,
                options: ws,
                groups: [],
                create: false,
                sort: false,
                splitHuman: false,
            },
        });

        ml.UI.setEnabled(next, false);
        next.unbind("click");
        next.click(function () {
            select.remove();
            that.wizardStepMapColumns(ui, next, h1, thead, tbody);
        });
    }

    // for normal file excel to item:
    private wizardStepSelectColumns(ui: JQuery, next: JQuery) {
        let that = this;
        ui.html("");
        ui.closest(".ui-dialog-content").removeClass("dlg-v-scroll");
        $("<h1>Step 3: Match the columns</h1>").appendTo(ui);

        let table = $("<table>").appendTo(ui);
        $("<thead>").appendTo(table);
        let tbody = $("<tbody>").appendTo(table);

        let tConfig = globalMatrix.ItemConfig.getTestConfig();
        let tColumns =
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            tConfig && tConfig.render && tConfig.render[this.item.type] ? tConfig.render[this.item.type].columns : [];
        if (!tColumns || tColumns.length == 0) {
            this.error(-1, "Tests are not (correctly) configured for this category");
            return;
        }
        // how many rows to ignore
        let dIgnore: IDropdownOption[] = [];
        for (let idx = 0; idx < 26; idx++) dIgnore.push({ id: idx + "", label: "ignore first " + idx + " row(s)" });

        let ignore = $("<div>").appendTo(ui);
        ignore.mxDropdown({
            controlState: ControlState.FormEdit,
            canEdit: true,
            help: "Ignore headers",
            fieldValue: "1",
            valueChanged: function () {},
            parameter: {
                placeholder: "ignore headers",
                maxItems: 1,
                options: dIgnore,
                groups: [],
                create: false,
                sort: false,
                splitHuman: false,
            },
        });

        // mapping to columns
        let importMap: IStringNumberMap = {};
        let dd: IDropdownOption[] = [{ id: "", label: "ignore" }];
        for (let idx = 0; idx < 26; idx++) dd.push({ id: idx + "", label: String.fromCharCode(65 + idx) });

        $.each(tColumns, function (cIdx, column) {
            let tr = $("<tr>").appendTo(tbody);
            $("<td>").appendTo(tr).html(column.name);
            let td = $("<td style='padding:8px 0 0 8px;'>").appendTo(tr);
            let select = $("<div>").appendTo(td);
            importMap[column.field] = cIdx;
            select.mxDropdown({
                controlState: ControlState.FormEdit,
                canEdit: true,
                help: "",
                fieldValue: cIdx + "",
                valueChanged: async function () {
                    if (select) {
                        importMap[column.field] = (await select.getController().getValueAsync())
                            ? Number(await select.getController().getValueAsync())
                            : -1;
                    }
                },
                parameter: {
                    placeholder: "select mapping",
                    maxItems: 1,
                    options: dd,
                    groups: [],
                    create: false,
                    sort: false,
                    splitHuman: false,
                },
            });
        });
        ml.UI.setEnabled(next, true);
        next.unbind("true");
        next.click(async function () {
            that.importTC(ui, next, Number(await ignore.getController().getValueAsync()), importMap);
        });
    }

    private getCellIndex(cell: JQuery, order: number): number {
        // it's either the place (or an explicit index if there are some gaps...)
        if ($(cell).attr("ss:Index")) {
            return Number($(cell).attr("ss:Index")) - 1;
        }
        return order;
    }
    private wizardStepMapColumns(ui: JQuery, next: JQuery, h1: JQuery, thead: JQuery, tbody: JQuery) {
        let that = this;
        h1.html("Step 4: Map columns to fields");

        // get options for mapping (supported fields)
        let ddOptions: IImportColumn[] = [
            { isLabel: false, label: "ignore", id: "" },
            { isLabel: false, label: "HIERARCHY", id: "HIERARCHY" },
            { isLabel: false, label: "FOLDER", id: "FOLDER" },
            { isLabel: false, label: "TITLE", id: "TITLE" },
        ];

        let category = ml.Item.parseRef(app.getCurrentItemId()).type;
        $.each(globalMatrix.ItemConfig.getFields(category), function (idx, field) {
            if (
                field.fieldType == FieldDescriptions.Field_checkbox ||
                field.fieldType == FieldDescriptions.Field_dropdown ||
                that.jsonFields.indexOf(field.fieldType) != -1 ||
                field.fieldType == FieldDescriptions.Field_steplist ||
                field.fieldType == FieldDescriptions.Field_test_steps ||
                field.fieldType == FieldDescriptions.Field_test_steps_result
            ) {
                // simple direct copy fields
                ddOptions.push({ isLabel: false, label: field.label, id: field.label });
            } else if (field.fieldType == FieldDescriptions.Field_risk2) {
                // risk field
                let rc =
                    field.parameterJson && (<IRiskParameter>field.parameterJson).riskConfig
                        ? (<IRiskParameter>field.parameterJson).riskConfig
                        : globalMatrix.ItemConfig.getRiskConfig();
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                $.each(rc.factors, function (factorIdx, factor) {
                    let opt = field.label + "." + factor.type;
                    ddOptions.push({ isLabel: false, label: opt, id: opt });
                    $.each(factor.weights, function (weightIdx, weight) {
                        let opt = field.label + "." + weight.type;
                        ddOptions.push({ isLabel: false, label: opt, id: opt });
                    });
                });
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                if (rc.postReduction && rc.postReduction.weights) {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    $.each(rc.postReduction.weights, function (weightIdx, weight) {
                        let opt = field.label + ".#." + weight.type;
                        ddOptions.push({ isLabel: false, label: opt, id: opt });
                    });
                }
            }
        });

        $.each(ml.LabelTools.getLabelDefinitions([category]), function (labelIdx, label) {
            ddOptions.push({
                isLabel: true,
                label: "label: " + label.label + " (" + ml.LabelTools.getDisplayName(label.label) + ")",
                id: label.label,
            });
        });

        that.columns = [];
        $.each($("th", thead), function (thIdx, th) {
            if (thIdx > 0) {
                let dd = $("<select style='width:100%'>").appendTo(th).data("index", thIdx);
                $.each(ddOptions, function (ddOptionIdx, ddOption) {
                    $(
                        "<option data-index='" +
                            (thIdx - 1) +
                            "' data-islabel='" +
                            (ddOption.isLabel ? 1 : 0) +
                            "'>" +
                            ddOption.label +
                            "</option>",
                    )
                        .appendTo(dd)
                        .val(ddOption.id);
                });
            }
        });
        $("select", thead).change(function (event: JQueryEventObject) {
            that.columns = [];
            $.each($("select option:selected", thead), function (selIdx, selected) {
                if ($(selected).text()) {
                    that.columns.push({
                        isLabel: $(selected).data("islabel") == "1",
                        id: $(selected).val(),
                        label: $(selected).text(),
                        index: $(selected).data("index"),
                    });
                }
            });
            ml.UI.setEnabled(next, that.columns.length != 0);
        });

        ml.UI.setEnabled(next, false);
        next.unbind("click");
        next.click(function () {
            that.rows = that.rows.filter(function (row, rowIdx) {
                return $($("tr input", tbody)[rowIdx]).is(":checked");
            });
            that.import(ui, next);
        });
    }

    /* *************************************************
        MassImport
    ************************************************* */

    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private columns: IImportColumn[];
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private folderColumn: number;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private hierarchyColumn: number;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private titleColumn: number;

    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private rows: IImportRow[];
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private messages: JQuery;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private hierarchyMap: IStringMap;

    constructor() {}

    // import one workshet in excel -> each row one item
    private import(ui: JQuery, next: JQuery) {
        let that = this;

        ui.html("<h1>Step 5: Converting ....</h1>");
        this.messages = $("<ul>").appendTo(ui);
        ml.UI.setEnabled(next, false);

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        let category = ml.Item.parseRef(this.item.id).type;
        let fields = globalMatrix.ItemConfig.getFields(category);
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        if (fields.length == 0) {
            this.error(-1, "Category does not exist/has no fields");
            return;
        }
        // map columns to fields
        this.folderColumn = -1;
        this.titleColumn = -1;
        this.hierarchyColumn = -1;

        // ignore columns which do not need to be matched
        this.columns = this.columns.filter(function (column) {
            return column.label != "ignore";
        });

        // add matching fields id's and types
        $.each(this.columns, function (idx: number, column: IImportColumn) {
            if (column.id == "HIERARCHY") {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                that.hierarchyColumn = column.index;
            } else if (column.id == "FOLDER") {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                that.folderColumn = column.index;
            } else if (column.id == "TITLE") {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                that.titleColumn = column.index;
            } else if (column.isLabel) {
            } else {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                let field = fields.filter(function (field) {
                    return field.label.toLowerCase() == column.id.toLowerCase().split(".")[0];
                });
                if (field.length != 1) {
                    ml.Logger.log(
                        "Info",
                        "the field " +
                            column.id.toLowerCase().split(".")[0] +
                            " does not exist in category " +
                            category,
                    );
                    return;
                }
                column.fieldId = field[0].id;
                column.fieldType = field[0].fieldType;
            }
        });

        if (this.folderColumn != -1 && this.hierarchyColumn != -1) {
            this.error(-1, "Error: folder options can only be hierarchical or flat - not both.");
            return;
        }
        // get rid of special columns
        this.columns = this.columns.filter(function (column) {
            return column.id != "FOLDER" && column.id != "HIERARCHY" && column.id != "TITLE";
        });

        // go through all rows...
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        this.importData(category, this.item.id)
            .then(function () {
                ml.UI.showSuccess("All Done!");
                that.messages.append("<li><b>Done!</b></li>");
                ml.UI.setEnabled(next, true);
            })
            .catch(function () {
                that.messages.append("<li><b>Failed!</b></li>");
                ml.UI.showError("Conversion Failed", "see log");
                ml.UI.setEnabled(next, true);
            });
        next.html("Close");
        next.unbind("click");
        next.click(function () {
            $(".ui-dialog-titlebar-close", that.dlg.parent()).trigger("click");
        });
    }

    // import tests: each worksheet -> one item
    private importTC(ui: JQuery, next: JQuery, ignoreHeaderRows: number, columnMapping: IStringNumberMap) {
        let that = this;

        ui.html("<h1>Step 5: Converting ....</h1>");
        this.messages = $("<ul>").appendTo(ui);
        ml.UI.setEnabled(next, false);

        // find the test_step table
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        let category = ml.Item.parseRef(this.item.id).type;
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        let fields = globalMatrix.ItemConfig.getFields(category).filter(function (field) {
            return field.fieldType == FieldDescriptions.Field_test_steps;
        });
        if (fields.length != 1) {
            this.error(-1, "Error:category does not have a single test table");
            return;
        }
        let testStepFieldId = fields[0].id;

        // go through all rows...
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        this.importTCWorksheets(0, category, this.item.id, testStepFieldId, ignoreHeaderRows, columnMapping)
            .done(function () {
                ml.UI.showSuccess("All Done!");
                that.messages.append("<li><b>Done!</b></li>");
                ml.UI.setEnabled(next, true);
            })
            .fail(function () {
                that.messages.append("<li><b>Failed!</b></li>");
                ml.UI.showError("Conversion Failed", "see log");
                ml.UI.setEnabled(next, true);
            });
        ml.UI.setEnabled(next, false);
        next.html("Close");
        next.unbind("click");
        next.click(function () {
            $(".ui-dialog-titlebar-close", that.dlg.parent()).trigger("click");
        });
    }

    private importTCWorksheets(
        worksheet: number,
        category: string,
        root: string,
        testStepFieldId: number,
        ignoreHeaderRows: number,
        columnMapping: IStringNumberMap,
    ) {
        let that = this;
        let res = $.Deferred();

        let worksheets = $("Worksheet", that.xml);
        if (worksheets.length <= worksheet) {
            res.resolve();
            return res;
        }

        // convert test steps
        let test: IStringMap[] = [];
        $.each($("row", $(worksheets[worksheet])), function (ridx, row) {
            // get cells in row
            let cells: string[] = [];
            let cellIdx = 0;
            $.each($("cell", $(row)), function (cidx, cell) {
                cellIdx = that.getCellIndex(cell, cellIdx);
                // In formatted cells, data is occasionally hidden behind ss:data instead of data.
                const attrName = $("data", cell).length == 0 ? "ss\\:data" : "data";
                cells[cellIdx] = $($(attrName, cell)).text().trim();
                cellIdx++;
            });

            if (ridx >= ignoreHeaderRows) {
                let testRow: IStringMap = {};
                $.each(columnMapping, function (cMapName, cMapIdx) {
                    testRow[cMapName] = cells[cMapIdx] ? cells[cMapIdx] : "";
                });
                test.push(testRow);
            }
        });

        // create item data
        let item: IItemPut = {
            title: $(worksheets[worksheet]).attr("ss:Name"),
        };
        // add test steps
        (<any>item)[testStepFieldId] = JSON.stringify(test);

        // upload
        app.createItemOfTypeAsync(category, item, "import", root)
            .done(function (newItem) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                that.showCreatedItem(-1, newItem.item.id, newItem.item.title);
                that.importTCWorksheets(
                    worksheet + 1,
                    category,
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    that.item.id,
                    testStepFieldId,
                    ignoreHeaderRows,
                    columnMapping,
                )
                    .done(function () {
                        res.resolve();
                    })
                    .fail(function () {
                        res.reject();
                    });
            })
            .fail(function () {
                that.error(-1, "creating test from worksheet " + worksheet + ": '" + item.title + "'");
                res.reject();
            });

        return res;
    }

    private importData(category: string, root: string) {
        let that = this;
        return new Promise<void>(function (resolve, reject) {
            if (that.hierarchyColumn == -1) {
                that.importAllRows(category, root, root, 0)
                    .then(function () {
                        resolve();
                    })
                    .catch(function () {
                        reject();
                    });
            } else {
                let neededFolders: string[] = [];
                $.each(that.rows, function (rowIdx, row) {
                    let hier = row.cells[that.hierarchyColumn];
                    // hier can be A | B | C | D: a folder D in a folder C in a folder B...
                    // make a flat list of needed folders A, A | B, A | B | C, ... in case they done exist yet...
                    if (hier) {
                        let parts = hier.split("|");
                        let addPath = "";
                        $.each(parts, function (partIdx, part) {
                            addPath += part;
                            if (neededFolders.indexOf(addPath) == -1) {
                                neededFolders.push(addPath);
                            }
                            addPath += "|";
                        });
                    }
                });
                that.hierarchyMap = {};
                that.createFolderHierarchy(category, root, neededFolders, 0)
                    .done(function () {
                        that.importAllRows(category, root, root, 0)
                            .then(function () {
                                resolve();
                            })
                            .catch(function () {
                                reject();
                            });
                    })
                    .fail(function () {
                        reject();
                    });
            }
        });
    }

    private createFolderHierarchy(category: string, root: string, neededFolders: string[], rowIdx: number) {
        let that = this;
        let res = $.Deferred();

        if (rowIdx >= neededFolders.length) {
            res.resolve();
            return res;
        }
        // convert A | B | C to parent = A | B and last = C
        let full = neededFolders[rowIdx].split("|");
        let last = full.splice(full.length - 1, 1)[0];
        let parent = full.join("|");
        // parent should already exist (ensured by called) if not it's in the root
        let parentId = parent ? that.hierarchyMap[parent] : root;
        let item: IItemPut = {
            title: last.trim(),
            children: [],
        };
        app.createItemOfTypeAsync(category, item, "import", parentId)
            .done(function (newFolder) {
                // create the lookup
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                that.hierarchyMap[neededFolders[rowIdx]] = newFolder.item.id;

                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                that.showCreatedItem(rowIdx, newFolder.item.id, newFolder.item.title);

                // ml.UI.showSuccess( "created folder: " + last);
                that.createFolderHierarchy(category, root, neededFolders, rowIdx + 1)
                    .done(function () {
                        res.resolve();
                    })
                    .fail(function () {
                        res.reject();
                    });
            })
            .fail(function () {
                res.reject();
                that.error(rowIdx, "creating folder failed - aborting");
            });

        return res;
    }

    private async importAllRows(category: string, root: string, current: string, rowIdx: number) {
        let that = this;
        let res = $.Deferred();

        if (rowIdx >= this.rows.length) {
            res.resolve();
            return res;
        }

        let row = this.rows[rowIdx];

        // handle folder rows

        if (this.folderColumn != -1 && row.cells[this.folderColumn]) {
            ml.Logger.log("Info", "create folder " + row.cells[this.folderColumn] + " in " + root);
            let item: IItemPut = {
                title: row.cells[this.folderColumn],
                children: [],
            };

            app.createItemOfTypeAsync(category, item, "import", root)
                .done(function (newFolder) {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    that.showCreatedItem(rowIdx, newFolder.item.id, newFolder.item.title);
                    //ml.UI.showSuccess( rowIdx + "/" + (that.rows.length-1) + ": created new folder " + newFolder.item.id );
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    that.importAllRows(category, root, newFolder.item.id, rowIdx + 1)
                        .then(function () {
                            res.resolve();
                        })
                        .catch(function () {
                            res.reject();
                        });
                })
                .fail(function () {
                    res.reject();
                    that.error(rowIdx, "creating folder - aborting");
                });

            return res;
        }

        // handle empty rows
        let content = 0;
        $.each(this.columns, function (colIdx, column) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            if (row.cells[column.index]) content++;
        });
        if (that.titleColumn != -1 && row.cells[that.titleColumn]) content++;

        if (!content) {
            that.warning(rowIdx, "skipping row " + (rowIdx + 1));
            that.importAllRows(category, root, current, rowIdx + 1)
                .then(function () {
                    res.resolve();
                })
                .catch(function () {
                    res.reject();
                });
            return res;
        }
        // handle items

        let item: IItemPut = {
            title:
                this.titleColumn != -1 && row.cells[this.titleColumn]
                    ? this.rows[rowIdx].cells[this.titleColumn]
                    : "ROW " + (rowIdx + 1),
        };
        let labels: string[] = [];
        // create a fake dummy UI - only used for logic in risks' right now
        let riskField = 0;
        let riskControlName = "";
        let dummy = $("<div>");
        let newItem = new ItemControl({
            control: dummy,
            controlState: ControlState.DialogCreate,
            parent: current,
            type: category,
            isItem: true,
            changed: function () {},
        });
        await newItem.load();
        let postWeights: IRiskValueFactorWeight[];

        $.each(this.columns, function (colIdx, column) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            let cell = row.cells[column.index] ? row.cells[column.index] : "";

            if (column.fieldType == FieldDescriptions.Field_risk2) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                riskField = column.fieldId;
                riskControlName = column.label.split(".")[0];
                if (column.label.split(".")[1] == "#") {
                    // post weights
                    if (!postWeights) postWeights = [];
                    postWeights.push({
                        description: "",
                        label: "",
                        type: column.label.split(".")[2],
                        value: Number(cell),
                    });
                } else {
                    let input = column.label.split(".")[1];
                    that.setRiskInput(dummy, input, cell, false, rowIdx);
                    (<RiskControlImpl>newItem.getControlByName(riskControlName).getController()).riskChange();
                }
            } else if (column.fieldType == FieldDescriptions.Field_richtext) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                (<any>item)[column.fieldId] = cell.replace(/\n/g, "<br>");
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            } else if (that.jsonFields.indexOf(column.fieldType) != -1) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                (<any>item)[column.fieldId] = cell;
            } else if (column.fieldType == FieldDescriptions.Field_checkbox) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                (<any>item)[column.fieldId] = cell.toLowerCase() == "x" || cell.toLowerCase() == "true" || cell == "1";
            } else if (column.fieldType == FieldDescriptions.Field_dropdown) {
                that.makeDropdown(category, column, rowIdx, cell, item);
            } else if (
                column.fieldType == FieldDescriptions.Field_test_steps ||
                column.fieldType == FieldDescriptions.Field_test_steps_result ||
                column.fieldType == FieldDescriptions.Field_steplist
            ) {
                that.makeTable(category, column, rowIdx, cell, item);
            } else if (column.isLabel) {
                if (cell.toLowerCase() == "x" || cell.toLowerCase() == "true" || cell == "1") {
                    labels.push(column.id);
                }
            } else {
                that.warning(
                    rowIdx,
                    "unsupported field type: " + column.fieldType + " cannot be converted automatically",
                );
            }
        });

        if (labels.length) {
            item.labels = labels.join(",");
        }
        if (riskField) {
            let riskVal: IRiskValue = <IRiskValue>(
                JSON.parse(await newItem.getControlByName(riskControlName).getController().getValueAsync())
            );
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            if (postWeights) riskVal.postWeights = postWeights;
            (<any>item)[riskField] = JSON.stringify(riskVal);
        }

        let folder = current;
        if (
            that.hierarchyColumn != -1 &&
            row.cells[that.hierarchyColumn] &&
            that.hierarchyMap[row.cells[that.hierarchyColumn]]
        ) {
            folder = that.hierarchyMap[row.cells[that.hierarchyColumn]];
        }
        app.createItemOfTypeAsync(category, item, "import", folder ? folder : current)
            .done(function (newItem) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                that.showCreatedItem(rowIdx, newItem.item.id, newItem.item.title);
                //ml.UI.showSuccess( rowIdx + "/" + (that.rows.length-1) + ": created item " + item.title );
                that.importAllRows(category, root, current, rowIdx + 1)
                    .then(function () {
                        res.resolve();
                    })
                    .catch(function () {
                        res.reject();
                    });
            })
            .fail(function () {
                that.error(rowIdx, "creating item");
                res.reject();
            });
        return res;
    }

    private makeTable(category: string, column: IImportColumn, rowIdx: number, cell: string, item: IItemPut) {
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        let fieldConfig = globalMatrix.ItemConfig.getFields(category).filter(function (field) {
            return field.id == column.fieldId;
        })[0];

        let columnNames: string[] = [];
        if (
            fieldConfig.fieldType == FieldDescriptions.Field_test_steps ||
            fieldConfig.fieldType == FieldDescriptions.Field_test_steps_result
        ) {
            $.each(globalMatrix.ItemConfig.getTestConfig().render[category].columns, function (cidx, columnDef) {
                columnNames[cidx + 1] = columnDef.field;
            });
        } else if (fieldConfig.fieldType == FieldDescriptions.Field_steplist) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            $.each(fieldConfig.parameterJson.columns, function (cidx, columnDef) {
                columnNames[cidx + 1] = columnDef.field;
            });
        }

        let data: IStringMap[] = [];

        $.each(cell.split("|#"), function (ridx, row) {
            let jsonRow: IGenericMap = {};

            $.each(row.split("|*"), function (cidx, column) {
                if (columnNames[cidx]) {
                    let text = column.trim();
                    jsonRow[columnNames[cidx]] = text;
                }
            });

            data.push(jsonRow);
        });

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        (<any>item)[column.fieldId] = JSON.stringify(data);
    }
    private makeDropdown(category: string, column: IImportColumn, rowIdx: number, cell: string, item: IItemPut) {
        let that = this;

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        let jsonParams = <IDropdownParams>globalMatrix.ItemConfig.getFields(category).filter(function (field) {
            return field.id == column.fieldId;
        })[0].parameterJson;
        let dd_options = jsonParams.optionSetting;
        if (!dd_options) {
            this.warning(
                rowIdx,
                "unsupported dropdown configuration: only dropdowns with values in settings are supported!",
            );
        } else {
            let dd = globalMatrix.ItemConfig.getDropDowns(dd_options);
            if (dd.length != 1) {
                this.warning(
                    rowIdx,
                    "drop down config is missing: only dropdowns with values in settings are supported!",
                );
            } else {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                let options = jsonParams.maxItems > 1 ? cell.split("|") : [cell];
                let mapped: string[] = [];
                $.each(options, function (optIdx, option) {
                    let optionId = "";
                    $.each(dd[0].value.options, function (optIdx, opt) {
                        if (opt.label.toLowerCase() == option.trim().toLowerCase()) {
                            optionId = opt.id;
                        }
                    });
                    if (!optionId) {
                        if (jsonParams.create) {
                            optionId = option.trim();
                        } else {
                            that.warning(rowIdx, "drop down option does not exist: '" + option.trim() + "'");
                        }
                    }
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    if (mapped.length < jsonParams.maxItems || mapped.length == 0) {
                        mapped.push(optionId);
                    } else {
                        that.warning(
                            rowIdx,
                            "ignored option: '" + optionId + "' - to many selected options for this config!",
                        );
                    }
                });

                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                (<any>item)[column.fieldId] = mapped.length ? mapped.join() : "";
            }
        }
    }

    setRiskInput(item: JQuery, name: string, value: string, post: boolean, row: number) {
        if ($("input[name=" + name + "]", item).length > 0) {
            $("input[name=" + name + "]", item).val(value);
        } else if ($("div[name=" + name + "]", item).length > 0) {
            $("div[name=" + name + "]", item).data("realValue", value);
        } else if ($("textarea[name=" + name + "]", item).length > 0) {
            $("textarea[name=" + name + "]", item).val(value);
        } else if ($("select[name=" + name + "]", item).length > 0) {
            // dropdown
            let select = $(post ? $("select[name=" + name + "]", item)[1] : $("select[name=" + name + "]", item)[0]);
            let optionGoood = false;
            $.each($("option", select), function (idx, option) {
                if ($(option).data("value") == value) {
                    $(option).prop("selected", true);
                    optionGoood = true;
                } else {
                    $(option).prop("selected", false);
                }
            });
            // second change, go by the attr value
            if (!optionGoood && value) {
                $.each($("option", select), function (idx, option) {
                    if ($(option).attr("value") == value) {
                        $(option).prop("selected", true);
                        optionGoood = true;
                    } else {
                        $(option).prop("selected", false);
                    }
                });
            }
            // third change, go by the text
            if (!optionGoood && value) {
                $.each($("option", select), function (idx, option) {
                    if ($(option).text() == value) {
                        $(option).prop("selected", true);
                        optionGoood = true;
                    } else {
                        $(option).prop("selected", false);
                    }
                });
            }
            if (!optionGoood) {
                this.warning(row, "risk input " + name + " is a select, but the option '" + value + "' does not exist");
            }
        } else {
            this.warning(row, "risk input " + name + " does not exist as input, text area or select");
        }
    }

    private error(row: number, msg: string) {
        this.messages.append("<li style='color:red'>Error: (row " + (row + 1) + ") " + msg + "</li>");
        return msg;
    }

    private warning(row: number, msg: string) {
        if (this.duplicates.indexOf(msg) == -1) {
            this.duplicates.push(msg);

            this.messages.append("<li style='color:red'>Warning: (row " + (row + 1) + ") " + msg + "</li>");
            ml.UI.showError("Warning", "row " + (row + 1) + ": " + msg);
        }
        return msg;
    }

    private showCreatedItem(row: number, id: string, title: string) {
        if (row == -1) {
            this.messages.append("<li>Created " + id + " " + title + "</li>");
        } else {
            this.messages.append("<li>Created (row " + (row + 1) + ") " + id + " " + title + "</li>");
        }
    }
}

function initialize() {
    // register the engine as plugin
    plugins.register(new MassImportImpl());
}
