// -----------------------------------------------------------
// Tools to show reference dialog (with up and downtraces)
// -----------------------------------------------------------

/// <reference types="matrixrequirements-type-declarations" />
import { app, globalMatrix, IItem, IReference, matrixSession } from "../../../globals";
import { ml } from "../../matrixlib";
import { SelectMode } from "../Components/ProjectViewDefines";
import { refLinkStyle, refLinkTooltip } from "../Parts/RefLinkDefines";
import { ItemCreationTools } from "./ItemCreationView";
import { ItemSelectionTools } from "./ItemSelectionView";

import { UIToolsConstants } from "../../matrixlib/MatrixLibInterfaces";

export type { IReferenceToolsOptions };
export { ReferenceTools };

interface IReferenceToolsOptions {
    item: IItem;
    canEdit: boolean;
    callback?: (item: IItem) => void;
}

class ReferenceTools {
    showReferenceDialog(options: IReferenceToolsOptions) {
        if (options.callback == undefined) {
            //Make sure we have a callback
            options.callback = (item: IItem) => {};
        }
        let treeContainer = $('<div class="Container nodeContainer"></div>');

        let riskCategories = globalMatrix.ItemConfig.getFieldsOfType("risk2").map(function (rc) {
            return rc.category;
        });

        let allDownTypes: DrawTreeType[] = [];
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        $.each(globalMatrix.ItemConfig.getLinkTypes(options.item.type, true, true), function (idx, type) {
            allDownTypes.push({ type: type });
        });
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        $.each(globalMatrix.ItemConfig.getLinkTypes(options.item.type, true, false), function (idx, type) {
            allDownTypes.push({ type: type });
        });

        let allUpTypes: DrawTreeType[] = [];
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        $.each(globalMatrix.ItemConfig.getLinkTypes(options.item.type, false, true), function (idx, type) {
            if (riskCategories.indexOf(type) == -1) {
                allUpTypes.push({ type: type });
            }
        });
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        $.each(globalMatrix.ItemConfig.getLinkTypes(options.item.type, false, false), function (idx, type) {
            if (riskCategories.indexOf(type) == -1) {
                allUpTypes.push({ type: type });
            }
        });

        let rootDown: DrawTreeNode;
        let rootUp: DrawTreeNode;
        function hideXTCs(nodes: DrawTreeNode[]) {
            let hideFrom = 1000;
            let hideTo = -1000;
            for (let idx = 0; idx < nodes.length; idx++) {
                // assume that all XTCs come one after the other...
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                if (ml.Item.parseRef(nodes[idx].id).type == "XTC") {
                    if (idx < hideFrom) hideFrom = idx;
                    if (idx > hideTo) hideTo = idx;
                }
            }
            if (hideTo - hideFrom > 2) {
                // at least 4 XTCs
                let dotdotdot: DrawTreeNode = {
                    isRoot: false,
                    isOutDate: false,
                    Class: "nodesExpand",
                    Nodes: [],
                    ToolTip: "click to expand",
                    Content: $("<span>...</span>"),
                };
                dotdotdot.Hidden = nodes.splice(hideFrom + 1, hideTo - hideFrom - 1);
                nodes.splice(hideFrom + 1, 0, dotdotdot);
            }
        }
        function createStartNode(item: IItem): void {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            let itemDate = new Date(item.modDate);
            let missingDownLinks = app.getMissingDownLinks(item);
            let evalDownlinks = app.evaluateTraceRule(item, true);

            rootDown = {
                isRoot: true,
                isDown: true,
                id: item.id,
                Content: $("<div></div>").refLink({
                    id: item.id,
                    title: "",
                    style: refLinkStyle.show,
                    tooltip: refLinkTooltip.html,
                    hideTitle: true,
                }),
                Nodes: [],
            };

            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            for (let idx = 0; idx < item.downLinks.length; idx++) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                const refDate = new Date(item.downLinks[idx].modDate);

                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                rootDown.Nodes.push({
                    isCreate: false,
                    isOutDate: refDate < itemDate,
                    isDown: true,
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    isIndirect: item.downLinks[idx].isIndirect,
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    id: item.downLinks[idx].to,
                    Content: $("<div></div>").refLink({
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        id: item.downLinks[idx].to,
                        title: "",
                        style: refLinkStyle.show,
                        tooltip: refLinkTooltip.html,
                        hideTitle: true,
                    }),
                    Nodes: [],
                });
            }
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            hideXTCs(rootDown.Nodes);
            if (matrixSession.isEditor() && options.canEdit) {
                for (let idx = 0; idx < missingDownLinks.length; idx++) {
                    let required =
                        evalDownlinks.missingMustHaveCategories.indexOf(missingDownLinks[idx]) != -1
                            ? "nodeCreate"
                            : "nodeCreateOptional";

                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    rootDown.Nodes.push({
                        isCreate: true,
                        type: missingDownLinks[idx],
                        isDown: true,
                        Class: required,
                        ToolTip: "Create new linked item",
                        Content: $("<div>").html(missingDownLinks[idx]),
                        Nodes: [],
                    });
                }
            }
            let missingUpLinks = app.getMissingUpLinks(item);
            let upRequired = app.evaluateTraceRule(item, false);
            rootUp = {
                isRoot: true,
                isDown: false,
                id: item.id,
                Content: $("<div></div>").refLink({
                    id: item.id,
                    title: "",
                    style: refLinkStyle.show,
                    tooltip: refLinkTooltip.html,
                    hideTitle: true,
                }),
                Nodes: [],
            };
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            for (let idx = 0; idx < item.upLinks.length; idx++) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                const refDate = new Date(item.upLinks[idx].modDate);
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                rootUp.Nodes.push({
                    isCreate: false,
                    isOutDate: refDate > itemDate,
                    isDown: false,
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    isIndirect: item.upLinks[idx].isIndirect,
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    id: item.upLinks[idx].to,
                    Content: $("<div></div>").refLink({
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        id: item.upLinks[idx].to,
                        title: "",
                        style: refLinkStyle.show,
                        tooltip: refLinkTooltip.html,
                        hideTitle: true,
                    }),
                    Nodes: [],
                });
            }
            if (matrixSession.isEditor() && options.canEdit) {
                for (let idx = 0; idx < missingUpLinks.length; idx++) {
                    let required =
                        upRequired.missingMustHaveCategories.indexOf(missingUpLinks[idx]) != -1
                            ? "nodeCreate"
                            : "nodeCreateOptional";

                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    rootUp.Nodes.push({
                        isCreate: true,
                        type: missingUpLinks[idx],
                        isDown: false,
                        Class: required,
                        ToolTip: "Create new linked item",
                        Content: $("<div>").html(missingUpLinks[idx]),
                        Nodes: [],
                    });
                }
            }
            if (allUpTypes.length > 0 && matrixSession.isEditor() && options.canEdit) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                rootUp.Nodes.push({
                    isSelect: true,
                    types: allUpTypes,
                    isDown: false,
                    Class: "nodeCreate",
                    ToolTip: "Select linked item",
                    Content: $("<div>").html("Select"),
                    Nodes: [],
                });
            }
            if (allDownTypes.length > 0 && matrixSession.isEditor() && options.canEdit) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                rootDown.Nodes.push({
                    isSelect: true,
                    types: allDownTypes,
                    isDown: true,
                    Class: "nodeSelect",
                    ToolTip: "Select linked item",
                    Content: $("<div>").html("Select"),
                    Nodes: [],
                });
            }
        }

        function RefreshTree() {
            let oldContainer = treeContainer;
            oldContainer.hide();
            treeContainer = $('<div class="Container nodeContainer"></div>');
            app.dlgForm.append(treeContainer);
            DrawTree({
                Container: treeContainer,
                RootDown: rootDown,
                RootUp: rootUp,
                Layout: "Horizontal",
                OnNodeClick: NodeClick,
                OnNodeDoubleClick: NodeDoubleClick,
            });
            oldContainer.html("");
            let ph = treeContainer.parent().height();
            let pw = treeContainer.parent().width();
            let nh = treeContainer.height();
            let nw = treeContainer.data("maxWidth");
            if (ph > nh) {
                treeContainer.css({ top: (ph - nh) / 2 });
            }
            if (pw > nw) {
                treeContainer.css({ left: (pw - nw) / 2 });
            }
            treeContainer.width(nw);
        }

        function NodeClick(theNode: DrawTreeNode, event: MouseEvent) {
            if (theNode.Class == "nodesExpand") {
                theNode.Nodes = ml.JSON.clone(theNode.Hidden);
                theNode.Class = "nodesExpanded";
                RefreshTree();
            } else if (theNode.Class == "nodesExpanded") {
                // toggle XTCs
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                theNode.Nodes = null;
                theNode.Class = "nodesExpand";
                RefreshTree();
            } else if (theNode.isSelect) {
                app.dlgForm.dialog("close");
                let select = new ItemSelectionTools();
                select.showDialog({
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    linkTypes: theNode.types,
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    getSelectedItems: async function () {
                        return theNode.isDown ? options.item.downLinks : options.item.upLinks;
                    },
                    selectionChange: function (newRefs: IReference[]) {
                        let oldRefs = theNode.isDown ? options.item.downLinks : options.item.upLinks;
                        // figure out if the old refs have some links to some items which cannot be selected in tree (e.g. uplinks as risk controls -> these should not be removed)
                        let selectableTypes = (theNode.isDown ? allDownTypes : allUpTypes).map(function (st) {
                            return st.type;
                        });
                        $.each(oldRefs, function (oridx, oref) {
                            if (selectableTypes.indexOf(ml.Item.parseRef(oref.to).type) == -1) {
                                newRefs.push(oref);
                            }
                        });
                        let changes = ml.Item.updateReferences(
                            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                            oldRefs,
                            newRefs,
                            theNode.isDown ? options.item.id : null,
                            theNode.isDown ? null : options.item.id,
                        );
                        app.commitChangeListAsync(changes).always(function (error, stepsDone) {
                            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                            options.callback(options.item);
                        });
                    },
                    selectMode: SelectMode.items,
                });
            } else if (theNode.isCreate) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                if (app.canCreateItemType(theNode.type, false)) {
                    app.dlgForm.dialog("close");
                    let create = new ItemCreationTools();
                    create.showDialog({
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        name: globalMatrix.ItemConfig.getItemConfiguration(theNode.type).label,
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        parent: app.getRootOfType(theNode.type),
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        type: theNode.type,
                        folder: false,
                        dontOpenNewItem: true,
                        created: async function (created) {
                            let fromId: string;
                            let toId: string;
                            if (theNode.isDown) {
                                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                fromId = options.item.id;
                                toId = created.to;
                            } else {
                                fromId = created.to;
                                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                toId = options.item.id;
                            }
                            app.addDownLinkAsync(fromId, toId)
                                .done(function () {})
                                .fail(function () {
                                    ml.UI.showError("Could not link items.", "");
                                })
                                .always(function () {
                                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                    options.callback(options.item);
                                });
                        },
                    });
                }
            } else if (event.ctrlKey) {
                let win = window.open(app.createItemUrl(theNode.id), "_blank");
                if (event.preventDefault) event.preventDefault();
                if (event.stopPropagation) event.stopPropagation();
                return;
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            } else if (theNode.Nodes.length == 0) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                app.getItemAsync(theNode.id).done(function (item) {
                    if (theNode.isDown) {
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        for (let idx = 0; idx < item.downLinks.length; idx++) {
                            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                            theNode.Nodes[idx] = {
                                isCreate: false,
                                isDown: true,
                                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                isIndirect: item.downLinks[idx].isIndirect,
                                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                id: item.downLinks[idx].to,
                                Content: $("<div></div>").refLink({
                                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                    id: item.downLinks[idx].to,
                                    title: "",
                                    style: refLinkStyle.show,
                                    tooltip: refLinkTooltip.html,
                                    hideTitle: true,
                                }),
                                Nodes: [],
                            };
                        }
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        hideXTCs(theNode.Nodes);
                    } else {
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        for (let idx = 0; idx < item.upLinks.length; idx++) {
                            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                            theNode.Nodes[idx] = {
                                isCreate: false,
                                isDown: false,
                                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                isIndirect: item.upLinks[idx].isIndirect,
                                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                id: item.upLinks[idx].to,
                                Content: $("<div></div>").refLink({
                                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                    id: item.upLinks[idx].to,
                                    title: "",
                                    style: refLinkStyle.show,
                                    tooltip: refLinkTooltip.html,
                                    hideTitle: true,
                                }),
                                Nodes: [],
                            };
                        }
                    }
                    // Draw the tree for the first time
                    RefreshTree();
                });
            }
        }

        function NodeDoubleClick(theNode: DrawTreeNode, event: MouseEvent) {
            if (theNode.id == undefined) {
                console.warn(
                    "No id defined for node. Cannot open item. It's problably double click on select or create.",
                );
                return;
            }

            if (event.ctrlKey || event.metaKey) {
                let win = window.open(app.createItemUrl(theNode.id), "_blank");
                if (event.preventDefault) event.preventDefault();
                if (event.stopPropagation) event.stopPropagation();
                return;
            }
            app.dlgForm.dialog("close");
            app.treeSelectionChangeAsync(theNode.id)
                .done(function () {})
                .fail(function () {})
                .always(function () {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    options.callback(options.item);
                });
        }

        ml.UI.showDialog(
            app.dlgForm,
            "References of '" + options.item.id + "'",
            treeContainer,
            900,
            400,
            [
                {
                    text: "Close",
                    class: "btnCancelIt",
                    click: function () {
                        app.dlgForm.dialog("close");
                    },
                },
            ],
            UIToolsConstants.Scroll.Auto,
            false,
            true,
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            null,
            null,
            function () {
                app.dlgForm.resizeDlgContent([]);
                RefreshTree();
            },
        );

        /*
        app.dlgForm.dialog({
            autoOpen: true,
            title: "References of '" + options.item.id + "'",
            height: 400,
            width: 900,
            modal: true,
            resizeStop: function () {
                app.dlgForm.resizeDlgContent([]);
                RefreshTree();
            },
            open: function () {},
            buttons: []
        });
       */
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        app.getItemAsync(options.item.id).done(function (item: IItem) {
            createStartNode(item);
            RefreshTree();
        });
    }
}
