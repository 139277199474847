import { ml } from "./../core/common/matrixlib";
import {
    IBranchInfo,
    ICatFieldMapping,
    ICompanyUISettings,
    InitializeBusinessLogic,
    ITaskConfiguration,
    ItemConfiguration,
    RestConnector,
} from "../core/common/businesslogic/index";
import { Application } from "../core/common/UI/Application";
import { NavigationBar, KeyboardShortcuts, INavigationBar } from "../core/common/UI/Components/index";
import { MatrixReq } from "./../core/common/businesslogic/index";
import { MatrixSession } from "./../core/common/businesslogic/index";
import { RestDB } from "./../core/common/businesslogic/index";
import { JsonValidator } from "./../jsonvalidation/JsonValidator";
import { ServerStorageAdmin } from "./../core/admin/ServerStorageAdmin";
import { ProjectStorageAdmin } from "./../core/admin/ProjectStorageAdmin";
import { ServerStorage } from "./../core/client/ServerStorage";
import { ServerStorageMobile } from "./../core/mobile/ServerStorageMobile";
import {
    app,
    globalMatrix,
    InstallLegacyAdaptor,
    matrixSession,
    restConnection,
    setApp,
    setIC,
    setMatrixApplicationUI,
    setMatrixSession,
    setRestConnection,
} from "../core/globals";
import { plugins } from "../core/common/businesslogic/index";
import { InitializeUI } from "./../core/common/UI/initialize";
import { InitializePlugins } from "./../core/client/plugins/initialize";
import { IGate } from "./common/UI/Controls/gateControl";
import { ITemplateProjects } from "./common/UI/Controls/markAsTemplate";
import { IUpLinkDetails } from "./common/UI/Controls/uplinkinfo";
import { ITokenConfig } from "./common/UI/Parts/UserControl";
import { IPrintCustomFormatter } from "./printinterface/PrintFormatter";
import {
    IProjectGroups,
    INotificationConfig,
    ISmartTextConfig,
    IACL,
    ICategoryGroups,
    IContextPageConfig,
    IDHFConfig,
    IExtras,
    ILabelLockConfig,
    ILabelsConfig,
    IQMSConfig,
    IRiskConfig,
    ISearchConfig,
    ITestConfig,
    ITraceConfig,
    IDeletedProjects,
    IMailConfig,
    IFieldParameter,
} from "./ProjectSettings";

import { initialize as initializeAnalytics } from "./client/Dashboards/Analytics";
console.log("Starting point!");
initializeAnalytics();

export {
    ml,
    InitializeBusinessLogic,
    ItemConfiguration,
    RestConnector,
    Application,
    NavigationBar,
    KeyboardShortcuts,
    MatrixReq,
    MatrixSession,
    RestDB,
    JsonValidator,
    ServerStorageAdmin,
    ProjectStorageAdmin,
    ServerStorage,
    ServerStorageMobile,
    app,
    globalMatrix,
    InstallLegacyAdaptor,
    matrixSession,
    restConnection,
    setApp,
    setIC,
    setMatrixApplicationUI,
    setMatrixSession,
    setRestConnection,
    plugins,
    InitializeUI,
    InitializePlugins,
};
export type {
    IPrintCustomFormatter,
    IProjectGroups,
    INotificationConfig,
    ITokenConfig,
    ICompanyUISettings,
    ITemplateProjects,
    ISmartTextConfig,
    ICatFieldMapping,
    ITaskConfiguration,
    IACL,
    IBranchInfo,
    ICategoryGroups,
    IContextPageConfig,
    IDHFConfig,
    IExtras,
    ILabelLockConfig,
    ILabelsConfig,
    INavigationBar,
    IQMSConfig,
    IRiskConfig,
    ISearchConfig,
    ITestConfig,
    ITraceConfig,
    IDeletedProjects,
    IMailConfig,
    IGate,
    IUpLinkDetails,
    IFieldParameter,
};
