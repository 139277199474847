/// <reference types="matrixrequirements-type-declarations" />
import { app } from "../../globals";
import { ISearchTools, ICancelSearchEvent } from "./MatrixLibInterfaces";
import { EventDispatcher } from "../businesslogic/MatrixRequirementsAPI";

export { SearchTools };

class CancelSearchEvent implements ICancelSearchEvent {}

class SearchTools implements ISearchTools {
    // global filter is used to remember highligh after changes: a call to
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private globalFilter: string;

    // context is used to highlight only in select from tree dialog. by default all is
    private highlightContext: JQuery = $("#highlightContext");
    public OnCancelSearch: EventDispatcher<ICancelSearchEvent> = new EventDispatcher<ICancelSearchEvent>();

    getFilter() {
        return this.globalFilter;
    }
    cancelSearch() {
        // from all search filters, remove the search term and details
        $(".treeCtrl input[name=search]").val("");
        $(".treeCtrl :checkbox").prop("checked", false);
        // remove any kind of highlighting in main tree
        this.OnCancelSearch.dispatch(new CancelSearchEvent());
        this.hideHighlight();
    }
    searchInDialog() {
        this.cancelSearch();
        // now set search to dialog
        this.highlightContext = app.dlgForm;
    }
    endSearchInDialog() {
        this.highlightContext = $("#highlightContext");
        this.globalFilter = "";
    }
    highlight(term: string) {
        this.globalFilter = term;
        this.renderHighlight();
    }
    hideHighlight() {
        this.globalFilter = "";
        this.highlightContext.unhighlight();
        this.updateTinys();
    }

    renderHighlight() {
        this.highlightContext.unhighlight();

        if (this.globalFilter && this.globalFilter.length > 0) {
            this.highlightContext.highlight(this.globalFilter);
        }
        this.updateTinys();
    }

    private updateTinys() {
        let that = this;
        if (this.globalFilter) {
            // This is in case the tinymce editor is open
            $(".thisIsTiny").each(function (idx, tiny) {
                let editor = tinymce.get($(tiny).attr("id"));
                if (editor) {
                    $(editor.getBody()).unhighlight().highlight(that.getFilter());
                }
            });
            // This is in case the tinymce editor is closed
            for (let shadowRootContainer of $(".textEditContainer").find(".shadow-root").toArray()) {
                let shadowRoot = shadowRootContainer.shadowRoot;
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                $(shadowRoot).find(".shadowedContent").unhighlight().highlight(that.getFilter());
            }
        } else {
            $(".thisIsTiny").each(function (idx, tiny) {
                let editor = tinymce.get($(tiny).attr("id"));
                if (editor) {
                    $(editor.getBody()).unhighlight();
                }
            });
            for (let shadowRootContainer of $(".textEditContainer").find(".shadow-root").toArray()) {
                let shadowRoot = shadowRootContainer.shadowRoot;
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                $(shadowRoot).find(".shadowedContent").unhighlight();
            }
        }
    }
}
