import { app, ControlState, globalMatrix, IReference, matrixSession } from "../../../globals";
import { IPreCreateItemEvent, mDHF, MR1 } from "../../businesslogic/index";
import { ml } from "../../matrixlib";
import { ILinkType, ItemControl } from "../Components/ItemForm";
import { NavigationPanel } from "../MainTree/MainTree";

export type { ICreateDialogOptions, ICreateDialogEventOptions, ICreateDialogButtonOptions };
export { ItemCreationTools };

// -----------------------------------------------------------
// Dialog to create new folder or items of any kind
// -----------------------------------------------------------
interface ICreateDialogOptions {
    type: string;
    name: string;
    folder: boolean;
    created?: (newItems: IReference) => Promise<void>;
    singleCreate?: boolean; // set to true to hide create multiple
    dontOpenNewItem: boolean; // if set to true, the selection in the tree does not change
    parent: string;
    closed?: Function;
}

interface ICreateDialogEventOptions {
    data: ICreateDialogOptions;
}

interface ICreateDialogButtonOptions {
    control: JQuery;
    linkTypes: ILinkType[];
    singleCreate?: boolean; // set to true to hide create multiple
    created?: (newRef: IReference) => Promise<void>;
    isRiskControl?: boolean;
    type?: string;
    parent?: string;
    docTemplate?: boolean;
    open?: (view: ItemControl) => void; // called just after dialog was opened
    tinybuttons?: boolean;
    dontOpenNewItem: boolean; // if set to true, the selection in the tree does not change
}

class ItemCreationTools {
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private onOpenDlg: (view: ItemControl) => void;

    // pops up a dialog to create one or more items of the given type
    showDialog(options: ICreateDialogOptions) {
        this.showCreateDialog(options);
    }

    renderButtons(options: ICreateDialogButtonOptions) {
        let that = this;

        if (options.type && !globalMatrix.ItemConfig.canCreate(options.type)) {
            options.control.append(
                "<div class='inlineHelp'>You have no rights to create items or folders in this category</div>",
            );
            return;
        }
        // save on open callback if there is any
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        this.onOpenDlg = options.open;

        options.control.addClass("rowFlex").addClass("createButtons");

        for (let idx = 0; idx < options.linkTypes.length; idx++) {
            let disabled = app.canCreateItemType(options.linkTypes[idx].type, options.linkTypes[idx].folder)
                ? ""
                : "disabled";

            let seleniumClass = "";
            let name = options.linkTypes[idx].buttonName ? options.linkTypes[idx].buttonName : "Create";
            name += " <b>" + options.linkTypes[idx].name + "<b>";
            seleniumClass = "sel_create_" + (options.linkTypes[idx].folder ? "Folder" : options.linkTypes[idx].type);
            if (options.linkTypes[idx].buttonName == "Import") {
                seleniumClass = "sel_import_" + options.linkTypes[idx].name;
            }
            let riskFormat = options.isRiskControl ? "btn-sm " + (idx === 0 ? "rcb0" : "rcbn") : "";
            if (options.tinybuttons) {
                // overwrite style
                riskFormat = options.isRiskControl ? "btn-xs rcbn" : "btn-xs";
                name = "+" + options.linkTypes[idx].type;
            }
            let button = $(
                "<button class='" +
                    riskFormat +
                    " buttonCreateSelect btn btn-success " +
                    seleniumClass +
                    "' " +
                    disabled +
                    ">" +
                    name +
                    "</button>",
            );
            let parent = app.getRootOfType(options.linkTypes[idx].type);
            if (options.parent) {
                parent = options.parent;
            }

            let btnInfo: ICreateDialogOptions = {
                type: options.linkTypes[idx].type,
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                name: options.linkTypes[idx].name,
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                folder: options.linkTypes[idx].folder,
                created: options.created,
                parent: parent,
                singleCreate: options.singleCreate,
                dontOpenNewItem: options.dontOpenNewItem,
            };
            if (options.linkTypes[idx].import) {
                button.click(() => mDHF.showCreateFromDocx(btnInfo));
            } else {
                button.click(() => this.showCreateDialog(btnInfo));
            }
            options.control.append(button);
            if (!globalMatrix.ItemConfig.canCreate(options.linkTypes[idx].type)) {
                ml.UI.setEnabled(button, false);
            }
        }
    }

    private async showCreateDialog(options: ICreateDialogOptions) {
        let that = this;
        // options.type -> the type of item to create
        // options.name -> the name of item type to create
        // options.created callback called after item was created
        // options.closed callback called after dialog was closed
        // options.multiple NYI (allow to create several
        // options.parent -> needed for folder tools (undefinef for root folders)
        // options.folder -> true for folder tools

        // prepare dialog - nice and clean..
        app.dlgForm.html("");
        app.dlgForm.removeClass("dlg-no-scroll");
        app.dlgForm.addClass("dlg-v-scroll");

        // render form
        // The boolean {controlInitialized} is necessary because otherwise we can get
        // a JavaScript error in {reviewSaveState} below, if ctrl is not defined.
        let controlInitialized = false;
        let ctrl = new ItemControl({
            control: app.dlgForm,
            controlState: ControlState.DialogCreate,
            parent: options.parent,
            type: options.type,
            isItem: !options.folder,
            changed: function () {
                reviewSaveState();
            },
        });
        await ctrl.load();

        controlInitialized = true;

        async function reviewSaveState() {
            if (controlInitialized) {
                changeSaveState((await ctrl.needsSave()) && (await ctrl.hasTitle()));
            }
        }

        let createdMultiple = false;
        let createdMultipleValue = false;

        MR1.onItemCreateDlgOpen().subscribe(this, this.onDialogOpen);

        let ok = false;
        app.dlgForm
            .dialog({
                autoOpen: true,
                title: "Create new " + options.name,
                height: 300,
                width: 900,
                modal: true,
                closeOnEscape: false, // escape is annoying because it cannot be undone and it can happen when entering tables
                open: function () {
                    if (options.singleCreate) {
                        $(".ui-dialog-buttonpane button:eq(0)", app.dlgForm.parent()).hide();
                    } else {
                        $(".ui-dialog-buttonpane button:eq(0)", app.dlgForm.parent()).replaceWith(
                            '<label class="dlgCreateMultiple"><input type="checkbox" name="createMultiple">' +
                                "Create multiple</label>",
                        );

                        $("input[name=createMultiple]", app.dlgForm.parent()).on("click", (item, event) => {
                            createdMultipleValue = $(item.target).is(":checked");
                        });
                    }
                    // needs to be after line above (changes the first button;-)
                    changeSaveState(false);
                    matrixSession.startCommitTransaction();
                },
                close: function () {
                    MR1.onItemCreateDlgOpen().unsubscribe(that.onDialogOpen);
                    MR1.triggerItemCreateClose(ok);

                    ctrl.destroy();
                    app.dlgForm.parent().html("");
                    app.dlgForm.css("display", "none");
                    if (options.closed) {
                        options.closed();
                    }
                    matrixSession.stopCommitTransaction();
                    NavigationPanel.focusTree();
                },
                resizeStop: function () {
                    app.dlgForm.resizeDlgContent([ctrl]);
                },
                buttons: [
                    {
                        text: "Create multiple",
                        class: "btnCreateMultiple",
                        click: function () {},
                    },
                    {
                        text: "Create",
                        class: "btnDoIt",
                        click: function () {
                            ok = true;
                            window.setTimeout(function () {
                                // disable button during creation
                                // this needs to be done as soon as the create callback was handled
                                changeSaveState(false);
                            }, 1);
                            window.setTimeout(function () {
                                save();
                            }, 200);
                        },
                    },
                    {
                        text: "Cancel",
                        class: "btnCancelIt",
                        click: function () {
                            matrixSession.stopCommitTransaction();
                            app.dlgForm.dialog("close");
                        },
                    },
                ],
            })
            .resizeDlgContent([ctrl], true);

        // disable create button
        function changeSaveState(enabled: boolean) {
            let saveButton = $(".ui-dialog-buttonpane button:eq(0)", app.dlgForm.parent());
            ml.UI.setEnabled(saveButton, enabled);
        }

        // TODO: convert to const and make sure it's still works
        // eslint-disable-next-line no-var
        var save = async function () {
            if (await ctrl.needsSave()) {
                try {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    let result = await ctrl.saveAsync(options.type, null);
                    if (!createdMultiple) {
                        // make sure the item is visible, open the folder
                        await NavigationPanel.openFolder(options.parent);
                    }
                    if (options.created) {
                        await options.created({ to: result.item.id, title: result.item.title });
                    }
                    if (!createdMultipleValue) {
                        app.dlgForm.dialog("close");
                        if (
                            !createdMultiple &&
                            !options.dontOpenNewItem &&
                            (options.folder || (!matrixSession.getUISettings().legacyKeepFolder && !app.needsSave()))
                        ) {
                            // just one folder was created: select it in tree

                            app.treeSelectionChangeAsync(result.item.id);
                        }
                    } else {
                        createdMultiple = true;
                        changeSaveState(false);
                        ctrl.destroy();
                        ctrl.fillControls();
                    }
                } catch (error) {
                    console.error("Error during creation of item", error);
                    changeSaveState(true);
                }
            }
        };
        return this;
    }

    private onDialogOpen(event: IPreCreateItemEvent) {
        let that = <ItemCreationTools>event.caller;
        if (that.onOpenDlg) {
            that.onOpenDlg(event.view);
        }
    }

    private showCreateDialogEvent(event: ICreateDialogEventOptions) {
        this.showCreateDialog({
            type: event.data.type,
            name: event.data.name,
            folder: event.data.folder,
            created: event.data.created,
            parent: event.data.parent,
            singleCreate: event.data.singleCreate,
            dontOpenNewItem: false,
        });
    }
}
