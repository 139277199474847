/*
Notes about the implementation.

1) Pay close attention to the 'this' variable:
   Within the editors defined in this file, event handlers often have a 'this' context set
   by the caller that is required for certain functions. It's important to use another variable
   (like 'that') to reference member variables. For example:

                click: function () {
                    var rt = rte.getController().getValueAsync().replace(/(\r\n|\n|\r)/gm, "");
                    that.$input.val(rt);
                    that.richTextEditActive = false;
                    $(this).dialog("close");          <--- note 'this', not 'that'
                    that.args.grid.navigateNext();
                }

   $(this) is the way to access dialog functionality. It is not "your" 'this' context, it is
   the one that belongs to this function.

2) Anonymous class definitions are helpful:
   All the editors are implemented as anonymous classes. The class is passed to the grid editor
   where it appears as a constructor. The grid editor calls the function with 'new', passing
   arguments. In our case, we first need to bind the 'this' pointer of the parent class
   (OldTableControlImpl), so the anonymous class has all the member variables it needs. So each
   of the editors has a factory function that does that and returns the anonymous class.
   It can be helpful to look at the JavaScript compiled by TypeScript to make this more concrete.
   The key is that the class needs to install it's member methods on the object provided by
   the 'new' operator in the grid code. And anonymous classes are the only way I found to do that.

3) use of Javascript 'bind':
   If you have a class method that you want to expose as a callback, you need to manually bind
   the 'this' pointer to it, like so:

                this.$input.bind("keydown", this.handleKeyDown.bind(this));
                this.$input.bind("paste", this.handlePaste.bind(this));

   Now, when the keydown event occurs, this.handleKeyDown is called with the right 'this' pointer.
   The one you intended when the code was written. Otherwise it will be undefined or something
   weird.
*/
/// <reference types="matrixrequirements-type-declarations" />
import { app, ControlState, globalMatrix, matrixSession } from "../../../globals";
import {
    autoColumnSetting,
    autoColumnDefault,
    IAutoColumn,
    ITraceConfig,
    ITestRuleStep,
    IExtras,
} from "../../../ProjectSettings";
import {
    tableMath,
    mDHF,
    ColumnEditor,
    SteplistFieldHandler,
    BaseTableFieldHandler,
    ITableControlBaseParams,
} from "../../businesslogic";
import { ml } from "../../matrixlib";
import { HTMLCleaner } from "../../matrixlib/index";
import { BaseControl, IBaseControlOptions, ITableParams } from "./BaseControl";

interface IOldTableControlOptions extends IBaseControlOptions {
    fieldHandler: BaseTableFieldHandler;
}

type OldTableData = any;

$.fn.tableCtrl = function (this: JQuery, options: IOldTableControlOptions) {
    if (!options.fieldHandler) {
        // It's not a table from a field, so let's create an BaseTableFieldHandler that is not validating anything
        options.fieldHandler = new BaseTableFieldHandler(<ITableControlBaseParams>options);
    }

    let baseControl = new OldTableControlImpl(this, options.fieldHandler);
    this.getController = () => {
        return baseControl;
    };
    options.fieldHandler.initData(options.fieldValue);
    baseControl.init(options);
    baseControl.initControl();
    baseControl.saveData();
    this.insertLine = (line: OldTableData) => {
        baseControl.insertLine(line);
    };
    return baseControl;
};

interface ISelectColOption {
    id: string;
    label: string;
    class: string;
    sId: number;
    disabled: boolean;
}
interface ISelectColGroup {
    value: string;
    label: string;
}
interface ISelectColOptions {
    options: ISelectColOption[];
    groups: ISelectColGroup[];
}

class OldTableControlImpl extends BaseControl<BaseTableFieldHandler> {
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private settings: IBaseControlOptions;

    private editorActive = false;

    // member variables
    private data: OldTableData; // contains always most current data of grid
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private ctrlContainer: JQuery; // container for table and hidden formatting sections
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private _list: JQuery; // the table control
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private grid: Slick.Grid<OldTableData>; // the grid created for the table control
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private columns: Slick.Column<OldTableData>[]; // columns of the grid
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private rowToolsColumn: number; // the column which contains tools (actually the last or none)
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private focsuable: Record<number, { focusable: boolean }>;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private lastSelectedRows: number[]; // contains the last selected row (used in rowToolsFormatter, not to show tools in last row)
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private vp: JQuery; // to find / restore scroll position
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private vpp: number; // last scroll position storage used by functions manipulating grid
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private passFailOptions: ITestRuleStep[];
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private lastSize: number; // to handle resizing more efficiently
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private extras: IExtras;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private canImport: boolean;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private formattersRequiringSizers: Slick.Formatter<OldTableData>[];
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private ignoreResize: boolean;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private ignoreResizeReset: number;

    constructor(control: JQuery, fieldHandler: BaseTableFieldHandler) {
        super(control, fieldHandler);
    }

    init(options: IBaseControlOptions) {
        let defaultOptions: IBaseControlOptions = {
            controlState: ControlState.FormView, // read only rendering
            dummyData: false, // fill control with a dumy text (for form design...)
            canEdit: false, // whether data can be edited
            valueChanged: function () {}, // callback to call if value changes
            parameter: {
                readonly: false, // can be set to overwrite the default readonly status
                columns: [], // columns to be added [{name, field, editor, values}], list of column definitions:
                // name: the name of the column shown in the table header
                // field: the id in the column / of the data
                // editor: (optional) 'text','result' (if and how to edit the cell). , ...
                onDblClick: function () {}, // optional function to be called if cell is double clicked
                canBeModified: true, // if true, rows can be added and moved,
                create: true, // if canBeModified also can add lines (with little icon)
                showLineNumbers: true, // whether to show line numbers in front of each row (Step)
                maxRows: -1, // if set to a value other than -1, the maximum number of rows which can be created
                fixRows: 0, // if set to a value >0, the table has the exact number of rows, no rows can be added or removed (maxRows is ignored)
                readonly_allowfocus: false, // whether not editable cells can get focus (e.g. with tab)
                cellAskEdit: "", // indicate that cell can be edited by showing string
                autoUpdate: "", // by default don't run a script
            },
        };
        let settings = ml.JSON.mergeOptions(defaultOptions, options);

        if (
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            settings.parameter.maxRows &&
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            typeof settings.parameter.maxRows != "number" &&
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            !isNaN(settings.parameter.maxRows)
        ) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            settings.parameter.maxRows = Number(settings.parameter.maxRows);
        }
        if (
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            settings.parameter.fixRows &&
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            typeof settings.parameter.fixRows != "number" &&
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            !isNaN(settings.parameter.fixRows)
        ) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            settings.parameter.fixRows = Number(settings.parameter.fixRows);
        }
        this.settings = settings;

        this.data = []; // contains always most current data of grid
        this.ctrlContainer = $('<div style="font-size:8pt"></div>').addClass("baseControl"); // container for table and hidden formatting sections
        this._list = $('<div class="slickTable">'); // the table control
        // this.grid; // the grid created for the table control
        this.columns = []; // columns of the grid
        // this.rowToolsColumn; // the column which contains tools (actually the last or none)
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        this.focsuable = { 0: { focusable: settings.parameter.readonly_allowfocus } };
        // this.lastSelectedRows; // contains the last selected row (used in rowToolsFormatter, not to show tools in last row)
        // this.vp; // to find / restore scroll position
        // this.vpp; // last scroll position storage used by functions manipulating grid
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        this.passFailOptions = settings.parameter.passFailEditorConfig;
        // this.lastSize; // to handle resizing more efficiently
        this.extras = globalMatrix.ItemConfig.getExtrasConfig();
        this.canImport =
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            settings.parameter.create &&
            this.extras &&
            settings.canEdit &&
            (ml.JSON.isTrue(this.extras.tableCanImport) ||
                (this.extras.tableCanImport === "admin" && matrixSession.isAdmin()));

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        if (settings.parameter.limitToA4) {
            this._list.css("width", "680px");
            this._list.addClass("a4fullwidth");
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        } else if (settings.parameter.reviewMode) {
            this._list.width(settings.control.width() - 10);
        }
    }

    // public interface
    async hasChangedAsync() {
        return JSON.stringify(this._root.data("original")) !== JSON.stringify(this._root.data("new"));
    }

    async getValueAsync() {
        // @ts-ignore no idea why we're not passing anything there and whether it's supported or not
        if (this.grid.getEditorLock().isActive()) {
            if (this.grid.getEditorLock().commitCurrentEdit()) {
                // commit succeeded; proceed with submit
            }
        }
        this.setUIDs();

        (<SteplistFieldHandler>this.settings.fieldHandler).setDataAsArray(this._root.data("new"), true);
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        return this.settings.fieldHandler.getData();
    }

    getController() {
        return this;
    }

    highlightReferences() {
        // Only in cell, not in header
        $(this).find(".grid-canvas").highlightReferences();
    }

    setValue(val: string) {
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        this.settings.fieldHandler.setData(val);

        let oldNew = JSON.stringify(this.grid.getData());
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        this.data = JSON.parse(this.settings.fieldHandler.getData());
        this._root.data("new", this.data);

        this.grid.setData(this.data, false);
        this.grid.render();
        let newNew = JSON.stringify(this.grid.getData());

        if (oldNew != newNew) {
            // only trigger change if grid really changed MATRIX-3849
            this.gridChanged();
        }
    }

    setHiddenCell(rowIdx: number, columnName: string, value: unknown): void {
        let cdata = this.grid.getData();

        if (cdata.length > rowIdx) {
            cdata[rowIdx][columnName] = value;
        }
        // @ts-ignore not sure why we're using data property directly
        this.grid.data = cdata;
        this.gridChanged();
    }

    getHiddenCell(rowIdx: number, columnName: string) {
        let cdata = this.grid.getData();

        if (cdata.length > rowIdx) {
            return cdata[rowIdx][columnName];
        }

        return null;
    }

    linksToCreate() {
        let links: { down: string[]; up: string[] } = { down: [], up: [] };
        let ct = this._root.data("new");
        let that = this;
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        for (let column in this.settings.parameter.columns) {
            if (
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                this.settings.parameter.columns[column].editor === ColumnEditor.uprules &&
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                this.settings.parameter.columns[column].options &&
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                this.settings.parameter.columns[column].options.autolink
            ) {
                ct.forEach(function (row: Record<string, any>) {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    let cell = row[that.settings.parameter.columns[column].field];
                    if (cell) {
                        links.up = links.up.concat(cell.split(","));
                    }
                });
            }
            if (
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                this.settings.parameter.columns[column].editor === ColumnEditor.downrules &&
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                this.settings.parameter.columns[column].options &&
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                this.settings.parameter.columns[column].options.autolink
            ) {
                ct.forEach(function (row: Record<string, any>) {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    let cell = row[that.settings.parameter.columns[column].field];
                    if (cell) {
                        links.down = links.down.concat(cell.split(","));
                    }
                });
            }
        }
        return links;
    }

    destroy() {
        this.grid.destroy();
    }

    resizeItem(newWidth: number, force: boolean) {
        if (this.ignoreResize || newWidth === 0 || this.lastSize === newWidth) {
            return;
        }
        this.lastSize = newWidth;
        this.redraw(force);
    }

    refresh() {
        this.updateRowHeights();
    }

    redraw(force: boolean) {
        let that = this;
        if (force) this.grid.resizeCanvas();
        this.grid.invalidate();
        if (force) this.grid.render();

        if (!this.settings.isPrint) {
            // no need to change rows before the current edit
            this.updateRowHeights();
            ml.SmartText.showTooltips($(this.getController()), false);
        } else {
            // this is for the print view... It's ok to wait 3 seconds before to
            // paint it nicely
            window.setTimeout(function () {
                that.updateRowHeights();
            }, 3000);
        }
    }

    insertLine(newLine: OldTableData) {
        let gdata = this.grid.getData();
        gdata.push(newLine);
        this.grid.setData(gdata, false);
        this.grid.setSelectedRows([]);
        this.updateRowHeights();
        this.grid.render();
        this.gridChanged();
    }

    private setUIDs() {
        let uids = "";
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        for (let column of this.settings.parameter.columns) {
            if (column.editor === ColumnEditor.uid) {
                uids = column.field;
            }
        }
        if (!uids) return;
        // there' an uid column: find the max id for this tc
        let maxuid = 0;
        for (let row of this._root.data("new")) {
            if (row[uids]) {
                const idp = Number(row[uids].split("-")[0]);
                maxuid = Math.max(idp, maxuid);
            }
        }
        maxuid++;

        for (let row of this._root.data("new")) {
            if (!row[uids]) {
                row[uids] = this.createUid(maxuid);
                maxuid++;
            } else {
                // maybe need to update the uid if row changed
                let update = false;
                for (let old of this._root.data("original")) {
                    if (old[uids] == row[uids] && JSON.stringify(old) != JSON.stringify(row)) {
                        update = true;
                    }
                }
                if (update) {
                    // need to fix uid (line changed)
                    const idp = Number(row[uids].split("-")[0]);
                    row[uids] = this.createUid(idp);
                }
            }
        }
    }

    private createUid(base: number) {
        let tzeros = base < 10 ? "000" : base < 100 ? "00" : base < 1000 ? "0" : "";
        return (
            tzeros +
            base +
            "-" +
            (1 + (this.settings.item && this.settings.item.history ? this.settings.item.history.length : 0))
        );
    }

    // editors
    // This editors are created with a call to "new". They are classes.
    PassFailEditor() {
        // TODO: fix it
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        let parent = this;
        return class {
            public args: any;
            public result = "";
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            public icon: JQuery;
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            public editor: JQuery;

            constructor(args: any) {
                this.args = args;
                this.init();
            }
            init() {
                this.icon = $("<div>");
                this.editor = $("<div>");

                parent.editorActive = true;
                let that = this;

                $(this.args.container).append(
                    $("<table style='width:100%'>").append(
                        $("<tr>")
                            .append($("<td  style='width:30px'>").append(this.icon))
                            .append($("<td>").append(this.editor)),
                    ),
                );

                that.icon.html(parent.passFailFormatterIcon(this.args.item.result));
                let r = this.args.item.result ? this.args.item.result : "";
                let option_str = "";
                for (let idx = 0; idx < parent.passFailOptions.length; idx++) {
                    let resultCode = parent.passFailOptions[idx].code;
                    let resultKey = parent.passFailOptions[idx].key ? " (" + parent.passFailOptions[idx].key + ")" : "";
                    let resultText = parent.passFailOptions[idx].command + resultKey;
                    let selected = r === resultCode ? "selected" : "";
                    option_str += "<OPTION value='" + resultCode + "' " + selected + ">" + resultText + "</OPTION>";
                }

                let $select = $("<SELECT tabIndex='0' class='slick_table_dropdown'>" + option_str + "</SELECT>")
                    .change(function () {
                        // @ts-ignore TODO: investigate what "this" should refer to
                        that.result = $(this).val();
                        // @ts-ignore TODO: investigate what "this" should refer to
                        that.icon.html(parent.passFailFormatterIcon(this.result));
                    })
                    .keypress(function (event) {
                        for (let idx = 0; idx < parent.passFailOptions.length; idx++) {
                            if (String.fromCharCode(event.which) === parent.passFailOptions[idx].key) {
                                that.result = parent.passFailOptions[idx].code;
                                that.save();
                            }
                        }
                        return false;
                    })
                    .blur(function () {
                        that.args.commitChanges(true);
                        that.args.grid.resetActiveCell();
                    });
                that.editor.append($select);

                $select.focus();
            }
            save() {
                this.args.commitChanges();
            }
            destroy() {
                parent.editorActive = false;
                $(this.args.container).empty();
            }

            focus() {}

            serializeValue() {
                return this.result;
            }

            applyValue(item: Record<string, string>, state: string) {
                item.result = state;
            }

            loadValue(item: Record<string, string>) {
                this.result = item.result;
            }

            isValueChanged() {
                return this.args.item.result != this.result;
            }

            validate(): { valid: boolean; msg: null } {
                return { valid: true, msg: null };
            }
        };
    }

    InplaceLongText() {
        // TODO: fix it
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        let parent = this;
        return class {
            public args: any;
            public $input: any;
            public $wrapper: any;
            public $help: any;
            public $scroller: any;
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            public richTextEditActive: boolean;
            public richTextEditWasActive = false;
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            public defaultValue: string;
            public wasPositioned = false;

            constructor(args: any) {
                this.args = args;
                this.init();
            }

            init() {
                let that = this;
                parent.editorActive = true;
                let dlg = $(this.args.container).closest("#appPopup");

                let $container = dlg && dlg.length > 0 ? dlg : $("body");
                this.$scroller = $(this.args.container).closest(".panel-body-v-scroll");
                this.$wrapper = $("<div class='multiLineEditorContainer baseControl hidden-print'/>").appendTo(
                    $container,
                );
                this.$help = $("<div class='multiLineEditorHelp'/>")
                    .html(
                        "<b>tab:</b>save&amp;next, <b>shift-tab</b>:save&amp;back, <b>ctrl-enter</b>: save&amp;down, <b>esc</b>: cancel&amp;close, <b>shift return</b>: open full editor",
                    )
                    .appendTo(this.$wrapper);
                this.$input = $("<textarea hidefocus rows=5 class='multiLineEditor'>")
                    .appendTo(this.$wrapper)
                    .width(Math.max(600, $(this.args.container).width()))
                    .blur(function () {
                        if (!that.richTextEditActive) {
                            that.args.commitChanges(true);

                            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                            if (that.isValueChanged && parent.settings.controlState != ControlState.HistoryView) {
                                $(that.args.container).highlightReferences();
                            }
                            that.args.grid.resetActiveCell();
                        }
                    });

                this.$input.bind("keydown", this.handleKeyDown.bind(this));
                this.$input.bind("paste", this.handlePaste.bind(this));
                this.position(this.args.position);
                this.$input.focus().select();
            }
            editRichText() {
                this.richTextEditActive = true;
                this.richTextEditWasActive = true;
                let that = this;
                let text = this.$input.val();

                let dlg = $("#editFieldDlg");
                dlg.html("");
                dlg.addClass("dlg-no-scroll");
                dlg.removeClass("dlg-v-scroll");

                let rte = $("<div>");
                dlg.append($("<div>").append(rte));
                rte.richText({
                    controlState: ControlState.DialogEdit,
                    fieldValue: text,
                    canEdit: true,
                    help: " ",
                    parameter: { height: 285, tableMode: false, autoEdit: true, autoFocus: true },
                });

                let padding = 28;
                dlg.dialog({
                    autoOpen: true,
                    title: "Edit Cell",
                    height: 520,
                    width: 730,
                    modal: true,
                    resize: function () {
                        $(".note-editable", rte).height(dlg.height() - 65);
                        $("#editFieldDlg").width($("#editFieldDlg").parent().width() - padding);
                    },
                    resizeStop: function () {
                        $(".note-editable", rte).height(dlg.height() - 65);
                        $("#editFieldDlg").width($("#editFieldDlg").parent().width() - padding);
                    },
                    closeOnEscape: false, // escape is annoying because it cannot be undone and it can happen when entering tables
                    open: function () {
                        // MATRIX-6418, MATRIX-6683: resizes causing redraw of the table, which is breaking value update logic.
                        // ignoring the resize when dialog is opened, shouldn't cause any issues, because when value is updated
                        // the table is redrawn anyway and when update is cancelled, we're invoking redraw manually
                        parent.ignoreResize = true;

                        padding = $("#editFieldDlg").parent().width() - $("#editFieldDlg").width();
                        let el = $(".note-editable", rte);

                        el.on("keydown", async function (event) {
                            if (globalMatrix.globalShiftDown && event.keyCode === 13) {
                                if (event.preventDefault) event.preventDefault();
                                if (event.stopPropagation) event.stopPropagation();
                                that.$input.val(await rte.getController().getValueAsync());
                                that.richTextEditActive = false;
                                dlg.dialog("close");
                            }
                        });
                        ml.UI.pushDialog(dlg);
                    },
                    close: function () {
                        ml.UI.popDialog(dlg);
                        parent.ignoreResize = false;
                    },
                    buttons: [
                        {
                            text: "Ok",
                            class: "btnDoIt",
                            click: async function () {
                                let rt = (await rte.getController().getValueAsync()).replace(/(\r\n|\n|\r)/gm, "");
                                that.$input.val(rt);
                                that.richTextEditActive = false;
                                $(this).dialog("close");
                                that.args.grid.navigateNext();
                            },
                        },
                        {
                            text: "Cancel",
                            class: "btnCancelIt",
                            click: function () {
                                that.richTextEditActive = false;
                                $(this).dialog("close");
                                // MATRIX-6418, MATRIX-6683: we're ignoring resizing events when dialog is opened,
                                // meaning that after dialog is closed we need to potentially resize the table.
                                // in case of value update we have to do it all the time in order to render the updated value,
                                // but in case of a cancel, we have to invoke it manually just in case. it's not ideal,
                                // and it's possible to invoke the redraw only if resize actually happen when dialog was opened,
                                // but I'm not sure how much difference it would make in real world scenario.
                                // arguably, not a lot, hence leaving it as is.
                                parent.redraw(true);
                            },
                        },
                    ],
                });
            }
            handlePaste() {}
            handleKeyDown(e: KeyboardEvent) {
                if (e.key == "<" /* 226 = < */) {
                    let textarea = e.currentTarget as HTMLTextAreaElement;
                    if (textarea.selectionStart || textarea.selectionStart === 0) {
                        let startPos = textarea.selectionStart;
                        let endPos = textarea.selectionEnd;
                        textarea.value =
                            textarea.value.substring(0, startPos) +
                            "&lt;" +
                            textarea.value.substring(endPos, textarea.value.length);

                        textarea.selectionStart = startPos + "&lt;".length;
                        textarea.selectionEnd = textarea.selectionStart;
                    } else {
                        textarea.value += "&lt;";
                    }
                    if (e.preventDefault) e.preventDefault();
                    if (e.stopPropagation) e.stopPropagation();
                }
                if (e.which == $.ui.keyCode.ENTER && e.shiftKey) {
                    if (e.preventDefault) e.preventDefault();
                    if (e.stopPropagation) e.stopPropagation();

                    this.editRichText();
                } else if (e.which == $.ui.keyCode.ENTER && e.ctrlKey) {
                    this.save();
                } else if (e.which == $.ui.keyCode.ESCAPE) {
                    if (e.preventDefault) e.preventDefault();
                    this.cancel();
                } else if (e.which == $.ui.keyCode.TAB && e.shiftKey) {
                    if (e.preventDefault) e.preventDefault();
                    this.args.grid.navigatePrev();
                } else if (e.which == $.ui.keyCode.TAB) {
                    if (e.preventDefault) e.preventDefault();
                    if (e.stopPropagation) e.stopPropagation();
                    this.args.grid.navigateNext();
                }
            }

            save() {
                this.args.commitChanges();
            }

            cancel() {
                this.$input.val(this.defaultValue);
                this.args.cancelChanges();
            }

            hide() {
                this.$wrapper.hide();
            }

            show() {
                this.$wrapper.show();
            }

            position(cellExtend: { left: number; right: number; top: number }) {
                if (this.wasPositioned) return;
                this.wasPositioned = true;
                let dlgPosFix = 0;
                let dlg = parent._root.closest("#appPopup");
                if (dlg && dlg.length > 0) {
                    cellExtend.left -= dlg.parent().position().left;
                    cellExtend.right -= dlg.parent().position().left;
                    dlgPosFix = dlg.parent().position().top;
                }

                let moveUp =
                    $(window).height() - ($(this.args.container).offset().top + 100 + $(this.args.container).height());
                let moveDown = $(this.args.container).offset().top - 120;

                if (moveUp < -5) {
                    this.$scroller.scrollTop(this.$scroller.scrollTop() - moveUp);
                } else if (moveDown < -5) {
                    this.$scroller.scrollTop(this.$scroller.scrollTop() + moveDown);
                }

                if (dlg && dlg.length > 0) {
                    this.$wrapper.css(
                        "top",
                        Math.max(
                            10,
                            Math.min(cellExtend.top - 5 - dlgPosFix, dlg.height() - this.$wrapper.outerHeight() - 20),
                        ),
                    );
                    this.$wrapper.css(
                        "left",
                        Math.max(10, Math.min(cellExtend.left - 5, dlg.width() - this.$wrapper.width() - 15)),
                    );
                } else {
                    this.$wrapper.css(
                        "top",
                        Math.max(
                            10,
                            Math.min(
                                cellExtend.top - 5 - dlgPosFix,
                                $(window).height() - this.$wrapper.outerHeight() - 20,
                            ),
                        ),
                    );
                    this.$wrapper.css(
                        "left",
                        Math.max(10, Math.min(cellExtend.left - 5, $(window).width() - this.$wrapper.width() - 15)),
                    );
                }
            }

            destroy() {
                parent.editorActive = false;
                this.$wrapper.remove();
            }

            focus() {
                this.$input.focus();
            }

            loadValue(item: Record<string, string>) {
                this.$input.val((this.defaultValue = item[this.args.column.field]));
                this.$input.select();
            }

            serializeValue() {
                return this.$input.val();
            }

            applyValue(item: Record<string, string>, state: string) {
                if (!this.richTextEditWasActive) {
                    state = state.replace(/\n/g, "<br>");
                }
                state = state.replace(/&/g, "escapeANDXAND");
                let clean = new HTMLCleaner(state);
                // be strict if the server is strict
                clean.applyServerCleaning();
                // do the minimum
                state = clean.getClean(0, true);
                state = state.replace(/escapeANDXAND/g, "&");
                item[this.args.column.field] = state;
            }

            isValueChanged() {
                return (
                    !(this.$input.val() == "" && this.defaultValue == null) && this.$input.val() != this.defaultValue
                );
            }
            validate(): { valid: boolean; msg: null } {
                return {
                    valid: true,
                    msg: null,
                };
            }
        };
    }

    CommentlogEditor() {
        // let parent = this;
        return class {
            public args: any;
            public currentValue: string;
            public previousValue: string;

            constructor(args: any) {
                this.args = args;
                this.previousValue = this.currentValue =
                    args && args.item && args.column && args.column.field ? args.item[args.column.field] : "";
                this.init();
            }

            getDelete() {
                let del = $("<span class='commentDelete commentDate'><i class='fal fa-trash-alt'/></span>");
                del.click(function (event) {
                    $(event.delegateTarget).closest(".commentLine").remove();
                });
                return del;
            }

            addLine(earlier: JQuery, input: JQuery) {
                let newLi = $("<div class='commentLine'>");
                if (this.args && this.args.column && this.args.column.options && this.args.column.options.append) {
                    newLi.appendTo(earlier);
                } else {
                    newLi.prependTo(earlier);
                }

                newLi.append(this.getDelete());
                let creationDate = new Date();
                newLi.append(
                    "<div class='commentDate' data-cd='" +
                        creationDate.toISOString() +
                        "'>" +
                        ml.UI.DateTime.renderCustomerHumanDate(creationDate) +
                        "</div>",
                );
                newLi.append("<div class='commentUser'>" + matrixSession.getUser() + "</div>");
                newLi.append(
                    "<div class='commentText' style='float: left;'>" +
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        ml.UI.lt.forDB(input.val(), undefined).replace(/\n/g, "<br />") +
                        "</div>",
                );
                newLi.append("<div style='clear:both;'></div>"); // for height calculation (in case of short text)
                input.val("");
            }

            init() {
                let that = this;
                let dlg = $("#editFieldDlg");
                dlg.html("");
                dlg.addClass("dlg-no-scroll");
                dlg.removeClass("dlg-v-scroll");

                let adder = $("<div>").appendTo(dlg);
                if (that.currentValue && that.currentValue.length) {
                    dlg.append("<div style='padding: 12px 0 0 0;'>Previous Comments:</div>");
                }
                // add earlier comments
                let earlier = $("<div style='overflow-y:auto'>").appendTo(dlg);
                earlier.append(that.currentValue ? that.currentValue : "");
                $.each($(".commentLine", earlier), function (idx, li) {
                    if ($(".commentUser", $(li)).text() == matrixSession.getUser()) {
                        $(li).prepend(that.getDelete());
                    }
                });

                // add tools to add a new comment
                $("<div style='padding: 0 0 6px 0;'>New Comment:</div>").appendTo(adder);
                let input = $('<textarea class="form-control" rows="3" style="resize: vertical;">').appendTo(adder);

                let rte = $("<div>");
                dlg.append($("<div>").append(rte));

                // TODO: convert to const and make sure it's still works
                // eslint-disable-next-line no-var
                var padding = 28;
                dlg.dialog({
                    autoOpen: true,
                    title: "Edit",
                    height: 450,
                    width: 730,
                    modal: true,
                    resize: function () {
                        earlier.height(dlg.height() - 155);
                        $("#editFieldDlg").width($("#editFieldDlg").parent().width() - padding);
                    },
                    resizeStop: function () {
                        earlier.height(dlg.height() - 155);
                        $("#editFieldDlg").width($("#editFieldDlg").parent().width() - padding);
                    },
                    closeOnEscape: true, // escape is annoying because it cannot be undone and it can happen when entering tables
                    open: function () {
                        padding = $("#editFieldDlg").parent().width() - $("#editFieldDlg").width();
                        earlier.height(dlg.height() - 155);

                        ml.UI.pushDialog(dlg);
                    },
                    close: function () {
                        ml.UI.popDialog(dlg);
                        that.args.commitChanges(true);
                        that.args.grid.resetActiveCell();
                    },
                    buttons: [
                        {
                            text: "Ok",
                            class: "btnDoIt",
                            click: function () {
                                if (input.val()) {
                                    that.addLine(earlier, input);
                                }
                                $(".commentDelete", earlier).remove();
                                that.currentValue = earlier.html();
                                $(this).dialog("close");
                            },
                        },
                        {
                            text: "Cancel",
                            class: "btnCancelIt",
                            click: function () {
                                $(this).dialog("close");
                            },
                        },
                    ],
                });
            }

            save() {
                this.args.commitChanges();
            }
            cancel() {
                this.currentValue = this.previousValue;
            }
            hide() {}
            show() {}
            destroy() {}
            focus() {}
            loadValue() {}
            serializeValue() {
                return this.currentValue;
            }
            applyValue(item: Record<string, string>, state: string) {
                state = state.replace(/&/g, "escapeANDXAND");
                state = new HTMLCleaner(state).getClean(0, true);
                state = state.replace(/escapeANDXAND/g, "&");
                item[this.args.column.field] = state;
            }
            isValueChanged() {
                return this.previousValue !== this.serializeValue();
            }
            validate(): { valid: boolean; msg: null } {
                return {
                    valid: true,
                    msg: null,
                };
            }
        };
    }

    /** copy of Slick.Editor.Text with some cleaning */
    TextEditorSafe() {
        // let parent = this;
        return class {
            public args: any;
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            public $input: JQuery;
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            public defaultValue: string;

            constructor(args: any) {
                this.args = args;
                this.init();
            }

            init() {
                this.$input = $("<INPUT type=text class='editor-text' />")
                    .appendTo(this.args.container)
                    .bind("keydown.nav", function (e) {
                        if (e.keyCode === $.ui.keyCode.LEFT || e.keyCode === $.ui.keyCode.RIGHT) {
                            e.stopImmediatePropagation();
                        }
                    })
                    .focus()
                    .select();
            }

            destroy() {
                this.$input.remove();
            }

            focus() {
                this.$input.focus();
            }

            async getValue() {
                return this.$input.val();
            }

            setValue(val: string) {
                this.$input.val(val);
            }

            loadValue(item: Record<string, string>) {
                this.defaultValue = item[this.args.column.field] || "";
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                this.defaultValue = ml.UI.lt.forUI(this.defaultValue, undefined);

                this.$input.val(this.defaultValue);
                // @ts-ignore TODO: figure it out
                this.$input[0].defaultValue = this.defaultValue;
                this.$input.select();
            }

            serializeValue() {
                return this.$input.val();
            }

            applyValue(item: Record<string, string>, state: string) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                state = ml.UI.lt.forDB(state, undefined);
                state = new HTMLCleaner(state).getClean(0, true);

                item[this.args.column.field] = state;
            }

            isValueChanged() {
                return (
                    !(this.$input.val() == "" && this.defaultValue == null) && this.$input.val() != this.defaultValue
                );
            }

            validate() {
                if (this.args.column.validator) {
                    let validationResults = this.args.column.validator(this.$input.val());
                    if (!validationResults.valid) {
                        return validationResults;
                    }
                }

                return {
                    valid: true,
                    msg: null,
                };
            }
        };
    }

    ColorEditor() {
        // let parent = this;
        return class {
            public args: any;
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            public $input: JQuery;
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            public defaultValue: string;

            constructor(args: any) {
                this.args = args;
                this.init();
            }

            init() {
                this.$input = $("<INPUT type='color' class='editor-text' style='padding:0;height:20px' />")
                    .appendTo(this.args.container)
                    .bind("keydown.nav", function (e) {
                        if (e.keyCode === $.ui.keyCode.LEFT || e.keyCode === $.ui.keyCode.RIGHT) {
                            e.stopImmediatePropagation();
                        }
                    });
            }

            destroy() {
                this.$input.remove();
            }

            focus() {
                this.$input.focus();
            }

            async getValue() {
                return this.$input.val();
            }

            setValue(val: string) {
                this.$input.val(val);
            }

            loadValue(item: Record<string, string>) {
                this.defaultValue = item[this.args.column.field] || "";
                let ctx = document.createElement("canvas").getContext("2d");
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                ctx.fillStyle = this.defaultValue;
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                this.defaultValue = ctx.fillStyle;

                this.$input.val(this.defaultValue);
                // @ts-ignore not sure about it
                this.$input[0].defaultValue = this.defaultValue;
                this.$input.click();
            }

            serializeValue() {
                return this.$input.val();
            }

            applyValue(item: Record<string, string>, state: string) {
                item[this.args.column.field] = state;
            }

            isValueChanged() {
                return (
                    !(this.$input.val() == "" && this.defaultValue == null) && this.$input.val() != this.defaultValue
                );
            }

            validate() {
                if (this.args.column.validator) {
                    let validationResults = this.args.column.validator(this.$input.val());
                    if (!validationResults.valid) {
                        return validationResults;
                    }
                }

                return {
                    valid: true,
                    msg: null,
                };
            }
        };
    }

    SelectCellEditor() {
        // TODO: fix it
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        let parent = this;
        return class {
            public args: any;
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            public $select: JQuery;
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            public defaultValue: string;

            constructor(args: any) {
                this.args = args;
                this.init();
            }

            init() {
                parent.editorActive = true;
                let that = this;
                let option_str = "";

                if (typeof this.args.column.options == "string") {
                    option_str = this.args.column.options;
                } else if ($.isArray(this.args.column.options)) {
                    for (let idx = 0; idx < this.args.column.options.length; idx++) {
                        option_str +=
                            "<OPTION value='" +
                            this.args.column.options[idx].id +
                            "'" +
                            (this.args.column.options[idx].disabled ? "disabled" : "") +
                            ">" +
                            this.args.column.options[idx].label +
                            "</OPTION>";
                    }
                } else {
                    for (let opt in this.args.column.options) {
                        option_str += "<OPTION value='" + opt + "'>" + this.args.column.options[opt] + "</OPTION>";
                    }
                }
                this.$select = $("<SELECT tabIndex='0' class='slick_table_dropdown'>" + option_str + "</SELECT>").blur(
                    function () {
                        that.args.commitChanges(true);
                        that.args.grid.resetActiveCell();
                    },
                );

                this.$select.appendTo(this.args.container);
                this.$select.change(function () {
                    let rowCount = that.args.grid.getData().length;
                    let currentRow = that.args.grid.getActiveCell().row;
                    that.args.commitChanges(currentRow + 1 >= rowCount);
                });
                this.$select.focus();
            }

            destroy() {
                parent.editorActive = false;
                this.$select.remove();
            }

            focus() {
                this.$select.focus();
            }

            loadValue(item: Record<string, string>) {
                this.defaultValue = item[this.args.column.field];
                this.$select.val(this.defaultValue);
            }

            serializeValue() {
                return this.$select.val();
            }

            applyValue(item: Record<string, string>, state: string) {
                item[this.args.column.field] = state;

                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                let thisCol = parent.settings.parameter.columns.filter(
                    (col: Slick.Column<OldTableData>) => col.field == this.args.column.field,
                );
                if (thisCol.length == 0) {
                    return;
                }
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                let otherColumnsNames = parent.settings.parameter.columns
                    .filter((col: Slick.Column<OldTableData>) => col.field != this.args.column.field)
                    .map((col: Slick.Column<OldTableData>) => col.name);

                // check if there is a customer / project setting mapping this column to another existing column
                let customerAuto = matrixSession
                    .getCustomerSettingJSON(autoColumnSetting, autoColumnDefault)
                    .maps.filter(
                        (col: { dropdownColumnName: string; textColumnName: string }) =>
                            col.dropdownColumnName == thisCol[0].name &&
                            otherColumnsNames.indexOf(col.textColumnName) != -1,
                    );
                let projectAuto = (<IAutoColumn>(
                    globalMatrix.ItemConfig.getSettingJSON(autoColumnSetting, autoColumnDefault)
                )).maps.filter(
                    (col) =>
                        col.dropdownColumnName == thisCol[0].name &&
                        otherColumnsNames.indexOf(col.textColumnName) != -1,
                );

                let maps = customerAuto.concat(projectAuto);
                for (let map of maps) {
                    let mapping = map.mapping.filter((m: { dropdownValue: string }) => m.dropdownValue == state);
                    if (mapping.length) {
                        let otherVal = mapping[mapping.length - 1].textValue;
                        const otherColumnName = map.textColumnName;
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        let otherColumnFields = parent.settings.parameter.columns
                            .filter((col: Slick.Column<OldTableData>) => col.name == otherColumnName)
                            .map((col: Slick.Column<OldTableData>) => col.field);
                        item[otherColumnFields[0]] = otherVal;
                    }
                }
            }

            isValueChanged() {
                return this.$select.val() != this.defaultValue;
            }

            validate(): { valid: boolean; msg: null } {
                return {
                    valid: true,
                    msg: null,
                };
            }
        };
    }

    SelectCellPopupSelectEditor() {
        // TODO: fix it
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        let parent = this;
        return class {
            public args: any;
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            public $select: JQuery;
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            public defaultValue: string;

            constructor(args: any) {
                this.args = args;
                this.init();
            }

            init() {
                parent.editorActive = true;
                let that = this;

                let val = this.args.item[this.args.column.field] ? this.args.item[this.args.column.field] : "";
                let isUserGroup = this.args.column.options.select == "groupuser";
                let title = isUserGroup ? "Select user" : "Select user group / role";

                this.$select = $("<div style='width:100%;height:100%'>").appendTo(this.args.container);
                this.$select.click(function () {
                    // note changed this to  (to not break IE)
                    ml.UI.SelectUserOrGroup.showSingleSelectDialog(
                        val,
                        title,
                        "",
                        isUserGroup,
                        !isUserGroup,
                        function (selected) {
                            that.$select.html(ml.UI.SelectUserOrGroup.getGroupDisplayNameFromId(selected));
                            that.$select.data("selected", selected);
                            that.args.commitChanges(true);
                        },
                    );
                });
                this.$select.focus();
            }

            destroy() {
                parent.editorActive = false;
                this.$select.remove();
            }

            focus() {
                this.$select.focus();
            }

            loadValue(item: Record<string, string>) {
                this.defaultValue = item[this.args.column.field];
                this.$select.html(ml.UI.SelectUserOrGroup.getGroupDisplayNameFromId(this.defaultValue));
                this.$select.data("selected", this.defaultValue);
            }

            serializeValue() {
                return this.$select.data("selected");
            }

            applyValue(item: Record<string, string>, state: string) {
                item[this.args.column.field] = state;
            }

            isValueChanged() {
                return this.$select.data("selected") != this.defaultValue;
            }

            validate(): { valid: boolean; msg: null } {
                return {
                    valid: true,
                    msg: null,
                };
            }
        };
    }

    ItemRefEditor(typeList?: any, refOptions?: any) {
        // TODO: fix it
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        let parent = this;
        return class {
            public args: any;
            public typeList: any;
            public refOptions: any;
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            public tree: JQuery;

            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            public selectDialog: null; // seems weird
            public types: any; // same as typeList.
            public current: string[] = [];
            public previous = "";

            constructor(args: any) {
                this.args = args;

                this.typeList = typeList;
                if (!this.typeList) {
                    if (args.column.editorParam) {
                        this.typeList = args.column.editorParam;
                    }
                }
                this.types = this.typeList;

                this.refOptions = refOptions;
                if (!this.refOptions) {
                    // Default value.
                    this.refOptions = args.column.options;
                }
                this.init();
            }

            async saveSelection() {
                let that = this;
                this.current = [];
                (await this.tree.getController().getValueAsync()).forEach(function (val: { to: string }) {
                    that.current.push(val.to);
                });
            }

            showSelectDialog() {
                let that = this;

                // remove global highlight and show only matches in dlg after
                ml.Search.searchInDialog();

                let niceSize = ml.UI.getNiceDialogSize(900, 700);

                $("#selectItemDlg").html("");
                $("#selectItemDlg").removeClass("dlg-v-scroll");
                $("#selectItemDlg").addClass("dlg-no-scroll");

                this.tree = $("#selectItemDlg").projectView({
                    tree: app.getTree(this.types),
                    controlState: ControlState.DialogCreate,
                    canSelectItems: true,
                    selectedItems: [],
                    selectMode: this.refOptions && this.refOptions.singleSelect ? 3 : 1, // SelectMode.singleItem |  SelectMode.items
                    singleSelect: this.refOptions && this.refOptions.singleSelect,
                    expand: this.typeList.length > 2 ? 0 : 1,
                });

                $("#selectItemDlg")
                    .dialog({
                        autoOpen: true,
                        title: "Select Items",
                        height: niceSize.height,
                        width: niceSize.width,
                        modal: true,
                        close: function () {
                            // dlg is gone, remove highlights and back to global highlighting
                            ml.Search.endSearchInDialog();
                            that.args.commitChanges(true);
                            $(that.args.grid.getActiveCellNode()).highlightReferences(); // useful if user cancels dialog to show smart links
                            that.args.grid.resetActiveCell();
                        },
                        open: function () {},
                        resizeStop: function () {
                            // @ts-ignore TODO: investigate what "this" should refer to
                            $("#selectItemDlg").resizeDlgContent([this.tree]);
                        },
                        buttons: [
                            {
                                text: "Select",
                                class: "btnDoIt",
                                click: async function () {
                                    await that.saveSelection();
                                    $(this).dialog("close");
                                },
                            },
                            {
                                text: "Cancel",
                                class: "btnCancelIt",
                                click: function () {
                                    $(this).dialog("close");
                                },
                            },
                        ],
                    })
                    .resizeDlgContent([this.tree], false);
            }

            init() {
                parent.editorActive = true;
                this.current = [];
                this.previous = "";
                this.showSelectDialog();
            }

            destroy() {
                parent.editorActive = false;
                this.selectDialog = null;
            }

            focus() {
                // $select.focus();
            }

            loadValue(item: Record<string, string>) {
                this.current = [];
                this.previous = "";
                if (item[this.args.column.field] && this.tree) {
                    this.previous = item[this.args.column.field];
                    this.current = this.previous.split(",");
                    this.tree.getController().setValue(this.current);
                }
            }

            serializeValue() {
                return this.current.join(",");
            }

            applyValue(item: Record<string, string>, state: string) {
                item[this.args.column.field] = state;
            }

            isValueChanged() {
                return this.previous !== this.serializeValue();
            }

            validate(): { valid: boolean; msg: null } {
                return {
                    valid: true,
                    msg: null,
                };
            }
        };
    }

    ItemECOEditor() {
        return this.ItemRefEditor(["ECO"]);
    }

    ItemDesignEditor() {
        let types: string[] = [];
        let tc = <ITraceConfig>globalMatrix.ItemConfig.getTraceConfig();
        if (tc) {
            tc.rules.forEach(function (r) {
                if (r.reporting.indexOf("Design") !== -1) {
                    types.push(r.category);
                }
            });
        }
        return this.ItemRefEditor(types);
    }
    ItemDownLinkEditor() {
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        let req = globalMatrix.ItemConfig.getLinkTypes(this.settings.type, true, false);
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        let opt = globalMatrix.ItemConfig.getLinkTypes(this.settings.type, true, true);
        return this.ItemRefEditor(req.concat(opt));
    }
    ItemUpLinkEditor() {
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        let req = globalMatrix.ItemConfig.getLinkTypes(this.settings.type, false, false);
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        let opt = globalMatrix.ItemConfig.getLinkTypes(this.settings.type, false, true);
        return this.ItemRefEditor(req.concat(opt));
    }
    ItemAnyLinkEditor() {
        return this.ItemRefEditor();
    }
    ItemECOCAPAEditor() {
        return this.ItemRefEditor(["ECO", "CAPA"]);
    }

    // formatters
    rowToolsFormatter(row: number) {
        if (!this.lastSelectedRows || this.lastSelectedRows.indexOf(row) === -1) {
            return "";
        }
        let icons = "<span class='fal fa-times'></span> ";
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        if (this.settings.parameter.create) {
            icons += "<span class='fal fa-arrow-up'></span>";
        }

        if (this.canImport) {
            icons += "<span class='fal fa-file-import'></span>";
        }

        return icons;
    }
    passFailFormatterIcon(value: string) {
        for (let idx = 0; idx < this.passFailOptions.length; idx++) {
            if (value === this.passFailOptions[idx].code) {
                if (this.passFailOptions[idx].image && this.passFailOptions[idx].image !== "") {
                    return (
                        '<span><img src="' +
                        globalMatrix.matrixBaseUrl +
                        "/img/" +
                        this.passFailOptions[idx].image +
                        '" /></span>'
                    );
                } else {
                    return "<span>" + this.passFailOptions[idx].code + "</span>";
                }
            }
        }

        return "<span></span>";
    }
    passFailFormatter(
        row: number,
        cell: number,
        value: string,
        columnDef: Slick.Column<OldTableData>,
        dataContext: unknown,
    ) {
        let icon = this.passFailFormatterIcon(value);
        let text = "";
        for (let idx = 0; idx < this.passFailOptions.length; idx++) {
            if (value === this.passFailOptions[idx].code) {
                text =
                    "<span class='test_step_" +
                    this.passFailOptions[idx].render +
                    "'>" +
                    this.passFailOptions[idx].human +
                    "</span>";
            }
        }

        return "<table><tr><td style='width:30px'>" + icon + "</td><td>" + text + "</td></tr></table>";
    }
    rowCounterFormatter(row: number) {
        let disp = "";
        if (this.data[row]._refi) {
            disp +=
                "<span class='fal fa-retweet retweet-main' data-ref='" +
                this.data[row]._refi +
                "' title='included " +
                this.data[row]._refi +
                " step " +
                this.data[row]._refl +
                "'></span>";
        }
        disp += row + 1;

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        return "<span>" + (this.settings.parameter.showLineNumbers ? disp : "") + "</span>";
    }
    multiLineFormatter(
        row: number,
        cell: number,
        value: string,
        columnDef: Slick.Column<OldTableData>,
        dataContext: unknown,
    ) {
        // @ts-ignore we're messing with data manually in initControl
        return this.formatText(value, true, columnDef.readOnly);
    }
    multiLineFormatterRich(
        row: number,
        cell: number,
        value: string,
        columnDef: Slick.Column<OldTableData>,
        dataContext: unknown,
    ) {
        let clean = value
            ? this.settings.controlState == ControlState.HistoryView || this.settings.controlState == ControlState.Zen
                ? value
                : ml.SmartText.replaceTextFragments(value, true)
            : "";
        return "<span class='multiLineFormatter' >" + clean + "</span>";
    }
    multiLineFormatterInteger(
        row: number,
        cell: number,
        value: string,
        columnDef: Slick.Column<OldTableData>,
        dataContext: unknown,
    ) {
        return "<span class='multiLineFormatter' >" + value !== undefined ? value : "" + "</span>";
    }
    multiLinePlainFormatter(
        row: number,
        cell: number,
        value: string,
        columnDef: Slick.Column<OldTableData>,
        dataContext: unknown,
    ) {
        let clean = "";
        if (value) {
            value = value.replace(/</g, "&lt;");
            clean =
                this.settings.controlState == ControlState.HistoryView || this.settings.controlState == ControlState.Zen
                    ? value
                    : ml.SmartText.replaceTextFragments(value, true);
        }
        return "<span class='multiLineFormatter' >" + clean + "</span>";
    }
    colorFormatter(
        row: number,
        cell: number,
        value: string,
        columnDef: Slick.Column<OldTableData>,
        dataContext: unknown,
    ) {
        return `<div style="background:${value};width:100%;height:18px;border:solid 1px grey;margin:1px;"></div>`;
    }
    selectIconFormatter(
        row: number,
        cell: number,
        value: string,
        columnDef: Slick.Column<OldTableData>,
        dataContext: unknown,
    ) {
        let display = "";
        if (Array.isArray(columnDef.options)) {
            columnDef.options.forEach(function (opt) {
                if (opt.id == value) {
                    display = opt.label;
                }
            });
        } else if (
            value !== "undefined" &&
            typeof columnDef.options !== "string" &&
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            columnDef.options[value] !== "undefined" &&
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            columnDef.options[value]
        ) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            display = columnDef.options[value];
        }
        return `<span><i class='${value}'></i></span>`;
    }

    selectFormatter(
        row: number,
        cell: number,
        value: string,
        columnDef: Slick.Column<OldTableData>,
        dataContext: unknown,
    ) {
        let display = "";
        if (typeof columnDef.options == "string") {
            let select = $("<select>" + columnDef.options + "</select>");
            display = $("option[value='" + value + "']", select).text();
        } else if (Array.isArray(columnDef.options)) {
            columnDef.options.forEach(function (opt) {
                if (opt.id == value) {
                    display = opt.label;
                }
            });
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        } else if (value !== "undefined" && columnDef.options[value] !== "undefined" && columnDef.options[value]) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            display = columnDef.options[value];
        }
        // @ts-ignore we're messing with data manually in initControl
        return this.formatText(display, false, columnDef.readOnly);
    }

    selectCellPopupSelectFormatter(
        row: number,
        cell: number,
        value: string,
        columnDef: Slick.Column<OldTableData>,
        dataContext: unknown,
    ) {
        if (value) {
            let display = ml.UI.SelectUserOrGroup.getGroupDisplayNameFromId(value);
            // @ts-ignore we're messing with data manually in initControl
            return this.formatText(display, false, columnDef.readOnly);
        }
        return "";
    }

    itemRefFormatter(
        row: number,
        cell: number,
        value: string,
        columnDef: Slick.Column<OldTableData>,
        dataContext: unknown,
    ) {
        let items = typeof value !== "undefined" ? value : "";
        let showTitle =
            typeof columnDef.options !== "string" &&
            !Array.isArray(columnDef.options) &&
            columnDef.options?.showTitle &&
            items !== ""
                ? "!"
                : "";
        let il = items.split(",");
        let ret = $("<span class='reflistedit'>");
        if (typeof columnDef.options !== "string" && !Array.isArray(columnDef.options) && columnDef.options?.hideLink) {
            ret.removeClass("reflistedit");
        }
        il.forEach(function (il) {
            let d = $("<div>" + il + showTitle + "</div>");
            ret.append(d);
        });
        return $("<span>").append(ret).html();
    }

    dateFormatterMoment(
        row: number,
        cell: number,
        value: string,
        columnDef: Slick.Column<OldTableData>,
        dataContext: unknown,
    ) {
        // The Slick Date Control is coded to format the date string sent to us here in {value} as a Moment
        // date with getSimpleDateFormatMoment().
        // Therefore, in order to turn this string into a date, we need to let Moment create the date
        // using that format. (see MATRIX-7158).
        const momentDate = moment(value, ml.UI.DateTime.getSimpleDateFormatMoment()).toDate();
        return ml.UI.DateTime.renderHumanDate(momentDate, true);
    }

    formatText(value: string, rich: boolean, readOnly: boolean) {
        if (value) {
            return rich
                ? "<span class='multiLineFormatter' >" + value + "</span>"
                : "<span class='multiLineFormatter'>" + value + "</span>";
        }

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        if (!readOnly && this.settings.parameter.cellAskEdit) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            return "<span class='cellAskEdit'>" + this.settings.parameter.cellAskEdit + "</span>";
        }

        return "<span></span>";
    }

    // grid manipulation
    deleteRows() {
        // special treatment for IE: MATRIX-411
        $(".tooltip").remove();

        this.rememberScroll();
        let rows = this.grid.getSelectedRows();
        rows.sort(function (a, b) {
            return b - a;
        });
        let data = this.grid.getData();
        for (let i = 0; i < rows.length; i++) {
            data.splice(rows[i], 1);
        }
        this.grid.setData(data, false);
        this.grid.render();
        // unfortunately need to render whole thing
        this.updateRowHeights();
        this.scrollBack();

        this.gridChanged();
        this.grid.setSelectedRows(rows);
    }
    autoPopulate(row: Record<string, string | number>) {
        let that = this;
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        this.settings.parameter.columns.forEach(function (column: Slick.Column<OldTableData>) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            if (!row[column.field]) {
                if (column.editor == ColumnEditor.date_today || column.editor == ColumnEditor.today) {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    row[column.field] = ml.UI.DateTime.renderCustomerHumanDate(new Date(), true);
                } else if (column.editor == ColumnEditor.user_self || column.editor == ColumnEditor.self) {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    row[column.field] = matrixSession.getUser();
                } else if (column.editor == ColumnEditor.number) {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    row[column.field] = 0;
                } else if (column.editor == ColumnEditor.current_version) {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    row[column.field] =
                        that.settings.item && that.settings.item.history && that.settings.item.history.length
                            ? that.settings.item.history[0].version
                            : "0";
                }
            }
        });
    }
    insertAbove() {
        // special treatment for IE: MATRIX-411
        $(".tooltip").remove();
        this.rememberScroll();

        let rows = this.grid.getSelectedRows();

        let insertAbove = rows[0];
        if (
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            this.settings.parameter.maxRows !== -1 &&
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            this.grid.getDataLength() + rows.length > this.settings.parameter.maxRows
        ) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            ml.UI.showError("Warning", "Table can have only " + this.settings.parameter.maxRows + " rows");
            return;
        }

        rows.sort(function (a, b) {
            return a - b;
        });
        for (let idx = 0; idx < rows.length; idx++) {
            let newRow = {};
            this.autoPopulate(newRow);
            this.grid.getData().splice(insertAbove, 0, newRow);
        }
        this.grid.invalidate();
        this.grid.updateRowCount();
        this.grid.render();

        // unfortunately need to render whole thing
        this.updateRowHeights();
        this.scrollBack();

        this.gridChanged();
    }

    importAbove(append: boolean) {
        let that = this;

        $(".tooltip").remove();
        this.rememberScroll();

        let dlg = $("#importTableDlg");
        dlg.hide();
        dlg.html("");
        dlg.addClass("dlg-no-scroll");
        dlg.removeClass("dlg-v-scroll");
        let colIdxs: number[] = [];
        let colNames: string[] = [];
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        this.settings.parameter.columns.forEach(function (col: Slick.Column<OldTableData>, idx: number) {
            if (col.editor !== "none") {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                colNames.push(col.name);
                colIdxs.push(idx);
            }
        });
        dlg.append("<div>Copy / paste table into the form below.The columns must be separated by |, e.g.</div>");
        dlg.append("<div>|" + colNames.join("|") + "|</div>");
        dlg.append("<div>Note: escape | in a cell by \\|</div>");
        let textarea = $("<textarea style='width:90%;resize:none;top:80px;bottom:10px; position: absolute;'>");
        dlg.append(textarea);

        // if something is selected copy it into text area for copy paste
        if (this.grid.getSelectedRows().length > 0) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            let coldefs = this.settings.parameter.columns;
            let gdata = this.grid.getData();
            let select = "";
            let selrows = this.grid.getSelectedRows().sort(function (a, b) {
                return a - b;
            });
            selrows.forEach(function (selrow: number) {
                if (gdata[selrow]) {
                    select += "|";
                    coldefs.forEach(function (coldef: Slick.Column<OldTableData>) {
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        let col = gdata[selrow][coldef.field];
                        select += col ? col.replace(new RegExp("\\|", "g"), "\\\\|") : "";
                        select += "|";
                    });
                    select += "\n";
                }
            });
            textarea.val(select);
        }

        dlg.dialog({
            autoOpen: true,
            title: "import / export table content",
            width: 600,
            height: 500,
            modal: true,
            resizeStop: function () {},
            open: function () {
                ml.UI.pushDialog(dlg);
            },
            close: function () {
                ml.UI.popDialog(dlg);
            },
            buttons: [
                {
                    text: "Ok",
                    class: "btnDoIt",
                    click: function () {
                        let tabletext = textarea.val();
                        if (tabletext.length > 0) {
                            tabletext = tabletext.split("\\|").join("escapedpipereplacement");

                            let cells = tabletext.split("|");
                            if (cells.length % (colIdxs.length + 1) !== 1) {
                                ml.UI.showError("Warning", "Incorrect number of cells");
                                return;
                            }
                            let rowCount = (cells.length - 1) / (colIdxs.length + 1);

                            let insertAbove = append ? that.grid.getDataLength() : that.grid.getSelectedRows()[0];
                            if (
                                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                that.settings.parameter.maxRows !== -1 &&
                                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                that.grid.getDataLength() + rowCount > that.settings.parameter.maxRows
                            ) {
                                ml.UI.showError(
                                    "Warning",
                                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                    "Table can have only " + that.settings.parameter.maxRows + " rows",
                                );
                                return;
                            }

                            let nextCell = 0;
                            for (let row = 0; row < rowCount; row++) {
                                let newRow: Record<string, string> = {};
                                nextCell++;
                                for (let col = 0; col < colIdxs.length; col++) {
                                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                    newRow[that.settings.parameter.columns[colIdxs[col]].field] = cells[
                                        nextCell++
                                    ].replace(new RegExp("escapedpipereplacement", "g"), "|");
                                }
                                that.autoPopulate(newRow);
                                that.grid.getData().splice(insertAbove++, 0, newRow);
                            }
                            that.grid.invalidate();
                            that.grid.updateRowCount();
                            that.grid.render();

                            // unfortunately need to render whole thing
                            that.updateRowHeights();
                            that.scrollBack();

                            that.gridChanged();
                            $(this).dialog("close");
                        }
                    },
                },
                {
                    text: "Cancel",
                    class: "btnCancelIt",
                    click: function () {
                        $(this).dialog("close");
                    },
                },
            ],
        });
    }
    updateRowHeights(fromRow?: number, toRow?: number) {
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        if (this.settings.parameter.manualTableHeights) {
            return; // nothing to do
        }

        // calculate real rendered heights of cells and adjust heights accordingly

        // in some popups like history, this did not work, probably because the div node got moved

        if (!this._list.prop("class")) return; // avoid error log - can happen if user selects quickly multiple items with table because of delayed rendering of tooltip
        // get the unqiue classes of the control
        let classes = "." + this._list.prop("class").split(" ").join(".");
        // replace with moved
        this._list = $(classes);

        // prepare data
        let uhrCols = this.grid.getColumns();
        // handle nested tables
        let uhrRows = this._list.find(".grid-canvas").first().children(".slick-row");
        fromRow = fromRow ? fromRow : 0;
        toRow = toRow ? toRow : uhrRows.length;

        // prepare shadow copy
        let cellSizers = [];
        for (let cidx = 0; cidx < uhrCols.length; cidx++) {
            const func = uhrCols[cidx].formatter;

            // setup hidden render areas
            if (func) {
                const found = this.formattersRequiringSizers.find((f) => {
                    return f == func;
                });
                if (found) {
                    let cellSizer = $("<div class='controlContainer'>");
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    let width = Math.max(10, uhrCols[cidx].width - 10);
                    $("body").append(
                        cellSizer
                            .hide()
                            .width(width)
                            .css("left", "-" + width + "px"),
                    );
                    cellSizers.push({ colIdx: cidx, cellSizer: cellSizer });
                }
            }
        }

        // create shadow copy
        for (let uhrIdx = fromRow; uhrIdx < toRow; uhrIdx++) {
            const cells = uhrRows[uhrIdx].children;
            for (let cidx = 0; cidx < cellSizers.length; cidx++) {
                const idx = cellSizers[cidx].colIdx;
                let spacer = $("<div class='multiLineFormatter'>");
                cellSizers[cidx].cellSizer.append(spacer);
                if (cells[idx] && cells[idx].children[0]) {
                    // sometimes table cells are hidden
                    spacer.html(cells[idx].children[0].innerHTML);
                }
            }
        }

        for (let cidx = 0; cidx < cellSizers.length; cidx++) {
            cellSizers[cidx].cellSizer.show();
        }

        // hide the table (for performance)
        this._list.hide();

        // calculate max heights and set it to all new rows
        for (let uhrIdx = fromRow; uhrIdx < toRow; uhrIdx++) {
            let maxRowHeight = 25; // minimum...
            for (let cidx = 0; cidx < cellSizers.length; cidx++) {
                maxRowHeight = Math.max(
                    maxRowHeight,
                    cellSizers[cidx].cellSizer.children()[uhrIdx - fromRow].offsetHeight,
                );
            }

            const cells = uhrRows[uhrIdx].children;
            for (let cidx = 0; cidx < cellSizers.length; cidx++) {
                const idx = cellSizers[cidx].colIdx;
                if (cells[idx] && cells[idx].children[0]) {
                    $(cells[idx].children[0]).css("height", maxRowHeight + "px");
                }
            }

            for (let cidx = 0; cidx < cells.length; cidx++) {
                $(cells[cidx]).css("height", maxRowHeight + "px");
            }
            $(uhrRows[uhrIdx]).css("height", maxRowHeight + "px");
        }

        // calculate and set top

        let top = 0;
        if (fromRow > 0) {
            fromRow--;
            top = Number(uhrRows[fromRow].style.top.replace("px", ""));
        }
        for (let uhrIdx = fromRow; uhrIdx < uhrRows.length; uhrIdx++) {
            $(uhrRows[uhrIdx]).css("top", top + "px");
            top += $(uhrRows[uhrIdx]).height();
        }

        // set control height
        this._list.css("height", top + 50 + "px");
        $(".slick-viewport", this._list).css("height", "100%");

        // render hyperlinks
        if (this._root.highlightReferences && this.settings.controlState != ControlState.HistoryView) {
            this._root.highlightReferences();
            let searchFilter = ml.Search.getFilter();
            if (searchFilter) {
                // (re-)apply it
                this._root.highlight(searchFilter);
            }
            ml.Search.renderHighlight();
        }

        // show table again
        this._list.show();

        // remove $s (for print)
        for (let cidx = 0; cidx < cellSizers.length; cidx++) {
            cellSizers[cidx].cellSizer.remove();
        }

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        if (this.settings.parameter.updateParent) this.settings.parameter.updateParent();
    }
    // misc $ functions
    gridChanged() {
        let that = this;
        // callback called when grid changes

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        if (this.settings.parameter.autoUpdate) {
            let data = this.grid.getData();
            let before = JSON.stringify(data);
            let error = tableMath.execute(
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                this.settings.parameter.autoUpdate,
                data,
                <ITableParams>this.settings.parameter,
            );
            if (error) {
                ml.UI.showError("Cannot execute formula", error);
            } else {
                if (JSON.stringify(data) != before) {
                    this.rememberScroll();
                    this.grid.setData(data, false);
                    this.grid.render();
                    this.updateRowHeights(0, 0);
                    this.scrollBack();
                }
            }
        }
        this._root.data("new", this.grid.getData()); // required for d&d

        if (this.settings.valueChanged) {
            // valueChanged will trigger a needs save event,
            // this will trigger a redraw (as a timeout) which should be ignored
            this.ignoreResize = true;
            window.clearTimeout(this.ignoreResizeReset);
            this.ignoreResizeReset = window.setTimeout(function () {
                that.ignoreResize = false;
            }, 1000); // the other timeout is 500 ms
            // trigger event
            this.settings.valueChanged.apply(null);
        }
    }
    rememberScroll() {
        // function to remember scroll position of page
        this.vpp = this.vp.scrollTop();
    }
    scrollBack() {
        // function to restore scroll position of page
        this.vp.scrollTop(this.vpp);
    }

    /*
        {
            "options":[
                {"id":"todo","label":"To Do","class":"todo","sId":1},
                {"id":"fail","label":"TRES KAPUTT","class":"failed","sId":2},
                {"id":"pass","label":"Passed","class":"passed","sId":3},
                {"id":"Paaassse","label":"Passses","class":"passed","sId":4}
            ],
            "groups":[
                {"value":"passed","label":"passed"},
                {"value":"failed","label":"failed"},
                {"value":"todo","label":"todo"}
            ]
        }
    */
    getSelectColOptions(options: { setting: string }) {
        if (options && options.setting) {
            let dds = <ISelectColOptions>globalMatrix.ItemConfig.getSettingJSON(options.setting);
            if (dds && dds.groups && dds.groups.length) {
                // create all the groups the class of the group is it's id
                let select = $(
                    `<select>${dds.groups
                        .map((g) => {
                            return "<optgroup label='" + g.label + "' class='" + g.value + "'>";
                        })
                        .join("\n")}</select>`,
                );

                dds.options.forEach((opt) => {
                    if (opt.class == undefined) {
                        select.append(
                            $(`<option value="${opt.id}" ${opt.disabled ? "disabled" : ""} >${opt.label}</option>`),
                        );
                    } else {
                        $("." + opt.class, select).append(
                            $(`<option value="${opt.id}"  ${opt.disabled ? "disabled" : ""} >${opt.label}</option>`),
                        );
                    }
                });

                return select.html();
            }
            if (dds && dds.options) {
                let m: Record<string, string> = {};
                dds.options.forEach(function (ddo) {
                    m[ddo.id] = ddo.label;
                });
                return m;
            }
        }
        return options;
    }

    initControl() {
        let that = this;

        // We just want one copy of each formatter and editor. We have to use bind because
        // otherwise the "this" pointer in these functions will be undefined.
        const passFailFormatter = this.passFailFormatter.bind(this);
        const rowCounterFormatter = this.rowCounterFormatter.bind(this);
        const itemRefFormatter = this.itemRefFormatter.bind(this);
        const multiLineFormatter = this.multiLineFormatter.bind(this);
        const multiLinePlainFormatter = this.multiLinePlainFormatter.bind(this); // escape html
        const multiLineFormatterInteger = this.multiLineFormatterInteger.bind(this);
        const multiLineFormatterRich = this.multiLineFormatterRich.bind(this);
        const selectFormatter = this.selectFormatter.bind(this);
        const selectIconFormatter = this.selectIconFormatter.bind(this);
        const selectCellPopupSelectFormatter = this.selectCellPopupSelectFormatter.bind(this);
        const rowToolsFormatter = this.rowToolsFormatter.bind(this);
        const colorFormatter = this.colorFormatter.bind(this); // escape html
        const dateFormatterMoment = this.dateFormatterMoment.bind(this);

        // Editors are implemented as anonymous classes. They don't use bind, instead a factory
        // method returns the class configured with a parent pointer.
        const passFailEditor = this.PassFailEditor();
        const itemDesignEditor = this.ItemDesignEditor();
        const itemUpLinkEditor = this.ItemUpLinkEditor();
        const itemDownLinkEditor = this.ItemDownLinkEditor();
        const itemAnyLinkEditor = this.ItemAnyLinkEditor();
        const inplaceLongText = this.InplaceLongText();
        const commentlogEditor = this.CommentlogEditor();
        const slickEditorsDate = Slick.Editors.Date;
        const slickEditorsInteger = Slick.Editors.Integer;
        const selectCellEditor = this.SelectCellEditor();
        const selectCellPopupSelectEditor = this.SelectCellPopupSelectEditor();
        const textEditorSafe = this.TextEditorSafe();
        const colorEditor = this.ColorEditor();
        const itemECOEditor = this.ItemECOEditor();
        const itemECOCAPAEditor = this.ItemECOCAPAEditor();

        this.formattersRequiringSizers = [
            selectFormatter,
            multiLinePlainFormatter,
            itemRefFormatter,
            multiLineFormatter,
            multiLineFormatterRich,
            itemRefFormatter,
        ];

        if (this.settings.fieldHandler) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            this.data = JSON.parse(this.settings.fieldHandler.getData());
        } else if (this.settings.dummyData) {
            for (let idx = 0; idx < 500; idx++) {
                let row: Record<string, string> = {};
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                for (let column in this.settings.parameter.columns) {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    if (this.settings.parameter.columns[column].editor === ColumnEditor.text) {
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        row[this.settings.parameter.columns[column].field] =
                            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                            this.settings.parameter.columns[column].name + " " + idx;
                    }
                }
                this.data.push(row);
            }
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        } else if (this.settings.parameter.initialContent) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            this.data = ml.JSON.clone(this.settings.parameter.initialContent);
        }
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        if (this.settings.parameter.fixRows > 0) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            while (this.data.length < this.settings.parameter.fixRows) {
                let newRow = {};

                this.autoPopulate(newRow);
                this.data.push(newRow);
            }
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            if (this.data.length > this.settings.parameter.fixRows) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                this.data.splice(this.settings.parameter.fixRows, this.data.length - this.settings.parameter.fixRows);
            }
        }
        this._root.append(this.createHelp(this.settings));
        if (this.canImport) {
            let ci = $("<span class='fal fa-file-import' style='margin-left:10px; color:grey'>");
            this._root.append(ci);
            ci.click(function () {
                that.importAbove(true);
            }).tooltip({ container: "body", title: "export selection / import and append" });
        }
        this._root.append(this.ctrlContainer);
        this.ctrlContainer.append(this._list);
        this.vp = this._list.closest(".panel-body-v-scroll");
        // add column with line number
        let hasInclude = false;
        for (let idx = 0; idx < this.data.length && !hasInclude; idx++) {
            hasInclude = !!this.data[idx]._refi;
        }
        let lineNumberWidth =
            (hasInclude ? 50 : 40) + (this.data.length > 90 ? 10 : 0) + (this.data.length > 900 ? 10 : 0);
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        if (this.settings.canEdit && ml.JSON.isTrue(this.settings.parameter.canBeModified)) {
            this.columns.push({
                id: "#",
                name: "",
                width: lineNumberWidth,
                behavior: "selectAndMove",
                selectable: false,
                resizable: false,
                cssClass: "cell-reorder dnd",
                formatter: rowCounterFormatter,
            });
        } else {
            this.columns.push({
                id: "#",
                name: "",
                width: lineNumberWidth,
                selectable: false,
                resizable: false,
                formatter: rowCounterFormatter,
            });
        }

        // add data columns
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        if (!this.settings.parameter.columns) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            this.settings.parameter.columns = [];
            ml.Logger.log("error", "missing definition of table columns for use/test case");
        }
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        for (let idx = 0; idx < this.settings.parameter.columns.length; idx++) {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            let colDef = this.settings.parameter.columns[idx];
            let col: Slick.Column<OldTableData> = {
                headerCssClass: undefined,
                editor: undefined,
                options: undefined,
                editorParam: undefined,
                // @ts-ignore TODO: refactor to comply with the type. move to options?
                readOnly: undefined,

                id: colDef.field,
                name: colDef.name,
                field: colDef.field,
                width: colDef.relativeWidth ? colDef.relativeWidth : 350,
                cssClass: "cell-title" + (colDef.cssClass ? " " + colDef.cssClass : ""),
                formatter: multiLineFormatter, // use this as default
            };
            if (colDef.headerCssClass) {
                col.headerCssClass = colDef.headerCssClass;
            }
            // TODO: DRY it up
            if (
                this.settings.canEdit &&
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                (!this.settings.parameter.readOnlyFields ||
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    this.settings.parameter.readOnlyFields.indexOf(colDef.field) == -1)
            ) {
                this.focsuable[idx + 1] = { focusable: true };
                if (colDef.editor === ColumnEditor.text) {
                    col.editor = inplaceLongText;
                    col.formatter = multiLineFormatterRich;
                } else if (colDef.editor === ColumnEditor.commentlog) {
                    col.editor = commentlogEditor;
                    col.formatter = multiLineFormatter;
                    col.options = colDef.options;
                } else if (colDef.editor === ColumnEditor.date || colDef.editor === ColumnEditor.date_today) {
                    col.editor = slickEditorsDate;
                    col.formatter = dateFormatterMoment;
                } else if (colDef.editor === ColumnEditor.today) {
                    col.formatter = dateFormatterMoment;
                } else if (colDef.editor === ColumnEditor.current_version) {
                } else if (colDef.editor === ColumnEditor.select) {
                    // @ts-ignore TODO: refactor getSelectColOptions method to comply with the type
                    col.options = this.getSelectColOptions(colDef.options);
                    col.editor = selectCellEditor;
                    col.formatter = selectFormatter;
                } else if (colDef.editor === ColumnEditor.selectIcon) {
                    // @ts-ignore TODO: refactor getSelectColOptions method to comply with the type
                    col.options = this.getSelectColOptions(colDef.options);
                    col.editor = selectCellEditor;
                    col.formatter = selectIconFormatter;
                } else if (colDef.editor === ColumnEditor.versionletter) {
                    col.options = {
                        A: "A",
                        B: "B",
                        C: "C",
                        D: "D",
                        E: "E",
                        F: "F",
                        G: "G",
                        H: "H",
                        I: "I",
                        J: "J",
                        K: "K",
                        L: "L",
                        M: "M",
                        N: "N",
                        O: "O",
                        P: "P",
                        Q: "Q",
                        R: "R",
                        S: "S",
                        T: "T",
                        U: "U",
                        V: "V",
                        W: "W",
                        X: "X",
                        Y: "Y",
                        Z: "Z",
                    };
                    col.editor = selectCellEditor;
                    col.formatter = selectFormatter;
                } else if (colDef.editor === ColumnEditor.signaturemeaning) {
                    col.options = {
                        Author: "Author",
                        Reviewer: "Reviewer",
                        Approver: "Approver",
                        "Written By": "Written By",
                        "Reviewed By": "Reviewed By",
                        "Approved By": "Approved By",
                    };
                    if (mDHF && mDHF.getSignatureMeanings()) {
                        col.options = mDHF.getSignatureMeanings();
                    }
                    col.editor = selectCellEditor;
                    col.formatter = selectFormatter;
                } else if (colDef.editor === ColumnEditor.user || colDef.editor === ColumnEditor.user_self) {
                    col.options = { select: "groupuser" };
                    col.editor = selectCellPopupSelectEditor;
                    col.formatter = selectCellPopupSelectFormatter;
                } else if (colDef.editor === ColumnEditor.self) {
                    // self means a user id, and this formatter has support for deleted usernames.
                    col.formatter = selectCellPopupSelectFormatter;
                } else if (colDef.editor === ColumnEditor.group) {
                    col.options = { select: "group" };
                    col.editor = selectCellPopupSelectEditor;
                    col.formatter = selectCellPopupSelectFormatter;
                } else if (colDef.editor === ColumnEditor.textline || colDef.editor === ColumnEditor.revision) {
                    col.editor = textEditorSafe;
                    col.formatter = multiLinePlainFormatter; // escape html
                } else if (colDef.editor === ColumnEditor.colorPicker) {
                    col.editor = colorEditor;
                    col.formatter = colorFormatter;
                } else if (colDef.editor === ColumnEditor.number) {
                    col.editor = slickEditorsInteger;
                    col.formatter = multiLineFormatterInteger;
                } else if (colDef.editor === ColumnEditor.result) {
                    col.formatter = passFailFormatter;
                    col.editor = passFailEditor;
                } else if (colDef.editor === ColumnEditor.design) {
                    col.formatter = itemRefFormatter;
                    col.editor = itemDesignEditor;
                    col.options = colDef.options;
                } else if (colDef.editor === ColumnEditor.uprules) {
                    col.formatter = itemRefFormatter;
                    col.editor = itemUpLinkEditor;
                    col.options = colDef.options;
                } else if (colDef.editor === ColumnEditor.downrules) {
                    col.formatter = itemRefFormatter;
                    col.editor = itemDownLinkEditor;
                    col.options = colDef.options;
                    // convert to string here as it's a special case that is not part of the enum
                } else if (colDef.editor && (<string>colDef.editor).indexOf("rules:") === 0) {
                    col.formatter = itemRefFormatter;
                    col.editor = itemAnyLinkEditor;
                    col.editorParam = colDef.editor.substring(6);
                    col.options = colDef.options;
                    // convert to string here as it's a special case that is not part of the enum
                } else if (colDef.editor && (<string>colDef.editor).indexOf("category") === 0) {
                    col.formatter = itemRefFormatter;
                    col.editor = itemAnyLinkEditor;
                    col.editorParam = colDef.options.categories;
                    col.options = colDef.options;
                } else if (colDef.editor === ColumnEditor.eco) {
                    col.formatter = itemRefFormatter;
                    col.editor = itemECOEditor;
                } else if (colDef.editor === ColumnEditor.ecocapa) {
                    col.formatter = itemRefFormatter;
                    col.editor = itemECOCAPAEditor;
                } else {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    this.focsuable[idx + 1] = { focusable: this.settings.parameter.readonly_allowfocus };
                }
            } else {
                // @ts-ignore TODO: refactor to comply with the type
                col.readOnly = true;
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                this.focsuable[idx + 1] = { focusable: this.settings.parameter.readonly_allowfocus };
                if (colDef.editor === ColumnEditor.text) {
                    col.formatter = multiLineFormatterRich;
                } else if (colDef.editor === ColumnEditor.commentlog) {
                    col.formatter = multiLineFormatter;
                } else if (colDef.editor === ColumnEditor.eco) {
                    col.formatter = itemRefFormatter;
                } else if (colDef.editor === ColumnEditor.ecocapa) {
                    col.formatter = itemRefFormatter;
                } else if (colDef.editor === ColumnEditor.result) {
                    col.formatter = passFailFormatter;
                } else if (colDef.editor === ColumnEditor.select) {
                    // @ts-ignore TODO: refactor getSelectColOptions method to comply with the type
                    col.options = this.getSelectColOptions(colDef.options);
                    col.editor = selectCellEditor;
                    col.formatter = selectFormatter;
                } else if (colDef.editor === ColumnEditor.signaturemeaning) {
                    col.options = {
                        Author: "Author",
                        Reviewer: "Reviewer",
                        Approver: "Approver",
                        "Written By": "Written By",
                        "Reviewed By": "Reviewed By",
                        "Approved By": "Approved By",
                    };
                    if (mDHF && mDHF.getSignatureMeanings()) {
                        col.options = mDHF.getSignatureMeanings();
                    }
                    col.editor = selectCellEditor;
                    col.formatter = selectFormatter;
                } else if (colDef.editor === ColumnEditor.design) {
                    col.formatter = itemRefFormatter;
                    col.options = colDef.options;
                } else if (colDef.editor === ColumnEditor.uprules) {
                    col.formatter = itemRefFormatter;
                    col.editor = itemUpLinkEditor;
                    col.options = colDef.options;
                } else if (colDef.editor === ColumnEditor.downrules) {
                    col.formatter = itemRefFormatter;
                    col.options = colDef.options;
                } else if (colDef.editor === ColumnEditor.group) {
                    col.options = { select: "group" };
                    col.formatter = selectCellPopupSelectFormatter;
                } else if (colDef.editor && (<string>colDef.editor).indexOf("rules:") === 0) {
                    col.formatter = itemRefFormatter;
                    col.options = colDef.options;
                } else if (colDef.editor && (<string>colDef.editor).indexOf("category") === 0) {
                    col.formatter = itemRefFormatter;
                    col.options = colDef.options;
                }
            }
            this.columns.push(col);
        }
        // add row add/delete columns
        if (
            this.settings.canEdit &&
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            ml.JSON.isTrue(this.settings.parameter.canBeModified) &&
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            this.settings.parameter.fixRows <= 0
        ) {
            this.columns.push({
                id: "tools",
                name: "",
                width: 60,
                resizable: false,
                cssClass: "cell-effort-driven",
                cannotTriggerInsert: true,
                formatter: rowToolsFormatter,
            });
            this.rowToolsColumn = this.columns.length - 1;
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            this.focsuable[this.columns.length - 1] = { focusable: this.settings.parameter.readonly_allowfocus };
        }

        // configure and create grid
        this.data.getItemMetadata = function () {
            return {
                columns: that.focsuable,
            };
        };
        let grid_options: Slick.GridOptions<OldTableData> = {
            enableColumnReorder: undefined,

            editable: this.settings.canEdit,
            enableAddRow:
                this.settings.canEdit &&
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                ml.JSON.isTrue(this.settings.parameter.canBeModified) &&
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                this.settings.parameter.fixRows <= 0,
            forceFitColumns: true,
            asyncEditorLoading: false,
            autoHeight: true,
            enableCellNavigation: true,
            autoEdit: true,
            enableTextSelectionOnCells: true,
        };

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        if (this.settings.parameter.disableColumnReorder) {
            grid_options.enableColumnReorder = false;
        }
        this.grid = new Slick.Grid(this._list, this.data, this.columns, grid_options);

        // MATRIX-2027 the date picker control, steals the focus of the input cell underneath... so if the datepicker is still open don't do this
        // painful need to wait until datepicker is hidden....
        let waitForDatePickerClose = function () {
            if ($(".datepicker:visible").length) {
                window.setTimeout(function () {
                    waitForDatePickerClose();
                }, 100);
            } else {
                Slick.GlobalEditorLock.commitCurrentEdit();
                that.grid.resetActiveCell();
            }
        };

        $(".slick-viewport", this._list).on("blur", "input.editor-text", function () {
            let blurred = that.grid.getActiveCell();
            window.setTimeout(function () {
                let current = that.grid.getActiveCell();
                // if another editor was activated - no need to do an explicit commit
                if (
                    current &&
                    blurred.row == current.row &&
                    blurred.cell == current.cell &&
                    !that.editorActive &&
                    $(":focus").closest(".multiLineEditorContainer").length == 0
                ) {
                    // MATRIX-2027 the date picker control, steals the focus of the input cell underneath... so if the datepicker is still open don't do this
                    if ($(".datepicker:visible").length) {
                        waitForDatePickerClose();
                    } else {
                        Slick.GlobalEditorLock.commitCurrentEdit();
                        that.grid.resetActiveCell();
                    }
                }
            }, 100);
        });

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        if (!this.settings.canEdit || !ml.JSON.isTrue(this.settings.parameter.canBeModified)) {
            $($(".grid-canvas", this._list)[0]).off("mousedown");
        }

        this.grid.onBeforeEditCell.subscribe(function (e, args) {
            if (
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                !that.settings.parameter.limitEditRow ||
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                (that.settings.parameter.limitEditRow === "last" && args.row === that.grid.getDataLength() - 1) ||
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                (that.settings.parameter.limitEditRow === "first" && args.row === 0)
            ) {
                return true;
            } else {
                return false;
            }
        });

        // fix columns widths
        let availableWidth = 0;
        let lastWidth = 0;
        let cols = this.grid.getColumns();
        cols.forEach(function (col, cidx) {
            if (col.resizable) {
                let lastColumnWidth = Number(
                    globalMatrix.projectStorage.getItem("colWidth_" + that.settings.fieldId + "_" + cidx),
                );
                if (lastColumnWidth) {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    availableWidth += col.width;
                    lastWidth += lastColumnWidth;
                }
            }
        });
        if (lastWidth && availableWidth) {
            cols.forEach(function (col, cidx) {
                if (col.resizable) {
                    let lastColumnWidth = Number(
                        globalMatrix.projectStorage.getItem("colWidth_" + that.settings.fieldId + "_" + cidx),
                    );
                    let newWidth = ((lastColumnWidth ? lastColumnWidth : 100) * availableWidth) / lastWidth;
                    col.width = newWidth;
                }
            });
        }
        this.grid.resizeCanvas();

        // set up double click behaviour (calling external function)
        if (this.settings.onDblClick) {
            this.grid.onDblClick.subscribe(function (e: DOMEvent) {
                let cell = that.grid.getCellFromEvent(e);
                that.settings.onDblClick(cell.row, cell.cell, that.data[cell.row]);
            });
        }
        if (this.settings.onSelectCell) {
            that.grid.onClick.subscribe(function (e: DOMEvent) {
                let cell = that.grid.getCellFromEvent(e);
                that.settings.onSelectCell(cell.row, cell.cell, that.data[cell.row]);
            });
        }

        // configure print / readonly view
        if (
            this.settings.controlState === ControlState.Print ||
            this.settings.controlState === ControlState.Tooltip ||
            this.settings.controlState === ControlState.HistoryView ||
            this.settings.controlState === ControlState.Zen
        ) {
            this.settings.canEdit = false;
        }
        if (this.settings.controlState === ControlState.Print) {
            this._list.addClass("printNoBox");
            this._list.find(".slick-viewport").css("overflow-x", "hidden");
        }

        // attach event handlers
        this.grid.onSelectedRowsChanged.subscribe(function (e, args) {
            that.lastSelectedRows = args.rows;
            if (that.rowToolsColumn) {
                for (let idx = 0; idx < that.grid.getDataLength(); idx++) {
                    that.grid.updateCell(idx, that.rowToolsColumn);
                }
            }
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            if (that.settings.parameter.create) {
                $(".fa-arrow-up", that._list)
                    .click(that.insertAbove.bind(that))
                    .tooltip({ container: "body", title: "insert above" });
                $(".fa-file-import", that._list)
                    .click(function () {
                        that.importAbove(false);
                    })
                    .tooltip({ container: "body", title: "export selection / import above" });
                $(".fa-retweet", that._list)
                    .click(function () {})
                    .tooltip({ container: "body" });
            }
            $(".fa-times", that._list)
                .click(that.deleteRows.bind(that))
                .tooltip({ container: "body", title: "delete row" });
        });

        // @ts-ignore not sure where this method coming from
        this.grid.onRendered.subscribe(function () {
            if (
                that.settings.canEdit &&
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                ml.JSON.isTrue(that.settings.parameter.canBeModified) &&
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                that.settings.parameter.fixRows <= 0
            ) {
                let lastRow = $($(".slick-row", that._list)[$(".slick-row", that._list).length - 1]);
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                that.settings.parameter.columns.forEach(function (column: Slick.Column<OldTableData>, cidx: number) {
                    let text = "";
                    let css = "autoCell";
                    if (column.editor === ColumnEditor.self) {
                        text = matrixSession.getUser();
                    } else if (column.editor === ColumnEditor.user_self) {
                        css = "autoCellEdit";
                        text = matrixSession.getUser();
                    } else if (column.editor === ColumnEditor.today) {
                        text = ml.UI.DateTime.renderHumanDate(new Date(), true);
                    } else if (column.editor === ColumnEditor.date_today) {
                        css = "autoCellEdit";
                        text = ml.UI.DateTime.renderHumanDate(new Date(), true);
                    } else if (column.editor === ColumnEditor.current_version) {
                        text =
                            that.settings.item && that.settings.item.history && that.settings.item.history.length
                                ? that.settings.item.history[0].version.toString()
                                : "0";
                    }
                    if (text) {
                        $(".r" + (cidx + 1), lastRow)
                            .html("")
                            .append($("<span class='" + css + "'>").html(text));
                    }
                });
            }
        });

        // @ts-ignore not typed in the lib
        this.grid.setSelectionModel(new Slick.RowSelectionModel());

        // @ts-ignore not typed in the lib
        let moveRowsPlugin = new Slick.RowMoveManager({
            cancelEditOnDrag: true,
        });

        moveRowsPlugin.onBeforeMoveRows.subscribe(function (e: Event, data: { rows: any[]; insertBefore: number }) {
            for (let i = 0; i < data.rows.length; i++) {
                // no point in moving before or after itself
                if (data.rows[i] === data.insertBefore || data.rows[i] === data.insertBefore - 1) {
                    e.stopPropagation();
                    return false;
                }
            }
            return true;
        });

        moveRowsPlugin.onMoveRows.subscribe(function (e: unknown, args: { rows: any[]; insertBefore: number }) {
            that.rememberScroll();
            let extractedRows = [],
                left: any[],
                right: any[];
            let rows = args.rows;
            let insertBefore = args.insertBefore;
            left = that.data.slice(0, insertBefore);
            right = that.data.slice(insertBefore, that.data.length);
            rows.sort(function (a, b) {
                return a - b;
            });

            for (let i = 0; i < rows.length; i++) {
                extractedRows.push(that.data[rows[i]]);
            }

            rows.reverse();
            for (let i = 0; i < rows.length; i++) {
                let row = rows[i];
                if (row < insertBefore) {
                    left.splice(row, 1);
                } else {
                    right.splice(row - insertBefore, 1);
                }
            }

            that.data = left.concat(extractedRows.concat(right));
            let selectedRows = [];
            for (let i = 0; i < rows.length; i++) selectedRows.push(left.length + i);
            that.grid.resetActiveCell();
            that.grid.setData(that.data, false);
            that.grid.setSelectedRows(selectedRows);
            that.grid.render();
            that.gridChanged();

            that.updateRowHeights(0, 0);
            that.scrollBack();
        });

        this.grid.registerPlugin(moveRowsPlugin);

        this.grid.onDragInit.subscribe(function (e) {
            // prevent the grid from cancelling drag'n'drop by default
            e.stopImmediatePropagation();
        });

        this.grid.onDragStart.subscribe(function (e: DOMEvent, dd) {
            let cell = that.grid.getCellFromEvent(e);
            if (!cell) {
                return;
            }

            // @ts-ignore not sure about it
            dd.row = cell.row;
            // @ts-ignore not sure about it
            if (!that.data[dd.row]) {
                return;
            }

            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            if (Slick.GlobalEditorLock.isActive(undefined)) {
                return;
            }

            e.stopImmediatePropagation();
            // @ts-ignore not sure about it
            dd.mode = "recycle";
            let selectedRows = that.grid.getSelectedRows();
            // @ts-ignore not sure about it
            if (!selectedRows.length || $.inArray(dd.row, selectedRows) === -1) {
                // @ts-ignore not sure about it
                selectedRows = [dd.row];
                that.grid.setSelectedRows(selectedRows);
            }

            // @ts-ignore not sure about it
            dd.rows = selectedRows;
            // @ts-ignore not sure about it
            dd.count = selectedRows.length;
            return "";
        });

        this.grid.onDrag.subscribe(function (e, dd) {
            // @ts-ignore not sure about it
            if (dd.mode !== "recycle") {
                return;
            }
        });

        this.grid.onDragEnd.subscribe(function (e, dd) {
            // @ts-ignore not sure about it
            if (dd.mode !== "recycle") {
                return;
            }
        });

        (<any>$).drop({ mode: "mouse" });
        this.grid.onAddNewRow.subscribe(function (e, args) {
            if (
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                that.settings.parameter.maxRows !== -1 &&
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                that.grid.getDataLength() >= that.settings.parameter.maxRows
            ) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                ml.UI.showError("Warning", "Table can have only " + that.settings.parameter.maxRows + " rows");
                return;
            }
            that.rememberScroll();
            let item = {};
            $.extend(item, args.item);
            that.autoPopulate(item);
            that.data.push(item);
            that.grid.invalidateRows([that.data.length - 1]);
            that.grid.updateRowCount();
            that.grid.render();
            that.gridChanged();
            that.updateRowHeights(that.data.length - 1);
            that.scrollBack();
        });

        this.grid.onCellChange.subscribe(function (e, args) {
            that.gridChanged();
            if (typeof args.row !== "undefined") {
                that.rememberScroll();
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                if (that.settings.parameter.onCellChanged) {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    that.settings.parameter.onCellChanged(args);
                }

                ml.SmartText.showTooltips($(args.grid.getActiveCellNode()), false);
                that.updateRowHeights(args.row, args.row + 1);
                that.scrollBack();
            }
        });

        this.grid.onColumnsResized.subscribe(function () {
            let cols = that.grid.getColumns();
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            if (that.settings.parameter.onColumnsResized) that.settings.parameter.onColumnsResized();
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            if (that.settings.parameter.doNotRememberWidth) return;

            cols.forEach(function (col, cidx) {
                globalMatrix.projectStorage.setItem(
                    "colWidth_" + that.settings.fieldId + "_" + cidx,
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    col.width.toString(),
                );
            });
        });

        if (this.settings.controlState === ControlState.Print) {
            that.resizeItem(<number>(<unknown>"18cm"), true);
        }

        this._list.keyup(function (event) {
            that.copyPaste(event);
        });
    }

    saveData() {
        // must be called after initControl.
        let original = ml.JSON.clone(this.grid.getData());
        this._root.data("original", original);
        this._root.data("new", this.grid.getData());
    }

    copyPaste(event: JQueryKeyEventObject) {
        let that = this;
        if (globalMatrix.globalCtrlDown) {
            let rows = this.grid.getSelectedRows();
            let activeCell = this.grid.getActiveCell();

            if (rows.length > 1 || (rows.length == 1 && activeCell && activeCell.cell == 0)) {
                if (event.keyCode == 67) {
                    // ctrl-c
                    let gdata = this.grid.getData();
                    let select: any[] = [];
                    let selrows = this.grid.getSelectedRows().sort(function (a, b) {
                        return a - b;
                    });
                    // check if table has a uid column
                    let uidColumn = "";
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    this.settings.parameter.columns.forEach(function (col: Slick.Column<OldTableData>) {
                        if (col.editor == ColumnEditor.uid) {
                            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                            uidColumn = col.field;
                        }
                    });

                    selrows.forEach(function (selrow) {
                        let rowData = ml.JSON.clone(gdata[selrow]);

                        if (uidColumn) {
                            // remove the guid
                            rowData[uidColumn] = "";
                        }

                        select.push(rowData);
                    });

                    globalMatrix.serverStorage.setItem("copyPasteBufferTable", JSON.stringify(select), true);
                } else if (event.keyCode == 86 && globalMatrix.serverStorage.getItem("copyPasteBufferTable", true)) {
                    let newrows = JSON.parse(globalMatrix.serverStorage.getItem("copyPasteBufferTable", true));

                    let rowCount = newrows.length;

                    // make sure table rows can be added with copy paste only if row count can be modified
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    if (ml.JSON.isFalse(this.settings.parameter.canBeModified)) {
                        return;
                    }

                    // make sure table will not be not be bigger than allowed
                    if (
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        this.settings.parameter.maxRows !== -1 &&
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        this.grid.getDataLength() + rowCount > this.settings.parameter.maxRows
                    ) {
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        ml.UI.showError("Warning", "Table can have only " + this.settings.parameter.maxRows + " rows");
                        return;
                    }
                    // find place to insert
                    let insertAbove = rows[0];

                    newrows.forEach(function (row: any) {
                        // for each new row, build a row to insert
                        let newRow: Record<string, string> = {};
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        that.settings.parameter.columns.forEach(function (
                            col: Slick.Column<OldTableData>,
                            cidx: number,
                        ) {
                            if (col.editor !== "none") {
                                // for each editable column: copy fields with same field id's
                                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                if (row[that.settings.parameter.columns[cidx].field]) {
                                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                    newRow[that.settings.parameter.columns[cidx].field] =
                                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                        row[that.settings.parameter.columns[cidx].field];
                                }
                            }
                        });
                        that.autoPopulate(newRow);
                        // insert
                        that.grid.getData().splice(insertAbove++, 0, newRow);
                    });

                    this.grid.invalidate();
                    this.grid.updateRowCount();
                    this.grid.render();

                    // unfortunately need to render whole thing
                    this.updateRowHeights();
                    this.scrollBack();

                    this.gridChanged();
                }
            }
        }
    }
}
