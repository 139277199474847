import { IXPathTools, IXPathToolsComp } from "./MatrixLibInterfaces";

export { XPathTools };

class XPathTools implements IXPathTools {
    constructor() {}

    get(node: JQuery) {
        let comp: IXPathToolsComp,
            comps: IXPathToolsComp[] = [];

        let xpath = "";
        let getPos = function (element: Node) {
            let position = 1,
                curNode: Node;
            if (element.nodeType == Node.ATTRIBUTE_NODE) {
                return null;
            }
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            for (curNode = element.previousSibling; curNode; curNode = curNode.previousSibling) {
                if (curNode.nodeName == element.nodeName) {
                    ++position;
                }
            }
            return position;
        };
        let element: Node = node[0];
        if (element instanceof Document) {
            return "/";
        }
        for (
            ;
            element && !(element instanceof Document);
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            element = element.nodeType == Node.ATTRIBUTE_NODE ? (<Attr>element).ownerElement : element.parentNode
        ) {
            comp = comps[comps.length] = <IXPathToolsComp>{};
            switch (element.nodeType) {
                case Node.TEXT_NODE:
                    comp.name = "text()";
                    break;
                case Node.ATTRIBUTE_NODE:
                    comp.name = "@" + element.nodeName;
                    break;
                case Node.PROCESSING_INSTRUCTION_NODE:
                    comp.name = "processing-instruction()";
                    break;
                case Node.COMMENT_NODE:
                    comp.name = "comment()";
                    break;
                case Node.ELEMENT_NODE:
                    comp.name = element.nodeName;
                    break;
            }
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            comp.position = getPos(element);
        }
        for (let i = comps.length - 1; i >= 0; i--) {
            comp = comps[i];
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            xpath += "/" + comp.name.toLowerCase();
            if (comp.position !== null) {
                xpath += "[" + comp.position + "]";
            }
        }

        return xpath;
    }

    hardCopy(element: JQuery) {
        // note this is used only by selenium top copy the attributes of a doc
        $.each(element.find("select"), function (idx, select) {
            let selected = $(select).val();
            $(select).find("option").prop("selected", false);
            $(select).find("option").removeAttr("selected");
            $(select)
                .find("option[value='" + selected + "']")
                .prop("selected", true);
            $(select)
                .find("option[value='" + selected + "']")
                .attr("selected", "selected");
        });

        $.each(element.find("input[type='checkbox']"), function (idx, cb) {
            $(cb).is(":checked") ? $(cb).attr("checked", "checked") : $(cb).removeAttr("checked");
        });
        return element.html();
    }
}
