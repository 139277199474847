import { IDB, ItemConfiguration } from "../../../common/businesslogic/index";
import { IPlugin, IProjectPageParam, IPluginPanelOptions, plugins } from "../../../common/businesslogic/index";
import { ml } from "../../../common/matrixlib";
import { IBaseControlOptions } from "../../../common/UI/Controls/BaseControl";
import { IItem, globalMatrix, restConnection, ILink, app, matrixSession } from "../../../globals";

import {
    XRGetProject_StartupInfo_ListProjectAndSettings,
    XRGetProject_Needle_TrimNeedle,
    XRGetTodosAck,
    XRTrimNeedleItem,
    XRTodo,
} from "../../../RestResult";
import { FieldDescriptions } from "../../../common/businesslogic/FieldDescriptions";
import { NotificationsBL } from "../../../common/businesslogic/NotificationsBL";

export type { IXmlCharIssues };
export { Cleanup };
export { initialize };

interface IXmlCharIssues {
    itemId: string;
    details: string;
}

class Cleanup implements IPlugin {
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private item: IItem;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private jui: JQuery;
    static FIX_THE_ZOMBIE = "Fix the zombie link to ";
    static FIX_THE_IMAGE = "Fix the zombie image ";
    static FIX_INVALID_XML = "Find invalid xml";

    public static badEncodedChars = [
        // ok: #x9 | #xA | #xD | [#x20-#xD7FF] | [#xE000-#xFFFD] | [#x10000-#x10FFFF]

        "[\x00-\x08]",
        // OK: #x9 | #xA
        "[\x0B-\x0C]",
        // OK: #xD
        "[\x0E-\x1F]",
        // OK: [#x20-#xD7FF]
        "[\uD800-\uDFFF]",
        // OK: [#xE000-#xFFFD]
        "[\uFFFE-\uFFFF]",

        // some bad html characters NOT USED https://www.w3schools.com/tags/ref_charactersets.asp
        String.fromCharCode(129),
        String.fromCharCode(141),
        String.fromCharCode(143),
        String.fromCharCode(144),
        String.fromCharCode(157),
    ];

    // ****************************************
    // standard plugin interface
    // ****************************************

    public isDefault = true;
    constructor() {}
    initItem(_item: IItem, _jui: JQuery) {
        this.item = _item;
        this.jui = _jui;
    }

    initServerSettings(serverSettings: XRGetProject_StartupInfo_ListProjectAndSettings) {}

    updateMenu(ul: JQuery, hook: number) {
        return;
    }

    supportsControl(fieldType: string): boolean {
        return false;
    }

    createControl(ctrl: JQuery, options: IBaseControlOptions) {}

    initProject() {}

    // project pages show in the top in Projects, Reports and Documents
    getProjectPagesAsync(): Promise<IProjectPageParam[]> {
        return new Promise((resolve, reject) => {
            let that = this;
            let pages: IProjectPageParam[] = [];

            let extras = globalMatrix.ItemConfig.getExtrasConfig();
            if (extras && ml.JSON.isTrue(extras.cleanup)) {
                pages.push({
                    id: "CLEANUP",
                    title: "technical verification of project",
                    folder: "TOOLS",
                    order: 1000,
                    icon: "fal fa-washer",
                    usesFilters: true,
                    render: (options: IPluginPanelOptions) => that.renderProjectPage(options),
                });
            }
            resolve(pages);
        });
    }

    // ****************************************
    // Misc functions called by task control
    // to create / refresh UI components
    // ****************************************

    private renderProjectPage(options: IPluginPanelOptions) {
        let that = this;

        ml.UI.toggleFilters(false);

        let cleanUpTasks = $("<ul style='margin-top:6px'>");
        ml.UI.getPageTitle("Cleanup").appendTo(options.control);
        let page = $('<div class="panel-body-v-scroll fillHeight" style="padding:12px">').appendTo(options.control);

        $("<button title class='btn btn-success hidden-print'>Find zombie smart links</button>")
            .appendTo(page)
            .click(function () {
                cleanUpTasks.html("").append(ml.UI.getSpinningWait("finding zombie links"));
                that.runCleanupSmartZombies(cleanUpTasks);
            });
        $("<button style='margin-left:12px' title class='btn btn-success hidden-print'>Find zombie images</button>")
            .appendTo(page)
            .click(function () {
                cleanUpTasks.html("").append(ml.UI.getSpinningWait("finding zombie links"));
                that.runCleanupImageZombies(cleanUpTasks);
            });
        $(
            "<button style='margin-left:12px' title class='btn btn-success hidden-print'>Find invalid characters</button>",
        )
            .appendTo(page)
            .click(function () {
                cleanUpTasks.html("").append(ml.UI.getSpinningWait("finding invalid characters"));
                that.runCleanupCharacters(cleanUpTasks);
            });
        let results = $("<div style='padding-top:6px'>").appendTo(page);
        results.append(
            "<p><b>Zombie smart links</b> are smart links which point to items which don't exist (anymore).</p>",
        );
        results.append(
            "<p><b>Zombie images</b> are images which are on a third party server. These are problematic, because during the report generation, our server might not have the right to access these, so they will be missing in the generated documents.</p>",
        );
        results.append(
            "<p><b>Invalid characters</b> are characters which cannot be printed. In case data gets imported (maybe through the api or excel), some items might these unprintable characters and printing will fail.</p>",
        );

        results.append(cleanUpTasks).appendTo(page);
    }

    private runCleanupSmartZombies(cleanUpTasks: JQuery) {
        let that = this;

        app.getNeedlesAsync("category!=XTC", false, false, "*", false)
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            .done((needles: IItem[]) => {
                cleanUpTasks.html("");

                let zombies: ILink[] = [];

                let allItems = needles.map(function (needle) {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    return ml.Item.parseRef(needle.id).id;
                });
                let allFolders = that.getFolders(app.getTree());

                let all = allItems
                    .concat(allFolders)
                    .concat(app.getItemTitle("F-XTC-1") ? app.getChildrenIdsRec("F-XTC-1") : []);

                $.each(needles, function (idx, needle) {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    let item = ml.Item.parseRef(needle.id).id;

                    let smartLinks = that.getSmartLinks(needle);
                    if (smartLinks) {
                        $.each(smartLinks, function (fsl, sl) {
                            if (all.indexOf(sl) == -1) {
                                console.log("zombie: " + sl + " in id " + item);
                                let parsed = ml.Item.parseRef(sl);
                                let first = (parsed.isFolder ? "F-" : "") + parsed.type;

                                cleanUpTasks.append(
                                    "<li>" +
                                        item +
                                        " has a zombie link to <span>" +
                                        first +
                                        "-</span>" +
                                        parsed.number +
                                        "</li>",
                                );
                                zombies.push({ from: item, to: sl });
                            }
                        });
                    }
                });

                cleanUpTasks.highlightReferences();

                if (zombies.length) {
                    ml.UI.showConfirm(
                        -1,
                        {
                            title:
                                "Found " +
                                zombies.length +
                                " zombie links! These are smart links which point to non existing items.",
                            ok: "Create Notifications",
                            nok: "Review Manually",
                        },
                        () => {
                            restConnection.getProject("todo", true).done(async function (allNotifications) {
                                let oldFixIt = (allNotifications as XRGetTodosAck).todos.filter(function (todo) {
                                    if (NotificationsBL.getMessage(todo).indexOf(Cleanup.FIX_THE_ZOMBIE) != 0) {
                                        // an unrelated notification - ignore it
                                        return false;
                                    }
                                    for (let zidx = 0; zidx < zombies.length; zidx++) {
                                        if (
                                            zombies[zidx].from == todo.itemRef &&
                                            NotificationsBL.getMessage(todo) ==
                                                that.getZombieNotificationName(zombies[zidx].to)
                                        ) {
                                            // there is a zombie and a notification, no need to create a new one, nor to remove  the old
                                            zombies.splice(zidx, 1);
                                            return false;
                                        }
                                    }
                                    return true;
                                });
                                // now oldFixIt contains all the obsolete notifications and zombies all new ones
                                await that.createSmartZombieNotifications(zombies, 0);
                                that.removeNotifications(oldFixIt, 0);
                            });
                        },
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        null,
                    );
                } else {
                    ml.UI.showSuccess("no zombies found!");
                }
            })
            .fail(function () {
                ml.UI.showError("Error retrieving project data", "");
            });
    }

    // create a notification about a smart link being a zombie
    private async createSmartZombieNotifications(zombies: ILink[], idx: number) {
        let that = this;

        if (zombies.length <= idx) {
            return;
        }
        try {
            await NotificationsBL.createNotification(
                [matrixSession.getUser()],
                matrixSession.getProject(),
                zombies[idx].from,
                that.getZombieNotificationName(zombies[idx].to),
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                null,
                null,
            );
        } catch (e) {} // ignore errors
        try {
            await that.createSmartZombieNotifications(zombies, idx + 1);
        } catch (e) {} // ignore errors
        return;
    }

    private getZombieNotificationName(itemId: string) {
        return Cleanup.FIX_THE_ZOMBIE + itemId;
    }
    private getSmartLinks(needle: IItem) {
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        let categoryFields = globalMatrix.ItemConfig.getFields(needle.type);

        const excludedFieldTypes = [
            FieldDescriptions.Field_crosslinks,
            FieldDescriptions.Field_fileManager,
            FieldDescriptions.Field_signCache,
            FieldDescriptions.Field_risk2,
            FieldDescriptions.Field_publishedItemList,
        ];

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        let fieldsToScan = categoryFields.filter((field) => !excludedFieldTypes.includes(field.fieldType));

        if (fieldsToScan.length == 0) {
            return;
        }

        // define expression for smart links
        let regex = "((F-)*(/)*(" + globalMatrix.ItemConfig.getCategories(true).join("|") + ")-[0-9]+)";
        let re = new RegExp(regex, "g");

        // parse fields
        let links: string[] = [];
        fieldsToScan.forEach((fieldId) => {
            let fieldValue: string = needle[fieldId.id];
            let match = fieldValue ? fieldValue.match(re) : null;
            if (match) {
                match
                    .filter((m) => !m.startsWith("/"))
                    .forEach((m) => {
                        if (links.indexOf(m) === -1) {
                            links.push(m);
                        }
                    });
            }
        });
        return links;
    }
    private runCleanupImageZombies(cleanUpTasks: JQuery) {
        let that = this;

        app.getNeedlesAsync("category!=XXXXXX", false, false, "*", false)
            .done((resultsNeedles) => {
                cleanUpTasks.html("");

                let zombies: ILink[] = [];

                $.each(resultsNeedles, function (idx, needle) {
                    let item = needle.id;

                    let imageLinks = that.getImages(needle);
                    if (imageLinks) {
                        $.each(imageLinks, function (fsl, sl) {
                            console.log("zombie: " + sl + " in id " + item);

                            cleanUpTasks.append("<li>" + item + " has a zombie image '<span>" + sl + "'</li>");
                            zombies.push({ from: item, to: sl });
                        });
                    }
                });

                $("img", cleanUpTasks).addClass("cleanupImg");
                cleanUpTasks.highlightReferences();

                if (zombies.length) {
                    ml.UI.showConfirm(
                        -1,
                        {
                            title:
                                "Found " +
                                zombies.length +
                                " zombie images! These are images which point to an external file server.",
                            ok: "Create Notifications",
                            nok: "Review Manually",
                        },
                        () => {
                            restConnection.getProject("todo", true).done(async function (allNotifications) {
                                let oldFixIt = (allNotifications as XRGetTodosAck).todos.filter(function (todo) {
                                    if (NotificationsBL.getMessage(todo).indexOf(Cleanup.FIX_THE_IMAGE) != 0) {
                                        // an unrelated notification - ignore that
                                        return false;
                                    }
                                    for (let zidx = 0; zidx < zombies.length; zidx++) {
                                        if (
                                            zombies[zidx].from == todo.itemRef &&
                                            NotificationsBL.getMessage(todo) ==
                                                that.getZombieImageName(zombies[zidx].to)
                                        ) {
                                            // there is a zombie and a notification, no need to create a new one, nor to remove  the old
                                            zombies.splice(zidx, 1);
                                            return false;
                                        }
                                    }
                                    return true;
                                });
                                // now oldFixIt contains all the obsolete notifications and zombies all new ones
                                try {
                                    await that.createImageZombieNotifications(zombies, 0);
                                } catch (e) {
                                    console.warn(e);
                                }
                                that.removeNotifications(oldFixIt, 0);
                            });
                        },
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        null,
                    );
                } else {
                    ml.UI.showSuccess("no zombies found!");
                }
            })
            .fail(function () {
                ml.UI.showError("Error retrieving project data", "");
            });
    }

    // create a notification about a smart link being a zombie
    private async createImageZombieNotifications(zombies: ILink[], idx: number): Promise<void> {
        let that = this;

        if (zombies.length <= idx) {
            return;
        }
        try {
            await NotificationsBL.createNotification(
                [matrixSession.getUser()],
                matrixSession.getProject(),
                zombies[idx].from,
                that.getZombieImageName(zombies[idx].to),
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                null,
                null,
            );
        } catch (e) {
            console.warn(e);
        }
        try {
            await that.createSmartZombieNotifications(zombies, idx + 1);
        } catch (e) {
            console.warn(e);
        }
    }

    private getZombieImageName(link: string) {
        return Cleanup.FIX_THE_IMAGE;
    }

    private removeNotifications(oldFixIt: XRTodo[], idx: number) {
        let that = this;

        if (oldFixIt.length <= idx) {
            return;
        }
        NotificationsBL.deleteNotification(oldFixIt[idx]).then(function () {
            that.removeNotifications(oldFixIt, idx + 1);
        });
    }

    private getFolders(tree: IDB[]): string[] {
        let that = this;

        let folders: string[] = [];
        $.each(tree, function (idx, folderOrItem) {
            if (folderOrItem.children) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                folders.push(folderOrItem.id);
                folders = folders.concat(that.getFolders(folderOrItem.children));
            }
        });

        return folders;
    }
    // image links
    private getImages(item: IItem) {
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        let fields = globalMatrix.ItemConfig.getFields(ml.Item.parseRef(item.id).type);

        // define expression for smart links
        let quote = "['" + '"]+';
        let regexstr = "<img.*?src.*?=.*?" + quote + ".*?" + quote + ".*?>";
        let re = new RegExp(regexstr, "g");

        // parse fields
        let links: string[] = [];
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        for (let field of fields) {
            let fieldVal = item[field.id];
            let match = fieldVal ? fieldVal.match(re) : null;
            if (match) {
                $.each(match, function (midx, m) {
                    if (m.indexOf(globalMatrix.matrixBaseUrl) != -1) return; // local - no issue
                    if (m.indexOf("data:image") != -1) return; // embedded no issue
                    if (links.indexOf(m) == -1) links.push(m.replace(/\\"/g, '"'));
                });
            }
        }
        return links;
    }

    /*****************************************
     *
     * invalid xml characters
     *
     */

    private async runCleanupCharacters(cleanUpTasks: JQuery) {
        let that = this;
        cleanUpTasks.html("");

        for (let category of globalMatrix.ItemConfig.getCategories(true)) {
            let line = $(`<li>Testing category: ${category}</li>`).appendTo(cleanUpTasks);

            let xmlCharIssues: IXmlCharIssues[] = [];

            let results = <XRGetProject_Needle_TrimNeedle>(
                await restConnection.getProject(`needle?search=mrql:category=${category}&fieldsOut=*`)
            );

            for (let needle of results.needles) {
                let itemId = ml.Item.parseRef(needle.itemOrFolderRef).id;
                for (let fieldVal of needle.fieldVal) {
                    that.testXML(xmlCharIssues, itemId, fieldVal.id, JSON.stringify(fieldVal.value));
                }
                that.testXML(xmlCharIssues, itemId, 0, needle.title);
            }
            if (xmlCharIssues.length == 0) {
                line.append("<span> - no issues found</span>");
            }
            for (let issue of xmlCharIssues) {
                cleanUpTasks.append(
                    "<li>" + ml.Item.parseRef(issue.itemId).link + " has an issue " + issue.details + "</li>",
                );
            }
        }
    }

    static textOk(fieldVal: any) {
        if (!fieldVal) {
            return true;
        }

        let text = typeof fieldVal == "string" ? fieldVal : JSON.stringify(fieldVal);

        for (let j = 0; j < Cleanup.badEncodedChars.length; j++) {
            let matches = text.match(Cleanup.badEncodedChars[j]);
            if (matches) {
                return false;
            }
        }
        return true;
    }

    private testXML(xmlCharIssues: IXmlCharIssues[], itemId: string, fieldId: number, fieldVal: string) {
        for (let j = 0; j < Cleanup.badEncodedChars.length; j++) {
            if (fieldVal) {
                let matches = fieldVal.match(Cleanup.badEncodedChars[j]);
                if (!matches) {
                    try {
                        let parsed = JSON.parse(fieldVal);
                        matches = parsed.match(Cleanup.badEncodedChars[j]);
                    } catch (e) {
                        // not sire
                    }
                }
                if (matches) {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    let pos = matches["index"] - 10;
                    let cat = ml.Item.parseRef(itemId).type;
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    let fieldName = fieldId > 0 ? globalMatrix.ItemConfig.getFieldById(cat, fieldId).label : "title";
                    xmlCharIssues.push({
                        itemId: itemId,
                        details:
                            "field:'" +
                            fieldName +
                            "' pos:" +
                            matches["index"] +
                            " char:" +
                            matches[0] +
                            " near: " +
                            fieldVal.substr(pos < 0 ? 0 : pos, 20),
                    });
                }
            }
        }
    }
}

// register the engine as plugin
function initialize() {
    let mCleanup = new Cleanup();
    plugins.register(mCleanup);
}
