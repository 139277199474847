import { GenericDocAbstractFieldHandler } from "./GenericDocAbstractFieldHandler";
import { IDashboardParams } from "../../../../client/plugins/DocumentSections/Dashboard";
import { IPluginCoreForDashboard, IPluginFieldValueBase, plugins } from "../../PluginManager";
import { IDashboardParametersBase } from "./IDashboardParametersBase";

export interface IDashboardDocFieldValue extends IPluginFieldValueBase {
    value: IDashboardParams;
}

export class DashboardDocFieldHandler extends GenericDocAbstractFieldHandler {
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private currentDashboardValue: IDashboardDocFieldValue;

    getDefaultConfig(): any {
        return {};
    }

    constructor(sectionType: string, config: IDashboardParams, fieldValue: string | undefined) {
        super(sectionType, config, fieldValue);

        this.initData(fieldValue);
    }

    getXmlValue(): string {
        return JSON.stringify([this.currentDashboardValue]);
    }

    getData(): string | undefined {
        // A dashboard doesn't *really* have a value. But it has a cached html version of the
        // last time we saved based on the given configuration. So the html is the part that
        // matters.
        return JSON.stringify(this.currentDashboardValue);
    }

    getHtmlValue(): string {
        // Note it calls *super*.getData(), where the cached html is stored.
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        return super.getData();
    }

    /**
     * If you need to re-render the cached html, call this.
     * @returns valid html
     */
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    async getNewHtmlValue(): Promise<string> {
        let pluginsList = plugins.getPlugins();
        for (let p of pluginsList) {
            if ("Plugin" in p) {
                let plugin = <IPluginCoreForDashboard<IDashboardParametersBase>>p["Plugin"];
                if (plugin.getDashboardAsync) {
                    let dashboard = await plugin.getDashboardAsync();
                    if (dashboard.renderProjectPageWithParams) {
                        let dashboardDocFieldValue = this.getValue();
                        if (
                            dashboardDocFieldValue &&
                            dashboardDocFieldValue.value &&
                            dashboardDocFieldValue.value.dashboardID == plugin.getConfig().dashboard.id
                        ) {
                            // We have a match
                            if (dashboardDocFieldValue.value.dashboardParams) {
                                let html = await dashboard.renderProjectPageWithParams(
                                    dashboardDocFieldValue.value.dashboardParams,
                                    false,
                                );
                                return html.html();
                            }
                        }
                    }
                }
            }
        }
    }

    // TODO:
    // What is the difference between getValue() and getData()? So a dashboard doc has a "value" and a "data".
    // The value is dashboardParams, and the data is something like { value: ""; html: <cached html or empty string?> }
    getValue(): IDashboardDocFieldValue {
        return this.currentDashboardValue;
    }

    initData(serializedFieldData: string | undefined) {
        if (serializedFieldData) {
            this.currentDashboardValue = JSON.parse(serializedFieldData);
        } else {
            // TODO: this implies we can't determine if there is a no data situation.
            // We should probably set dashboardParams to undefined and deal with that.
            this.currentDashboardValue = { html: "", value: { dashboardID: "", dashboardParams: {} } };
        }
    }

    setDashboardID(newValue: string) {
        if (!this.currentDashboardValue) {
            this.currentDashboardValue = { html: "", value: { dashboardID: "", dashboardParams: {} } };
        }
        if (!this.currentDashboardValue.value) {
            this.currentDashboardValue.value = { dashboardID: "", dashboardParams: {} };
        }

        this.currentDashboardValue.value.dashboardID = newValue;
        console.log("Settings dahboard ID!!!");
    }

    setDashboardParams(newValue: IDashboardParametersBase) {
        if (!this.currentDashboardValue) {
            this.currentDashboardValue = { html: "", value: { dashboardID: "", dashboardParams: {} } };
        }
        if (!this.currentDashboardValue.value) {
            this.currentDashboardValue.value = { dashboardID: "", dashboardParams: {} };
        }
        this.currentDashboardValue.value.dashboardParams = newValue;
    }

    setHtmlValue(s: string) {
        this.currentDashboardValue.html = s;
    }
}
