/// <reference types="bootstrap-datepicker" />

import { ControlState, globalMatrix } from "../../../globals";
import { ml } from "../../matrixlib";
import { IBaseControlOptions, BaseControl } from "./BaseControl";
import { DateFieldHandler, FieldHandlerFactory, IDateSelectParamsBase } from "../../businesslogic";
import { IFieldHandler } from "../../businesslogic/FieldHandlers/IFieldHandler";
import { DropdownFieldHandler } from "../../businesslogic/FieldHandlers/DropdownFieldHandler";
import { FieldDescriptions } from "../../businesslogic/FieldDescriptions";

export type { IDateSelectParams, IDateSelectOptions };
export { DateSelectImpl };

interface IDateSelectParams extends IDateSelectParamsBase {
    allowClear?: boolean;
    readonly?: boolean;
    vertical?: string; // show it as drop up instead of drop down
    horizontal?: string; // align left / right / auto
    minDate?: Date; // to disable dates earlier
    requiresContent?: boolean;
    initialContent?: boolean; // set to true to set date to now
    inlineHelp?: string;
}

interface IDateSelectOptions extends IBaseControlOptions {
    parameter?: IDateSelectParams;
}

$.fn.dateselect = function (this: JQuery, options: IDateSelectOptions) {
    if (!options.fieldHandler) {
        options.fieldHandler = FieldHandlerFactory.CreateHandler(
            globalMatrix.ItemConfig,
            FieldDescriptions.Field_date,
            options,
        );
        options.fieldHandler.initData(options.fieldValue);
    }
    let baseControl = new DateSelectImpl(this, options.fieldHandler as DateFieldHandler);
    this.getController = () => {
        return baseControl;
    };
    baseControl.init(options);
    return this;
};

class DateSelectImpl extends BaseControl<DateFieldHandler> {
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private settings: IDateSelectOptions;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private ctrl: JQuery;
    private doesRequireContent = false;

    constructor(control: JQuery, fieldHandler: DateFieldHandler) {
        super(control, fieldHandler);
    }

    init(options: IDateSelectOptions) {
        let that = this;
        let defaultOptions = <IDateSelectOptions>{
            controlState: ControlState.FormView, // read only rendering
            dummyData: false, // fill control with a dumy text (for form design...)
            canEdit: false, // whether data can be edited
            valueChanged: function () {
                // callback to call if value changes
            },
            parameter: {
                readonly: false, // can be set to overwrite the default readonly status
                allowClear: false,
            },
        };
        this.settings = ml.JSON.mergeOptions(defaultOptions, options);

        (<DateFieldHandler>this.settings.fieldHandler).initData(this.settings.fieldValue);

        if (this.settings.fieldHandler === undefined) {
            // have default values
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            if (!this.settings.fieldValue && this.settings.parameter.initialContent && !this.settings.item) {
                let date = new Date();
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                (<DateFieldHandler>this.settings.fieldHandler).setDate(date);
            }
        }

        if (this.settings.controlState === ControlState.Print || this.settings.controlState === ControlState.Tooltip) {
            this._root.append(super.createHelp(this.settings));

            let css = this.settings.controlState === ControlState.Print ? "class='printBox'" : "";
            this._root.append("<div class='" + css + "'>" + this.renderHuman() + "</div>");

            return;
        }

        this._root.append(super.createHelp(this.settings));
        let ctrlContainer = $("<div>").addClass("baseControl");
        this._root.append(ctrlContainer);
        if (!this.settings.canEdit) {
            let ctrlx = $('<input autocomplete="off" class="lineInput form-control" type="text" readonly>').addClass(
                "dateSelect",
            );
            ctrlx.val(this.renderHuman());
            ctrlContainer.append(ctrlx);
            return;
        }

        if (options.parameter && options.parameter.requiresContent) {
            this.doesRequireContent = options.parameter.requiresContent;
        }

        this.ctrl = $('<input autocomplete="off" class="lineInput form-control datepickText" type="text" readonly />')
            .addClass("dateSelect")
            .val(this.renderHuman());
        this.ctrl.data("lastSelectedDate", (<DateFieldHandler>this.settings.fieldHandler).getDate());
        ctrlContainer.append(this.ctrl);
        if (this.settings.parameter && this.settings.parameter.allowClear) {
            this.ctrl.css("display", "inline");
            let clear = $('<span name="clear" class="fal fa-times-circle filter-clear-xin">');
            ctrlContainer.append(clear);
            clear.click(() => {
                that.ctrl.val("");
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                (<DateFieldHandler>that.settings.fieldHandler).setDate(null);
                that.ctrl.data("lastSelectedDate", (<DateFieldHandler>that.settings.fieldHandler).getDate());
                that.valueChanged();
            });
        }
        this.ctrl
            .datetimepicker({
                ignoreReadonly: true,
                format: ml.UI.DateTime.getSimpleDateFormatMoment(),
                widgetPositioning: {
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    horizontal: this.settings.parameter.horizontal ? this.settings.parameter.horizontal : "auto",
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    vertical: this.settings.parameter.vertical ? this.settings.parameter.vertical : "auto",
                },
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                minDate: this.settings.parameter.minDate,
            })
            .on("dp.change", (event: DatepickerEventObject) => {
                (<DateFieldHandler>that.settings.fieldHandler).setDate(new Date(event.date.toISOString()));

                that.ctrl.data("lastSelectedDate", (<DateFieldHandler>this.settings.fieldHandler).getDate());
                that.valueChanged();
            });
        this.ctrl.on("keyup", () => {
            window.setTimeout(that.valueChanged, 10);
        });
        let rt = this.ctrl.val();
        this._root.data("original", rt);
        this._root.data("new", rt);
    }

    // public interface
    async hasChangedAsync() {
        return this._root.data("original") !== this._root.data("new");
    }
    async getValueAsync() {
        try {
            let date = (<DateFieldHandler>this.settings.fieldHandler).getDate();
            if (!date) {
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                if (this.settings.parameter.allowClear) {
                    // the user might decide not to specify a date
                    return "";
                }
                // was not changed
                if (this._root.data("new")) {
                    date = new Date(this._root.data("new"));
                } else {
                    // item saved, though date was never created, use today instead!
                    date = new Date();
                }
            }
            return date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate();
        } catch (err) {
            let date = new Date();
            return date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate();
        }
    }
    setValue(date: string) {
        // parse string
        (<DateFieldHandler>this.settings.fieldHandler).setData(date);
        // update ui
        this.ctrl.val(this.renderHuman());
        this.ctrl.data("lastSelectedDate", (<DateFieldHandler>this.settings.fieldHandler).getDate());
        // set new value
        this._root.data("new", this.ctrl.val());
        this.valueChanged();
    }
    destroy() {
        if (this.ctrl) {
            this.ctrl.off();
        }
        $(".datepicker").remove();
    }
    resizeItem() {
        //Nothing to do
    }

    requiresContent() {
        return this.doesRequireContent;
    }

    // private functions
    private valueChanged() {
        this._root.data("new", this.ctrl.val());
        if (this.settings.valueChanged) {
            this.settings.valueChanged.apply(null);
        }
    }

    private renderHuman() {
        if ((<DateFieldHandler>this.settings.fieldHandler).getDate() === null) {
            return "";
        } else {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            return ml.UI.DateTime.renderHumanDate((<DateFieldHandler>this.settings.fieldHandler).getDate(), true);
        }
    }
}
