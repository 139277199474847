/// <reference types="matrixrequirements-type-declarations" />

import { ICategorySettingTextIcon } from "../../../ProjectSettings";
import { IDB } from "../../businesslogic/index";
import { ml } from "../../matrixlib";
import { MyNode, MyNodeData, SelectMode, MyFancytreeOption, MyFancytree } from "./ProjectViewDefines";
import { ProjectView } from "./ProjectView";
import { mTM } from "../../businesslogic/index";
import { NavBar } from "./NavigationBar";
import { IStringMap, globalMatrix, app } from "../../../globals";
import { EImportMode } from "../../businesslogic/ComponentImport";

export type { ISearchCounts, ISearchCountsTab };
export { ProjectTree };

interface ISearchCounts {
    current: number; // number of hits in current tab of navigation bar is visible (or all otherwise)
    total: number; // total number
    perTab: ISearchCountsTab[]; // count per tab
}
interface ISearchCountsTab {
    tabName: string;
    count: number;
}

class ProjectTree {
    private hits: string[] = [];
    private allHits: string[] = [];
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private lastFilterFct: (node: Fancytree.FancytreeNode) => boolean;
    private panel: ProjectView;
    private canBeFiltered: boolean;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private delayedFilter: number;
    // performance stuff for tree
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private lt: string; // last added type
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private lit: string; // last added type's icon text

    private legacyColors: IStringMap = {
        REQ: "#0000a4", // UIToolsConstants.CIColors.BlueEastBay.color,
        UC: "#41c9dd", //UIToolsConstants.CIColors.BlueLagoon.color,
        SPEC: "#00a600", //UIToolsConstants.CIColors.GreenSushi.color,
        DOC: "#2a6ac0", //UIToolsConstants.CIColors.Black.color,
        SIGN: "#2a6ac0", //UIToolsConstants.CIColors.Black.color,
        REPORT: "#2a6ac0", //UIToolsConstants.CIColors.Black.color,
        RISK: "#a90000", // UIToolsConstants.CIColors.RedPersimmon.color,
        TC: "#41c9dd", // UIToolsConstants.CIColors.GreyDark.color
        TRUN: "#faebd7", // special case for legacy TRUN folder icons
        XTC: "#faebd7", // special case for legacy TRUN folder icons
    };
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    public alpha: 40;

    constructor(panel: ProjectView, canBeFiltered: boolean) {
        this.panel = panel;
        this.canBeFiltered = canBeFiltered;
    }
    // show the tree
    show() {
        this.panel.tree.show();
    }
    // hide the tree
    hide() {
        this.panel.tree.hide();
    }

    setSelectedItems(selectedItems: string[]) {
        let that = this;
        $.each(selectedItems, function (index, key) {
            let item = that.getNode(key);
            if (item) {
                item.setSelected();
            }
        });
    }

    applyFilter() {
        let that = this;
        if (this.canBeFiltered && this.lastFilterFct) {
            clearTimeout(this.delayedFilter);
            that.delayedFilter = window.setTimeout(() => {
                this.getFancyTree().filterNodes((node) => this.lastFilterFct(node));
            }, 100);
        }
    }

    // hotfix one item - not good after the next change in the tree it will be rendered again wrongly
    redrawItem(itemId: string) {
        let node = this.getNode(itemId);
        if (node) {
            node.render();
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            let span = $((<MyNode>node).span);
            if ((<MyNodeData>node.data).isUnselected) {
                span.removeClass("fancytree-match");
            } else {
                span.addClass("fancytree-match");
            }
        }
    }
    openTree(key: string) {
        return this.getNode(key).setExpanded(true);
    }
    closeTree(key: string) {
        this.getNode(key).setExpanded(false);
    }

    selectAll(selected: boolean) {
        // TODO: potentially problematic on a big trees, consider updating the lib to get "selectAll" method
        // https://wwwendt.de/tech/fancytree/doc/jsdoc/Fancytree.html#selectAll
        this.getFancyTree().visit((node) => {
            node.setSelected(selected);
        });
    }

    selectMatched(selected: boolean) {
        $.each(this.hits, (hi, hit) => {
            if (this.panel.settings.selectMode == SelectMode.auto || !ml.Item.parseRef(hit).isFolder) {
                const item = this.getNode(hit);

                if (item) {
                    item.setSelected(selected);
                }
            }
        });
    }

    // this selects children and in case a folder is selected also the children of the folder
    selectChildren(node: Fancytree.FancytreeNode) {
        let children = node.getChildren();
        if (!children) {
            return;
        }
        for (let child of children) {
            if (!child.isSelected()) {
                child.setSelected(true);
            }
            if (child.isFolder()) {
                this.selectChildren(child);
            }
        }
    }
    unSelectChildren(node: Fancytree.FancytreeNode) {
        let children = node.getChildren();
        if (!children) {
            return;
        }
        for (let child of children) {
            if (child.isSelected()) {
                child.setSelected(false);
            }
            if (child.isFolder()) {
                this.unSelectChildren(child);
            }
        }
    }
    // this unselects all parents of a item or folder
    unselectParents(node: Fancytree.FancytreeNode) {
        let parent = node.getParent();
        if (!parent) {
            return;
        }
        if (parent.isSelected()) {
            parent.setSelected(false);
            this.unselectParents(parent);
        }
    }

    setHideMismatches(hideMismatches: boolean) {
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        let currentMode = (<MyFancytreeOption>(<MyFancytree>this.getFancyTree()).options).filter.mode;
        let nextMode = hideMismatches ? "hide" : "dimm";

        if (currentMode === nextMode) {
            return;
        }

        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        (<MyFancytreeOption>(<MyFancytree>this.getFancyTree()).options).filter.mode = nextMode;
        this.getFancyTree().clearFilter();
    }

    forcePartial() {
        function _walk(node: Fancytree.FancytreeNode) {
            let i,
                l,
                child,
                someSelected,
                children = node.children;

            if (children && children.length) {
                // check all children recursively
                someSelected = false;

                for (i = 0, l = children.length; i < l; i++) {
                    child = children[i];
                    // the selection state of a node is not relevant; we need the end-nodes
                    if (_walk(child)) {
                        someSelected = true;
                    }
                }

                // @ts-ignore TODO: do not satisfy the type. check it
                if (someSelected && !node.selected) {
                    // @ts-ignore TODO: do not satisfy the type. check it
                    $(node.span).addClass("fancytree-partsel");
                    node.extraClasses = "fancytree-partsel";
                } else {
                    // @ts-ignore TODO: do not satisfy the type. check it
                    $(node.span).removeClass("fancytree-partsel");
                    node.extraClasses = "";
                }
            }
            // @ts-ignore TODO: do not satisfy the type. check it
            return someSelected || !!node.selected;
        }

        let tree = this.getFancyTree();
        _walk(tree.rootNode);
    }
    filterTree(match?: string): ISearchCounts {
        let that = this;

        this.hits = [];
        this.allHits = [];
        let total = 0;
        if (match) {
            let treeMatch = (match + "").replace(/([.?*+^$[\]\\(){}|-])/g, "\\$1"); // make sure a '.' is treated literally
            let treeMatchRegEx = new RegExp(".*" + treeMatch + ".*", "i");

            this.lastFilterFct = function treeFilter(node: Fancytree.FancytreeNode) {
                if ((<MyNodeData>node.data).isUnselected) {
                    // project label filter
                    return false;
                }

                let sel =
                    (!that.panel.prefixCategory || ml.Item.parseRef(node.key).type == that.panel.prefixCategory) &&
                    !!treeMatchRegEx.exec(node.title);

                if (sel) {
                    this.allHits.push(node.key);
                    total++;
                    if (that.panel.settings.isMainTree) {
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        sel = NavBar.isInCurrentTab(node.key);
                    }
                    if (sel) {
                        that.hits.push(node.key);
                    }
                }

                return sel;
            };

            this.canBeFiltered &&
                (<Fancytree.Fancytree>this.getFancyTree()).filterNodes((node: Fancytree.FancytreeNode) =>
                    this.lastFilterFct(node),
                );

            // after rendering the tree: maybe open to see all leafs
            if (this.panel.viewModeSelector.isExpandTree()) {
                this.getFancyRootNode().visit(function (node: Fancytree.FancytreeNode) {
                    if ((node.isFolder() && (<MyNode>node).subMatch) || !that.panel.viewModeSelector.hideMismatches()) {
                        node.setExpanded((<MyNode>node).subMatch ? true : false, {
                            noAnimation: true,
                            noEvents: true,
                            scrollIntoView: false,
                        });
                    }
                });
            }
            return { current: this.hits.length, total: total, perTab: NavBar.countPerTab(this.allHits) };
        } else {
            // just in case
            let total = this.removeFilter();
            return { current: total, total: total, perTab: NavBar.countPerTab(this.hits) };
        }
    }

    removeFilter() {
        let that = this;

        this.hits = [];

        // just apply global filters (no expanding)
        this.lastFilterFct = function treeFilter(node: Fancytree.FancytreeNode) {
            if ((<MyNodeData>node.data).isUnselected) {
                // project label filter
                return false;
            }
            that.hits.push(node.key);
            return true;
        };

        this.canBeFiltered && this.getFancyTree().filterNodes((node) => this.lastFilterFct(node));
        return this.hits.length;
    }
    showSearchResults(serverSearchResults: string[]): ISearchCounts {
        let that = this;

        this.hits = [];
        let countAll = 0;

        this.lastFilterFct = function treeFilter(node: Fancytree.FancytreeNode) {
            if ((<MyNodeData>node.data).isUnselected) {
                return false;
            }

            if (serverSearchResults.length > 0) {
                let sel = serverSearchResults.indexOf(node.key) !== -1;
                if (sel) {
                    countAll++;
                    if (that.panel.settings.isMainTree) {
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        sel = NavBar.isInCurrentTab(node.key);
                    }
                    if (sel) {
                        that.hits.push(node.key);
                    }
                }
                return sel;
            }
            return false;
        };

        this.canBeFiltered && this.getFancyTree().filterNodes((node) => this.lastFilterFct(node));

        // after rendering the tree: maybe open to see all leafs
        if (this.panel.viewModeSelector.isExpandTree()) {
            this.getFancyRootNode().visit(function (node: Fancytree.FancytreeNode) {
                node.setExpanded((<MyNode>node).subMatch ? true : false);
            });
        }
        return { current: this.hits.length, total: countAll, perTab: NavBar.countPerTab(serverSearchResults) };
    }

    getFancyRootNode(): Fancytree.FancytreeNode {
        return <Fancytree.FancytreeNode>this.panel.tree.fancytree("getRootNode");
    }
    getFancyTree(): Fancytree.Fancytree {
        return <Fancytree.Fancytree>this.panel.tree.fancytree("getTree");
    }
    getNode(key: string): Fancytree.FancytreeNode {
        return this.getFancyTree().getNodeByKey(key);
    }
    removeNode(key: string) {
        this.getNode(key).remove();
    }

    setTitle(key: string, title: string): boolean {
        try {
            let node = <MyNode>this.getNode(key);
            if (node) {
                let changed = title !== node.shortTitle;

                if (node.data) {
                    if (title == (<MyNodeData>node.data).shortTitle) {
                        changed = false;
                    }
                    let foldertext = $(".refTitle span:not(.highlight)", node.span);
                    if (node.folder && foldertext.length !== 0) {
                        (<MyNodeData>node.data).shortTitle = foldertext[0].outerHTML + title;
                    } else {
                        (<MyNodeData>node.data).shortTitle = title;
                    }
                }
                node.shortTitle = title;
                node.title = node.folder ? title : node.key + " " + title;

                return changed;
            }
        } catch (exp) {}
        return false;
    }

    select(key: string) {
        let ft = this.getFancyTree();

        NavBar.activateItemsTab(key);
        if (this.panel.tree.is(":visible")) {
            // if the tree is not visible it throws an exception, because of option to auto activate
            ft.activateKey(key);
        } else {
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            (<MyFancytree>ft).options.autoScroll = false;
            ft.activateKey(key);
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            (<MyFancytree>ft).options.autoScroll = true;
        }
    }

    isSelected(key: string): boolean {
        let treeNode = this.getNode(key);
        return treeNode.isActive();
    }

    // private functions

    updateRec(item: IDB) {
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        let treeNode = this.getNode(item.id);
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        treeNode.title = item.title;
        if (item.children) {
            // recreate children
            treeNode.children = [];
            this.addNodes(treeNode, item.children);
        }
    }
    insertRec(parentKey: string, item: IDB) {
        let parent = this.getNode(parentKey);
        if (!parent.children) {
            parent.children = [];
        }

        for (let idx = 0; idx < parent.children.length; idx++) {
            if (parent.children[idx].key === item.id) {
                // replace child
                this.updateRec(item);
                return;
            }
        }
        // create a new child and replace
        let nn = this.addNode(parent, item);
        this.updateRec(item);
    }
    moveNode(parentId: string, itemId: string, position: number) {
        let parentNode = this.getNode(parentId);

        let itemNode = this.getNode(itemId);
        // remove from current parent
        let oldParent = itemNode.parent;
        oldParent.children = oldParent.children.filter(function (child) {
            return child.key != itemId;
        });
        // insert in new place
        if (!parentNode.children) {
            parentNode.children = [];
        }
        if (position >= parentNode.children.length) {
            parentNode.children.push(itemNode);
        } else {
            parentNode.children.splice(position, 0, itemNode);
        }
        itemNode.parent = parentNode;
    }
    addNode(treeNode: Fancytree.FancytreeNode, item: IDB, position?: { at: number }): Fancytree.FancytreeNode {
        let newNode: Fancytree.FancytreeNode;

        if (item.hasOwnProperty("children") === true) {
            // a folder

            if (item.type !== this.lt) {
                this.lit = "";
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                let param = <ICategorySettingTextIcon>globalMatrix.ItemConfig.getCategorySetting(item.type, "texticon");
                if (param) {
                    param = <any>ml.JSON.mergeOptions({ color: "black", text: item.type }, param);
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    let color = ml.UI.standardizeColor(param.color, this.alpha); // add alpha
                    let style = "";
                    // if( item.id.endsWith("-1") && item.id.startsWith("F-") )
                    style = `style="background-color: ${color};"`;
                    this.lit = '<span class="fancytree-icontext" ' + style + ">" + param.text + "</span>";
                }
            }

            let icontext = this.lit;
            let hasIconText = icontext;
            // hack for test runs of type TRUN
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            if (typeof mTM !== "undefined" && mTM.isXTC(item.type)) {
                // this is a XTC type
                if (treeNode.parent) {
                    // not the top level TRUN but a TRUN,...

                    icontext = icontext
                        ? icontext
                        : '<span class="fancytree-icontext fancytree-iconxtc"  style="color: ' +
                          "grey" +
                          ';">' +
                          "RUN" +
                          "</span>";
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    let tp = item.title.indexOf("(F-"); // e.g. The system is delivered (F-TC-6)
                    if (!hasIconText && tp !== -1) {
                        // most likely this is an executed folder... try to get information from where it comes from
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        let te = item.title.indexOf("-", tp + 3);
                        if (te !== -1) {
                            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                            let category = item.title.substr(tp + 3, te - tp - 3); // -> TC
                            let source = app.getTree([category]);
                            if (source && source.length === 1) {
                                item.icon = source[0].icon;
                                icontext = "";
                                let param = <ICategorySettingTextIcon>(
                                    globalMatrix.ItemConfig.getCategorySetting(category, "texticon")
                                );
                                if (param) {
                                    param = <any>ml.JSON.mergeOptions({ color: "black", text: category }, param);
                                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                                    let color = ml.UI.standardizeColor(param.color, this.alpha); // add alpha
                                    icontext =
                                        '<span class="fancytree-icontext fancytree-iconxtc" style="background-color: ' +
                                        color +
                                        '; b">' +
                                        param.text +
                                        "</span>";
                                }
                            }
                        }
                    }
                } else {
                    icontext = icontext
                        ? icontext
                        : '<span class="fancytree-icontext fancytree-iconxtc" style="background-color: ' +
                          this.legacyColors["TRUN"] +
                          ';">' +
                          "TRUN" +
                          "</span>";
                }
            } else if (!hasIconText && item.type && this.legacyColors[item.type]) {
                let color = ml.UI.standardizeColor(this.legacyColors[item.type], this.alpha); // add alpha

                icontext =
                    '<span class="fancytree-icontext fancytree-iconxtc" style="background-color: ' +
                    color +
                    ';">' +
                    item.type +
                    "</span>";
            }
            let nodeData = <Fancytree.NodeData>{
                shortTitle: icontext + item.title, // as displayed
                title: item.title, // this is not displayed but used for searches
                key: item.id,
                type: item.type,
                background: item.background,
                border: item.border,
                cstrender: true,
                folder: true,
                hideCheckbox:
                    this.panel.settings.selectMode == SelectMode.none ||
                    this.panel.settings.selectMode == SelectMode.items ||
                    this.panel.settings.selectMode == SelectMode.singleItem,
                isUnselected: item.isUnselected,
                icon: icontext ? false : item.icon,
                extraStyle: item.extraStyle,
                mode: item.mode, // copy mode over if it's set (it's coming from the server: EImportMode.Copy or EImportMode.Import)
            };

            if (item.iconClass) {
                (<any>nodeData).icon = true;
                nodeData.iconclass = item.iconClass + " fancy-icon";
            }
            if (item.mode == EImportMode.Include || item.mode == EImportMode.IncludeRoot) {
                (<MyNodeData>nodeData).shortTitle =
                    `<i class="fal fa-external-link-alt importedFolder"></i>` + (<MyNodeData>nodeData).shortTitle;
            }
            newNode = treeNode.addChildren(nodeData);
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            this.addNodes(newNode, item.children);

            let hasNotLatest = false;
            $.each(newNode.children, function (cidx, child) {
                if (child.extraClasses && child.extraClasses.indexOf("notLatest") != -1) {
                    hasNotLatest = true;
                }
            });
            if (hasNotLatest) {
                newNode.extraClasses = newNode.extraClasses ? newNode.extraClasses + "notLatest" : "notLatest";
            }
        } else {
            let fn = <Fancytree.NodeData>{
                shortTitle: item.title, // as displayed
                title: item.id + " " + item.title, // this is not displayed but used for searches
                key: item.id,
                cstrender: true,
                type: item.type,
                isUnselected: item.isUnselected,
                extraStyle: item.extraStyle,
                mode: item.mode, // copy mode over if it's set
            };

            if (item.icon) {
                fn.icon = item.icon;
                fn.extraClasses = "fancy-icon";
            }
            if (item.iconClass) {
                fn.iconclass = item.iconClass + " fancy-icon";
            }
            if (item.mode == EImportMode.Include) {
                (<any>fn).icon = true;
                fn.iconclass = "fal fa-external-link-alt importedItem";
            }
            if (item.version && item.version.split("/")[0] != item.version.split("/")[1]) {
                fn.extraClasses = fn.extraClasses ? fn.extraClasses + "notLatest" : "notLatest";
            }
            newNode = treeNode.addChildren(fn);
        }

        if (position && position.at < treeNode.children.length) {
            let newChild = treeNode.children[treeNode.children.length - 1];
            treeNode.children.splice(treeNode.children.length - 1, 1);
            treeNode.children.splice(position.at, 0, newChild);
        }

        return newNode;
    }

    treeFromDb(dbTree: IDB[]) {
        this.lt = "";
        this.addNodes(this.getFancyRootNode(), dbTree);
    }

    updateItemIsUnselected(itemId: string, isUnselected: boolean): boolean {
        try {
            let node = this.getNode(itemId);
            if (node) {
                // sometimes the node does not yet exist, e.g. when creating a signed doc
                if ((<MyNodeData>node.data).isUnselected !== isUnselected) {
                    (<MyNodeData>node.data).isUnselected = isUnselected;
                    return true;
                }
            }
        } catch (exp) {
            // mobile client has no tree...
        }
        return false;
    }

    updateNotificationCounters() {
        (<any>this.getFancyTree()).updateNotificationCounters();
    }

    private addNodes(treeNode: MyNodeData, obj: IDB[]) {
        let that = this;
        $.each(obj, function (idx: number, idb: IDB) {
            that.addNode(treeNode, idb);
        });
    }
}
