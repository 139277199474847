import { app, ControlState, globalMatrix, matrixSession } from "../../../globals";
import { MR1, IItemChangeEvent, GateFieldHandler, FieldHandlerFactory } from "../../businesslogic/index";
import { IBaseControlOptions, BaseControl } from "./BaseControl";
import { ml } from "./../../matrixlib";
import { UIToolsConstants } from "../../matrixlib/MatrixLibInterfaces";
import {
    IBaseGateOptions,
    IGateLineBase,
    IGateStatusLine,
    IGateStatus,
} from "../../businesslogic/FieldHandlers/GateFieldHandler";
import { FieldDescriptions } from "../../businesslogic/FieldDescriptions";
import { NotificationsBL } from "../../businesslogic/NotificationsBL";

export type { IGateControlControlOptions, IGate, IGateLine };
export { GateControlImpl };

interface IGateControlControlOptions extends IBaseControlOptions {
    controlState?: ControlState;
    canEdit?: boolean;
    help?: string;
    fieldValue?: string;
    valueChanged?: Function;
    parameter: IGate;
}

/** define behavior of a gate */
interface IGate extends IBaseGateOptions {
    /** define different reviews/approvals which need to be made for gate to pass */
    lines: IGateLine[];
    /** if set to true the user can add some comment when approving */
    hasComments: boolean;
    /** defines behavior when all reviews/approvals have passed */
    /** can be undefined when gate is not configured properly */
    allPass?: {
        /** locks the fields above the gate if all reviews/approvals have passed */
        lockAbove: boolean;
        /** enables the fields below the gate if all reviews/approvals have passed */
        enableBelow: boolean;
        /** sets the specified labels if all reviews/approvals have passed */
        setLabels?: string[];
        /** hides the tools menu if all reviews/approvals have passed */
        hideMenu?: boolean | string[]; // list of tool menu items to hide
        /** hides the reference view if all reviews/approvals have passed */
        hideReferences?: boolean; // whether to lock reference view
        /** locks the title if all reviews/approvals have passed */
        lockTitle?: boolean; // disable edit of title
        /** disables delete if all reviews/approvals have passed */
        lockDelete?: boolean; // disable delete
        /** beta: notify by email that gate has passed */
        notifyEmailPassed?: string[];
        /** beta: customize the email notification when that gate has passed (used canned message, when not specified)*/
        notifyEmailTemplate?: string;
        /* notify by notification that gate has passed */
        notifyPassed?: string[];
        notifyFirstReject?: string[];
    };
    /* notify by notification that gate has been rejected */
    notifyFirstReject?: string[];
    /** text to show in printed reports if all reviews/approvals have passed */
    printAllPassed: string;
    /** text to show in printed reports if reviews/approvals has been rejected */
    printNotPassed: string;
    /** text to show in printed reports if reviews/approvals still need to be finished */
    printTodo: string;
    // any of these users can pass or fail the gate
    /** button allowing to approve a gate, leave empty ("") to hide*/
    pass: string;
    /** button allowing to reject a gate, leave empty  ("") to hide*/
    fail: string;
    /** text to show instead of pass button if gate was approved, leave empty ("") to use same as pass, set to "hide" to hide the button in this state*/
    passPassed?: string;
    /** text to show instead of pass button if gate was rejected, leave empty ("") to use same as pass, set to "hide" to hide the button in this state */
    passFailed?: string;
    /** text to show instead of fail button if gate was approved, leave empty ("") to use same as fail, set to "hide" to hide the button in this state */
    failPassed?: string;
    /** text to show instead of fail button if gate was rejected, leave empty ("") to use same as fail, set to "hide" to hide the button in this state */
    failFailed?: string;
    /** ask for signature to approve a gate */
    requireSignature?: boolean;
    /** ask for signature to reject a gate */
    requireSignatureReject?: boolean;
    /**  reset gate if any of these fields change. enter field names or ids  */
    reset?: string[];
    // standard options
    readOnly?: boolean;
    // 2.3
    /** legacy mode (don't show in UI and printed documents who approved/rejected a line) */
    hideApprovalInfo?: boolean;
    /** show a line per given signature when printing */
    printSignaturesApproved?: boolean;
    /** show a line per missing signature when printing */
    printSignaturesRequired?: boolean;
    /** show a line per rejected signature when printing */
    printSignaturesRejected: boolean;
    /**   if set to true only allow a user to sign only one line in a gate  */
    strictSign?: boolean;
}

// define requirements for one line in gate
interface IGateLine extends IGateLineBase {
    // 2.3
    /** info to show before user (e.g. to hint what the approval means) */
    hint?: string;
    /** info to show before user (... once approved) */
    hintDone?: string;
    /** info to show before user (... if rejected) */
    hintRejected?: string;
}

$.fn.gateControl = function (this: JQuery, options: IGateControlControlOptions) {
    if (!options.fieldHandler) {
        //No need for a field handler here, so let's create a dummy one.
        options.fieldHandler = FieldHandlerFactory.CreateHandler(
            globalMatrix.ItemConfig,
            FieldDescriptions.Field_gateControl,
            options,
        );
        options.fieldHandler.initData(options.fieldValue);
    }
    let baseControl = new GateControlImpl(this, options.fieldHandler as GateFieldHandler);
    this.getController = () => {
        return baseControl;
    };
    baseControl.init(options);

    return this;
};

class GateControlImpl extends BaseControl<GateFieldHandler> {
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private settings: IGateControlControlOptions;

    constructor(control: JQuery, fieldHandler: GateFieldHandler) {
        super(control, fieldHandler);
    }

    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private uiCtrl: JQuery;
    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
    private triggerUpdate: number;

    init(options: IGateControlControlOptions) {
        let that = this;

        let defaultOptions = {
            controlState: ControlState.FormView, // read only rendering
            dummyData: false, // fill control with a dummy text (for form design...)
            canEdit: false, // whether data can be edited
            valueChanged: function () {
                // callback to call if value changes
            },
            parameter: {
                readonly: false, // can be set to overwrite the default readonly status
            },
        };
        this.settings = <IGateControlControlOptions>ml.JSON.mergeOptions(defaultOptions, options);
        if (!this.settings.parameter.lines || !this.settings.parameter.allPass) {
            this._root.append(
                "<p style='color:red'>Control for <b>'" +
                    this.settings.help +
                    "'</b> of type <b>'" +
                    this.settings.fieldType +
                    "'</b> is badly configured!</p>",
            );
            return;
        }
        // initialize object
        (<GateFieldHandler>that.settings.fieldHandler).initData(this.settings.fieldValue);
        // render control
        this._root.append(super.createHelp(options));
        this.uiCtrl = $("<div class='baseControl'>").appendTo(this._root);

        this.showControl();
        // lock extra things
        if (
            (this.settings.controlState == ControlState.FormEdit ||
                that.settings.controlState == ControlState.FormView) &&
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            (<GateFieldHandler>that.settings.fieldHandler).getGateValue().passed
        ) {
            if (this.settings.parameter.allPass.hideMenu == true) {
                // hide all tool menus
                $("#shareButton").hide();
            } else if (typeof this.settings.parameter.allPass.hideMenu == "object") {
                for (let menuEntry of this.settings.parameter.allPass.hideMenu) {
                    // hide a specific tool menu entries by name
                    $.each($("#shareButton").parent().find("a"), (idx, a) => {
                        if ($(a).text().toLowerCase() == menuEntry.toLowerCase()) $(a).hide();
                    });
                }
            }
            if (this.settings.parameter.allPass.hideReferences) {
                // hide reference dialog
                $("#referenceToolBtn").hide();
            }
            if (this.settings.parameter.allPass.lockDelete) {
                $(".deleteItemBtn").hide();
            }
        }
        MR1.onAfterSave().subscribe(this, this.postSave);
    }
    private postSave(event: IItemChangeEvent) {
        let that = <GateControlImpl>event.caller;
        if (!that.settings.parameter || !that.settings.parameter.allPass) {
            return;
        }

        let notifyMail =
            that.settings.parameter.allPass.notifyEmailPassed &&
            that.settings.parameter.allPass.notifyEmailPassed.length != 0
                ? that.settings.parameter.allPass.notifyEmailPassed
                : null;
        let notifyEmailTemplate = that.settings.parameter.allPass.notifyEmailTemplate;

        let notifyPassed =
            that.settings.parameter.allPass.notifyPassed && that.settings.parameter.allPass.notifyPassed.length != 0
                ? that.settings.parameter.allPass.notifyPassed
                : null;
        let notifyFirstReject =
            that.settings.parameter.notifyFirstReject && that.settings.parameter.notifyFirstReject.length != 0
                ? that.settings.parameter.notifyFirstReject
                : null;

        if (!notifyMail && !notifyPassed && !notifyFirstReject) {
            return;
        }

        let before = (<GateFieldHandler>that.settings.fieldHandler).parseFieldValue(
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            event.before[that.settings.fieldId],
        );
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        let after = (<GateFieldHandler>that.settings.fieldHandler).parseFieldValue(event.after[that.settings.fieldId]);
        if (after.passed && !before.passed) {
            //console.log( "Gate changed to passed: " + that.settings.help);
            if (notifyMail) {
                for (let mailTo of notifyMail) {
                    let message = ml.Mail.getCannedMessage(
                        "gate_passed",
                        mailTo,
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        that.settings.id,
                        notifyEmailTemplate,
                        that.settings.help,
                    );
                    ml.Mail.sendMail(
                        mailTo,
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        null,
                        null,
                        `Passed gate ${that.settings.help} for  ${that.settings.id}`,
                        message,
                    );
                }
            }
            if (notifyPassed) {
                NotificationsBL.createNotification(
                    notifyPassed,
                    matrixSession.getProject(),
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    that.settings.id,
                    "Gate Passed",
                    null,
                    null,
                );
            }
        }
        if (after.failed && !before.failed) {
            //console.log( "Gate changed to failed: " + that.settings.help);

            if (notifyFirstReject) {
                NotificationsBL.createNotification(
                    notifyFirstReject,
                    matrixSession.getProject(),
                    // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                    that.settings.id,
                    "Gate Rejected",
                    null,
                    null,
                );
            }
        }
    }

    async hasChangedAsync(): Promise<boolean> {
        return (await this.getValueAsync()) != this.settings.fieldValue;
    }
    async getValueAsync() {
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        return this.settings.fieldHandler.getData();
    }
    setValue() {
        // NYI
    }

    destroy() {
        MR1.onAfterSave().unsubscribe(this.postSave);
    }
    resizeItem() {
        // adjust heights
        let comments = $(".gateCommentBox", this._root);
        $.each(comments, (idx, commentBox) => {
            let textarea = $("textarea", commentBox);
            if (textarea.length == 0) {
                textarea = $("div", commentBox);
            }
            let line = $(commentBox).closest(".gateLine");
            let user = $(".gateUser", line);

            const border = 2; // line above/below text area
            $(commentBox)
                .height(Math.max(line.height() - border, textarea[0].scrollHeight))
                .css("min-height", user.outerHeight() - border + "px");
        });
    }

    labelsToSet() {
        // if status is now passed, set labels if needed
        if (
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            JSON.parse(this.settings.fieldHandler.getData()).passed === true &&
            this.settings.parameter.allPass?.setLabels &&
            // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
            this.hasChangedAsync()
        ) {
            return this.settings.parameter.allPass.setLabels;
        }
        return null;
    }

    changed(fieldId: number, fieldName: string) {
        if (this.settings.parameter.reset) {
            // check if the field change should reset the label
            if (
                this.settings.parameter.reset.filter((reset) => {
                    return reset == "" + fieldId || reset.toLowerCase() == fieldName.toLowerCase();
                }).length > 0
            ) {
                // only reset the gate once in every 'session'
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                this.settings.parameter.reset = null;
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                this.settings.fieldHandler.initData("");
                this.uiCtrl.html("");
                this.showControl();
            }
        }
    }

    // Deleted users should have a strikethrough.
    private formatUserLogin(userLogin: string): string {
        return globalMatrix.ItemConfig.hasUserInfo(userLogin) ? userLogin : `<s>${userLogin}</s>`;
    }

    // render control
    private showControl() {
        let that = this;
        let conf = <IGate>this.settings.parameter;

        let sigInfo = $("<div class=''>").appendTo(this.uiCtrl);

        let maxuserwidth = 0;

        // can user sign?
        let userLine = -1;
        if (conf.strictSign) {
            // the user can only accept or deny one line: so check if the user passed or failed one line
            let idx = 0;
            for (let line of conf.lines) {
                let currentLine = that.getLine(line.id);
                if (currentLine.user == matrixSession.getUser() && (currentLine.passed || currentLine.failed)) {
                    userLine = idx;
                }
                idx++;
            }
        }
        let idx = 0;
        for (let line of conf.lines) {
            if (line.users.length == 0) {
                // needs no action
                return;
            }

            // figure out if user needs to sign this line
            let currentLine = that.getLine(line.id);
            let userOrGroupNames = line.users.map((userOrGroup: string) => {
                return ml.UI.SelectUserOrGroup.getGroupDisplayNameFromId(userOrGroup);
            });
            let canDo = false;
            if (!conf.readOnly && that.settings.canEdit && (userLine == -1 || userLine == idx)) {
                canDo = line.users.indexOf(matrixSession.getUser()) != -1;
                let groups = globalMatrix.ItemConfig.getUserGroups();

                if (groups) {
                    for (let group of groups) {
                        if (
                            line.users.indexOf(ml.UI.SelectUserOrGroup.getGroupId(group)) != -1 &&
                            group.membership
                                .map((member) => {
                                    return member.login;
                                })
                                .indexOf(matrixSession.getUser()) != -1
                        ) {
                            canDo = true;
                        }
                    }
                }
            }

            // add line
            let tr = $('<div class="input-group gateLine">').appendTo(sigInfo);

            // hint for signature
            let hint = $("<div>");
            if (!conf.hideApprovalInfo) {
                hint.addClass(currentLine.passed ? "gateHintDone" : "gateHint");
                if (currentLine.passed) {
                    hint.html(line.hintDone ? line.hintDone : "approved by");
                } else if (currentLine.failed) {
                    hint.html(line.hintRejected ? line.hintRejected : "rejected by");
                } else {
                    hint.html(line.hint ? line.hint : "requires approval of");
                }
            }
            // add column with users / info about signature
            let users = $('<div class="input-group-addon gateUser">').appendTo(tr).append(hint);
            if (!conf.hideApprovalInfo && (currentLine.passed || currentLine.failed)) {
                // show info about who signed it
                const userName = that.formatUserLogin(currentLine.user);
                users.append(
                    `<div><span class='gateUserId'>${userName}</span>${
                        currentLine.dateUser ? " on " + currentLine.dateUser : ""
                    }</div>`,
                );
            } else {
                users.css("white-space", "normal");
                let htmlUserNames: string[] = userOrGroupNames.map((name) => {
                    // groups are already formatted
                    if (name.startsWith("<")) return name;
                    const textName = that.formatUserLogin(name);
                    return `<span>${textName}</span>`;
                });
                users.append($("<div class='gatePassUser'>").html(htmlUserNames.join(", ")));
            }

            // add a comment box
            let comments: JQuery;
            let inputs = $(`<div class='gateCommentBox' style='resize:vertical;overflow:hidden'>`);

            if (conf.hasComments) {
                inputs.appendTo(tr);
                comments = $(
                    '<textarea style="height:100%;resize:none !important;margin-right:6px;" class="form-control gateComment gateInput" ' +
                        (canDo ? "" : " readonly") +
                        ">",
                );
                inputs.append(comments);
                // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                comments.val(currentLine.comment ? ml.UI.lt.forUI(currentLine.comment, that.settings.fieldId) : "");
                if (canDo) {
                    comments.on("change keyup paste", () => {
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        currentLine.comment = ml.UI.lt.forDB(comments.val(), that.settings.fieldId);
                        (<GateFieldHandler>that.settings.fieldHandler).updateOverallStatus();
                        window.clearTimeout(that.triggerUpdate);
                        that.triggerUpdate = window.setTimeout(() => {
                            if (that.settings.valueChanged) {
                                that.settings.valueChanged.apply(null);
                            }
                        });
                    });
                }
            }

            // add sign/reject buttons based on status
            let pass = $("<span>");
            let fail = $("<span>");

            let title = "";
            if (currentLine.passed || currentLine.failed) {
                const userName = that.formatUserLogin(currentLine.user);
                title =
                    (hint.html() ? hint.html() : "last change by") +
                    " " +
                    userName +
                    (currentLine.dateUser ? " on " + currentLine.dateUser : "");
                tr.attr("title", title);
            }

            if (conf.pass) {
                let passText =
                    currentLine.passed && conf.passPassed
                        ? conf.passPassed
                        : currentLine.failed && conf.passFailed
                        ? conf.passFailed
                        : conf.pass;
                if (passText != "hide") {
                    pass = $(
                        '<span class="input-group-btn input-group-addon ' +
                            (canDo ? "btn btn-default" : "gateButtonOff") +
                            ' gateButton">' +
                            passText +
                            "</span>",
                    ).appendTo(tr);
                }
            }

            if (conf.fail) {
                let failText =
                    currentLine.passed && conf.failPassed
                        ? conf.failPassed
                        : currentLine.failed && conf.failFailed
                        ? conf.failFailed
                        : conf.fail;
                if (failText != "hide") {
                    fail = $(
                        '<span class="input-group-btn input-group-addon ' +
                            (canDo ? "btn btn-default" : "gateButtonOff") +
                            ' gateButton">' +
                            failText +
                            "</span>",
                    ).appendTo(tr);
                }
            }

            if (canDo) {
                pass.click(() => {
                    if (currentLine.passed) {
                        currentLine.passed = false;
                        that.setStatus(currentLine, pass, currentLine.passed, fail, currentLine.failed);
                    } else if (conf.requireSignature) {
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        that.askForSignature().done((password: string) => {
                            app.checkPassword(password)
                                .done(() => {
                                    currentLine.passed = true;
                                    currentLine.failed = false;
                                    that.setStatus(currentLine, pass, currentLine.passed, fail, currentLine.failed);
                                })
                                .fail((jqxhr, textStatus, error) => {
                                    ml.UI.showError("Incorrect  password!", "");
                                });
                        });
                    } else {
                        currentLine.passed = true;
                        currentLine.failed = false;
                        that.setStatus(currentLine, pass, currentLine.passed, fail, currentLine.failed);
                    }
                });
                fail.click(() => {
                    if (currentLine.failed) {
                        currentLine.failed = false;
                        that.setStatus(currentLine, pass, currentLine.passed, fail, currentLine.failed);
                    } else if (conf.requireSignatureReject) {
                        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
                        that.askForSignature().done((password: string) => {
                            app.checkPassword(password)
                                .done(() => {
                                    currentLine.failed = true;
                                    currentLine.passed = false;
                                    that.setStatus(currentLine, pass, currentLine.passed, fail, currentLine.failed);
                                })
                                .fail((jqxhr, textStatus, error) => {
                                    ml.UI.showError("Incorrect  password!", "");
                                });
                        });
                    } else {
                        currentLine.failed = true;
                        currentLine.passed = false;
                        that.setStatus(currentLine, pass, currentLine.passed, fail, currentLine.failed);
                    }
                });
            }

            that.setColor(pass, true, currentLine.passed);
            that.setColor(fail, false, currentLine.failed);

            maxuserwidth = Math.max(maxuserwidth, users.width(), 150);
            idx++;
        }
        $(".gateUser", sigInfo).width(maxuserwidth);
        if (
            this.settings.controlState == ControlState.HistoryView ||
            this.settings.controlState == ControlState.Zen ||
            this.settings.controlState == ControlState.Tooltip
        ) {
            this._root.css("min-width", "600px");
            this.resizeItem();
        }
    }

    private askForSignature() {
        let res: JQueryDeferred<string> = $.Deferred();
        let that = this;

        let dlg = $("<div>").appendTo($("body"));
        let ui = $("<div style='height:100%;width:100%'>");

        let password = { password: "" };
        ml.UI.addPassInput(ui, "Password:", password, "password", () => {});

        ml.UI.showDialog(
            dlg,
            "Enter Signature",
            ui,
            730,
            -200,
            [
                {
                    text: "OK",
                    class: "btnDoIt",
                    click: () => {
                        dlg.dialog("close");
                        res.resolve(password.password);
                    },
                },
                {
                    text: "Cancel",
                    class: "btnCancelIt",
                    click: () => {
                        dlg.dialog("close");
                    },
                },
            ],
            UIToolsConstants.Scroll.None,
            false,
            false,
            () => {
                dlg.remove();
            },
            () => {},
            () => {},
        );
        return res;
    }
    // set status of current line
    private setStatus(
        currentLine: IGateStatusLine,
        passBtn: JQuery,
        passed: boolean,
        failBtn: JQuery,
        failed: boolean,
    ) {
        currentLine.passed = passed;
        currentLine.failed = failed;
        currentLine.date = new Date().toISOString();
        currentLine.dateUser = ml.UI.DateTime.renderCustomerHumanDate(new Date(currentLine.date), false);

        this.update(currentLine, passBtn, failBtn);
    }
    private update(currentLine: IGateStatusLine, pass: JQuery, fail: JQuery) {
        currentLine.user = matrixSession.getUser();
        this.setColor(pass, true, currentLine.passed);
        this.setColor(fail, false, currentLine.failed);
        (<GateFieldHandler>this.settings.fieldHandler).updateOverallStatus();
        if (this.settings.valueChanged) {
            this.settings.valueChanged.apply(null);
        }
    }

    // set color of button to indicate fail / pass
    private setColor(btn: JQuery, isPass: boolean, isOn: boolean) {
        if (!isOn) {
            btn.css("color", "");
            btn.css("background-color", "");
            return;
        }

        if (isPass) {
            let gateLine = btn.closest(".gateLine");
            if (isOn) {
                // no need to show password field
                $(".gatePwd", gateLine).hide();
                $(".gatePassLabel", gateLine).hide();
            } else {
                $(".gatePwd", gateLine).show();
                $(".gatePassLabel", gateLine).show();
            }
        }

        btn.css("background-color", isPass ? "green" : "red");
        btn.css("color", "white");
    }

    private getLine(id: string) {
        // during init it has been made sure that there is a line...
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        return (<GateFieldHandler>this.settings.fieldHandler).getGateValue().lines.filter((line) => {
            return line.id == id;
        })[0];
    }
}
