import {
    IDHFSection,
    IDHFControlDefinition,
    IDHFSectionOptions,
    DocumentSectionType,
} from "../../../common/businesslogic/index";
import { IBaseControlOptions } from "../../../common/UI/Controls/BaseControl";
import { IDocFieldHandler } from "../../../common/businesslogic/FieldHandlers/Document/IDocFieldHandler";
import { BaseDHFSection } from "./BaseDHFSection";
import { GenericFieldHandler } from "../../../common/businesslogic/FieldHandlers/GenericFieldHandler";
import { SectionDescriptions } from "../../../common/businesslogic/FieldHandlers/Document/SectionDescriptions";
import { GenericDocFieldHandler } from "../../../common/businesslogic/FieldHandlers/Document/GenericDocFieldHandler";

export { Hidden };

class Hidden extends BaseDHFSection<IDHFSectionOptions> implements IDHFSection {
    renderControl(ctrl: IDHFControlDefinition, ctrlParameter: IBaseControlOptions) {
        // @ts-ignore TODO: MATRIX-6934: nullStrictCheck should be fixed for next line
        ctrl.control.hidden(ctrlParameter);
    }

    showSpecificSettings(ctrl: IDHFControlDefinition, ctrlParameter: IBaseControlOptions, custom: JQuery) {}
    async saveSpecificSettingsAsync(ctrl: IDHFControlDefinition, ctrlParameter: IBaseControlOptions, custom: JQuery) {
        return false;
    }
    async verifyContentAsync(ctrl: IDHFControlDefinition) {}
}
