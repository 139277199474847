export { Email };

// makes an email field with validation out of an input field
class Email {
    private element: JQuery;
    private resultBox: JQuery;
    constructor(e: string) {
        this.element = $(e);
        this.resultBox = $("<div>");
        this.resultBox.css("color", "red");

        this.element.parent().append(this.resultBox);
        this.element.keyup(() => this.validate());
    }

    isOK() {
        return this.validate();
    }

    private validate() {
        let name = this.element.val();
        if (name === "") {
            this.resultBox.html("email is required and cannot be empty");
            this.resultBox.show();
            return false;
        }
        if (
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
                name,
            )
        ) {
            this.resultBox.hide();
            return true;
        }

        this.resultBox.html("This is not a correct email");
        this.resultBox.show();
        return false;
    }
}
